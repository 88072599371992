import Layout from 'layout';
import { Header } from 'styles/integrations';
import Button from 'components/ui/button';
import Link from 'components/ui/link';
import Section from 'components/clickmaps/section';
import StartUsing from 'components/ui/startUsing';
import img1 from 'img/clickmaps/1.svg';
import img2 from 'img/clickmaps/2.svg';
import img3 from 'img/clickmaps/3.svg';
import img4 from 'img/clickmaps/4.svg';
import hero from 'img/clickmaps/hero.svg';
import error from 'img/clickmaps/error.svg';
import rage from 'img/clickmaps/rage.svg';
import clicks from 'img/clickmaps/clicks.svg';
import { Container, ExtendHeader, ContentSection, CustomSection } from 'styles/clickmaps';

const Clickmaps = () => (
  <Layout
    canonical="/clickmaps/"
    metaTitle="Click maps | Track Most Valuable Clicks"
    metaDescription="Identify your website’s most clickable elements with LiveSession Clickmaps. Use heat maps to spot the hottest sections."
  >
    <Container className="container ">
      <div className="p-168-120-90 pb-240-168-120-90">
        <Header>
          <h1>Click maps by LiveSession</h1>
          <p style={{ marginBottom: 40 }}>Find your website’s most clickable elements.</p>
          <Button signUp sourceID="hero">
            Start using click maps
          </Button>
        </Header>
        <ExtendHeader>
          <img src={hero} alt="Clickmaps" title="Clickmaps" className="img-fluid" />
          <h2>Instant and retroactive click maps + heat maps</h2>
          <p>
            We generate click maps and heat maps on the fly, showing you the most engaging sections
            of your website. Use filters and segments to see how certain groups of users interact
            with it. Take advantage of the data you collected.
          </p>
        </ExtendHeader>
      </div>
      <ContentSection>
        <Section>
          <div>
            <h2>See what draws your visitors’ attention</h2>
            <p>
              LiveSession Clickmaps give you an instant overview of your pages’ most clickable
              elements. See how many times users interacted with any button, link, headline or
              paragraph.
            </p>
            <p>
              Leverage data aggregated from your website’s visits. Know what attracts your users and
              understand their behavior better with LiveSession Clickmaps.
            </p>
            <Link href="/help/how-do-the-clickmaps-work/" withArrow>
              Read more
            </Link>
          </div>
          <img
            src={img1}
            alt="Clickmaps - example - 1"
            title="Find most engagement places"
            className="img-fluid"
          />
        </Section>
        <div className="p-240-168-120-90">
          <Section reversed>
            <div>
              <h2>Improve conversion rate, get rid of obstacles</h2>
              <p>
                Click maps allow you to improve your conversion rate by showing you which elements
                of your website are essential on your users’ path to purchase. They also point to
                the dead-ends.
              </p>
              <p>With LiveSession Clickmaps, you can group your users’ interactions by:</p>
              <CustomSection>
                <div className="section-box">
                  <img src={clicks} alt="Clicks" title="Clicks" className="img-fluid" />
                  <div>
                    <h5>Clicks</h5>
                    <p>
                      Consider clicks as your customer journey to purchase. See your website’s most
                      clickable elements. Find the most engaging sections and work on improving the
                      click rate even more.
                    </p>
                  </div>
                </div>
                <div className="section-box">
                  <img src={error} alt="Error Clicks" title="Error Clicks" className="img-fluid" />
                  <div>
                    <h5>Error Clicks</h5>
                    <p>
                      Error clicks are caused by JavaScript errors and can lead to users’ annoyance,
                      which may eventually lower your conversion rate. See where Error clicks happen
                      and get rid of them.
                    </p>
                  </div>
                </div>
                <div className="section-box">
                  <img src={rage} alt="Rage Clicks" title="Rage Clicks" className="img-fluid" />
                  <div>
                    <h5>Rage Clicks</h5>
                    <p>
                      Rage clicks are logged when your user gets frustrated and clicks on something
                      repeatedly. Usually, it happens when some elements of your website are unclear
                      or misleading. Spot Rage clicks and refine your website’s UX.
                    </p>
                  </div>
                </div>
              </CustomSection>
            </div>
            <img
              src={img2}
              alt="Clickmaps - example - 2"
              title="Track different bahaviors"
              className="img-fluid"
            />
          </Section>
        </div>
        <Section>
          <div>
            <h2>Find the hottest sections on your website with heat maps</h2>
            <p>
              Turn on LiveSession Heatmaps and see the hottest sections of your website in a blink
              of an eye. Place important content, such as calls to action or forms, in places that
              engage your visitors the most. Get more signups or purchases, using insights on your
              users’ behavio
            </p>

            <Link href="/help/how-do-the-clickmaps-work/" withArrow>
              Read more
            </Link>
          </div>
          <img
            src={img3}
            alt="Clickmaps - example - 3"
            title="Spot important places eaiser with engagement Heatmaps"
            className="img-fluid"
          />
        </Section>
        <div className="p-240-168-120-90 pb-120-90">
          <Section reversed>
            <div>
              <h2>Use filters to get more out of your data</h2>
              <p>
                The data we present in click maps can be filtered to match your goal better. Whether
                you want to improve the checkout process or get more leads, use filters to dig
                deeper and know how particular segments of your website’s visitors act. Add layers
                to your analysis and find new ways to boost your sales. Get the most accurate view
                by taking advantage of the result’s segmentation.
              </p>
            </div>
            <img
              src={img4}
              alt="Clickmaps - example - 3"
              title="Get more accurate view with filters"
              className="img-fluid"
            />
          </Section>
        </div>
      </ContentSection>
    </Container>
    <StartUsing />
  </Layout>
);

export default Clickmaps;
