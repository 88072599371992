import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';

const UpHeading = ({ headingText, spanText }) => (
  <Styled.Flex>
    <Styled.Heading>{headingText}</Styled.Heading>
    {spanText && <Styled.Span>{spanText}</Styled.Span>}
  </Styled.Flex>
);
export default UpHeading;
UpHeading.propTypes = {
  headingText: PropTypes.string.isRequired,
  spanText: PropTypes.string.isRequired,
};
