import React from 'react';
import Link from 'components/ui/link';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';
import { toolsStack } from 'data/integrations/platforms';
import { CardIntegration } from 'components/ecommerce/styles';
import UpHeading from '../upHeading';
import * as Styled from './styled';

const ToolStack = ({ Wave }) => (
  <Styled.IntegrationsAside>
    {Wave ? (
      <div className="waveContainer waveGray">
        <div className="container">
          <div className="aside-content ">
            <UpHeading headingText="INTEGRATIONS" />
            <h2>Keep your tool stack connected</h2>
            <p>
              Analytics, bug tracking, customer support - enhance your customer experience and ease
              your team members’ lives by adding some extra context.
            </p>
            <Link withArrow href="/integrations/">
              See all integrations
            </Link>
          </div>
          <Styled.CardsContainer>
            {toolsStack.map((toolStack) => (
              <CardIntegration
                key={toolStack.img}
                to={toolStack.link}
                as={toolStack.link ? GatsbyLink : 'div'}
              >
                <img src={toolStack.img} alt={toolStack.alt} className="img-fluid" />
                <p>{toolStack.alt}</p>
              </CardIntegration>
            ))}
          </Styled.CardsContainer>
        </div>
      </div>
    ) : (
      <div className="container">
        <div className="aside-content">
          <UpHeading headingText="INTEGRATIONS" />
          <h2>Keep your tool stack connected</h2>
          <p>
            Analytics, bug tracking, customer support - enhance your customer experience and ease
            your team members’ lives by adding some extra context.
          </p>
          <Link withArrow href="/integrations/">
            See all integrations
          </Link>
        </div>
        <Styled.CardsContainer>
          {toolsStack.map((toolStack) => (
            <CardIntegration
              key={toolStack.img}
              to={toolStack.link}
              as={toolStack.link ? GatsbyLink : 'div'}
            >
              <img src={toolStack.img} alt={toolStack.alt} className="img-fluid" />
              <p>{toolStack.alt}</p>
            </CardIntegration>
          ))}
        </Styled.CardsContainer>
      </div>
    )}
  </Styled.IntegrationsAside>
);

export default ToolStack;

ToolStack.propTypes = {
  Wave: PropTypes.string.isRequired,
};
