/* eslint-disable jsx-a11y/anchor-is-valid */

import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import withIntegrationCardContext from 'hoc/withIntegrationCardContext';
import Slider from 'react-slick';

const Intercom = ({ sliderSettings }) => {
  const { intercom1, intercom2 } = useStaticQuery(graphql`
    {
      intercom1: file(relativePath: { eq: "marketplace/intercom/1.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      intercom2: file(relativePath: { eq: "marketplace/intercom/2.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);
  const photos = [
    {
      img: intercom1.childImageSharp,
      alt: 'Intercom integration',
      title: 'Integrate LiveSession with Intercom',
    },
    {
      img: intercom2.childImageSharp,
      alt: 'Intercom integration',
      title: 'Integrate LiveSession with Intercom',
    },
  ];
  return (
    <>
      <Slider {...sliderSettings(photos)}>
        {photos.map(({ img, alt, title }) => (
          <div key={img}>
            <GatsbyImage image={img?.gatsbyImageData} alt={alt} title={title} />
          </div>
        ))}{' '}
      </Slider>
      <div className="section">
        <h2>Overview</h2>
        <p>
          Intercom is a system to communicate with visitors on your website. This tool can be used
          for lead generation, customer service, and improving customer engagement too. It offers a
          reliable live chat solution for your website, as well as multichannel management tools,
          customer data platform and a wide range of integrations.
        </p>
        <p>You can connect Intercom to LiveSession and learn more about your customers.</p>
      </div>
      <div className="section">
        <h2>Benefits</h2>
        <ul className="checkmarks">
          <li>Access LiveSession recordings from Intercom conversations</li>
          <li>Step into the user’s shoes and see what they’ve seen</li>
          <li>Provide relevant solutions faster</li>
        </ul>
      </div>
      <h2> How to install</h2>
      <p>
        You can install this integration straight from LiveSession. See a step-by-step tutorial in
        our <a href="/help/intercom-integration/">help center</a>.
      </p>
    </>
  );
};

export default withIntegrationCardContext(Intercom);

Intercom.propTypes = {
  sliderSettings: PropTypes.func.isRequired,
};
