import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const Badge = styled.div`
  width: ${(props) => (props.small ? '104px' : '139px')};
  height: ${(props) => (props.small ? '33px' : '42px')};
  background-color: #ffffff;
  border-radius: 4px;
  position: absolute;
  bottom: ${(props) => (props.small ? '14px' : '33px')};
  right: ${(props) => (props.small ? '14px' : '33px')};
  background-size: 80%;
  background-position: center;
  background-repeat: no-repeat;
  ${media.tablet} {
    width: 104px;
    height: 33px;
  }
`;
