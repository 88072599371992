import styled from 'styled-components';

export const Aside = styled.aside`
  background-color: #ffffff;
  border-radius: 4px;
  padding: 32px 24px;
  margin-top: 72px;
  border: 2px solid black;
  & ul {
    list-style: none;
    margin: 0;
    padding: 0;
    & li {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
      & a {
        width: 100%;
        color: #000;
        &:hover {
          color: #000;
        }
        &.active {
          color: #0a4ed6;
          &:hover {
            color: #0a4ed6;
          }
          & p:first-child {
            color: #0a4ed6;
          }
          & p:last-child {
            font-weight: 800;
          }
        }
        & p:first-child {
          color: #808080;
          font-size: 14px;
          line-height: 24px;
          margin-bottom: 4px;
          text-transform: uppercase;
        }
        & p:last-child {
          font-size: 18px;
          line-height: 26px;
          margin: 0;
        }
      }
    }
  }
`;
