import intercom from 'img/companyLogo/icons/intercom-logo.svg';
import ga from 'img/companyLogo/icons/ga.svg';
import bigcommerce from 'img/companyLogo/icons/bigcommerce.svg';
import shopify from 'img/companyLogo/icons/shopify.svg';
import wordpress from 'img/companyLogo/icons/wordpress.svg';
import gtm from 'img/companyLogo/icons/gtm.svg';
import drift from 'img/companyLogo/icons/drift-logo.svg';
import livechat from 'img/companyLogo/icons/livechat.svg';
import wix from 'img/companyLogo/icons/wix.svg';
import gatsby from 'img/companyLogo/icons/gatsby.svg';
import helpscout from 'img/companyLogo/icons/helpscout.svg';
import segment from 'img/companyLogo/icons/segment-logo.svg';
import crisp from 'img/companyLogo/icons/crisp-logo.svg';
import olark from 'img/companyLogo/icons/olark-logo.png';
import zendesk from 'img/companyLogo/icons/zendesk.svg';
import sentry from 'img/companyLogo/icons/sentry.svg';
import bugsnag from 'img/companyLogo/icons/bugsnag.png';
import survicate from 'img/companyLogo/icons/survicate.svg';
import heap from 'img/companyLogo/icons/heap.svg';
import errorception from 'img/companyLogo/icons/errorception.svg';
import mixpanel from 'img/companyLogo/icons/mixpanel.svg';
import user from 'img/companyLogo/icons/user.png';
import dashly from 'img/companyLogo/icons/dashly.png';
import googleOptimize from 'img/companyLogo/icons/google-optimize.svg';
import slack from 'img/companyLogo/icons/slack.svg';
import zapier from 'img/companyLogo/icons/zapier.svg';

// covers
import segCover from 'img/marketplace/covers/Segment.png';
import intCover from 'img/marketplace/covers/Intercom.png';
import bcCover from 'img/marketplace/covers/Bigcommerce.png';
import shCover from 'img/marketplace/covers/shopify.png';
import wpCover from 'img/marketplace/covers/wordpress.png';
import gtmCover from 'img/marketplace/covers/gtm.png';
import drCover from 'img/marketplace/covers/Drift.png';
import lvCover from 'img/marketplace/covers/livechat.png';
import wixCover from 'img/marketplace/covers/Wix.png';
import gatCover from 'img/marketplace/covers/Gatsby.png';
import hcCover from 'img/marketplace/covers/HelpScout.png';
import crCover from 'img/marketplace/covers/Crisp.png';
import olCover from 'img/marketplace/covers/Olark.png';
import zenCover from 'img/marketplace/covers/Zendesk.png';
import senCover from 'img/marketplace/covers/Sentry.png';
import bugCover from 'img/marketplace/covers/Bugsnag.png';
import survCover from 'img/marketplace/covers/Survicate.png';
import gaCover from 'img/marketplace/covers/ga.png';
import heapCover from 'img/marketplace/covers/Heap.png';
import errCover from 'img/marketplace/covers/Errorception.png';
import userCover from 'img/marketplace/covers/User.png';
import mixCover from 'img/marketplace/covers/Mixpanel.png';
import dashlyCover from 'img/marketplace/covers/Dashly.png';
import googleOptimizeCover from 'img/marketplace/covers/googleOptimize.png';
import slackCover from 'img/marketplace/covers/Slack.png';

// Add ORDERS TO SORT

export const frontmatter = {
  items: [
    {
      id: `integration-26`,
      img: zapier,
      cover: intCover,
      title: 'Zapier',
      desc: `Build automation workflows with LiveSession data passed through Zapier.`,
      link: '/integrations/zapier/',
      madeBy: 'LiveSession',
      categories: ['New', 'Marketers', 'Developers'],
    },
    {
      id: `integration-1`,
      img: intercom,
      cover: intCover,
      title: 'Intercom',
      desc: `Send LiveSession recordings to your Intercom account.`,
      link: '/integrations/intercom/',
      madeBy: 'LiveSession',
      categories: ['Customer support', 'Most popular', 'New', 'Marketers', 'Support'],
    },
    {
      id: `integration-8`,
      img: livechat,
      cover: lvCover,
      title: `LiveChat`,
      desc: `Send LiveSession recordings to your LiveChat account.`,
      link: '/integrations/livechat/',
      madeBy: 'LiveSession',
      categories: ['Customer support', 'Support', 'New', 'Most popular'],
    },
    {
      id: `integration-15`,
      img: zendesk,
      cover: zenCover,
      title: `Zendesk`,
      desc: `Add LiveSession recordings to your Zendesk tickets.`,
      link: '/integrations/zendesk/',
      madeBy: 'LiveSession',
      categories: ['Customer support', 'Support'],
    },
    {
      id: `integration-16`,
      img: sentry,
      cover: senCover,
      title: `Sentry`,
      desc: `Add LiveSession recordings to issues in Sentry.`,
      link: '/integrations/sentry/',
      madeBy: 'LiveSession',
      categories: ['Bug tracking', 'New', 'Developers'],
    },
    {
      id: `integration-25`,
      img: slack,
      cover: slackCover,
      title: 'Slack',
      desc: `Send LiveSession Alerts to Slack channel`,
      link: '/integrations/slack/',
      madeBy: 'LiveSession',
      categories: ['Customer support', 'Most popular', 'Marketers', 'New'],
    },
    {
      id: `integration-2`,
      img: ga,
      cover: gaCover,
      title: 'Google Analytics',
      desc: `Use LiveSession recordings as events in Google Analytics.`,
      link: '/integrations/google-analytics/',
      madeBy: 'LiveSession',
      categories: ['Analytics', 'Most popular', 'Marketers', 'UX Designers'],
    },
    {
      id: `integration-3`,
      img: bigcommerce,
      cover: bcCover,
      title: 'BigCommerce',
      desc: `Connect LiveSession to your BigCommerce website.`,
      link: '/integrations/bigcommerce/',
      madeBy: 'LiveSession',
      categories: ['E-commerce'],
    },
    {
      id: `integration-4`,
      img: shopify,
      cover: shCover,
      title: 'Shopify',
      desc: `See how the users interact with your Shopify store.`,
      link: '/integrations/shopify/',
      madeBy: 'LiveSession',
      categories: ['E-commerce', 'Most popular'],
    },
    {
      id: `integration-5`,
      img: wordpress,
      cover: wpCover,
      title: 'Wordpress',
      desc: `Add a tracking code to your Wordpress website.`,
      link: '/integrations/wordpress/',
      madeBy: 'LiveSession',
      categories: ['CMS', 'Most popular'],
    },
    {
      id: `integration-6`,
      img: gtm,
      cover: gtmCover,
      title: `Google Tag Manager`,
      desc: `Add LiveSession tracking code as a tag in Google Tag Manager.`,
      link: '/integrations/google-tag-manager/',
      madeBy: 'LiveSession',
      categories: ['Analytics', 'Most popular', 'Marketers'],
    },
    {
      id: `integration-7`,
      img: drift,
      cover: drCover,
      title: `Drift`,
      desc: `Add LiveSession recordings to Drift contact pages.`,
      link: '/integrations/drift/',
      madeBy: 'LiveSession',
      categories: ['Customer support', 'Support'],
    },
    {
      id: `integration-9`,
      img: wix,
      cover: wixCover,
      title: `Wix`,
      desc: `Track users on your Wix website.`,
      link: '/integrations/wix/',
      madeBy: 'LiveSession',
      categories: ['CMS'],
    },
    {
      id: `integration-10`,
      img: gatsby,
      cover: gatCover,
      title: `Gatsby`,
      desc: `Install LiveSession as a Gatsby plugin.`,
      link: '/integrations/gatsby/',
      madeBy: 'LiveSession',
      categories: ['CMS', 'New', 'Developers'],
    },
    {
      id: `integration-11`,
      img: segment,
      cover: segCover,
      title: `Segment`,
      desc: `Use LiveSession recordings as events in Segment.`,
      link: '/integrations/segment/',
      madeBy: 'LiveSession',
      categories: ['Analytics', 'Most popular', 'Marketers'],
    },
    {
      id: `integration-12`,
      img: helpscout,
      cover: hcCover,
      title: `Help Scout`,
      desc: `Add LiveSession recordings to Help Scout tickets.`,
      link: '/integrations/help-scout/',
      madeBy: 'LiveSession',
      categories: ['Customer support', 'Support'],
    },
    {
      id: `integration-13`,
      img: crisp,
      cover: crCover,
      title: `Crisp`,
      desc: `Send LiveSession recordings to your Crisp account.`,
      link: '/integrations/crisp/',
      madeBy: 'LiveSession',
      categories: ['Customer support', 'Support'],
    },
    {
      id: `integration-14`,
      img: olark,
      cover: olCover,
      title: `Olark`,
      desc: `Add LiveSession recordings to Olark.`,
      link: '/integrations/olark/',
      madeBy: 'LiveSession',
      categories: ['Customer support', 'Support'],
    },
    {
      id: `integration-17`,
      img: bugsnag,
      cover: bugCover,
      title: `Bugsnag`,
      desc: `Add LiveSession recordings to issue events in Bugsnag.`,
      link: '/integrations/bugsnag/',
      madeBy: 'LiveSession',
      categories: ['Bug tracking', 'New', 'Developers'],
    },
    {
      id: `integration-18`,
      img: survicate,
      cover: survCover,
      title: `Survicate`,
      desc: `Connect session recording link to Survicate respondent profile.`,
      link: '/integrations/survicate/',
      madeBy: 'LiveSession',
      categories: ['Surveys', 'New', 'UX Designers', 'Marketers'],
    },
    {
      id: `integration-19`,
      img: errorception,
      cover: errCover,
      title: `Errorception`,
      desc: `Access LiveSession recordings directly from Errorception. `,
      link: '/integrations/errorception/',
      madeBy: 'LiveSession',
      categories: ['Bug tracking', 'New', 'Developers'],
    },
    {
      id: `integration-20`,
      img: heap,
      cover: heapCover,
      title: 'Heap',
      desc: `Add LiveSession recording links to Heap events.`,
      link: '/integrations/heap/',
      madeBy: 'LiveSession',
      categories: ['Analytics', 'Most popular', 'Marketers', 'New'],
    },
    {
      id: `integration-21`,
      img: mixpanel,
      cover: mixCover,
      title: 'Mixpanel',
      desc: `Find LiveSession recordings in event properties in Mixpanel.`,
      link: '/integrations/mixpanel/',
      madeBy: 'LiveSession',
      categories: ['Analytics', 'Most popular', 'Marketers', 'New'],
    },
    {
      id: `integration-22`,
      img: user,
      cover: userCover,
      title: 'User.com',
      desc: `Access LiveSession recordings from User.com conversations.`,
      link: '/integrations/user/',
      madeBy: 'LiveSession',
      categories: ['Customer support', 'Support', 'Marketers', 'New'],
    },
    {
      id: `integration-23`,
      img: dashly,
      cover: dashlyCover,
      title: 'Dashly',
      desc: `Add session replays as events in Dashly.`,
      link: '/integrations/dashly/',
      madeBy: 'LiveSession',
      categories: ['Customer support', 'Support', 'Marketers', 'New'],
    },
    {
      id: `integration-24`,
      img: googleOptimize,
      cover: googleOptimizeCover,
      title: 'Google Optimize',
      desc: `Add A/B test variants as custom properties in LiveSession.`,
      link: '/integrations/google-optimize/',
      madeBy: 'LiveSession',
      categories: ['Analytics', 'Most popular', 'Marketers', 'New', 'UX Designers'],
    },
  ],
};
