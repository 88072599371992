import PropTypes from 'prop-types';
import styled from 'styled-components';

const Button = styled.button`
  font-weight: 700;
  color: ${(props) => props.color};
  .blackColor {
    background-color: ${(props) => props.backgroundColor};
  }
`;

const Hamburger = ({ showMobileMenu, font, onClick }) => (
  <Button
    color={showMobileMenu ? '#000' : font}
    backgroundColor={showMobileMenu ? '#000' : font || '#000'}
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
    className="navbar-toggler navbar-toggler-right"
    onClick={onClick}
  >
    Menu
    <span className={['hamburger__box', showMobileMenu && 'hamburger--active'].join(' ')}>
      <span className="hamburger__inner__before blackColor" />
      <span className="hamburger__inner blackColor" />
      <span className="hamburger__inner__after blackColor" />
    </span>
  </Button>
);

export default Hamburger;

Hamburger.propTypes = {
  showMobileMenu: PropTypes.bool,
  font: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

Hamburger.defaultProps = {
  showMobileMenu: false,
  font: null,
};
