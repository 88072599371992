import React from 'react';
import Link from 'components/ui/link';
import Button from 'components/ui/button';
import { StaticImage } from 'gatsby-plugin-image';
import intercom from 'img/companyLogo/icons/intercom-text.svg';

import * as Styled from './styled';

const Intercom = () => (
  <div className="container">
    <Styled.Intercom>
      <h2>Integrate with your customer support platform</h2>
      <div className="banner">
        <div className="banner-content">
          <img src={intercom} alt="livechat logo" className="img-fluid" />
          <h3>Watch user session directly from Intercom, Zendesk or LiveChat</h3>
          <p>
            Add context to each chat you are having with your customers. Enrich every Intercom
            conversation with a link to the user’s session replay. Streamline your customer support
            process and make your support team’s life easier.
          </p>
          <div className="banner-cta--buttons">
            <Button href="/integrations/intercom/">Learn more</Button>
            <Link withArrow href="/integrations/">
              Show more integrations
            </Link>
          </div>
        </div>
        <Styled.ImgWrapper>
          <StaticImage
            src="../../../img/saas/intercomchatx3.png"
            alt="intercom chat"
            objectFit="contain"
          />
        </Styled.ImgWrapper>
      </div>
    </Styled.Intercom>
  </div>
);

export default Intercom;
