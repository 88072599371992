import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';
import { media } from 'theme/breakpoints';
import ls from 'img/LogoLS/favicon-ls.png';
import checkmark from 'img/ui/checkmarkGreen.svg';
import MoreInfo from 'img/ui/moreInfoBlack.inline.svg';
import cross from 'img/ui/x-ads.svg';

const Section = styled.section`
  padding-top: 0;
  & h2 {
    text-align: center;
    font-size: 28px;
    line-height: 42px;
    ${media.tablet} {
      font-size: 24px;
      line-height: 36px;
    }
  }
  & p {
    margin: 20px 0 80px;
    font-size: 18px;
    text-align: center;
    ${media.mobile} {
      margin: 20px 0 64px;
    }
  }
`;

const FeaturesTable = styled.table`
  margin: 0;
  table-layout: fixed;
  width: 100%;
  max-width: 100%;

  td {
    img {
      height: 16px;
      margin: 0;
      display: initial;
    }
    padding: 0;
  }
  .more-info {
    display: inline-block;

    :hover {
      span {
        text-decoration: underline;
      }
      i {
        border: 1px solid #000;
        color: #000;
        fill: #000;
        rect {
          fill: #000;
        }
      }
    }
    span {
      font-size: 16px;
      font-weight: 700;
      ${media.mobile} {
        font-size: 12px;
      }
    }
    i {
      display: inline-flex;
      transform: scale(0.7);
      margin-left: 4px;
      border: 1px solid rgb(170, 170, 170);
      border-radius: 50%;
      min-width: 20px;
      height: 20px;
      text-align: center;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      max-width: 18px;
      color: rgb(170, 170, 170);
      svg,
      i,
      rect {
        fill: rgb(170, 170, 170);
        font-size: 10px;
      }
    }
  }
  & thead tr th {
    padding: 0 0 40px 0;
    & img {
      max-height: 24px;
    }
  }
  & thead th {
    border: none;
    &.plan {
      width: 35%;
      font-size: 16px;
      text-align: center;
      ${media.mobile} {
        font-size: 12px;
        width: auto;
      }
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        ${media.mobile} {
          flex-direction: column;
        }
      }
      span {
        padding-left: 8px;
        font-size: 18px;
        ${media.mobile} {
          font-size: 12px;
          padding: 8px 0 0 0;
        }
      }
      img {
        border-radius: 2px;
        height: 24px;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.16);
        margin-bottom: 0;
      }
      .lsIcon {
        border-radius: 4px;
      }
    }
  }

  & tbody tr th {
    padding: 16px 0;
    font-weight: 700;
    position: relative;
    cursor: pointer;
    span {
      display: inline-block;
    }
  }
  & th,
  td {
    text-align: center;
    vertical-align: middle !important;
    border: none;
    font-size: 16px;
    font-weight: 800;
    position: relative;
    ${media.mobile} {
      font-size: 12px;
    }
    & span.planned {
      display: inline-block;
      align-items: center;
      justify-content: center;
      font-style: normal;
      color: #000;
      height: 24px;
      font-size: 13px;
      font-weight: 800;
      line-height: 24px;
      text-align: center;
      padding: 0 8px;
    }
  }
  .subinfo {
    color: #788487;
    font-weight: 300;
    max-width: 265px;
  }
  & td.cross {
    background-color: #f5005708;
  }
  & tr {
    th {
      &:first-child {
        text-align: left;
      }
    }
  }
  @media (max-width: 810px) {
    text-align: center;
    & th,
    td {
      padding-left: 0;
    }
  }

  & thead,
  tr {
    background-color: #fff;
  }
  & .icon-check {
    color: #000;
    font-size: 20px;
  }
  & .icon-cross {
    color: #f50057;
    font-size: 25px;
  }
`;

const CompareTo = ({ dataSetBase, LSbase, compareBase, compareTo, icon, title, desc }) => {
  const pages = [
    {
      name: 'LiveSession',
      base: LSbase,
      key: 0,
      icon: <img className="lsIcon" src={ls} alt="favicon" title="LiveSession Favicon" />,
    },
    {
      name: compareTo,
      base: compareBase,
      key: 1,
      icon: <img src={icon} alt="favicon" title={`${compareTo} Favicon`} />,
    },
  ];

  const renderIcon = (item) =>
    item.includes('check') ? (
      <img src={checkmark} alt="check" />
    ) : (
      <>
        {!item.includes('planned') && <img src={cross} alt="cross" />}
        {item.includes('planned') && <span className="planned">Coming soon</span>}
      </>
    );

  return (
    <Section className="pb-168-120-90">
      <h2>{title}</h2>
      <p>{desc}</p>
      <FeaturesTable>
        <thead className="test">
          <tr>
            <th aria-label="emptycol" />
            <th className="plan">
              <div>
                {pages[0].icon}
                <span>LiveSession</span>
              </div>
            </th>
            <th className="plan">
              <div>
                {pages[1].icon} <span>{pages[1].name}</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {dataSetBase.map((item, i) =>
            item.tooltip ? (
              <tr key={item.tooltip}>
                <th scope="row">
                  <Tippy content={item.tooltip} className="custom" placement="right">
                    <span className="more-info">
                      {item.name}{' '}
                      <i>
                        <MoreInfo />
                      </i>
                    </span>
                  </Tippy>
                </th>
                <td>{renderIcon(pages[0].base[i])}</td>
                <td className={pages[1].base[i].includes('cross') ? 'cross' : ''}>
                  {renderIcon(pages[1].base[i])}
                </td>
              </tr>
            ) : (
              <tr key={item.name}>
                <th scope="row">{item.name}</th>
                <td className={[pages[0].base[i].includes('cross') ? 'cross' : ''].join(' ')}>
                  {renderIcon(pages[0].base[i])}
                </td>
                <td className={pages[1].base[i].includes('cross') ? 'cross' : ''}>
                  {renderIcon(pages[1].base[i])}
                </td>
              </tr>
            ),
          )}
        </tbody>
      </FeaturesTable>
    </Section>
  );
};

export default CompareTo;

CompareTo.propTypes = {
  dataSetBase: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      tooltip: PropTypes.string,
    }),
  ).isRequired,
  LSbase: PropTypes.arrayOf(PropTypes.node.isRequired).isRequired,
  compareBase: PropTypes.arrayOf(PropTypes.node.isRequired).isRequired,
  compareTo: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
};
