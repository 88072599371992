import { media } from 'theme/breakpoints';
import styled from 'styled-components';

export const SliderWrapper = styled.div`
  section {
    border: none;
    padding: 0;
  }
  .padding {
    padding: 24px;
    text-align: left;
  }
  .content-container {
    .displayNone,
    .line {
      display: none;
    }
  }
  .headingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    p {
      max-width: 709px;
      padding: 16px 0 56px 0;
      ${media.desktop} {
        padding: 16px 0 24px 0;
      }
    }
  }
`;
