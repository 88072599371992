import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { media } from 'theme/breakpoints';

export const Container = styled.div`
  display: flex;
  grid-column: 2/-2;
  flex-flow: column;
  row-gap: 56px;
  justify-content: center;
  align-items: center;

  .logo {
    width: 160px;
  }

  .link {
      display:  block;
      text-align: center;
      margin: 32px 0 64px;
    }
`

export const Card = styled.section`
`;

export const ProfilePhoto = styled(GatsbyImage)`
  height: 100%;
`;

export const ProfileTitle = styled.div`
  & h2 {
    font-weight: 900;
    font-size: 36px;
    line-height: 60px;
    text-align: center;
    max-width: 1024px;

    ${media.desktop} {
      text-align: center;
    }
    ${media.tablet} {
      font-size: 24px;
      line-height: 36px;
    }
  }
  & div.content-container {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    justify-content: center;
    flex-direction: row;
    align-items: center;

    & .mobile-profile {
      width: 90px;
      margin-right: 36px;
      border-radius: 50%;

      ${media.mobile} {
        width: 80px;
        margin-right: 24px;
      }
    }
    & img {
      max-width: 120px;
    }

    .title, .company {
      font-size: 18px;
      line-height: 1.6;
      margin: 0;
      font-weight: 600;

    }
    .name {
      font-size: 22px;
      line-height: 1.6;
      margin: 0;
      font-weight: 900;

      ${media.mobile} {
        font-size: 16px;
        line-height: normal;
        margin-bottom: 8px;
      }
    }
  }
`;
