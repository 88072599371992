import shopify from 'img/companyLogo/icons/shopify.svg';
import wix from 'img/companyLogo/icons/wix.svg';
import bigcommerce from 'img/companyLogo/icons/bigcommerce.svg';
import prestashop from 'img/companyLogo/icons/prestashop.svg';
import magento from 'img/companyLogo/icons/magento.svg';
import squarespace from 'img/companyLogo/icons/squarespace.svg';
import woocommerce from 'img/companyLogo/icons/woocommerce.svg';
import ecwid from 'img/companyLogo/icons/ecwid.svg';
import opencart from 'img/companyLogo/icons/opencart.svg';
// TOOLSTACK
import analytics from 'img/companyLogo/icons/analytics.svg';
import zendesk from 'img/companyLogo/icons/zendesk-logo.svg';
import mixpanel from 'img/companyLogo/icons/mixpanel-logo.svg';
import intercom from 'img/companyLogo/icons/intercom.svg';
import helpscout from 'img/companyLogo/icons/helpscout.svg';
import drift from 'img/companyLogo/icons/drift.svg';
import survicate from 'img/companyLogo/icons/survicate.svg';
import heap from 'img/companyLogo/icons/heap-logo.svg';
import sentry from 'img/companyLogo/icons/sentry-logo.svg';
import bugsnag from 'img/companyLogo/icons/bugsnag.svg';
import segment from 'img/companyLogo/icons/segment.svg';
import livechat from 'img/companyLogo/icons/livechat-logo.svg';

export const platforms = [
  {
    img: prestashop,
    alt: 'PrestaShop',
  },
  {
    img: magento,
    alt: 'Magento',
  },
  {
    img: shopify,
    alt: 'Shopify',
    link: '/integrations/shopify/',
  },

  {
    img: bigcommerce,
    alt: 'BigCommerce',
    link: '/integrations/bigcommerce/',
  },
  {
    img: squarespace,
    alt: 'Squarespace',
  },
  {
    img: woocommerce,
    alt: 'WooCommerce',
  },
  {
    img: wix,
    alt: 'Wix',
    link: '/integrations/wix/',
  },
  {
    img: ecwid,
    alt: 'Ecwid',
  },
  {
    img: opencart,
    alt: 'Open Cart',
  },
];
export const toolsStack = [
  {
    img: analytics,
    alt: 'Analytics',
    link: '/integrations/google-analytics/',
  },
  {
    img: zendesk,
    alt: 'Zendesk',
    link: '/integrations/zendesk/',
  },
  {
    img: mixpanel,
    alt: 'Mixpanel',
    link: '/integrations/mixpanel/',
  },
  {
    img: intercom,
    alt: 'Intercom',
    link: '/integrations/intercom/',
  },
  {
    img: helpscout,
    alt: 'Help scout',
    link: '/integrations/help-scout/',
  },
  {
    img: drift,
    alt: 'Drift',
    link: '/integrations/drift/',
  },
  {
    img: survicate,
    alt: 'Survicate',
    link: '/integrations/survicate/',
  },
  {
    img: heap,
    alt: 'Heap',
    link: '/integrations/heap/',
  },
  {
    img: sentry,
    alt: 'Sentry',
    link: '/integrations/sentry/',
  },
  {
    img: bugsnag,
    alt: 'Bugsnag',
    link: '/integrations/bugsnag/',
  },
  {
    img: segment,
    alt: 'Segment',
    link: '/integrations/segment/',
  },
  {
    img: livechat,
    alt: 'LiveChat',
    link: '/integrations/livechat/',
  },
];
