import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const Intercom = styled.section`
  border-top: 1px solid #e4e5e8;
  padding: 168px 0 0 0;
  ${media.mobile} {
    padding-bottom: 0;
  }
  & h2 {
    font-size: 36px;
    text-align: center;
    margin: 0 auto 70px;
    ${media.tablet} {
      font-size: 32px;
    }
  }
  & .banner {
    background: #f5f5f5;
    border-radius: 4px;
    padding: 0 0 0 64px;
    display: flex;
    position: relative;
    align-items: center;
    height: 516px;
    & .gatsby-image-wrapper {
      flex-basis: 55%;
      width: 100%;
      align-self: flex-end;
    }
    & .banner-content {
      flex-basis: 47%;
      & img {
        max-width: 157px;
        margin-bottom: 32px;
      }
      & h3 {
        font-size: 28px;
        line-height: 40px;
        margin-bottom: 24px;
        max-width: 90%;
      }
      & p {
        margin-bottom: 40px;
        max-width: 93%;
      }
      & .banner-cta--buttons {
        display: flex;
        align-items: center;
        & a:last-of-type {
          margin-left: 32px;
        }
      }
    }
  }
  ${media.desktopWide} {
    & .banner {
      padding: 48px;
      flex-direction: column;
      height: auto;
      & .gatsby-image-wrapper {
        display: none;
      }
    }
  }
  ${media.tablet} {
    padding-top: 120px;
    & .banner {
      & .banner-cta--buttons {
        & a {
          font-size: 16px;
        }
      }
    }
  }
  ${media.mobile} {
    & .banner {
      padding: 48px 24px;
    }
    & .banner .banner-cta--buttons a:first-of-type {
      display: none;
      margin-left: 32px;
    }
    & .banner .banner-content .banner-cta--buttons a:last-of-type {
      margin-left: 0;
    }
  }
`;

export const ImgWrapper = styled.div`
  width: 517px;
  margin: auto auto 0 auto;
`;
