import styled from 'styled-components';
import { Link } from 'gatsby';
import { StyledLink } from 'components/ui/link';
import { media } from 'theme/breakpoints';

export const ImageBox = styled.div`
  border-radius: 4px;
  height: 204px;
  overflow: hidden;
  ${media.desktop} {
    height: fit-content;
  }
  & .gatsby-image-wrapper {
    transition: transform 0.2s ease;
  }
`;

export const Card = styled(Link)`
  display: flex;
  flex-direction: column;
  opacity: ${(props) => (props.comingSoon ? 0.5 : 1)};
  &:hover {
    text-decoration: none;
    ${StyledLink} {
      text-decoration: underline;
      color: #0a4ed6;
      &::after {
        transform: translateX(8px);
      }
    }
    & .gatsby-image-wrapper {
      transform: scale(1.08);
    }
  }
  & h3 {
    font-size: 24px;
    line-height: 34px;
    margin-top: 24px;
    color: #000;
    ${media.mobile} {
      margin-top: 16px;
    }
  }
  & p {
    font-size: 16px;
    line-height: 26px;
    margin: 8px 0 24px;
    color: #000;
    &:last-child {
      margin: 0;
    }
    &.comingsoon {
      margin: 0;
      font-weight: 800;
      font-size: 18px;
      line-height: 24px;
    }
  }
  ${StyledLink} {
    color: #0a4ed6;
  }
`;
