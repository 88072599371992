import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const BackgroundContainer = styled.div`
  width: 100%;
  background-color: #f5f5f5;
`;

export const PersonalDataSection = styled.section`
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 80px 0px;
  display: flex;
  position: relative;
  align-items: center;
  a {
    font-size: 16px;
  }
  & .content {
    margin-right: 100px;
    & h2 {
      margin-bottom: 18px;
    }
    & p {
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 48px;
      padding-right: 20px;
      ${media.desktop} {
        margin-bottom: 32px;
        padding-right: 0;
      }
    }
  }
  & div {
    flex: 1;
  }
  ${media.desktop} {
    & .main-image {
      display: none;
    }
  }
  ${media.tablet} {
    & .content {
      margin-right: 0;
    }
    margin: 0;
  }
  ${media.mobile} {
    padding: 24px;
    & .content {
      & h2 {
        font-size: 24px;
        line-height: 36px;
        margin: 0px 0px 16px;
      }
      & p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 18px;
      }
      & a {
        line-height: 24px;
      }
    }
  }
`;
export const ImageContainer = styled.div`
  display: flex;
  margin-top: 32px;
  & a {
    display: block;
    cursor: pointer;
  }
  & a:first-child {
    margin-right: 24px;
  }
  ${media.mobile} {
    display: none;
  }
`;
