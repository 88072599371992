import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import star from 'img/ui/star.svg';
import starBlack from 'img/ui/starBlack.svg';
import starWhite from 'img/ui/starWhite.png';
import halfStarWhite from 'img/ui/halfStarWhite.png';
import halfStar from 'img/ui/halfStar.svg';
import halfStarBlack from 'img/ui/halfStarBlack.svg';
import { customers } from 'data/pricing/customers';

export const Rate = styled.section`
  max-width: 700px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: ${(props) => (props.black ? 'repeat(3, min-content)' : 'repeat(3, 1fr)')};
  grid-template-rows: repeat(2, 1fr);
  grid-row-gap: ${(props) => (props.black ? '2rem' : '4rem')};
  grid-column-gap: ${(props) => (props.heroPage ? '80px' : '40px')};
  align-items: center;
  padding: ${(props) => (props.heroPage ? '90px 0 0' : 'inherit')};
  justify-content: center;
  ${media.tablet} {
    grid-template-columns: ${(props) => (props.black ? 'repeat(3, 1fr)' : 'repeat(3, 1fr)')};
  }
  ${media.mobile} {
    padding: 1.5rem 0 0;
    grid-row-gap: 32px;
    grid-column-gap: 0;
  }
  h2 {
    grid-column: 1 / 4;
    margin-bottom: 0;
    justify-self: center;
    align-self: center;
    font-weight: 800;
    font-size: ${(props) => (props.black ? '36px' : '32px')};
    display: ${(props) => (props.heroPage ? 'none' : 'block')};
    text-align: center;
    width: ${(props) => (props.black ? '150%' : 'auto')};
    ${media.tablet} {
      width: auto;
    }
    ${media.mobile} {
      line-height: ${(props) => (props.black ? '44px' : '34px')};
      font-size: ${(props) => (props.black ? '32px' : '22px')};
      text-align: center;
    }
  }
`;

export const Customer = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  transition: 0.2s;
  border-radius: 10px;
  cursor: pointer;
  color: #000;
  padding: ${(props) => (props.heroPage ? '0' : '26px')};
  width: ${(props) => (props.heroPage ? 'fit-content' : '100%')};
  &:hover {
    box-shadow: 0px 0px 8px #00000033;
    color: #000;
    text-decoration: none;
  }
  ${media.mobile} {
    height: 50px;
    justify-self: center;
    padding: 40px 10px;
  }

  img {
    max-height: 29px;
    max-width: ${(props) => (props.black ? '110px' : '100%')};
    height: ${(props) => (props.black ? '24px' : '200px')};
    ${media.mobile} {
      max-height: ${(props) => (props.black ? '20px' : '17px')};
    }
  }
`;

export const Rating = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${(props) => (props.heroPage ? '11px' : '16px')};
  border-radius: 20px;
  ${media.mobile} {
    display: block;
    margin-top: 0;
    box-shadow: none;
    border: none;
    border-radius: 0;
    padding: 0;
  }
  .stars {
    display: flex;
    justify-content: center;
    align-items: center;
    ${media.mobile} {
      margin-top: 10px;
    }
    img {
      margin-right: 4px;
      max-width: ${(props) => (props.black ? '18px' : '14px')};
      width: ${(props) => (props.black ? ' 14.5px' : 'auto')};
      ${media.mobile} {
        max-width: 10px;
        margin-right: 2px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  p {
    font-size: 14px;
    margin: 0;
    margin-left: 12px;
    font-weight: 400;
    line-height: normal;
    ${media.mobile} {
      text-align: center;
      margin-left: 0;
      margin-top: 6px;
      font-size: 12px;
    }
    span {
      font-weight: 800;
      ${media.mobile} {
        font-size: 12px;
      }
    }
  }
`;

export const renderStars = (mark, white) => {
  const arrStars = [];
  if (mark < 5) {
    const roundMark = Math.floor(mark);
    const countOfHalfStars = 5 - roundMark;
    for (let i = 0; i < roundMark; i += 1) {
      // arrStars.push(<img src={starBlack} alt="star" key={`${star}+${i}`} />);
      arrStars.push(
        <img src={white === 'white' ? starWhite : starBlack} alt="star" key={`${star}+${i}`} />,
      );
    }
    for (let i = 0; i < countOfHalfStars; i += 1) {
      arrStars.push(
        <img
          src={white === 'white' ? halfStarWhite : halfStarBlack}
          alt="halfStar"
          key={`${halfStar}+${i}`}
        />,
      );
    }
  } else {
    for (let i = 0; i < mark; i += 1) {
      arrStars.push(<img src={starBlack} alt="star" key={`${star}+${i}`} />);
    }
  }
  return arrStars;
};

const CustomersRate = ({ black, title, deleteTitle, style, addStars }) => (
  <Rate title={title} deleteTitle={deleteTitle} black={black} style={style}>
    <h2>{deleteTitle ? null : title || 'What customers say about LiveSession?'}</h2>
    {customers.map(({ logoBlack, link, key, mark }) => (
      <Customer href={link} target="_blank" rel="noopener noreferrer" key={key} black={black}>
        <img src={logoBlack} alt="Customer logo" title={title} />
        <Rating black={black}>
          <div className="stars">{renderStars(mark)}</div>
          {black ? null : (
            <p>
              <span>{mark}</span>
              /5
            </p>
          )}
          {addStars && (
            <p>
              <span>{mark}</span>
              /5
            </p>
          )}
        </Rating>
      </Customer>
    ))}
  </Rate>
);

export default CustomersRate;

CustomersRate.propTypes = {
  title: PropTypes.string,
  deleteTitle: PropTypes.bool,
  black: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.object]),
  addStars: PropTypes.bool,
};

CustomersRate.defaultProps = {
  title: null,
  deleteTitle: false,
  black: false,
  style: undefined,
  addStars: false,
};

const ExtendRate = styled(Rate)`
  grid-column-gap: 40px;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(3, min-content);
`;

const ExtendCustomer = styled(Customer)`
  padding: 16px;
  & img {
    max-height: 24px;
    height: 24px;
  }
  ${media.mobile} {
    height: auto;
  }
`;

const ExtendRating = styled(Rating)`
  margin-top: 8px;
  .stars {
    img {
      max-width: 10px;
    }
  }
  & p {
    font-size: 12px;
  }
  ${media.mobile} {
    & .stars {
      margin-top: 4px;
    }
    margin-top: 0;
  }
`;

export const CustomersRateAds = ({ black, style, deleteTitle }) => (
  <ExtendRate deleteTitle={deleteTitle} black={black} style={style}>
    {customers.map(({ logoBlack, link, key, mark, title }) => (
      <ExtendCustomer href={link} target="_blank" rel="noopener noreferrer" key={key} black={black}>
        <img src={logoBlack} alt="Customer logo" title={title} />
        <ExtendRating black={black}>
          <div className="stars">{renderStars(mark)}</div>
          {black ? null : (
            <p>
              <span>{mark}</span>
              /5
            </p>
          )}
        </ExtendRating>
      </ExtendCustomer>
    ))}
  </ExtendRate>
);

CustomersRateAds.propTypes = {
  deleteTitle: PropTypes.bool,
  black: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.object]),
};

CustomersRateAds.defaultProps = {
  deleteTitle: false,
  black: false,
  style: undefined,
};
