import React from 'react';
import { renderStars } from 'components/CustomersRate';
import { customersWhite } from 'data/pricing/customers';
import Link from 'components/ui/link';
import SliderAboutAs from 'components/index/sliderAboutAs';
import StartUsing from 'components/ui/startUsing';
import * as Styled from './styled';

const AboutUs = () => (
  <>
    <div className="waveBlack">
      <Styled.WrapperBackground>
        <h2 className="container headerAbout">What customers say about us?</h2>
        <SliderAboutAs />
        <Styled.Wrapper className="container">
          <Styled.RateBox>
            {customersWhite.map(({ logoBlack, mark, link, title }) => (
              <Styled.Rate key={title}>
                <div className="content-box">
                  <img
                    src={logoBlack}
                    alt="logo"
                    title={title}
                    className="logo"
                    style={{ height: 28 }}
                  />
                  <div className="stars">
                    {renderStars(mark, 'white')}{' '}
                    <p>
                      <strong>{mark}</strong>/5
                    </p>
                  </div>
                </div>

                <Link
                  title={title}
                  yellow
                  withArrow
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read more now
                </Link>
              </Styled.Rate>
            ))}
          </Styled.RateBox>
          <StartUsing />
        </Styled.Wrapper>
      </Styled.WrapperBackground>
    </div>
    <Styled.PaddingBottom />
  </>
);

export default AboutUs;
