import withIntegrationCardContext from 'hoc/withIntegrationCardContext';

const Gatsby = () => (
  <>
    <div className="section">
      <h2>Overview</h2>
      <p>
        Gatsby is a React-based framework for building faster websites and web apps. You can use a
        dedicated plugin to add the LiveSession tracking code to your Gatsby website.
      </p>
    </div>
    <div className="section">
      <h2>Benefits</h2>
      <ul className="checkmarks">
        <li>Faster installation</li>
        <li>Optimized code</li>
        <li>Continuing LiveSession support</li>
      </ul>
    </div>
    <h2>How to install</h2>
    <p>
      Use a Gatsby plugin, as described in our <a href="/help/gatsby-integration/">help center</a>.
    </p>
  </>
);

export default withIntegrationCardContext(Gatsby);
