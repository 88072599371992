import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const Box = styled.div`
  padding: ${(props) => (props.lastSection ? '0 40px 40px' : '40px')};
  flex-basis: 50%;
  border: ${(props) => (props.lastSection ? 'none' : '1px solid #e4e5e8}')};
  border-radius: 4px;
  max-width: ${(props) => (props.lastSection ? '800px' : null)};
  margin: ${(props) => (props.lastSection ? '0 auto' : null)};
  height: min-content;
  ${media.tablet} {
    padding: ${(props) => (props.lastSection ? '20px' : '30px')};
  }
  h2 {
    font-weight: 800;
    margin-bottom: ${(props) => (props.lastSection ? '40px' : '18px')};
    font-size: ${(props) => (props.lastSection ? '40px' : '32px')};
    line-height: 50px;
    ${media.tablet} {
      font-size: 22px;
    }
  }
  p {
    margin-bottom: ${(props) => (props.lastSection ? '24px' : '30px')};
    font-size: ${(props) => (props.lastSection ? '18px' : '16px')};
    line-height: ${(props) => (props.lastSection ? '30px' : '26px')};
  }
  ul {
    list-style: none;
    padding-left: 20px;
    margin-bottom: 0;
    li {
      display: flex;
      p {
        margin-bottom: ${(props) => (props.lastSection ? '24px' : '20px')};
      }
      &::before {
        content: '• ';
        color: #0a4ed6;
        font-weight: 900;
        display: inline-block;
        width: 1em;
        margin-left: -20px;
        margin-right: 20px;
        ${media.tablet} {
          margin-right: 10px;
        }
      }
      &:last-child p {
        margin-bottom: 0;
      }
    }
  }
`;
