import Layout from 'layout';
import Link from 'components/ui/link';
import Button from 'components/ui/button';

const ContentSpecialist = () => (
  <Layout
    metaTitle="Go Developer"
    canonical="/jobs/go-developer/"
    metaDescription="We are looking for Go Developer - join us if you are looking for new challenges!"
  >
    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 text-left p-4 m-auto">
            <h1 className="mb-5 text-center">Go Developer</h1>
            <p className="mb-3">
              We are looking for a talented Go Developer to build and mantain our backend services
              infrastructure. This is a permanent, full time remote job.
            </p>
            <p className="mt-4">Our future colleague: </p>
            <ul className="">
              <li>
                has 1+ year of commercial experience with <strong>Go (Golang)</strong>
              </li>
              <li>understands concurrency concept and patterns</li>
              <li>
                has experience with SQL (<strong>MySQL</strong>) and noSQL (
                <strong>ElasticSearch, Redis, Memcached</strong>) databases
              </li>
              <li>has experience with gRPC, REST, Git (Github, Bitbucket)</li>
              <li>has experience with CI/CD</li>
              <li>
                experience with <strong>Google Cloud</strong> (Cloud Build, Pub/Sub, Scheduler,
                Stackdrive, CloudSQL), Node.js and <strong>Kubernetes</strong> is a plus
              </li>
              <li>has basic knowledge how to use unix shell</li>
              <li>is passionate about technologies</li>
              <li>has good English communication skills</li>
              <li>has ability and desire to work in a fast-growing startup environment</li>
            </ul>
            <p className="mt-4">Benefits: </p>
            <ul>
              <li>salary tailored to your experience, skills, and performance</li>
              <li>fully remote with flexible working hours</li>
              <li>startup atmosphere</li>
            </ul>
            <p className="mt-4">
              Send us your CV and a few words about yourself to:{' '}
              <Link href="mailto:jobs@livesession.io">jobs@livesession.io</Link>
            </p>
            <p
              style={{
                margin: '3rem',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button href="mailto:jobs@livesession.io">Apply</Button>
            </p>

            <p className="mt-5" style={{ fontSize: '0.8rem', color: 'grey' }}>
              By sending a recruitment application to LiveSession Sp. z o.o. with headquarters in
              Wroclaw, at gen. Wladyslawa Sikorskiego 3/2, 56-659, Wroclaw, entered in the register
              of entrepreneurs of the National Court Register under number 0000753134, Tax ID:
              PL8971860301, REGON: 381580380, you agree to the processing of personal data contained
              in the recruitment application by the Company in order to recruit for the position
              indicated in the announcement.
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default ContentSpecialist;
