import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import { GatsbyImage } from 'gatsby-plugin-image';

export const Section = styled.section`
  ${media.mobile} {
    padding-bottom: 60px;
  }
`;

export const Header = styled.div`
  position: relative;
  padding-top: 8rem;
  margin-bottom: 88px;
  text-align: left;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 56px;
  align-items: center;
  width: 100%;
  ${media.desktopWide} {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-gap: 40px;
    padding-bottom: 40px;
  }
  ${media.mobile} {
    margin-bottom: 40px;
    padding-top: 108px;
  }
`;

export const Title = styled.h1`
  margin: 0 0 32px 0;
  line-height: 60px;
  ${media.desktopWide} {
    font-size: 1.8rem;
    margin: 0 0 1rem 0;
    line-height: 1.4;
  }
  @media (max-width: 600px) {
    font-size: 1.6rem;
  }
  @media (max-width: 400px) {
    font-size: 1.5rem;
  }
`;

export const Cover = styled(GatsbyImage)`
  max-width: 584px;
  position: relative;
  z-index: 5;
  ${media.desktopWide} {
    margin: 0 auto;
    max-width: 770px;
  }
  ${media.tablet} {
    margin: 0 auto;
    max-width: 100%;
  }
`;

export const ContentContainer = styled.article`
  display: block;
  background-color: #f5f5f5;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 40px;
    background-color: #fff;
    bottom: 0;
    left: 0;
    z-index: 1;
    ${media.desktopWide} {
      display: none;
    }
  }
`;

export const ArticleFooter = styled.section`
  border-top: 1px solid #eaebec;
  border-bottom: 1px solid #eaebec;
  padding-top: 3rem;
  padding-bottom: 3rem;
  & .bio {
    font-size: 16px;
    line-height: 28px;
    margin-top: 16px;
  }
  .profile-picture {
    margin: 0 auto;
  }
`;

export const TimeToRead = styled.em`
  font-style: normal;
  margin-left: 16px;
  position: relative;
  &::before {
    content: '';
    width: 4px;
    height: 4px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-10px, -25%);
    border-radius: 50%;
    background-color: #000;
    opacity: 0.75;
  }
`;

export const Toc = styled.ol`
  position: sticky;
  top: 130px;
  margin-right: 26px;
  padding-left: 20px;
  ${media.desktop} {
    position: static;
    margin: 0 0 56px 0;
    padding-left: 18px;
  }
  & li {
    font-weight: 700;
    ${media.mobile} {
      color: #0a4ed6;
    }
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    &.active {
      color: #0a4ed6;
      & a {
        color: #0a4ed6;
      }
    }
    & a {
      color: #000;
      font-size: 16px;
      line-height: 24px;
      display: inline;
      transition: none;
      font-weight: 700;
      ${media.mobile} {
        color: #0a4ed6;
      }
    }
  }
`;

export const HeaderContainer = styled.div`
  margin-bottom: 40px;
  ${media.desktopWide} {
    width: 770px;
    margin: 0 auto;
  }
  ${media.tablet} {
    width: auto;
  }
`;

export const TocHeading = styled.h4`
  display: none;
  ${media.desktop} {
    display: block;
    margin-bottom: 32px;
  }
  ${media.mobile} {
    margin-bottom: 24px;
  }
`;

export const ArticleContainer = styled.div`
  & img {
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.16));
    border: 1px solid #f5f5f5;
  }
  & h2 {
    font-weight: 800;
  }
  div.section-container {
    padding-top: 78px;
    margin-top: -78px;
  }
`;
