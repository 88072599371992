import PropTypes from 'prop-types';
import Button from 'components/ui/button';
import styled from 'styled-components';
import { media } from 'theme/breakpoints';

const Section = styled.section`
  border: 2px solid #000000;
  border-radius: 4px;
  padding: 80px;
  margin-bottom: 120px;
  &.without-price {
    & h2 {
      text-align: center;
    }
    & div p {
      text-align: center;
      max-width: 100%;
    }
    & a {
      display: block;
      margin: 0 auto;
      width: min-content;
    }
  }
  ${media.desktop} {
    padding: 40px 80px;
    margin-bottom: 0;
  }
  ${media.tablet} {
    padding: 40px 20px;
  }
  & div {
    & p {
      font-size: 18px;
      line-height: 30px;
      margin: 40px 0 48px;
      max-width: 55%;
      ${media.desktop} {
        text-align: center;
        margin: 20px auto 40px;
        max-width: 100%;
      }
    }
    ${media.desktop} {
      & a {
        margin: 0 auto;
        display: block;
        width: min-content;
      }
    }
  }
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.desktop} {
    flex-direction: column;
    & h2 {
      text-align: center;
      font-size: 28px;
      margin-bottom: 20px;
      line-height: 42px;
    }
  }
  & p.price {
    font-size: 36px;
    line-height: 45px;
    font-weight: 800;
    white-space: nowrap;
    margin: 0 0 0 60px;
    ${media.desktop} {
      margin: 0;
      text-align: center;
      font-size: 20px;
    }
    & span {
      font-size: 120px;
      line-height: normal;
      ${media.desktop} {
        font-size: 90px;
      }
    }
  }
`;

const Banner = ({ withoutPrice, title, desc }) => (
  <Section className={withoutPrice ? 'without-price' : ''}>
    <div>
      <Head>
        {title}
        {!withoutPrice && (
          <p className="price">
            $ <span>5000</span> / year
          </p>
        )}
      </Head>
      <p>{desc}</p>
      <Button secondary signUp sourceID="banner">
        Sign up free
      </Button>
    </div>
  </Section>
);

export default Banner;

Banner.propTypes = {
  withoutPrice: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.object]).isRequired,
  desc: PropTypes.string.isRequired,
};

Banner.defaultProps = {
  withoutPrice: false,
};
