import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const InfoSection = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 80px;
  padding-bottom: 120px;
  & div {
    & h2 {
      margin-bottom: 32px;
      ${media.desktop} {
        font-size: 32px;
        line-height: 40px;
      }
    }
    & h4,
    span {
      margin-top: 32px;
    }

    & p {
      margin: 0;
    }
  }
  ${media.tablet} {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, min-content);
    grid-row-gap: 80px;
    text-align: center;
    padding-bottom: 120px;
  }
`;
