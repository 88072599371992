import { createGlobalStyle } from 'styled-components';
import { media } from 'theme/breakpoints';
import arrowDropdown from 'fonts/arrowMenu/icomoon.woff';
import searchicon from 'fonts/searchbar/icomoon.woff';
import spanVector from 'img/index/vectorSpan.svg';
import upBackground from 'img/index/blackUp.svg';

const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@900&display=swap');
#___gatsby{
overflow-x: ${(props) => (props.blockScrollX ? 'hidden' : 'initial')};
}
.SourceCode{
  h2{
    font-family: 'Source Code Pro', monospace;
    font-weight: 900;
  }
}

body{
  font-weight: 400;
}

 .modal {
    z-index: 9999;
}

h1,h2,h3,h4,h5,h6{
  font-weight: 900;
  margin:0;
  & span{
    font-weight: 900;
  }
  &.line{
    span {
      /* -webkit-text-fill-color: transparent;
      background-image: linear-gradient(347deg, #2196F3, rgb(5 79 208));
      -webkit-background-clip: text;
      background-clip: text; */
      
      position: relative;
      z-index: 1;
      &::after {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 0.3em;
        mask-image: url(${spanVector});
        mask-size: 100% 100%;
        background-color:#F8D047;
        mask-repeat: no-repeat;
        background-position: bottom;
        left: 0;
        bottom: -0.085em;
        z-index: -1;
      }
    }
  }
}


.fillViolet{
  ::after{
    content:"" ;
    background-color:#8864FE !important;
  }
}
.fillBlue{
  ::after{
    content:"" ;
    background-color:#7DFFEE !important;
  }
}

h1, h1.line{
  font-size: 48px;
  line-height: 64px;
  ${media.tablet}{
    font-size: 36px;
    line-height: 48px;
  }
}

h2, h2.line{
  font-size: 36px;
  line-height: 48px;
  ${media.tablet}{
    font-size: 32px;
    line-height: 44px;
  }
}

h3{
  font-size: 32px;
  line-height: 44px;
}

h4{
  font-size: 26px;
  line-height: 38px;
}

h5{
  font-size: 22px;
  line-height: 34px;
}

h6{
  font-size: 18px;
}

p{
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  &.subtitle{
    font-size: 22px;
    line-height: 34px;
    margin-top: 32px;
    margin-bottom: 0;
    ${media.mobile} {
      margin-top: 24px;
      font-size: 20px;
      line-height: 30px;
    }
  }
  & strong{
    font-weight: 700;
  }
}

@font-face {
  font-family: "icomoon";
  src: url(${arrowDropdown}) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'searchicon';
  src: url(${searchicon}) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}



.waveBlack{
  position: relative;
  width: 100%;
  ::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 9px;
    top: -8px;
    left: 0;
    right: 0;
    mask-image:url(${upBackground}) ;
    background-size: auto 30px;
    background-position: top left;
    background-repeat: repeat-x;
    background-color: #171717;
}
  }
  .waveBlackDown{
  position: relative;
  width: 100%;
  ::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 9px;
    bottom: -8px;
    left: 0;
    right: 0;
    transform: rotate(180deg);
    mask-image:url(${upBackground});
    background-size: auto 30px;
    background-position: top left;
    background-repeat: repeat-x;
    background-color: #171717;
}
  }

  .wavePurple{
    position: relative;
    width: 100%;
    ::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 9px;
      top: -8px;
      left: 0;
      right: 0;
      mask-image:url(${upBackground}) ;
      background-size: auto 30px;
      background-position: top left;
      background-repeat: repeat-x;
      background-color: #F4F0FF;
  }
    }
    .wavePurpleDown{
    position: relative;
    width: 100%;
    ::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 9px;
      bottom: -8px;
      left: 0;
      right: 0;
      transform: rotate(180deg);
      mask-image:url(${upBackground});
      background-size: auto 30px;
      background-position: top left;
      background-repeat: repeat-x;
      background-color: #F4F0FF;
  }
    }

  .waveGray{
    position: relative;
  width: 100%;
  ::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 8px;
    top: -8px;
    left: 0;
    right: 0;
    mask-image:url(${upBackground}) ;
    background-size: auto 30px;
    background-position: top left;
    background-repeat: repeat-x;
    background-color: #f5f5f5;
  }
}
.waveGrayBottom{
    position: relative;
  width: 100%;
  ::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 8px;
    bottom: -7px;
    transform: rotate(180deg);
    left: 0;
    right: 0;
    mask-image:url(${upBackground}) ;
    background-size: auto 30px;
    background-position: top left;
    background-repeat: repeat-x;
    background-color: #f5f5f5;
  }
}

.separator{
  width: 100%;
  height: 0.5px;
  background-color:#e4e5e8;
}

.commingPadding {
  background-color: red;
    padding-top: 0;
    ${media.desktop} {
      padding-top: 120px;
    }
    ${media.mobile} {
      padding-top: 90px;
    }
  }
`;

export default GlobalStyle;
