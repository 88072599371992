import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import * as Styled from './styled';

const DevTool = ({ reverse, heading, firstParagraph, secondParagraph, image }) => (
  <div className="container">
    <Styled.Section reverse={reverse}>
      <GatsbyImage
        className="transform"
        image={image.childImageSharp.gatsbyImageData}
        alt={heading}
      />
      <div className="content-box">
        <h2 className="line">{heading}</h2>
        <p className="firstParagraph">{firstParagraph}</p>
        <p>{secondParagraph}</p>
      </div>
    </Styled.Section>
  </div>
);
export default DevTool;
DevTool.propTypes = {
  reverse: PropTypes.bool,
  heading: PropTypes.string.isRequired,
  firstParagraph: PropTypes.string.isRequired,
  secondParagraph: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};
DevTool.defaultProps = {
  reverse: false,
};
