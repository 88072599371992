import fundraise from '../img/companyLogo/fundraiseup.svg';
import databox from '../img/companyLogo/databox-svg.svg';
import kissflow from '../img/companyLogo/kissflow.svg';
import chance from '../img/companyLogo/chance.svg';
import livereacting from '../img/companyLogo/livereacting.svg';
import trail from '../img/companyLogo/trail.svg';
import earthtones from '../img/companyLogo/earthtones.svg';
import midigator from '../img/companyLogo/midigator.svg';
import hypestHive from '../img/companyLogo/hypestHive.svg';
import vitreo from '../img/companyLogo/vitreo.svg';
import yapsody from '../img/companyLogo/yapsody.svg';
import apploi from '../img/companyLogo/apploi.svg';
import ing from '../img/companyLogo/ing.svg';
import logicalplan from '../img/companyLogo/logicalplan.svg';
import spySerp from '../img/companyLogo/spyserp.svg';
import talentLyft from '../img/companyLogo/talentLyft.svg';
import currenxie from '../img/companyLogo/currenxie.svg';
import renmoney from '../img/companyLogo/renmoney.svg';
import scalablePress from '../img/companyLogo/scalablePress.svg';
import rocketlink from '../img/companyLogo/rocketlink.svg';

export const useLogoCompanies = () => ({
  fundraise,
  databox,
  kissflow,
  livereacting,
  chance,
  yapsody,
  trail,
  midigator,
  hypestHive,
  vitreo,
  earthtones,
  apploi,
  ing,
  spySerp,
  talentLyft,
  currenxie,
  renmoney,
  logicalplan,
  scalablePress,
  rocketlink,
});
