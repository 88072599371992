import React from 'react';
import { media } from 'theme/breakpoints';
import styled from 'styled-components';
import Layout from 'layout';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import SignUpForm from 'components/ui/signupForm';
import DevTool from 'components/devTools';
import ToolStack from 'components/newCustomers/toolStack';
import StartUsingLS from 'components/ui/startUsing';

const HeroWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding-top: 168px;
  color: #fff;
  ${media.mobile} {
    padding-top: 120px;
  }
  h1 {
    font-family: 'Source Code Pro', monospace;
    font-weight: 900;
    text-align: center;
    margin-bottom: 0;
  }
  p {
    text-align: center;
    ::after {
      content: '';
      color: #fff;
    }
  }
  .headerContent {
    padding-top: 40px;
    .analizeText {
      max-width: 650px;
      margin-bottom: 0;
      ${media.desktop} {
        width: 100%;
      }
    }
    p {
      margin: 0 auto;
      display: block;
      text-align: center;
      font-size: 22px;
      margin-bottom: 24px;
      line-height: 32px;
      ${media.desktop} {
        display: inline;
        font-size: 18px;
        line-height: 26px;
      }
    }
  }
  form {
    justify-content: center;
  }
  div {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  button {
    background-color: #f8d047;
    color: black;
    :hover {
      background-color: #f5f3f5;
    }
  }
`;

const BackgroundContainer = styled.div`
  width: 100%;
  background-color: #171717;
`;
const WaveWrapper = styled.div`
  width: 100%;
  background-color: #171717;
  height: calc(643px / 2 + 80px);
  ${media.desktopWide} {
    height: calc(537px / 2 + 80px);
  }
  ${media.desktop} {
    height: calc(399px / 2 + 80px);
  }
  ${media.tablet} {
    height: calc(290px / 2 + 40px);
  }
  ${media.mobile} {
    height: calc(196px / 2 + 40px);
  }
`;
const VideoWrapper = styled.div`
  margin: 0 auto;
  max-width: 1110px;
  text-align: center;
  position: relative;
  margin-top: calc(-642px / 2);
  ${media.desktopWide} {
    margin-top: calc(-537px / 2);
  }
  ${media.desktop} {
    margin-top: calc(-399px / 2);
  }
  ${media.tablet} {
    margin-top: calc(-290px / 2);
  }
  ${media.mobile} {
    margin-top: calc(-196px / 2);
  }
  .replayText {
    max-width: 550px;
    margin: 0 auto;
  }
  h2 {
    font-family: 'Source Code Pro', monospace;
    padding: 80px 0 42px;
    ${media.tablet} {
      padding: 42px 0 24px;
    }
  }
  p {
    font-weight: 700;
  }
  img {
    max-width: 1110px;
    ${media.mobile} {
      width: 340px;
    }
  }
`;
const PageLoaded = styled.div`
  text-align: center;
  p {
    padding: 24px 0 64px;
    font-weight: 500;
    margin-bottom: 0;
    ${media.tablet} {
      padding: 24px 0 40px;
    }
  }
  img {
    width: 500px;
    ${media.tablet} {
      width: 300px;
    }
  }
  .imageWrapper {
    margin: 0 auto;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    height: 570px;
    background-color: #f5f5f5;
    ${media.tablet} {
      height: 350px;
    }
  }
`;

const DevTools = () => {
  const { console, devtools, network } = useStaticQuery(graphql`
    query {
      console: file(relativePath: { eq: "devTools/consoleLogs.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 95)
        }
      }
      devtools: file(relativePath: { eq: "devTools/detailedErrors.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 95)
        }
      }
      network: file(relativePath: { eq: "saas/networkx3.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 95)
        }
      }
    }
  `);
  return (
    <Layout
      hideBorder="#17171"
      btnColor="#F8D047"
      photoColor="reversed"
      metaTitle="Dev Tools by LiveSession"
      canonical="/dev-tools/"
      metaDescription="Analize and fix your bugs faster with LiveSession Dev Tools.
      Track console logs, JavaScript errors and Network requests for every session."
    >
      <BackgroundContainer>
        <HeroWrapper className="container">
          <h1 className="line"> &#47;* Dev Tools by LiveSession *&#47;</h1>
          <div className="headerContent">
            <p className="analizeText">
              Analize and fix your bugs faster with LiveSession Dev Tools.
            </p>
            <p>Track console logs, JavaScript errors and Network requests for every session.</p>
          </div>
          <SignUpForm blackbgc />
        </HeroWrapper>
        <WaveWrapper className="waveBlackDown" />
      </BackgroundContainer>
      <div className="container">
        <VideoWrapper>
          <StaticImage
            src="../img/devTools/hero.png"
            alt="the session in which the Livesession functionalities are presented"
          />
          <h2 className="line">Understand application and customer errors</h2>
          <p className="replayText">
            Replay user’s sessions and stop guessing why bugs happen. Get the technical story of
            user journey.
          </p>
        </VideoWrapper>
      </div>
      <div className="pt-168-120-90 SourceCode ">
        <DevTool
          reverse
          heading="Watch console logs"
          firstParagraph="Replay user’s sessions and stop guessing why bugs happen. Get the technical story of user journey."
          secondParagraph="See console logs, JavaScript errors, and detailed object and discover the what happen during user’s session."
          image={console}
        />
      </div>
      <div className="p-168-120-90 SourceCode">
        <DevTool
          heading="Get detailed JS errors"
          firstParagraph="Get detailed stacktraces and discover the exact line of broken code."
          secondParagraph="Connect external bug-tracking tools and watch user’s session direclty from Sentry, Bugsnag or Errorception."
          image={devtools}
        />
      </div>
      <div className="SourceCode">
        <DevTool
          reverse
          heading="Replay network requests"
          firstParagraph="Check the requests status and find what requests causes errors from your API."
          secondParagraph="Record headers, request and response bodies for individual network requests that took place within a user's session. Control which URLs you want to record and which to discard."
          image={network}
        />
      </div>
      <div className="p-240-168-120-90  SourceCode ">
        <ToolStack Wave />
      </div>
      <PageLoaded className="container SourceCode">
        <h2 className="line">Page speed metrics for every user session</h2>
        <p>
          Find out how quickly pages are loading for yuor customers on every browser, device, or
          network <br />
          connection with page speed metrics.
        </p>
        <div className="imageWrapper">
          <StaticImage
            src="../img/devTools/pageLoaded.png"
            alt="page speed metrics for every user session"
          />
        </div>
      </PageLoaded>
      <div className="mclear mt-168-120-90 mb-168-120-90">
        <StartUsingLS />
      </div>
    </Layout>
  );
};

export default DevTools;
