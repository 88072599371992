import { Container, Card } from 'components/newsletters/cardPreview/styled';
import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const StyledSection = styled.section`
  margin-top: 108px;
  & h1 {
    text-align: center;
  }
  & .subtitle {
    margin: 40px 0 80px;
    font-size: 22px;
    text-align: center;
  }
  ${media.mobile} {
    margin-top: 1rem;
    & .subtitle {
      font-size: 20px;
      margin: 32px 0 80px;
    }
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  margin-bottom: 50px;
  & ${Card}:nth-child(3n) ${Container} svg {
    & .a {
      fill: rgb(159, 252, 239);
    }
  }
  & ${Card}:nth-child(2n) ${Container} svg {
    & .a {
      fill: rgb(130, 102, 246);
    }
  }
  ${media.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${media.mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;
