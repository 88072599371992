import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const Header = styled.header`
  padding: 168px 0 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  ${media.mobile} {
    padding: 120px 15px 0;
  }
`;

export const StyledSection = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 40px;
  padding-bottom: 0;
  padding-top: 88px;
  ${media.desktop} {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-row-gap: 60px;
  }
  ${media.mobile} {
    grid-row-gap: 64px;
    padding-top: 64px;
  }
`;

export const CTASection = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border-top: 1px solid #e4e5e8;
  padding-top: 120px;
  padding-bottom: 68px;
  margin-top: 120px;
  ${media.mobile} {
    padding-top: 60px;
    margin-top: 60px;
  }
  & .subtitle {
    margin-top: 24px;
    margin-bottom: 40px;
  }
`;
