import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import { StyledLink } from 'components/ui/link';

export const Title = styled.h1`
  margin: 0 0 3rem;
  ${media.tablet} {
    margin: 0 0 24px;
  }
`;

export const SubTitle = styled.h3`
  font-size: 24px;
  margin-bottom: 24px;
  font-weight: 800;
`;

export const StyledH4 = styled.h4`
  font-size: 20px;
  margin-bottom: 16px;
  font-weight: 800;
`;

export const Article = styled.section`
  padding: 0;
  margin: 0 auto 24px;
  & .article-content {
    padding-left: 0;
    & ul,
    ol {
      padding: 0 0 0 20px;
    }
    & .gatsby-image-wrapper {
      margin-bottom: 32px;
    }
    & table {
      margin: 0 0 48px;
      width: 100%;
      & thead {
        border-bottom: 1px solid #e4e5e8;
        tr {
          td {
            padding: 24px 15px;
            font-size: 16px;
            font-weight: 800;
            ${media.mobile} {
              padding: 12px 8px;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            padding: 20px 16px 20px 15px;
            font-size: 16px;
            ${media.mobile} {
              padding: 20px 16px 20px 8px;
            }
          }
        }
      }
    }
  }
  ${StyledLink} {
    display: inline;
  }
  ${media.tablet} {
    margin: 132px auto 24px;
    & .article-content {
      padding-left: 15px;
    }
  }
  ${media.mobile} {
    ${StyledLink} {
      font-size: 18px;
    }
  }
`;

export const Related = styled.div`
  padding: 120px 0 0;
  border-top: 1px solid #eaebec;
  margin-top: 90px;
  ${media.tablet} {
    padding: 80px 0 0;
    margin-top: 4rem;
  }
  h4 {
    font-size: 32px;
    font-weight: 800;
    margin-bottom: 0;
    ${media.tablet} {
      font-size: 24px;
      line-height: 42px;
    }
  }
  ul {
    padding: 0;
  }
`;

export const HeaderSection = styled.section`
  width: 100%;
  padding: 0;
  margin: 144px 0 56px;
  max-width: 1200px;
  transform: translateX(5px);
  ${media.tablet} {
    display: none;
  }
  .breadcrumbs {
    grid-area: breadcrumbs;
    display: flex;
    align-items: center;
    & img {
      margin: 0 16px;
      ${media.mobile} {
        max-width: 8px;
      }
    }
    & p {
      margin: 0;
      color: #000;
      font-size: 14px;
      line-height: 24px;
      ${media.mobile} {
        line-height: normal;
      }
      & a {
        color: #000;
        font-size: 14px;
        line-height: 24px;
        ${media.mobile} {
          font-size: 12px;
          line-height: 24px;
        }
      }
    }
  }
`;

export const CustomLi = styled.li`
  list-style: none;
  padding: 48px 0;
  border-bottom: 1px solid #e4e5e8;
  ${media.tablet} {
    padding: 24px 0;
  }
  a {
    font-weight: 900;
    font-size: 20px;
    ${media.tablet} {
      font-size: 18px;
      line-height: 28px;
    }
  }
  p {
    font-weight: 500;
    margin: 16px 0 0;
    ${media.tablet} {
      font-size: 14px;
      line-height: 26px;
    }
  }
  &:last-child {
    border-bottom: none;
  }
`;
