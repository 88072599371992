import PropTypes from 'prop-types';
import Button from 'components/ui/button';
import * as Styled from './styled';

const Intro = ({ buttonText }) => (
  <Styled.Section className="text-center">
    <div>
      <div className="signupAndDemo">
        <Button signUp secondary={!buttonText} center sourceID="hero" style={{ fontSize: '18px' }}>
          {buttonText || 'Sign up free'}
        </Button>
      </div>
      <div className="signupAndDemo">
        <Button
          href="/demo/"
          reversed={!buttonText}
          center
          sourceID="hero"
          style={{ fontSize: '18px' }}
        >
          {buttonText || 'Get a demo'}
        </Button>
      </div>
    </div>
  </Styled.Section>
);

export default Intro;

Intro.propTypes = {
  buttonText: PropTypes.string,
};

Intro.defaultProps = {
  buttonText: undefined,
};
