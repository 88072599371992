import PropTypes from 'prop-types';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { CustomersRateAds } from 'components/CustomersRate';
import Button from 'components/ui/button';
import { GatsbyImage } from 'gatsby-plugin-image';

import * as Styled from './styled';

const HeaderSection = ({ title }) => {
  const { camparsionHero } = useStaticQuery(graphql`
    {
      camparsionHero: file(relativePath: { eq: "camparsion/camparsionHero.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);
  return (
    <Styled.Section>
      <Styled.ContentSection>
        <h1 className="line">
          Try <span>LiveSession</span> as a better alternative to {title}
        </h1>
        <p className="subtitle">A better way to analyze customer behaviour on your website.</p>
        <Button signUp secondary sourceID="hero">
          Start Free trial
        </Button>
        <p className="contentTrial">
          Create free 14 days trial or <Link to="/pricing/">check pricing.</Link>
        </p>
        <div className="WrapperCustomers">
          <CustomersRateAds deleteTitle style={{ height: '88px' }} />
        </div>
      </Styled.ContentSection>

      <div className="imgWrapper">
        <GatsbyImage
          image={camparsionHero.childImageSharp.gatsbyImageData}
          alt="session"
          objectFit="contain"
        />
      </div>
    </Styled.Section>
  );
};

export default HeaderSection;

HeaderSection.propTypes = {
  title: PropTypes.string.isRequired,
};
