import optimization from 'img/solutions/icons/optimization.svg';
import tracking from 'img/solutions/icons/tracking.svg';
import testing from 'img/solutions/icons/testing.svg';
import debugging from 'img/solutions/icons/debugging.svg';
import support from 'img/solutions/icons/support.svg';
import marketing from 'img/solutions/icons/marketing.svg';

export const data = [
  {
    image: optimization,
    title: 'Conversion optimization',
    desc: 'Find opportunities for improvement',
    link: '#conversion-optimization',
  },
  {
    image: tracking,
    title: 'User engagement tracking',
    desc: 'Discover what works best',
    link: '#user-engagement-tracking',
  },
  {
    image: testing,
    title: 'Usability testing',
    desc: 'See how users interact with your website',
    link: '#usability-testing',
  },
  {
    image: debugging,
    title: 'Application debugging',
    desc: 'Debug your application faster',
    link: '#application-debugging',
  },
  {
    image: support,
    title: 'Customer support',
    desc: 'Improve your help center',
    link: '#customer-support',
  },
  {
    image: marketing,
    title: 'Performance marketing',
    desc: 'Boost your marketing results',
    link: '#performance-marketing',
  },
];
