import PropTypes from 'prop-types';
import * as Styled from './styled';

const FinalOutcome = ({ children, lastSection }) => (
  <Styled.Box lastSection={lastSection}>{children}</Styled.Box>
);

export default FinalOutcome;

FinalOutcome.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]).isRequired,
  lastSection: PropTypes.bool,
};

FinalOutcome.defaultProps = {
  lastSection: false,
};
