import NewsletterTemplate from 'templates/newsletter';
import { StaticImage } from 'gatsby-plugin-image';
import { Article, TextContent } from 'styles/newsletter';

const Content = () => (
  <table className="content" cellSpacing="0" cellPadding="0" align="center" width="100%">
    <tbody>
      <tr>
        <td>
          <table className="text" cellSpacing="0" cellPadding="0" style={{ width: '100%' }}>
            <tbody>
              <tr>
                <td style={{ padding: '32px 40px 12px' }}>
                  <h1>
                    Hi{' '}
                    <span role="img" aria-label="emoji">
                      &#128075;
                    </span>
                    ,
                  </h1>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '12px 40px' }}>
                  <p style={{ margin: 0, fontSize: '16px', lineHeight: '24px' }}>
                    We hope you’re staying safe and doing well!
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '12px 40px 44px' }}>
                  <p style={{ margin: 0, fontSize: '16px', lineHeight: '24px' }}>
                    We’re doing our best to get the most out of quarantine. Here’s what we’ve
                    released to make your work easier:
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '40px 40px 0', borderTop: '1px solid rgb(228, 229, 232)' }}>
                  <h2 style={{ margin: '0 0 16px 0', fontSize: '22px' }}>Product updates</h2>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '0 40px 12px' }}>
                  <Article>
                    <StaticImage
                      src="../../img/blog/covers/badgebackground.png"
                      alt="badge"
                      title="Product update - LiveSession badge"
                      style={{ borderRadius: 4 }}
                    />
                    <TextContent>
                      <p className="head-2">
                        <strong>LiveSession badge</strong>
                      </p>
                      <p className="desc-2">
                        Add a LiveSession badge to your website’s footer and get 5,000 sessions for
                        free. Sharing the love just got easier!
                      </p>
                      <a
                        href="/badge/"
                        className="with-arrow"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Add a badge and get free sessions
                      </a>
                    </TextContent>
                  </Article>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    padding: '12px 40px 44px',
                    borderBottom: '1px solid rgb(228, 229, 232)',
                  }}
                >
                  <Article>
                    <StaticImage
                      src="../../img/blog/covers/lsreactang.png"
                      alt="sdk"
                      title="Product update - LiveSession SDK"
                      style={{ borderRadius: 4 }}
                    />
                    <TextContent>
                      <p className="head-2">
                        <strong>LiveSession SDK</strong>
                      </p>
                      <p className="desc-2">
                        We’ve released a dedicated package for React and Angular. Working with
                        single-page applications is now much faster.
                      </p>
                      <a
                        href="https://www.npmjs.com/package/@livesession/sdk"
                        className="with-arrow"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Check out LiveSession SDK
                      </a>
                    </TextContent>
                  </Article>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '32px 40px 12px' }}>
                  <Article>
                    <div className="head" style={{ paddingTop: 30 }}>
                      <p className="title">
                        <strong>Blog posts</strong>
                      </p>
                    </div>
                    <StaticImage
                      src="../../img/blog/covers/analyze-session-recordings.png"
                      alt="analyze-session-recordings"
                      title="Blog - How to Analyze Session Recordings (Effectively)"
                      style={{ borderRadius: 4 }}
                    />
                    <TextContent>
                      <p className="head-2">
                        <strong>How to Analyze Session Recordings (Effectively)</strong>
                      </p>
                      <p className="desc-2">You’ve got hours of session recordings – now what?</p>
                      <a
                        href="/blog/analyze-session-recordings/"
                        className="with-arrow"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        See our tips for analyzing session replays
                      </a>
                    </TextContent>
                  </Article>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '32px 40px 12px' }}>
                  <Article>
                    <StaticImage
                      src="../../img/blog/covers/trackingcustomerbehavior.png"
                      alt="tracking-customer-behavior"
                      title="Blog - Why Tracking Customer Behavior Is Important (with expert quotes)"
                      style={{ borderRadius: 4 }}
                    />
                    <TextContent>
                      <p className="head-2">
                        <strong>
                          Why Tracking Customer Behavior Is Important (with expert quotes)
                        </strong>
                      </p>
                      <p className="desc-2">Knowledge is power – but why, exactly?</p>
                      <a
                        href="/blog/tracking-customer-behavior/"
                        className="with-arrow"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Read thoughts from our experts
                      </a>
                    </TextContent>
                  </Article>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '40px 60px 12px 40px' }}>
                  <p style={{ margin: 0, fontSize: '16px', lineHeight: '24px' }}>
                    Any questions? Just reply to this email!
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '45px 40px 32px' }}>
                  <table className="kamil" cellSpacing="0" cellPadding="0">
                    <tbody>
                      <tr style={{ verticalAlign: 'middle' }}>
                        <th>
                          <img
                            src="https://emails.livesession.io/kamil-rounded.png"
                            className="kamil-img"
                            style={{ maxWidth: '56px' }}
                            alt="kamil"
                          />
                        </th>
                        <th style={{ paddingLeft: '16px' }}>
                          <p
                            className="info"
                            style={{
                              margin: 0,
                              textAlign: 'left',
                              fontWeight: 400,
                              lineHeight: '140%',
                            }}
                          >
                            <strong>Kamil</strong> <br />
                            CEO and Founder @ LiveSession
                          </p>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
);

const Newsletter = NewsletterTemplate(Content);

const frontmatter = {
  title: 'LiveSession Newsletter May 2020',
  description: "Read LiveSession's monthly update from May 2020.",
  date: '2020-05-15',
  link: '/newsletters/may-2020/',
};

const NewsletterPage = () => <Newsletter {...frontmatter} />;

export default NewsletterPage;
