import PropTypes from 'prop-types';
import { useLogoCompanies } from 'hooks/useLogoCompanies';
import * as Styled from './styled';

const TrustedBy = ({ deleteText, style, hero, customText, intercomPage, saas }) => {
  const {
    databox,
    kissflow,
    logicalplan,
    fundraise,
    trail,
    hypestHive,
    vitreo,
    scalablePress,
    ing,
    midigator,
    talentLyft,
    currenxie,
    renmoney,
    rocketlink,
    spySerp,
  } = useLogoCompanies();

  return (
    <Styled.Trusted style={style} hero={hero} intercomPage={intercomPage} saas={saas}>
      {!deleteText && customText ? (
        <p className="custom-text">{customText}</p>
      ) : saas ? (
        <p>Those companies build better products with LiveSession</p>
      ) : (
        <p>Trusted by 1000+ teams worldwide</p>
      )}

      {saas ? (
        <Styled.SaasIcon>
          <img src={kissflow} alt="kissflow" title="Kissflow" />
          <img src={databox} alt="databox" title="Databox" />
          <img src={talentLyft} alt="talentlyft" title="TalentLyft" />
          <img src={trail} alt="trail" title="Trail" />
          <img src={ing} alt="Ing bank" title="Ing" />
          <img src={fundraise} alt="Fundraise" title="fundraise" />
        </Styled.SaasIcon>
      ) : (
        <Styled.Icons hero={hero}>
          <>
            <img src={kissflow} alt="kissflow" title="Kissflow" />
            <img src={databox} alt="databox" title="Databox" />
            <img src={talentLyft} alt="talentlyft" title="TalentLyft" />
            <img src={currenxie} alt="currenxie" title="Currenxie" />
            <img src={ing} alt="ing" title="Ing" />
            <img src={trail} alt="trail" title="Trail" />
            <img src={renmoney} alt="renmoney" title="Renmoney" className="last-in-row" />
          </>

          {hero ? null : saas ? null : (
            <>
              <img src={hypestHive} alt="hypestHive" title="Hypest Hive" />
              <img src={logicalplan} alt="logicalplan" title="LogicalPlan" />
              <img src={vitreo} alt="vitreo" title="Vitreo" />
              <img src={spySerp} alt="spySerp" title="SpySerp" />
              <img src={scalablePress} alt="scalablePress" title="Scalable Press" />
              <img src={midigator} alt="midigator" title="Midigator" />
              <img src={rocketlink} alt="rocketlink" title="RocketLink" />
            </>
          )}
        </Styled.Icons>
      )}
    </Styled.Trusted>
  );
};

export default TrustedBy;

TrustedBy.propTypes = {
  deleteText: PropTypes.bool,
  intercomPage: PropTypes.bool,
  hero: PropTypes.bool,
  saas: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  customText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

TrustedBy.defaultProps = {
  deleteText: false,
  intercomPage: false,
  hero: false,
  saas: false,
  style: null,
  customText: null,
};
