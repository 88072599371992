import { media } from 'theme/breakpoints';
import styled from 'styled-components';

export const Container = styled.div``;

export const ExtendHeader = styled.div`
  padding-top: 64px;
  margin: 0;
  text-align: center;
  & h2 {
    font-weight: 800;
    font-size: 36px;
    line-height: 45px;
    margin-top: 80px;
    ${media.tablet} {
      margin-top: 40px;
      font-size: 32px;
      line-height: 40px;
    }
  }
  & p {
    font-size: 22px;
    max-width: 85%;
    margin: 40px auto 0;
    ${media.tablet} {
      margin: 32px auto 0;
      font-size: 18px;
      line-height: 30px;
    }
  }
`;

export const ContentSection = styled.section`
  padding: 0;
`;

export const CustomSection = styled.div`
  margin-top: 48px;
  & div.section-box {
    display: flex;
    align-items: center;
    justify-content: center;
    ${media.desktop} {
      max-width: 80%;
      margin: 0 auto;
      flex-direction: column;
    }
    &:not(:last-child) {
      margin-bottom: 40px;
    }
    & img {
      margin-right: 32px;
      ${media.desktop} {
        margin-right: 0;
        margin: 0 auto 16px;
        display: block;
      }
    }
    & div {
      & h5 {
        margin-bottom: 16px;
      }
      & p {
        margin: 0;
      }
    }
  }
`;
