import React, { useState } from 'react';

import { Popup } from 'react-typeform-embed';
import Layout from 'layout';
import { DropdownItem } from 'reactstrap';
import Button from 'components/ui/button';
import checkmark from 'img/ui/checkmark-black.svg';
import illustration from 'img/startup-program.svg';
import sessionicon from 'img/index/sessionreplayIcon.svg';
import filtersicon from 'img/index/filtersIcon.svg';
import inspecticon from 'img/index/featureCards/badgeInspect.svg';
import arrowDown from 'img/ui/chevron-down.svg';
import { StyledDropdownMenu } from 'styles/pricing';
import logicalplan from 'img/companyLogo/logicalplan.svg';
import spySerp from 'img/companyLogo/spyserp.svg';
import rocketlink from 'img/companyLogo/rocketlink.svg';
import vitreo from 'img/companyLogo/vitreo.svg';
import scalablePress from 'img/companyLogo/scalablePress.svg';
import livereacting from 'img/companyLogo/livereacting.svg';
import {
  HeroSection,
  PricingBanner,
  StyledCustomDropdown,
  ExtendStyledDropdown,
  Price,
  Features,
  Requirements,
  List,
  FeatureCards,
  ApplyBanner,
  ContentBox,
  ImagesGrid,
} from 'styles/earlyStage';

const requirements = [
  'Up to $1M in funding',
  'Less than 2 years old',
  '20 or fewer employees',
  'New or non-paying customer',
];

const StartupProgram = () => {
  const [currency, setCurrency] = useState('usd');
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <Layout
      canonical="/early-stage-program/"
      metaTitle="Early-stage Startup Program"
      metaDescription="Grow your early-stage startup with LiveSession. Save almost $1400 and boost your growth."
    >
      <div className="container">
        <HeroSection>
          <div>
            <h1>Take your early-stage startup to the next level</h1>
            <p>
              Starting your business adventure? Grow faster with LiveSession. Save money with our
              early-stage program.
            </p>
            <Popup id="o6Mura" hideHeaders hideFooter style={{ top: '-100%' }}>
              <Button as="button" style={{ position: 'reative', zIndex: 9999 }}>
                Apply now
              </Button>
            </Popup>
          </div>
          <img src={illustration} alt="Hero Illustration" title="Grow faster with LiveSession" />
        </HeroSection>
        <PricingBanner>
          <StyledCustomDropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
            <ExtendStyledDropdown>
              <p>{currency}</p>
              <img
                src={arrowDown}
                alt="arrow"
                title="arrow down"
                style={{ transform: dropdownOpen && `rotate(180deg)` }}
              />
            </ExtendStyledDropdown>
            <StyledDropdownMenu>
              <DropdownItem onClick={() => setCurrency('usd')}>USD</DropdownItem>
              <DropdownItem onClick={() => setCurrency('eur')}>EUR</DropdownItem>
            </StyledDropdownMenu>
          </StyledCustomDropdown>
          <Price>
            <p>
              <strong> {`${currency === 'usd' ? '$ 1990' : '€ 1990'}`}</strong>
            </p>
            <p>
              <strong>
                <sup>{`${currency === 'usd' ? '$' : '€'}`}</sup>
                {`${currency === 'usd' ? '999' : '999'}`}
                <sub>/ 1st year</sub>
              </strong>
            </p>
          </Price>
          <Features>
            <li>
              <strong>25 000</strong> sessions monthly
            </li>
            <li>
              <strong>20</strong> team members
            </li>
            <li>
              <strong>5</strong> websites
            </li>
          </Features>
        </PricingBanner>
        <Requirements>
          <h2>Early-stage program requirements</h2>
          <List>
            {requirements.map((req) => (
              <li key={req}>
                <img src={checkmark} alt="checkmark" title="Checkmark" />
                <p>{req}</p>
              </li>
            ))}
          </List>
        </Requirements>
        <FeatureCards>
          <div>
            <img src={sessionicon} alt="Sessions" title="Session replays badge" />
            <h3>Learn more about your customers</h3>
            <p>See how users interact with your product.</p>
          </div>
          <div>
            <img src={filtersicon} alt="Filters" title="Filters badge" />
            <h3>Get insights faster</h3>
            <p>Analyzing behavior is easier with advanced filters.</p>
          </div>
          <div>
            <img src={inspecticon} alt="Inspect" title="Inspect mode badge" />
            <h3>Find spots for improvement</h3>
            <p>Make the right changes and boost conversions.</p>
          </div>
        </FeatureCards>
        <ApplyBanner>
          <ContentBox>
            <h2>Apply now</h2>
            <p>Join the early-stage program and grow like our customers.</p>
            <Button as="button">Apply now</Button>
          </ContentBox>
          <ImagesGrid>
            <img src={logicalplan} alt="logicalplan" title="LogicalPlan" />
            <img src={livereacting} alt="livereacting" title="LiveReacting" />
            <img src={vitreo} alt="vitreo" title="Vitreo" />
            <img src={spySerp} alt="spySerp" title="spySerp" />
            <img src={scalablePress} alt="scalablePress" title="Scalable Press" />
            <img src={rocketlink} alt="rocketlink" title="rocketlink" />
          </ImagesGrid>
        </ApplyBanner>
      </div>
    </Layout>
  );
};

export default StartupProgram;
