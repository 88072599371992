import Layout from 'layout';
import { ExtendGridBox } from 'styles/integrations';
import SideBar from 'components/marketplace/sideBar';
import Content from 'components/marketplace/content';
import Banner from 'components/marketplace/banner';
import { frontmatter } from 'data/marketplace/integrations';
import arrow from 'img/ui/arrow_black.svg';
import OwnAppSection from 'components/marketplace/ownAppSection';

const CMS = () => {
  const [mostPopularCard] = frontmatter.items.filter((item) => item.title === 'Wordpress');
  return (
    <Layout
      metaTitle="CMS | Integrations"
      canonical="/integrations/cms/"
      metaDescription="Add LiveSession to your content management system and get access to more interesting data."
    >
      <div className="container">
        <ExtendGridBox>
          <div className="breadcrumbs">
            <p>
              <a href="/integrations/">Integrations</a>
            </p>
            <img src={arrow} alt="arrow" title="Arrow" />
            <p>
              <a href="/integrations/cms/">
                <strong>CMS</strong>
              </a>
            </p>
          </div>
          <SideBar activeCategory="CMS" underlineColor="#F8D047" />
          <Content activeCategory="CMS" hideTitle>
            <Banner
              activeCategory="CMS"
              card={mostPopularCard}
              text="Add LiveSession to your content management system and get access to more interesting data."
              underlineColor="#F8D047"
            />
          </Content>
        </ExtendGridBox>
        <OwnAppSection />
      </div>
    </Layout>
  );
};

export default CMS;
