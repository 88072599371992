import { useStaticQuery, graphql, Link } from 'gatsby';
import Layout from 'layout';
import Header from 'components/customer/header';
import Recommendation from 'components/customer/recommendation';
import StartUsing from 'components/ui/startUsing';
import FinalOutcome from 'components/customer/finalOutcome';
import Banner from 'components/usability-testing/banner';
import {
  HeroImage,
  SectionWithRecommendation,
  DescriptionBox,
  StyledSection,
  StyledImg,
} from 'components/styled/CustomerPage';
import Container from 'components/customer/breadcrumbs';

const Databox = () => {
  const { hero, img1, gvidovic, post } = useStaticQuery(graphql`
    query databoxPhotos {
      img1: file(relativePath: { eq: "databox/1.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 95)
        }
      }
      hero: file(relativePath: { eq: "databox/hero.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 95)
        }
      }
      gvidovic: file(relativePath: { eq: "persons/gasper.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      post: markdownRemark(frontmatter: { company: { eq: "Databox" } }) {
        frontmatter {
          description
          title
          slug
          company
        }
      }
    }
  `);

  const { frontmatter } = post;

  const imgSocial = process.env.GATSBY_HOST + hero.childImageSharp.gatsbyImageData.src;
  const descriptionSocial = frontmatter.description;
  const { title, slug } = frontmatter;

  const extraMetaTags = [
    { property: 'fb:app_id', content: '926720414193529' },
    { property: 'og:type', content: 'article' },
    { property: 'og:url', content: process.env.GATSBY_HOST + slug },
    { property: 'og:title', content: title },
    { property: 'og:site_name', content: 'LiveSession' },
    { name: 'twitter:title', content: title },
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:site', content: '@LiveSessionHQ' },
  ];

  if (imgSocial) {
    extraMetaTags.push({ property: 'og:image', content: imgSocial });
    extraMetaTags.push({
      property: 'og:image:secure_url',
      content: imgSocial,
    });
    extraMetaTags.push({ property: 'og:image:width', content: 1287 });
    extraMetaTags.push({ property: 'og:image:height', content: 685 });
    extraMetaTags.push({ name: 'twitter:image', content: imgSocial });
  }

  if (descriptionSocial) {
    extraMetaTags.push({
      property: 'og:description',
      content: descriptionSocial,
    });
    extraMetaTags.push({
      name: 'twitter:description',
      content: descriptionSocial,
    });
  }

  return (
    <Layout
      metaTitle="How Databox achieved a 223% increase in conversions to paid accounts after switching to LiveSession."
      titleOverride
      metaDescription={frontmatter.description}
      extraTags={extraMetaTags}
      canonical="/customers/databox/"
    >
      <div className="container">
        <Container title="Databox">
          <Header
            title={
              <>
                How Databox achieved a <span>223% increase</span> in conversions to paid accounts
                after switching to LiveSession.{' '}
              </>
            }
            description="The onboarding process streamlined by identifying potential issues."
          />
          <HeroImage
            image={hero.childImageSharp.gatsbyImageData}
            clasName="img-fluid"
            alt="website - hero"
            title="Databox team"
            style={{ maxHeight: 500 }}
          />
          <SectionWithRecommendation>
            <DescriptionBox>
              <p>
                Databox automates reporting, helping companies to monitor, analyze and improve their
                performance in one spot. The software can gather data from many sources and
                visualizes it in a structured way in customizable dashboards. Data can be accessed
                from any device.
              </p>
              <p>
                Since the beginnings of the company, it was clear for Databox that they will need an
                exceptionally reliable session replaying software. To constantly improve the quality
                of their service, they required the ability to quickly find flaws in their app and
                to identify where users are stuck, especially during the onboarding process. Without
                that knowledge, it would be impossible to achieve fast business growth worldwide.
              </p>
              <p>
                Databox started their journey by experimenting with one session recording software
                after another. But… Any solution they tried was not good enough.
              </p>
            </DescriptionBox>
            <FinalOutcome>
              <h2>Key achievements</h2>
              <ul>
                <li>
                  <p>identification of potential issues</p>
                </li>
                <li>
                  <p>prioritization of improvements</p>
                </li>
                <li>
                  <p>bulletproof flow during onboarding</p>
                </li>
                <li>
                  <p>
                    activation Rate <strong>increase of 25%</strong>
                  </p>
                </li>
                <li>
                  <p>
                    <strong>223% increase</strong> in conversions from signup to paid account
                  </p>
                </li>
              </ul>
            </FinalOutcome>
          </SectionWithRecommendation>
          <Recommendation
            recommendation="We had experimented with several solutions, but we weren’t fully satisfied with them. We needed to find the best-in-class session recording software. LiveSession lives up to our high expectations."
            person="Gasper Vidovic"
            job="Director of Product"
            company="Databox"
            url="https://databox.com"
            image={gvidovic.childImageSharp.gatsbyImageData}
          />
          <StyledSection>
            <h2>Choosing the best software</h2>
            <p>
              The ideal application should have been fast, reliable, and easy to search for the
              needed information. The recordings should have been loading quickly. It was also vital
              to easily filter them with a combination of default and user-defined filters, with
              custom properties and events. Another Databox requirement was to observe the
              customer’s journey in the application. It was expected that the software would show
              the layout of the site from the time when user&apos;s session was recorded. In cases
              where the page was changed after a visit, the visualization should include what the
              customer actually saw.
            </p>
            <p>
              LiveSession has provided all desired features. Moreover, the connection to the Databox
              website took only several minutes.{' '}
            </p>
            <p>
              LiveSession offers a one-click installation with{' '}
              <Link to="/integrations/google-tag-manager/">Google Tag Manager</Link>. Another easy
              option is to add a few lines of tracking code to the website.
            </p>
          </StyledSection>
          <Recommendation
            recommendation="Thanks to LiveSession we gained valuable insights about our customers. By observing
            screen recordings, and heatmaps, we have discovered how users interacted with the
            Databox application. This allowed us to work effectively on improvement of their
            onboarding experience."
            person="Gasper Vidovic"
            job="Director of Product"
            company="Databox"
            image={gvidovic.childImageSharp.gatsbyImageData}
          />

          <StyledSection>
            <p>
              {' '}
              Databox also benefited from the automated integration of LiveSession with{' '}
              <Link to="/integrations/intercom/">Intercom</Link>. With our one-click integration,
              Databox could enrich every chat with a link to the user&apos;s session replay. This
              helps to have immediate access to the recording in the moment when the customer
              contacts the support team.
            </p>
            <p>
              Moreover, the Databox can integrate LiveSession with many other applications they use,
              such as <Link to="/integrations/google-analytics/">Google Analytics</Link>,{' '}
              <Link to="/integrations/mixpanel/">Mixpanel</Link>, and{' '}
              <Link to="/integrations/help-scout/">HelpScout</Link>.
            </p>
            <Banner
              title="Take advantage of LiveSession. Click now and try for free!"
              style={{ marginBottom: 0 }}
            />
          </StyledSection>

          <StyledSection>
            <h2>Most compelling features</h2>
            <p>
              Although speed and reliability are the core characteristics of LiveSession, there is
              one more thing that drives customers to our solution. Thanks to our focus on User
              Experience, our User Interface is very intuitive. Databox employees confirmed that it
              has been extremely easy to work with LiveSession.
            </p>
          </StyledSection>
          <Recommendation
            recommendation="Integration has been very quick, and the usage of the software has been very
            intuitive for us."
            person="Gasper Vidovic"
            job="Director of Product"
            company="Databox"
            image={gvidovic.childImageSharp.gatsbyImageData}
          />
          <StyledSection>
            <h2>Business benefits for Databox</h2>
            <StyledImg
              image={img1.childImageSharp.gatsbyImageData}
              alt="Databox app"
              title="Databox business plaform"
              className="img-fluid"
            />
            <h3>Identification of potential issues</h3>
            <p>
              While watching the most substantial sessions, you may notice a pattern in which your
              customers behave. This may give you insights into the way your users think, and
              interact. Then you may eventually conclude that the direction in which you are going
              with the development of your product may not be the optimal one. Having the knowledge
              from qualitative analysis provided by LiveSession, and after investigation of
              identified issues Databox have had a clear vision of what to improve.
            </p>
          </StyledSection>
          <StyledSection>
            <h3>Prioritization of improvements</h3>
            <p>
              The ability to observe how customers use your app gives you the insight to identify
              the most common UX flaws. Working with LiveSession, Databox has compiled a prioritized
              list of flaws, starting from those most inconvenient for users. Focusing the
              development on the most needed fixes quickly resulted in a significant and positive
              impact on Databox User Experience.
            </p>
          </StyledSection>
          <StyledSection>
            <h3>Bulletproof flow during onboarding</h3>
            <p>
              {' '}
              When you lose the opportunity to make a great first impression, you may lose the
              potential customer forever. That is why the tight bulletproof flow during the
              onboarding process was the main concern for Databox.{' '}
            </p>

            <p>
              Having fixed the most upsetting flaws, Databox have significantly enhanced the user
              experience. Such an accomplishment would not be achievable without the support of
              LiveSession. Now, Databox customers are able to intuitively learn how to use the
              application. They have quickly recognized the advantages of the software, and their
              impressions have been very positive.
            </p>
          </StyledSection>
          <StyledSection>
            <h3>Activation Rate Increase of 25%</h3>
            <p>
              Another of Databox’s goals was to increase the users’ motivation for active usage of
              the application after they had signed up. After eliminating all user problems with the
              sign-up and onboarding process, the activation rate has increased. Due to the
              fantastic customer experience, it has grown by 25%.
            </p>
          </StyledSection>
          <StyledSection>
            <h3>Increase in conversions from sign-up to paid accounts (223% increase)</h3>
            <p>
              Although a great customer experience and a frustration-free onboarding process are
              important, the Return of Investment is essential for every company. Signup is not the
              end of the customer acquisition process. Success is achieved when the satisfied users
              are happily willing to pay for the app usage.{' '}
            </p>
            <p>
              The increase in conversions from trial to paid model have been essential to Databox.
              After switching to LiveSession, conversions from free to paid accounts have grown by
              223%.
            </p>
            <Banner title="Are you looking for exceptional results? Try LiveSession." />
          </StyledSection>

          <FinalOutcome lastSection>
            <h2>Final outcome</h2>
            <p>
              Databox is happy with LiveSession, but what’s in it for the business? We’ve got a
              couple of numbers to prove the effectiveness. After fixing a couple of bugs and
              improving UX design, Databox has seen:
            </p>
            <ul>
              <li>
                <p>
                  our activation rate (from signup to activate) grew from <strong>44.8%</strong> to{' '}
                  <strong>56.3%</strong>
                </p>
              </li>
              <li>
                <p>
                  our signup to paid CR (freemium model) grew from <strong>1.16%</strong> to{' '}
                  <strong>3.75%</strong>.
                </p>
              </li>
            </ul>
          </FinalOutcome>
        </Container>
      </div>
      <StartUsing />
    </Layout>
  );
};

export default Databox;
