import NewsletterTemplate from 'templates/newsletter';

const Content = () => (
  <table className="content" cellSpacing="0" cellPadding="0" align="center" width="100%">
    <tbody>
      <tr>
        <td>
          <table className="text" cellSpacing="0" cellPadding="0" style={{ width: '100%' }}>
            <tbody>
              <tr>
                <td style={{ padding: '40px 40px 24px' }}>
                  <p style={{ margin: 0 }}>
                    Hi{' '}
                    <span role="img" aria-label="emoji">
                      &#128075;
                    </span>
                    <br />
                    <br />
                    Pete from LiveSession here. This month we have <strong>something BIG</strong> to
                    share. As many of you requested, we’re introducing:
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '12px 40px' }}>
                  <h1 style={{ margin: 0 }}>
                    <span>The DevTools</span>
                  </h1>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '12px 40px' }}>
                  <img
                    src="https://emails.livesession.io/devtools_newsletter.png"
                    alt="devtools_newsletter"
                    style={{ maxWidth: '100%', height: 'auto', marginTop: '16px' }}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ padding: '12px 40px' }}>
                  <p style={{ margin: 0 }}>
                    Debug and fix bugs like a pro. We can’t promise you won’t have to ask users for
                    screenshots anymore, but we’ve been using this marvelous feature for some time
                    now and there’s one thing I can assure you: it does the job.
                    <br />
                    <br />
                    You can see all console logs in one view or filter them by a severity level:
                    Info, Warn or Error. If you are looking for a specific message, use the search
                    field.
                    <br />
                    <br />
                    DevTools are currently in open beta. We’re planning to launch them for all our
                    users by the end of April. But if you’re anxious to use the LiveSession DevTools
                    right now, you can request early access directly from your Session player:
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '12px 40px' }}>
                  <img
                    src="https://emails.livesession.io/dev_tools_beta_1.png"
                    alt="devtools beta"
                    style={{ maxWidth: '100%', height: 'auto', marginTop: '16px' }}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ padding: '28px 40px' }}>
                  <a
                    href="https://app.livesession.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="go-to-app"
                    style={{
                      display: 'block',
                      margin: '0 auto',
                      backgroundColor: '#0a4ed6',
                      padding: '10px 0',
                      fontWeight: 700,
                      color: '#fff',
                      borderRadius: '4px',
                      width: '350px',
                      textAlign: 'center',
                    }}
                  >
                    Request early access
                  </a>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '12px 0' }}>
                  <div style={{ width: '100%', height: '1px', backgroundColor: '#ddd' }} />
                </td>
              </tr>
              <tr>
                <td style={{ padding: '32px 40px 12px' }}>
                  <h1 style={{ margin: 0 }}>
                    <span>New on our blog</span>
                  </h1>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '12px 40px' }}>
                  <div>
                    <a
                      href="/blog/10-ways-to-increase-urgency-in-your-online-sales/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://emails.livesession.io/202103_blog_1.png"
                        alt="badge"
                        title="Abandoned Carts - Recover Them Creating A Sense Of Urgency"
                        style={{
                          maxWidth: '100%',
                          height: 'auto',
                          marginTop: '16px',
                          borderRadius: '4px',
                        }}
                      />
                    </a>
                    <div style={{ marginTop: 24 }}>
                      <p style={{ fontSize: 20, marginBottom: 12 }}>
                        <strong>Abandoned Carts - Recover Them Creating A Sense Of Urgency</strong>
                      </p>
                      <p style={{ color: '#9b9b9b', marginBottom: 12 }}>
                        The “abandoned cart” is an issue for many online businesses. For those who
                        don’t know, the abandoned cart occurs when a potential customer goes through
                        the process of logging onto a business’s website, choosing a product, adding
                        it to their purchase basket, but doesn’t end up finishing the checkout
                        purchase.
                      </p>
                      <a
                        href="/blog/10-ways-to-increase-urgency-in-your-online-sales/"
                        className="with-arrow"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ fontSize: '16px', color: '#0a4ed6', marginTop: 12 }}
                      >
                        Read more on our blog
                      </a>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '12px 40px' }}>
                  <div>
                    <a
                      href="/blog/not-all-traffic-is-created-equally/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://emails.livesession.io/202103_blog_2.png"
                        alt="badge"
                        title="How To Measure The Importance of Traffic Sources Using UTM's"
                        style={{
                          maxWidth: '100%',
                          height: 'auto',
                          marginTop: '16px',
                          borderRadius: '4px',
                        }}
                      />
                    </a>
                    <div style={{ marginTop: 24 }}>
                      <p style={{ fontSize: 20, marginBottom: 12 }}>
                        <strong>
                          How To Measure The Importance of Traffic Sources Using UTM&apos;s
                        </strong>
                      </p>
                      <p style={{ color: '#9b9b9b', marginBottom: 12 }}>
                        Sources of traffic to a website are the channels through which visitors come
                        to a website. As you know, it is the number of visitors that is one of the
                        main indicators of the success of the project. The more of them, the higher
                        the likelihood of achieving your conversion goal. Of course, not every site
                        can be proud of a steady flow of users. Knowledge of where to effectively
                        attract customers, as well as high-quality analysis of traffic sources, will
                        help to increase the traffic indicator.
                      </p>
                      <a
                        href="/blog/not-all-traffic-is-created-equally/"
                        className="with-arrow"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ fontSize: 16, color: '#0a4ed6', marginTop: 12 }}
                      >
                        Read more on our blog
                      </a>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '12px 40px' }}>
                  <p style={{ margin: 0 }}>
                    As always, I encourage you to contact us by replying to this email if you have
                    any questions. Follow us on{' '}
                    <a href="https://www.facebook.com/LiveSessionIO/">Facebook</a> and{' '}
                    <a href="https://www.linkedin.com/company/livesession/">LinkedIn</a>. Let’s stay
                    in touch!
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '40px 60px 15px 40px' }}>
                  <p style={{ margin: 0, fontSize: '16px', lineHeight: '24px' }}>Best,</p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '15px 40px 32px' }}>
                  <table className="kamil" cellSpacing="0" cellPadding="0">
                    <tbody>
                      <tr style={{ verticalAlign: 'middle' }}>
                        <th>
                          <img
                            src="https://emails.livesession.io/pete_rounded.png"
                            className="kamil-img"
                            style={{ maxWidth: '56px' }}
                            alt="kamil"
                          />
                        </th>
                        <th style={{ paddingLeft: '16px' }}>
                          <p
                            className="info"
                            style={{
                              margin: 0,
                              textAlign: 'left',
                              fontWeight: 400,
                              lineHeight: '140%',
                            }}
                          >
                            <strong>Pete</strong> <br />
                            Product Marketing Manager @ LiveSession
                          </p>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
);

const Newsletter = NewsletterTemplate(Content);

const frontmatter = {
  title: 'LiveSession Newsletter March 2021',
  description: "Read LiveSession's monthly update from March 2021.",
  date: '2021-03-01',
  link: '/newsletters/march-2021/',
};

const NewsletterPage = () => <Newsletter {...frontmatter} />;

export default NewsletterPage;
