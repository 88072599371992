/* eslint-disable react/prop-types */
import React from 'react';
import Link from 'components/ui/link';
import Layout from 'layout';
import StartUsingLS from 'components/ui/startUsing';
import arrow from 'img/ui/arrow_black.svg';
import * as Styled from './styled';
import { SubTitle } from './styled';
import SearchBox from '../searchbox';

const HelpArticle = (Content) =>
  class extends React.Component {
    renderRelatedList = () => {
      const { related } = this.props;
      if (!related || related.length === 0) return false;

      const list = related.map((el) => (
        <Styled.CustomLi key={el.link}>
          <Link href={el.link}>{el.title}</Link>
          {el.description && <p>{el.description}</p>}
        </Styled.CustomLi>
      ));
      return (
        <Styled.Related>
          <h4>Related topics</h4>
          <ul className="col-lg-7">{list}</ul>
        </Styled.Related>
      );
    };

    render() {
      const { metaTitle, metaDescription, titleOverride, canonical, section, nav, title } =
        this.props;
      return (
        <Layout
          metaTitle={metaTitle}
          metaDescription={metaDescription}
          titleOverride={titleOverride}
          canonical={canonical}
        >
          <Styled.Article className="help-article">
            <div className="container">
              <div className="row">
                <Styled.HeaderSection>
                  <div className="breadcrumbs">
                    <p>
                      <Link href="https://help.livesession.io/en/">Help</Link>
                    </p>
                    <img src={arrow} alt="arrow" title="Arrow" />
                    <p>
                      <Link href={`/help/${section.toLowerCase().replace(' ', '-')}/`}>
                        {nav || section}
                      </Link>
                    </p>
                    <img src={arrow} alt="arrow" title="Arrow" />
                    <p>
                      <strong>{title}</strong>
                    </p>
                  </div>
                </Styled.HeaderSection>
              </div>
              <div className="row justify-content-between">
                <div className="col-lg-8 article-content">
                  <Styled.Title>{title}</Styled.Title>
                  <Content {...this.props} />
                </div>
                <SearchBox />
              </div>
              <div className="row">
                <div className="col">{this.renderRelatedList()}</div>
              </div>
            </div>
          </Styled.Article>
          <StartUsingLS />
        </Layout>
      );
    }
  };

export { HelpArticle, SubTitle };

export default HelpArticle;
