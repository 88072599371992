import { useStaticQuery, graphql } from 'gatsby';
import { Post } from 'components/blog/content/styled';
import GuideArticle from 'hoc/guideArticle';
import Code from 'components/help/codeSnippet';
import ImageModal from 'components/ui/image-modal';
// import ArticlePreview from 'components/blog/blogArticlePreview';
import img1 from 'img/tutorials/abtest/1.png';
import img2 from 'img/tutorials/abtest/2.png';
import img3 from 'img/tutorials/abtest/3.png';
import img4 from 'img/tutorials/abtest/4.png';
import img5 from 'img/tutorials/abtest/5.png';
import img6 from 'img/tutorials/abtest/6.png';
import img7 from 'img/tutorials/abtest/7.png';
import img8 from 'img/tutorials/abtest/8.png';
import img9 from 'img/tutorials/abtest/9.png';
import img10 from 'img/tutorials/abtest/10.png';
import img11 from 'img/tutorials/abtest/11.png';
import img12 from 'img/tutorials/abtest/12.png';
import img13 from 'img/tutorials/abtest/13.png';
import img14 from 'img/tutorials/abtest/14.png';
import img15 from 'img/tutorials/abtest/15.png';
import img16 from 'img/tutorials/abtest/16.png';
import img17 from 'img/tutorials/abtest/17.png';
import img18 from 'img/tutorials/abtest/18.png';
import img19 from 'img/tutorials/abtest/19.png';
import img20 from 'img/tutorials/abtest/20.png';
import img21 from 'img/tutorials/abtest/21.png';
import img22 from 'img/tutorials/abtest/22.png';
import img23 from 'img/tutorials/abtest/23.png';
import img24 from 'img/tutorials/abtest/24.png';
import img25 from 'img/tutorials/abtest/25.png';
import img26 from 'img/tutorials/abtest/26.png';
import img27 from 'img/tutorials/abtest/27.png';
import img28 from 'img/tutorials/abtest/28.png';
import img29 from 'img/tutorials/abtest/29.png';
import img30 from 'img/tutorials/abtest/30.png';
import img31 from 'img/tutorials/abtest/31.png';
import img32 from 'img/tutorials/abtest/32.png';
import img33 from 'img/tutorials/abtest/33.png';
import img34 from 'img/tutorials/abtest/34.png';
import { meta } from 'data/tutorials/abtesting';

const Guide = () => (
  <Post>
    <p>
      <strong>
        A/B testing is no longer a niche optimization technique. Did you know that{' '}
        <a
          href="https://www.invespcro.com/blog/the-state-of-ab-testing/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          77% of companies
        </a>{' '}
        run A/B tests on their websites?
      </strong>
    </p>
    <p>
      <strong>
        Despite the volume, only 1 in 8 A/B tests results in significant changes for the business.
        In this guide, we’re going to show you how you can make your test more insightful with
        LiveSession.
      </strong>
    </p>
    <div id="qualitative-data-in-a-b-testing" className="section-container">
      <h2>Qualitative data in A/B testing</h2>
      <p>
        Qualitative data is not an alternative to quantitative findings. It’s actually quite the
        contrary! These two methods are supposed to complement each other and help you gain a deeper
        understanding of user behavior.
      </p>
      <p>
        While numbers will show you what is happening, qualitative insights will help you find the
        reasons why it’s happening.
      </p>
      <p>
        For instance, you might have a landing page with quite a lot of traffic but unsatisfying
        results. While many people visit it, the conversion rates and bounce rates may not meet your
        expectations. Now, what should you do about it?
      </p>
      <p>
        Instead of trying to take a shot in the dark, you can turn to{' '}
        <strong>qualitative data </strong>and see how the users interact with your landing page.
        Perhaps a button is not working, or they just can’t find what they’re looking for?
      </p>
      <p>There is a method that is sure to help you out:</p>
      <h3 id="get-more-context-with-session-replays">Get more context with session replays</h3>

      <p>
        You can easily access this kind of information through <strong>session replays</strong>.
        This qualitative user research method has a range of od advantages:
      </p>
      <ul>
        <li>
          <strong>It’s affordable</strong> – Conducting real-time interviews with your users comes
          with costs that can be hard to cover for some. Session replays, on the other hand, are
          much more budget-friendly. All you need to do is add a piece of code on your website and
          you’re ready to start.{' '}
        </li>

        <li>
          <strong>It happens in the background </strong>– Session replays don’t need any involvement
          from your side. They’re recording in the background and the material is ready for your
          analysis.{' '}
        </li>

        <li>
          <strong>It fights the social desirability bias</strong> – When faced with a researcher,
          the users tend to respond in a way that seems more desirable. In the case of session
          recordings, the users are not aware they’re being watched. Because of this, their behavior
          is more natural and not affected by this phenomenon, known as the social desirability
          bias.
        </li>
      </ul>
      <h3 id="use-qualitative-research-to-design-a-b-tests">
        Use qualitative research to design A/B tests
      </h3>

      <p>
        Qualitative data can suggest you potential solutions to the problem. When you watch how the
        visitors use your website, you start to imagine what can be done to solve the issue. Below
        we’re describing our own case study so you can see exactly how it works!
      </p>
    </div>
    <div id="how-livesession-can-help-with-a-b-testing" className="section-container">
      <h2>How LiveSession can help with A/B testing</h2>

      <p>
        LiveSession is a session recording and qualitative analytics platform. It can also be
        connected with a range of other tools, including Google Optimize, one of the most popular
        solutions for A/B testing.
      </p>
      <p>
        When you connect your Google Optimize A/B test to LiveSession, you will be able to see
        sessions that illustrate how users interacted with different versions of the test. In other
        words, it’s a fast and easy way to enrich{' '}
        <strong>quantitative data with qualitative data</strong>.
      </p>
      <p>Below you’ll find a tutorial on how to connect LiveSession and Google Optimize:</p>
    </div>
    <div id="how-to-connect-livesession-with-google-optimize" className="section-container">
      <h2>How to connect LiveSession with Google Optimize</h2>

      <p>We’ll start with an example so you can easily see how it works.</p>
      <p>
        Let’s say that you want to measure how the button color affects conversion and bounce rate.
      </p>
      <p>
        In our example, we’re going to test two different colors: <strong>green</strong> and{' '}
        <strong>red</strong>. Originally, the “Purchase Now” button on our site was green:
      </p>
      <ImageModal src={img1} alt="Guide to AB testing - 1" title="LiveSession - AB testing" />
      <p>
        The best way to find out is to conduct an <strong>A/B test</strong> with different color
        variants. Here’s where <strong>Google Optimize </strong>comes in:
      </p>
      <p>
        It’s really simple: just go to{' '}
        <a href="https://optimize.google.com/" target="_blank" rel="noopener noreferrer nofollow">
          https://optimize.google.com/
        </a>{' '}
        and create your first experiment:
      </p>
      <ImageModal src={img2} alt="Guide to AB testing - 2" title="LiveSession - AB testing" />
      <p>
        Define the name (1), the URL of the website you’ll be testing (2), and click Create (3):
      </p>
      <ImageModal src={img3} alt="Guide to AB testing - 3" title="LiveSession - AB testing" />
      <p>
        Next, go to the <strong>Targeting and variants </strong>section. Click{' '}
        <strong>Add variant </strong>(1),<strong> </strong>define the name of the variant (2), and
        click <strong>Done </strong>(3):
      </p>
      <ImageModal src={img4} alt="Guide to AB testing - 4" title="LiveSession - AB testing" />
      <p>
        Click <strong>Edit</strong> to set a variant directly on the website:
      </p>
      <ImageModal src={img5} alt="Guide to AB testing - 5" title="LiveSession - AB testing" />
      <p>
        <em>
          TIP: The easiest way to create a variant is with the official Google Optimize extension.{' '}
          <br />
          We’re going to use it in this tutorial.{' '}
        </em>
      </p>
      <ImageModal src={img6} alt="Guide to AB testing - 6" title="LiveSession - AB testing" />
      <p>
        Select the button (1). Find the background-color property (2), change it to red (3) and
        click <strong>Save</strong> (4).
      </p>
      <p>
        In the next section, connect (1) your <strong>Google Analytics </strong>account and choose
        (2) a property for the Google Optimize Container. Remember that it has to be{' '}
        <em>the same property</em> that you’re using on your website.
      </p>
      <ImageModal src={img7} alt="Guide to AB testing - 7" title="LiveSession - AB testing" />
      <p>Create an objective to measure:</p>
      <ImageModal src={img8} alt="Guide to AB testing - 8" title="LiveSession - AB testing" />
      <p>
        It this example, we’re going to measure <strong>Bounces</strong>:
      </p>
      <ImageModal src={img9} alt="Guide to AB testing - 9" title="LiveSession - AB testing" />
      <p>
        Go back to the top and click <strong>Start</strong>:
      </p>
      <ImageModal src={img10} alt="Guide to AB testing - 10" title="LiveSession - AB testing" />
      <p>
        Click <strong>View instructions </strong>to get information about your
        <strong> Container ID</strong>:
      </p>
      <ImageModal src={img11} alt="Guide to AB testing - 11" title="LiveSession - AB testing" />
      <p>Copy the selected ID:</p>
      <ImageModal src={img12} alt="Guide to AB testing - 12" title="LiveSession - AB testing" />
      <p>
        Next, go to <strong>Google Tag Manage</strong>r (
        <a href="https://tagmanager.google.com/" target="_blank" rel="noopener noreferrer nofollow">
          https://tagmanager.google.com/
        </a>
        ), chose a container for the same website, and open the <strong>Tags</strong> section:
      </p>
      <ImageModal src={img13} alt="Guide to AB testing - 13" title="LiveSession - AB testing" />
      <p>
        Click <strong>New </strong>to add a new tag:
      </p>
      <ImageModal src={img14} alt="Guide to AB testing - 14" title="LiveSession - AB testing" />
      <p>
        Find <strong>Google Optimize</strong> on the list:
      </p>
      <ImageModal src={img15} alt="Guide to AB testing - 15" title="LiveSession - AB testing" />
      <p>
        Paste your container ID and connect your <strong>Google Analytics settings</strong> (or
        create new ones) with the same property you’ve connected with{' '}
        <strong>Google Optimizer</strong>. Click <strong>Save</strong>.
      </p>
      <ImageModal src={img16} alt="Guide to AB testing - 16" title="LiveSession - AB testing" />
      <p>
        Go back to <strong>Google Optimize </strong>and copy the Experiment ID from the{' '}
        <strong>Measurement and objective</strong> section:
      </p>
      <ImageModal src={img17} alt="Guide to AB testing - 17" title="LiveSession - AB testing" />
      <p>
        In Google Tag Manager, add another Tag with a <strong>Custom HTML</strong>.
      </p>
      <ImageModal src={img18} alt="Guide to AB testing - 18" title="LiveSession - AB testing" />
      <p>Paste this code:</p>
      <Code style={{ margin: '1rem 0' }}>
        {`<script>
  function gtag() {dataLayer.push(arguments)}
  gtag('event', 'optimize.callback', {
    name: '<EXPERIMENT_ID>',
    callback: function(variant) {
      __ls("setCustomParams", {params: {ab_variant: variant, ab_id: "<EXPERIMENT_ID>"}});
    }
 });
</script>
`}
      </Code>
      <p>
        Paste the copied experiment ID in the <Code inline>{`<EXPERIMENT_ID>`}</Code> field. This is
        a connector between <strong>LiveSession</strong> and <strong>Google Optimize</strong>.
      </p>
      <ImageModal src={img19} alt="Guide to AB testing - 19" title="LiveSession - AB testing" />
      <p>
        Click <strong>Save, </strong>and then <strong>Submit</strong>...
      </p>
      <ImageModal src={img20} alt="Guide to AB testing - 20" title="LiveSession - AB testing" />
      <p>
        ...and <strong>Publish</strong> the changes:
      </p>
      <ImageModal src={img21} alt="Guide to AB testing - 21" title="LiveSession - AB testing" />
      <p>
        In <strong>Google Optimize</strong>, click <strong>Start</strong> to start testing!
      </p>
      <p>
        From now on, your website visitors will see the two different versions of the{' '}
        <strong>Purchase Now</strong> button.
      </p>
      <p>
        Go to LiveSession, and choose the <strong>Custom param</strong> filter:
      </p>
      <ImageModal src={img22} alt="Guide to AB testing - 22" title="LiveSession - AB testing" />
      <p>
        Set the <strong>ab_id </strong>with the Experiment ID value. Then, set the{' '}
        <strong>ab_variant</strong>. In our case, <strong>1</strong> represents the first variant
        with the red button.
      </p>
      <ImageModal src={img23} alt="Guide to AB testing - 23" title="LiveSession - AB testing" />
      <p>
        Save the results as a segment (1). It will appear in the segments section (2). Click at the{' '}
        <strong>ab_variant </strong>filter (3):
      </p>
      <ImageModal src={img24} alt="Guide to AB testing - 24" title="LiveSession - AB testing" />
      <p>
        Change the original version’s <strong>ab_variant</strong> to <strong>0</strong> (1), apply
        filters (2), and click Save as a segment.
      </p>
      <ImageModal src={img25} alt="Guide to AB testing - 25" title="LiveSession - AB testing" />
      <p>
        Use the created segments to compare two groups of users. With <strong>LiveSession, </strong>
        you can precisely check how the behavior varies between different versions. You can always
        preview the properties to see which variant the user has interacted with.
      </p>
      <p>
        What’s more, you can also see the results for the segment in{' '}
        <strong>Google Analytics</strong>. Just create a segment in Google Optimize:
      </p>
      <ImageModal src={img26} alt="Guide to AB testing - 26" title="LiveSession - AB testing" />
      <p>Here’s what it looks like in Google Analytics:</p>
      <ImageModal src={img27} alt="Guide to AB testing - 27" title="LiveSession - AB testing" />
    </div>
    <div
      id="case-study-how-to-analyze-test-results-with-livesession-and-google-optimize"
      className="section-container"
    >
      <h2>Case study: how to analyze test results with LiveSession and Google Optimize</h2>

      <p>
        To show you how our{' '}
        <a href="/integrations/google-optimize/" target="_blank" rel="noopener noreferrer">
          Google Optimize integration
        </a>{' '}
        works in action, we’ve decided to use a real-life example!
      </p>
      <p>
        We’ve set up a little A/B test on our home page. We’ve created two different versions of the
        hero section on our homepage. Each one of them was shown to half of the website’s visitors.
        The 0 variant had only one column, while the 1 version had two:
      </p>
      <ImageModal src={img28} alt="Guide to AB testing - 28" title="LiveSession - AB testing" />
      <ImageModal src={img29} alt="Guide to AB testing - 29" title="LiveSession - AB testing" />
      <h3 id="the-what-google-optimize-test-results">The what: Google Optimize test results</h3>

      <p>Here are our results from Google Optimize:</p>
      <ImageModal src={img30} alt="Guide to AB testing - 30" title="LiveSession - AB testing" />
      <p>
        As you can see, the one-column version performed significantly better than the two-column
        one. The conversion (from visit to signup) was <strong>twice as high</strong> for the
        original version.
      </p>
      <p>
        What’s more, the Optimize analysis comes with actionable statistical data. The{' '}
        <em>Probability to be best </em>section shows you how likely it is that the winning variant
        really is the best one. You can also see the range of conversion rates you can expect for
        both cases.
      </p>
      <p>
        That’s it for the results, but here comes another question – how exactly do people interact
        with these websites? Why is one version better than the other?
      </p>
      <p>LiveSession can help you find the answers.</p>
      <h3 id="the-why-livesession-recordings">The why: LiveSession recordings</h3>

      <p>The recordings of users who took part in the A/B test are saved as a separate segment:</p>
      <ImageModal src={img31} alt="Guide to AB testing - 31" title="LiveSession - AB testing" />
      <p>
        It’s easy to say that with session recordings you can see how A/B test participants
        interacted with the website.
      </p>
      <p>
        It’s harder, however, to imagine what exactly you can see in these recordings. We don’t want
        to leave it to your imagination only. Have a look at some of our findings from the described
        A/B test:
      </p>

      <ul>
        <li>
          Users tend to <strong>click on things</strong> that are not clickable, such as the “play”
          button on this illustration:
          <ImageModal src={img32} alt="Guide to AB testing - 32" title="LiveSession - AB testing" />
        </li>
        <li>
          You can see where people tend to stop when they’re browsing the page. For instance, they
          spend more time reading the section about data privacy:
          <ImageModal src={img33} alt="Guide to AB testing - 33" title="LiveSession - AB testing" />
        </li>
        <li>
          You can track the most common next steps, just like you’re sitting next to the user! In
          most cases, the visitors go to Features and Pricing after seeing the main page:
          <ImageModal src={img34} alt="Guide to AB testing - 34" title="LiveSession - AB testing" />
        </li>
      </ul>
    </div>
  </Post>
);
const Wrapped = GuideArticle(Guide);

const WrapperArticle = () => {
  const { cover } = useStaticQuery(graphql`
    query CoverABtest {
      cover: file(relativePath: { eq: "tutorials/abtest.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 95)
        }
      }
    }
  `);
  return (
    <Wrapped
      title={meta.title}
      description={meta.description}
      date={meta.date}
      author={meta.author}
      url={meta.url}
      group={meta.group}
      img={cover.childImageSharp.gatsbyImageData}
      tutorials={meta.tutorials}
      customSection={meta.CustomSection}
    />
  );
};

export default WrapperArticle;
