import { authorZuzanna } from 'data/authors';

import cover from 'img/product-analytics/cover/product-analytics.png';

const olStyle = {
  lineHeight: '48px',
};

export const data = [
  {
    url: '/product-analytics/',
    title: 'Introduction',
    desc: 'Introduction to Product Analytics: Why It’s Crucial for Business Success ',
    metaDesc:
      'Welcome to the ultimate article on all things product analytics. Join us in exploring the power or analyzing your product usage, user interactions and more. ',
    timeToRead: 11,
    author: authorZuzanna,
    date: 'April 07, 2023',
    heading: (
      <>
        <p>Navigating the business world without data is like sailing without a compass. </p>

        <p>
          Among the many types of data, product analytics holds a special place. It&apos;s more than
          just numbers and charts - it&apos;s the pulse of your product, telling you what works,
          what doesn&apos;t, and what can be improved. Understanding product analytics can mean the
          difference between success and failure in today&apos;s competitive marketplace.{' '}
        </p>

        <p>
          Let&apos;s delve into the essentials of product analytics and explore its pivotal role in
          driving business success.
        </p>

        <ol style={olStyle}>
          <li>
            <h6>
              <a href="#what-is-product-analytics">What is product analytics?</a>
            </h6>
          </li>
          <li>
            <h6>
              <a href="#importance-of-product-analytics-in-business">
                Importance of Product Analytics in Business
              </a>
            </h6>
          </li>
          <li>
            <h6>
              <a href="#benefits-of-product-analytics">Benefits of Product Analytics</a>
            </h6>
          </li>
          <li>
            <h6>
              <a href="#how-livesession-helps-with-product-analytics">
                How LiveSession Helps With Product Analytics
              </a>
            </h6>
          </li>
        </ol>
      </>
    ),
    cover,
  },
  {
    url: '/product-analytics/product-analytics-training/',
    title: 'Chapter 1',
    desc: 'How to Run Product Analytics Training in Your Team',
    metaDesc:
      'Interested in product analytics training? Not sure if it’s for your business? Read this post on product analytics training & find the answers to your questions!',
    timeToRead: 10,
    author: authorZuzanna,
    date: 'April 07, 2023',
    heading: (
      <>
        <p>
          Building a successful product is a team effort, and product analytics is a vital part of
          that process.
        </p>

        <p>But how do you ensure your team is well-versed in this crucial area? </p>

        <p>The answer lies in effective training.</p>
        <p>
          Implementing a robust product analytics training program within your team can equip them
          with the necessary skills to harness data effectively, make informed decisions, and drive
          your product&apos;s success.
        </p>
        <p>
          In this guide, we&apos;ll walk you through the steps to run a successful product analytics
          training program in your team, turning your members into data-savvy professionals ready to
          take your product to new heights.
        </p>

        <ol style={olStyle}>
          <li>
            <h6>
              <a href="#what-is-product-analytics-training">What is Product Analytics Training?</a>
            </h6>
          </li>
          <li>
            <h6>
              <a href="#running-product-analytics-training-in-your-team">
                Running Product Analytics Training in Your Team - Best Practices
              </a>
            </h6>
          </li>
          <li>
            <h6>
              <a href="#product-analytics-training">
                Product Analytics Training - Learning Materials and Courses Examples
              </a>
            </h6>
          </li>
          <li>
            <h6>
              <a href="#to-train-ot-not-to-train">
                To Train or Not To Train? Benefits of Product Analytics Training
              </a>
            </h6>
          </li>
          <li>
            <h6>
              <a href="#final-remarks">Final remarks</a>
            </h6>
          </li>
        </ol>
      </>
    ),
    cover,
  },
  {
    url: '/product-analytics/product-analysis-best-practices/',
    title: 'Chapter 2',
    desc: 'Product Analysis Best Practices: Going Beyond the Numbers + FREE GIFT',
    metaDesc: `You frown at the mention of product analysis? Read this article and never be afraid of product analysis ever before. Tips, tricks, best practices + free gift.`,
    timeToRead: 9,
    author: authorZuzanna,
    date: 'April 07, 2023',
    heading: (
      <>
        <p>
          Product analysis is more than just crunching numbers and tracking metrics. It&apos;s about
          understanding the story behind the data, the patterns that drive user behavior, and the
          factors that influence product performance.
        </p>

        <p>
          We&apos;ll explore the best practices for product analysis, focusing on how to extract
          meaningful insights from data and use them to drive strategic decision-making. We&apos;ll
          show you how to go beyond the surface-level metrics and uncover the deeper insights that
          can propel your product to new heights of success.
        </p>

        <ol style={olStyle}>
          <li>
            <h6>
              <a href="#what-is-product-analysis">What is Product Analysis?</a>
            </h6>
          </li>
          <li>
            <h6>
              <a href="#best-tips-and-tricks-to-effective-product-analysis">
                Best Tips & Tricks to Effective Product Analysis
              </a>
            </h6>
          </li>
          <li>
            <h6>
              <a href="#did-you-get-all-the-steps">CHECKLIST: did you get all the steps?</a>
            </h6>
          </li>
          <li>
            <h6>
              <a href="#free-gift">
                Free Gift: 20 types of questions to ask yourself during product analysis
              </a>
            </h6>
          </li>
          <li>
            <h6>
              <a href="#over-to-you">Over to you</a>
            </h6>
          </li>
        </ol>
      </>
    ),
    cover,
  },
  {
    url: '/product-analytics/product-analytics-software-partI/',
    title: 'Chapter 3',
    desc: 'Benefits of Product Analytics Software (+ how to find it) part I',
    metaDesc: `Read this post to learn about product analytics software: examples, benefits, tips to finding the best one & much more. Boost your product analytics today`,
    timeToRead: 13,
    author: authorZuzanna,
    date: 'April 07, 2023',
    heading: (
      <>
        <p>
          Product analytics software is a powerful tool in the modern business arsenal. It&apos;s
          like a high-powered microscope, allowing you to examine your product&apos;s performance at
          a granular level, uncovering insights that can drive innovation and growth.{' '}
        </p>

        <p>
          With so many options available, though, how do you choose the right product analytics
          software?
        </p>

        <p>
          In the first part of this guide, we&apos;ll delve into the benefits of product analytics
          software and provide you with a roadmap to navigate the landscape of available options.{' '}
        </p>

        <p>
          Stay tuned as we embark on this journey to find the perfect tool to supercharge your
          product&apos;s success.
        </p>

        <ol style={olStyle}>
          <li>
            <h6>
              <a href="#importance-of-product-analytics-software-for-business">
                Importance of Product Analytics Software for Businesses
              </a>
            </h6>
          </li>
          <li>
            <h6>
              <a href="#product-analytics-software-example">Product Analytics Software Examples</a>
            </h6>
          </li>
          <li>
            <h6>
              <a href="#benefits-of-product-analytics-software">
                Benefits of Product Analytics Software
              </a>
            </h6>
          </li>
          <li>
            <h6>
              <a href="#finding-the-best-product-analytics-software">
                Finding The Best Product Analytics Software - Checklist
              </a>
            </h6>
          </li>
          <li>
            <h6>
              <a href="#questions-to-ask-yourself-during-product-analytics-software-hunt">
                Questions To Ask Yourself During Product Analytics Software Hunt
              </a>
            </h6>
          </li>
          <li>
            <h6>
              <a href="#final-words">Final words</a>
            </h6>
          </li>
        </ol>
      </>
    ),
    cover,
  },
  {
    url: '/product-analytics/product-analytics-software-partII/',
    title: 'Chapter 4',
    desc: `Product Analytics Software - Best Product Analytics Tools' Features & More  (part II)`,
    metaDesc: `Join us in exploring the exciting world of product analytics tools. Learn with us about their features, price, and implementation challenges. Don’t miss it! `,
    timeToRead: 12,
    author: authorZuzanna,
    date: 'April 07, 2023',
    heading: (
      <>
        <p>In part two of our guide, we explore product analytics software in more detail.</p>

        <p>
          We&apos;ll explore the features that set the best tools apart, from intuitive dashboards
          and robust data visualization to advanced segmentation and predictive analytics
          capabilities. We&apos;ll also discuss factors to consider when choosing a tool, such as
          integration capabilities, scalability, and customer support.
        </p>

        <p>
          We&apos;ll highlight some of the top product analytics tools in the market, providing an
          overview of their features, strengths, and who they&apos;re best suited for.
        </p>

        <p>
          We hope this guide will help you make an informed decision, whether you&apos;re a startup
          or an established company looking for your first product analytics tool!
        </p>

        <ol style={olStyle}>
          <li>
            <h6>
              <a href="#top-feeatures-of-product-analytics-tools">
                Top Features of Product Analytics Tools
              </a>
            </h6>
          </li>
          <li>
            <h6>
              <a href="#the-real-price-of-a-product-analytics-solution">
                The Real Price of a Product Analytics Solution
              </a>
            </h6>
          </li>
          <li>
            <h6>
              <a href="#challenges-in-implementing-product-analytics-tools">
                Challenges in Implementing Product Analytics Tools
              </a>
            </h6>
          </li>
          <li>
            <h6>
              <a href="#how-does-livesession-compare-with-other-tools">
                How Does LiveSession Compare With Other Tools?
              </a>
            </h6>
          </li>
          <li>
            <h6>
              <a href="#conclusion">Conclusion</a>
            </h6>
          </li>
        </ol>
      </>
    ),
    cover,
  },
  {
    url: '/product-analytics/product-analytics-dashboard/',
    title: 'Chapter 5',
    desc: 'Inside of Product Analytics Tools: Your Product Analytics Dashboards',
    metaDesc: `Are you curious to know what YOUR product analytics dashboard could look like? Check our article to find out. Analytics, segments, filters and more. Read now!`,
    timeToRead: 7,
    author: authorZuzanna,
    date: 'April 07, 2023',
    heading: (
      <>
        <p>
          The heart of any product analytics tool is its dashboard - a command center where data is
          transformed into insights. It&apos;s where numbers become stories, telling you about user
          behavior, product performance, and opportunities for growth.
        </p>

        <p>
          Today, we’ll take you inside the world of product analytics dashboards. We&apos;ll explore
          how to interpret different metrics, customize your dashboard to fit your needs, and use
          the insights you gain to drive decision-making. Like a pro!
        </p>

        <ol style={olStyle}>
          <li>
            <h6>
              <a href="#what-is-a-product-analytics-dashboard">
                What is a product analytics dashboard?
              </a>
            </h6>
          </li>
          <li>
            <h6>
              <a href="#what-to-look-for-in-product-analytics-dashboard">
                What to look for in Product Analytics Dashboard
              </a>
            </h6>
          </li>
          <li>
            <h6>
              <a href="#product-analytics-dashboard-must-have-features">
                Product Analytics Dashboard: Must-Have Features
              </a>
            </h6>
          </li>
          <li>
            <h6>
              <a href="#livesession-for-the-win">LiveSession For The Win</a>
            </h6>
          </li>
          <li>
            <h6>
              <a href="#wrap-up">Wrap up</a>
            </h6>
          </li>
        </ol>
      </>
    ),
    cover,
  },
  {
    url: '/product-analytics/product-analytics-frameworks/',
    title: 'Chapter 6',
    desc: 'Product Analytics Frameworks: The Right Approach to Success',
    metaDesc: `Looking for the perfect product analytics framework? We've got you covered! Learn about our 7-phase framework for guaranteed success. Enjoy!`,
    timeToRead: 8,
    author: authorZuzanna,
    date: 'April 07, 2023',
    heading: (
      <>
        <p>
          A well-defined product analytics framework not only gives direction but also ensures that
          every piece of data you collect serves a purpose.{' '}
        </p>

        <p>
          In this guide, we&apos;ll demystify product analytics frameworks, shedding light on their
          role in shaping successful data-driven strategies.{' '}
        </p>

        <p>
          As we discuss various frameworks, we will help you determine which is best suited for your
          business. From implementation to deriving actionable insights, we&apos;ll cover it all!
        </p>

        <ol style={olStyle}>
          <li>
            <h6>
              <a href="#what-are-product-analytics-frameworks">
                What are Product Analytics Frameworks?
              </a>
            </h6>
          </li>
          <li>
            <h6>
              <a href="#product-analytics-frameworks-why-your-business-should-care">
                Product Analytics Frameworks: Why Your Business Should Care
              </a>
            </h6>
          </li>
          <li>
            <h6>
              <a href="#seven-phase-product-analytics-framework">
                7-Phase Product Analytics Framework
              </a>
            </h6>
          </li>
          <li>
            <h6>
              <a href="#three-reasons-why-you-should-use-product-analytics-framework">
                3 Reasons Why You Should Use Product Analytics Frameworks
              </a>
            </h6>
          </li>
          <li>
            <h6>
              <a href="#no-time-hire-a-product-analyst-who-has">
                No time? Hire a Product Analyst who has:
              </a>
            </h6>
          </li>
          <li>
            <h6>
              <a href="or-let-livesession-do-the-job">&hellip; or let LiveSession do the job</a>
            </h6>
          </li>
          <li>
            <h6>
              <a href="#over-to-you">Over to you</a>
            </h6>
          </li>
        </ol>
      </>
    ),
    cover,
  },
  {
    url: '/product-analytics/product-analytics-examples/',
    title: 'Chapter 7',
    desc: 'Real-World Product Analysis Examples To Boost Your Business',
    metaDesc: `Not sure if product analytics is for your business? Read our real-life product analysis examples to make up your mind. Real businesses, real results. Read now`,
    timeToRead: 12,
    author: authorZuzanna,
    date: 'April 07, 2023',
    heading: (
      <>
        <p>
          Product analysis isn&apos;t just a theoretical concept - it&apos;s a practical tool that
          businesses around the world use to drive growth and innovation.
        </p>

        <p>
          Seeing it in action can provide valuable insights and inspiration for your own strategies.
        </p>

        <p>
          Today, we&apos;ll explore real-world examples of product analysis, showcasing how
          businesses have used data to make informed decisions, optimize their products, and deliver
          exceptional customer experiences. Read our guide to make better marketing choices!
        </p>

        <ol style={olStyle}>
          <li>
            <h6>
              <a href="#why-product-analysis-examples-are-useful">
                Why Product Analysis Examples Are Useful
              </a>
            </h6>
          </li>
          <li>
            <h6>
              <a href="#finding-product-analysis-examples">Finding Product Analysis Examples</a>
            </h6>
          </li>
          <li>
            <h6>
              <a href="#real-world-product-analysis-examples">
                Real-World Product Analysis Examples
              </a>
            </h6>
          </li>
          <li>
            <h6>
              <a href="#conclusion">Conclusion</a>
            </h6>
          </li>
        </ol>
      </>
    ),
    cover,
  },
  {
    url: '/product-analytics/product-analytics-books/',
    title: 'Chapter 8',
    desc: 'These 15+ Product Analytics Books Can (Really) Teach You A Lot',
    metaDesc: `Are you a bookworm interested in product analytics? Check out our curated list of top product analytics books that drive product success. Don’t miss out on it.`,
    timeToRead: 10,
    author: authorZuzanna,
    date: 'April 07, 2023',
    heading: (
      <>
        <p>
          Knowledge is power, and when it comes to product analytics, there&apos;s a wealth of
          knowledge waiting to be discovered in books.
        </p>

        <p>
          From foundational texts to cutting-edge guides, these books can provide valuable insights,
          whether you&apos;re a beginner or a seasoned pro. As part of our guide, we&apos;ll
          introduce you to over 15 product analytics books that can truly enrich your understanding.
        </p>

        <p>
          These books cover a wide range of topics, from the basics of data analysis to advanced
          strategies for product optimization. So, you’re likely to find something for yourself!
        </p>
      </>
    ),
    cover,
  },
];
