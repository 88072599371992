/* eslint-disable jsx-a11y/anchor-is-valid */

import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import withIntegrationCardContext from 'hoc/withIntegrationCardContext';
import Slider from 'react-slick';

const Zapier = ({ sliderSettings }) => {
  const { zapier1, zapier2, zapier3 } = useStaticQuery(graphql`
    {
      zapier1: file(relativePath: { eq: "marketplace/zapier/1.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      zapier2: file(relativePath: { eq: "marketplace/zapier/2.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      zapier3: file(relativePath: { eq: "marketplace/zapier/3.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);
  const photos = [
    {
      img: zapier1.childImageSharp,
      alt: 'Zapier integration',
      title: 'Integrate LiveSession with Zapier',
    },
    {
      img: zapier2.childImageSharp,
      alt: 'Zapier integration',
      title: 'Integrate LiveSession with Zapier',
    },
    {
      img: zapier3.childImageSharp,
      alt: 'Zapier integration',
      title: 'Integrate LiveSession with Zapier',
    },
  ];
  return (
    <>
      <Slider {...sliderSettings(photos)}>
        {photos.map(({ img, alt, title }) => (
          <div key={img}>
            <GatsbyImage image={img?.gatsbyImageData} alt={alt} title={title} />
          </div>
        ))}{' '}
      </Slider>
      <div className="section">
        <h2>Overview</h2>
        <p>
          Zapier makes it easy to connect LiveSession with over 6000 other popular apps, so you can
          automate your business.
        </p>
      </div>
      <div className="section">
        <h2>Benefits</h2>
        <ul className="checkmarks">
          <li>Receive events and pass it through 6000+ Zapier apps</li>
          <li>Build automation workflows</li>
        </ul>
      </div>
      <h2> How to install</h2>
      <p>
        You can install this integration straight from LiveSession. See a step-by-step tutorial in
        our <a href="/help/zapier-integration/">help center</a>.
      </p>
    </>
  );
};

export default withIntegrationCardContext(Zapier);

Zapier.propTypes = {
  sliderSettings: PropTypes.func.isRequired,
};
