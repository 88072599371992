import { media } from 'theme/breakpoints';
import styled from 'styled-components';

export const WrapperBackground = styled.div`
  background-color: #171717;
  width: 100%;
  color: white;
  padding-top: 120px;
  position: relative;
  ${media.tablet} {
    padding-top: 68px;
  }
  ${media.mobile} {
    padding-top: 32px;
  }
  .headerAbout {
    margin: 0 auto 80px;
    text-align: center;
    ${media.tablet} {
      margin: 0 auto 48px;
    }
  }
`;
export const Wrapper = styled.section`
  text-align: center;
  position: relative;
  padding: 0 0 134px;
  ${media.desktop} {
    padding: 0 0 174px;
  }
  section {
    color: black;
    height: 268px;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;

    ${media.desktop} {
      height: 360px;
    }

    ${media.tablet} {
      transform: translate(0, 0);
    }
  }
  h2 {
    text-align: left;
    ${media.desktop} {
      text-align: center;
    }
  }
`;

export const PaddingBottom = styled.div`
  height: 214px;
  background-color: #f5f5f5;
  ${media.desktop} {
    height: 240px;
  }
  ${media.tablet} {
    height: 220px;
  }
`;

export const RateBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
`;
export const Rate = styled.div`
  color: #fff;
  border-radius: 4px;
  padding: 48px 0 0;
  width: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  ${media.tablet} {
    margin: 0 15px;
    width: 130px;
  }
  & a {
    color: #f8d047;
    margin-bottom: 80px;
    ${media.tablet} {
      display: none;
    }

    :hover {
      color: #f8d047;
    }
  }
  ${media.tablet} {
    justify-content: center;
    & a {
      margin-top: 20px;
    }
  }

  & .stars {
    margin-top: 20px;
    & img {
      width: 14px;
      margin-right: 4px;
    }
    & p {
      font-size: 14px;
      margin: 0;
      margin-left: 7px;
    }
    display: flex;
    align-items: center;
  }
  & div.content-box {
    margin-bottom: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    & .logo {
      max-height: 42px;
    }
  }
`;
