import { connectHighlight } from 'react-instantsearch-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledP = styled.p`
  mark {
    font-size: initial;
    font-weight: 900;
    color: #000;
    background-color: #f8d047;
    padding: 0;
  }
  span {
    font-size: 14px;
    margin: 0;
  }
`;

const Highlight = ({ highlight, attribute, hit }) => {
  const parsedHit = highlight({
    highlightProperty: '_highlightResult',
    attribute,
    hit,
  });
  return (
    <StyledP>
      {parsedHit.map((part) =>
        part.isHighlighted ? (
          <mark key={part.value}>{part.value}</mark>
        ) : (
          <span key={part.value}>{part.value}</span>
        ),
      )}
    </StyledP>
  );
};

const CustomHighlight = connectHighlight(Highlight);

export default CustomHighlight;

Highlight.propTypes = {
  highlight: PropTypes.func.isRequired,
  attribute: PropTypes.string.isRequired,
  hit: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
