import CustomLink from 'components/ui/link';
import PropTypes from 'prop-types';
import Button from 'components/ui/button';
import * as Styled from './styled';

const IntegrationCard = ({
  title,
  desc,
  img,
  link,
  integrationLink,
  externalInstallLink,
  activeCategory,
  integrationPage,
  as,
  linkTag,
  handleIntegrationBtnClick,
}) => {
  const badgeColor = (text) => {
    switch (text) {
      case 'Customer support':
        return '#8765F9';
      case 'Analytics':
        return '#F8D047';
      case 'E-commerce':
        return '#7DFFEF';
      case 'CMS':
        return '#F8D047';
      case 'Developers':
        return '#F8D047';
      case 'UX Designers':
        return '#F8D047';
      case 'Marketers':
        return '#7DFFEF';
      case 'Most popular':
        return '#8765F9';
      case 'New':
        return '#8765F9';
      case 'Bug tracking':
        return '#7DFFEF';
      case 'Surveys':
        return '#F8D047';
      default:
        return null;
    }
  };

  function installNowHref() {
    if (externalInstallLink) {
      return externalInstallLink;
    }
    return integrationLink ? `${process.env.GATSBY_APP_URL}${integrationLink}` : link;
  }

  return (
    <Styled.Card
      badgeColor={badgeColor(activeCategory)}
      badge={activeCategory}
      white={activeCategory === 'Customer support' || activeCategory === 'Most popular'}
      href={linkTag ? undefined : link}
      integrationPage={integrationPage}
      as={as}
    >
      <div>
        <img src={img} alt={`${title} - logo`} title={title} />
        <h3>{title}</h3>
        <p>{desc}</p>
      </div>
      {integrationPage ? (
        integrationLink ? (
          <CustomLink
            withArrow
            tag={linkTag || 'p'}
            href={linkTag ? link : undefined}
            onClick={(e) => handleIntegrationBtnClick(e, title)}
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </CustomLink>
        ) : (
          ''
        )
      ) : (
        <CustomLink
          withArrow
          tag={linkTag || 'p'}
          href={linkTag ? link : undefined}
          onClick={(e) => handleIntegrationBtnClick(e, title)}
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more
        </CustomLink>
      )}
      {integrationPage && (
        <Button
          href={installNowHref()}
          className={!integrationLink && link ? 'btn-only' : undefined}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => handleIntegrationBtnClick(e, title)}
        >
          Install now
        </Button>
      )}
    </Styled.Card>
  );
};

export default IntegrationCard;

IntegrationCard.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  img: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]).isRequired,
  link: PropTypes.string.isRequired,
  activeCategory: PropTypes.string,
  linkTag: PropTypes.string,
  as: PropTypes.string,
  integrationLink: PropTypes.string,
  externalInstallLink: PropTypes.string,
  handleIntegrationBtnClick: PropTypes.func,
  integrationPage: PropTypes.bool,
};

IntegrationCard.defaultProps = {
  integrationPage: false,
  linkTag: undefined,
  as: undefined,
  activeCategory: undefined,
  integrationLink: undefined,
  externalInstallLink: undefined,
  handleIntegrationBtnClick: undefined,
};
