import React from 'react';
import SignUpForm from 'components/ui/signupForm';
import { StaticImage } from 'gatsby-plugin-image';
import TrustedBy from 'components/ui/trustedBy';
import UpHeading from '../upHeading';
import * as Styled from './styled';

const TopSection = () => (
  <Styled.Section>
    <Styled.GridBox className="container">
      <div className="gridbox-container">
        <UpHeading headingText="LIVESESSION FOR SAAS" />
        <h1 className="line">
          Turn insights into real business <span>results</span>
        </h1>
        <Styled.Paragraph>
          Track user behavior to improve acquisition, retention, and expansion of your SaaS.
          Increase the number of new registrations and lower the churn rate with LiveSession.
        </Styled.Paragraph>
        <SignUpForm />
      </div>
      <Styled.ImageWrapper>
        <StaticImage
          src="../../../img/saas/heroSaas.png"
          placeholder="BLURRED"
          objectFit="cover"
          alt="User behavior tracking session"
        />
      </Styled.ImageWrapper>
    </Styled.GridBox>
    <div className="container">
      <TrustedBy saas style={{ padding: 0 }} />
    </div>
  </Styled.Section>
);
export default TopSection;
