import NewsletterTemplate from 'templates/newsletter';

const Content = () => (
  <table className="content" cellSpacing="0" cellPadding="0" align="center" width="100%">
    <tbody>
      <tr>
        <td>
          <table className="text" cellSpacing="0" cellPadding="0" style={{ width: '100%' }}>
            <tbody>
              <tr>
                <td style={{ padding: '32px 40px' }}>
                  <p style={{ margin: 0, fontSize: '16px', lineHeight: '24px' }}>
                    Hi 👋
                    <br />
                    <br />
                    Today we&apos;d like to tell you about how our customers use LiveSession to
                    achieve their goals and share some insightful new pieces we recently published
                    on our blog. Stay tuned for more news in the near future!
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '12px 0' }}>
                  <div style={{ width: '100%', height: '1px', backgroundColor: '#ddd' }} />
                </td>
              </tr>
              <tr>
                <td style={{ padding: '32px 40px 12px' }}>
                  <h1 style={{ margin: 0 }}>
                    <span>Success Stories</span>
                  </h1>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '12px 40px 0 40px' }}>
                  <div>
                    <a href="/customers/databox/" target="_blank" rel="noopener noreferrer">
                      <img
                        src="https://emails.livesession.io/databox.jpeg"
                        alt="badge"
                        title="How Databox achieved a 223% increase in conversions to paid accounts after switching to LiveSession"
                        style={{
                          maxWidth: '100%',
                          height: 'auto',
                          marginTop: '16px',
                          borderRadius: '4px',
                        }}
                      />
                    </a>
                    <div style={{ marginTop: '24px', marginBottom: '32px' }}>
                      <p style={{ fontSize: '20px', marginBottom: '12px' }}>
                        <strong>
                          How Databox achieved a 223% increase in conversions to paid accounts after
                          switching to LiveSession
                        </strong>
                      </p>
                      <p style={{ color: '#9b9b9b', marginBottom: '12px' }}>
                        Although a great customer experience and a frustration-free onboarding
                        process are important, the Return of Investment is essential for every
                        company. Signup is not the end of the customer acquisition process. Success
                        is achieved when the satisfied users are happily willing to pay for the app
                        usage.
                        <br />
                        <br />
                        The increase in conversions from trial to paid model has been essential to
                        Databox.{' '}
                        <strong>
                          After switching to LiveSession, conversions from free to paid accounts
                          have grown by 223%.
                        </strong>
                      </p>
                      <a
                        href="/customers/databox/"
                        className="with-arrow"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ fontSize: '16px', color: '#0a4ed6', marginTop: '12px' }}
                      >
                        Read more on our website
                      </a>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '12px 0' }}>
                  <div style={{ width: '100%', height: '1px', backgroundColor: '#ddd' }} />
                </td>
              </tr>
              <tr>
                <td style={{ padding: '32px 40px 12px' }}>
                  <h1 style={{ margin: 0 }}>
                    <span>New on our blog</span>
                  </h1>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '12px 40px' }}>
                  <div>
                    <a
                      href="/blog/get-more-leads-this-year/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://emails.livesession.io/8_cro_tips.png"
                        alt="badge"
                        title="8 CRO Tips That Will Help You Get More Leads This Year"
                        style={{
                          maxWidth: '100%',
                          height: 'auto',
                          marginTop: '16px',
                          borderRadius: '4px',
                        }}
                      />
                    </a>
                    <div style={{ marginTop: '24px' }}>
                      <p style={{ fontSize: '20px', marginBottom: '12px' }}>
                        <strong>8 CRO Tips That Will Help You Get More Leads This Year</strong>
                      </p>
                      <p style={{ color: '#9b9b9b', marginBottom: '12px' }}>
                        Lead generation, or creating interest in your brand, is an important step
                        towards increasing your profit. But, if you can’t convert that interest into
                        actual customers, all that effort is lost. That’s why a low conversion rate
                        can be so damaging. You don’t just want views on your site — you want active
                        visitors who interact with your content and invest in your products and
                        services.
                      </p>
                      <a
                        href="/blog/get-more-leads-this-year/"
                        className="with-arrow"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ fontSize: '16px', color: '#0a4ed6', marginTop: '12px' }}
                      >
                        Read more on our blog
                      </a>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '12px 40px' }}>
                  <div>
                    <a
                      href="/blog/boost-websites-conversion-with-ux-improvements/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://emails.livesession.io/5_ways_to_boost_conversions.png"
                        alt="badge"
                        title="5 Actionable Ways to Improve your Website’s UX Design and Boost conversion"
                        style={{
                          maxWidth: '100%',
                          height: 'auto',
                          marginTop: '16px',
                          borderRadius: '4px',
                        }}
                      />
                    </a>
                    <div style={{ marginTop: '24px' }}>
                      <p style={{ fontSize: '20px', marginBottom: '12px' }}>
                        <strong>
                          5 Actionable Ways to Improve your Website’s UX Design and Boost conversion
                        </strong>
                      </p>
                      <p style={{ color: '#9b9b9b', marginBottom: '12px' }}>
                        While great UX design enjoys so much importance in web projects, it is more
                        than just eye-catchy design layout, smooth user flow and quick loading time.
                        A great web user experience is the neat outcome of an array of factors
                        leading to ease of use, smooth engagement and user interactions, more web
                        traffic and more business conversions. In short, better user experience
                        helps a brand to convert more visitors into business leads and buyers.
                      </p>
                      <a
                        href="/blog/boost-websites-conversion-with-ux-improvements/"
                        className="with-arrow"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ fontSize: '16px', color: '#0a4ed6', marginTop: '12px' }}
                      >
                        Read more on our blog
                      </a>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '12px 40px' }}>
                  <div>
                    <a
                      href="/blog/boost-websites-conversion-with-ux-improvements/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://emails.livesession.io/student_experience.png"
                        alt="badge"
                        title="10 best UX tricks you should adapt to improve student experience"
                        style={{
                          maxWidth: '100%',
                          height: 'auto',
                          marginTop: '16px',
                          borderRadius: '4px',
                        }}
                      />
                    </a>
                    <div style={{ marginTop: '24px' }}>
                      <p style={{ fontSize: '20px', marginBottom: '12px' }}>
                        <strong>
                          10 best UX tricks you should adapt to improve &quot;student
                          experience&quot;
                        </strong>
                      </p>
                      <p style={{ color: '#9b9b9b', marginBottom: '12px' }}>
                        14% of people visiting a college’s website want to know more about the
                        institution. Now, if the website has poor UX, the conversion rate is lower.
                        Meaning that because of bad UX, fewer students are going to consider signing
                        up at that school. Surveys from Research in Higher Education Journal found
                        that a college website was an &quot;important&quot; factor in deciding
                        campus attendance. Participants also indicated that an &quot;attractive and
                        clear&quot; website is more appealing and influenced them the most.
                      </p>
                      <a
                        href="/blog/boost-websites-conversion-with-ux-improvements/"
                        className="with-arrow"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ fontSize: '16px', color: '#0a4ed6', marginTop: '12px' }}
                      >
                        Read more on our blog
                      </a>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '12px 40px' }}>
                  <p style={{ margin: 0, fontSize: '16px', lineHeight: '24px' }}>
                    If you have any questions, let me know by replying to this email. Follow us on{' '}
                    <a href="https://www.facebook.com/LiveSessionIO/">Facebook</a>,{' '}
                    <a href="https://twitter.com/livesessionhq/">Twitter</a> and{' '}
                    <a href="https://www.linkedin.com/company/livesession/">LinkedIn</a>. Let’s stay
                    in touch!
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '20px 40px 15px' }}>
                  <p style={{ margin: 0, fontSize: '16px', lineHeight: '24px' }}>Best,</p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '15px 40px 32px' }}>
                  <table className="kamil" cellSpacing="0" cellPadding="0">
                    <tr style={{ verticalAlign: 'middle' }}>
                      <th>
                        <img
                          src="https://emails.livesession.io/pete_rounded.png"
                          className="kamil-img"
                          style={{ maxWidth: '56px' }}
                          alt="Kamil - LiveSession's CEO"
                        />
                      </th>
                      <th style={{ paddingLeft: '16px' }}>
                        <p className="info">
                          <strong style={{ fontWeight: 800 }}>Pete Pal</strong> <br />
                          Marketing Manager @ LiveSession
                        </p>
                      </th>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
);

const Newsletter = NewsletterTemplate(Content);

const frontmatter = {
  title: 'LiveSession Newsletter May 2021',
  description: "Read LiveSession's monthly update from May 2021.",
  date: '2021-05-01',
  link: '/newsletters/may-2021/',
};

const NewsletterPage = () => <Newsletter {...frontmatter} />;

export default NewsletterPage;
