import { useStaticQuery, graphql } from 'gatsby';
import { transformImages } from 'helpers/transformImages';
import * as Styled from './styled';
import Card from '../card';

const WrapperCard = () => {
  const { allFile } = useStaticQuery(graphql`
    query WrapperCard {
      allFile(filter: { relativePath: { regex: "/feature-cards/" } }) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);
  const files = transformImages(allFile.edges);
  const cardsInfo = [
    {
      title: 'Alerts',
      desc: `Get instant Slack notification of your own defined events like starting new subscription or finishing onboarding.`,
      img: files.alerts.node.childImageSharp.gatsbyImageData,
      imageStyle: {
        maxWidth: '75%',
      },
    },
    {
      title: 'Recording rules',
      desc: `Exclude recording for a specific IP, country, URL or user agent.`,
      img: files.rules.node.childImageSharp.gatsbyImageData,
      imageStyle: {
        maxWidth: '97%',
      },
    },
    {
      title: 'User journey',
      desc: `See the whole user journey as a timeline with most important events.`,
      img: files.user.node.childImageSharp.gatsbyImageData,
      imageStyle: {
        maxWidth: '100%',
      },
    },
    {
      title: 'Rage & Error Clicks',
      desc: 'Rage clicks and Error clicks automatically spot pain points and errors on your website.',
      img: files.error.node.childImageSharp.gatsbyImageData,
      imageStyle: {
        maxWidth: '68%',
      },
    },
    {
      title: 'Engagement score',
      desc: 'Save your time with our engagement score. You’ll find the most interesting recordings in a few clicks.',
      img: files.engagement.node.childImageSharp.gatsbyImageData,
      imageStyle: {
        maxWidth: '100%',
      },
    },
    {
      title: 'Rest API',
      desc: 'Retrieve collected data programmatically form LiveSession API.',
      img: files.rest.node.childImageSharp.gatsbyImageData,
      imageStyle: {
        maxWidth: '93%',
      },
    },
    {
      title: 'Exports',
      desc: 'Export users and events as a JSON or CSV file.',
      img: files.exports.node.childImageSharp.gatsbyImageData,
      imageStyle: {
        maxWidth: '93%',
      },
    },
  ];

  return (
    <Styled.Wrapper className="container">
      <h2 className="line">Other powerfull features</h2>
      <Styled.Section>
        {cardsInfo.map((item) => (
          <Card {...item} key={item.title} />
        ))}
      </Styled.Section>
    </Styled.Wrapper>
  );
};

export default WrapperCard;
