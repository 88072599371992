import { useState, useEffect } from 'react';

export const useSourceParams = (sourceID) => {
  const [urlSource, setUrlSource] = useState('');
  useEffect(() => {
    if (urlSource === '') {
      setUrlSource(window.location.pathname);
    }
  }, [urlSource]);

  let params = '?';

  if (urlSource && urlSource.length > 0) {
    params += `url_source=${encodeURIComponent(urlSource)}`;
  }

  if (sourceID && sourceID.length > 0) {
    if (params.length > 1) {
      params += '&';
    }
    params += `source_id=${encodeURIComponent(sourceID)}`;
  }
  const link = `/signup/${params}`;

  return { link, params };
};
