import intercom from 'img/companyLogo/icons/intercom-logo.svg';
import wordpress from 'img/companyLogo/icons/wordpress.svg';
import ga from 'img/companyLogo/icons/ga.svg';
import shopify from 'img/companyLogo/icons/shopify.svg';
import gtm from 'img/companyLogo/icons/gtm.svg';
import segment from 'img/companyLogo/icons/segment-logo.svg';
import drift from 'img/companyLogo/icons/drift-logo.svg';
import livechat from 'img/companyLogo/icons/livechat.svg';
import helpscout from 'img/companyLogo/icons/helpscout.svg';
import sentry from 'img/companyLogo/icons/sentry-logo.svg';
import bugsnag from 'img/companyLogo/icons/bugsnag.svg';
import zendesk from 'img/companyLogo/icons/zendesk-logo.svg';

// this is used on index, square boxes

export const integrations = [
  {
    img: ga,
    alt: 'Google Analytics',
    title: 'Analytics',
    link: '/integrations/google-analytics/',
  },
  {
    img: wordpress,
    alt: 'Wordpress',
    link: '/integrations/wordpress/',
  },
  {
    img: shopify,
    alt: 'Shopify',
    link: '/integrations/shopify/',
  },
  {
    img: intercom,
    alt: 'Intercom',
    link: '/integrations/intercom/',
  },
  {
    img: helpscout,
    alt: 'Help Scout',
    link: '/integrations/help-scout/',
  },
  {
    img: drift,
    alt: 'Drift',
    link: '/integrations/drift/',
  },
  {
    img: sentry,
    alt: 'Sentry',
    link: '/integrations/sentry/',
  },
  {
    img: gtm,
    alt: 'Google Tag Manager',
    title: 'GTM',
    link: '/integrations/google-tag-manager/',
  },
  {
    img: livechat,
    alt: 'LiveChat',
    link: '/integrations/livechat/',
  },
  {
    img: zendesk,
    alt: 'Zendesk',
    link: '/integrations/zendesk/',
  },
  {
    img: segment,
    alt: 'Segment',
    link: '/integrations/segment/',
  },
  {
    img: bugsnag,
    alt: 'Bugsnag',
    link: '/integrations/bugsnag/',
  },
];
