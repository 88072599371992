import PropTypes from 'prop-types';
import { format } from 'date-fns';
import authors from 'data/authors';
import * as Styled from './styled';

const ArticlePreview = ({
  first,
  title,
  description,
  img,
  url,
  date,
  blogDate,
  group,
  isList,
  list,
  timeToRead,
  authorId,
}) => {
  const author = authors.find((item) => item?.authorId === authorId) || {};
  const { authorName } = author;

  const formattedDate = format(new Date(date), 'MMMM dd, yyyy');

  return first ? (
    <Styled.CustomLink href={url}>
      <Styled.FirstPreview>
        <Styled.ImageBox isList={isList} className="first">
          <Styled.FirstImg
            image={img}
            alt={`${title} - cover`}
            title={title}
            style={{ height: '100%' }}
          />
        </Styled.ImageBox>
        <Styled.FirstContent>
          <p>{list || 'Articles'}</p>
          <Styled.Title>{title}</Styled.Title>
          <p className="desc">{description}</p>
          <p className="name bigger">by {authorName}</p>
          <Styled.TimeToRead className="bigger">
            {blogDate || formattedDate}
            {timeToRead ? <span>{timeToRead} min read</span> : null}
          </Styled.TimeToRead>
        </Styled.FirstContent>
      </Styled.FirstPreview>
    </Styled.CustomLink>
  ) : (
    <Styled.CustomLink href={url}>
      <Styled.Preview isList={isList}>
        <Styled.ImageBox isList={isList} className={!isList ? 'recent-posts' : ''}>
          <Styled.GatsbyImg image={img} alt={`${title} - cover`} />
        </Styled.ImageBox>
        <Styled.Content isList={isList}>
          <p>{list || group || 'Articles'}</p>
          <Styled.Title>{title}</Styled.Title>
          <p className="name">by {authorName}</p>
          <Styled.TimeToRead>
            {blogDate || formattedDate}
            {timeToRead ? <span>{timeToRead} min read</span> : null}
          </Styled.TimeToRead>
        </Styled.Content>
      </Styled.Preview>
    </Styled.CustomLink>
  );
};

ArticlePreview.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  img: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  url: PropTypes.string.isRequired,
  authorId: PropTypes.string.isRequired,
  isList: PropTypes.bool,
  list: PropTypes.string,
  group: PropTypes.string,
  first: PropTypes.bool,
  timeToRead: PropTypes.number,
  blogDate: PropTypes.string,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};

ArticlePreview.defaultProps = {
  list: null,
  group: null,
  timeToRead: null,
  blogDate: null,
  date: null,
  first: false,
  isList: false,
  description: '',
};

export default ArticlePreview;
