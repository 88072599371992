/* eslint-disable jsx-a11y/anchor-is-valid */

import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import withIntegrationCardContext from 'hoc/withIntegrationCardContext';
import Slider from 'react-slick';

const GoogleTagManager = ({ sliderSettings }) => {
  const { img1, img2, img3 } = useStaticQuery(graphql`
    {
      img1: file(relativePath: { eq: "help/gtm/gtm_3.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img2: file(relativePath: { eq: "help/gtm/gtm_4.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img3: file(relativePath: { eq: "help/gtm/gtm_5.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);
  const photos = [
    {
      img: img1.childImageSharp,
      alt: 'Google Tag Manager Integration',
      title: 'Integrate Google Tag Manager with LiveSession',
    },
    {
      img: img2.childImageSharp,
      alt: 'Google Tag Manager Integration',
      title: 'Integrate Google Tag Manager with LiveSession',
    },
    {
      img: img3.childImageSharp,
      alt: 'Google Tag Manager Integration',
      title: 'Integrate Google Tag Manager with LiveSession',
    },
  ];
  return (
    <>
      <Slider {...sliderSettings(photos)}>
        {photos.map(({ img, alt, title }) => (
          <div>
            <GatsbyImage image={img.gatsbyImageData} alt={alt} title={title} />
          </div>
        ))}
      </Slider>
      <div className="section">
        <h2>Overview</h2>
        <p>
          Google Tag Manager is a comprehensive tool to manage marketing tags, such as tracking
          pixels or code snippets. Now you can connect it with LiveSession and get even more
          insights on user behavior.
        </p>
      </div>
      <div className="section">
        <h2>Benefits</h2>
        <ul className="checkmarks">
          <li>Install the integration without using code, just add a custom tag</li>
          <li>Access qualitative and quantitative data in one place</li>
          <li>Get in-depth insights and make more data-driven decisions</li>
        </ul>
      </div>
      <h2>How to install</h2>
      <p>
        Just add a tag in Google Tag Manager, as described in our{' '}
        <a href="/help/how-install-with-google-tag-manager/">help center</a>.
      </p>
    </>
  );
};

export default withIntegrationCardContext(GoogleTagManager);

GoogleTagManager.propTypes = {
  sliderSettings: PropTypes.func.isRequired,
};
