import React, { useState, useEffect } from 'react';
import { frontmatter } from 'data/marketplace/integrations';
import PropTypes from 'prop-types';
import MainBanner from 'components/marketplace/banner/main';
import Card from '../card';
import * as Styled from './styled';

const Content = ({ children, activeCategory, hideTitle }) => {
  const [currentCategory, setCurrentCategory] = useState('');

  useEffect(() => {
    if (currentCategory !== activeCategory) {
      setCurrentCategory(activeCategory);
    }
  }, [activeCategory, currentCategory]);

  const filterCards = () => {
    const cards = frontmatter.items.flatMap((card) =>
      card.categories.map((item) => {
        if (item === activeCategory) {
          return card;
        }
        return null;
      }),
    );
    if (activeCategory === 'All integrations') {
      return frontmatter.items.map((card) => (
        <Card key={card.title} {...card} activeCategory={card.categories[0]} />
      ));
    }
    return cards.map(
      (card) => card && <Card key={card.title} {...card} activeCategory={card.categories[0]} />,
    );
  };
  return (
    <Styled.Section>
      {children || <MainBanner />}
      {!hideTitle && <h2>{activeCategory}</h2>}
      <Styled.GridBox hideTitle={hideTitle}>{filterCards()}</Styled.GridBox>
    </Styled.Section>
  );
};

export default Content;

Content.propTypes = {
  activeCategory: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
  hideTitle: PropTypes.bool,
};

Content.defaultProps = {
  children: undefined,
  hideTitle: false,
};
