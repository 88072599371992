import PropTypes from 'prop-types';
import * as Styled from './styled';

const Section = ({ children, reversed }) => (
  <Styled.Container reversed={reversed}>{children}</Styled.Container>
);

export default Section;

Section.propTypes = {
  children: PropTypes.node.isRequired,
  reversed: PropTypes.bool,
};

Section.defaultProps = {
  reversed: false,
};
