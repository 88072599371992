import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const WhatsNext = styled.section`
  border-top: 1px solid #e4e5e8;
  padding-bottom: 40px;
  ${media.mobile} {
    padding-top: 60px;
    padding-bottom: 8px;
  }
  & h2.heading {
    text-align: center;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 24px;
  }
  & p.desc {
    font-size: 20px;
    line-height: 36px;
    text-align: center;
    max-width: 75%;
    margin: 0 auto 86px;
    ${media.tablet} {
      max-width: 100%;
      font-size: 18px;
      line-height: 30px;
    }
    ${media.mobile} {
      margin: 0 auto 56px;
    }
  }
  & .article-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 55px;
    ${media.tablet} {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, auto);
      grid-gap: 55px;
    }
    ${media.mobile} {
      grid-gap: 32px;
    }
  }
`;
