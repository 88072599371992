import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const AbandonedCarts = styled.section`
  border-radius: 4px;
  position: relative;
  border-top: 1px solid #e4e5e8;
  display: grid;
  grid-template-areas: 'content images';
  grid-template-columns: 426px 1fr;
  grid-template-rows: auto;
  padding-top: 0;
  padding-bottom: 0;
  a {
    padding-top: 24px;
    font-size: 16px;
  }

  & .BrainhubCarousel__container {
    grid-area: images;
    transform: translateX(64px);
  }
  & .BrainhubCarouselItem {
    justify-content: flex-start;
    align-items: center;
  }
  & .gatsby-image-wrapper {
    width: 100%;
  }
  & p {
    margin-bottom: 0;
  }
  & h2.line {
    margin-bottom: 16px;
  }
  & .content-container {
    grid-area: content;
  }
  &.secondary {
    grid-column-gap: 111px;
    & .BrainhubCarousel__container {
      transform: translateX(0);
    }
    & .gatsby-image-wrapper {
      margin-right: 1px;
    }
  }
  &.reversed {
    grid-template-areas: 'images content';
    grid-template-columns: 1fr 426px;
  }

  ${media.desktopWide} {
    &.secondary {
      grid-column-gap: 55px;
    }
  }
  ${media.desktop} {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    grid-template-areas:
      'content'
      'images';
    grid-template-rows: repeat(2, min-content);
    grid-row-gap: 40px;
    text-align: center;
    & .BrainhubCarousel__container {
      transform: translateX(0);
    }
    &.reversed {
      grid-template-areas:
        'content'
        'images';
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, min-content);
      & .BrainhubCarousel__container {
        transform: translateX(0);
      }
    }
  }
  ${media.tablet} {
    border-top: none;
  }
  ${media.mobile} {
    .gallery-img {
      display: none;
    }
    &::before {
      display: none;
    }
  }
`;

export const Card = styled.button`
  border: none;
  padding: 24px;
  transition: height 0.2s ease;
  background-color: transparent;
  border-radius: 0px 4px 4px 0px;
  &:hover:not(.active) {
    text-decoration: underline;
  }
  .coming-soon {
    line-height: 17px;
    font-size: 12px;
    font-weight: 700;
    padding: 0px 8px;
    margin-left: 8px;
    margin-bottom: 8px;
    border-radius: 10px;
    background-color: rgb(235, 235, 235);
  }
  & div {
    display: flex;
    align-items: center;
    & h3 {
      font-weight: 800;
      font-size: 18px;
      line-height: 28px;
      margin-right: 16px;
      color: #000;
    }
  }
  & p {
    font-size: 16px;
    line-height: 26px;
    margin-top: 8px;
    margin-bottom: 0;
    display: none;
    text-align: left;
  }
  &:hover:not(&:disabled) {
    cursor: pointer;
  }

  & div.in-progress {
    position: relative;
    &::after {
      content: 'In progress';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(135%, -50%);
      padding: 1px 6px;
      background-color: #f8d047;
      font-size: 14px;
      line-height: 18px;
      color: #000;
      font-weight: 700;
      border-radius: 4px;
    }
  }
  & svg {
    transition: transform 0.2s ease;
  }
  &.active {
    background-color: #f5f5f5;
    border-left: ${({ color }) =>
      color !== `${color}` ? '3px solid #F8D047;' : `3px solid ${color};`};
    color: #000;

    & p {
      display: block;
    }
    & h3 {
      color: #000;
    }
    & svg path {
      fill: #000;
    }
    & svg {
      transform: rotate(180deg);
    }
  }
`;

export const CardContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  ${Card}:nth-of-type(2) {
    &.active {
      border-left: ${({ color }) =>
        color !== `${color}` ? '3px solid #F8D047' : `3px solid ${color};`};
    }
  }
  ${Card}:nth-of-type(3) {
    &.active {
      border-left: ${({ color }) =>
        color !== `${color}` ? '3px solid #F8D047' : `3px solid ${color};`};
    }
  }
  ${Card}:nth-of-type(4) {
    &.active {
      border-left: ${({ color }) =>
        color !== `${color}` ? '3px solid #F8D047' : `3px solid ${color};`};
    }
  }
  ${Card}:last-of-type {
    cursor: initial;
    &:hover {
      text-decoration: none;
    }
  }
`;
