import { useStaticQuery, graphql } from 'gatsby';
import { transformImages } from 'helpers/transformImages';
import PropTypes from 'prop-types';
import * as Styled from '../styled';
import Card from '../card';

const GrayCard = ({ children, text }) => {
  const { allFile } = useStaticQuery(graphql`
    query GrayCard {
      allFile(filter: { relativePath: { regex: "/feature-cards/" } }) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);
  const files = transformImages(allFile.edges);
  const cardsInfo = [
    {
      title: 'User identification',
      desc: `Identify users by name and email and use it to filter sessions based on those criteria.`,
      img: files.identyfication.node.childImageSharp.gatsbyImageData,
      imageStyle: {
        maxWidth: '100%',
      },
    },
    {
      title: 'Funnels',
      desc: `Organize visitors' paths into funnels to see  their journeys to conversion.`,
      img: files.funnels.node.childImageSharp.gatsbyImageData,
      imageStyle: {
        maxWidth: '100%',
      },
    },
    {
      title: 'Custom properties',
      desc: `Add custom properties, such as subscription plan and other user data you need for your research.`,
      img: files.properties.node.childImageSharp.gatsbyImageData,
    },
    {
      title: 'Custom Events',
      desc: 'Extend your filter list with industry-specific events to better suit your needs. Get a full view of your product usage.',
      img: files.events.node.childImageSharp.gatsbyImageData,
      imageStyle: {
        maxWidth: '85%',
      },
    },
  ];

  return (
    <Styled.Wrapper className="container">
      <Styled.ContentWrapper>
        {children}
        <p>{text}</p>
      </Styled.ContentWrapper>
      <Styled.Section className="white">
        {cardsInfo.map((item) => (
          <Card {...item} key={item.title} />
        ))}
      </Styled.Section>
    </Styled.Wrapper>
  );
};

export default GrayCard;

GrayCard.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};
