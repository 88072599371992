import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import { StyledButton } from 'components/ui/button';
import arrowIcon from 'img/ui/icon_arrow.svg';
import Link, { StyledLink } from 'components/ui/link';

export const Container = styled.div`
  padding: 0 !important;
  margin-top: 16px;
  ${media.tablet} {
    padding: 0 15px !important;
  }
  .linkColor {
    color: white;
  }
`;

export const Navigation = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 15px 16px;
  width: 100vw;
  background-color: transparent;
  z-index: 9998;
  transition: background-color 0.2s ease, transform 0.2s ease;
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor} !important;
  border-bottom: ${(props) => props.borderBottom};

  &.fixed-bg-banner {
    transform: translateY(-48px);
    ${media.mobile} {
      transform: translateY(0);
    }
  }
  &.hide-border {
    border-bottom: none;
  }
  &.fixed-bg {
    background-color: #fff;
  }

  .whiteHamburger {
    .navbar-toggler {
      color: white;
      .hamburger__box {
        .hamburger__inner,
        .hamburger__inner__before,
        .hamburger__inner__after {
          background-color: white;
        }
      }
    }
  }
  &.fix-hamburger {
    .whiteHamburger {
      .navbar-toggler {
        color: black;
        .hamburger__box {
          .hamburger__inner,
          .hamburger__inner__before,
          .hamburger__inner__after {
            background-color: black;
          }
        }
      }
    }
  }

  &.fixed-bg-black {
    .linkWhite {
      color: #000;
    }
    .yellowBtn {
      color: #fff;
      background-color: black;
      :hover {
        background-color: #fff;
        color: black;
      }
    }
    .linkColor {
      color: black;
    }
    .whiteHamburger {
      background-color: transparent;
      border-radius: 0;
    }
  }

  &.fixed-bg.hide-border {
    border-bottom: 1px solid #f6f6f8;
  }
  ${media.mobile} {
    padding: 0 0 16px 0;
  }
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1023px) {
    display: none;
  }
`;

export const Menu = styled.ul`
  display: flex;
  margin: 0 auto 0 16px;
  padding-left: 0;
  justify-content: space-between;
  letter-spacing: -0.014rem;

  ${media.desktopWide} {
    margin: 0 0 0 12px;
  }

  li {
    list-style: none;
    a {
      color: #000;
      font-size: 16px;
      font-weight: 700;
      padding: 20px 22px;

      ${media.desktopWide} {
        padding: 20px 16px;
        font-size: 14px;
      }
      &:hover {
        text-decoration: none;
      }
    }
    &:hover a {
      color: #0346c9 !important;
    }
  }
  @media (max-width: 1023px) {
    display: none;
  }
`;

export const MenuButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

export const Dropdown = styled.li`
  position: relative;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 31px;
    &::after {
      font-family: 'icomoon' !important;
      content: '\\e900';
      font-size: 6.5px;
      transition: transform 0.3s;
      margin-left: 0.5rem;
      color: ${(props) => (props.font ? '#000' : props.font)};
      transform: translateY(2px);
    }
  }
  &:hover .dropdown-menu {
    padding: 19px 0;
    visibility: visible;
    opacity: 1;
    display: block;
    min-width: 180%;
  }
`;

export const DropdownMenu = styled.ul`
  list-style: none;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  left: 0;
  transform: translateY(-7px);
  display: none;
  border-radius: 4px !important;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.03);
  &.dropdown-menu.resources {
    display: flex;
    min-width: 500px;
    & ul.dropdown-resource-container {
      flex: 1;
      border-right: 1px solid #efefef;
      padding: 0;
      &:not(.disable-hover) a {
        padding-right: 0;
      }
    }
    ul:not(.dropdown-resource-container) {
      font-size: 16px;
      flex: 1;
      padding: 0;
      li {
        font-weight: 700;
        display: flex;
        align-items: center;

        & img {
          margin-right: 1rem;
          max-width: 24px;
          max-height: 24px;
        }

        .desc {
          font-size: 0.75rem;
          line-height: 20px;
          color: gray;
          display: flex;
          font-weight: 600 !important;
          display: block;
        }
      }
    }
  }
  li:not(.disable-hover) {
    & > a {
      justify-content: flex-start;
      padding: 10px 31px;
      color: #000 !important;
      font-weight: 700;

      &::after {
        display: none;
      }
    }
  }

  li:not(.disable-hover):hover {
    background-color: #eee;
  }
`;

export const MobileNavbar = styled.div`
  display: none;
  @media (max-width: 1023px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2px;
  }
`;

export const MobileNavigation = styled.ul`
  width: 100%;
  display: none;
  z-index: -1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 20px 0 0;
  list-style: none;

  transition: display 0.2s ease;
  &.active {
    display: flex;
    z-index: 9998;
    @media (min-width: 1023px) {
      display: none !important;
    }
  }
  li {
    padding: 8px 0;
    width: 100%;
    text-align: center;
    a {
      color: #000;
      font-weight: 800;
      font-size: 14px;
      &:hover {
        text-decoration: none;
      }
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  width: 40%;
  margin: 30px auto 0;
  align-items: center;
  justify-content: center;
  a {
    flex-basis: 50%;
    text-align: center;
  }
  .signup-button {
    width: 50%;
    padding: 0.35rem 0.2rem;
    text-align: center;
  }
  ${media.tablet} {
    width: 90%;
  }
`;

export const DesktopButtons = styled.div`
  min-width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  .log-in-button-pc {
    transition: 0.2s ease;
    &:hover {
      color: #0446ca !important;
    }
    &:focus {
      box-shadow: none;
      color: #0446ca !important;
    }
  }
  .linkWhite {
    color: #fff;
  }
  .yellowBtn {
    color: black;
    background-color: #f8d047;
    :hover {
      background-color: #f5f5f5;
    }
  }
`;

export const LoginButton = styled(StyledButton)`
  color: #000;
  background-color: transparent;
  padding: 8px 32px;
  border: none;
  font-weight: 900;
  min-width: auto;
  &:hover {
    color: #0a4ed6;
    background-color: transparent;
  }
  ${media.desktopWide} {
    padding: 8px 24px;
  }
  ${media.tablet} {
    border: 1px solid rgb(34, 34, 34);
  }
`;

export const SubMenu = styled.li`
  &::after {
    font-family: 'icomoon' !important;
    content: '\\e900';
    font-size: 6.5px;
    -webkit-transition: -webkit-transform 0.3s;
    -webkit-transition: transform 0.3s;
    transition: transform 0.3s;
    margin-right: 2rem;
    position: absolute;
    right: 0;
    transform: rotateZ(270deg);
    margin-top: -26px;
  }

  &:hover {
    ul {
      display: block;
    }
  }

  ul {
    display: none;
    padding: 19px 0px;
    position: absolute;
    background-color: #f9f9f9;
    left: 100%;
    top: 0;
    margin-left: -2px;
    min-width: 310px;
    font-size: 16px;
    border-radius: 0px 4px 4px 0;
    box-shadow: 6px 0 12px rgba(0, 0, 0, 0.16), 2px 2px 4px rgba(0, 0, 0, 0.03);
    &.solutions {
      min-width: 340px;
    }
    li {
      font-weight: 700;
      display: flex;
      align-items: center;
      & img {
        margin-right: 1rem;
        max-width: 24px;
        max-height: 24px;
      }

      .desc {
        font-size: 0.75rem;
        color: gray;
        display: flex;
        font-weight: 600 !important;
        display: block;
      }
    }
  }
`;

export const SubLink = styled(Link)`
  display: flex;
  align-items: center !important;
  padding: 10px 31px;
  width: 100%;
  & p {
    margin: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const SubLinkMore = styled(SubLink)`
  border: none;
  position: relative;
  z-index: 5;
  line-height: 38px;
  color: #0446ca !important;
  display: block !important;
  padding: 10px 31px !important;
  &::after {
    content: url(${arrowIcon}) !important;
    display: inline-block;
    clear: both;
    margin-left: 16px !important;
    transition: 0.2s ease;
  }
  &:hover {
    color: #0446ca !important;
    box-shadow: none;
    &::after {
      transform: translateX(10px);
    }
  }
`;

export const SecondaryMobileButton = styled(StyledButton)`
  background-color: #fff;
  color: #000;
`;

export const Banner = styled.a`
  height: 42px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  color: #fff;
  transition: all 0.2s ease;
  transform: translate(-15px, 0);
  position: relative;
  &:hover {
    text-decoration: none;
    & p {
      color: #fff;
    }
    & ${StyledLink} {
      color: #f8d047;
      text-decoration: underline;

      &::after {
        transform: translateX(8px);
      }
    }
  }

  ${media.mobile} {
    display: none;
  }
  & span:not(.emoji) {
    background-color: #f8d047;
    color: #000;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 12px;
    margin-right: 16px;
    font-weight: 700;
  }
  & p {
    margin-bottom: 0;
    margin-right: 24px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
  }
  & ${StyledLink} {
    font-size: 14px;
    color: #f8d047;
    font-weight: 800;
  }
  & button {
    border: none;
    background-color: transparent;
    position: absolute;
    right: 32px;
    padding: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
  }
`;

export const Integrations = styled.a`
  color: #000;
  &:hover {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  & span {
    margin-right: 16px;
  }
  margin: 0;
  white-space: nowrap;
`;
