import NewsletterTemplate from 'templates/newsletter';

const Content = () => (
  <table className="content" cellSpacing="0" cellPadding="0" align="center" width="100%">
    <tbody>
      <tr>
        <td>
          <img
            src="https://emails.livesession.io/livechat_cover.png"
            alt="livechat cover"
            className="head"
          />
        </td>
      </tr>
      <tr>
        <td>
          <table classNameName="text" cellSpacing="0" cellPadding="0" style={{ width: '100%' }}>
            <tbody>
              <tr>
                <td style={{ padding: '32px 40px 12px' }}>
                  <p style={{ margin: 0 }}>
                    <strong>We’re taking our integration with LiveChat to the next level</strong>.
                    With our presence on LiveChat Marketplace, it’ll be easier to{' '}
                    <a href="/integrations/livechat/">
                      connect LiveSession to a market-leading live chat solution
                    </a>{' '}
                    and add context to each conversation you are having.
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '12px 40px' }}>
                  <a href="/integrations/livechat/">
                    <img
                      src="https://emails.livesession.io/lc.png"
                      alt="lc"
                      style={{ maxWidth: '100%', height: 'auto', marginTop: '16px' }}
                    />
                  </a>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '12px 40px' }}>
                  <p style={{ margin: 0 }}>
                    Get to know what the user you are talking to was doing on your website and
                    enchant your visitors with excellent support!
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '28px 40px' }}>
                  <a
                    href="/integrations/livechat/"
                    target="_blank"
                    rel="noopener noreferrer"
                    classNameName="go-to-app"
                    style={{
                      display: 'block',
                      margin: '0 auto',
                      backgroundColor: '#0a4ed6',
                      padding: '10px 0',
                      fontWeight: 700,
                      color: '#fff',
                      borderRadius: '4px',
                      width: '350px',
                      textAlign: 'center',
                    }}
                  >
                    Install LiveChat integration
                  </a>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '12px 40px' }}>
                  <p style={{ margin: 0 }}>
                    As always, if you have any questions or need assistance, let me know.
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '20px 40px 15px' }}>
                  <p style={{ margin: 0 }}>Best,</p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '15px 40px 32px' }}>
                  <table className="kamil" cellSpacing="0" cellPadding="0">
                    <tr style={{ verticalAlign: 'middle' }}>
                      <th>
                        <img
                          src="https://emails.livesession.io/kamil-rounded.png"
                          className="kamil-img"
                          style={{ maxWidth: '56px' }}
                          alt="Kamil - LiveSession's CEO"
                        />
                      </th>
                      <th style={{ paddingLeft: ' 16px' }}>
                        <p
                          className="info"
                          style={{
                            margin: 0,
                            textAlign: 'left',
                            fontWeight: 400,
                            lineHeight: '140%',
                          }}
                        >
                          <strong>Kamil Drazkiewicz</strong> <br />
                          CEO @ LiveSession
                        </p>
                      </th>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
);

const Newsletter = NewsletterTemplate(Content);

const frontmatter = {
  title: 'LiveSession Newsletter April 2021',
  description: "Read LiveSession's monthly update from April 2021.",
  date: '2021-04-01',
  link: '/newsletters/april-2021/',
};

const NewsletterPage = () => <Newsletter {...frontmatter} />;

export default NewsletterPage;
