import styled from 'styled-components';
import { DropdownMenu, DropdownToggle } from 'reactstrap';

export const StyledDropdown = styled(DropdownToggle)`
  border-radius: 4px;
  border: 2px solid #000000;
  background: #fff;
  background-color: #fff;
  color: #000000;
  padding: 4px 12px;
  display: flex;
  align-items: center;
  width: 85px;
  &:hover,
  &:active,
  &:focus {
    background-color: #fff !important;
    color: #000000 !important;
    box-shadow: none !important;
    border: 2px solid #000000 !important;
  }
  & img {
    width: 14px;
    height: 8px;
    transition: transform 0.2s ease;
    transform-origin: 50% 50%;
  }
  & p {
    margin: 0 16px 0 0;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    letter-spacing: normal;
  }
`;

export const StyledDropdownMenu = styled(DropdownMenu)`
  width: 100%;
  min-width: auto;
  transform: translate3d(0px, 34px, 0px) !important;
  border: 2px solid #000000 !important;
  margin: 0;
  border-radius: 4px;
  & .dropdown-item {
    padding: 6px 12px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    &:focus,
    &:hover,
    &:active {
      background-color: #000;
      color: #fff;
    }
    &:first-child {
      border-bottom: 2px solid #000000 !important;
    }
  }
`;
