import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Prism from 'prismjs';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import * as Styled from './styled';

class CodeSnippet extends Component {
  state = { codeCoppied: false };

  componentDidMount() {
    Prism.highlightAll();
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    const { currentValue } = this.props;
    if (prevProps.currentValue !== currentValue) {
      Prism.highlightAll();
    }
  }

  handleCopy = () => {
    this.setState((prevState) => ({ codeCoppied: !prevState.codeCoppied }));
  };

  renderButton = () => {
    const { children } = this.props;
    const { codeCoppied } = this.state;
    return (
      <CopyToClipboard text={children} onCopy={this.handleCopy}>
        <Styled.CopyButton>{codeCoppied ? 'Code coppied' : 'Copy to clipboard'}</Styled.CopyButton>
      </CopyToClipboard>
    );
  };

  renderWithPre = () => {
    const { copy, lang, children, maxWidth, margin, style } = this.props;
    return (
      <Styled.Container style={style}>
        {copy && this.renderButton()}
        <Styled.Pre style={{ maxWidth, margin }}>
          <code className={`language-${lang}`}>{children}</code>
        </Styled.Pre>
      </Styled.Container>
    );
  };

  renderInline = () => {
    const { lang, children } = this.props;
    return <code className={`language-${lang}`}>{children}</code>;
  };

  render() {
    const { inline } = this.props;
    return <>{inline ? this.renderInline() : this.renderWithPre()}</>;
  }
}

export default CodeSnippet;

CodeSnippet.propTypes = {
  lang: PropTypes.string,
  copy: PropTypes.bool,
  maxWidth: PropTypes.number,
  margin: PropTypes.string,
  inline: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  style: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  // eslint-disable-next-line react/forbid-prop-types
  currentValue: PropTypes.any,
};
CodeSnippet.defaultProps = {
  lang: 'markup',
  maxWidth: 1200,
  margin: '0 0 0 0',
  inline: false,
  copy: false,
  currentValue: null,
  style: null,
};
