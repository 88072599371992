import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Link from 'components/ui/link/';
import segmentlogo from 'img/ecommerce/segment.png';
import PropTypes from 'prop-types';
import UpHeading from '../upHeading';
import * as Styled from './styled';

const ShoppingEvents = ({ header, article }) => (
  <div className="container">
    <Styled.FeaturesSection className="shopping-events">
      <div>
        <UpHeading headingText="CUSTOM EVENTS" />
        <h3>{header}</h3>
        <p>{article}</p>
        <Link withArrow href="/help/how-do-the-custom-events-work/">
          Use custom events
        </Link>
        <p>
          <strong>Connect events with your current analytics stack:</strong>
        </p>
        <div className="logo-container">
          <StaticImage
            src="../../../img/ecommerce/ga.png"
            alt="google analytics logo"
            title="Google analytics"
            style={{ maxWidth: 204 }}
            objectFit="contain"
          />
          <img src={segmentlogo} alt="segment logo" title="Segment" className="img-fluid" />
        </div>
      </div>
      <StaticImage
        src="../../../img/saas/productEvents.png"
        alt="real data to see what behaviors users are exhibiting and stop guessing when making decisions"
        style={{ zIndex: 5, maxWidth: 1600, margin: '0 auto' }}
        objectFit="contain"
      />
    </Styled.FeaturesSection>
  </div>
);

export default ShoppingEvents;

ShoppingEvents.propTypes = {
  header: PropTypes.string.isRequired,
  article: PropTypes.string.isRequired,
};
