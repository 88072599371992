import NewsletterTemplate from 'templates/newsletter';

const Content = () => (
  <table className="content" cellSpacing="0" cellPadding="0" align="center" width="100%">
    <tbody>
      <tr>
        <td>
          <a href="/help/how-do-the-funnels-work/">
            <img src="https://emails.livesession.io/funnel.png" alt="funnel" className="head" />
          </a>
        </td>
      </tr>
      <tr>
        <td>
          <table className="text" cellSpacing="0" cellPadding="0" style={{ width: '100%' }}>
            <tbody>
              <tr>
                <td style={{ padding: '32px 40px 12px' }}>
                  <p style={{ margin: 0, fontSize: '16px', lineHeight: '24px' }}>
                    We know you waited patiently for our Funnels to go live.
                    <br />
                    <br />
                    <strong>Well, the wait is over!</strong>
                    <br />
                    <br />
                    From now on, you can recreate your users’ paths and track sequences of clicks or
                    events that lead to meeting the goals you set.
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '12px 40px' }}>
                  <h1 style={{ margin: 0 }}>
                    <span>What are Funnels?</span>
                  </h1>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '12px 40px' }}>
                  <p style={{ margin: 0, fontSize: '16px', lineHeight: '24px' }}>
                    A funnel (or a conversion funnel) is a set of actions your user should take
                    visiting your website. Each funnel ends with a goal specific to the industry
                    you’re in. For example, if you run an eCommerce store, your funnel should end
                    with a purchase. For SaaS, it would be subscribing to one of your plans. As an
                    independent consultant, you probably want your potential customers to schedule a
                    meeting or at least leave their phone number and email address.
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '28px 40px' }}>
                  <a
                    href="https://app.livesession.io/app/funnels/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="go-to-app"
                    style={{
                      display: 'block',
                      margin: '0 auto',
                      backgroundColor: '#0a4ed6',
                      padding: '10px 0',
                      fontWeight: 700,
                      color: '#fff',
                      borderRadius: '4px',
                      maxWidth: '350px',
                      textAlign: 'center',
                      textDecoration: 'none',
                      fontSize: '16px',
                    }}
                  >
                    Start using Funnels
                  </a>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '12px 40px' }}>
                  <h1 style={{ margin: 0 }}>
                    <span>How do the Funnels work?</span>
                  </h1>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '12px 40px' }}>
                  <p style={{ margin: 0, fontSize: '16px', lineHeight: '24px' }}>
                    Watch a{' '}
                    <a href="https://www.youtube.com/watch?v=Ai-bhGjD3i0/">
                      short video introduction to the LiveSession Funnels on YouTube
                    </a>
                    :
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '0 40px 12px 40px' }}>
                  <a
                    href="https://www.youtube.com/watch?v=Ai-bhGjD3i0/"
                    style={{ textDecoration: 'none' }}
                  >
                    <img
                      src="https://emails.livesession.io/funnels_600.gif"
                      alt="funnels"
                      style={{ maxWidth: '100%', height: 'auto', marginTop: '16px' }}
                    />
                  </a>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '12px 40px 32px' }}>
                  <p style={{ margin: 0, fontSize: '16px', lineHeight: '24px' }}>
                    ...or <a href="/help/how-do-the-funnels-work/">read more on our website</a>.
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '12px 0;' }}>
                  <div style={{ width: '100%', height: '1px', backgroundColor: '#ddd' }} />
                </td>
              </tr>
              <tr>
                <td style={{ padding: '32px 40px 12px' }}>
                  <h1 style={{ margin: 0 }}>
                    <span>REST API</span>
                  </h1>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '0 40px 12px 40px' }}>
                  <a href="https://developers.livesession.io/rest-api/introduction/">
                    <img
                      src="https://emails.livesession.io/rest_api_2.png"
                      alt="rest api"
                      style={{ maxWidth: '100%', height: 'auto', marginTop: '16px' }}
                    />
                  </a>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '12px 40px 32px' }}>
                  <p style={{ margin: 0 }}>
                    We&apos;ve also launched a beta version of LiveSession&apos;s REST API. From now
                    on, you&apos;ll be able to retrieve information about your data
                    programmatically. You can, for example, use the API for a deeper analysis of the
                    data collected by LiveSession.
                    <br />
                    <a
                      href="https://developers.livesession.io/rest-api/introduction/"
                      className="with-arrow"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ fontSize: '16px', color: '#0a4ed6', marginTop: '12px' }}
                    >
                      Read more on our website
                    </a>
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '12px 0' }}>
                  <div style={{ width: '100%', height: '1px', backgroundColor: '#ddd' }} />
                </td>
              </tr>
              <tr>
                <td style={{ padding: '32px 40px 12px' }}>
                  <h1 style={{ margin: 0 }}>
                    <span>New on our blog</span>
                  </h1>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '12px 40px' }}>
                  <div>
                    <a
                      href="/blog/how-to-ab-test-saas-pricing-pages-in-2021/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://emails.livesession.io/202106_blog_post.png"
                        alt="badge"
                        title="How to A/B test SaaS pricing pages in 2021"
                        style={{
                          maxWidth: '100%',
                          height: 'auto',
                          marginTop: '16px',
                          borderRadius: '4px',
                        }}
                      />
                    </a>
                    <div style={{ marginTop: '24px' }}>
                      <p style={{ fontSize: '20px', marginBottom: '12px' }}>
                        <strong>How to A/B test SaaS pricing pages in 2021</strong>
                      </p>
                      <p style={{ color: '#9b9b9b', marginBottom: '12px' }}>
                        Testing pricing pages is risky – increase your prices and customers will
                        riot on social media. However, you don’t have to be that radical! There are
                        a few ways you can increase your conversion on a pricing page without
                        actually changing prices. In this article, we will show what page elements
                        you should test to avoid shooting yourself in the foot when doing pricing
                        page tests.
                      </p>
                      <a
                        href="/blog/how-to-ab-test-saas-pricing-pages-in-2021/"
                        className="with-arrow"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ fontSize: '16px', color: '#0a4ed6', marginTop: '12px' }}
                      >
                        Read more on our blog
                      </a>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '12px 40px' }}>
                  <p style={{ margin: 0, fontSize: '16px', lineHeight: '24px' }}>
                    If you have any questions, let me know by replying to this email. Follow us on{' '}
                    <a href="https://www.facebook.com/LiveSessionIO/">Facebook</a>,{' '}
                    <a href="https://twitter.com/livesessionhq/">Twitter</a> and{' '}
                    <a href="https://www.linkedin.com/company/livesession/">LinkedIn</a>. Let’s stay
                    in touch!
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '20px 40px 15px' }}>
                  <p style={{ margin: 0, fontSize: '16px', lineHeight: '24px' }}>Best, </p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '15px 40px 32px' }}>
                  <table className="kamil" cellSpacing="0" cellPadding="0">
                    <tr style={{ verticalAlign: 'middle' }}>
                      <th>
                        <img
                          src="https://emails.livesession.io/pete_rounded.png"
                          className="kamil-img"
                          style={{ maxWidth: '56px' }}
                          alt="Kamil - LiveSession's CEO"
                        />
                      </th>
                      <th style={{ paddingLeft: '16px' }}>
                        <p
                          className="info"
                          style={{
                            margin: 0,
                            textAlign: 'left',
                            fontWeight: 400,
                            lineHeight: '140%',
                            fontSize: '16px',
                          }}
                        >
                          <strong style={{ fontWeight: 800 }}>Pete Pal</strong> <br />
                          Marketing Manager @ LiveSession
                        </p>
                      </th>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
);

const Newsletter = NewsletterTemplate(Content);

const frontmatter = {
  title: 'LiveSession Newsletter June 2021',
  description: "Read LiveSession's monthly update from June 2021.",
  date: '2021-06-01',
  link: '/newsletters/june-2021/',
};

const NewsletterPage = () => <Newsletter {...frontmatter} />;

export default NewsletterPage;
