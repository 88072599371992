import { useStaticQuery, graphql } from 'gatsby';
import Layout from 'layout';
import Header from 'components/customer/header';
import Recommendation from 'components/customer/recommendation';
import StartUsing from 'components/ui/startUsing';
import FinalOutcome from 'components/customer/finalOutcome';
import {
  HeroImage,
  SectionWithRecommendation,
  DescriptionBox,
  StyledSection,
  StyledImg,
} from 'components/styled/CustomerPage';
import Container from 'components/customer/breadcrumbs';

const RocketLink = () => {
  const { hero, img1, img2, img3, wjasnos, post } = useStaticQuery(graphql`
    query rocketlinkPhotos {
      img1: file(relativePath: { eq: "rocketlink/1.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 80)
        }
      }
      img2: file(relativePath: { eq: "rocketlink/2.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 80)
        }
      }
      img3: file(relativePath: { eq: "rocketlink/3.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 80)
        }
      }
      hero: file(relativePath: { eq: "rocketlink/main.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 80)
        }
      }
      wjasnos: file(relativePath: { eq: "persons/wjasnos.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      post: markdownRemark(frontmatter: { company: { eq: "Rocketlink" } }) {
        frontmatter {
          description
          title
          slug
          company
          featuredimage {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  `);

  const { frontmatter } = post;

  const imgSocial = process.env.GATSBY_HOST + hero.childImageSharp.gatsbyImageData.src;
  const descriptionSocial = frontmatter.description;
  const { title } = frontmatter;
  const url = '/customers/rocketlink/';

  const extraMetaTags = [
    { property: 'fb:app_id', content: '926720414193529' },
    { property: 'og:type', content: 'article' },
    { property: 'og:url', content: process.env.GATSBY_HOST + url },
    { property: 'og:title', content: title },
    { property: 'og:site_name', content: 'LiveSession' },
    { name: 'twitter:title', content: title },
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:site', content: '@LiveSessionHQ' },
  ];

  if (imgSocial) {
    extraMetaTags.push({ property: 'og:image', content: imgSocial });
    extraMetaTags.push({
      property: 'og:image:secure_url',
      content: imgSocial,
    });
    extraMetaTags.push({ property: 'og:image:width', content: 1287 });
    extraMetaTags.push({ property: 'og:image:height', content: 685 });
    extraMetaTags.push({ name: 'twitter:image', content: imgSocial });
  }

  if (descriptionSocial) {
    extraMetaTags.push({
      property: 'og:description',
      content: descriptionSocial,
    });
    extraMetaTags.push({
      name: 'twitter:description',
      content: descriptionSocial,
    });
  }

  return (
    <Layout
      metaTitle="How RocketLink got 40% more paid subscriptions thanks to LiveSession"
      titleOverride
      metaDescription={frontmatter.description}
      extraTags={extraMetaTags}
      canonical="/customers/rocketlink/"
    >
      <div className="container">
        <Container title="Rocketlink">
          <Header
            title={
              <>
                How RocketLink got <span> 40% more paid </span> subscriptions thanks to LiveSession{' '}
              </>
            }
            description="Improved onboarding, 110% increase in signups and reduced churn rates."
          />
          <HeroImage
            image={hero.childImageSharp.gatsbyImageData}
            clasName="img-fluid"
            alt="website - hero"
            title="Rocketlink website"
          />
          <SectionWithRecommendation>
            <DescriptionBox>
              <p>
                RocketLink is a link shortening tool that allows you to add retargeting pixels to
                your URLs. This handy feature can be used to create custom audiences for PPC ads and
                increase conversion rates in a smart way.
              </p>
              <p>
                Wojtek Jasnos, the CEO and founder of RocketLink, knows that little details can make
                a huge change. This is why he started to use LiveSession – and this is how it helped
                him grow the business:
              </p>
            </DescriptionBox>
            <FinalOutcome>
              <h2>Final outcome</h2>
              <p>
                We’ve got a couple of numbers to prove the effectiveness. After fixing a couple of
                bugs and improving UX design, RocketLink has seen:
              </p>
              <ul>
                <li>
                  <p>
                    <strong>110% </strong>increase in sign-ups
                  </p>
                </li>
                <li>
                  <p>
                    over <strong> 40% </strong>increase in paid subscriptions
                  </p>
                </li>
                <li>
                  <p>
                    reduced churn rate by <strong>40% </strong>
                  </p>
                </li>
              </ul>
            </FinalOutcome>
          </SectionWithRecommendation>
          <Recommendation
            recommendation="Monitoring how existing users interact with the RocketLink app is not the only benefit 
              of using LiveSession. We can also record sessions on our homepage and all other pages. 
              That allows us to understand what our users do on our website before they sign up."
            person="Wojciech Jasnos"
            job="CEO"
            company="Rocketlink"
            image={wjasnos.childImageSharp.gatsbyImageData}
            link="https://rocketlink.io/"
          />
          <StyledSection>
            <h2>Constant improvement</h2>
            <p>
              Wojtek believes that a SaaS company needs to keep improving to meet the users’
              expectations. While a lot of ideas come from RocketLink team members, many new
              features are answering direct customer requests.
            </p>
            <p>
              The in-house team knows how important it is to test every functionality. They’re also
              fully aware that some bugs are hard to discover as they may occur only in particular
              scenarios. RocketLink was receiving a lot of{' '}
              <strong>support tickets regarding issues that can’t be reproduced</strong>. Talking to
              users isn’t always enough to know what caused the problem. How can you tackle this
              effectively? Here’s where LiveSession comes in handy. The session can be replayed
              right away, the problem diagnosed, and the bug fixed.
            </p>
          </StyledSection>
          <StyledSection>
            <h2>The first to know</h2>
            <p>
              Wojtek believes that a SaaS company needs to keep improving to meet the users’
              expectations. While a lot of ideas come from RocketLink team members, many new
              features are answering direct customer requests.
            </p>
            <p>
              RocketLink’s Wojtek claims that using LiveSession is{' '}
              <strong>like having CCTV cameras in all parts of the app</strong> or a website. What’s
              more, monitoring customer behavior is not just about fixing bugs. It also helps you
              improve the existing interface and make it more convenient for the user.
            </p>
          </StyledSection>
          <StyledSection>
            <h2>Common issues</h2>
            <p>
              So, what has RocketLink learned thanks to LiveSession? First of all, they finally
              found out why some customers can’t configure the tool. It was because of an{' '}
              <strong>invalid format of the pixel ID</strong> that the users added to their links.
            </p>
            <p>
              As we’ve mentioned before, RocketLink allows adding retargeting pixels to any URL.
              Facebook Pixel is one of the most popular retargeting solutions for in shortened
              links. To implement it, RocketLink needs the Facebook Pixel ID, while some customers
              were trying to paste the whole code snippet instead. This indicated that the users
              need more guidance to get this step right.
            </p>
            <StyledImg
              image={img1.childImageSharp.gatsbyImageData}
              alt="website - dashboard"
              title="Common issues"
              className="img-fluid"
            />
            <p>
              Another issue that RocketLink identified thanks to LiveSession was related to{' '}
              <strong>using a discount code</strong>.
            </p>
            <p>
              One user reached out via live chat because his code was not working. He provided a
              valid discount code and claimed that it wasn’t recognized by the application. After
              watching the session recording, it turned out that he was trying to paste the code
              together with an <strong>extra space character</strong>. At that time, RocketLink app
              couldn’t trim the code. Once the cause became clear, the RocketLink team could solve
              the problem very easily and prevent this from happening in the future.
            </p>
          </StyledSection>
          <StyledSection>
            <h2>Improving the onboarding process</h2>
            <p>
              Onboarding is a critical part of the user journey – it often decides whether the
              customer will stay or not. This is why RocketLink decided to create a{' '}
              <strong>step-by-step checklist guide</strong> to configuring and using the app.
            </p>
            <p>
              Unfortunately, one of the steps on the checklist wasn’t working properly. The „Add a
              custom domain” tutorial was marked as completed right after a new user signed up and
              hasn’t added any domains yet. Here’s how it looked like:
            </p>
            <StyledImg
              image={img2.childImageSharp.gatsbyImageData}
              alt="website - your domains"
              title="Improving the onboarding process"
              className="img-fluid"
            />
            <p>
              This problem only affected a few accounts and wasn’t noticed after implementing the
              checklist feature. Thanks to LiveSession, the RocketLink team could spot the issue
              before anyone else.
            </p>
          </StyledSection>
          <StyledSection>
            <h2>Understanding the customer journey</h2>
            <p>
              LiveSession helped RocketLink find out that many visitors click on the feature cards
              on the homepage. The cards, however, are not clickable. Although it is not a bug, it
              definitely indicates that potential customers would like to learn more about the
              features. With this in mind, the team decided to{' '}
              <strong>add short feature descriptions</strong>:
            </p>
            <StyledImg
              image={img3.childImageSharp.gatsbyImageData}
              alt="website - features"
              title="Improving the onboarding process"
              className="img-fluid"
            />
          </StyledSection>
          <FinalOutcome lastSection>
            <h2>Final outcome</h2>
            <p>
              RocketLink is happy with LiveSession, but what’s in it for the business? We’ve got a
              couple of numbers to prove the effectiveness. After fixing a couple of bugs and
              improving UX design, RocketLink has seen:
            </p>
            <ul>
              <li>
                <p>
                  <strong>110% </strong>increase in sign-ups
                </p>
              </li>
              <li>
                <p>
                  over <strong> 40% </strong>increase in paid subscriptions
                </p>
              </li>
              <li>
                <p>
                  reduced churn rate by <strong>40% </strong>
                </p>
              </li>
            </ul>
          </FinalOutcome>
        </Container>
      </div>
      <StartUsing />
    </Layout>
  );
};

export default RocketLink;
