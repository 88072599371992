import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import Link from 'components/ui/link/';
import StartUsing from 'components/ui/startUsing';
import { useFeatureData } from 'data/features';
import WorksWith from 'components/index/worksWith';
import TrustedBy from 'components/ui/trustedBy';
import FeaturesSlider from 'components/index/featuresSlider';
import SignUpForm from 'components/ui/signupForm';
import badge from 'img/index/GDPRbadge.svg';
import NewLogo from 'components/index/newLogo/index';
import badge2 from 'img/index/CCPAbadge.svg';
import * as Styled from './styled';

const Features = () => {
  const data = useFeatureData();
  return (
    <>
      <Styled.FeaturesContainer className="container">
        <Styled.Header>
          <h1 className="line">
            An amazing blend of <span>features</span> to help you grow
          </h1>
          <p className="page-desc">Because everything has to work together just right</p>
          <SignUpForm />
        </Styled.Header>
        <FeaturesSlider
          sectionTitle={
            <h2 className="line">
              The essentials for <br /> <span className="fillviolet">successful UX</span>
            </h2>
          }
          sectionDesc="Use all the necessary tools LiveSession provides you with to enhance your website’s UI. Grow your user base or sell more thanks to well-thought design improvements with a little help of:"
          cards={data.yellow.cards}
          images={data.yellow.images}
          alternativeText={data.yellow.alt}
        />
        <div className="mpclear p-120-90 pb-168-120-90">
          <TrustedBy hero />
        </div>

        <FeaturesSlider
          sectionTitle={
            <h2 className="line">
              Focus on things that <br />
              <span className="fillViolet">bring real value</span>
            </h2>
          }
          sectionDesc="Shorten the time needed to do your research. Never miss an important session again."
          cards={data.purple.cards}
          images={data.purple.images}
          reversed
          alternativeText={data.purple.alt}
          color="#8864FE"
          className="secondary"
        />
        <div className="mpclear p-168-120-90 pb-240-168-120-90">
          <FeaturesSlider
            sectionTitle={
              <h2 className="line">
                <span className="fillBlue">Find regularities</span> <br /> in your users&apos;
                behavior
              </h2>
            }
            sectionDesc="Gather insights into your users’ behavior. Rethink your website’s flow to recreate conditions in which your visitors convert. Improve your customer support."
            cards={data.blue.cards}
            color="#7DFFEE"
            alternativeText={data.blue.alt}
            images={data.blue.images}
            className="secondary"
          />
        </div>
        <Styled.PersonalDataSection>
          <div className="content">
            <h2>Personal data protection and privacy are rooted in our values</h2>
            <p>
              We allow you to anonymize text and images containing sensitive data. Take care of your
              customers&apos; security. LiveSession is GDPR and CCPA compliant.
            </p>
            <Link withArrow href="/help/how-to-anonymize-sensitive-page-content/">
              See how to hide sensitive data
            </Link>
            <Styled.ImageContainer>
              <Link href="/help/gdpr/">
                <img src={badge} alt="GDPR badge" title="GDPR Compiliant" className="img-fluid" />
              </Link>
              <Link href="/help/ccpa/">
                <img src={badge2} alt="CCPA badge" title="CCPA Compiliant" className="img-fluid" />
              </Link>
            </Styled.ImageContainer>
          </div>
          <StaticImage
            src="../../img/featurePhotos/personaldata.png"
            objectFit="contain"
            objectPosition="center center"
            className="main-image"
            alt="a session showing how livesession takes care of security to make sure everything is RODO and CCPA compliant"
          />
        </Styled.PersonalDataSection>
        <div className="p-240-168-120-90">
          <Styled.MainFeaturesSection>
            <h2 className="line">
              Find <span>the most valuable sessions</span> in a blink of an eye
            </h2>
            <p className="subtitle">Focus on finding solutions, not searching for problems</p>
            <Styled.CardContainer>
              {data.featureCards.map((item) => (
                <div key={item.title}>
                  <GatsbyImage image={item.image} alt={item.title} />
                  <h3>{item.title}</h3>
                  {item.desc}
                </div>
              ))}
            </Styled.CardContainer>
          </Styled.MainFeaturesSection>
        </div>
      </Styled.FeaturesContainer>
      <div className="mpclear">
        <WorksWith />
      </div>
      <div className="mpclear pt-168-120-90">
        <NewLogo />
      </div>
      <StartUsing />
    </>
  );
};

export default Features;
