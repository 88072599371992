import { authorAnia, authorKasia } from 'data/authors';
import Link from 'components/ui/link';

import cover from 'img/heatmaps/cover/heatmaps1.png';

export const data = [
  {
    url: '/heatmaps/',
    title: 'Introduction',
    desc: 'The only guide you need to use heatmaps effectively',
    metaDesc:
      'Heatmaps are your best friend when it comes to website optimization. Read our comprehensive guide to learn everything you need to know about using heatmaps ',
    timeToRead: 8,
    author: authorKasia,
    date: 'June 09, 2022',
    heading: (
      <>
        <p>
          Trying to optimize your website without using the right tools such as heat maps is like
          walking in a jungle without a compass – you’re completely lost. A web page heat map helps
          you better understand how your visitors navigate around your site. Without having access
          to one, you’ll be playing a guessing game that you’ll either win if you’re lucky or lose
          miserably. Either way, it will take you much longer to figure out how you can improve your
          site than with a heat map, so why waste time?
        </p>

        <p>
          In the following guide, we’re going to tell you what a heat map is, which different types
          you can use, and how you can benefit from them. We will also cover some best practices and
          common mistakes that are worth avoiding. Ultimately, we will share some UX improvements
          that business experts achieved by using heat maps and recommend the right tools to
          supplement your findings with. Let’s dive right in.
        </p>
      </>
    ),
    button: {
      text: 'Improve your website',
    },
    cover,
  },
  {
    url: '/heatmaps/what-is-a-heat-map/',
    title: 'Chapter 1',
    desc: 'How to define heat maps and what types you can use for your business',
    metaDesc:
      'Heat maps are a great tool for website optimization. Check out this article to learn about the types of heat maps you can use and their benefits.',
    timeToRead: 8,
    author: authorAnia,
    date: 'June 09, 2022',
    heading: (
      <>
        <p>
          Did you know that heat maps were already{' '}
          <Link
            href="https://www.cio.com/article/3398938/8-cool-heat-maps-that-help-you-visualize-big-data.html"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            used in the 1800s
          </Link>
          ? Since then, as technology progressed, these 2D data visualization tools have evolved and
          are being used in various business areas, including education, digital marketing, and even
          healthcare and fitness apps.
        </p>

        <p>
          In this article, we will tackle the question “what is a heat map”, discuss how you use
          heat map colors to define data, and what the different types of AI-generated heatmaps are.
          .
        </p>

        <p>
          Let’s start by looking at the heat map meaning, and how various types of heat map can
          provide exceptional value to your business.
        </p>
      </>
    ),
    button: {
      text: 'Improve your website',
    },
    cover,
  },
  {
    url: '/heatmaps/generating-heat-maps/',
    title: 'Chapter 2',
    desc: 'How to create a heat map - best practices & mistakes to avoid',
    metaDesc: `Generating heat maps can help you improve UX and boost conversion – provided that you know how to use them. Here are the best practices and mistakes to avoid.`,
    timeToRead: 8,
    author: authorKasia,
    date: 'June 09, 2022',
    heading: (
      <>
        <p>
          Do you know how to create a heat map? If you run a website, there are many reasons why you
          should. If used properly, they can help improve your UX, which will make navigating your
          site easier for users.
        </p>

        <p>
          In this blog post, we will take a closer look at how to make a heat map, what the best
          practices for heat map creators are, and some common mistakes that you should avoid to
          ensure clarity and relevance.{' '}
        </p>
      </>
    ),
    button: {
      text: 'Improve your website',
    },
    cover,
  },
  {
    url: '/heatmaps/heat-maps-for-website/',
    title: 'Chapter 3',
    desc: 'How to use heat maps to improve your website',
    metaDesc: `Wondering how to improve your website? Read our article to learn how to leverage website heatmap tools to provide a great user experience.`,
    timeToRead: 8,
    author: authorAnia,
    date: 'June 09, 2022',
    heading: (
      <>
        <p>
          {' '}
          Did you know that it takes{' '}
          <Link
            href="https://neilpatel.com/blog/on-site-behavior/"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            just 50 milliseconds
          </Link>{' '}
          for a website visitor to form an opinion about it? This means you need to create a website
          that provides a great user experience, even on the very first impression. One of the best
          ways to discover and understand how you can optimize your page for visitors is by using
          heat map solutions.
        </p>

        <p>
          In the following article, you’ll learn how to improve a website following a heat map
          report. Specifically, we will help you understand how they give insight into users’
          interactions and how you can leverage these findings on your homepage, blog, and/or
          landing pages. We will also give you examples of how heat maps on websites can help you
          boost user engagement rates, optimize your customer journey paths, and improve the
          conversion process in general.
        </p>
      </>
    ),
    button: {
      text: 'Improve your website',
    },
    cover,
  },
  {
    url: '/heatmaps/click-maps/',
    title: 'Chapter 4',
    desc: 'What are click maps and how can they be used to boost engagement?',
    metaDesc: `Are you struggling with low conversion rates? Learn how to use click maps to boost user engagement and as a result drive higher conversion. `,
    timeToRead: 7,
    author: authorKasia,
    date: 'June 09, 2022',
    heading: (
      <>
        <p>
          Understanding where visitors click on your website is arguably one of the most important
          UX-related insights that you can get from a business perspective. To put it simply, each
          click translates into a specific action that either brings customers closer to or further
          from completing a goal. If analyzed and tracked correctly, clicks can be a genuine user
          data goldmine.{' '}
        </p>

        <p>
          In this article, we will talk about what click maps are and how they can boost web
          engagement. We will also take a look at some tools that you can use with click maps to
          help you further improve your website’s performance. Let’s start by taking a look at what
          a click map involves.{' '}
        </p>
      </>
    ),
    button: {
      text: 'Improve your website',
    },
    cover,
  },
  {
    url: '/heatmaps/heatmap-analysis/',
    title: 'Chapter 5',
    desc: 'How to analyze heatmaps – an actionable guide',
    metaDesc: `Want to use a heatmap, but don’t know how to interpret the results? Read on to learn how to best approach heatmap analysis and what other tools to use!`,
    timeToRead: 7,
    author: authorKasia,
    date: 'June 09, 2022',
    heading: (
      <>
        <p>
          {' '}
          If you own a website, chances are that you’ve considered setting up a heatmap. Perhaps you
          even have one set up already, but find it troubling to make sense of all the color schemes
          or marks and can’t properly interpret your findings. Although it might seem overwhelming
          at first, with the right instructions, conducting and drawing conclusions from your
          heatmap analysis will be pretty straightforward!
        </p>

        <p>
          In the following article, we’ll explain how to read a heatmap and share a few best
          practices. We’ll also tell you about some other heatmap tools that you may find useful to
          gain a solid understanding of what your audience thinks about your site and needs from a
          service like yours.
        </p>

        <p>Let’s jump right in.</p>
      </>
    ),
    button: {
      text: 'Improve your website',
    },
    cover,
  },
  {
    url: '/heatmaps/heat-mapping-tool/',
    title: 'Chapter 6',
    metaTitle: 'The only heat mapping tool you will ever need',
    desc: 'The Top Benefits of Using Heatmaps on your Website',
    metaDesc: `Heat mapping tools can bring significant benefits to your business from eliminating usability issues to making better business decisions. Click here to learn more.`,
    timeToRead: 8,
    author: authorAnia,
    date: 'June 09, 2022',
    heading: (
      <>
        <p>
          {' '}
          In a report by CareerFoundry, Microsoft UX Designer{' '}
          <Link
            href="https://images.careerfoundry.com/public/theuxschool/uploads/The_Trillion_Dollar_UX_Problem.pdf"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            Joanna Ngai
          </Link>{' '}
          admits that ”design-driven businesses have outperformed the S&P by a whopping 228% over
          the past 10 years. The bottom line, good design = good business.”
        </p>

        <p>
          Needless to say, if you aren’t using a way to track user engagement and website
          performance, you’re missing out on a lot of potential business growth coming from creating
          a positive user experience. Without a doubt, one of the best ways to gain plenty of
          insights into your audience is using a heatmapping tool. Among their many benefits, heat
          maps help to expose any possible issues with your website so that you can ensure things
          are running as effectively as possible. They also help to boost on-site engagement by
          verifying whether or not users see the most important information and are guided
          purposefully through content.
        </p>

        <p>
          In the following article, we’re going to shed light on these and the other main advantages
          of using heatmap software.{' '}
        </p>
      </>
    ),
    button: {
      text: 'Improve your website',
    },
    cover,
  },
  {
    url: '/heatmaps/heat-map-software/',
    title: 'Chapter 7',
    desc: 'How to use heat map software with other tools',
    metaDesc: `Wondering which tools you should use together with your heat map software, and how? Here are the 6 types of tools we recommend along with examples.`,
    timeToRead: 8,
    author: authorAnia,
    date: 'June 09, 2022',
    heading: (
      <>
        <p>
          {' '}
          As far as website UX and user intent are concerned, heat maps are easily one of the top
          tools that you should be using. Among others, they indicate which elements are most viewed
          and clicked, how far down a page people scroll, and which pieces of content they fail to
          notice. While they’re a great stand-alone tool, heatmap tools become even more powerful if
          you combine them with other types of software
        </p>

        <p>
          In the following piece, we’re going to take a look at seven types of tools that you can
          use jointly with your heat mapping software. We’ll explain what exactly each combination
          brings to the table and provide you with recommendations of specific tools available on
          the market.
        </p>

        <p>Let’s get to it.</p>
      </>
    ),
    button: {
      text: 'Improve your website',
    },
    cover,
  },
  {
    url: '/heatmaps/ux-improvements/',
    title: 'Chapter 8',
    desc: '11 experts share the UX improvements they achieved with heat maps',
    metaDesc: `Interested in learning about the UX improvements you can achieve with heat maps? Learn how 11 experts were able to use them to boost business growth!`,
    timeToRead: 11,
    author: authorKasia,
    date: 'June 09, 2022',
    heading: (
      <p>
        While heat maps are widely used by businesses of all shapes and sizes to improve website
        usability, we’ve decided to talk to 11 business experts to learn specifically what UX
        improvements they’ve achieved. Here’s what we’ve learned, and are now happy to share with
        you.
      </p>
    ),
    button: {
      text: 'Improve your website',
    },
    cover,
  },
];
