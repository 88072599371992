/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from 'layout';
import TrustedBy from 'components/ui/trustedBy';
import Testimonial from 'components/index/testimonial';
import Logo from 'components/layout/logo';
import NewToolIntegrations from 'components/index/newToolStack';
import StartUsingCTA from 'components/index/startusingCTA';
import StartUsing from 'components/ui/startUsing';
import FeatureCards from 'components/ui/features';
import WrapperCard from 'components/ui/features/wrapperCard';
import HeaderSection from 'components/ads/HeaderSection';
import GrayCard from 'components/ui/features/grayCard';
import styled from 'styled-components';
import Button from 'components/ui/button';
import logrocket from 'img/companyLogo/logLogRocket.svg';
import checkmark from 'img/ui/checkmarkGreen.svg';
import { media } from 'theme/breakpoints';
import PlanTable from 'components/ads/AlternativeTable';
import Separator from 'components/separator';

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 27px auto 84px;
  width: 100%;

  ${media.mobile} {
    margin: 27px 0 38px;
  }
`;

const Container = styled.div`
  .features {
    text-align: center;
  }
  .bgcWrapper {
    background-color: #f5f5f5;
  }
  .solutions {
    margin-top: 42px;
    margin-bottom: -56px;
  }
  ${media.desktop} {
    .solutions {
      margin-top: 42px;
      margin-bottom: 0;
    }
  }
  ${media.desktopWide} {
    .features {
      display: none;
      margin-top: 80px;
    }
  }
  ${media.mobile} {
    .solutions {
      margin-top: 72px;
    }
  }
  .waveGray {
    background: linear-gradient(180deg, #f5f5f5 0%, #f5f5f5 51.35%, rgba(245, 245, 245, 0) 104.06%);
    padding-bottom: 0;
  }

  .listWrapper {
    position: relative;
    justify-content: space-between;
    display: flex;
    ${media.desktopWide} {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    & h2 {
      display: flex;
      text-align: left;
      width: 307px;
      align-items: center;
      ${media.desktopWide} {
        padding-bottom: 32px;
        display: block;
        width: 100%;
        text-align: center;
      }
    }
    & ul {
      list-style: none;
      padding-left: 16px;
      margin: 0;
      ${media.desktopWide} {
        width: 420px;
      }
      ${media.mobile} {
        width: 100%;
        padding-left: 34px;
      }
      & li {
        font-weight: 700;
        font-size: 22px;
        line-height: 34px;
        color: #000;
        margin-bottom: 32px;
        position: relative;
        &::before {
          content: '';
          background-image: url(${checkmark});
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center center;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translate(-42px, -50%);
          width: 18px;
          height: 14px;
          ${media.mobile} {
            width: 14px;
            height: 12px;
            transform: translate(-34px, -50%);
          }
        }
        ${media.tablet} {
          font-size: 18px;
          margin-bottom: 24px;
        }
      }
    }
  }
  .startAds {
    section {
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
      border-radius: 8px;
    }
  }
  .startCTA {
    section {
      padding: 48px;
      background-color: #f8d047;
    }
  }
`;

const Ad = ({ data }) => {
  const post = data.markdownRemark.frontmatter;
  return (
    <Layout
      noIndex
      metaTitle={`Try LiveSession as a better alternative to ${post.title}`}
      metaDescription="A better way to analyze customer behaviour on your website."
      adPage
      hideHeader
    >
      <Container>
        <Header className="container">
          <Logo />
          <Button secondary signUp sourceID="header" small>
            Sign up free
          </Button>
        </Header>
        <div className="container">
          <HeaderSection title={post.title} />
        </div>
        <div className="waveGray">
          <div className="p-120-90 listWrapper container">
            <h2 className="line">Why LiveSession is better:</h2>
            <ul>
              <li>Premium level session recordings</li>
              <li>Advanced clickmaps with heatmaps</li>
              <li>50+ Filters and Segments</li>
            </ul>
            <ul>
              <li>Custom searchable session data</li>
              <li>Console logs & Network logs</li>
              <li>Custom events</li>
            </ul>
          </div>
        </div>
        {post.title === 'LogRocket' && (
          <>
            <Separator />
            <PlanTable
              price="500"
              logoCompany={logrocket}
              companyName="Logrocket"
              EngagementScoreCompany
              InspectModeCompany
              EventAlertsCompany
            />
          </>
        )}
        <div className="container">
          <TrustedBy saas style={{ padding: 0, paddingBottom: '90px' }} />
          <div className="separator" />
        </div>
        <h2 className="pt-168-120-90 features">Check LiveSession features</h2>

        <div className="p-120-90">
          <FeatureCards
            text="Use all the necessary tools LiveSession provides you with to enhance your website’s UI. Grow your user base or sell more thanks to well-thought design improvements."
            primary
          >
            <h2 className="line">
              The essentials for <span>successful UX</span>
            </h2>
          </FeatureCards>
        </div>

        <div className="waveGray" />
        <div className="p-120-90 bgcWrapper">
          <GrayCard text="Gather insights into your users’ behavior. Rethink your website’s flow to recreate conditions in which your visitors convert. Improve your customer support.">
            <h2 className="line">
              <span>Find regularities</span> in your users' behavior
            </h2>
          </GrayCard>
        </div>
        <div className="waveGrayBottom" />
        <div className="p-168-120-90">
          <FeatureCards text="Shorten the time needed to do your research. Never miss an important session again.">
            <h2 className="line">
              Focus on things that are bring <span>real value</span>
            </h2>
          </FeatureCards>
        </div>
        <div className="mclear startAds startCTA">
          <StartUsingCTA />
        </div>
        <div className="p-168-120-90">
          <div className="waveGray" />
          <section className="bgcWrapper">
            <NewToolIntegrations />
          </section>
          <div className="waveGrayBottom" />
        </div>
        <WrapperCard />
        <div className="mpclear p-168-120-90">
          <Testimonial />
        </div>
        <div className="mclear pb-120-90 startAds">
          <StartUsing />
        </div>
      </Container>
    </Layout>
  );
};

export default Ad;

export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
      }
      html
    }
  }
`;
