import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import { GridBox } from 'components/index/hero/styled';

export const Header = styled.header`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  padding: 168px 0 0;
  & div.gatsby-image-wrapper {
    width: 722px;
    height: 529.11px;
    transform: translateX(145px);
  }
  & h1 {
    margin-bottom: 24px;
  }
  & p {
    font-size: 22px;
    line-height: 34px;
    margin-bottom: 32px;
  }
  & .gridbox-container .start {
    margin-top: 0;
  }
  & ${GridBox} {
    margin-bottom: 0;
  }
  ${media.desktopWide} {
    & div.gatsby-image-wrapper {
      width: 500px;
      height: auto;
      transform: translateX(70px);
    }
  }
  ${media.desktop} {
    & div.gatsby-image-wrapper {
      display: none;
    }
  }
  ${media.desktop} {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, min-content);
    align-items: flex-start;
    & ${GridBox} {
      grid-row-gap: 0;
    }
  }
  ${media.tablet} {
    padding: 168px 0 90px;
  }
  ${media.mobile} {
    padding: 120px 0 90px;
    text-align: center;
    & p {
      font-size: 18px;
      line-height: 30px;
    }
    & div.gatsby-image-wrapper {
      display: none;
    }
    & ${GridBox} {
      width: auto;
      padding: 0;
      margin-bottom: 0;
    }
  }
`;

export const FeaturesSection = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: 'photo content';
  align-items: center;
  grid-column-gap: 94px;
  padding: 120px 0;
  &.shopping-events {
    padding-top: 40px;
  }
  & .gatsby-image-wrapper {
    width: 100%;
    grid-area: ${({ reversed }) => (reversed ? 'photo' : 'content')};
  }
  & div {
    grid-area: ${({ reversed }) => (reversed ? 'content' : 'photo')};
    & img {
      margin-bottom: 16px;
    }
    & h3 {
      margin-bottom: 32px;
      font-size: 36px;
      line-height: 48px;
    }
    & p {
      margin-bottom: 24px;
    }
    & a {
      margin-bottom: 40px;
    }
    & div.logo-container {
      display: flex;
      & img {
        max-height: 37px;
        margin-bottom: 0;
      }
      & .gatsby-image-wrapper {
        margin-right: 32px;
        width: 100%;
      }
    }
  }
  ${media.desktop} {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, min-content);
    grid-template-areas:
      'content'
      'photo';
    grid-column-gap: 0;
    grid-row-gap: 40px;
    text-align: center;
    & .gatsby-image-wrapper {
      grid-area: photo;
    }
    & div {
      grid-area: content;
      & div.logo-container {
        margin-bottom: 24px;
        justify-content: center;
      }
    }
  }
  ${media.mobile} {
    &.shopping-events {
      padding-top: 72px;
    }
    padding-top: 0;
  }
`;

export const IntegrationsAside = styled.aside`
  border-radius: 4px;
  padding: 96px 0;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100vw;
    height: 424px;
    z-index: -1;
    background-color: #f5f5f5;
  }
  & .container {
    display: flex;
    align-items: center;
    & div.aside-content {
      & h2 {
        margin-bottom: 32px;
        max-width: 85%;
      }
      & p {
        margin-bottom: 32px;
        max-width: 85%;
      }
    }
  }
  ${media.desktop} {
    background-color: #f5f5f5;
    &::before {
      display: none;
    }
    & .container {
      flex-direction: column;
      & div.aside-content {
        margin-bottom: 48px;
        text-align: center;
        & h2 {
          max-width: 100%;
        }
        & p {
          max-width: 100%;
        }
      }
    }
  }
  ${media.mobile} {
    margin: 90px 0;
    padding: 48px 9px;
  }
`;

export const LivechatSection = styled.section`
  border-top: 1px solid #e4e5e8;
  padding-top: 168px;
  & h2 {
    text-align: center;
    margin-bottom: 16px;
  }
  & p.header-desc {
    text-align: center;
    max-width: 70%;
    margin: 0 auto 80px;
    ${media.desktop} {
      max-width: 100%;
    }
  }
  & .banner {
    background: #f5f5f5;
    border-radius: 4px;
    padding: 0 0 0 64px;
    display: flex;
    position: relative;
    align-items: center;
    height: 516px;
    & .gatsby-image-wrapper {
      flex-basis: 55%;
      width: 100%;
      align-self: flex-end;
    }
    & .banner-content {
      flex-basis: 45%;
      & img {
        max-width: 157px;
        margin-bottom: 32px;
      }
      & h3 {
        font-size: 28px;
        line-height: 40px;
        margin-bottom: 24px;
        max-width: 90%;
      }
      & p {
        margin-bottom: 40px;
        max-width: 90%;
      }
      & .banner-cta--buttons {
        display: flex;
        align-items: center;
        & a:last-of-type {
          margin-left: 32px;
        }
      }
    }
  }
  ${media.desktopWide} {
    & .banner {
      padding: 48px;
      flex-direction: column;
      height: auto;
      & .gatsby-image-wrapper {
        display: none;
      }
    }
  }
  ${media.tablet} {
    padding-top: 120px;
    & .banner {
      & .banner-cta--buttons {
        & a {
          font-size: 16px;
        }
      }
    }
  }
  ${media.mobile} {
    & .banner {
      padding: 48px 24px;
    }
    & .banner .banner-cta--buttons a:first-of-type {
      display: none;
      margin-left: 32px;
    }
    & .banner .banner-content .banner-cta--buttons a:last-of-type {
      margin-left: 0;
    }
  }
`;

export const InfoSection = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 80px;
  padding-bottom: 168px;
  & div {
    & .gatsby-image-wrapper {
      margin-bottom: 32px;
    }
    & h2 {
      margin-bottom: 32px;
      ${media.desktop} {
        font-size: 32px;
        line-height: 40px;
      }
    }
    & p {
      margin: 0;
    }
  }
  ${media.tablet} {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, min-content);
    grid-row-gap: 80px;
    text-align: center;
    padding-bottom: 120px;
  }
`;

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, min-content);
  grid-template-rows: repeat(3, min-content);
  grid-column-gap: 48px;
  grid-row-gap: 64px;
  ${media.mobile} {
    grid-gap: 55px;
  }
`;

export const CardIntegration = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0px 4px 16px rgb(0 0 0 / 8%);
  border-radius: 10px;
  width: 100px;
  height: 120px;
  color: #000;
  position: relative;
  &:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
    text-decoration: none;
    & p {
      color: #0a4ed6;
      font-weight: 700;
    }
  }
  & p {
    margin: 0;
    font-size: 11px;
    line-height: 14px;
    text-align: center;
    position: absolute;
    bottom: 14px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }
  & img {
    align-self: center;
    transform: translateY(-14px);
  }
  ${media.mobile} {
    width: 50px;
    height: 50px;
    align-items: center;
    & p {
      display: none;
    }
    & img {
      max-width: 80%;
      max-height: 25px;
      transform: translateY(0);
    }
  }
`;
