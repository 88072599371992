import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const Section = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-bottom: 0;
  align-items: center;
  section {
    padding-bottom: 0;
    margin: 40px 0;
    width: 370px;
    height: 370px;
    ${media.desktop} {
      width: 320px;
    }
    ${media.desktop} {
      width: 320px;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  ${media.desktopWide} {
    flex-direction: column;
  }
  h2 {
    text-align: center;
  }
`;
