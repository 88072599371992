import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const Wrapper = styled.div`
  ${media.desktop} {
    .p240 {
      padding: 168px 0 !important;
    }
    ${media.mobile} {
      .p240 {
        padding: 120px 0 !important;
      }
    }
  }
`;

export const Section = styled.section`
  display: flex;
  width: 100%;
  padding: 0;
  ${media.desktop} {
    width: 100%;
    flex-direction: column-reverse;
    & div.content-box {
      padding-bottom: 32px;
    }
  }

  & div.gatsby-image-wrapper {
    flex: 1;
    ${media.desktop} {
      width: 100%;
      grid-area: image;
    }
  }
  & div.content-box {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 420px;
    margin: 0 0 0 64px;

    ${media.desktop} {
      width: 100%;
      margin: 0 auto;
      text-align: center;
      grid-area: content;
      justify-content: start;
    }
    & p {
      margin: 34px 0;
      ${media.desktop} {
        margin: 24px 0;
      }
    }
  }
`;
