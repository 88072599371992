import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Link from 'components/ui/link/';
import UpHeading from '../upHeading';
import * as Styled from './styled';

const ConnectData = () => (
  <Styled.Wrapper className="container">
    <div className="p240">
      <Styled.Section>
        <StaticImage
          src="../../../img/saas/connectx3.png"
          alt="adding values such as subscription plan, company, support level or conversion probability that allows for a quick connection and easy way to filter sessions based on these criteria"
          title="User connect data"
          objectFit="contain"
        />
        <div className="content-box">
          <UpHeading headingText="USER IDENTIFICATION" />
          <h2 className="line">
            Connect <span>your data</span>
          </h2>

          <p>
            Match users data from your product with LiveSession. Adding values like subscription
            plan, company, support level, or conversion probability gives a quick connection and
            easy way to filter sessions based on those criteria.
          </p>
          <Link withArrow href="/help/user-identification-custom-properties/">
            See how to identify users
          </Link>
        </div>
      </Styled.Section>
    </div>
  </Styled.Wrapper>
);
export default ConnectData;
