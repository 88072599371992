import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import Link from 'components/ui/link';
import talentlyft from 'img/companyLogo/talentlyft-small.svg';
import * as Styled from './styled';

const Testimonial = ({
  name,
  copy,
  title,
  company,
  logo,
  children,
  img,
  linkText,
  href,
  noIndex,
}) => {
  const { mario } = useStaticQuery(graphql`
    {
      mario: file(relativePath: { eq: "index/marioYellowx3.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);
  return (
    <Styled.Container className="container">
      <div><img src={logo || talentlyft} alt="logo" className="img-fluid logo" /></div>
      <Styled.Card>
        <Styled.ProfileTitle>
          <h2>“{copy}“</h2>
          <div className="link">
            {!noIndex && (
              <Link withArrow href={href}>
                {linkText}
              </Link>
            )}
          </div>
          <div className="content-container">
            <Styled.ProfilePhoto
              image={img?.childImageSharp.gatsbyImageData || mario.childImageSharp.gatsbyImageData}
              alt={title}
              title={name}
              className="mobile-profile"
            />
            <div>
              <p className="name">
                {name}
              </p>
              <p className="title">{title}</p>
              <p className="company">{company}</p>
              {children}
            </div>
          </div>
        </Styled.ProfileTitle>
      </Styled.Card>
    </Styled.Container>
  );
};

export default Testimonial;

Testimonial.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  img: PropTypes.object.isRequired,
  logo: PropTypes.string.isRequired,
  linkText: PropTypes.string,
  noIndex: PropTypes.string.isRequired,
  href: PropTypes.string,
  name: PropTypes.string,
  copy: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  company: PropTypes.string,
};
Testimonial.defaultProps = {
  href: '/customers/talentlyft/',
  linkText: 'Read full story',
  name: 'Mario Butnic',
  company: 'TalentLyft',
  copy: 'With LiveSession, we investigate how similar existing features are used, so we can make the new ones even more user-friendly.',
  title: 'Chief Executive Officer',
};
