import PropTypes from 'prop-types';
import * as Styled from './styled';

const CustomerBadge = ({ img, small, style }) => (
  <Styled.Badge small={small} style={{ backgroundImage: `url(${img})`, ...style }} />
);

export default CustomerBadge;

CustomerBadge.propTypes = {
  img: PropTypes.string.isRequired,
  small: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

CustomerBadge.defaultProps = {
  small: false,
  style: null,
};
