export const questions = [
  {
    question: 'Can I try LiveSession for free?',
    answer:
      'Yes! You can sign up for a free 14 days trial with 1 000 sessions with access to all Pro plan features. ',
  },
  {
    question: 'What is a session?',
    answer: (
      <>
        A session is defined as all the interactions one user takes during one visit. Session ends
        after the user is not active for 30 minutes. Everything the user does on your website before
        they leave (e.g. browses pages, clicks, scrolls, writes on keyboard) adds up to one session.
        You can read more about the <a href="/help/what-is-a-session/">session</a>.
      </>
    ),
  },
  {
    question: 'Can I cancel my subscription anytime?',
    answer:
      'You can cancel your subscription at any time. It will remain active until the end of the period you paid for.',
  },
  {
    question: 'What payment methods do you support?',
    answer:
      'We support all major credit cards (Visa, Mastercard, Amex, Maestro, and Discover). For Amex cards we charge in EUR.',
  },
  {
    question: 'What if I exceed my plan sessions limit?',
    answer:
      'You will receive a notification from our team after exceeding 80% of your limit and after the full quota. Any sessions above the limit will not be recorded.',
  },
  {
    question: 'What happens to my recordings after the retention period?',
    answer:
      'Depending on your plan, the recordings are permanently deleted after 1, 3, 6 or 12 months.',
  },
  {
    question: 'Can I use LiveSession on several websites?',
    answer:
      'You can create a separate project for each website, or use one tracking code on as many websites as you wish. In most cases, keeping the websites separated is more useful, but there is no limit.',
  },
  {
    question: 'Is LiveSession compliant with the GDPR and CCPA?',
    answer: (
      <>
        Personal data protection and privacy are the core values of LiveSession. Read more about our
        compliant with <a href="/help/gdpr/">GDPR</a> and <a href="/help/ccpa/">CCPA</a>.
      </>
    ),
  },
  {
    question: 'How does LiveSession approach privacy?',
    answer:
      'LiveSession offers a wide range of features to control the data sent to our servers. Everything from static elements and images to dynamic content, requests and console logs can be anonymized. With LiveSession you can provide a full anonymized experience without losing the profit of analytics. ',
  },
];
