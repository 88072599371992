import React from 'react';
import Layout from 'layout';
import TopSection from 'components/newCustomers/TopSection';
import EngagementSlider from 'components/newCustomers/engagementSlider';
import ApplicationSlider from 'components/newCustomers/applicationSlider';
import Testimonial from 'components/index/testimonial';
import PersonalData from 'components/newCustomers/personalData';
import StartUsingCTA from 'components/index/startusingCTA';
import ShoppingEvents from 'components/newCustomers/shoppingEvents';
import ImportantUsers from 'components/newCustomers/ImportantUsers';
import StartUsing from 'components/ui/startUsing';
import ToolStack from 'components/newCustomers/toolStack';
import Information from 'components/newCustomers/information';
import Intercom from 'components/newCustomers/intercom';
import ConnectData from 'components/newCustomers/connectData';
import { Wrapper, WrapperInformation, WrapperStartUsing } from 'styles/saas';

const LsForSaas = () => (
  <Layout
    metaTitle="LiveSession for SaaS | Product Analytics"
    canonical="/industry/saas/"
    metaDescription="Track user behavior to improve acquisition, retention, and expansion of your SaaS. Increase the number of new registrations and lower the churn rate with LiveSession  to accelerate growth."
  >
    <TopSection />
    <div className="pbclear">
      <EngagementSlider />
    </div>
    <ConnectData />
    <div className="mpclear">
      <Testimonial />
    </div>
    <div className="mpclear">
      <ApplicationSlider />
    </div>
    <PersonalData
      header="Easy control of end-user"
      headerBr="privacy"
      paragraph="LiveSession allows you to control what part of the page and application is recorded.
              Take care of your and your customers' security. LiveSession is GDPR and CCPA
              compliant."
    />
    <Wrapper className="p240">
      <StartUsingCTA />
    </Wrapper>
    <div className="mpclear">
      <ShoppingEvents
        header="Track product events"
        article="Set up custom events to get a full view of your product usage. Get the most of your session replays and track your customers’ path to activation. Watch real data to see which behaviors users have and stop guessing when making a decision."
      />

      <ImportantUsers
        header="Build and customize  dashboards"
        article="Visualize and analyze the data you collect with LiveSession. Use Dashboards to track product adoption, activation or retention. Combining with custom events allow you to see the most important metrics of your product. See the trends of your business KPIs and set goals based on it. "
      />
    </div>
    <ToolStack />
    <WrapperInformation className="mpclear pt240 pb120">
      <Information />
    </WrapperInformation>
    <Intercom />
    <WrapperStartUsing className="mclear p168">
      <StartUsing />
    </WrapperStartUsing>
  </Layout>
);

export default LsForSaas;
