import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const Section = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 370px);
  grid-template-rows: repeat(2, 370px);
  grid-column-gap: 30px;
  grid-row-gap: 85px;
  padding-bottom: 0;
  padding-top: 40px;
  justify-content: flex-end;

  ${media.desktopWide} {
    grid-template-columns: repeat(2, 370px);
    grid-template-rows: repeat(2, 370px);
    justify-content: center;
  }
  ${media.desktop} {
    grid-template-columns: repeat(2, 320px);
    grid-template-rows: repeat(2, 370px);
  }
  ${media.tablet} {
    grid-template-columns: repeat(1, 320px);
    grid-template-rows: repeat(4, 370px);
  }
  ${media.mobile} {
    grid-template-rows: repeat(4, auto);
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  ${media.desktopWide} {
    flex-direction: column;
  }
  .white {
    section {
      background-color: white;
    }
  }
`;

export const ContentWrapper = styled.div`
  width: 355px;

  ${media.desktopWide} {
    padding: 0;
  }
  p {
    margin-bottom: 0;
    font-size: 22px;
    line-height: 34px;
    ${media.desktopWide} {
      padding-bottom: 30px;
    }
  }
  h2 {
    padding: 0 30px 30px 0;
    text-align: left !important;
    ${media.desktopWide} {
      width: 100%;
      padding: 0 0 24px 0;
      text-align: center !important;
    }
  }
  ${media.desktopWide} {
    width: 100%;
    text-align: center;
  }
`;
