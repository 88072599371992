import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import { StyledLink } from 'components/ui/link';
import { Rate } from 'components/CustomersRate';
import checkmark from 'img/ui/checkmarkGreen.svg';

export const Section = styled.section`
  margin-top: 90px;
  display: grid;
  grid-template-columns: 1fr 434px;
  grid-template-rows: auto auto;
  grid-column-gap: 64px;
  grid-row-gap: 70px;
  margin-bottom: 62px;
  position: relative;
  & h1 {
    margin-right: 16px;
  }
  & ol {
    padding-inline-start: 20px;
  }
  & ${Rate} {
    grid-template-rows: 1fr;
    grid-row-gap: 0;
    margin: 0;
    justify-content: flex-start;
    grid-column-gap: 16px;
    a:hover {
      box-shadow: none;
    }
    & a:first-of-type {
      padding-left: 0;
    }
  }
  ${media.desktopWide} {
    margin-top: 3rem;
    grid-template-columns: 4fr 3fr;
  }
  ${media.desktop} {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    grid-column-gap: 0;
    grid-row-gap: 40px;
    & ${Rate} {
      justify-content: center;
      & a:first-of-type {
        padding-left: 26px;
      }
    }
  }

  ${media.mobile} {
    margin-top: 2rem;
    & form {
      width: calc(100% - 20px);
    }
    ${StyledLink} {
      font-size: 18px;
    }
  }
`;

export const Container = styled.div`
  ${media.desktopWide} {
    margin-bottom: 3rem;
  }
  ${media.mobile} {
    margin-bottom: 2rem;
    width: calc(100% - 30px);
  }
`;

export const SubTitle = styled.p`
  font-size: 22px;
  line-height: 34px;
  margin-top: 32px;
  ${media.mobile} {
    margin-top: 24px;
    font-size: 20px;
    line-height: 30px;
  }
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 56px;
  padding-left: 42px;
  & li,
  h3 {
    font-size: 18px;
    line-height: 23px;
    margin-top: 16px;
    font-weight: 700;
    color: #000;
    position: relative;
    & span {
      position: relative;
      display: inline;
      font-weight: 700;
    }
    &::before {
      content: '';
      background-image: url(${checkmark});
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(-42px, -50%);
      width: 18px;
      height: 14px;
    }
  }
`;

export const ListDesc = styled.p`
  font-weight: 800;
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 24px;
  margin-top: 40px;
`;

export const InputContainer = styled.div`
  margin-top: 24px;
`;

export const Textarea = styled.textarea`
  border: 2px solid black;
  min-height: 96px;
  font-size: 16px;
  line-height: normal;
  color: #000;
  padding: 8px 0.75rem;
  border-radius: 4px;
  transition: 0.2s;
  width: 100%;
  outline: 0;
`;

export const Label = styled.label`
  font-weight: 400;
  font-size: 13px;
  color: #222;
  line-height: normal;
  margin-bottom: 8px;
`;

export const Message = styled.div`
  width: 100%;
  background-color: #e8fff2;
  border: 2px solid #00c853;
  padding: 11px 0;
  top: 60px;
  left: 0;
  text-align: center;
  grid-area: 1 / 1 / 2 / 4;
  &.error {
    border-color: rgb(240, 65, 52);
    background-color: #ffccc7;
  }
  & p {
    font-size: 18px;
    line-height: 34px;
    margin: 0;
  }
  ${media.desktop} {
    grid-area: 1 / 1 / 2 / 2;
    width: calc(100% - 24px);
  }
  ${media.mobile} {
    top: 50px;
    & p {
      font-size: 16px;
      line-height: 30px;
    }
  }
`;
