import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const Wrapper = styled.div`
  ${media.desktop} {
    .p240 {
      padding: 168px 0 !important;
    }
    ${media.mobile} {
      .p240 {
        padding: 120px 0 !important;
      }
    }
  }
`;

export const Section = styled.div`
  display: flex;
  width: 100%;
  ${media.desktop} {
    width: 100%;
    flex-direction: column-reverse;
    & div.content-box {
      padding-bottom: 32px;
    }
  }

  & div.gatsby-image-wrapper {
    min-width: 400px;
    padding: 38px 0;
    position: relative;
    flex-grow: 1;
    flex: 1;
    ${media.desktop} {
      min-width: auto;
      width: 100%;
      grid-area: image;
    }
  }
  & div.content-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 509px;
    margin: 0 0 0 86px;

    ${media.desktop} {
      width: auto;
      margin: 0 auto;
      text-align: center;
      grid-area: content;
      justify-content: start;
    }
    & p {
      margin: 24px 0;
      ${media.desktop} {
        margin: 24px 0;
      }
    }
    & .coming {
      width: 108px;
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      padding: 0 10px !important;
      border-radius: 10px;
      margin: 24px 0 8px 0;
      padding: 0 0;
      background-color: #ebebeb;

      ${media.desktop} {
        margin: 0 auto;
      }
    }
  }
`;
