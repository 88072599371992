import PropTypes from 'prop-types';
import { connectHits } from 'react-instantsearch-dom';
import styled from 'styled-components';
import CustomHighlight from './customhighlight';

const StyledUl = styled.ul`
  margin: 0;
  padding: 0;
`;

const StyledA = styled.a`
  width: 100%;

  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
`;

const StyledBox = styled.li`
  display: flex;
  min-height: 110px;
  align-items: center;
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e4e5e8;
  background-color: #fff;
  &:hover {
    background-color: #f6f8f9;
  }
  p:nth-child(1) {
    span {
      font-size: 16px;
      font-weight: 800;
      color: #0a4ed6;
    }
  }
  p:nth-child(2) {
    font-weight: 400;
    color: #000;
    margin-top: 8px;
    mark {
      font-weight: 400;
    }
  }
`;

const Hits = ({ hits }) => (
  <StyledUl>
    {hits.map((hit, index) => (
      <StyledA
        href={hit.canonical}
        className="articlepreview"
        // eslint-disable-next-line react/no-array-index-key
        key={index}
      >
        <StyledBox>
          <CustomHighlight hit={hit} attribute="metaTitle" />
          <CustomHighlight hit={hit} attribute="metaDescription" />
        </StyledBox>
      </StyledA>
    ))}
  </StyledUl>
);

const CustomHits = connectHits(Hits);

export default CustomHits;

Hits.propTypes = {
  hits: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
};
