import React from 'react';
import FeaturesSlider from 'components/index/featuresSlider';
import { graphql, useStaticQuery } from 'gatsby';
import { media } from 'theme/breakpoints';
import { saas } from 'data/index/cards';
import styled from 'styled-components';
import UpHeading from '../upHeading';

export const Wrapper = styled.div`
  div {
    h5 {
      padding-top: 55px;
      ${media.desktop} {
        padding-top: 0;
      }
    }
  }
  section {
    border: none;
  }
`;

const ApplicationSlider = () => {
  const { console, network } = useStaticQuery(graphql`
    {
      console: file(relativePath: { eq: "saas/consolex3.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      network: file(relativePath: { eq: "saas/networkx3.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  const dataImage = {
    images: [console, network],
    alt: [
      'javascript errors, detailed stacktraces and logs as in your own browser',
      'Logging headers, request and response content for individual network requests',
    ],
  };

  return (
    <Wrapper className="container p240">
      <FeaturesSlider
        color="#000000"
        sectionTitle={
          <>
            <UpHeading headingText="DEV TOOLS" />
            <h2 className="line">
              Understand application and <span>customer</span> issues
            </h2>
          </>
        }
        sectionDesc="Replay users’ sessions and stop guessing why bugs happen. Get the technical story of user journey."
        images={dataImage.images}
        cards={saas}
        alternativeText={dataImage.alt}
      />
    </Wrapper>
  );
};

export default ApplicationSlider;
