import Button from 'components/ui/button';
import * as Styled from './styled';

const ProducthuntModal = () => (
  <Styled.Content>
    <h2>Special Deal for Product Hunt members!</h2>
    <Styled.StyledImg
      image="../../img/producthunt.png"
      alt="Product Hunt"
      title="Special deal for Product Hunt members!"
    />
    <Button secondary center href="/signup?ref=producthunt&promo=producthunt">
      Get Lifetime Free Account
    </Button>
    <p>with 1500 sessions monthly</p>
  </Styled.Content>
);

export default ProducthuntModal;
