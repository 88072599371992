export const Plans = {
  Free: 'free',
  Basic: 'basic',
  Pro: 'pro',
  Enterprise: 'enterprise',
};

export const showPlans = [Plans.Free, Plans.Basic, Plans.Pro, Plans.Enterprise];

export function planPriority(plan) {
  switch (plan) {
    case Plans.Free:
      return 1;
    case Plans.Basic:
      return 2;
    case Plans.Pro:
      return 3;
    case Plans.Enterprise:
      return 4;
    default:
      return 0;
  }
}
