import Layout from 'layout';
import { GatsbyImage } from 'gatsby-plugin-image';
import StartUsing from 'components/ui/startUsing';
import Opinion from 'components/compare/Opinion';
import CustomerBadge from 'components/customer/customerBadge';
import databoxBadge from 'img/databox/badge.png';
import NewLogo from 'components/index/newLogo/index';
import Button from 'components/ui/button';
import Card from 'components/customer/card';
import { cards } from 'data/customers/cards';
import { graphql, useStaticQuery } from 'gatsby';
import { StyledHeader, HeroImgBox, Section, CardContainer, NumbersSection } from 'styles/customers';

const Customers = () => {
  const { banner } = useStaticQuery(graphql`
    {
      banner: file(relativePath: { eq: "databox/banner.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `);
  return (
    <Layout
      metaTitle="Case Studies | Real Customer Stories"
      metaDescription="See how session replay software can boost your business. Read success stories and case studies from LiveSession’s customers."
      canonical="/customers/"
    >
      <div className="container">
        <StyledHeader>
          <div>
            <h1 className="line blue">
              How Databox achieved a 223% increase in conversions to paid accounts after switching
              to <span>LiveSession.</span>
            </h1>
            <Button href="/customers/databox/">Read story</Button>
          </div>
          <HeroImgBox>
            <GatsbyImage
              image={banner.childImageSharp.gatsbyImageData}
              alt="databox team"
              title="Databox - team"
              className="databox-hero-img"
            />
            <CustomerBadge img={databoxBadge} />
          </HeroImgBox>
        </StyledHeader>
        <NewLogo />
        <Section>
          <h2>LiveSession helps businesses of all sizes</h2>
          <p className="desc">Read our featured customer stories</p>
          <CardContainer>
            {cards.map((card) => (
              <Card key={card.link} {...card} />
            ))}
          </CardContainer>
        </Section>
        <NumbersSection>
          <p>
            <span>5,000,000</span> users analyzed every month
          </p>
          <p>
            <span>60+</span> countries
          </p>
          <p>
            <span>1000+</span> teams using LiveSession
          </p>
          <div>
            <a
              title="LiveSession is a leader in Session Replay on G2"
              href="https://www.g2.com/products/livesession/reviews?utm_source=rewards-badge"
            >
              <img
                style={{ width: 105 }}
                alt="LiveSession is a leader in Session Replay on G2"
                src="https://images.g2crowd.com/uploads/report_medal/image/1335/medal.svg"
              />
            </a>
            <a
              title="Users love LiveSession on G2"
              href="https://www.g2.com/products/livesession/reviews?utm_source=rewards-badge"
            >
              <img
                style={{ width: 105 }}
                alt="Users love LiveSession on G2"
                src="https://my.g2.com/assets/product-badges/users-love-us-2008b519df49af90dcfa7db4b5fe13c8ec24ced0348f0a6bd039711ad8bbffc7.svg"
              />
            </a>
            <a
              title="LiveSession is a leader in Session Replay on G2"
              href="https://www.g2.com/products/livesession/reviews?utm_source=rewards-badge"
            >
              <img
                style={{ width: 105 }}
                alt="LiveSession is a leader in Session Replay on G2"
                src="https://images.g2crowd.com/uploads/report_medal/image/1347/medal.svg"
              />
            </a>
            <a
              title="LiveSession is a leader in Europe Session Replay on G2"
              href="https://www.g2.com/products/livesession/reviews?utm_source=rewards-badge"
            >
              <img
                style={{ width: 105 }}
                alt="LiveSession is a leader in Europe Session Replay on G2"
                src="https://images.g2crowd.com/uploads/report_medal/image/1471/medal.svg"
              />
            </a>
          </div>
        </NumbersSection>
        <Opinion
          opinion="We had experimented with several solutions, but we weren’t fully satisfied with them. We needed to find the best-in-class session recording software. LiveSession lives up to our high expectations."
          companyID="databox"
          secondary
          style={{ paddingBottom: 48 }}
        />
      </div>
      <StartUsing />
    </Layout>
  );
};

export default Customers;
