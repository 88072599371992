import { media } from 'theme/breakpoints';
import styled from 'styled-components';

export const Trusted = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 1200px;
  padding: 120px 15px;

  ${media.tablet} {
    padding: 68px 15px;
    max-width: 576px;
  }
  ${media.mobile} {
    padding: 32px 15px;
  }
  h2 {
    text-align: center;
    z-index: 2;
  }
  .customText {
    font-weight: 600;
    text-transform: none;
    color: rgb(106, 106, 106);
    font-size: 16px;
    margin-bottom: 0;
    & b {
      font-weight: 800;
    }
    ${media.tablet} {
      text-align: center;
    }
  }
`;
export const WrapperIcon = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(6, 1fr);
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 42px 0;
  p {
    font-size: 16px;
    line-height: 30px;
    font-weight: 600;
    margin: 0 auto;
    color: #6a6a6a;
    ${media.mobile} {
      font-size: 18px;
    }
  }
  img {
    margin: 0 auto;
    width: 140px;
  }
  ${media.desktopWide} {
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    & .last-in-row,
    & .last-in-second-row {
      display: none;
    }

    & img {
      width: 120px;
    }
  }
  ${media.desktop} {
    margin-top: 40px;
    & img {
      width: 80px;
    }
  }
  ${media.tablet} {
    justify-content: center;
    grid-template-rows: repeat(2, 57px);
    grid-template-columns: repeat(3, 100px);
    grid-column-gap: 50px;
    grid-row-gap: 10px;
    & img {
      width: 110px;
    }
  }
  ${media.mobile} {
    grid-column-gap: 15px;
    grid-row-gap: 8px;
    & img {
      width: 100px;
    }
  }
`;
