import Link from 'components/ui/link';
import { StaticImage } from 'gatsby-plugin-image';
import withUsabilityTestingTemplate from 'hoc/content-cluster';
import arrow from 'img/ui/arrow_white.svg';
import Banner from 'components/usability-testing/banner';

const UsabilityTestingQuestions = () => (
  <>
    <h2>Why is it crucial to select the right usability testing questions?</h2>
    <p>
      The results of your usability test will highly depend on your choice of questions. A few
      reasons why it’s necessary to pick the right user testing questions are to:
    </p>
    <ul>
      <li>
        get an overall understanding and great insights into how users feel about or perceive your
        mobile apps or website
      </li>
      <li>
        identify issues or features that are troublesome (or event total blockers that need to be
        fixed critically)
      </li>
      <li>
        understand customers’ fears, goals, and challenges with honest opinions from real people
      </li>
      <li>find out why users abandon tasks (for example, shopping carts)</li>
      <li>design better workflows that will help users achieve their goals faster</li>
      <li>build a successful product based on a fine-tuned design and user interactions</li>
    </ul>
    <StaticImage
      src="../../img/usability-testing/usability-questions/artboard.png"
      alt="Design"
      title="Usability testing questions for enhancing UX"
    />
    <h2>Usability testing questions for enhancing the UX</h2>
    <p>
      We’ve split the questions into four categories: screening, pre-test, in-test, and post-test to
      make your usability testing questions selection easier.
    </p>
    <h3>Screening usability questions</h3>
    <p>
      These will help you determine what type of users to involve in your UX testing process.
      Screening questions can support your entire usability study, so pay close attention to this
      step. It’s good practice to identify and list all the criteria that an ideal participant
      should meet.{' '}
    </p>
    <ol>
      <li>
        <p>
          <strong>Demographic details questions</strong> – asking for someone’s age, gender, level
          of education, and occupation (or, in the case of businesses, inquiring about their
          firmographics such as company size and industry). This information is important for
          ensuring that the right users are testing your product. For example, if you target
          marketers and you have CSMs among your participants, they will skew your results{' '}
        </p>
      </li>
      <li>
        <p>
          <strong>Tech-literacy questions</strong> – find out how often the test participants use
          technologies and devices, as well as how easy or difficult using these devices is for
          them. If you’re testing a feature or a product that requires high tech proficiency then
          you can’t have laggards among your testers.{' '}
        </p>
      </li>
      <li>
        <p>
          <strong>How do you currently solve the problems this product seeks to address?</strong> –
          are users also employing any competing solutions? Perhaps they are using a method that is
          much more complex and time-consuming than what you’re offering. Such insights will let you
          understand how to better reach your target user and convince them to use your solution.
        </p>
      </li>
      <li>
        <p>
          <strong>
            What is your income (in the case of B2C) or budget (in the case of B2B solutions)?
          </strong>{' '}
          – essential information for paid functions in apps, e-commerce, and premium plans. These
          questions also let you establish who your High-Value Customers are and focus on delivering
          them the perfect user experience.{' '}
        </p>
        <h3>Pre-test usability questions</h3>
        <p>
          Pre-test usability questions are used to further narrow down your participants, making
          sure only the most suitable ones take part in your usability test. With pre-test
          questions, your product team can save a lot of time on each actual user testing session.{' '}
        </p>
      </li>
      <li>
        <p>
          <strong>Behavioral questions</strong> – ask how frequently they engage with the product
          (or similar products). If you want to specifically focus on power users or disengaged
          users, asking this question will help you define their usage frequency.{' '}
        </p>
      </li>
      <li>
        <p>
          <strong>What do you use the website or app for?</strong> – if you want to focus on
          specific product use cases, this user testing question will help you eliminate all users
          who fail to meet your criteria.{' '}
        </p>
      </li>
      <li>
        <p>
          <strong>How satisfied are you with the available workflows?</strong> – when designing apps
          or websites, one always has specific workflows in mind that should be easy to follow and
          intuitive. Ask this question to discover if users agree with your way of thinking.{' '}
        </p>
      </li>
      <li>
        <p>
          <strong>Which features do you use most?</strong> – if you want to build your test around a
          specific feature, this question is necessary to identify those users who are already
          familiar with it. If you end up with participants who have never used that particular
          feature before, they won’t bring any valuable insights into the test.{' '}
        </p>
      </li>
      <li>
        <p>
          <strong>What problems/tasks does the product/website/app help you solve?</strong> –
          products are built to solve specific problems. Asking this usability survey question will
          help you verify your hypotheses and confirm whether or not your product actually solves
          the problems it was designed to. It might also reveal new product usages that you were
          previously unaware of.{' '}
        </p>
      </li>
      <li>
        <p>
          <strong>What other apps did you use or research prior to selecting this one?</strong> –
          answers to this usability testing question will help you discover who you are in
          competition with, as well as to verify if any new players have appeared on the horizon,
          how you compare to them, and what you can do to outperform them.{' '}
        </p>
        <h3>In-test usability questions</h3>
        <p>
          These types of questions are asked while your users engage with your website or app. By
          asking in-test usability questions, you can understand how the participants use different
          features, if they find the interface clear, as well as why and when they decide to abandon
          a particular process, among many others. With in-test questions, you can get more ideas
          about how to attract new potential users and improve your usability testing methods.{' '}
        </p>
      </li>
      <li>
        <p>
          <strong>
            When you log on, what’s the first thing you do? Is there another way to complete this
            task?
          </strong>{' '}
          – asking such a question will let you in on user motivation and the different paths they
          use to achieve their desired result.{' '}
        </p>
      </li>
      <li>
        <p>
          <strong>How do you use this feature?</strong> – while this question might seem very
          general, it’s worth asking to learn about any non-standard use practices. For instance,
          thanks to a survey conducted among its users, a company called TechJury found out that
          their servers were being used not for business purposes, but by{' '}
          <a
            href="https://survicate.com/customer-feedback/business-strategy/"
            taeget="_blank"
            rel="noopener noreferrer nofollow"
          >
            a gamer community
          </a>
          . Such revelations can help you reinvent your products or features to better cater to your
          actual users. users.
        </p>
      </li>
      <li>
        <p>
          <strong>Do you like the interface? Is it easy to use?</strong> – a simple question that
          will let you in on user sentiment and point to any potential UX or UI flaws.
        </p>
      </li>
      <li>
        <p>
          <strong>What do you think about how information and features are laid out?</strong> – this
          usability testing question will help you understand if anything, from the way your product
          is designed up to the way the UX message is written, might be a source of confusion for
          your users.
        </p>
      </li>
      <li>
        <p>
          <strong>Exit intent</strong> – if the user wants to go back to the previous page or leave
          the app or website altogether, ask about their reason for doing so (for example, the
          interface confused them or errors and bugs appeared).
        </p>
        <h3>Post-testing usability questions</h3>
        <p>
          After the participants have completed the usability test, it’s time to ask them some
          follow-up questions about their impressions. That’s where post-testing questions come into
          play. In the post-testing phase, make sure to ask about their general experiences with
          your app or website, which features they liked and disliked, and whether or not they would
          recommend your product to others. These post-test questions and some other app usability
          testing questions that are provided below will arm you with a whole range of invaluable
          insights.
        </p>
      </li>
      <li>
        <p>
          <strong>Overall, what has your experience been with the website or app?</strong> – asking
          this key question during the post-testing phase will let you collect live impressions from
          users. They might be inclined to name any confusing or underperforming areas that have
          stuck with them, or it will give you a general opinion on the user experience. Depending
          on your preference and how you will analyze any deeper insights, you could either ask them
          to give you a score on a numerical scale or for a descriptive, text answer only.
        </p>
      </li>
      <li>
        <p>
          <strong>What did you like about the product?</strong> – knowing what features your real
          users appreciate will help you make future development decisions about your products.
          You’ll also know which features or benefits to focus your marketing efforts on.{' '}
        </p>
      </li>
      <li>
        <p>
          <strong>What didn’t you like about the product?</strong> – this info will make you aware
          of the product areas that most require further work. Maybe there are some features that
          you can get rid of completely to make the product easier to use, and as a result, enhance
          the user experience for your target audience.
        </p>
      </li>
      <li>
        <p>
          <strong>Is there any product similar to this one?</strong> – by asking this question,
          you’ll learn who you’re competing with for your users’ attention. These insights will not
          only fuel your product strategy (i.e. how to outgrow your competitors by providing a
          better user experience), but will also help improve your marketing messaging, pricing
          strategy, and more.
        </p>
      </li>
      <li>
        <p>
          <strong>What were your feelings about the instructions?</strong> – after your testers have
          completed the task, it’s worth asking them whether or not the instructions were clear. If
          they weren’t, you’ll understand that the reason for this was a confusing user workflow or
          interface, or that they could have been written in a better way.
        </p>
      </li>
      <li>
        <p>
          <strong>Do you know anyone who would like this product?</strong> – one of the most common
          questions, but it is a goldmine when it comes to building out your user profiles. Ask your
          respondents to describe who they think your product would be right for. For many of us,
          it’s easier to talk about people and their goals, challenges, and fears than to talk about
          products.
        </p>
      </li>
      <li>
        <p>
          <strong>Customer Effort Score (CES)</strong> – this usability survey question is a popular
          metric that is often measured by product teams. Asked after a completed task, it lets you
          collect quantitative data about how difficult or easy it was to perform the recently
          completed task and nail down areas for improvement.
        </p>
      </li>
      <li>
        <p>
          <strong>Net Promoter Score (NPS)</strong>– arguably the most popular customer satisfaction
          metric in the world. It lets you ask your users “How likely are you to recommend this
          website or app to others?” on a scale from 0 to 10, and for the reasons behind their
          answer. While the NPS score is a metric used to track overall customer loyalty levels per
          se, it&apos;s invaluable for UX teams. Reading the low scoring responses (i.e. between 0
          and 6) along with the commentary will help you capture any recurring usability issues.
        </p>
      </li>
      <li>
        <p>
          <strong>Given your experience, how frequently would you use this product/feature?</strong>{' '}
          – after your respondents have used a certain feature or product, they’ll likely be able to
          evaluate how often they’d need (or want) it in future. By asking this kind of additional
          question, you’ll find out what impression the product/feature has made and how important
          it is for helping users achieve their goals.{' '}
        </p>
      </li>
    </ol>
    <Banner />
    <h2>Summary</h2>
    <p>
      As we’ve shown in the examples above, there are plenty of user testing questions that are
      worth asking when you conduct{' '}
      <Link href="/usability-testing/" target="_blank" rel="noopener noreferrer">
        usability testing
      </Link>
      . During the screening phase, we recommend that you ask your users for basic information such
      as their demographics or firmographics in order to build accurate user profiles. This should
      be followed by behavioral or open-ended questions asked in the pre-test stage.{' '}
    </p>
    <p>
      During the test, it’s important that you ask questions and collect insights on your product,
      interface, or specific user paths. Last, but not least, after your users have completed the
      task, you should ask for their general impressions and common questions to learn how you can
      improve the user experience. Then, you can ask follow-up questions if necessary. All of these
      valuable insights will help you optimize your digital products or physical products and ensure
      you provide a relevant service to your users.
    </p>
    <p>All that’s left to say? Good luck!</p>
    <div className="next-chapter">
      <Link href="/usability-testing/usability-reporting/">
        <div>
          <p>Chapter 3</p>
          <p>How to Become Great at Usability Reporting</p>
        </div>
        <img src={arrow} alt="arrow" title="white arrow" />
      </Link>
    </div>
  </>
);

export default withUsabilityTestingTemplate(UsabilityTestingQuestions);
