import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import { Section as FeatureSection } from 'components/solutions/section/styled';

export const StyledSection = styled.section`
  padding-top: 168px;
  padding-bottom: 0;
  & .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  & h1 {
    text-align: center;
  }
  & .subtitle {
    margin: 32px 0 48px;
    font-size: 22px;
    text-align: center;
  }
  ${media.mobile} {
    padding-top: 120px;
    ${FeatureSection}:last-child {
      padding-bottom: 95px;
    }
    & .subtitle {
      font-size: 18px;
      margin: 32px 0 40px;
    }
  }
`;
