import Layout from 'layout';
import Button from 'components/ui/button';
import icon from 'img/ui/blog-confirm-icon.svg';
import { StyledSection } from 'styles/newsletterConfirmed';

const NewsletterConfirmed = () => (
  <Layout noIndex metaTitle="Blog Subscription Confirmed">
    <StyledSection>
      <img src={icon} alt="Icon - signed to newsletter" title="You're signed!" />
      <h1>You&apos;re in!</h1>
      <p>
        You&apos;ve signed up for the LiveSession newsletter. Stay tuned for fresh insights and
        expert knowledge!
      </p>
      <Button href="/blog/">Go to blog</Button>
    </StyledSection>
  </Layout>
);

export default NewsletterConfirmed;
