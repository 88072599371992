import NewsletterTemplate from 'templates/newsletter';
import { StaticImage } from 'gatsby-plugin-image';

const Content = () => (
  <table className="content" cellSpacing="0" cellPadding="0" align="center" width="100%">
    <tbody>
      <tr>
        <td>
          <table className="text" cellSpacing="0" cellPadding="0" style={{ width: '100%' }}>
            <tbody>
              <tr>
                <td style={{ padding: '32px 40px 12px' }}>
                  <h1>
                    Hi{' '}
                    <span role="img" aria-label="emoji">
                      &#128075;
                    </span>
                  </h1>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '12px 40px' }}>
                  <p style={{ margin: 0, fontSize: '16px', lineHeight: '24px' }}>
                    We hope you’re doing well!
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '12px 40px' }}>
                  <p style={{ margin: 0, fontSize: '16px', lineHeight: '24px' }}>
                    We’ve been working hard to launch our brand new knowledge base!{' '}
                    <span role="img" aria-label="emoji">
                      🎉
                    </span>{' '}
                    Stay tuned for even more fresh content in the nearest future!{' '}
                    <span role="img" aria-label="emoji">
                      📚
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '12px 40px 44px' }}>
                  <p style={{ margin: 0, fontSize: '16px', lineHeight: '24px' }}>See what’s new:</p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '12px 0 34px' }}>
                  <div style={{ borderTop: '1px solid #ddd' }} />
                </td>
              </tr>
              <tr>
                <td style={{ padding: '0 40px' }}>
                  <h2 style={{ margin: 0, fontSize: '22px' }}>LiveSession Guides</h2>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '32px 40px 12px' }}>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <a href="/usability-testing/" target="_blank" rel="noopener noreferrer">
                            <img
                              src="https://emails.livesession.io/usability-testing.png"
                              width="520"
                              height="400"
                              alt="Content update - Usability Testing Guide"
                              style={{
                                border: 0,
                                lineHeight: '100%',
                                outline: 0,
                                display: 'block',
                                height: 'auto',
                                borderRadius: '6px',
                                maxWidth: '100%',
                              }}
                            />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            href="/usability-testing/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              textDecoration: 'none',
                              color: '#061631',
                              fontWeight: 800,
                              marginTop: '24px',
                              display: 'block',
                            }}
                          >
                            Usability Testing Guide
                          </a>
                          <p
                            style={{
                              color: '#9b9b9b',
                              fontSize: 16,
                              marginTop: 8,
                              display: 'block',
                            }}
                          >
                            We’ve launched a series of in-depth{' '}
                            <a
                              href="/usability-testing/"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                textDecoration: 'none',
                                color: '#0446CA',
                                fontWeight: 700,
                              }}
                            >
                              Guides
                            </a>{' '}
                            to grow your business. Get started with our ultimate guide to usability
                            testing:
                          </p>
                          <a
                            href="/usability-testing/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              fontSize: '16px',
                              color: '#0446CA',
                              fontWeight: 700,
                              textDecoration: 'none',
                              marginTop: 8,
                              display: 'block',
                            }}
                          >
                            Become a usability testing pro
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '34px 0' }}>
                  <div style={{ borderTop: '1px solid #ddd' }} />
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0 40px' }}>
                  <h2 style={{ margin: 0, fontSize: '22px' }}>LiveSession Tutorials</h2>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '32px 40px 12px' }}>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <a
                            href="/tutorials/guide-to-ab-testing-with-livesession/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ display: 'block' }}
                          >
                            <StaticImage
                              src="../../img/tutorials/abtest.png"
                              alt="Content update - AB Test Tutoral"
                              style={{
                                border: 0,
                                lineHeight: '100%',
                                outline: 0,
                                display: 'block',
                                height: 'auto',
                                borderRadius: '6px',
                                maxWidth: '100%',
                              }}
                            />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            href="/tutorials/guide-to-ab-testing-with-livesession/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              textDecoration: 'none',
                              color: '#061631',
                              fontWeight: 800,
                              marginTop: '24px',
                              display: 'block',
                            }}
                          >
                            Guide to A/B testing
                          </a>
                          <p
                            style={{
                              color: '#9b9b9b',
                              fontSize: 16,
                              marginTop: 8,
                              display: 'block',
                            }}
                          >
                            But wait, there’s more!{' '}
                            <a
                              href="/tutorials/guide-to-ab-testing-with-livesession/"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                textDecoration: 'none',
                                color: '#0446CA',
                                fontWeight: 700,
                              }}
                            >
                              Tutorials
                            </a>{' '}
                            will show you how to get the most out of LiveSession. See how to learn
                            even more from your A/B tests with session replays:
                          </p>
                          <a
                            href="/tutorials/guide-to-ab-testing-with-livesession/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              fontSize: '16px',
                              color: '#0446CA',
                              fontWeight: 700,
                              textDecoration: 'none',
                              marginTop: 8,
                              display: 'block',
                            }}
                          >
                            Take you A/B testing skills to the next level
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '34px 0' }}>
                  <div style={{ borderTop: '1px solid #ddd' }} />
                </td>
              </tr>

              <tr>
                <td style={{ padding: '0 40px' }}>
                  <h2 style={{ margin: 0, fontSize: '22px' }}>Blog posts</h2>
                </td>
              </tr>

              <tr>
                <td style={{ padding: '32px 40px 12px' }}>
                  <table style={{ width: '100%' }}>
                    <tbody>
                      <tr>
                        <td>
                          <a
                            href="/blog/how-to-use-session-recordings-to-grow-your-saas/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ display: 'block' }}
                          >
                            <StaticImage
                              src="../../img/blog/covers/sessionRecordings.png"
                              alt="Blog post - How to use Session Recordings to Grow Your SaaS"
                              style={{
                                border: 0,
                                lineHeight: '100%',
                                outline: 0,
                                display: 'block',
                                height: 'auto',
                                borderRadius: '6px',
                                maxWidth: '100%',
                              }}
                            />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            href="/blog/how-to-use-session-recordings-to-grow-your-saas/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              textDecoration: 'none',
                              color: '#061631',
                              fontWeight: 800,
                              marginTop: '24px',
                              display: 'block',
                            }}
                          >
                            How to use Session Recordings to Grow Your SaaS
                          </a>
                          <p
                            style={{
                              color: '#9b9b9b',
                              fontSize: 16,
                              marginTop: 8,
                              display: 'block',
                            }}
                          >
                            Need a SaaS optimization solution that actually brings results?
                          </p>
                          <a
                            href="/blog/how-to-use-session-recordings-to-grow-your-saas/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              fontSize: '16px',
                              color: '#0446CA',
                              fontWeight: 700,
                              textDecoration: 'none',
                              marginTop: 8,
                              display: 'block',
                            }}
                          >
                            See how session recordings can help
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '40px 60px 12px 40px' }}>
                  <p style={{ margin: 0, fontSize: '16px', lineHeight: '24px' }}>
                    Any questions? Just reply to this email!
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '24px 40px 32px' }}>
                  <table className="kamil" cellSpacing="0" cellPadding="0">
                    <tbody>
                      <tr style={{ verticalAlign: 'middle' }}>
                        <th>
                          <img
                            src="https://emails.livesession.io/kamil-rounded.png"
                            className="kamil-img"
                            style={{ maxWidth: '56px' }}
                            alt="kamil"
                          />
                        </th>
                        <th style={{ paddingLeft: '16px' }}>
                          <p
                            className="info"
                            style={{
                              margin: 0,
                              textAlign: 'left',
                              fontWeight: 400,
                              lineHeight: '140%',
                            }}
                          >
                            <strong>Kamil</strong> <br />
                            CEO and Founder @ LiveSession
                          </p>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
);

const Newsletter = NewsletterTemplate(Content);

const frontmatter = {
  title: 'LiveSession Newsletter July 2020',
  description: "Read LiveSession's monthly update from July 2020.",
  date: '2020-07-01',
  link: '/newsletters/july-2020/',
};

const NewsletterPage = () => <Newsletter {...frontmatter} />;

export default NewsletterPage;
