import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import Paragpraph from 'components/ui/Paragraph';
import * as Styled from './styled';

const Card = ({ title, desc, img, imageStyle }) => (
  <Styled.Card>
    <GatsbyImage
      image={img}
      alt="Powerfull features"
      title={title}
      objectFit="contain"
      style={imageStyle}
    />
    <Styled.Container>
      <h3>{title}</h3>
      <Paragpraph>{desc}</Paragpraph>
    </Styled.Container>
  </Styled.Card>
);

export default Card;

Card.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  img: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]).isRequired,
  imageStyle: PropTypes.oneOfType([PropTypes.object]),
};

Card.defaultProps = {
  imageStyle: {},
};
