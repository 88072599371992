/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useCallback, useRef } from 'react';
import Layout from 'layout';
import { Link } from 'gatsby';
import { frontmatter } from 'data/marketplace/integrations';
import { useLocation } from '@reach/router';
import AliceCarousel from 'react-alice-carousel';
import arrow from 'img/ui/arrow_black.svg';
import PropTypes from 'prop-types';
import Card from 'components/marketplace/card';
import Button from 'components/ui/button';
import OwnAppSection from 'components/marketplace/ownAppSection';
import { data } from 'data/marketplace/sidebar';
import { post } from 'api';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import * as Styled from './styled';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import integrations from '../../data/frontmatter/integrations.json';

export const CardContext = ({ render }) => {
  const [showMoreCards, setShowMoreCards] = useState(false);
  const fixedButton = useRef(null);

  const listenToScroll = useCallback(() => {
    const position = window.pageYOffset;
    if (position > 400) {
      fixedButton.current.classList.add('active');
    } else {
      fixedButton.current.classList.remove('active');
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    setShowMoreCards(true);
    return () => window.removeEventListener('scroll', listenToScroll);
  }, [listenToScroll]);

  const location = useLocation();
  const [card] = frontmatter.items.filter(({ link }) => link === location.pathname);
  const context = location.pathname.split('/').filter(Boolean).pop();

  const handleIntegrationBtnClick = (e, integrationTitle) => {
    trackCustomEvent({
      category: 'Integration - Install now',
      action: 'Click',
      label: integrationTitle,
    });
    post('/marketplace/event', {
      context,
      name: 'marketplace.clicked.install',
    });
  };

  useEffect(() => {
    post('/marketplace/event', {
      context,
      name: 'marketplace.visit',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const number = Math.random() * (frontmatter.items.length - 5);
  const moreCards = frontmatter.items
    .filter(({ title }) => title !== card.title)
    .slice(number, number + 4);
  const renderMoreCards = (arr) =>
    arr.map((item) => <Card {...item} key={item.link} activeCategory={item.categories[0]} />);

  const checkCategory = (category) => {
    const [getCurrentCat] = data.flatMap(({ list }) =>
      list.filter(({ title }) => title === category),
    );
    return getCurrentCat.link;
  };

  const [filterInstallLink] = integrations.filter((item) => {
    const { installationLink, helpLink } = item;
    const currentInt = location.pathname.split('/')[2];
    if (helpLink?.includes(currentInt)) {
      return installationLink || helpLink;
    }
  });

  // eslint-disable-next-line no-unsafe-optional-chaining

  if (!filterInstallLink) {
    return null;
  }

  const { helpLink, installationLink, externalInstallLink } = filterInstallLink;

  return (
    <Layout
      canonical={location.pathname}
      metaTitle={`${card.title} - ${card.categories[0]} | Integrations`}
      metaDescription={card.desc}
      image={card.cover}
    >
      <div className="container">
        <Styled.ExtendGridBox>
          <div className="breadcrumbs">
            <p>
              <Link to="/integrations/">Integrations</Link>
            </p>
            <img src={arrow} alt="arrow" title="Arrow" />
            <p>
              <Link to={`/integrations/${card.categories[0].toLowerCase().replace(' ', '-')}/`}>
                {card.categories[0]}
              </Link>
            </p>
            <img src={arrow} alt="arrow" title="Arrow" />
            <p>
              <Link to={location.pathname}>
                <strong>{card.title}</strong>
              </Link>
            </p>
          </div>
          <Styled.CardColumn>
            <div className="sticky-box">
              <Card
                {...card}
                integrationLink={installationLink}
                externalInstallLink={externalInstallLink}
                link={helpLink}
                integrationPage
                as="div"
                linkTag="a"
                handleIntegrationBtnClick={handleIntegrationBtnClick}
              />
              <p className="madeby">
                Made by: <strong>{card.madeBy}</strong>
              </p>
              <p className="title">
                <strong>Categories</strong>
                <span>
                  Made by: <strong>{card.madeBy}</strong>
                </span>
              </p>
              <Styled.Categories>
                {card.categories.map((cat) => (
                  <a className="category" key={cat} href={checkCategory(cat)}>
                    <span>{cat}</span>
                  </a>
                ))}
              </Styled.Categories>
            </div>
          </Styled.CardColumn>
          <Styled.ContentStyles>{render(card)}</Styled.ContentStyles>
        </Styled.ExtendGridBox>
        <Styled.MoreApps>
          <h2>Discover more apps</h2>
          <div className="card-box">{showMoreCards && renderMoreCards(moreCards)}</div>
          <AliceCarousel mouseTrackingEnabled buttonsDisabled>
            {renderMoreCards(moreCards)}
          </AliceCarousel>
        </Styled.MoreApps>
        <OwnAppSection />
      </div>
      <Styled.FixedButton ref={fixedButton}>
        <Button
          href={
            filterInstallLink?.node?.frontmatter?.installationLink
              ? `${process.env.GATSBY_APP_URL}${filterInstallLink?.node?.frontmatter?.installationLink}`
              : filterInstallLink?.node?.frontmatter?.canonical
          }
          onClick={(e) => handleIntegrationBtnClick(e, card.title)}
        >
          Install now
        </Button>
      </Styled.FixedButton>
    </Layout>
  );
};

CardContext.propTypes = {
  render: PropTypes.func.isRequired,
};

const withIntegrationCardContext = (Component) => (props) => {
  const sliderSettings = (photos) => {
    const settings = {
      customPaging: (i) => (
        <a
          style={{
            backgroundImage: `url(${
              typeof photos[i].img === 'string'
                ? photos[i].img
                : photos[i].img?.gatsbyImageData?.images?.fallback?.src || photos[i].img?.src
            })`,
          }}
        />
      ),
      dots: photos.length > 1,
      dotsClass: 'slick-dots',
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return settings;
  };
  return (
    <CardContext
      render={(card) => <Component {...props} card={card} sliderSettings={sliderSettings} />}
    />
  );
};

export default withIntegrationCardContext;
