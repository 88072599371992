import React, { Component } from 'react';
import PropTypes from 'prop-types';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Configure, Highlight, connectSearchBox } from 'react-instantsearch-dom';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import Autocomplete from './autocomplete';
import CustomHits from './customHits';

const StyledContainer = styled.div`
  position: relative;
  z-index: 20;
`;

const VirtalSearchBox = connectSearchBox(() => null);

const index = process.env.GATSBY_ALGOLIA_INTEGRATIONS;

const searchClient = algoliasearch('G5L9QWEP2F', 'bf1653634fbcabb418d2f166f607c083');

const Hit = ({ hit }) => (
  <div>
    <Highlight attribute="name" hit={hit} />
  </div>
);

Hit.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  hit: PropTypes.object.isRequired,
};

class SearchBar extends Component {
  state = {
    query: '',
  };

  onSuggestionSelected = (_, { suggestion: { link, name } }) => {
    navigate(link);
    this.setState({
      query: name,
    });
  };

  onSuggestionCleared = () => {
    this.setState({
      query: '',
    });
  };

  render() {
    const { query } = this.state;
    return (
      <StyledContainer>
        <InstantSearch indexName={index} searchClient={searchClient}>
          <Configure hitsPerPage={5} />
          <Autocomplete
            onSuggestionSelected={this.onSuggestionSelected}
            onSuggestionCleared={this.onSuggestionCleared}
          />
        </InstantSearch>

        <InstantSearch indexName={index} searchClient={searchClient}>
          <VirtalSearchBox defaultRefinement={query} />
          {query && <CustomHits hitComponent={Hit} />}
        </InstantSearch>
      </StyledContainer>
    );
  }
}

export default SearchBar;
