import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const Header = styled.section`
  padding: 168px 0 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: 0rem;
  position: relative;
  color: #000;
  ${media.mobile} {
    padding: 120px 0 0;
  }
  & h1 {
    margin: 0;
    margin-top: 0;
    & span {
      z-index: 100;
      &::after {
        z-index: -1;
      }
    }
    ${media.tablet} {
      font-size: 28px;
      line-height: 38px;
    }
  }
`;

export const StyledSection = styled.section`
  padding: 48px 0 0;
  position: relative;
  & .container {
    padding: 0;
  }
  &::before {
    content: '';
    width: 100vw;
    height: 53.5%;
    background-color: #f5f5f5;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    ${media.desktopWide} {
      height: 29.5%;
    }
    ${media.mobile} {
      height: 43%;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

export const Subtitle = styled.p`
  font-size: 20px;
  line-height: 28px;
  margin: 16px 0 48px;
  font-weight: 500;
  color: #000;
  text-align: center;
  ${media.tablet} {
    margin: 24px 0 32px;
    font-size: 18px;
    width: 90%;
  }
  ${media.mobile} {
    margin-bottom: -8px;
  }
`;

export const Plans = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 25%);
  justify-content: center;
  & .pricing-box:first-of-type {
    border-radius: 4px 0 0 4px;
  }
  & .pricing-box:last-of-type {
    border-radius: 0 4px 4px 0;
  }
  ${media.desktopWide} {
    grid-template-columns: repeat(2, 278px);
    grid-column-gap: 35px;
    & .pricing-box:first-of-type {
      border-radius: 4px;
    }
    & .pricing-box:last-of-type {
      border-radius: 4px;
    }
  }
  @media (max-width: 900px) {
    grid-column-gap: 30px;
    grid-template-columns: repeat(2, minmax(278px, min-content));
  }
  ${media.mobile} {
    display: flex;
    flex-direction: column;
  }
`;

export const Switcher = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0px 0 24px;
  align-self: flex-end;
  ${media.desktopWide} {
    align-self: center;
    display: grid;
    grid-template-columns: repeat(2, 278px);
    grid-column-gap: 35px;
    justify-content: center;
    & ul {
      grid-area: 1 / 1 / 2 / 3;
    }
  }
  ${media.mobile} {
    display: flex;
    justify-content: center;
    margin: 0 0 14px;
    width: 100%;
    & ul li span {
      display: none;
    }
  }
  p {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    margin-right: 16px;
  }
  ul {
    list-style: none;
    padding: 0;
    display: flex;
    margin: 0;
    ${media.desktop} {
      padding: 0 4px;
    }
    ${media.mobile} {
      width: 100%;
      justify-content: center;
      padding: 0;
    }
    li {
      cursor: pointer;
      display: flex;
      align-items: center;
      & p {
        color: #000;
        text-align: center;
        font-weight: 400;
        font-size: 16px;
        margin: 0;
      }
      & span {
        margin-left: 12px;
        background-color: #dfe9fc;
        padding: 4px 16px;
        border-radius: 20px;
        font-weight: 400;
        font-size: 14px;
        color: #0446ca;
        font-weight: 700;
      }
      &:last-child {
        margin-left: 24px;
      }
      &.selected {
        & p {
          position: relative;
          color: #0446ca;
          font-weight: 700;
          &::after {
            width: 100%;
            height: 2px;
            background-color: #0446ca;
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
          }
        }
      }
      ${media.mobile} {
        & p {
          font-size: 16px;
        }
        & span {
          margin-left: 8px;
          font-size: 12px;
        }
        &:last-child {
          margin-left: 24px;
        }
      }
    }
  }
`;

export const PlansAndMethods = styled.div`
  display: flex;
  flex-direction: column;
  ${media.mobile} {
    width: 100%;
    padding: 0 16px;
  }
`;
