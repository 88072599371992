import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const FeaturesContainer = styled.div`
  & .features-slider {
    border: none;
    padding: 0 auto;
  }
`;

export const Header = styled.header`
  text-align: center;
  padding-top: 168px;
  padding-bottom: 168px;
  & .page-desc {
    margin-top: 24px;
    font-size: 22px;
    line-height: 34px;
    margin-bottom: 0;
  }
  & .signup-box {
    align-items: center;
  }
  ${media.tablet} {
    padding-bottom: 120px;
    & .signup-box {
      margin-top: 0;
    }
  }
  ${media.mobile} {
    padding-top: 120px;
    padding-bottom: 90px;
    & h1 {
      font-size: 36px;
      line-height: 48px;
    }
    & .page-desc {
      font-size: 18px;
      line-height: 28px;
      margin-top: 16px;
    }
  }
`;

export const Section = styled.section`
  padding: 0;
  ${media.tablet} {
    padding: 0 15px;
    margin: 0 auto;
    & div.container div.reversed div.content {
      margin-bottom: 56px;
    }
  }
`;

export const CarouselItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: calc(100vw - 30px);
  padding: 0 15px;
  ${media.tablet} {
    width: auto;
    max-width: 576px;
    margin: 0 auto;
  }
  h4 {
    font-size: 20px;
    line-height: 32px;
    font-weight: 900;
    ${media.tablet} {
      font-size: 18px;
    }
  }
  p {
    font-size: 16px;
    line-height: 28px;
    font-weight: 600;
    ${media.tablet} {
      font-size: 14px;
    }
  }
`;

export const PersonalDataSection = styled.section`
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 56px 48px;
  display: flex;
  & .content {
    margin-right: 48px;
    & h2 {
      margin-bottom: 32px;
      & p {
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 24px;
      }
    }
  }
  & div {
    flex: 1;
  }
  ${media.desktop} {
    & .main-image {
      display: none;
    }
  }
  ${media.tablet} {
    & .content {
      margin-right: 0;
    }
  }
  ${media.mobile} {
    padding: 24px;
    & .content {
      & h2 {
        font-size: 24px;
        line-height: 36px;
        margin: 0px 0px 16px;
      }
      & p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 18px;
      }
      & a {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  margin-top: 32px;
  & a {
    display: block;
    cursor: pointer;
  }
  & a:first-child {
    margin-right: 24px;
  }
  ${media.mobile} {
    display: none;
  }
`;

export const MainFeaturesSection = styled.section`
  padding: 0 0;
  & h2 {
    margin-bottom: 24px;
    text-align: center;
  }
  & .subtitle {
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    margin-bottom: 80px;
  }
  ${media.mobile} {
    padding-top: 30px;
    padding-bottom: 50px;
    & .subtitle {
      margin-bottom: 40px;
    }
  }
`;

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-column-gap: 80px;
  grid-row-gap: 120px;
  & div {
    display: flex;
    flex-direction: column;
    & h3 {
      font-size: 28px;
      line-height: 42px;
      margin-top: 32px;
    }
    & p {
      font-size: 18px;
      line-height: 30px;
      margin-top: 20px;
      margin-bottom: 0;
    }
  }
  ${media.desktop} {
    grid-row-gap: 90px;
  }
  ${media.tablet} {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto);
  }
  ${media.mobile} {
    grid-row-gap: 90px;
  }
`;
