import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import { StyledButton } from 'components/ui/button';

export const SignUpForm = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  ${media.tablet} {
    flex-wrap: wrap;
    width: 100%;
  }
  ${StyledButton} {
    border: 2px solid #000 !important;
    ${media.tablet} {
      border-radius: 4px;
      width: 100%;
      max-width: 328px;
    }
    &:hover {
      border-color: #000;
    }
  }

  label {
    margin-bottom: 0;
    ${media.tablet} {
      width: 100%;
    }
    input {
      border-radius: 4px;
      border: none;
      height: 48px;
      width: 280px;
      padding-left: 16px;
      outline: 0;
      -webkit-appearance: none;
      border: 1px solid #fff;
      margin-right: 16px;
      &::placeholder {
        font-size: 16px;
        opacity: 1;
        font-weight: 400;
        color: #808080;
      }
      ${media.tablet} {
        width: 100%;
        margin-bottom: 16px;
        border-radius: 4px;
        margin-right: 0;
      }
    }
  }
`;
