/* eslint-disable jsx-a11y/anchor-is-valid */

import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import withIntegrationCardContext from 'hoc/withIntegrationCardContext';
import Slider from 'react-slick';

const Mixpanel = ({ sliderSettings }) => {
  const { img1, img2 } = useStaticQuery(graphql`
    {
      img1: file(relativePath: { eq: "marketplace/mixpanel/1.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      img2: file(relativePath: { eq: "marketplace/mixpanel/2.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);
  const photos = [
    {
      img: img1.childImageSharp,
      alt: 'Mixpanel Integration',
      title: 'Integrate Mixpanel with LiveSession',
    },
    {
      img: img2.childImageSharp,
      alt: 'Mixpanel Integration',
      title: 'Integrate Mixpanel with LiveSession',
    },
  ];
  return (
    <>
      <Slider {...sliderSettings(photos)}>
        {photos.map(({ img, alt, title }) => (
          <div key={img}>
            <GatsbyImage image={img.gatsbyImageData} alt={alt} title={title} />
          </div>
        ))}
      </Slider>
      <div className="section">
        <h2>Overview</h2>
        <p>
          Mixpanel is a product analytics tool to help you learn more about your users, increase
          engagement, and boost conversions. LiveSession integration will help you get even more
          from it:
        </p>
      </div>
      <div className="section">
        <h2>Benefits</h2>
        <ul className="checkmarks">
          <li>Add a direct session recording link to Mixpanel </li>
          <li>Access all user data in one place</li>
          <li>Get more insights</li>
        </ul>
      </div>
      <h2>How to install</h2>
      <p>
        Just copy and paste a piece of code, as described in our{' '}
        <a href="/help/mixpanel-integration/">help center</a>.
      </p>
    </>
  );
};

export default withIntegrationCardContext(Mixpanel);

Mixpanel.propTypes = {
  sliderSettings: PropTypes.func.isRequired,
};
