import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NewsletterForm, Container, Message } from 'components/styled/NewsletterForm';
import { post } from 'api';
import ls from '@livesession/sdk';
import * as Styled from './styled';

const NewsletterBox = ({ blogPost }) => {
  const [email, setEmail] = useState('');
  const [msg, setMsg] = useState('');
  const [isSigned, setSignFlag] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const data = JSON.stringify({ email });
  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    post('/accounts/newsletter', data)
      .then(() => {
        setSignFlag(true);
        setMsg('Thanks for subscribing!');
        ls.track('Newsletter subscribed');
        setLoading(false);
      })
      .then(() => {
        if (typeof window !== `undefined`)
          window.location.href = `${process.env.GATSBY_HOST}/newsletter-confirmed/`;
      })
      .catch((err) => {
        let message = '';
        if (err.response !== undefined) {
          message = err.response.data.error.message;
        } else {
          message = 'Unknown error, try again later.';
        }
        setMsg(message);
        setSignFlag(false);
        setLoading(false);
      });
  };

  return (
    <Styled.Section className="container" blogPost={blogPost}>
      <Styled.Box>
        <Styled.Info>
          <h2>Want more knowledge?</h2>
          <p>
            Get more tips and insights on UX, research and CRO. Zero spam. Straight to your inbox.
          </p>
        </Styled.Info>
        <NewsletterForm onSubmit={(e) => handleSubmit(e)}>
          <Container>
            <label htmlFor="subscribe-newsletter" className="subscribe">
              <input
                id="subscribe-newsletter"
                type="email"
                name="email"
                placeholder="Your email address"
                required
                value={email}
                className={!isSigned && msg && 'error'}
                onChange={({ target: { value } }) => setEmail(value)}
                disabled={isLoading}
              />
              <Message className={[!isSigned && 'error', isSigned && 'success'].join(' ')}>
                {msg}
              </Message>
            </label>
            <Styled.CustomStyledButton as="button" type="submit" disabled={isLoading}>
              Subscribe
            </Styled.CustomStyledButton>
          </Container>
          <label htmlFor="read-agreements" className="agreement">
            <input type="checkbox" name="agreement" id="read-agreements" required />
            <p>
              I have read the{' '}
              <a title="Privacy policy" href="https://livesession.io/privacy-policy/">
                privacy policy
              </a>{' '}
              and I accept{' '}
              <a href="/newsletter-terms-and-conditions" target="_blank" rel="noopener noreferrer">
                newsletter terms and conditions
              </a>
              .
            </p>
          </label>
        </NewsletterForm>
      </Styled.Box>
    </Styled.Section>
  );
};

export default NewsletterBox;

NewsletterBox.propTypes = {
  blogPost: PropTypes.bool,
};

NewsletterBox.defaultProps = {
  blogPost: false,
};
