import { authorAnia, authorKasia } from 'data/authors';
import Link from 'components/ui/link';

import cover from 'img/user-experience/cover/userexperience1.png';

export const data = [
  {
    url: '/user-experience/',
    title: 'Introduction',
    desc: 'How to Create a Great User Experience? All You Need to Know',
    metaDesc:
      'Customers nowadays demand a great user experience and are willing to pay extra for it. Learn everything you should know about creating a great UX.',
    timeToRead: 14,
    author: authorAnia,
    date: 'June 09, 2022',
    heading: (
      <>
        <p>
          User experience (UX) is a buzzword that has dominated the business world for years. And
          there is a good reason for it – according to{' '}
          <Link
            href="https://www.salesforce.com/research/customer-expectations/"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            Salesforce
          </Link>
          , 67% of customers say they are willing to pay more for a better user experience. This
          gives businesses a great opportunity to stand out from the crowd. While products are easy
          to imitate, great experiences aren’t.
        </p>
        <p>
          In the following guide, we’re going to explore what UX experience is, how to create an
          effective UX design process and what it should include, as well as tell you about
          conducting UX research and creating UX prototypes. Let’s dive in.
        </p>
      </>
    ),
    button: {
      text: 'Improve user experience',
    },
    cover,
  },
  {
    url: '/user-experience/what-is-ux/',
    title: 'Chapter 1',
    desc: 'What is UX? 10 Expert Definitions',
    metaDesc: `There are many ways to define user experience apart from the standard Wikipedia answer. See how 10 experts describe what UX means to them.`,
    timeToRead: 7,
    author: authorKasia,
    date: 'June 09, 2022',
    heading: (
      <>
        <p>
          Did you know that every{' '}
          <Link
            href="https://www.forrester.com/report/The+Six+Steps+For+Justifying+Better+UX/-/E-RES117708"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            $1 invested in user experience has a return of $100
          </Link>
          ? This translates into a ROI of 10,000%, which seems like a pretty good investment to us.
          However, before you start spending your hard-earned money, it’s worth gaining a deep
          understanding of what UX design is.
        </p>
        <p>
          For this purpose, we decided to ask 10 business experts “what is UX?” Take a look at their
          insights.{' '}
        </p>
      </>
    ),
    button: {
      text: 'Improve user experience',
    },
    cover,
  },
  {
    url: '/user-experience/ux-design-process/',
    title: 'Chapter 2',
    metaDesc: `Learn how to implement an effective UX design process - having one in place is necessary for building products that will meet customer expectations.
    `,
    desc: 'UX Design Process: Essential Elements to Get it Right',
    timeToRead: 8,
    author: authorAnia,
    date: 'June 09, 2022',
    heading: (
      <>
        <p>
          Customers are becoming increasingly demanding, and that should come as no surprise. Given
          the number of alternatives available to them, they can afford to be picky. In fact,{' '}
          <Link
            href="https://www.toptal.com/designers/ux/ux-statistics-insights-infographic"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            90% of real users
          </Link>{' '}
          stop using an app due to poor performance. Is there a way to ensure this doesn’t happen to
          you? Of course there is, provided that you have effective UX design process steps in
          place.
        </p>
        <p>
          In the following article, we’re going to talk about the importance of your UX design
          decisions, discuss the most crucial user experience elements, and guide you step by step
          through creating an efficient UX design process.
        </p>
      </>
    ),
    button: {
      text: 'Improve user experience',
    },
    cover,
  },
  {
    url: '/user-experience/user-experience-design/',
    title: 'Chapter 3',
    desc: 'User Experience Design: Improve with Best Practices',
    metaDesc: `One of the keys to a successful business lies in effective user experience design. Read on to learn UX design best practices and see how to improve your product.`,
    timeToRead: 9,
    author: authorAnia,
    date: 'June 11, 2020',
    heading: (
      <>
        <h2>The Key to Launching a Successful Product</h2>
        <p>
          It’s a proven fact that UX design can make or break a business by heavily impacting its
          customer loyalty levels. In fact, according to{' '}
          <Link
            href="https://www.pwc.com/us/en/advisory-services/publications/consumer-intelligence-series/pwc-consumer-intelligence-series-customer-experience.pdf"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            PWC
          </Link>
          , 82% of top-performing businesses admit that they prioritize usability across their
          digital touchpoints.
        </p>
        <p>
          By taking your audience’s experience and opinions into account, you can raise their
          satisfaction levels and increase sales of your products or services. These are metrics
          that you can actively improve by surveying your audience and researching their
          preferences.
        </p>
        <p>
          In the following article, we discuss several best practices to improve the user experience
          and share examples of rich user experience design implementations.
        </p>
      </>
    ),
    button: {
      text: 'Improve user experience',
    },
    cover,
  },

  {
    url: '/user-experience/user-journey-map/',
    title: 'Chapter 4',
    desc: 'How to Develop a User Journey Map: 6 Simple Steps',
    metaDesc: `Developing a user journey map is a necessity for providing a great user experience. Read this article to learn how.`,
    timeToRead: 8,
    author: authorKasia,
    date: 'June 09, 2022',
    heading: (
      <>
        <p>
          Launching a product or service is an exciting process. Every product owner wants to turn
          potential customers into first-time buyers and then repeat customers. However, this
          requires gaining valuable insights into who they are and what they need.
        </p>
        <p>
          Luckily, by going through the process of creating a user journey map, you can design your
          product or service in a way that motivates your customer base and provides them with the
          results they desire to achieve with it.
        </p>
        <p>
          Before we start creating a user journey map, it is important to have empathy for customers
          and understand what their buyer journey entails. So, let us first peek into the definition
          of a user journey and what it means.
        </p>
      </>
    ),
    button: {
      text: 'Improve user experience',
    },
    cover,
  },
  {
    url: '/user-experience/ux-prototyping/',
    title: 'Chapter 5',
    desc: 'Effective UX Prototyping in a Nutshell',
    metaDesc: `UX prototyping is the best way to meet your users’ expectations. Read on to learn about the various prototype methodologies and their benefits for your product.

    `,
    timeToRead: 8,
    author: authorKasia,
    date: 'June 09, 2022',
    heading: (
      <>
        <h2>The Key to Effective UX Prototyping</h2>
        <p>
          According to recent user experience (UX){' '}
          <Link
            href="https://www.invisionapp.com/inside-design/statistics-on-user-experience/"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            statistics
          </Link>
          , first impressions are 94% design related. As you can only make a first impression on
          your users once, it better be a good one. One way of ensuring that your product or service
          is the best it can possibly be is by leveraging UX prototyping.
        </p>
        <p>
          In the following article, we will tackle UX Prototyping inside out – from its benefits and
          various methodologies all the way through to the different types of effective prototyping
          approaches that are available.
        </p>
      </>
    ),
    button: {
      text: 'Improve user experience',
    },
    cover,
  },
  {
    url: '/user-experience/ux-research/',
    title: 'Chapter 6',
    desc: 'How to Conduct Effective UX Research?',
    metaDesc:
      'Conducting mobile usability tests is necessary to ensure your users return to the app. Find out how to test your apps effectively and what tools to use.', // to change
    timeToRead: 7,
    author: authorAnia,
    date: 'June 09, 2022',
    button: {
      text: 'Improve user experience',
    },
    heading: (
      <>
        <p>
          According to a report from{' '}
          <Link
            href="https://www.forbes.com/sites/forbestechcouncil/2017/01/23/how-ux-is-transforming-business-whether-you-want-it-to-or-not/#6e9a1a0c580e"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            Forrester
          </Link>
          , every dollar invested in UX brings $100 in return on average. This translates to a
          staggering 10,000% in Return on Investment (ROI). However, in order to ensure that your
          product’s UX is effective, you must prioritize thorough user experience research. This is
          an essential element of the UX process that allows you to find the true value of your
          products or services, and gauge whether or not they will sell in the real world.
        </p>
        <p>
          In this article, we are going to take a closer look at user experience research, the
          various types, and the different methodologies that you can use to conduct these studies.
        </p>
      </>
    ),
    cover,
  },
];
