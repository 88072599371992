import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import { StyledDivInput, StyledA } from 'components/help/searchbar/autocomplete';

export const FindAnswer = styled.div`
  max-width: 380px;
  z-index: 10;
  height: fit-content;
  background-color: #f1f5f9;
  padding: 40px;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 120px;
  ${media.desktop} {
    max-width: 100%;
    margin-top: 32px;
  }
  h2 {
    font-weight: 900;
    font-size: 20px;
    line-height: 44px;
    margin-bottom: 0;
    ${media.tablet} {
      font-size: 18px;
    }
  }
  p {
    margin-top: 16px;
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 500;
    ${media.tablet} {
      font-size: 14px;
    }
  }
  a {
    margin-top: 98px;
    color: #0a4ed6;
    font-weight: 800;
    font-size: 20px;
    ${media.tablet} {
      margin-top: 64px;
      font-size: 18px;
    }
  }
  a + a {
    margin-top: 24px;
  }

  & ${StyledDivInput} {
    width: auto;
    margin: 0;
  }
  & ${StyledA} {
    margin-top: 0;
    div {
      p:nth-child(1) {
        margin-top: 0;
      }
    }
  }
  & .react-autosuggest__suggestions-container--open {
    right: 0 !important;
    transform: translate(0, 52px) !important;
    ${media.desktop} {
      width: 100% !important;
    }
  }
  ${media.tablet} {
    margin: 58px 15px 15px;
    padding: 30px;
    height: auto;
  }
`;
