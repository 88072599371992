import styled from 'styled-components';
import logoLight from 'img/LogoLS/logo_livesession_light.svg';
import logoDark from 'img/LogoLS/logo_livesession_dark.svg';

export const LogoContainer = styled.div`
  min-width: 140px;
  max-width: 150px;
  height: 55px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  background-image: url(${(props) => (props.logoImage ? logoDark : logoLight)});
  @media (max-width: 600px) {
    min-width: 130px;
    height: 40px;
  }
`;

export const Brand = styled.a`
  display: flex !important;
  align-items: center !important;
  width: auto !important;
  font-size: 1.1rem !important;
  font-weight: 600;
  color: inherit;
  position: relative;
  margin-right: 12px;
  &:hover {
    color: inherit;
  }

  @media (max-width: 600px) {
    font-size: 1rem;
  }
`;
