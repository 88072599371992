import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import Link from 'components/ui/link';
import { GatsbyImage } from 'gatsby-plugin-image';

export const Title = styled.h2`
  margin: 0 0 16px;
  font-size: 22px;
  line-height: 34px;
  font-weight: 700;
  ${media.desktopWide} {
    margin: 0 0 8px;
    font-size: 18px;
    line-height: 24px;
  }
  ${media.desktop} {
    margin: 0 0 16px;
    font-size: 22px;
    line-height: 34px;
  }
  ${media.tablet} {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Preview = styled.div`
  max-height: auto;
  display: flex;
  margin: 20px 0;
  flex-direction: ${(props) => (props.isList ? 'column' : 'row')};
  color: #000;
  ${media.desktop} {
    flex-direction: column;
    max-height: fit-content;
    margin: 0 0 13px;
  }
`;

export const FirstPreview = styled.div`
  display: flex;
  flex-direction: column;
  color: #000;
  ${Title} {
    font-size: 36px;
    line-height: 50px;
  }
  ${media.tablet} {
    ${Title} {
      font-size: 26px;
      line-height: 38px;
    }
    & p:first-child,
    & p:last-child {
      font-size: 14px !important;
    }
    & .desc {
      font-size: 14px;
    }
  }
`;

export const Content = styled.div`
  margin-left: ${(props) => (props.isList ? '0' : '24px')};
  margin-top: ${(props) => (props.isList ? '16px' : '0')};
  display: flex;
  flex-direction: column;
  & p {
    margin: 0;
    font-weight: 400;
    transition: none;
  }
  & p:first-child {
    font-size: 14px;
    color: #0a4ed6;
    text-transform: uppercase;
    margin: 0 0 8px;
    font-weight: 700;
    line-height: normal;
  }
  & p:last-child {
    font-size: 12px;
    color: #808080;
    font-weight: ${(props) => (props.isList ? '500' : '400')};
    margin-top: 6px;
    line-height: normal;
    margin-bottom: 0;
  }
  & p.name {
    margin-bottom: 0;
    font-size: 14px;
    line-height: normal;
  }
  & p.name.bigger {
    font-size: 16px;
  }
  ${media.desktop} {
    margin-left: 0;
    margin-top: 16px;
  }
  ${media.tablet} {
    & p:first-child,
    & p:last-child {
      font-size: 12px;
      line-height: normal;
    }
  }
`;

export const FirstContent = styled(Content)`
  margin: 60px 0 0 0;
  & p {
    margin: 0 0 24px;
    font-size: 18px;
  }
  & .desc {
    line-height: 30px;
  }
`;

export const FirstImg = styled(GatsbyImage)`
  border-radius: 4px;
  transition: 0.3s;
  width: 100%;

  ${media.desktop} {
    min-height: auto;
    height: ${(props) => (props.isList ? '180px' : 'auto')};
  }
  ${media.mobile} {
    object-fit: cover;
    height: 150px;
  }
`;

export const GatsbyImg = styled(GatsbyImage)`
  border-radius: 4px;
  transition: 0.3s;
  width: 100%;

  ${media.desktop} {
    min-height: auto;
    height: ${(props) => (props.isList ? '180px' : 'auto')};
  }
  ${media.mobile} {
    object-fit: cover;
    height: 150px;
  }
`;

export const CustomImg = styled.img`
  width: ${(props) => (props.isList ? '100%' : '150px')};
  height: ${(props) => (props.isList ? '210px' : '150px')};
  border-radius: 4px;
  transition: 0.3s;
  object-fit: cover;
  ${media.desktopWide} {
    object-fit: cover;
  }
  ${media.desktop} {
    min-height: auto;
    height: ${(props) => (props.isList ? '180px' : '150px')};
    width: 100%;
  }
  ${media.mobile} {
    height: 150px;
  }
`;

export const CustomLink = styled(Link)`
  display: block;
  transition: none;
  &:hover {
    text-decoration: none;
    ${GatsbyImg}:not(.first),
    & ${CustomImg}, & ${FirstImg} {
      transform: scale(1.08);
    }
    & ${Title} {
      text-decoration: underline;
    }
  }
`;

export const ImageBox = styled.div`
  overflow: hidden;
  min-width: 150px;
  min-height: ${(props) => (props.isList ? '210px' : '150px')};
  & .gatsby-image-wrapper {
    min-width: 150px;
    min-height: ${(props) => (props.isList ? '210px' : '150px')};
  }
  &.recent-posts {
    flex-basis: 150px;
  }
  &.first {
    transform: scale(1.2) translateX(-7%);
    @media (max-width: 1440px) {
      transform: scale(1) translateX(0);
    }
  }
  ${media.desktop} {
    min-height: 150px;
  }
`;

export const TimeToRead = styled.p`
  margin-bottom: 0;
  position: relative;
  &.bigger {
    font-size: 14px !important;
  }
  & span {
    position: relative;
    margin-left: 24px;
    &::before {
      content: '';
      width: 4px;
      height: 4px;
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: -14px;
      transform: translateY(-50%);
      background-color: #808080;
    }
  }
`;
