import Layout from 'layout';
import { ExtendGridBox } from 'styles/integrations';
import SideBar from 'components/marketplace/sideBar';
import Content from 'components/marketplace/content';
import Banner from 'components/marketplace/banner';
import { frontmatter } from 'data/marketplace/integrations';
import arrow from 'img/ui/arrow_black.svg';
import OwnAppSection from 'components/marketplace/ownAppSection';

const CustomerSupport = () => {
  const [mostPopularCard] = frontmatter.items.filter((item) => item.title === 'Intercom');
  return (
    <Layout
      metaTitle="Customer Support | Integrations"
      canonical="/integrations/customer-support/"
      metaDescription="Learn what the users do on your website and provide better customer service with LiveSession."
    >
      <div className="container">
        <ExtendGridBox>
          <div className="breadcrumbs">
            <p>
              <a href="/integrations/">Integrations</a>
            </p>
            <img src={arrow} alt="arrow" title="Arrow" />
            <p>
              <a href="/integrations/customer-support/">
                <strong>Customer support</strong>
              </a>
            </p>
          </div>
          <SideBar activeCategory="Customer support" underlineColor="#8765F9" />
          <Content activeCategory="Customer support" hideTitle>
            <Banner
              activeCategory="Customer support"
              card={mostPopularCard}
              text="Learn what the users do on your website and provide better customer service with LiveSession."
              underlineColor="#8765F9"
            />
          </Content>
        </ExtendGridBox>
        <OwnAppSection />
      </div>
    </Layout>
  );
};

export default CustomerSupport;
