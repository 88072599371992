/* eslint-disable jsx-a11y/anchor-is-valid */

import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import withIntegrationCardContext from 'hoc/withIntegrationCardContext';
import Slider from 'react-slick';

const Dashly = ({ sliderSettings }) => {
  const { img1 } = useStaticQuery(graphql`
    {
      img1: file(relativePath: { eq: "marketplace/dashly/1.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);
  const photos = [
    {
      img: img1.childImageSharp.gatsbyImageData,
      alt: 'Dashly Integration',
      title: 'Integrate Dashly with LiveSession',
    },
  ];
  return (
    <>
      <Slider {...sliderSettings(photos)} className="without-dots">
        <div>
          <GatsbyImage image={photos[0].img} alt={photos[0].alt} title={photos[0].title} />
        </div>
      </Slider>
      <div className="section">
        <h2>Overview</h2>
        <p>
          Dashly is an all-in lead generation tool. You can use it to talk to your visitors, as well
          as for user tracking and analytics.
        </p>
      </div>
      <div className="section">
        <h2>Benefits</h2>
        <ul className="checkmarks">
          <li>Access LiveSession recordings straight from Dashly</li>
          <li>Learn more about your users</li>
          <li>Generate more leads </li>
        </ul>
      </div>
      <h2>How to install</h2>
      <p>
        Just copy and paste a piece of code, as described in our{' '}
        <a href="/help/dashly-integration/">help center</a>.
      </p>
    </>
  );
};

export default withIntegrationCardContext(Dashly);

Dashly.propTypes = {
  sliderSettings: PropTypes.func.isRequired,
};
