/* eslint-disable jsx-a11y/anchor-is-valid */

import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import withIntegrationCardContext from 'hoc/withIntegrationCardContext';
import Slider from 'react-slick';

const Crisp = ({ sliderSettings }) => {
  const { img1 } = useStaticQuery(graphql`
    {
      img1: file(relativePath: { eq: "marketplace/crisp/1.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);
  const photos = [
    {
      img: img1.childImageSharp.gatsbyImageData,
      alt: 'Crisp Integration',
      title: 'Integrate Crisp with LiveSession',
    },
  ];
  return (
    <>
      <Slider {...sliderSettings(photos)} className="without-dots">
        <div>
          <GatsbyImage image={photos[0].img} alt={photos[0].alt} title={photos[0].title} />
        </div>
      </Slider>
      <div className="section">
        <h2>Overview</h2>
        <p>
          Crisp is a comprehensive customer service app. The wide choice of features includes live
          chat, chatbot, shared inbox, knowledge base, as well as an advanced CRM system. If you’re
          looking for an omnichannel customer support solution, Crisp should make a great fit.
        </p>
        <p>
          The LiveSession & Crisp integration will help you improve communication with your clients.
        </p>
      </div>
      <div className="section">
        <h2>Benefits</h2>
        <ul className="checkmarks">
          <li>Access session replays directly from Crisp</li>
          <li>Get more insights on user behavior</li>
          <li>Provide better customer support</li>
        </ul>
      </div>
      <h2>How to install</h2>
      <p>
        Just copy and paste a piece of code. See a detailed tutorial in our{' '}
        <a href="/help/crisp-integration/">help center</a>.
      </p>
    </>
  );
};

export default withIntegrationCardContext(Crisp);

Crisp.propTypes = {
  sliderSettings: PropTypes.func.isRequired,
};
