import styled from 'styled-components';
import { StyledButton } from 'components/ui/button';
import { media } from 'theme/breakpoints';

export const Section = styled.section`
  display: grid;
  grid-template-columns: auto 655px;
  grid-template-rows: auto;
  padding: 0 0 120px 0;
  .WrapperCustomers {
    section {
      justify-content: start;
      ${media.desktop} {
        justify-content: space-around;
      }
    }
    a {
      padding: 10px;
    }
  }
  ${media.desktop} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  ${media.mobile} {
    padding: 0 0 90px;
  }
  .imgWrapper {
    transform: translate(60px, 0);
    ${media.desktop} {
      transform: translate(0, 0);
      margin-top: 40px;
    }
  }
`;

export const ContentSection = styled.div`
  display: flex;
  flex-direction: column;

  ${media.desktop} {
    margin-bottom: 40px;
    text-align: center;
    align-items: center;
  }
  h1 {
    margin-top: 40px;
    ${media.desktop} {
      margin-top: 0;
    }
  }
  p.subtitle {
    font-size: 22px;
    line-height: 34px;
    margin-bottom: 32px;
    margin-top: 24px;
    width: 550px;
    ${media.desktop} {
      width: auto;
    }
    ${media.tablet} {
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 32px;
    }
  }
  & ${StyledButton} {
    width: min-content;
    height: auto;
    width: 176px;
  }
  .contentTrial {
    font-size: 14px;
    line-height: 18px;
    margin: 24px 0 40px;
  }
`;
