import PropTypes from 'prop-types';
import clock from 'img/ui/clock.svg';
import * as Styled from './styled';

const OtherChapters = ({ pathname, data }) => (
  <Styled.Section>
    <h2>Other chapters</h2>
    <div className="card-box">
      {data.map(({ title, desc, timeToRead, url }) => (
        <Styled.Card key={url} className={url === pathname ? 'active' : null} to={url}>
          <div>
            <p>{title}</p>
            <h3>{desc}</h3>
          </div>
          <p className="time">
            <img src={clock} alt="clock" title="Time to read" /> {timeToRead} min
          </p>
        </Styled.Card>
      ))}
    </div>
  </Styled.Section>
);

export default OtherChapters;

OtherChapters.propTypes = {
  pathname: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([PropTypes.array]).isRequired,
};
