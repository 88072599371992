import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// import queryString from 'query-string';
// import Cookies from 'js-cookie';
import GlobalStyles from 'theme/GlobalStyle';
import { track } from 'services/tracking';
// import ls from '@livesession/sdk';
import CookieBar from './cookieBar';
import Footer from './footer';
import Header from './header';
import SEO from './SEO/seo';
import 'sass/style.scss';
import 'css/prism.css';
import 'tippy.js/dist/tippy.css';
import 'css/tippy.css';
import 'css/entypo.css';
// const HIDE_BANNER_COOKIE = 'hide_producthunt_banner';

const Layout = ({
  children,
  metaTitle,
  metaDescription,
  canonical,
  hideFooter = false,
  extraTags,
  hideCookieBanner = false,
  font,
  btnColor,

  photoColor,
  author,
  image,
  datePublished,
  blockScrollX,
  addBanner,
  textBanner,
  adPage,
  hideHeader,
  noIndex,
  hideBorder,
}) => {
  useEffect(() => {
    track();
    // const acceptedCookies = Cookies.get(HIDE_BANNER_COOKIE);
    // const data = queryString.parse(window.location.search);
    // if (data.ref === 'producthunt' && acceptedCookies !== '1' && !isModalActive) {
    //   setModalActive(true);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // if (process.env.GATSBY_LS_ENV === 'prod') {
    //   ls.init('123.f4572ba8', null, { scriptURL: 'https://cdn.staging.livesession.io/track.js' });
    //   ls.setOptions({ accountID: '123', websiteID: '8c993c34' });
    //   ls.newPageView();
    // }
  }, []);
  const noIndexPage = process.env.GATSBY_ENV !== 'PROD' || noIndex;

  let extra;
  if (extraTags) {
    extra = extraTags.map((tag) => <meta {...tag} key={tag} />);
  }
  return (
    <>
      <GlobalStyles blockScrollX={blockScrollX} />
      <SEO
        description={metaDescription}
        title={metaTitle}
        noIndex={noIndexPage}
        extraTags={extra}
        canonical={canonical}
        datePublished={datePublished}
        author={author}
        image={image}
      />
      {!hideHeader && (
        <Header
          btnColor={btnColor}
          font={font}
          photoColor={photoColor}
          addBanner={addBanner}
          textBanner={textBanner}
          hideBorder={hideBorder}
        />
      )}
      {children}
      {!hideFooter && <Footer adPage={adPage} />}
      {!hideCookieBanner && <CookieBar />}
    </>
  );
};

export default Layout;

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.object]).isRequired,
  metaTitle: PropTypes.string.isRequired,
  metaDescription: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  // eslint-disable-next-line consistent-return
  canonical: (props, propName, componentName) => {
    if (props.noIndex !== true && props.canonical === undefined) {
      return new Error(`Please provide a ${propName} link in ${componentName}!`);
    }
  },
  hideFooter: PropTypes.bool,
  extraTags: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  hideCookieBanner: PropTypes.bool,
  font: PropTypes.string,
  btnColor: PropTypes.string,
  photoColor: PropTypes.string,
  author: PropTypes.string,
  image: PropTypes.string,
  datePublished: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  blockScrollX: PropTypes.bool,
  textBanner: PropTypes.string,
  addBanner: PropTypes.bool,
  adPage: PropTypes.bool,
  hideHeader: PropTypes.bool,
  noIndex: PropTypes.bool,
  hideBorder: PropTypes.bool,
};
Layout.defaultProps = {
  hideFooter: false,
  hideCookieBanner: false,
  extraTags: null,
  author: '',
  image: null,
  datePublished: '',
  blockScrollX: false,
  font: '',
  btnColor: '',
  photoColor: '',
  metaDescription: null,
  textBanner: null,
  addBanner: false,
  hideHeader: false,
  adPage: false,
  canonical: undefined,
  hideBorder: undefined,
  noIndex: false,
};
