import React, { useState } from 'react';
import Layout from 'layout';
import Link from 'components/ui/link';
import Code from 'components/help/codeSnippet';
import badgeLight from 'img/badges/light.svg';
import badgeDark from 'img/badges/dark.svg';
import { Section, Container, SubTitle, Buttons, CustomButton, Preview } from 'styles/badge';

const Badge = () => {
  const [currentbadge, setCurrentbadge] = useState('dark');
  return (
    <Layout
      canonical="/badge/"
      metaTitle="Add a Badge to Your Website"
      metaDescription="Add a LiveSession badge to your website and get 5,000 sessions for free."
    >
      <div className="container">
        <Section className="row">
          <Container className="col-xl-6 col-md-12">
            <h1 className="line">
              Record and analyze <span>+5,000 sessions for free.</span>
            </h1>
            <SubTitle>
              Just add our badge to your website and we&apos;ll give you extra sessions.
            </SubTitle>
          </Container>
          <div className="col-xl-6 col-md-12">
            <ol>
              <li>
                <p>Add this code to the footer on your website:</p>
                <Buttons>
                  <CustomButton
                    type="button"
                    className={currentbadge === 'dark' && 'active'}
                    onClick={() => setCurrentbadge('dark')}
                  >
                    Dark
                  </CustomButton>
                  <CustomButton
                    type="button"
                    className={currentbadge === 'light' && 'active'}
                    onClick={() => setCurrentbadge('light')}
                  >
                    Light
                  </CustomButton>
                </Buttons>
                <Code copy lang="html" currentValue={currentbadge} style={{ marginTop: 0 }}>
                  {`<a href="https://livesession.io/?utm_source=badge">
  <img style="width:108px;height:40px" 
  src="https://static.livesession.io/badge_${currentbadge}.svg" 
  alt="LiveSession | Session replay software" /></a>`}
                </Code>
                <Preview>
                  <p>Preview: </p>
                  <img
                    src={currentbadge === 'light' ? badgeLight : badgeDark}
                    alt="Badge"
                    title="LiveSession Badge"
                  />
                </Preview>
              </li>
              <li>
                <p>
                  Let us know that you&apos;ve added the badge. Write to us at{' '}
                  <Link href="mailto:hello@livesession.io">
                    <strong>hello@livesession.io</strong>
                  </Link>{' '}
                  and we&apos;ll give you <strong>5,000 sessions</strong> for free.
                </p>
              </li>
            </ol>
          </div>
        </Section>
      </div>
    </Layout>
  );
};

export default Badge;
