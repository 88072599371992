/* eslint-disable react/no-array-index-key */

import Layout from 'layout';
import Button from 'components/ui/button';
import StartUsing from 'components/ui/startUsing';
import Logos from 'components/ui/trustedBy';
import StartUsingCTA from 'components/index/startusingCTA';
import Cards from 'components/solutions/cards';
import Section from 'components/solutions/section';
import { data } from 'data/solutions/sections';
import { StyledSection } from 'styles/solutions';

const Solutions = () => (
  <Layout
    metaTitle="Solutions | Session Recordings and Qualitative Analytics"
    metaDescription="See how LiveSession can help with conversion optimization, usability testing, debugging, customer support, and more. Try a new qualitative analytics tool."
    canonical="/solutions/"
  >
    <StyledSection>
      <div className="container">
        <h1 className="line">
          <span>Smart ways</span> to use LiveSession
        </h1>
        <p className="subtitle">One product, different solutions</p>
        <Button signUp secondary center sourceID="hero">
          Sign up free
        </Button>
        <Cards />
        <Logos hero style={{ paddingBottom: 75 }} />
        {[data[0], data[1], data[2]].map((section, idx) => (
          <Section {...section} idx={idx + 1} key={idx} />
        ))}
        <div className="p-120-90 deletePadding">
          <StartUsingCTA />
        </div>
        {[data[3], data[4], data[5]].map((section, idx) => (
          <Section {...section} idx={idx} key={idx} />
        ))}
      </div>
    </StyledSection>
    <StartUsing />
  </Layout>
);

export default Solutions;
