import PropTypes from 'prop-types';
import ImageModal from 'components/ui/image-modal';
import * as Styled from './styled';

const HelpImage = (props) => {
  const { cardStyle, setMarginBottom } = props;
  return (
    <Styled.Card className="card" style={cardStyle} setMarginBottom={setMarginBottom}>
      <Styled.CardBody className="card-body" style={cardStyle}>
        <ImageModal {...props} />
      </Styled.CardBody>
    </Styled.Card>
  );
};

export default HelpImage;

HelpImage.propTypes = {
  cardStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  setMarginBottom: PropTypes.bool,
};

HelpImage.defaultProps = {
  cardStyle: null,
  setMarginBottom: false,
};
