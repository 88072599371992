import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import { StyledButton } from 'components/ui/button';
import Tippy from '@tippyjs/react';

export const Price = styled.div`
  font-size: 36px;
  line-height: 1.2;
  font-weight: 800;
  margin: 42px 0 16px 0;
  position: relative;
  span {
    font-size: 1rem;
    font-weight: 700;
    &.price-rest {
      font-size: 14px;
      font-weight: inherit;
      margin-top: 5px;
      margin-left: 2px;
    }
  }

  ${media.mobile} {
    margin: 12px 0 32px 0;
    span {
      font-size: 14px;
    }
  }
`;

export const PageviewsCTA = styled.p`
  margin: 8px 0 0 0;
  font-size: 12px;
  line-height: 12px;
  span {
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    &:first-child {
      margin-top: 0;
    }
    strong {
      font-weight: 800;
    }
  }
  ${media.mobile} {
    margin: 12px 0 0 0;
    text-align: left;
  }
`;

export const Box = styled.div`
  margin-bottom: 30px;
  position: relative;
  width: 100%;
  border: ${({ mostPopular }) => (mostPopular ? '2px solid #0A4ED6' : '1px solid #e4e5e8')};
  border-right: ${({ mostPopular, free }) =>
    mostPopular ? '2px solid #0A4ED6' : free ? '0' : '1px solid #e4e5e8'};
  background-color: #fff;
  ${media.desktopWide} {
    border-right: ${({ mostPopular }) => (mostPopular ? '2px solid #0A4ED6' : '1px solid #e4e5e8')};
    border-radius: 4px;
  }

  &:last-child {
    margin-right: 0;
  }
  &.custom {
    & ${Price}, & ${PageviewsCTA} {
      display: none;
    }
  }
  &.most-popular {
    position: relative;
    &::before {
      content: 'Recommended';
      color: #fff;
      background-color: #0446ca;
      border-radius: 4px 4px 0px 0px;
      position: absolute;
      top: -32px;
      left: -2px;
      width: calc(100% + 4px);
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 800;
      font-size: 14px;
      line-height: 20px;
    }
  }
  ${media.desktopWide} {
    margin: 0 0 45px;
    justify-self: center;
    width: 100%;
  }
  ${media.mobile} {
    min-height: auto;
    width: 100%;
    margin-top: 24px;
    margin-right: 0;
    margin-bottom: 0;
    border: 1px solid #e4e5e8;
    &:nth-child(3) {
      margin-top: 56px;
    }
    &:first-child {
      margin-top: 10px;
    }
    &.most-popular {
      &::before {
        left: -1px;
        width: calc(100% + 2px);
      }
    }
  }
`;

export const Inner = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 24px;
  & a {
    align-self: flex-end;
  }
  ${StyledButton} {
    max-width: initial;
    width: 100%;
    min-height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ${media.desktopWide} {
    padding: 16px;
  }
  ${media.mobile} {
    align-items: flex-start;
    padding: 24px;
  }
  .collapse:not(.show) {
    display: flex;
    ${media.mobile} {
      display: ${(props) => (props.isOpen ? 'flex' : 'none')};
    }
  }
`;

export const MonthlyPrice = styled.span`
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  color: #788487;
  text-decoration: line-through;
  margin-top: 0.5rem;
`;

export const Badge = styled.div`
  height: 24px;
  background-color: #000;
  color: #fff;
  font-weight: 800;
  font-size: 12px;
  align-items: center;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  position: absolute;
  min-width: 136px;
  border-radius: 4px;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5px 0;
`;

export const PlanName = styled.span`
  font-size: 26px;
  font-weight: 900;
  color: #000;
  margin: 8px 0 0 0;
  ${media.mobile} {
    font-size: 32px;
    line-height: 40px;
    margin: 0 0 8px 0;
  }
`;

export const Currency = styled.span`
  font-size: 14px !important;
  font-weight: 700;
  margin-top: 8px;
  margin-right: 4px;
  position: relative;
  align-self: flex-start;

  ${media.mobile} {
    font-size: 14px;
  }
`;

export const Features = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 16px;
  padding-left: 0;
  margin-bottom: 0;
  li {
    font-size: 14px;
    display: flex;
    justify-content: left;
    align-items: center;
    font-weight: 400;
    padding: 8px 16px;
    line-height: normal;
    width: 100%;
    cursor: pointer;
    &.with-hover {
      &:hover {
        background-color: #f5f5f5;
        border-radius: 20px;
        &.white {
          background-color: #fff;
        }
      }
    }

    img {
      margin-right: 10px;
      width: 0.8em;
    }
  }
`;

export const TextFeature = styled.span`
  display: block;
  &:hover {
    cursor: pointer;
  }
`;

export const Use = styled.p`
  font-size: 16px;
  font-weight: 400;
  margin: 8px 0 0;
  line-height: normal;
  text-align: left;
  ${media.mobile} {
    margin: 0px 0 0;
    font-size: 16px;
    line-height: 28px;
  }
`;

export const Line = styled.em`
  width: 100%;
  height: 1px;
  background-color: ${(props) => (props.mostPopular ? '#000' : '#e4e5e8')};
  margin: 22px 0 22px;
  opacity: ${(props) => (props.mostPopular ? 0.14 : 1)};
`;

export const SeeFeatures = styled.button`
  display: none;
  color: #0a4ed6;
  border: none;
  background-color: transparent;
  font-size: 14px;
  font-weight: 800;
  margin: 32px auto 0;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  outline: 0;
  i {
    display: block;
    font-size: 20px;
    margin-left: 16px;
  }
  ${media.mobile} {
    display: flex;
  }
`;

export const Range = styled.div`
  width: 100%;
  & .disabled-plan {
    position: relative;
    & .change-plan {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      font-size: 13px;
      line-height: 15px;
      color: #808080;
      text-align: center;
      & button {
        background-color: transparent;
        border: none;
        color: #0446ca;
        cursor: pointer;
      }
    }
    & p {
      color: #a7a7a7;
    }
  }
  & .rc-slider {
    position: relative;
    height: 14px;
    padding: 5px 0;
    touch-action: none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    & .rc-slider-track {
      height: 4px;
      background-color: #0446ca;
      position: absolute;
      left: 0;
      border-radius: 20px;
    }
    & .rc-slider-rail {
      position: absolute;
      width: 100%;
      background-color: #ebebeb;
      height: 4px;
      border-radius: 20px;
    }
    & .rc-slider-step {
      position: absolute;
      width: 100%;
      height: 4px;
      background-color: transparent;
    }
    .rc-slider-handle {
      position: absolute;
      width: 16px;
      height: 16px;
      cursor: pointer;
      cursor: -webkit-grab;
      margin-top: -5px;
      cursor: grab;
      border-radius: 50%;
      background-color: #0446ca;
      touch-action: pan-x;
    }
    & .rc-slider-mark {
      & span {
        font-size: 13px;
        line-height: normal;
        color: #000;
        top: 22px;
        position: absolute;
      }
    }
  }
  & .change-plan {
    color: #808080;
  }
  & div {
    display: flex;
    justify-content: space-between;
    & p {
      font-size: 14px;
      line-height: 18px;
    }
  }
  ${media.mobile} {
    margin-top: -8px;
    margin-bottom: 53px;
  }
`;

export const Saving = styled.div`
  color: #00983d;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  font-weight: 700;
  margin-left: 8px;
  align-self: flex-end;
  padding-bottom: 6px;
`;

export const PriceBox = styled.div`
  min-height: 200px;
  display: flex;
  flex-direction: column;
  ${media.mobile} {
    min-height: auto;
  }
`;

export const PricingHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 24px;
  border-bottom: 1px solid #e4e5e8;
  ${media.mobile} {
    width: 100%;
  }
`;
export const CustomPricing = styled.div`
  & .custom-pricing {
    margin-top: 43px;
    font-weight: 800;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 0;
    min-height: 43px;
    display: flex;
    align-items: center;
    ${media.mobile} {
      margin-top: 16px;
      font-size: 18px;
      line-height: 23px;
    }
  }
  & .starts-at {
    font-size: 12px;
    line-height: 12px;
    margin-top: 8px;
    ${media.mobile} {
      margin-top: 4px;
      margin-bottom: 32px;
    }
  }
`;

export const StyledTippy = styled(Tippy)`
  max-width: 220px !important;
`;
