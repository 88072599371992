import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const Header = styled.header`
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #dc608f1a 100%);
  height: 552px;
  padding-top: 168px;
  & .container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    & div {
      flex: 1;
      & p {
        margin: 32px 0 48px;
        font-size: 22px;
        line-height: normal;
      }
    }
    & div.gatsby-image-wrapper,
    & img {
      flex: 1;
    }
  }
  ${media.tablet} {
    height: auto;
    & .head-content-box {
      margin-bottom: 60px;
    }
    & div.gatsby-image-wrapper {
      width: 100%;
      & img {
        margin: 0;
      }
    }
    & img {
      width: 100%;
      margin-top: 60px;
    }
    & .container {
      flex-direction: column;
      & h1 {
        text-align: center;
      }
      & div p {
        margin: 32px 0;
        font-size: 18px;
        text-align: center;
      }
      & a {
        margin: 0 auto;
        display: block;
        width: min-content;
      }
    }
  }
  ${media.mobile} {
    padding-top: 120px;
  }
`;

export const HeadSection = styled.section`
  padding: 120px 0 240px;
  ${media.tablet} {
    padding: 100px 0;
  }
  & h2 {
    text-align: center;
    line-height: 54px;
    ${media.tablet} {
      font-size: 28px;
      line-height: 42px;
    }
  }
  & div.gatsby-image-wrapper {
    margin: 120px auto;
    ${media.tablet} {
      margin: 32px auto 60px;
    }
  }
  & div.text-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, min-content);
    grid-gap: 80px;
    & p {
      margin: 20px 0 0;
    }
    ${media.tablet} {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(4, min-content);
      grid-gap: 60px;
      & h4 {
        font-size: 24px;
        line-height: 36px;
      }
    }
  }
`;

export const Section = styled.section`
  display: flex;
  align-items: center;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  ${media.tablet} {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      'content'
      'image';
    grid-row-gap: 20px;
    padding: 20px 0;
  }
  & div.gatsby-image-wrapper {
    flex: 1;
    ${media.tablet} {
      width: 100%;
      grid-area: image;
    }
  }
  & div.content-box {
    margin: ${({ reverse }) => (reverse ? '0 80px 0 0' : '0 0 0 80px')};
    display: flex;
    flex-direction: column;
    flex: 1;
    ${media.tablet} {
      margin: 0;
      grid-area: content;
    }
    & img {
      max-width: 48px;
    }
    & h3 {
      margin-top: 20px;
      &.new {
        position: relative;
        width: fit-content;
        &::after {
          content: 'New';
          position: absolute;
          right: -12px;
          top: 50%;
          transform: translate(100%, -50%);
          width: 42px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 800;
          font-size: 12px;
          line-height: 15px;
          color: #7dffef;
          background: #000000;
          border-radius: 4px;
        }
      }
      ${media.tablet} {
        font-size: 24px;
        line-height: 36px;
      }
    }
    & p {
      margin: 40px 0 48px;
      ${media.tablet} {
        margin: 20px 0 0;
      }
    }
    & div.buttons {
      display: flex;
      ${media.tablet} {
        margin-top: 32px;
        margin-bottom: 12px;
      }
      ${media.mobile} {
        justify-content: center;
      }
      & a:last-child {
        display: flex;
        align-items: center;
        padding-left: 40px;
        font-size: 16px;
        ${media.mobile} {
          padding-left: 30px;
        }
      }
    }
  }
`;

export const StyledH2 = styled.h2`
  text-align: center;
  ${media.tablet} {
    font-size: 28px;
    line-height: 42px;
    margin-bottom: 20px;
  }
`;

export const Divider = styled.div`
  width: 100%;
  border-top: 1px solid #e4e5e8;
  margin-bottom: 114px;
  margin-top: 114px;
  ${media.desktop} {
    margin-top: -6px;
    margin-bottom: 30px;
  }
  ${media.tablet} {
    margin-top: -26px;
    margin-bottom: 100px;
  }
  ${media.mobile} {
    margin-top: 28px;
    margin-bottom: 100px;
  }
`;

export const Main = styled.main`
  padding: 120px 0;
  ${media.tablet} {
    padding: 100px 0;
  }
  & h2 {
    text-align: center;
    ${media.tablet} {
      font-size: 28px;
      line-height: normal;
      margin-bottom: 20px;
    }
  }
  & ${Section} {
    padding: 60px 0;
    ${media.tablet} {
      padding: 20px 0;
    }
  }
  & ${Section}:first-of-type {
    padding: 120px 0 60px;
    ${media.tablet} {
      padding: 40px 0;
    }
  }
  & ${Section}:last-of-type {
    padding-bottom: 0;
  }
`;
