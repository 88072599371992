import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import { SignUpForm } from 'components/styled/SignUpForm';
import { StyledLink } from 'components/ui/link';
import { StyledButton } from 'components/ui/button';

export const SubFooter = styled.div`
  background-color: #f5f5f5;
  clear: both;
  padding: 32px 0;
  border: 1px solid #e4e5e8;
  margin-top: 80px;
  & .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${media.mobile} {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  & .container p {
    margin-bottom: 0;
    color: #6a6a6a;
    font-size: 16px;
    line-height: 24px;
    ${media.desktop} {
      &:first-child {
        margin-right: 16px;
      }
    }
    ${media.mobile} {
      text-align: left;
      &:first-child {
        margin-right: 0;
      }
    }
    &:last-child {
      ${media.mobile} {
        margin-top: 32px;
      }
    }
  }
  ${media.mobile} {
    border-bottom: none;
    padding: 24px 0 0;
    margin-top: 70px;
  }
`;

export const SubHeading = styled.p`
  font-size: 18px;
  font-weight: 800;
  color: #000;
  margin-bottom: 0;
  ${media.tablet} {
    text-align: center;
  }
  ${media.mobile} {
    text-align: left;
    &.badges-header {
      text-align: center;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.adPage ? 'center' : 'space-between')};
  margin-top: 24px;
  ${media.desktop} {
    flex-direction: column;
    align-items: flex-start;
  }
  ${media.tablet} {
    align-items: center;
  }
  ${media.mobile} {
    align-items: center;
  }
`;

export const Socials = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 28%;
  & p {
    color: #000;
    margin-bottom: 0;
    ${media.desktop} {
      margin-right: 16px;
    }
    ${media.tablet} {
      margin: 0 16px 0 0;
    }
  }
  & div {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    a {
      width: 100%;
      padding: 10px;
      display: flex;
      justify-content: center;
      transition: 0.2s;
      &:hover {
        transform: scale(1.2);
      }
      & img {
        width: 24px;
        height: 24px;
      }
    }
  }
  ${media.desktopWide} {
    width: 35%;
  }
  ${media.desktop} {
    margin-top: ${(props) => (props.adPage ? 0 : '24px')};
    width: auto;
    justify-content: flex-start;
  }
  ${media.tablet} {
    margin-top: ${(props) => (props.adPage ? 0 : '75px')};
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`;

export const List = styled.ul`
  li {
    ${StyledLink} {
      color: #000;
      font-weight: 400;
    }
  }
`;

export const ExtendSignUpForm = styled(SignUpForm)`
  margin: 0;
  & label input {
    width: 276px;
    border: 2px solid #000;
    transition: 0.2s ease;
    &:hover {
      border-color: #000;
    }
  }
  ${media.tablet} {
    justify-content: center;
    ${StyledButton} {
      max-width: 276px;
    }
    & label {
      display: flex;
      justify-content: center;
      & input {
        width: 314px;
      }
    }
  }
  ${media.mobile} {
    ${StyledButton} {
      max-width: 100%;
    }
    & label input {
      width: 100%;
      margin-bottom: 16px;
      border-radius: 4px;
    }
  }
`;

export const GridBox = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.secondary ? 'repeat(3, 1fr)' : 'repeat(2, 1fr)')};
  grid-column-gap: 34px;
  .mobile-view {
    display: none;
  }
  .desktop-view {
    display: block;
    margin-top: 40px;
  }
  ${media.desktop} {
    display: flex;
    flex-direction: column;
    & .footer-heading.custom-heading {
      margin-top: 40px;
    }
    .mobile-view {
      display: block;
      margin-top: 40px;
    }
    .desktop-view {
      display: none;
    }
  }
  ${media.mobile} {
    & .footer-heading.custom-heading {
      margin-top: 0;
    }
  }
`;

export const ContainerBox = styled.div`
  margin-bottom: 80px;
  display: grid;
  grid-template-columns: 2fr 3fr 1fr;
  grid-column-gap: 34px;
  & div:nth-of-type(1) .footer-heading:nth-of-type(2),
  & div:nth-of-type(2) .footer-heading:nth-of-type(2),
  & div:nth-of-type(2) .footer-heading:nth-of-type(3),
  & div:nth-of-type(3) .footer-heading:nth-of-type(2),
  & div:nth-of-type(3) .footer-heading:nth-of-type(3),
  & div:nth-of-type(4) .footer-heading:nth-of-type(2) {
    margin-top: 40px;
  }
  .read-more-posts {
    color: #0a4ed6 !important;
    font-weight: 800 !important;
    font-size: 16px;
    margin-top: 16px !important;
  }
  ${media.desktop} {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 64px;
    grid-column-gap: 24px;
    ${GridBox}:nth-child(2) {
      & div:nth-of-type(2),
      div:nth-of-type(3) {
        margin-top: 32px;
      }
    }
    & .blog-footer {
      grid-area: 2 / 1 / 3 / 3;
      .read-more-posts {
        margin-top: 20px !important;
      }
    }
  }
  ${media.mobile} {
    ${GridBox}:nth-child(1) {
      & div:nth-of-type(2) {
        margin-top: 32px;
      }
    }
  }
`;

export const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: calc(80px - 1.5rem);
  & a {
    margin-right: 48px;
  }
  & div {
    & a:first-of-type {
      margin-right: 48px;
    }
  }
  ${media.tablet} {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas:
      'img1 img2 img3'
      'img4 img4 img4';
    align-items: center;
    justify-content: center;
    grid-column-gap: 48px;
    grid-row-gap: 24px;
    & a {
      margin-right: 0;
    }
    & img {
      justify-self: center;
    }
    & div {
      grid-area: img4;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
