import Layout from 'layout';
import Features from 'components/features';
import 'react-alice-carousel/lib/alice-carousel.css';
import 'css/carousel.css';

const FeaturesPage = () => (
  <Layout
    metaTitle="Features | Top Session Replay Software"
    canonical="/features/"
    metaDescription="Session replays, advanced filtering, custom segments, error and rage clicks detection. Try LiveSession and see what you can get from session recordings."
    blockScrollX
  >
    <Features />
  </Layout>
);

export default FeaturesPage;
