import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import arrowSlider from 'img/ui/sliderArrow.svg';
import { StyledButton } from 'components/ui/button';
import checkmark from 'img/ui/checkmarkBlue.svg';

export const ContentStyles = styled.main`
  grid-area: content;
  overflow: hidden;
  ${media.tablet} {
    margin-top: 16px;
  }
  & .slick-slider {
    max-width: 100%;
    margin-bottom: 210px;
    &.without-dots {
      margin-bottom: 60px;
    }
    ${media.desktop} {
      margin-bottom: 130px;
    }
    .slick-slide {
      img {
        max-width: 100%;
        border: 2px solid #f2f2f2;
        border-radius: 4px;
      }
    }
  }
  & .slick-dots {
    bottom: -140px;
    margin-bottom: 0;
    display: flex !important;
    ${media.desktop} {
      bottom: -75px;
    }
    li {
      width: auto;
      height: auto;
      margin-bottom: 0;
      &:not(:last-child) {
        margin-right: 28px;
        ${media.desktop} {
          margin-right: 24px;
        }
      }
      a {
        width: 84px;
        height: 84px;
        border-radius: 4px;
        border: 1px solid #000000;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        ${media.desktop} {
          width: 48px;
          height: 50px;
        }
      }
    }
  }
  & .slick-arrow {
    background: rgba(255, 255, 255, 0.7);
    border-radius: 4px;
    padding: 9px 16px;
    width: 48px;
    height: 48px;
    &:hover {
      background: rgba(255, 255, 255, 1);
    }
    ${media.desktop} {
      width: 24px;
      height: 24px;
    }
    &.slick-next {
      right: 32px;
      &::before {
        content: '';
        background-image: url(${arrowSlider});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        width: 15px;
        height: 30px;
        position: absolute;
        transform: rotate(180deg) translate(-25%, 0%);
        position: absolute;
        top: 25%;
        left: 25%;
      }
      ${media.desktop} {
        right: 16px;
        &::before {
          width: 7px;
          height: 15px;
        }
      }
    }
    &.slick-prev {
      left: 32px;
      z-index: 55;
      &::before {
        content: '';
        background-image: url(${arrowSlider});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        width: 15px;
        height: 30px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      ${media.desktop} {
        left: 16px;
        &::before {
          width: 7px;
          height: 15px;
        }
      }
    }
  }
  & div.section {
    margin-bottom: 48px;
  }
  & h2 {
    font-weight: 800;
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 24px;
  }
  & p {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 20px;
    ${media.desktop} {
      font-size: 16px;
      line-height: 28px;
    }
    a {
      &:hover {
        text-decoration: underline;
      }
    }
    strong {
      font-weight: 800;
    }
  }
  & ul {
    margin-bottom: 2rem;
    list-style: none;
    li {
      font-size: 18px;
      line-height: 28px;
      ${media.desktop} {
        font-size: 16px;
        line-height: 28px;
      }
    }
  }
  & ul.checkmarks {
    padding-left: 42px;
    li {
      margin-bottom: 24px;
      position: relative;
      &::before {
        content: '';
        background-image: url(${checkmark});
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(-42px, -50%);
        width: 18px;
        height: 14px;
      }
    }
  }
`;

export const ExtendGridBox = styled.div`
  display: grid;
  grid-template-columns: 270px 1fr;
  grid-column-gap: 28px;
  padding-bottom: 168px;
  margin-top: 144px;
  grid-row-gap: 56px;
  grid-template-areas:
    'breadcrumbs breadcrumbs'
    'card content';
  ${media.tablet} {
    grid-row-gap: 40px;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
      'breadcrumbs'
      'card'
      'content';
    padding-bottom: 120px;
  }
  ${media.mobile} {
    margin-top: 110px;
  }
  .breadcrumbs {
    grid-area: breadcrumbs;
    display: flex;
    align-items: center;
    & img {
      margin: 0 16px;
      ${media.mobile} {
        max-width: 8px;
      }
    }
    & p {
      margin: 0;
      ${media.mobile} {
        line-height: normal;
      }
      & a {
        color: #000;
        font-size: 14px;
        line-height: 24px;
        ${media.mobile} {
          font-size: 12px;
          line-height: 24px;
        }
      }
    }
  }
`;

export const MoreApps = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 0;
  & .alice-carousel {
    display: none;
    margin-bottom: 30px;
    & .alice-carousel__stage {
      & .alice-carousel__stage-item {
        transform: translateX(15px);
        & a {
          width: calc(100% - 30px);
        }
      }
    }

    & .alice-carousel__dots {
      margin-top: 37px;
    }
    ${media.mobile} {
      display: block;
    }
  }
  & h2 {
    font-size: 36px;
    line-height: 45px;
    font-weight: 800;
    margin-bottom: 64px;
    text-align: center;
    ${media.tablet} {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 36px;
    }
  }
  & .card-box {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 40px;
    ${media.desktopWide} {
      grid-column-gap: 28px;
    }
    ${media.desktop} {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 32px;
    }
    ${media.mobile} {
      display: none;
    }
  }
`;

export const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: card;
  height: 100%;
  & .sticky-box {
    position: sticky;
    top: 120px;
  }
  & p.madeby {
    margin: 24px 0;
    font-size: 14px;
    line-height: 18px;
    ${media.mobile} {
      display: none;
    }
  }
  & p.title {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
    & span {
      display: none;
      ${media.mobile} {
        display: inline;
        font-size: 14px;
        line-height: 18px;
        color: #808080;
      }
    }
    ${media.mobile} {
      display: flex;
      justify-content: space-between;
      margin: 24px 0 16px;
    }
  }
`;

export const Categories = styled.div`
  display: flex;
  flex-wrap: wrap;
  & .category {
    border-radius: 4px;
    border: 1px solid #000000;
    padding: 3px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin-right: 8px;
    margin-bottom: 8px;
    display: block;
    text-decoration: none;
    color: #000;
    font-size: 12px;
    line-height: 14px;
    &:hover {
      background-color: #000;
      cursor: pointer;
      & span {
        color: #fff;
      }
    }
    & span {
      margin: 0;
      font-weight: 700;
      white-space: nowrap;
    }
  }
`;

export const FixedButton = styled.div`
  background: rgba(248, 248, 248, 0.9);
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 19px 15px 16px;
  z-index: 999;
  transform: translateY(100%);
  transition: transform 0.2s ease;
  display: none;
  ${media.mobile} {
    display: block;
  }
  &.active {
    transform: translateY(0);
  }
  & ${StyledButton} {
    max-width: 100%;
    width: 100%;
  }
`;
