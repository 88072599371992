import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import { StyledLink } from 'components/ui/link';

export const StyledSection = styled.section`
  padding: 234px 0 36px;
  @media (max-width: 1440px) {
    padding: 168px 0 36px;
  }
  ${media.mobile} {
    padding: 120px 0 36px;
  }
`;

export const StyledHeader = styled.div`
  margin-bottom: 92px;
  @media (max-width: 1440px) {
    margin-bottom: 64px;
  }
  ${media.tablet} {
    margin-bottom: 48px;
  }
`;

export const StyledBlogTitle = styled.h1`
  margin: 0 0 8px;

  ${media.tablet} {
    font-size: 32px;
    line-height: 44px;
    margin: 0 0 6px;
  }
`;

export const StyledBlogDesc = styled.p`
  font-size: 22px;
  line-height: 28px;
  font-weight: 400;

  ${media.tablet} {
    font-size: 18px;
    line-height: 23px;
  }
`;

export const GuestPostBox = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 40px;
  padding: 0;
  ${media.tablet} {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-column-gap: 0;
    grid-row-gap: 32px;
    align-items: flex-end;
  }
  ${media.mobile} {
    padding: 0 15px;
  }
  & .box {
    display: block;
    padding: 40px 48px;
    background-color: #f8f8f8;
    border-radius: 6px;
    cursor: pointer;
    height: fit-content;
    color: #000;
    height: 100%;
    &:hover {
      text-decoration: none;
      box-shadow: none;
      ${StyledLink} {
        text-decoration: underline;
        &::after {
          transform: translateX(8px);
        }
      }
    }
    ${media.tablet} {
      height: auto;
    }
    ${media.mobile} {
      padding: 24px 30px;
    }
    & h3 {
      font-size: 26px;
      line-height: 50px;
      font-weight: 900;
      margin-bottom: 24px;
      ${media.mobile} {
        margin-bottom: 16px;
        font-size: 22px;
        line-height: 32px;
      }
    }
    & p {
      margin: 0;
    }
    & p:first-of-type {
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 32px;
      ${media.mobile} {
        margin-bottom: 24px;
        font-size: 14px;
        line-height: 24px;
      }
    }
    ${StyledLink} {
      font-weight: 700;
      font-size: 16px;
    }
  }
`;
