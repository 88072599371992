import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const Section = styled.section`
  padding-top: 168px;
  padding-bottom: 0px;

  ${media.mobile} {
    padding: 120px 0 0 0;
    margin: 0 auto;
  }
`;
export const Paragraph = styled.div`
  width: 570px;
  font-size: 22px;
  margin: 16px 0;
  max-width: 790px;
  ${media.desktop} {
    width: auto;
  }
`;
export const ImageWrapper = styled.div`
  justify-self: center;
  width: 632px;
  position: relative;
  left: 56px;
  ${media.desktop} {
    display: none;
  }
`;

export const GridBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  margin-bottom: 54px;
  ${media.desktop} {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    align-items: center;
    text-align: center;
  }
  ${media.mobile} {
    text-align: center;
    margin-bottom: 100px;
  }
  & .gridbox-container {
    display: flex;
    padding-top: 64px;
    flex-direction: column;
    padding-bottom: 126px;

    & form {
      justify-content: flex-start;
      width: 100%;
      ${media.desktop} {
        justify-content: center;
      }
    }
    ${media.desktopWide} {
      grid-column-gap: 0;
    }
    ${media.desktop} {
      padding-top: 0;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, min-content);
      grid-row-gap: 0px;
      padding-bottom: 90px;
      & form {
        margin-top: 0;
      }
    }
    ${media.tablet} {
      & form label {
        width: auto;
      }
    }
    ${media.mobile} {
      grid-row-gap: 0px;
      width: 100%;
      padding: 0 15px 20px 15px;
      & .checkmarks {
        justify-content: center;
      }
      & form {
        justify-content: center;
        & label {
          width: 100%;
        }
      }
    }
  }
`;
