import styled from 'styled-components';
import { Link } from 'gatsby';
import { media } from 'theme/breakpoints';
import leftwave from 'img/index/leftwave.svg';

export const FormContainer = styled.div`
  max-width: 434px;
  width: 100%;
  height: 100%;
  margin: auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  position: relative;
  z-index: 10;
  justify-content: center;
`;

export const SignupSection = styled.section`
  width: 100%;
  min-height: 100vh;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Roboto', 'Muli', sans-serif;
  .leftwave {
    @media screen and (max-width: 1100px) {
      display: none;
    }
  }
  .wrapperLogin {
    margin: 0 auto;
    width: 63%;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 1100px) {
      width: 100%;
    }
    .formContainer {
      input,
      button {
        border: 2px solid black;
      }
    }
  }
  .rightWrapper {
    min-height: 100vh;
    position: relative;
    font-family: 'Muli', sans-serif;
    justify-content: center;
    padding: 96px 72px 40px;
    width: 37%;
    background-color: #f5f5f5;
    display: flex;
    ::before {
      content: '';
      position: absolute;
      width: 6px;
      top: 0;
      bottom: 0;
      left: -5px;
      right: 0;
      mask-image: url(${leftwave});
      background-size: auto 30px;
      background-position: top left;
      background-repeat: repeat-y;
      background-color: #f5f5f5;
    }
    @media screen and (max-width: 1450px) {
      padding: 96px 32px 40px;
    }
    @media screen and (max-width: 1100px) {
      display: none;
    }

    h2 {
      padding-bottom: 42px;
      font-size: 36px;
      width: 100%;
      font-weight: 900;
      line-height: 156%;
      @media screen and (max-width: 1540px) {
        font-size: 32px;
      }
      @media screen and (max-width: 1450px) {
        font-size: 24px;
      }
    }
    img {
      width: 118px;
    }
    flex-direction: column;
    .imgWrapper {
      flex-direction: row;
      display: flex;
      margin-top: 16px;
      padding-bottom: 96px;
      align-items: center;
      border-bottom: 1px solid #d7d7d7;
      img {
        width: 60px;
        height: 60px;
      }
      .textWrapper {
        p {
          margin: 0 0 0 16px;
        }
        .name {
          font-weight: 900;
        }
      }
    }
    .trustedBy {
      padding-top: 32px;
      margin-bottom: 24px;
      color: #6a6a6a;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
    }
    .containerLogo {
      margin: 0 auto;
      .logoWrapper {
        max-width: 510px;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        img {
          width: 128px;
        }
      }
    }
  }
`;

export const Logo = styled(Link)`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 48px;
  top: 48px;
  line-height: normal;
  ${media.desktop} {
    display: none;
  }
  & img {
    max-width: 125px;
    height: auto;
  }
`;

export const PromoCode = styled.h3`
  font-weight: 500;
  font-size: 16px;
  max-width: 420px;
  line-height: 29px;
  border: 2px solid #4caf50;
  margin: 0 auto 42px auto;
  border-radius: 4px;
  padding: 16px;
  background-color: honeydew;
`;

export const UsingLS = styled.div`
  position: absolute;
  top: 48px;
  right: calc(37% + 42px);
  font-family: 'Roboto', 'Muli', sans-serif;
  @media screen and (max-width: 1100px) {
    right: 42px;
  }
  ${media.desktop} {
    display: none;
  }
  & p {
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin: 0;
    ${media.desktop} {
      font-size: 14px;
      line-height: normal;
    }
  }
`;
