import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const Section = styled.section`
  display: flex;
  width: 100%;
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
  padding: 0;
  ${media.desktop} {
    width: 100%;
    flex-direction: column-reverse;
    & div.content-box {
      padding-bottom: 32px;
    }
  }

  .transform {
    transform: ${(props) => (props.reverse ? 'translate(80px)' : 'translate(-80px)')};
    ${media.desktop} {
      transform: translate(0);
    }
  }
  & div.gatsby-image-wrapper {
    flex: 1;
    ${media.desktop} {
      width: 100%;
      grid-area: image;
    }
  }
  & div.content-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 520px;
    /* margin: ${(props) => (props.reverse ? '0 64px 0 0' : '0 0 0 64px')}; */

    ${media.desktop} {
      width: 100%;
      margin: 0 auto;
      text-align: center;
      grid-area: content;
      justify-content: start;
    }
    .firstParagraph {
      padding: 24px 0;
    }
    p {
      margin-bottom: 0;
    }
  }
`;
