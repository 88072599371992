import withUsabilityTestingTemplate from 'hoc/content-cluster';
import { StaticImage } from 'gatsby-plugin-image';
import Link from 'components/ui/link';
import arrow from 'img/ui/arrow_white.svg';

const UXDesignProcess = () => (
  <>
    <h2>What is UX Design and Why is it Important?</h2>
    <h3>User experience </h3>
    <p>
      To get a full understanding of what UX design process is, it’s crucial to start with a
      definition of user experience:
    </p>
    <p>
      To get a full understanding of what the UX design process is, it’s crucial to start with a
      definition of user experience. It refers to the feelings and emotions that interacting with a
      product or service evokes in the user. The term was first introduced by Donald Norman,{' '}
      <Link
        href="https://theblog.adobe.com/where-did-the-term-user-experience-come-from/"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        who said
      </Link>
      :
    </p>
    <p>
      <em>
        “I invented the term because I thought human interface and usability were too narrow. I
        wanted to cover all aspects of the person’s experience with the system including industrial
        design, graphics, the interface, the physical interaction, and the manual.”
      </em>
    </p>
    <h3>UX design</h3>

    <p>
      Going back to UX design, it is the process of designing physical and digital products with the
      target user in mind. This term is about the design project of solving their problems by
      creating meaningful user experiences.
    </p>
    <p>
      Now that we have the definitions sorted, you might wonder why the UX design process is
      important. Let’s get right to it.
    </p>
    <h3>Why UX design matters</h3>
    <p>Having a UX design process in place brings significant benefits, including:</p>
    <ul>
      <li>
        <strong>Improved user retention </strong>– it’s common knowledge that happy customers tend
        to be more loyal. Building products with your typical user in mind will help you create a
        more rewarding customer experience, with fewer bugs and roadblocks. This in turn will
        improve your customer satisfaction resulting in a higher retention rate. And let’s not
        forget that a{' '}
        <Link
          href="https://media.bain.com/Images/BB_Prescription_cutting_costs.pdf"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          5% increase in user retention
        </Link>
        translates into 25% profit growth.{' '}
      </li>
      <li>
        <strong>Better brand image </strong>– in an age of product abundance and similarity,
        building a good brand image can be what makes you stand out. Good UX design is invisible
        because it makes interacting with your product a pleasurable experience, meaning no errors
        and no confusion. Users who are pleased with your product will talk about it, recommend it
        to your friends, or share their excitement by writing a positive review. Now, guess what
        happens when users are dissatisfied with your product – they also talk about it, just more
        frequently.{' '}
      </li>
      <li>
        <strong>Higher conversion rate </strong>– having a well-designed website or an app will help
        users or visitors easily navigate around it and quickly find the information they look for,
        which should have a positive impact on your conversion rate. Having a UX design process in
        place while building your website will help you decide which info to put where - according
        to the NN Group, there is an{' '}
        <Link
          href="https://www.nngroup.com/articles/page-fold-manifesto/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          84% difference
        </Link>{' '}
        in how users interact with content above vs below the fold{' '}
      </li>
    </ul>
    <h2>The Elements of the User Experience Process</h2>

    <p>
      Hopefully, by now, we all agree that having an effective UX design process is necessary to
      build products that customers enjoy interacting with. The primary purpose of a UX design
      process is creating a great{' '}
      <Link href="/user-experience/" target="_blank" rel="noopener noreferrer">
        user experience
      </Link>
      . In order to do that, it’s worth identifying the key UX design tools.
    </p>

    <h3>Research</h3>

    <p>
      Research is the backbone of every effective UX design process. It’s necessary to understand
      user goals, behavior, mindset, and preferences.
    </p>
    <h3>Visual design</h3>
    <p>
      Shows the look and feel of the front end of any user interface. A design team uses visual
      elements such as colors, images, and symbols to produce a realistic representation of the
      product for your audience.
    </p>
    <h3>Information architecture</h3>
    <p>
      Another important element of the user experience, about organizing and labeling information to
      make it easily discoverable and helpful to the users. According to{' '}
      <Link
        href="https://uxdesign.cc/rediscovering-information-architecture-8f27e5ac6257"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Abby Covert
      </Link>
      , the author of How to Make Sense of Any Mess: Information Architecture for Everybody,
      “Information architecture is the way that we arrange the parts of something to make it
      understandable”.
    </p>
    <StaticImage
      src="../../img/user-experience/ux-design-process/triangle.jpg"
      title="User research - Expert design practice - usability"
      alt="triangle"
    />
    <p>
      Source:{' '}
      <Link
        href="https://www.netsolutions.com/insights/5-vital-elements-of-a-good-user-experience-design/"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        NetSolutions{' '}
      </Link>
    </p>
    <h3>Usability </h3>
    <p>
      Usability refers to the degree a product can be used by a specific target group to realize
      their goals effectively and efficiently with a desired level of user satisfaction. A properly
      designed app or a website enables users to easily interact with it from the word go - an
      aspect that can be verified by usability testing.
    </p>
    <h3>Accessibility</h3>

    <p>
      This design concept revolves around ease of use, reach, and understandability. It’s often
      viewed in the context of disabilities, i.e. how easy the product is to use by people with some
      kind of handicap.
    </p>
    <h3>Human-computer interaction</h3>

    <p>
      As explained by{' '}
      <Link
        href="https://en.wikipedia.org/wiki/User_experience_design#Elements"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Wikipedia
      </Link>
      , “Human-computer interaction is concerned with the design, evaluation, and implementation of
      interactive computing systems for human use and with the study of major phenomena surrounding
      them”.
    </p>
    <h2>6 Steps to Creating an Effective User Experience Design Process</h2>

    <p>
      There are a number of steps that every effective UX design process should incorporate,
      including:{' '}
    </p>
    <h3>Step 1: Understand the user and the brand</h3>

    <p>
      Trying to build a product without understanding your potential users first is like walking in
      the dark without a flashlight - pretty clueless. Designing the user experience is all about
      solving user problems, and you need to know what their pain points are in order to do that.
      You should find answers to the following three questions:
    </p>
    <ol>
      <li>What are the issues you are aiming to resolve?</li>
      <li>What problems do your users have?</li>
      <li>What makes you think you can resolve those problems?</li>
    </ol>

    <p>
      Only after you gain a deeper understanding of your target audience’s struggles can you move on
      to the design phase.
    </p>
    <h3>Step 2: Research</h3>
    <p>
      This is the foundation of every successful UX design process – it helps you verify your
      hypotheses and saves you from making expensive mistakes in your design principles. You can
      select from a variety of research methods including:
    </p>
    <ul>
      <li>
        <strong>1:1 interviews </strong>– talk to someone from your target audience and ask them
        direct questions about the problems they face.
      </li>
      <li>
        <strong>Focus groups </strong>– discussions between 3-5 people from your target audience who
        talk about their emotions, impressions, and attitudes towards your product.{' '}
      </li>
      <li>
        <strong>User surveys </strong>– let you quickly gather a lot of data. You can conduct user
        interviews, ask specific survey questions about what they seek in a product, or gather
        feedback on a particular feature or functionality.{' '}
      </li>
    </ul>
    <h3>Step 3: Analyze your findings</h3>
    <p>
      Gather and analyze the findings you collected during the previous stages for coming up with a
      buyer persona and customer journey map:{' '}
    </p>
    <ul>
      <li>
        <p>
          <strong>User personas </strong>– these are profiles of your ideal customers, including
          age, gender, profession, goals, behaviors, and spending habits, etc. All of your product
          design decisions should revolve around your buyer personas.{' '}
        </p>
        <StaticImage
          src="../../img/user-experience/ux-design-process/portfolio.jpg"
          title="User personas"
          alt="Buyer persona template"
        />
        <p>
          Source:{' '}
          <Link
            href="https://www.oberlo.com/ecommerce-wiki/buyer-persona"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            Oberlo
          </Link>{' '}
        </p>
      </li>
      <li>
        <p>
          <strong>User journey maps </strong>– demonstrate step by step how users will interact with
          your product.{' '}
        </p>
        <StaticImage
          src="../../img/user-experience/ux-design-process/customerjourneymap.jpg"
          title="Customer Journey Map"
          alt="User journey mapping"
        />
        <p>
          Source:{' '}
          <Link
            href="https://www.nngroup.com/articles/analyze-customer-journey-map/"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            NNGroup
          </Link>{' '}
        </p>
      </li>
    </ul>
    <h3>Step 4: Building your design</h3>
    <p>
      This stage will include creating mockups, site maps, user flows, images, and colors, etc. It’s
      the right time to start building wireframes, which are a basic visual representation of what
      your product will do. Bear in mind that this is a continuous process, not a one-off – you’ll
      have to constantly modify your design based on user feedback.
    </p>
    <h3>Step 5: Launch</h3>
    <p>
      When you feel like your design is as good as it can be (at least at this stage), it’s launch
      time – you’ll pass on all the materials to the development team to build a high-fidelity
      version of your product. After it’s built, you can carry out beta testing, internal testing,
      and user testing to ensure your product’s market-readiness:
    </p>
    <ul>
      <li>
        <strong>Conduct some user testing </strong>– observe how your target customers interact with
        your product to spot any roadblocks.{' '}
      </li>
      <li>
        <strong>Beta launch </strong>– release the product to a limited number of users so they can
        take it for a test drive.{' '}
      </li>
      <li>
        <strong>Internal testing </strong>– ask your own product team to “play” with your product
        and to give you feedback.
      </li>
    </ul>
    <h3>Step 6: Analyze and re-analyze</h3>
    <p>
      After the product launch, you’ll need to take a close look at the results. However, instead of
      looking at research, you’ll be able to analyze the final product. It’s worth thinking about:
    </p>
    <ul>
      <li>your struggle areas</li>
      <li>what you did right</li>
      <li>if your product managed to solve your target audience’s problems</li>
      <li>what features or functionalities require improvement, etc.</li>
    </ul>

    <p>
      Getting answers to the above questions will not only allow you to enhance your UX design
      process, but it will also give you insights on how to improve your product to better meet user
      expectations.{' '}
    </p>
    <StaticImage
      src="../../img/user-experience/ux-design-process/writing.png"
      title="Analyze"
      alt="Writing on board"
    />
    <p>
      Source:{' '}
      <Link
        href="https://unsplash.com/photos/3V8xo5Gbusk"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Unsplash
      </Link>
    </p>
    <h2>Summary</h2>
    <p>
      You can’t build a product that your target audience will love without an effective UX design
      process. Having one in place will not only help you achieve product-market fit quicker and
      boost your conversion, but it will also have a positive impact on your brand image and help
      you achieve your business goals faster.{' '}
    </p>
    <p>
      The main purpose of a UX design process is to create an amazing user experience. For this
      reason, it’s worth remembering what the UX elements are: research, visual design, information
      architecture, usability, accessibility, and human-computer interaction. Before you start
      building a high-fidelity prototype product, remember to conduct thorough user research,
      analyze your findings with user journey maps, and never stop the iterative process of
      improving your product even after the initial launch. Good luck.{' '}
    </p>
    <div className="next-chapter">
      <Link href="/user-experience/user-experience-design/">
        <div>
          <p>Chapter 3</p>
          <p>User Experience Design – How to Improve & Best Practices</p>
        </div>
        <img src={arrow} alt="arrow" title="white arrow" />
      </Link>
    </div>
  </>
);

export default withUsabilityTestingTemplate(UXDesignProcess);
