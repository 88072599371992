import Link from 'components/ui/link';
import withUserExperienceTemplate from 'hoc/content-cluster';
import arrow from 'img/ui/arrow_white.svg';
import { StaticImage } from 'gatsby-plugin-image';
import { useSourceParams } from 'hooks/useSourceParams';
import Banner from 'components/usability-testing/banner';

const UserExperience = () => {
  const link1 = useSourceParams('userresearch');
  return (
    <>
      <h2>What is User Experience? </h2>
      <p>
        Do you ever wonder how using certain products makes you feel? Do you get frustrated or feel
        delighted? The feelings and emotions that interacting with products evoke in us can be
        referred to as the user experience. This term was pioneered by{' '}
        <Link
          href="https://blog.adobe.com/en/2017/08/28/where-did-the-term-user-experience-come-from.html#gs.ggyd17"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Donald Norman
        </Link>{' '}
        who said that “I invented the term because I thought human interface and usability were too
        narrow. I wanted to cover all aspects of the person’s experience with the system including
        industrial design, graphics, the interface, the physical interaction, and the manual.”
      </p>
      <StaticImage
        src="../img/user-experience/introduction/unsplash1.png"
        alt="Table with cards"
        title="User experience - cards"
      />
      <p>
        Source:{' '}
        <Link
          href="https://unsplash.com/photos/zoCDWPuiRuA"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Unsplash
        </Link>{' '}
      </p>
      <p>
        User experience means different things to different people and there are various UX
        definitions, some of which are very distinct from the standard one. If you’re curious about
        how user experience is defined by design professionals, take a look at our article in which{' '}
        <Link href="/user-experience/what-is-ux/" target="_blank" rel="noopener noreferrer">
          10 business experts explain what UX is to them
        </Link>
        .{' '}
      </p>
      <p>
        Since you have an understanding of what UX means, it’s time to discuss UX experience and the
        elements of an effective UX design process.{' '}
      </p>
      <h2>UX Design Process: What to Include and How to Do it Right</h2>
      <p>
        Let’s start off with the question, “what is UX design?” There isn’t a universally accepted
        definition. It’s a multidimensional concept which incorporates a variety of disciplines like
        interaction design, information architecture, visual design, usability, and human-computer
        interaction.{' '}
      </p>
      <p>
        However, to get a better grasp of what UX design indicates, let’s take a look at how the{' '}
        <Link
          href="https://www.lsi-media.com/what-is-ux-design/#:~:text=According%20to%20this%20study%20from,the%20interaction%20with%20a%20product.%E2%80%9D"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Oxford Journal on Interacting With Computers
        </Link>{' '}
        explains it:{' '}
      </p>
      <p>
        The primary purpose of UX design is to “
        <i>
          improve customer satisfaction and loyalty through the utility, ease of use, and pleasure
          provided in the interaction with a product.
        </i>
        ” To put it simply, it’s about building products that not only provide value to customers
        but are also easy and pleasant to use.{' '}
      </p>
      <StaticImage
        src="../img/user-experience/introduction/uxdesign.png"
        alt="UX Design"
        title="Design process"
      />
      <p>
        Source:{' '}
        <Link
          href="https://www.newbreedmarketing.com/blog/ask-an-expert-what-is-user-experience-ux-design"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          New Breed Marketing
        </Link>{' '}
      </p>
      <p>
        Now, what does it take to create great user experiences? The key lies in the{' '}
        <Link href="/user-experience/ux-design-process/" target="_blank" rel="noopener noreferrer">
          UX design process
        </Link>
        , i.e. a set of rules and processes that designers follow at various stages of developing a
        product. This process is instrumental in creating solutions that truly resonate with its
        users.{' '}
      </p>
      <p>
        First, UX specialists learn about who their target user is and what needs and expectations
        they have of the product. Next, the specialists proceed to validate their hypotheses by
        creating visuals and conducting usability testing.{' '}
      </p>
      <p>
        Let us take a closer look at the elements of the UX design process as well as the benefits
        that following it entails.
      </p>
      <h3>Elements of the User Experience Process</h3>
      <h4>User Research</h4>
      <p>
        This is the starting point of any user-centered design. It’s also a continuous process that
        helps designers enhance the usability of an existing product.{' '}
      </p>
      <p>
        In order to create a user-centered experience, designers require a deep understanding of
        their target audience’s goals, fears, and challenges. This can be achieved, for example,
        through conducting user interviews,{' '}
        <Link href={link1.link} target="_blank" rel="noopener noreferrer">
          session recordings
        </Link>
        , or surveys.{' '}
      </p>
      <p>
        With these insights at hand, UX specialists can proceed with building user personas and user
        journey mapping (see below), as well as creating wireframes and prototypes
      </p>
      <p>We will discuss user research in more detail further down in this post.</p>
      <h4>Information Architecture (IA)</h4>
      <p>
        IA is another crucial element of the user experience. Its role is to organize information in
        a way that makes it helpful and intuitive for users to navigate. One of the ways that IA can
        be verified is by testing the intended design with users, which brings us to...
      </p>
      <h4>Visual Design</h4>
      <p>
        A term used to describe the look and feel of a product. Visual design includes elements such
        as an app’s or site’s layout, colors, or the symbols that help users interact with the
        interface. Visual design concepts are tested by UX designers through wireframes and
        prototypes, which we discuss in detail below.
      </p>
      <StaticImage
        src="../img/user-experience/introduction/unsplash2.png"
        alt="Design, concepts, wireframes"
        title="Visual design"
      />
      <p>
        Source:{' '}
        <Link
          href="https://unsplash.com/photos/tZc3vjPCk-Q"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Unsplash
        </Link>
      </p>
      <h4>Usability</h4>
      <p>
        The term ‘usability’ describes the degree to which a product can be used by its target
        audience so that they can achieve their goals. There are{' '}
        <Link
          href="https://www.nngroup.com/articles/usability-101-introduction-to-usability/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          five constituents
        </Link>{' '}
        to usability – a product’s learnability, efficiency, memorability, its errors, and the
        levels of user satisfaction.
      </p>
      <h4>Accessibility</h4>
      <p>
        Lastly, this is the rate at which a product can be easily used, understood, and reached by a
        disabled individual. This might mean, for instance, designing a voice interface for the
        visually impaired or enabling a bigger font or element size for the elderly.
      </p>
      <h3>The Benefits of a UX Design Process</h3>
      <p>
        So, what benefits does following the user experience design process provide? Below, we
        mention some of the most prominent ones:
      </p>
      <ul>
        <li>
          <strong>User retention improvement </strong>- when a product offers a rewarding
          experience, users will be more likely to return to it.
        </li>
        <li>
          <strong>Higher conversion rate </strong>- if you ensure that your site visitors can easily
          find the information they are looking for, you increase your chances of improving
          conversion.
        </li>
        <li>
          <strong>A stronger brand </strong>- great user experiences are also memorable ones. This
          means that, by ensuring a flawless UX, you can strengthen your brand image and boost the
          likelihood of getting a positive review online.
        </li>
      </ul>
      <p>
        You can read more about the UX design process and what it includes in a dedicated chapter of
        this guide.
      </p>
      <h2>How to Conduct Effective UX Research?</h2>
      <p>
        Research is essential for creating an effective UX design, which is why it’s important to
        shed more light on the subject.
      </p>
      <p>
        <Link href="/user-experience/ux-research/" target="_blank" rel="noopener noreferrer">
          User experience research
        </Link>{' '}
        provides information required at all steps of the design process. It allows designers to
        validate their product plans and hypotheses, as well as compare the intended service against
        others that are present on the market.
      </p>
      <p>There are two main types of user research – quantitative and qualitative.</p>
      <h3>Quantitative vs Qualitative Research</h3>
      <p>
        Quantitative research, as the name indicates, is the collection of data that can be
        numerically measured and analyzed. This can be, for instance, the number of website visitors
        who bounced from your site or results from a user survey (measured on a 1-10 scale).
      </p>
      <StaticImage
        src="../img/user-experience/introduction/unsplash3.png"
        alt="Laptop"
        title="Research"
      />
      <p>
        Source:{' '}
        <Link
          href="https://unsplash.com/photos/mcSDtbWXUZU"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Unsplash
        </Link>
      </p>
      <p>
        {' '}
        While quantitative data will tell you ‘what’, qualitative data lets you in on the ‘why’ –
        i.e. the motivation for user actions. Examples of such insights include descriptive answers
        to open-ended survey questions or findings from 1:1 user interviews.
      </p>
      <p>How to know which method to employ, and at which stage in the research process?</p>
      <h3>How to Choose the Right User Research Method?</h3>
      <p>
        As explained by the{' '}
        <Link
          href="https://www.nngroup.com/articles/ux-research-cheat-sheet/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Nielsen Norman Group
        </Link>
        , there are four stages in the UX research process – Discover, Explore, Learn, and Listen.
        Below, we mention the methods that can be used at each step.
      </p>
      <h4>Stage 1 – Discover</h4>
      <p>During the initial stages, you will benefit from the following:</p>
      <ul>
        <li>field studies</li>
        <li>competitive analysis</li>
        <li>stakeholder interviews</li>
        <li>user interviews</li>
      </ul>
      <p>
        These methods will help you understand what other products on the market you’re competing
        with, how you can meet your users’ expectations and resolve their problems, as well as ways
        to help them achieve their goals.
      </p>
      <h4>Stage 2 – Explore</h4>
      <p>In the ‘Explore’ phase, we recommend turning to:</p>
      <ul>
        <li>card sorting (i.e. asking users to organize topics or elements into categories)</li>
        <li>task assignments (i.e. asking testers to complete an objective during the study)</li>
        <li>
          user persona development (i.e. creating a fictional, yet realistic customer profile)
        </li>
      </ul>
      <p>
        These methods will help you uncover potential user journey paths, new features and
        enhancements, plus others.
      </p>
      <h4>Stage 3 – Learn</h4>
      <p>
        In the ‘Learn’ stage, you gain a deeper understanding of your users’ interactions with your
        product. Therefore, you will benefit from:
      </p>
      <ul>
        <li>
          usability testing (among others, checking whether your designs have a gentle learning
          curve that is efficient and memorable for users)
        </li>
        <li>
          accessibility tests (checking if all user groups, including the disabled, can use your
          product fully)
        </li>
        <li>benchmark testing (learning where your product stands against others on the market)</li>
      </ul>
      <StaticImage
        src="../img/user-experience/introduction/unsplash4.png"
        alt="Thinking man"
        title="Learn"
      />
      <h4>Stage 4 – Listen</h4>
      <p>
        Last, but not least, researchers listen to their audience to learn how they can improve the
        UX design. The recommended methods include:
      </p>
      <ul>
        <li>determining Frequently Asked Questions</li>
        <li>running customer surveys</li>
        <li>
          analyzing customer data from analytics tools (for example, the queries they search Google
          for)
        </li>
      </ul>
      <p>
        {' '}
        We describe each of the aforementioned methods in detail in a dedicated chapter of this
        guide.
      </p>
      <p>
        Let’s now see how you can use the gathered customer insights to fuel your user journey and
        to create your user journey map.
      </p>
      <h2>How to Develop a User Journey Map in 6 Simple Steps</h2>
      <p>Before we proceed to the tactics, let’s first look at the definition.</p>
      <h3>What is a User Journey and a User Journey Map?</h3>
      <p>
        In the simplest possible terms, a user journey is the entire experience a user has while
        interacting with an interface or product. It shows you all the steps they’ve taken in order
        to achieve a goal – for instance, finalizing a purchase.
      </p>
      <p>
        That being said, user journey mapping is the process of documenting this entire interaction.
        It helps designers visualize the entire UX experience and lets them spot any improvement
        areas, unnecessary steps, or other important elements of a given user task.
      </p>
      <h3>6 Steps to Creating a User Journey Map</h3>
      <p>There are six steps to mapping out user experiences. They are as follows:</p>
      <h4>Step 1 – Creating User Personas</h4>
      <p>
        User personas are fictional, representative reflections of your actual customers. They are a
        way of giving your user research data a personality so they are more relatable when you work
        on your designs.
      </p>
      <h4>Step 2 – Determining Your Users’ Goals</h4>
      <p>
        With personas, you can understand what your target audience’s goals, fears, and challenges
        are. Create a list of reasons why customers turn to your product or service and what they
        aim to achieve by using it.
      </p>
      <h4>Step 3 – Map Out the Touchpoints</h4>
      <p>
        Determine all the points of contact or scenarios your user engages in when they interact
        with your product. Create a list of all potential ways they can come across your brand –
        both online and offline. An example of this is, for instance, a virtual check-in for a
        flight (which takes place well ahead of the passenger entering the plane and using the
        actual service).
      </p>
      <StaticImage
        src="../img/user-experience/introduction/customerjourneymap.png"
        alt="Customer Journey Map"
        title="Map Out the Touchpoints"
      />
      <p>
        Source:{' '}
        <Link
          href="https://www.nngroup.com/articles/analyze-customer-journey-map/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Nielsen Norman Group
        </Link>
      </p>
      <h4>Step 4 – Find Out What Customers’ Frustrations and Pains Are</h4>
      <p>Ask yourself the following:</p>
      <ul>
        <li>Is it possible for my customers to achieve their goals by using my product?</li>
        <li>Were there any roadblocks that caused frustration?</li>
        <li>Is there any step during which users stopped the customer journey?</li>
      </ul>
      <p>These and other questions will provide you with a list of user journey improvements.</p>
      <h4>Step 5 – Eliminate the Obstacles</h4>
      <p>
        With a list of roadblocks, it’s time to categorize. Decide which problems call for a
        ‘nice-to-have’ improvement and which entirely block the user from continuing their journeys,
        then prioritize the latter.
      </p>
      <h4>Step 6 – Enhance Your Product</h4>
      <p>Apply the improvements derived from your user journey map to enhance UX.</p>
      <p>
        As user goals and priorities change over time, we advise you to update or verify the
        validity of your user journey maps every 6 months. User journey maps give you a ton of
        insights that can be used for creating the intended product’s first visuals – i.e.
        wireframes and higher-fidelity prototypes.
      </p>
      <h2>All You Need to Know About Effective UX Prototyping</h2>
      <p>
        The best way to check if your design is market-ready is{' '}
        <Link href="/user-experience/ux-prototyping/" target="_blank" rel="noopener noreferrer">
          through UX prototyping
        </Link>
        , which is creating a basic version of your design with the potential of turning it into a
        fully functional product. It enables initial product testing and feedback collection.
      </p>
      <h3>Why Should You Consider Creating a UX Prototype?</h3>
      <p>
        You might be wondering – why should I bother building a prototype? It has lots of benefits,
        we’ll get to them right now:
      </p>
      <ul>
        <li>
          <strong>Checking product-market fit </strong>– it’s difficult to assess whether or not a
          product is ready for market launch without having a physical product at hand. Having even
          the most simple prototype allows you to share it with your target audience and get their
          feedback. Use that to improve your product or push it into development right away.
        </li>
        <li>
          <strong>Boosting user experience </strong>– you can easily spot issues that require
          further work as they block your users from meeting their goals. Fixing them prior to
          launch will improve the overall UX.
        </li>
        <li>
          <strong>Faster development time and lower costs </strong>– use a prototype to discover
          which features are missing and which are unnecessary. This will not only save you a lot of
          money, but it will also cut your time to development.{' '}
        </li>
      </ul>
      <p>
        There is more than one prototyping technique that you can use, so let’s take a quick look at
        the various options now.{' '}
      </p>
      <h3>Prototyping Methodologies You Can Use</h3>
      <ol>
        <li>
          <p>
            <strong>Paper prototyping </strong>– its low cost and flexibility make it a popular
            option among designers. It can be tested with any type of interface including a website,
            mobile app, or software. It’s frequently confused with sketching, but it goes beyond
            paper drawing. Paper prototypes can be made of cardboard or cut out of paper, which
            makes interacting with them easier.
          </p>
          <StaticImage
            src="../img/user-experience/introduction/paper.png"
            alt="Paper prototype phone"
            title="Paper prototyping"
          />
          <p>
            Source:{' '}
            <Link
              href="https://martha-eierdanz.com/blog/paper-prototyping-in-practice"
              target="_blank"
              rel="noopener noreferrer"
            >
              Martha Eierdanz
            </Link>
          </p>
        </li>
        <li>
          <strong>Digital prototyping </strong>– they are most often used in app prototyping due to
          their robustness. With digital prototyping, you can easily assess the functionality and
          usability of a product as users get a real feel for it.{' '}
        </li>
        <li>
          <strong>HTML prototyping </strong>– although slowly becoming obsolete, some UX designers
          still go for this option. A prototype is built in HTML, so it requires some coding skills.
          The design is plain, not including any images or fancy colors, but it can be useful when
          it comes to web prototyping.{' '}
        </li>
      </ol>
      <p>
        Since you know what prototyping methodologies you can select from, it’s time for some
        practical skills on how to approach it.
      </p>
      <h3>Steps to Effective UX Prototyping</h3>
      <p>There are five steps that you should follow to develop a UX prototype. They are:</p>
      <h4>Step 1: Develop your Idea</h4>
      <p>
        You can’t build a product without knowing the reason why you want to do it in the first
        place. Figure out what problem your product is going to solve, then focus on building a
        design that would enable users to meet their goals in the simplest way.
      </p>
      <h4>Step 2: Create a List of Product Requirements</h4>
      <p>
        What features does your product require to deliver on its objectives? Start with
        shortlisting the must-have or core features and focus on prioritizing those instead of
        listing an endless number of features that will only increase your development costs and
        decrease usability.{' '}
      </p>
      <h4>Step 3: Start Sketching </h4>
      <p>
        While at this stage most designers will already have product layouts and structures in mind,
        the purpose of this step is to explore what’s possible and what isn’t. So try to approach it
        with a fresh mind – start by drawing the core screens of your app.
      </p>
      <h4>Use Your Sketches to Build Wireframes</h4>
      <p>
        Now that you have your sketches ready, you can start building wireframes. These lay out the
        content and functionality, taking into consideration users’ needs. Its main purpose is to
        show to stakeholders which entities, pages, and components are going to be included in the
        app, and how they will interact with one another.{' '}
      </p>
      <h4>Step 5: Build a Prototype</h4>
      <p>
        The final step is to build the prototype. You have two options: 1) you can use your
        wireframes to build a basic prototype, or 2) you can use specialized tools to transform your
        ideas into a more interactive prototype filled with fake content.{' '}
      </p>
      <h2>User Experience Design – How to Improve & Best Practices</h2>
      <p>
        With all of the above, it’s now time to review the best practices that will help you ensure
        you’re creating a{' '}
        <Link
          href="/user-experience/user-experience-design/"
          target="_blank"
          rel="noopener noreferrer"
        >
          good UX design
        </Link>
        .
      </p>
      <h3>The Main User Experience Best Practices</h3>
      <p>
        Here are some UX experience best practices worth following while creating your design
        process:
      </p>
      <h4>Know Your Target Audience</h4>
      <p>
        You design products for specific users, which means you’ll have to get to know them inside
        out. Find out what their problems and pain points are, what their needs are, and what goals
        they are trying to achieve. Only once you have this information can you start thinking about
        product design.
      </p>
      <StaticImage
        src="../img/user-experience/introduction/target.png"
        alt="Target"
        title="Find your target"
      />
      <p>
        Source:{' '}
        <Link
          href="https://unsplash.com/photos/1JX4J_kq7sU"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Unsplash
        </Link>
      </p>
      <h4>Remember About Simplicity and Familiarity </h4>
      <p>
        As humans, we often cling to what we’re familiar with. This also applies to products.
        Familiarity allows users to learn how to use new products faster, which also has a positive
        impact on engagement.{' '}
      </p>
      <h4>Check How Users Interact with Your Design</h4>
      <p>
        We frequently assume that users will interact with our product in a certain way, but these
        are just assumptions. And reality can surprise us, which is why it’s worth verifying your
        assumptions by observing how users interact with your product in real-life.{' '}
      </p>
      <p>
        Not only will you be able to spot any potential roadblocks that prevent your users from
        meeting their goals, but you might also discover some design elements that bring joy to
        customers. If you want to observe how your users interact with your website or service, you
        can do so by signing up to{' '}
        <Link
          href="https://livesession.io/signup/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          LiveSession
        </Link>{' '}
        for free.
      </p>
      <Banner title="Do you want to observe how your users interact with your website or service?" />
      <h4>Make Your User Flow Consistent</h4>
      <p>
        User flow visually displays all the steps that a user must take to achieve their goal. While
        designing your user flow keep three questions in mind:
      </p>
      <ul>
        <li>Will your users be able to meet their goals in the end?</li>
        <li>Are there any elements that might make the experience less pleasant?</li>
        <li>Are there any steps that could be either simplified or disposed of completely?</li>
      </ul>
      <StaticImage
        src="../img/user-experience/user-experience-design/uxdesign.png"
        alt="User Flow Design"
        title="Make your user flow consistent"
      />
      <p>
        Source:{' '}
        <Link
          href="https://uxdesign.cc/when-to-use-user-flows-guide-8b26ca9aa36a"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          UXdesign
        </Link>{' '}
      </p>
      <h4>Put Readability and Accessibility First </h4>
      <p>
        Readability and accessibility play an important role in creating great user experiences. The
        text in your app being error-free doesn’t necessarily mean that it’s easy to read. Low
        readability will create navigation problems, and your users might feel lost. Instructions
        should be straightforward and easy to follow, which can only be achieved by using simple and
        familiar language that all users, irrespective of their age groups, can understand.{' '}
      </p>
      <p>
        If you’re seeking inspiration, a few{' '}
        <Link
          href="/user-experience/user-experience-design/"
          target="_blank"
          rel="noopener noreferrer"
        >
          user experience examples
        </Link>{' '}
        that you can look at include Google Search Engine, Facebook, Paypal, and Starbucks.
      </p>
      <h2>Summary</h2>
      <p>
        Offering great user experiences is no longer an option, it’s an expectation that most
        customers have and are willing to pay extra for. However, creating great UX requires an
        in-depth knowledge of your target audience, which calls for extensive UX research to form
        the basis of an effective UX design process. Having one in place will improve your chances
        of not only creating products that meet customers’ goals, but which are also easy and
        enjoyable to use.{' '}
      </p>
      <p>
        When creating your user experience design process, remember to keep accessibility,
        readability, and simplicity in mind, as well as making sure that your user flow is
        consistent.{' '}
      </p>
      <p>
        This was just a brief introduction to UX, and there’s much more to uncover. Click below to
        proceed to Chapter One – What is UX? 10 Experts Share Their Definition.
      </p>
      <div className="next-chapter">
        <Link href="/user-experience/what-is-ux/">
          <div>
            <p>Chapter 1</p>
            <p>What is UX? 10 Experts Share Their Definition</p>
          </div>
          <img src={arrow} alt="arrow" title="white arrow" />
        </Link>
      </div>
    </>
  );
};

export default withUserExperienceTemplate(UserExperience);
