import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { media } from 'theme/breakpoints';

export const HeroImage = styled(GatsbyImage)`
  display: block;
  margin: 0 auto;
  max-width: 964px;
`;

export const SectionWithRecommendation = styled.section`
  max-width: 964px;
  display: flex;
  padding-bottom: 3rem;
  margin: 0 auto;
  padding-bottom: 90px;
  justify-content: space-between;
  ${media.tablet} {
    flex-direction: column;
  }
`;

export const DescriptionBox = styled.div`
  flex-basis: 50%;
  margin: 0 72px 0 0;
  & h2 {
    margin-bottom: 24px;
  }
  ${media.tablet} {
    margin: 0 0 24px 0;
  }
`;

export const StyledSection = styled.section`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem 0;
  h2 {
    font-size: 40px;
    line-height: 50px;
    font-weight: 800;
    margin-bottom: 40px;
    ${media.tablet} {
      font-size: 24px;
      line-height: 34px;
    }
  }
  h3 {
    font-size: 32px;
    line-height: normal;
    font-weight: 800;
    margin-bottom: 40px;
    ${media.tablet} {
      font-size: 20px;
      line-height: 30px;
    }
  }
  strong {
    font-weight: 800;
  }
  ${media.tablet} {
    padding: 1rem 0;
  }
`;

export const StyledImg = styled(GatsbyImage)`
  transform: scale(1.15);
  margin: calc(82px * 1.15) 0 calc(90px * 1.15);
  ${media.tablet} {
    transform: scale(1);
    margin: 32px 0;
  }
`;
