import styled from 'styled-components';
import { Dropdown } from 'reactstrap';
import { media } from 'theme/breakpoints';
import { StyledButton } from 'components/ui/button';
import { StyledDropdown } from 'styles/pricing';

export const HeroSection = styled.section`
  display: grid;
  grid-template-columns: 1fr minmax(400px, 550px);
  grid-column-gap: 60px;
  justify-content: center;
  align-items: center;
  margin-top: 90px;
  & h1 {
    margin-bottom: 32px;
  }
  & p {
    font-size: 22px;
    line-height: 34px;
    margin-bottom: 40px;
  }
  & img {
    max-width: 560px;
  }
  ${media.desktopWide} {
    grid-template-columns: 1fr 1fr;
    & img {
      max-width: 100%;
    }
  }
  ${media.desktop} {
    & h1 {
      font-size: 36px;
      line-height: 48px;
      margin-bottom: 24px;
    }
    & p {
      font-size: 18px;
      line-height: 30px;
    }
  }
  ${media.tablet} {
    grid-column-gap: 0;
    margin-top: 2rem;
    grid-template-columns: 1fr;
    grid-template-rows: min-content;
    & img {
      display: none;
    }
  }
  ${media.mobile} {
    padding-top: 6.2rem;
    margin-top: 0;
    grid-row-gap: 52px;
  }
`;

export const PricingBanner = styled.section`
  background-color: #f5f5f5;
  border-radius: 6px;
  padding: 46px 0;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-column-gap: 105px;
  justify-content: center;
  margin-bottom: 40px;
  position: relative;
  ${media.tablet} {
    grid-template-columns: 1fr;
    padding: 40px;
    grid-row-gap: 24px;
    grid-template-rows: min-content min-content;
  }
  ${media.mobile} {
    margin-bottom: 30px;
  }
`;

export const Price = styled.div`
  align-self: center;
  & p strong {
    font-weight: 800;
  }
  & p:first-child {
    text-decoration: line-through;
    color: #6a6a6a;
    font-size: 22px;
    margin-bottom: 0;
    line-height: normal;
    ${media.mobile} {
      font-size: 20px;
    }
  }
  & p:last-child {
    font-size: 48px;
    position: relative;
    line-height: normal;
    margin-bottom: 0;
    &::before,
    &::after {
      font-weight: 800;
      position: absolute;
    }
    & sup {
      font-size: 22px;
      margin-right: 4px;
    }
    & sub {
      font-size: 20px;
      margin-left: 4px;
    }
  }
`;

export const Features = styled.ul`
  padding: 0;
  margin: 0;
  & li {
    font-size: 22px;
    margin-bottom: 2px;
  }
  ${media.tablet} {
    padding-left: 20px;
    & li {
      font-size: 18px;
    }
  }
`;

export const Requirements = styled.section`
  text-align: center;
`;

export const List = styled.ul`
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-template-rows: repeat(2, auto);
  grid-gap: 40px;
  justify-content: center;
  margin-top: 72px;
  margin-left: 60px;
  ${media.tablet} {
    margin-left: 0;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto);
    width: fit-content;
    margin: 54px auto 0;
  }
  & li {
    display: flex;
    align-items: center;
    & img {
      margin-right: 16px;
      max-width: 22px;
    }
    & p {
      font-weight: 400;
      font-size: 22px;
      line-height: normal;
      margin-bottom: 0;
      ${media.tablet} {
        font-size: 18px;
      }
    }
  }
`;

export const FeatureCards = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 33px;
  padding-top: 40px;
  ${media.desktop} {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    grid-row-gap: 24px;
  }
  ${media.mobile} {
    padding-top: 30px;
  }
  & div {
    background-color: #f5f5f5;
    border-radius: 6px;
    padding: 40px 24px 24px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 282px;
    ${media.desktop} {
      min-height: auto;
      padding: 40px;
    }
    &:nth-child(2) {
      padding: 30px 24px 24px;
      ${media.desktop} {
        padding: 30px 30px 40px;
      }
    }
    & img {
      max-width: min-content;
      height: auto;
      display: block;
      margin: 0 auto 16px;
    }
    & h3 {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 24px;
    }
    & p {
      margin-bottom: 0;
    }
  }
`;

export const ApplyBanner = styled.section`
  background-color: #7dffef;
  border-radius: 6px;
  padding: 56px 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 6.5rem 0 120px;
  ${media.desktop} {
    flex-direction: column;
    padding: 56px;
  }
  ${media.mobile} {
    margin: 2rem 0 120px;
    padding: 32px 24px;
  }
`;

export const ContentBox = styled.div`
  & h2 {
    font-size: 48px;
    margin-bottom: 32px;
  }
  & p {
    font-size: 22px;
    line-height: 34px;
    margin-bottom: 40px;
  }

  ${media.desktop} {
    text-align: center;
    & a {
      margin: 0 auto;
    }
    & ${StyledButton} {
      margin: 0 auto;
      display: block;
    }
  }
  ${media.mobile} {
    & h2 {
      font-size: 32px;
      margin-bottom: 24px;
    }
    & p {
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 32px;
    }
  }
`;

export const ImagesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-row-gap: 8px;
  grid-column-gap: 56px;
  margin-left: 85px;
  ${media.desktopWide} {
    margin-left: 40px;
    grid-column-gap: 24px;
  }
  ${media.desktop} {
    margin-top: 32px;
    margin-left: 0;
    grid-column-gap: 32px;
  }
  ${media.mobile} {
    grid-column-gap: 8px;
  }
  & .gatsby-image-wrapper {
    img {
      left: 50% !important;
      transform: translateX(-50%);
    }
  }
  img {
    margin: 0 auto;
    max-width: 138px;
    ${media.tablet} {
      max-width: 110px;
    }
    ${media.mobile} {
      max-width: 90px;
    }
  }
`;

export const StyledCustomDropdown = styled(Dropdown)`
  position: absolute;
  right: 16px;
  top: 16px;
`;

export const ExtendStyledDropdown = styled(StyledDropdown)`
  border: none;
  &:hover,
  &:active,
  &:focus {
    background-color: #fff !important;
    color: #000000 !important;
    box-shadow: none !important;
    border: none !important;
  }
`;
