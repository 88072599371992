import React from 'react';
import Link from 'components/ui/link/';
import PropTypes from 'prop-types';
import { useLogoCompanies } from 'hooks/useLogoCompanies';
import * as Styled from './styled';

const NewLogo = ({ children, customText, linkStudies }) => {
  const {
    kissflow,
    databox,
    ing,
    trail,
    earthtones,
    fundraise,
    hypestHive,
    chance,
    vitreo,
    yapsody,
    apploi,
    midigator,
  } = useLogoCompanies();

  return (
    <Styled.Trusted className="container">
      {children}
      <p className="customText">{customText}</p>
      <Styled.WrapperIcon>
        <img src={kissflow} alt="kissflow" title="Kissflow" />
        <img src={databox} alt="databox" title="Databox" />
        <img src={ing} alt="Ing bank" title="Ing" />
        <img src={trail} alt="Trail" title="Trail" />
        <img src={earthtones} alt="EarthtOnes" title="Earthtones" />
        <img src={fundraise} alt="Fundraise" title="Fundraise" />
        <img src={hypestHive} alt="HypestHive" title="Hypest Hive" />
        <img src={chance} alt="Chance" title="Chance" />
        <img src={vitreo} alt="Vitreo" title="Vitreo" />
        <img src={yapsody} alt="Yapsody" title="Yapsody" />
        <img src={apploi} alt="Apploi" title="Apploi" />
        <img src={midigator} alt="Midigator" title="Midigator" />
      </Styled.WrapperIcon>
      {linkStudies ? (
        <Link withArrow href="/customers/">
          {linkStudies}
        </Link>
      ) : (
        ''
      )}
    </Styled.Trusted>
  );
};

export default NewLogo;

NewLogo.propTypes = {
  children: PropTypes.node.isRequired,
  customText: PropTypes.string,
  linkStudies: PropTypes.string.isRequired,
};
NewLogo.defaultProps = {
  customText: 'Trusted by 1 000+ teams worldwide',
};
