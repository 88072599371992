import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  margin-top: 58px;
`;

export const Pre = styled.pre`
  position: relative;
`;

export const CopyButton = styled.button`
  position: absolute;
  top: -16px;
  transform: translateY(-100%);
  right: 5px;
  border: none;
  background-color: transparent;
  color: #0a4ed6;
  font-weight: 800;
  font-size: 16px;
  line-height: normal;
`;
