import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const StyledList = styled.ul`
  padding: 0;
  display: flex;
  flex-direction: column;
  list-style: none;
  &.compare {
    li {
      width: 100%;
    }
  }
  & li.title {
    font-weight: 800;
    font-size: 14px;
    line-height: 24px;
    margin: 32px 0 16px;
    padding: 0 16px;
    text-align: left;
    text-transform: uppercase;
  }
  li:not(.title) {
    padding: 16px;
    width: 100%;
    font-size: 16px;
    font-weight: 800;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:nth-child(odd) {
      background-color: #f5f5f5;
    }
    p {
      font-weight: 500;
      margin: 0;
      font-size: 16px;
      line-height: 28px;
    }
    & img {
      max-width: 16px;
    }
    & svg {
      transform: translateY(-6px);
      margin-left: 1px;
    }
    i {
      font-size: 20px;
      position: relative;
      &.planned {
        &::after {
          content: 'PLANNED';
          font-style: normal;
          color: #000;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(calc(-100% - 14px), -50%);
          background-color: #f8d047;
          width: 67px;
          height: 25px;
          border-radius: 4px;
          font-size: 10px;
          font-weight: 700;
          line-height: 28px;
          text-align: center;
        }
      }
    }
    .icon-cross {
      color: #f50057;
    }
  }
  ${media.tablet} {
    max-width: 576px;
    margin: 0 auto;
  }
`;

export const PaginationWrapper = styled.div`
  width: 100vw;
  position: sticky;

  z-index: 99;
  ${media.tablet} {
    max-width: 576px;
    margin: 0 auto;
    top: 88px;
  }
  ${media.mobile} {
    top: 84px;
  }
  &.compare {
    transform: translateX(-15px);
    .pagination {
      padding: 0 15px;
    }
  }
  .pagination {
    border-bottom: 1px solid #e4e5e8;
    border-radius: 0;
    justify-content: space-evenly;
    padding: 0;
    margin-bottom: 0;
  }
  .page-item {
    border-bottom: 2px solid #fff;
    width: 100%;
    text-align: center;
    background-color: #fff;
  }
  .page-link {
    font-size: 16px;
    font-weight: 500;
    border: none;
    color: #000;
    transition: 0.2s;
    outline: 0 !important;
    padding: 8px 10px 15px;
    background-color: #fff;
    line-height: normal;
  }
  .page-item.active {
    border-bottom: 2px solid #0a4ed6;
    transition: 0.2s;
    .page-link {
      background-color: #fff;
      color: #000;
      outline: 0 !important;
      transition: 0.2s;
      font-weight: 800;
      opacity: 1;
      border-color: transparent;
      &:focus {
        box-shadow: none;
      }
    }
  }
`;
export const List = styled.div`
  display: none;
  margin: 64px 0;
  li:last-child {
    border-bottom: none;
  }
  ${media.tablet} {
    display: block;
  }
`;
