import withUsabilityTestingTemplate from 'hoc/content-cluster';
import { StaticImage } from 'gatsby-plugin-image';
import Link from 'components/ui/link';
import arrow from 'img/ui/arrow_white.svg';
import Banner from 'components/usability-testing/banner';

const UsabilityReporting = () => (
  <>
    <h2>What Is a Usability Testing Report and What Should It Include</h2>
    <p>
      Let’s assume you have just conducted a usability test on your product and are now wondering
      how you should analyze the results. The answer lies in creating a report that is the
      by-product of your{' '}
      <Link href="/usability-testing/" target="_blank" rel="noopener noreferrer">
        usability testing
      </Link>
      . Reporting and usability testing go hand in hand – you cannot conduct great usability testing
      without effective reporting of your findings.
    </p>
    <p>
      While usability testing is conducted to discover valuable insights directly from end-users, a
      usability report is a summary of the findings. It’s a document that is prepared after
      evaluating the qualitative and quantitative data gathered during a usability test, showing the
      findings and recommendations to help with improving your product’s UX.
    </p>
    <p>
      Now that you know the definition of a usability report, let’s talk about what it should
      include.
    </p>
    <h2>What to Include in Your Usability Report</h2>
    <p>
      A usability testing report covers several{' '}
      <Link
        target="_blank"
        rel="noopener noreferrer nofollow"
        href="https://www.usability.gov/how-to-and-tools/methods/reporting-usability-test-results.html"
      >
        variables
      </Link>
      . Here are some factors to include in yours:
    </p>
    <h3>Background summary</h3>
    <p>
      This should explain the main reasons for conducting the test and list which products were
      tested (website usability, mobile usability, etc.). It should also provide information about
      the test participants (e.g. education level), sample size, testing location, and timestamps of
      when the study was conducted. A brief outline of all the steps taken to perform the test, the
      type of testing material used, and any observed setbacks (e.g. website usability issues,
      mobile usability issues) should also all be noted in the background summary.
    </p>
    <h3>Methodology</h3>
    <p>
      Whether you employed session recordings or head-to-head testing, it is always worth including
      how your usability test was conducted. Also, mention the types of questions and which of the
      four question types –{' '}
      <Link href="/usability-testing/usability-testing-questions/">
        screening, pre-test, in-test, and post-test
      </Link>{' '}
      – were asked of the participants.
    </p>
    <h3>Audience</h3>
    <p>
      Describe your participants, their demographics, and why they were recruited in the first
      place. Providing a summary of their demographics - e.g. age, profession, sites visited, and
      internet usage - is fine, but you should refrain from using full names due to privacy
      concerns.
    </p>
    <StaticImage
      src="../../img/usability-testing/usability-reporting/paper.png"
      alt="Audience"
      title="Data report"
    />
    <h3>Test results</h3>
    <p>
      This is the key ingredient of a usability report. In it, you should create a table that
      features a summary of task completion rates, number of participants, types of assignments, and
      other data.
    </p>
    <h3>Findings and recommendations</h3>
    <p>
      These should be real findings that you observed, rather than merely assumed. Also, you can
      either make one overall list of your findings or have them segmented by scenarios.
    </p>
    <h3>Executive summary of the usability testing</h3>
    <p>
      Placing this at the top of your report will help instantly onboard anyone who comes across
      your report in the future.
    </p>
    <p>
      With all of the above in mind, let’s now take a look at the key metrics that your report
      should include.
    </p>
    <h2>What Usability Metrics You Should Focus On</h2>
    <p>
      Usability metrics are standards of measurements that come in handy when you are trying to
      gauge the efficacy of a product. Without them, it would be virtually impossible to create a
      testing report. Here are some of the most important metrics to include in your reports:
    </p>
    <h3>Success rate</h3>
    <p>
      As per the <em>Nielsen Norman Group</em>, the success rate is the
      <Link
        target="_blank"
        rel="noopener noreferrer nofollow"
        href="https://www.nngroup.com/articles/success-rate-the-simplest-usability-metric/"
      >
        {' '}
        simplest usability metric
      </Link>
      . It is usually defined by the percentage of tasks that actual users complete successfully,
      while the failure rate means the opposite. User success gives insight into how well your
      product resonates with its end-users, which is the main reason for conducting a usability test
      in the first place. The higher the percentage of completed tasks by the participants, the
      greater the chances of your product being a success.
    </p>
    <h3>The number of errors</h3>
    <p>
      Listing{' '}
      <Link
        target="_blank"
        rel="noopener noreferrer nofollow"
        href="https://www.invespcro.com/blog/usability-metrics/"
      >
        the average number of times
      </Link>{' '}
      an error occurred when a user was performing a given task is a good metric to focus on. You
      shouldn’t expect to achieve a completely perfect outcome with an interactive test because a
      user might make an accidental, human error - a typo, picking the wrong reservation dates for a
      flight, or clicking on the wrong image, etc - so you shouldn’t expect to achieve a completely
      perfect outcome with an interactive test. However, listing the average number of errors does
      point you in the right direction for diagnostic purposes. Scoring each error with a severity
      level and short description is also a good idea for understanding the functionality of your
      tested product.
    </p>
    <h3>Time-based efficiency</h3>
    <p>
      This is a key metric to list in your report as it gauges the amount of time it takes for a
      user to complete a certain task or use a certain feature. This, in turn, measures the
      efficiency of your product. The less time a user spends performing a task, the higher the
      effectiveness of your solution.
    </p>
    <h3>User’s subjective satisfaction</h3>
    <p>
      This includes the personal thoughts of your participants regarding their level of comfort,
      behavior, and acceptability of product usage. This metric can be measured on either a
      quantitative or a qualitative scale in order to gain insights for your report.
    </p>
    <p>
      For usability testing to be successful, your findings must be clear and straight to the point.
      Let us now look at a few tips to make sure your reports follow this mantra.
    </p>
    <StaticImage
      src="../../img/usability-testing/usability-reporting/searching.png"
      alt="Metrics"
      title="User’s subjective satisfaction"
    />
    <h2>6 Tips for Usability Reporting</h2>
    <p>
      The following tips will be useful for everyone on your team in identifying the key problems
      with your digital or physical product and providing resolutions:
    </p>
    <ol>
      <li>
        Include visuals
        <p>
          We recommend that you include{' '}
          <Link
            target="_blank"
            rel="noopener noreferrer nofollow"
            href="https://pixelfridge.digital/7-ways-to-improve-your-user-testing-reports/"
          >
            visuals
          </Link>{' '}
          in your reports. Most people have short attention spans, so keeping them engaged with long
          text descriptions can be really hard. Instead, providing annotated screenshots or, better
          yet, video clips from your session recordings will have a greater impact.
        </p>
      </li>

      <li>
        Keep it short
        <p>
          No one likes to read pages and pages of formal documents. Writing concise and to-the-point
          reports, avoiding technicalities, is the key to getting the desired outcome.
        </p>
      </li>

      <li>
        Prioritize usability issues
        <p>
          Avoid discussing every single issue that was encountered during the test. It is also a
          good idea to assign priorities to your issues when compiling them in the report. This way
          the higher priority issues are looked at first to ensure a reduction in production
          backlog.
        </p>
      </li>

      <li>
        Use what you learned
        <p>
          It is important to take away constructive insights from your report and put them to good
          use. Implementing the findings into positive solutions will result in a much improved UX.
        </p>
      </li>

      <li>
        Take advantage of quotes
        <p>
          To make your reports more credible, try to incorporate user statements. Adding direct
          quotes from the participants clarifies the basis of user viewpoint and gives your reports
          a greater impact. They also add character to your report and keep its readers engaged.
        </p>
      </li>
      <li>
        Remember about the next steps
        <p>
          Always summarize your report with the proposed next steps. Having a solid plan of action
          to improve your product will enhance your report. Propose discussions and presentations
          that you should organize in the future based on the findings of your report. This will not
          only promote teamwork, but will also pave the way for product optimization.
        </p>
      </li>
    </ol>
    <p>
      Once you are armed with the aforementioned tips, try investing in a tool that can compile and
      organize your data in the best way possible.
    </p>
    <Banner />
    <h2>3 Tools That Help with Usability Reporting</h2>
    <p>
      Do you want your readers to understand the findings in your report easily? How about keeping
      them engaged from start to finish? In order to do so, we recommend that you invest in a
      professional reporting tool. Here are the top three reporting on usability testing tools that
      you should consider:
    </p>
    <h3>Google Data Studio</h3>
    <p>
      <Link
        target="_blank"
        rel="noopener noreferrer nofollow"
        href="https://datastudio.google.com/overview"
      >
        Google’s free-to-use, proprietary built-in visualization tool
      </Link>{' '}
      . It displays your quantitative findings by importing .xls or .csv files from Google Sheets.
      The best part is that it completely synchronizes with all of your files that are stored there.
      Every time your source files change, all of the data on Google Studio updates as well.
    </p>
    <StaticImage
      src="../../img/usability-testing/usability-reporting/dashboard.png"
      alt="Visualization tool"
      title="Google Data Studio"
    />
    <p>
      Source:{' '}
      <Link
        target="_blank"
        rel="noopener noreferrer nofollow"
        href="https://datastudio.google.com/gallery"
      >
        Google Data Studio
      </Link>
    </p>
    <h3>Xtensio</h3>
    <p>
      This tool facilitates a collaborative approach. You can edit and work on your data in real
      time, together with your team members. It also offers a
      <Link
        target="_blank"
        rel="noopener noreferrer nofollow"
        href="https://xtensio.com/templates/"
      >
        {' '}
        library of templates that
      </Link>
      let you design an instant and visually-appealing usability report.
    </p>
    <StaticImage
      src="../../img/usability-testing/usability-reporting/portfolio.png"
      alt="Edit and work on your data live along with your team members"
      title="Xtensio"
    />
    <p>
      Source:{' '}
      <Link target="_blank" rel="noopener noreferrer nofollow" href="https://xtensio.com/tour/">
        Xtensio
      </Link>
    </p>
    <h3>Chartblocks</h3>
    <p>
      This tool comes in handy when you are dealing with time constraints. It also makes it easy to
      merge and import data from multiple sources and create usability
      <Link
        target="_blank"
        rel="noopener noreferrer nofollow"
        href="https://www.chartblocks.com/en/"
      >
        {' '}
        charts
      </Link>
      .
    </p>
    <StaticImage
      src="../../img/usability-testing/usability-reporting/laptop.png"
      alt="Use Chartblocks to merge and import data from multiple sources and create usability charts."
      title="Chartblocks"
    />
    <p>
      Source:{' '}
      <Link
        target="_blank"
        rel="noopener noreferrer nofollow"
        href="https://www.chartblocks.com/en/features"
      >
        Chartblocks
      </Link>
    </p>
    <h2>Summary</h2>
    <p>Let’s peek into the key findings that were discussed in this article:</p>
    <ul>
      <li>
        Usability testing and usability reporting go hand in hand – you cannot conduct great
        usability testing without providing efficient reporting.
      </li>

      <li>
        A usability test report is a document that is prepared after evaluating the qualitative and
        quantitative data gathered during a usability test. It offers findings and recommendations
        that eventually help with improving your product’s UX.{' '}
      </li>

      <li>
        A usability report should include metrics like background summary, methodology, audience,
        test results, findings and recommendations, as well as an executive summary of the
        underlying usability test.
      </li>

      <li>
        The main metrics to focus on when creating a usability report are average success rate,
        number of errors, time-based efficiency, and the user’s subjective satisfaction.{' '}
      </li>

      <li>
        Some tips for creating a usability report are including visuals, keeping it concise,
        prioritizing common usability issues, using what you learned, taking advantage of direct
        user quotes, and crafting your next steps.{' '}
      </li>

      <li>
        The top 3 tools that greatly help with usability reporting are <em>Google Data Studio</em>,{' '}
        <em>Xtensio</em>, and <em>Chartblocks</em>.
      </li>
    </ul>
    <p>
      To sum up, your product is part of your brand identity and it should therefore not be
      compromised. Conducting usability testing and writing great usability reports is a surefire
      way of bettering your product and identifying weak spots, as well as reaching your target
      audience with a stronger, more well-crafted offer and improved aspects of user experience.
    </p>
    <div className="next-chapter">
      <Link href="/usability-testing/usability-testing-tools/">
        <div>
          <p>Chapter 4</p>
          <p>9 Usability Testing Tools You Need To Optimize Your UX</p>
        </div>
        <img src={arrow} alt="arrow" title="white arrow" />
      </Link>
    </div>
  </>
);

export default withUsabilityTestingTemplate(UsabilityReporting);
