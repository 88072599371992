import PropTypes from 'prop-types';
import * as Styled from './styled';

const Header = ({ title, description, titleStyle }) => (
  <Styled.Header>
    <Styled.Title className="line" style={titleStyle}>
      {title}
    </Styled.Title>
    <Styled.Description>{description}</Styled.Description>
  </Styled.Header>
);

export default Header;

Header.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]).isRequired,
  titleStyle: PropTypes.oneOfType([PropTypes.object]),
  description: PropTypes.string.isRequired,
};

Header.defaultProps = {
  titleStyle: undefined,
};
