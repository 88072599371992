export const cards = [
  {
    title: 'Session replays',
    desc: 'Advanced filtering will help you remain focused on the crucial recordings only.',
  },
  {
    title: 'Funnels',
    desc: `Organizing visitors' paths into funnels, you will be able to analyze your customers' journeys to purchase.`,
  },
  {
    title: 'Click & Heat Maps',
    desc: `Visualization of your website’s hottest sections will enable you to increase the number of actions.`,
  },
  {
    title: 'Dev Tools',
    desc: `Console logs will let you get rid of errors that lead to user’s irritation and, in the end - lower your conversion rates.`,
  },
];
export const cardsIndex = [
  {
    title: 'Session replays',
    desc: 'Advanced filtering will help you remain focused on the crucial recordings only.',
  },
  {
    title: 'Events',
    desc: `Send product-specific events via our Javascript API to better reflect the user's experience. Enrich user sessions to build a single source of truth.`,
  },
  {
    title: 'Metrics',
    desc: `By tracking KPIs such as user engagement, retention rate and conversion rate you can measure the success of your product and identify areas for improvement.`,
  },
  {
    title: 'Funnels',
    desc: `Organizing visitors' paths into funnels, you will be able to analyze your customers' journeys to purchase.`,
  },
  {
    title: 'Dev Tools',
    desc: `Console logs will let you get rid of errors that lead to user’s irritation and, in the end - lower your conversion rates.`,
  },
  {
    title: 'Click & Heat Maps',
    desc: `Visualization of your website’s hottest sections will enable you to increase the number of actions.`,
  },
];
export const saas = [
  {
    title: 'Console',
    desc: 'See Javascript errors, detailes stacktraces and logs like in your own browser.',
  },
  {
    title: 'Network',
    desc: `Record headers, request bodies and response bodies for individual network requests that took place within a user's session.`,
  },
];
