import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styled';

const Logo = ({ photoColor, showMobileMenu }) => {
  const [posY, setPosY] = useState(!photoColor);
  const listenToScroll = () => {
    const position = window.pageYOffset;
    if (position > 50) {
      setPosY(true);
    } else if (photoColor === 'reversed') {
      setPosY(false);
    } else {
      setPosY(true);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    return () => window.removeEventListener('scroll', listenToScroll);
  });

  return (
    <Styled.Brand href="/" aria-label="Main page">
      <Styled.LogoContainer logoImage={showMobileMenu ? true : posY} />
      {/* <BrandName>LiveSession</BrandName> */}
    </Styled.Brand>
  );
};

export default Logo;

Logo.propTypes = {
  photoColor: PropTypes.string,
  showMobileMenu: PropTypes.bool,
};

Logo.defaultProps = {
  showMobileMenu: false,
  photoColor: null,
};
