import { media } from 'theme/breakpoints';
import styled from 'styled-components';

export const Section = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 120px 0;
  ${media.tablet} {
    padding: 0 0 90px;
  }
  h3 {
    font-size: 28px;
    line-height: 42px;
  }
  p {
    padding: 20px 0 80px 0;
    margin: 0;
    ${media.mobile} {
      padding: 20px 0 64px 0;
    }
  }
  table {
    width: 100%;
    tbody {
      tr {
        ${media.mobile} {
          font-size: 14px;
        }
        td,
        th {
          padding: 16px 0;
          text-align: center;
          img {
            height: 16px;
          }
        }
        .planCompany {
          margin-left: 10px;
        }
        .planLS {
          margin-right: 10px;
        }
        .planCompany,
        .planLS {
          padding-top: 0;
          padding-bottom: 16px;
          width: 35%;
          .imageWrapper {
            margin-bottom: 24px;
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            img {
              height: 100%;
              max-height: 24px;
              border-radius: 4px;
              ${media.mobile} {
                margin-bottom: 4px;
              }
            }
            span {
              color: #000;
              margin-left: 8px;
              ${media.mobile} {
                margin-left: 0;
              }
            }
            ${media.mobile} {
              margin-bottom: 16px;
              flex-direction: column;
            }
          }
          ${media.mobile} {
            font-size: 12px;
          }
          ${media.tablet} {
            width: auto;
          }
        }
        .variant {
          text-align: left;
          display: flex;
          align-items: center;
          div {
            display: inline-block;

            :hover {
              span {
                text-decoration: underline;
              }
              i {
                border: 1px solid #000;
                color: #000;
                fill: #000;
                rect {
                  fill: #000;
                }
              }
            }
            span {
              font-size: 16px;
              font-weight: 700;
              ${media.mobile} {
                font-size: 14px;
              }
            }
            i {
              display: inline-flex;
              transform: scale(0.7);
              margin-left: 4px;
              border: 1px solid rgb(170, 170, 170);
              border-radius: 50%;
              min-width: 20px;
              height: 20px;
              text-align: center;
              -webkit-box-pack: center;
              justify-content: center;
              -webkit-box-align: center;
              align-items: center;
              max-width: 18px;
              color: rgb(170, 170, 170);

              svg,
              i,
              rect {
                fill: rgb(170, 170, 170);
                font-size: 10px;
              }
            }
          }
        }

        .planLS {
          span {
            color: #00983d;
          }
        }
        .planCompany {
          span {
            color: #d6204c;
          }
        }
        .cross {
          background-color: rgba(214, 32, 76, 3%);
        }
      }
    }
  }
`;
