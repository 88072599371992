export const features = [
  { key: 'alerts', title: 'Alerts' },
  { key: 'always_on_recording', title: 'Always on recording' },
  {
    key: 'audit_logs',
    title: 'Audit logs',
  },
  {
    key: 'custom_events',
    title: 'Custom events',
    plan_visible: true,
    tooltip: 'Capture any interaction your customers make with your app.',
  },
  {
    key: 'custom_invoicing',
    title: 'Custom invoicing',
    plan_visible: true,
  },
  { key: 'custom_properties', title: 'Custom properties' },
  { key: 'custom_user_access', title: 'Custom user access', plan_visible: true },
  { key: 'customer_success_manager', title: 'Customer success manager', plan_visible: true },
  { key: 'customizable_dashboards', title: 'Customizable dashboards' },
  { key: 'dashboards', title: 'Dashboards' },
  { key: 'data_export', title: 'Data export' },
  {
    key: 'developer_tools',
    title: 'DevTools',
    plan_visible: true,
    tooltip: 'Track console logs and errors.',
    index: 2,
  },

  {
    key: 'filters',
    title: 'Filters',
    plan_visible: true,
    tooltip: 'Use a wide range of filtering options to browse your recordings.',
  },
  { key: 'funnels', title: 'Funnels' },
  // {
  //   key: 'heat_maps',
  //   title: 'Heat maps',
  //   plan_visible: true,
  //   tooltip: 'Find the hottest sections on your website with heat maps.',
  // },
  { key: 'integrations', title: 'Integrations' },
  {
    key: 'min_duration',
    title: 'Minimum duration on page',
    plan_visible: true,
    tooltip: "Don't capture sessions shorter than chosen time.",
  },
  { key: 'multi_factor_authentication', title: 'Multi factor authentication' },
  { key: 'notes', title: 'Notes' },
  { key: 'organizations', title: 'Organizations', plan_visible: true },
  {
    key: 'priority_support',
    title: 'Priority support',
    tooltip: 'Quick responses and personalized approach via live chat and email.',
  },
  { key: 'rage_and_error_clicks', title: 'Rage and error clicks' },
  { key: 'recording_rules', title: 'Recording rules' },
  {
    key: 'resource_synchronization',
    title: 'Resource backuping',
    plan_visible: true,
    tooltip:
      'Styles and fonts are saved to properly display session even when the original files are deleted.',
  },
  {
    key: 'rest_api',
    title: 'REST API access',
    plan_visible: true,
    tooltip: 'Retrieve collected data programmatically.',
  },
  {
    key: 'segment_notifications',
    title: 'Segment notifications',
    plan_visible: true,
    tooltip: 'Daily or weekly email notifications about segment changes.',
  },
  {
    key: 'segments',
    title: 'Segments',
    plan_visible: true,
    tooltip: 'Build multiple segments to track different behavioral patterns.',
  },
  {
    key: 'session_sharing',
    title: 'Session sharing',
    plan_visible: true,
    tooltip: 'Share sessions with your team.',
  },
  { key: 'skip_inactivity', title: 'Skip inactivity' },
  { key: 'speed_up_session_replay', title: 'Speed up session replay' },
  {
    key: 'sso',
    title: 'SSO/SAML',
    tooltip: 'Quick responses and personalized approach via live chat and email.',
  },
  { key: 'tagging', title: 'Tagging' },
  { key: 'team_members', title: 'Team members' },
  {
    key: 'user_identification',
    title: 'User identification',
    plan_visible: true,
    tooltip: 'Identify your users by name or email.',
  },
  { key: 'user_journey', title: 'User journey' },
  { key: 'webhooks', title: 'Webhooks' },
  { key: 'websites', title: 'Websites' },
  {
    key: 'engagement_score',
    title: 'Engagement score',
    plan_visible: true,
    tooltip: 'Find and watch the most interesting recordings based on user activity.',
  },
  {
    key: 'click_maps',
    title: 'Clickmaps & Heatmaps',
    plan_visible: true,
    tooltip:
      'See how users interact with specific elements on your website. Find the hottest sections on your website with heat maps.',
  },
  {
    key: 'premium_integrations',
    title: 'Premium integrations',
    plan_visible: true,
    tooltip: 'Integrate LiveSession with Intercom, LiveChat or Zendesk.',
    index: 1,
  },
];

export const getFeaturesForPlan = (plan, minFeaturePlans) => {
  const planFeatures = Object.entries(minFeaturePlans)
    .map((item) => {
      if (item[1] === plan) {
        const [mapped] = features.filter((f) => f.key === item[0] && f?.plan_visible);
        return mapped;
      }
      return null;
    })
    .filter(Boolean);
  return planFeatures.sort((a, b) => (b.index || 0) - (a.index || 0));
};
