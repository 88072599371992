import { Link as GatsbyLink } from 'gatsby';
import PropTypes from 'prop-types';
import Paragraph from 'components/ui/Paragraph';
import Link from 'components/ui/link';
import * as Styled from './styled';

const Card = ({ img, title, desc, link, bgColor, comingSoon }) => (
  <Styled.Box
    to={comingSoon ? undefined : link}
    as={comingSoon ? 'div' : GatsbyLink}
    comingSoon={comingSoon}
  >
    <div className="image-box" style={{ backgroundColor: bgColor }}>
      <img src={img} alt="logo" title={title} className="img-fluid" />
    </div>
    <div className="content-box">
      <h3>{title}</h3>
      <Paragraph>{desc}</Paragraph>
      {comingSoon ? (
        <Paragraph className="coming-soon">Coming soon</Paragraph>
      ) : (
        <Link tag="p" to={link} className="with-arrow" style={{ margin: 0 }}>
          Read full story
        </Link>
      )}
    </div>
  </Styled.Box>
);

export default Card;

Card.propTypes = {
  img: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.element]).isRequired,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
  comingSoon: PropTypes.bool,
};

Card.defaultProps = {
  comingSoon: false,
};
