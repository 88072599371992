import React from 'react';
import FeaturesSlider from 'components/index/featuresSlider';
import { graphql, useStaticQuery } from 'gatsby';
import { cards } from 'components/newCustomers/cards';
import { media } from 'theme/breakpoints';
import styled from 'styled-components';

export const Wrapper = styled.div`
  section {
    border: none;
  }
  ${media.desktop} {
    .pt120 {
      padding-top: 90px !important;
      ${media.tablet} {
        padding-top: 0 !important;
      }
    }
  }
`;

const EngagementSlider = () => {
  const { session, funnels, click } = useStaticQuery(graphql`
    {
      session: file(relativePath: { eq: "saas/sessionx3.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      funnels: file(relativePath: { eq: "ecommerce/funnels.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      click: file(relativePath: { eq: "ecommerce/heatmap.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  const dataImage = {
    images: [session, funnels, click],
    alt: [
      'advanced filtering to help you focus on only the most important recordings',
      `organizing visitors' paths into funnels`,
      'visualize the hottest sections of your site ',
    ],
  };

  return (
    <Wrapper className="container mt-120-90">
      <div className="separator" />
      <div className="pt-168-120-90">
        <FeaturesSlider
          color="#f8d047"
          sectionTitle={
            <h2 className="line">
              Track <span>engagement</span>
            </h2>
          }
          sectionDesc="If you are after converting your website’s visitors into registered accounts, you have to lead them on a straight path to subscribe."
          images={dataImage.images}
          alternativeText={dataImage.alt}
          cards={cards}
        />
      </div>
    </Wrapper>
  );
};
export default EngagementSlider;
