import NewsletterTemplate from 'templates/newsletter';

const Content = () => (
  <table className="content" cellSpacing="0" cellPadding="0" align="center" width="100%">
    <tbody>
      <tr>
        <td>
          <table className="text" cellSpacing="0" cellPadding="0" style={{ width: '100%' }}>
            <tbody>
              <tr>
                <td style={{ padding: '32px 40px 12px' }}>
                  <h1>
                    Hi{' '}
                    <span role="img" aria-label="emoji">
                      &#128075;
                    </span>
                  </h1>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '12px 40px' }}>
                  <p style={{ margin: 0, fontSize: '16px', lineHeight: '24px' }}>
                    We hope you’re doing well! The world is slowly coming back to normal, and we’re
                    not slowing down either. We’ve released many new features and we’re curious to
                    see what you think!{' '}
                    <span role="img" aria-label="emoji">
                      👀
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '12px 40px 44px' }}>
                  <p style={{ margin: 0, fontSize: '16px', lineHeight: '24px' }}>
                    Here’s what’s new in LiveSession:
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '12px 0 34px' }}>
                  <div style={{ borderTop: '1px solid #ddd' }} />
                </td>
              </tr>
              <tr>
                <td style={{ padding: '0 40px' }}>
                  <h2 style={{ margin: 0, fontSize: '22px' }}>Product updates</h2>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '32px 40px 12px' }}>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <a href="/blog/autoplay/" target="_blank" rel="noopener noreferrer">
                            <img
                              src="https://emails.livesession.io/play-next.png"
                              width="520"
                              height="400"
                              alt="Product update - LiveSession autoplay"
                              style={{
                                border: 0,
                                lineHeight: '100%',
                                outline: 0,
                                display: 'block',
                                height: 'auto',
                                borderRadius: '6px',
                                maxWidth: '100%',
                              }}
                            />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            href="/blog/autoplay/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              textDecoration: 'none',
                              color: '#061631',
                              fontWeight: 800,
                              marginTop: '24px',
                              display: 'block',
                            }}
                          >
                            Autoplay
                          </a>
                          <a
                            href="/blog/autoplay/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              textDecoration: 'none',
                              color: '#9b9b9b',
                              fontSize: 16,
                              marginTop: 8,
                              display: 'block',
                            }}
                          >
                            By popular demand, we’ve just launched autoplay in LiveSession. After
                            you finish watching a recording, the next session from the selected
                            segment will play automatically.
                          </a>
                          <a
                            href="/blog/autoplay/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              fontSize: '16px',
                              color: '#0446CA',
                              fontWeight: 700,
                              textDecoration: 'none',
                              marginTop: 8,
                              display: 'block',
                            }}
                          >
                            Test autoplay in action
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '32px 40px 12px' }}>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <a
                            href="/blog/filtering-updates/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src="https://emails.livesession.io/new-filters.png"
                              width="520"
                              height="400"
                              alt="Product update - LiveSession new filters"
                              style={{
                                border: 0,
                                lineHeight: '100%',
                                outline: 0,
                                display: 'block',
                                height: 'auto',
                                borderRadius: '6px',
                                maxWidth: '100%',
                              }}
                            />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            href="/blog/filtering-updates/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              textDecoration: 'none',
                              color: '#061631',
                              fontWeight: 800,
                              marginTop: '24px',
                              display: 'block',
                            }}
                          >
                            Filtering updates
                          </a>
                          <a
                            href="/blog/filtering-updates/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              textDecoration: 'none',
                              color: '#9b9b9b',
                              fontSize: 16,
                              marginTop: 8,
                              display: 'block',
                            }}
                          >
                            Filtering got even easier! Now you can browse sessions by page count,
                            use autocomplete, duplicate and disable filters, and much more.
                          </a>
                          <a
                            href="/blog/filtering-updates/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              fontSize: '16px',
                              color: '#0446CA',
                              fontWeight: 700,
                              textDecoration: 'none',
                              marginTop: 8,
                              display: 'block',
                            }}
                          >
                            See new filters
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '32px 40px 12px' }}>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <a
                            href="/blog/advanced-session-sharing/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src="https://emails.livesession.io/share-session.png"
                              width="520"
                              height="400"
                              alt="Product update - LiveSession session sharing"
                              style={{
                                border: 0,
                                lineHeight: '100%',
                                outline: 0,
                                display: 'block',
                                height: 'auto',
                                borderRadius: '6px',
                                maxWidth: '100%',
                              }}
                            />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            href="/blog/advanced-session-sharing/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              textDecoration: 'none',
                              color: '#061631',
                              fontWeight: 800,
                              marginTop: '24px',
                              display: 'block',
                            }}
                          >
                            Advanced session sharing
                          </a>
                          <a
                            href="/blog/advanced-session-sharing/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              textDecoration: 'none',
                              color: '#9b9b9b',
                              fontSize: 16,
                              marginTop: 8,
                              display: 'block',
                            }}
                          >
                            You can now choose to share sessions with the public, or with your
                            teammates only. You can also set the recording to start at a particular
                            moment.
                          </a>
                          <a
                            href="/blog/advanced-session-sharing/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              fontSize: '16px',
                              color: '#0446CA',
                              fontWeight: 700,
                              textDecoration: 'none',
                              marginTop: 8,
                              display: 'block',
                            }}
                          >
                            Try new sharing settings
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '34px 0' }}>
                  <div style={{ borderTop: '1px solid #ddd' }} />
                </td>
              </tr>
              <tr>
                <td style={{ padding: '0 40px' }}>
                  <h2 style={{ margin: 0, fontSize: '22px' }}>Blog posts</h2>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '32px 40px 12px' }}>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <a
                            href="/blog/cognitive-psychology-theories/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src="https://emails.livesession.io/cognitive-psychology.png"
                              width="520"
                              height="400"
                              alt="Blog post - 6 Cognitive Psychology Theories to Improve UX Design"
                              style={{
                                border: 0,
                                lineHeight: '100%',
                                outline: 0,
                                display: 'block',
                                height: 'auto',
                                borderRadius: '6px',
                                maxWidth: '100%',
                              }}
                            />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            href="/blog/cognitive-psychology-theories/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              textDecoration: 'none',
                              color: '#061631',
                              fontWeight: 800,
                              marginTop: '24px',
                              display: 'block',
                            }}
                          >
                            6 Cognitive Psychology Theories to Improve UX Design
                          </a>
                          <a
                            href="/blog/cognitive-psychology-theories/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              textDecoration: 'none',
                              color: '#9b9b9b',
                              fontSize: 16,
                              marginTop: 8,
                              display: 'block',
                            }}
                          >
                            See how cognitive psychology can help you become a better UX designer.
                            Read the most important theories for UX design.
                          </a>
                          <a
                            href="/blog/cognitive-psychology-theories/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              fontSize: '16px',
                              color: '#0446CA',
                              fontWeight: 700,
                              textDecoration: 'none',
                              marginTop: 8,
                              display: 'block',
                            }}
                          >
                            Learn about psychology in design
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '32px 40px 12px' }}>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <a
                            href="/blog/top-ux-events-in-cee/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src="https://emails.livesession.io/conferences-cover.png"
                              width="520"
                              height="400"
                              alt="Blog post - (COVID-19 Update) UX Events in CEE - 2020 Edition"
                              style={{
                                border: 0,
                                lineHeight: '100%',
                                outline: 0,
                                display: 'block',
                                height: 'auto',
                                borderRadius: '6px',
                                maxWidth: '100%',
                              }}
                            />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            href="/blog/top-ux-events-in-cee/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              textDecoration: 'none',
                              color: '#061631',
                              fontWeight: 800,
                              marginTop: '24px',
                              display: 'block',
                            }}
                          >
                            (COVID-19 Update) UX Events in CEE – 2020 Edition
                          </a>
                          <a
                            href="/blog/top-ux-events-in-cee/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              textDecoration: 'none',
                              color: '#9b9b9b',
                              fontSize: 16,
                              marginTop: 8,
                              display: 'block',
                            }}
                          >
                            The pandemic has shaken the events industry. See what’s changed.
                          </a>
                          <a
                            href="/blog/top-ux-events-in-cee/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              fontSize: '16px',
                              color: '#0446CA',
                              fontWeight: 700,
                              textDecoration: 'none',
                              marginTop: 8,
                              display: 'block',
                            }}
                          >
                            See the new UX events schedule for 2020
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '34px 0' }}>
                  <div style={{ borderTop: '1px solid #ddd' }} />
                </td>
              </tr>
              <tr>
                <td style={{ padding: '0 40px' }}>
                  <h2 style={{ margin: 0, fontSize: '22px' }}>Coming soon</h2>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '32px 40px 12px' }}>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <img
                            src="https://emails.livesession.io/clickmap.png"
                            width="520"
                            height="400"
                            alt="Coming soon - Click maps"
                            style={{
                              border: 0,
                              lineHeight: '100%',
                              outline: 0,
                              display: 'block',
                              height: 'auto',
                              borderRadius: '6px',
                              maxWidth: '100%',
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p
                            style={{
                              textDecoration: 'none',
                              color: '#000',
                              fontWeight: 800,
                              margin: '24px 0 0',
                              display: 'block',
                            }}
                          >
                            Click maps and forms analytics
                          </p>
                          <p
                            style={{
                              textDecoration: 'none',
                              color: '#000',
                              fontSize: 16,
                              margin: '8px 0 0',
                              display: 'block',
                            }}
                          >
                            Last but not least, we&apos;re constantly working hard on new features!
                            We&apos;re going to add <strong>new filters for forms analytics</strong>
                            , as well as <strong>click maps</strong> to track the most popular
                            elements on your website.
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '40px 60px 12px 40px' }}>
                  <p style={{ margin: 0, fontSize: '16px', lineHeight: '24px' }}>
                    If you have any suggestions, feel free to reply to this email. We’d love to hear
                    from you!
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '45px 40px 32px' }}>
                  <table className="kamil" cellSpacing="0" cellPadding="0">
                    <tbody>
                      <tr style={{ verticalAlign: 'middle' }}>
                        <th>
                          <img
                            src="https://emails.livesession.io/kamil-rounded.png"
                            className="kamil-img"
                            style={{ maxWidth: '56px' }}
                            alt="kamil"
                          />
                        </th>
                        <th style={{ paddingLeft: '16px' }}>
                          <p
                            className="info"
                            style={{
                              margin: 0,
                              textAlign: 'left',
                              fontWeight: 400,
                              lineHeight: '140%',
                            }}
                          >
                            <strong>Kamil</strong> <br />
                            CEO and Founder @ LiveSession
                          </p>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
);

const Newsletter = NewsletterTemplate(Content);

const frontmatter = {
  title: 'LiveSession Newsletter June 2020',
  description: "Read LiveSession's monthly update from June 2020.",
  date: '2020-06-01',
  link: '/newsletters/june-2020/',
};

const NewsletterPage = () => <Newsletter {...frontmatter} />;

export default NewsletterPage;
