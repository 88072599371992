import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const Section = styled.section`
  padding: 120px 0 168px;
  border-top: 1px solid #e4e5e8;
  text-align: center;
  ${media.mobile} {
    padding: 80px 0 120px;
  }
  & p {
    margin: 24px auto 40px;
    ${media.mobile} {
      font-size: 16px;
      line-height: 20px;
      margin: 24px auto 32px;
    }
  }
`;
