import premAvatar from 'img/blog/authors/prem_bitkowski.png';
import marcelAvatar from 'img/blog/authors/marcel_bednarz.png';
import kamilAvatar from 'img/blog/authors/kamil_drazkiewicz.png';
import szymonAvatar from 'img/blog/authors/simon_zynda.jpg';
import patrykAvatar from 'img/blog/authors/patryk_zdunowski.png';
import kalinaAvatar from 'img/blog/authors/kalina_tyrkiel.jpg';
import pawelAvatar from 'img/blog/authors/pawel_zielinski.jpeg';
import dorianAvatar from 'img/blog/authors/dorian_martin.jpg';
import aniaAvatar from 'img/blog/authors/ania_rubkiewicz.jpeg';
import kasiaAvatar from 'img/blog/authors/kasia_kowalska.jpg';
import joshAvatar from 'img/blog/authors/josh_brown.png';
import danielaAvatar from 'img/blog/authors/daniela.jpg';
import marieAvatar from 'img/blog/authors/marie_barnes.png';
import katherineAvatar from 'img/blog/authors/katherine_rundell.jpg';
import ronaldAvatar from 'img/blog/authors/ronald_cain.png';
import revanthAvatar from 'img/blog/authors/revanth_periyasamy.png';
import kendraAvatar from 'img/blog/authors/kendra-beckley.png';
import laurenAvatar from 'img/blog/authors/lauren_groff.jpg';
import nickAvatar from 'img/blog/authors/nick_brown.jpg';
import dhavalAvatar from 'img/blog/authors/dhaval_sarvaiya.png';
import justinAvatar from 'img/blog/authors/justin_osborne.png';
import aaronAvatar from 'img/blog/authors/aaron_haynes.jpg';
import margoAvatar from 'img/blog/authors/margo_ovsiienko.png';
import elizabethAvatar from 'img/blog/authors/elizabeth_hines.jpg';
import vikasAvatar from 'img/blog/authors/vikas_kalwani.png';
import zuzannaAvatar from 'img/blog/authors/zuzanna_sobczyk.png';

const authorPrem = {
  authorId: 'prem_bitkowski',
  authorName: 'Prem Bitkowski',
  authorDesc: 'Co-founder @ LiveSession',
  avatar: premAvatar,
};

const authorMarcel = {
  authorId: 'marcel_bednarz',
  authorName: 'Marcel Bednarz',
  authorDesc: 'Frontend Developer @ LiveSession',
  avatar: marcelAvatar,
};

const authorKamil = {
  authorId: 'kamil_drazkiewicz',
  authorName: 'Kamil Drazkiewicz',
  authorDesc: 'CEO @ LiveSession',
  avatar: kamilAvatar,
};

const authorSzymon = {
  authorId: 'simon_zynda',
  authorName: 'Simon Zynda',
  authorDesc: 'UX Specialist @ LiveSession',
  avatar: szymonAvatar,
};

const authorKalina = {
  authorId: 'kalina_tyrkiel',
  authorName: 'Kalina Tyrkiel',
  authorDesc: 'Content Specialist @ LiveSession',
  avatar: kalinaAvatar,
};

const authorPawel = {
  authorId: 'pawel_zielinski',
  authorName: 'Pawel Zielinski',
  authorDesc: 'Software Developer @ LiveSession',
  avatar: pawelAvatar,
};

const authorPatryk = {
  authorId: 'patryk_zdunowski',
  authorName: 'Patryk Zdunowski',
  authorDesc: 'Software Engineer @ LiveSession',
  avatar: patrykAvatar,
};

const authorDorian = {
  authorId: 'dorian_martin',
  authorName: 'Dorian Martin',
  authorDesc: 'Writer & Contributor @ WowGrade',
  avatar: dorianAvatar,
  bio: (
    <>
      Dorian Martin is a professional writer and contributor at{' '}
      <a href="https://wowgrade.net/" rel="noopener noreferrer" target="_blank">
        WowGrade
      </a>{' '}
      Dorian has a Psychology degree with a specialization in Design Psychology and currently writes
      his Ph.D. research paper on the impact of psychology on design.
    </>
  ),
};

const authorKasia = {
  authorId: 'kasia_kowalska',
  authorName: 'Kasia Kowalska',
  authorDesc: 'Content Specialist @ Contentki',
  avatar: kasiaAvatar,
};

const authorAnia = {
  authorId: 'anna_rubkiewicz',
  authorName: 'Anna Rubkiewicz',
  authorDesc: 'Content Specialist @ Contentki',
  avatar: aniaAvatar,
};

const authorJosh = {
  authorId: 'josh_brown',
  authorName: 'Josh Brown',
  authorDesc: '',
  avatar: joshAvatar,
  bio: (
    <>
      Josh is a former engineer-turned-marketer with a passion for writing. When Josh isn&apos;t
      building & analyzing marketing campaigns, he spends his time writing on topics that
      (hopefully) help businesses with improving the experience of customers.
    </>
  ),
};

const authorDaniela = {
  authorId: 'daniela_mcvicker',
  authorName: 'Daniela McVicker',
  authorDesc: '',
  avatar: danielaAvatar,
  bio: (
    <>
      Daniela McVicker is a passionate digital marketer. Daniela is interested in everything related
      to SEO and blogging. She collaborates with{' '}
      <a href="https://essayguard.com" rel="noopener noreferrer" target="_blank">
        Essayguard
      </a>{' '}
      and other websites where she shares her experience and helps marketers make their names in the
      online world.
    </>
  ),
};

const authorMarie = {
  authorId: 'marie_barnes',
  authorName: 'Marie Barnes',
  authorDesc: '',
  avatar: marieAvatar,
  bio: (
    <>
      Marie Barnes is Marketing Communication Manager and a writer for gearyoda. She is an
      enthusiastic blogger interested in writing about technology, social media, work, travel,
      lifestyle, and current affairs.
    </>
  ),
};

const authorKatherine = {
  authorId: 'katherine_rundell',
  authorName: 'Katherine Rundell',
  authorDesc: '',
  avatar: katherineAvatar,
  bio: (
    <>
      Katherine Rundell is a content writer at{' '}
      <a href="https://ukwritings.com/essay-services" rel="noopener noreferrer" target="_blank">
        Essay Services
      </a>{' '}
      and Personal Statement Writing Service. She writes about content marketing and as a freelance
      digital marketing consultant, she has brought many brands to wider and better engaged
      audiences.
    </>
  ),
};

const authorRonald = {
  authorId: 'ronald_cain',
  authorName: 'Ronald Cain',
  authorDesc: '',
  avatar: ronaldAvatar,
  bio: (
    <>
      Ronald Cain is a tutor at{' '}
      <a href="https://luckyassignments.com/manchester" rel="noopener noreferrer" target="_blank">
        Lucky assignments Manchester
      </a>
      . Whilst studying for a degree in psychology he got into building websites as a hobby and this
      developed into a passion for UX and design that eventually became his career.
    </>
  ),
};

const authorRevanth = {
  authorId: 'revanth_periyasamy',
  authorName: 'Revanth Periyasamy',
  authorDesc: '',
  avatar: revanthAvatar,
  bio: (
    <>
      Revanth is a marketing champ at{' '}
      <a
        href="https://www.apty.io/?utm_source=organic&utm_medium=guest_blog&utm_campaign=livesession"
        rel="noopener noreferrer nofollow"
        target="_blank"
      >
        Apty
      </a>
      . An ardent tech geek who loves to write on trending topics and is a big fan of all things
      relating to marketing. To know more about me, connect me on{' '}
      <a
        href="https://www.linkedin.com/in/revanth-periyasamy/"
        rel="noopener noreferrer nofollow"
        target="_blank"
      >
        LinkedIn
      </a>
      .
    </>
  ),
};

const authorKendra = {
  authorId: 'kendra_beckley',
  authorName: 'Kendra Beckley',
  authorDesc: '',
  avatar: kendraAvatar,
  bio: (
    <>
      Kendra Beckley is a writer and editor at{' '}
      <a
        href="https://academicbrits.com/write-my-research-proposal"
        rel="noopener noreferrer"
        target="_blank"
      >
        Write my research proposal
      </a>{' '}
      and{' '}
      <a href="https://originwritings.com/" rel="noopener noreferrer nofollow" target="_blank">
        Origin writings
      </a>
      . She is also a contributing writer for online publications, such as{' '}
      <a
        href="https://nextcoursework.com/buy-coursework"
        rel="noopener noreferrer nofollow"
        target="_blank"
      >
        Buy coursework
      </a>
      . As a business development manager, she helps companies build long-term relationships with
      partners nationwide and internationally.
    </>
  ),
};

const authorLauren = {
  authorId: 'lauren_groff',
  authorName: 'Lauren Groff',
  authorDesc: '',
  avatar: laurenAvatar,
  bio: (
    <>
      Lauren Groff is a tech writer at{' '}
      <a href="https://academized.com/" rel="noopener noreferrer" target="_blank">
        Academic Writing Service
      </a>{' '}
      and{' '}
      <a href="https://oxessays.com/" rel="noopener noreferrer nofollow" target="_blank">
        OXEssays
      </a>
      . She writes about Online Store Conversions, Funnels, and Marketing. With her free time she is
      a proofreader at{' '}
      <a href="https://paperfellows.com/" rel="noopener noreferrer nofollow" target="_blank">
        PaperFellows
      </a>{' '}
      writing service.
    </>
  ),
};

const authorNick = {
  authorId: 'nick_brown',
  authorName: 'Nick Brown',
  authorDesc: '',
  avatar: nickAvatar,
  bio: (
    <>
      Nick is a blogger and a marketing expert currently engaged in projects for Media Gurus, an
      Australian business, and marketing resource. He is an aspiring street artist and does
      Audio/Video editing as a hobby.
    </>
  ),
};

const authorDhaval = {
  authorId: 'dhaval_sarvaiya',
  authorName: 'Dhaval Sarvaiya',
  authorDesc: '',
  avatar: dhavalAvatar,
  bio: (
    <>
      I am Dhaval Sarvaiya, one of the founders of{' '}
      <a href="https://www.intelivita.com/" rel="noopener noreferrer" target="_blank">
        Intelivita
      </a>
      , an enterprise web and mobile app development company. I help Startups and Enterprises
      overcome their digital transformation and mobility challenges with the might of on-demand
      solutions powered by cutting-edge technology.
    </>
  ),
};

const authorJustin = {
  authorId: 'justin_osborne',
  authorName: 'Justin Osborne',
  authorDesc: '',
  avatar: justinAvatar,
  bio: (
    <>
      Justin is a marketing specialist and{' '}
      <a href="https://uk.bestessays.com/" rel="noopener noreferrer nofollow" target="_blank">
        essay writer
      </a>{' '}
      from Leicester, UK. When not working and rooting for Leicester FC, he likes to discuss new
      trends in digital marketing and share his own ideas with readers on different blogs and
      forums. Currently, he is working as a content marketer at{' '}
      <a
        href="https://www.bestessays.com/edu_college.php"
        rel="noopener noreferrer"
        target="_blank"
      >
        college paper writing services
      </a>
      .
    </>
  ),
};

const authorAaron = {
  authorId: 'aaron_haynes',
  authorName: 'Aaron Haynes',
  authorDesc: '',
  avatar: aaronAvatar,
  bio: (
    <>
      <a href="https://twitter.com/myfenixpro" target="_blank" rel="noopener noreferrer nofollow">
        Aaron Haynes
      </a>{' '}
      is CEO and co-founder of{' '}
      <a href="https://loganix.com/" rel="noopener noreferrer" target="_blank">
        Loganix
      </a>
      . The company is an SEO fulfillment partner for digital marketing agencies and professionals,
      which provides the services businesses need to improve their online visibility and grow. If
      you liked this article, check out the Loganix blog, where you’ll find more SEO guides full of
      expert advice.
    </>
  ),
};

const authorMargo = {
  authorId: 'margo_ovsiienko',
  authorName: 'Margo Ovsiienko',
  authorDesc: 'Product Marketing Manager @ InvoiceOcean',
  avatar: margoAvatar,
};

const authorElizabeth = {
  authorId: 'elizabeth_hines',
  authorName: 'Elizabeth Hines',
  authorDesc: '',
  avatar: elizabethAvatar,
  bio: (
    <>
      Elizabeth Hines is a writer at{' '}
      <a
        href="https://topcanadianwriters.com/best-essay-services/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Top Canadian Writers
      </a>{' '}
      and{' '}
      <a href="https://studydemic.com/" target="_blank" rel="noopener noreferrer nofollow">
        Study demic
      </a>
      . She is also a contributing writer for{' '}
      <a href="https://simplegrad.com/" target="_blank" rel="noopener noreferrer nofollow">
        Simple grad
      </a>
      . As a digital marketer, she specializes in social media advertising. As a content writer, she
      writes articles about the latest tech and marketing trends, innovations, and strategies.
    </>
  ),
};

const authorVikas = {
  authorId: 'vikas_kalwani',
  authorName: 'Vikas Kalwani',
  authorDesc: '',
  avatar: vikasAvatar,
  bio: (
    <>
      <a href="https://www.vikaskalwani.com/" target="_blank" rel="noopener noreferrer nofollow">
        Vikas Kalwani
      </a>{' '}
      is a product-led growth hacker and B2B Marketing Specialist skilled in SEO, Content Marketing,
      and Social Media Marketing. He works at{' '}
      <a href="https://userp.io/" target="_blank" rel="noopener noreferrer nofollow">
        {' '}
        uSERP
      </a>{' '}
      and is a mentor at 500 Startups.
    </>
  ),
};

const authorZuzanna = {
  authorId: 'zuza_sobczyk',
  authorName: 'Zuzanna Sobczyk',
  authorDesc: 'Content Designer',
  avatar: zuzannaAvatar,
};

const authors = [
  authorPrem,
  authorKamil,
  authorSzymon,
  authorPatryk,
  authorKalina,
  authorPawel,
  authorMarcel,
  authorDorian,
  authorKasia,
  authorAnia,
  authorJosh,
  authorDaniela,
  authorMarie,
  authorKatherine,
  authorRonald,
  authorRevanth,
  authorKendra,
  authorLauren,
  authorNick,
  authorDhaval,
  authorJustin,
  authorAaron,
  authorMargo,
  authorElizabeth,
  authorVikas,
];

export {
  authorPrem,
  authorKamil,
  authorSzymon,
  authorPatryk,
  authorKalina,
  authorPawel,
  authorMarcel,
  authorDorian,
  authorKasia,
  authorAnia,
  authorJosh,
  authorDaniela,
  authorMarie,
  authorKatherine,
  authorRonald,
  authorRevanth,
  authorKendra,
  authorLauren,
  authorNick,
  authorDhaval,
  authorJustin,
  authorAaron,
  authorMargo,
  authorElizabeth,
  authorVikas,
  authorZuzanna,
};

export default authors;
