import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const FeaturesSection = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: 'photo content';
  align-items: center;
  grid-column-gap: 94px;
  padding: 120px 0;
  &.shopping-events {
    padding-top: 40px;
  }
  & .gatsby-image-wrapper {
    width: 100%;
    grid-area: ${({ reversed }) => (reversed ? 'photo' : 'content')};
  }
  & div {
    grid-area: ${({ reversed }) => (reversed ? 'content' : 'photo')};
    & h3 {
      margin-bottom: 32px;
      font-size: 36px;
      line-height: 48px;
    }
    & p {
      margin-bottom: 24px;
    }
    & a {
      margin-bottom: 40px;
    }
    & div.logo-container {
      display: flex;
      & img {
        max-height: 37px;
        margin-bottom: 0;
      }
      & .gatsby-image-wrapper {
        margin-right: 32px;
        width: 100%;
      }
    }
  }
  ${media.desktop} {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, min-content);
    grid-template-areas:
      'content'
      'photo';
    grid-column-gap: 0;
    grid-row-gap: 40px;
    text-align: center;
    & .gatsby-image-wrapper {
      grid-area: photo;
    }
    & div {
      grid-area: content;
      & div.logo-container {
        margin-bottom: 24px;
        justify-content: center;
      }
    }
  }
  ${media.mobile} {
    &.shopping-events {
      padding-top: 72px;
    }
    padding-top: 0;
  }
`;
