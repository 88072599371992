import Layout from 'layout';
import Link from 'components/ui/link';
import Button from 'components/ui/button';

const ContentSpecialist = () => (
  <Layout
    metaTitle="Junior Software Engineer"
    canonical="/jobs/junior-software-engineer/"
    metaDescription="We are looking for a Junior Software Engineer - join us if you are looking for new challenges!"
  >
    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 text-left p-4 m-auto">
            <h1 className="mb-5 text-center">Junior Software Engineer</h1>

            <h4 className="mb-3">About us</h4>
            <p className="mb-3">
              LiveSession is growing our team of great people on a mission to make digital
              experiences better. Our core product helps product teams make better decisions based
              on our analytics platform. Born on qualitative analytics we&apos;re taking new steps
              further on quantitative bringing mixed types of analytics for better results.
            </p>

            <h4 className="mb-3">Our tech stack</h4>
            <ul className="">
              <li>JavaScript / TypeScript, React (+Redux)</li>
              <li>Go</li>
              <li>ElasticSearch, MySQL, Redis, Memcached</li>
              <li>Kubernetes, Helm, Ansible, Docker, Terraform</li>
              <li>Google Cloud</li>
            </ul>

            <h4 className="mb-3">About you</h4>
            <ul className="">
              <li>1+ years of experience in software engineering</li>
              <li>Willing to work and learn Go</li>
              <li>Experience with databases</li>
              <li>Experience with Linux-like environment</li>
              <li>Communicative Polish</li>
            </ul>

            <h4 className="mb-3">It would be perfect if you have</h4>
            <ul className="">
              <li>Experience working with Jira, Trello, Slack, Github, CI/CD</li>
              <li>
                Experience working with ElasticSearch, JavaScript, distributed systems,
                cloud-native, message brokers, multiple types of databases, browsers,
                unit/integration/e2e tests
              </li>
              <li>
                Experience working with debugging and monitoring production-ready applications
              </li>
              <li>Designed architectures from scratch</li>
              <li>Interest in Analytics topics</li>
              <li>Open Source initiatives</li>
            </ul>

            <p className="mb-3">Additionally:</p>
            <ul className="">
              <li>You’re a low-ego, high-urgency person</li>
              <li>You’re a lifelong learner</li>
              <li>You take ownership of your job</li>
              <li>You&apos;re goal-oriented</li>
              <li>You’re not afraid of honest feedback and giving one also</li>
            </ul>

            <h4 className="mb-3">What we offer</h4>
            <ul className="">
              <li>
                <b>Flexibility:</b> 100% remote work and flexible working hours
              </li>
              <li>
                <b>Time off:</b> flexible service-free days per year
              </li>
              <li>
                <b>Ownership and impact:</b> Joining as a first-10 engineer gives you big
                opportunities for your future career and a real impact on the company’s growth
              </li>
              <li>
                <b>Individual career path:</b> Ability to work with many different software
                engineering areas (distributed systems, APIs, frontend, data engineering, browser
                and mobile at a low level, machine learning, data science, and a lot more)
              </li>
            </ul>

            <i className="mb-3">
              Professional experience with these languages and technologies is not a requirement. We
              encourage candidates to use the programming language that they’re most comfortable
              with during the interview - new hires will be ramped in our codebase during
              onboarding.
            </i>

            <p className="mt-4">
              Send us your CV and a few words about yourself to:{' '}
              <Link href="mailto:jobs@livesession.io">jobs@livesession.io</Link>
            </p>
            <p
              style={{
                margin: '3rem',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button href="mailto:jobs@livesession.io">Apply</Button>
            </p>

            <p className="mt-5" style={{ fontSize: '0.8rem', color: 'grey' }}>
              By sending a recruitment application to LiveSession Sp. z o.o. with headquarters in
              Wroclaw, at gen. Wladyslawa Sikorskiego 3/2, 56-659, Wroclaw, entered in the register
              of entrepreneurs of the National Court Register under number 0000753134, Tax ID:
              PL8971860301, REGON: 381580380, you agree to the processing of personal data contained
              in the recruitment application by the Company in order to recruit for the position
              indicated in the announcement.
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default ContentSpecialist;
