import PropTypes from 'prop-types';
import styled from 'styled-components';
import quote from 'img/ui/quoteSecondary.svg';
import quoteBlack from 'img/ui/quoteBlack.svg';
import alexander from 'img/persons/alexanderShpilka.png';
import gasper from 'img/persons/gasper.png';
import logoLivereacting from 'img/companyLogo/logoLivereactingBlack.png';
import logoDatabox from 'img/companyLogo/logoDataboxBlack.png';
import { media } from 'theme/breakpoints';

const Section = styled.section`
  ${media.tablet} {
    padding-top: 0;
  }
`;

const Quote = styled.div`
  background-color: #f8d047;
  border-radius: 4px;
  padding: 100px 140px;
  width: 100%;
  position: relative;
  &.secondary {
    background-color: #f5f5f5;
    &::before,
    &::after {
      background-image: url(${quoteBlack});
    }
    & div {
      background-color: #f5f5f5;
    }
  }
  ${media.tablet} {
    padding: 100px 20px;
  }
  ${media.mobile} {
    padding: 94px 20px;
  }
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 60px;
    left: 60px;
    background-image: url(${quote});
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    width: 50px;
    height: 42px;
    ${media.tablet} {
      top: 40px;
      left: 20px;
      width: 40px;
      height: 34px;
    }
  }
  &::after {
    top: auto;
    left: auto;
    bottom: 60px;
    right: 60px;
    transform: rotate(180deg);
    ${media.tablet} {
      bottom: 40px;
      right: 20px;
    }
  }
  & h2 {
    text-align: center;
    line-height: 54px;
  }
  & div {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%) rotate(45deg);
    width: 20px;
    height: 20px;
    background-color: #f8d047;
    z-index: 10;
  }
`;

const Person = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: 60px;
  & img:first-of-type {
    max-width: 48px;
  }
  & h5 {
    margin-top: 16px;
    font-size: 18px;
    line-height: 30px;
  }
  & p {
    font-size: 18px;
    line-height: 30px;
    margin: 0;
  }
  & img:last-of-type {
    max-width: 102px;
    margin-top: 16px;
  }
`;

const persons = [
  {
    id: 'livereacting',
    name: 'Alexander Shpilka',
    job: 'CEO & Founder at LiveReacting',
    person: alexander,
    logo: logoLivereacting,
  },
  {
    id: 'databox',
    name: 'Gasper Vidovic',
    job: 'Director of Product at Databox',
    person: gasper,
    logo: logoDatabox,
  },
];

const Opinion = ({ opinion, companyID, secondary, style }) => (
  <Section style={style}>
    <Quote className={secondary ? 'secondary' : ''}>
      <h2>{opinion}</h2>
      <div />
    </Quote>
    {persons
      .filter(({ id }) => id === companyID)
      .map(({ name, job, person, logo, id }) => (
        <Person key={name}>
          <img src={person} alt={name} title={name} />
          <h5>{name}</h5>
          <p>{job}</p>
          <img src={logo} alt={id} />
        </Person>
      ))}
  </Section>
);

export default Opinion;

Opinion.propTypes = {
  opinion: PropTypes.string.isRequired,
  companyID: PropTypes.string.isRequired,
  secondary: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.object]),
};

Opinion.defaultProps = {
  secondary: false,
  style: undefined,
};
