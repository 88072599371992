/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import Layout from 'layout';
import CustomersRate from 'components/CustomersRate';
import Input from 'components/signup/signupInput';
import Button from 'components/ui/button';
import { post } from 'api';
import {
  Section,
  Message,
  Container,
  SubTitle,
  ListDesc,
  List,
  InputContainer,
  Label,
  Textarea,
} from 'styles/demo';

const Demo = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [companyUrl, setCompanyUrl] = useState('');
  const [traffic, setTraffic] = useState('100k-500k');
  const [help, setHelp] = useState('');
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('');
  const [requestSended, setRequestSended] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const requestBody = {
      email,
      name,
      company,
      company_url: companyUrl,
      traffic,
      help,
    };
    post(`${process.env.GATSBY_API_URL}/accounts/demo`, requestBody)
      .then(() => {
        setMsg([
          <p>
            Thank you! We will contact you within <strong>1 business day</strong> to schedule a
            demo.
          </p>,
          'success',
        ]);
        setLoading(false);
        setRequestSended(true);
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        let message = '';
        if (err.response !== undefined) {
          message = err?.response?.data?.error?.message;
        } else {
          message = <p>Unknown error, try again later.</p>;
        }
        setMsg([message, 'error']);
        // eslint-disable-next-line no-console
        console.log(err);
        setLoading(false);
        window.scrollTo(0, 0);
      });
  };
  return (
    <Layout
      metaTitle="Book a Free Demo |  Top Session Replay Software"
      canonical="/demo/"
      metaDescription="Schedule a demo of LiveSession and let us show you how we can help your business grow. Looking for an enterprise pricing? Let's talk!"
    >
      <div className="container">
        <Section>
          {msg[0] && (
            <Message className={msg[1]}>
              {typeof msg[0] === 'string' ? <p>{msg[0]}</p> : msg[0]}
            </Message>
          )}
          <Container>
            <h1 className="line">
              Request a LiveSession
              <br />
              <span>demo</span> today
            </h1>

            <SubTitle>
              If you are wondering how LiveSession can help you get to know your visitors better,
              schedule an in-depth product demo and get an offer tailored to your needs.
            </SubTitle>
            <ListDesc>What you will get on of this demo:</ListDesc>
            <List>
              <li>Understand how to get most of qualitative analytics</li>
              <li>Knowledge about LiveSession’s power features</li>
              <li>An extended trial period and enterprise features</li>
              <li>An offer tailored to your needs</li>
              <li>
                <h3 className="line">
                  Access to the data that <span>matters</span> faster
                </h3>
              </li>
            </List>
            <CustomersRate black deleteTitle addStars />
          </Container>
          <form onSubmit={handleSubmit}>
            <InputContainer>
              <Input
                textLabel="Your full name"
                type="text"
                onChange={(e) => setName(e.target.value)}
                maxLength="255"
                required
                autoFocus
              />
            </InputContainer>
            <InputContainer>
              {' '}
              <Input
                textLabel="Business email"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                minLength="5"
                maxLength="255"
                required
              />
            </InputContainer>
            <InputContainer>
              <Input
                textLabel="Company name"
                type="text"
                onChange={(e) => setCompany(e.target.value)}
                maxLength="512"
                required
              />
            </InputContainer>
            <InputContainer>
              <Input
                textLabel="Company URL"
                type="text"
                onChange={(e) => setCompanyUrl(e.target.value)}
                required
              />
            </InputContainer>
            <InputContainer>
              <Label htmlFor="monthly-traffic">Monthly traffic</Label>
              <Textarea
                as="select"
                id="monthly-traffic"
                onChange={(e) => setTraffic(e.target.value)}
                style={{ minHeight: 48, backgroundColor: 'transparent' }}
                required
                defaultValue="100k-500k"
              >
                <option value="100k">Less than 100 000 sessions</option>
                <option value="100k-500k">100 000 - 500 000</option>
                <option value="500k-1mln">500 000 - 1 000 000</option>
                <option value="1mln-5mln">1 000 000 - 5 000 000</option>
                <option value="5mln+">5 000 000+</option>
              </Textarea>
            </InputContainer>
            <InputContainer style={{ marginBottom: 48 }}>
              <Label htmlFor="textarea">How can we help?</Label>
              <Textarea id="textarea" onChange={(e) => setHelp(e.target.value)} required />
            </InputContainer>

            <Button
              as="button"
              style={{ width: '100%' }}
              type="submit"
              secondary
              disabled={loading || requestSended}
            >
              Book a demo
            </Button>
          </form>
        </Section>
      </div>
    </Layout>
  );
};

export default Demo;
