import withUsabilityTestingTemplate from 'hoc/content-cluster';
import Link from 'components/ui/link';
import { StaticImage } from 'gatsby-plugin-image';
import arrow from 'img/ui/arrow_white.svg';
import { faq3 } from 'data/heatmaps/faq3';
import FAQ from 'components/FAQ';

const GeneratingHeatMaps = () => (
  <>
    <h2>Best practices for generating heat maps that are worth following</h2>
    <p>
      By generating heat maps for your website, you have the opportunity to gather better insights
      into how users navigate it. Furthermore, you can structure your site in a way that will
      improve sales, boost conversion rates, and keep visitors coming back.
    </p>
    <p>
      Here are some of the best practices that we recommend to help you grow your business’s digital
      footprint and boost sales.
    </p>
    <h3>1. Establish which pages you need to set up your heat map on</h3>

    <p>
      Although it might be tempting to simply create them for every page, we recommend heat map
      creators to focus first on the most-viewed pages that are crucial for their organization.
      These include your{' '}
      <Link
        href="https://instapage.com/blog/landing-page-heat-map"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        homepage
      </Link>{' '}
      , which is often the first page that users visit, and others that are of high importance for
      your business, such as pricing. If you have an eCommerce store or campaign pages for
      promotions or giveaways, it’s also worth setting up a heatmap layer on them. That’s because
      they, in particular, need to be user-friendly and easy to navigate.{' '}
    </p>

    <p>
      Other places worth considering for heat mapping are newly launched pages. They require special
      attention, since, for example, any design non-clickable elements that need refining should be
      identified either before or shortly after publishing. If the page features calls-to-action
      (CTAs), they also need to be tested to determine whether or not they are placed correctly and
      can generate interactions.
    </p>

    <p>
      Various types of heatmaps can also be used to target pages with high bounce rates and those
      that have low click-through rates (CTR). Pages that do not yield their expected conversion
      rates need extra attention, and using your Google Analytics dashboard should enable a product
      manager to identify which of them are not performing well.
    </p>
    <StaticImage
      src="../../img/heatmaps/generating-heat-maps/analytics.png"
      alt="Google Analytics - LiveSession website"
      title="Establish which pages you need to set up your heat map on"
    />

    <p>
      Last, but not least, remember to also review conversion elements and paths, i.e.{' '}
      <Link href="/user-experience/user-journey-map/" target="_blank" rel="noopener noreferrer">
        user journeys
      </Link>{' '}
      that demonstrate how your users navigate your website from first entry up to placing an order
      or taking another desired action. You need to use your heat map data to optimize the
      conversion funnel and ensure that visitors’ paths are seamless and error-free. With the right
      heat map report, doing this is easier than ever before.{' '}
    </p>
    <h3>2. Decide what information you need to verify</h3>

    <p>
      Before you launch your heat map, it’s worth agreeing on the problems or issues that you’re
      aiming to tackle by doing so. If, for instance, one or more of your product pages have a big
      drop-off rate, making a heat map will enable you to identify where users end their journeys.
      You can then further investigate the issue by using other tools such as session recordings or
      surveys, which we will talk about later in this article.{' '}
    </p>

    <h3>3. Use various types of heat maps (or establish the ones you need most)</h3>

    <p>
      Once you have determined which areas of your website call for improvement, such as in the
      aforementioned example of product pages, you might uncover that you’d benefit from more than
      one type of heat map. For a comprehensive overview, you can refer to our dedicated piece
      reviewing{' '}
      <Link href="/heatmaps/what-is-a-heat-map/" target="_blank" rel="noopener noreferrer">
        all the heatmap options
      </Link>{' '}
      to choose from.
    </p>

    <p>
      For instance, using a scroll map and a click map together will help you to determine how far
      down page users scroll and which areas generate clicks and interest. Ultimately, before
      deciding on the heat map type, aim to establish which comprehensive heatmap data you need most
      in order to resolve any usability problems and improve on-site conversion.
    </p>

    <h3>4. Use heat map data in conjunction with session recordings and eye-tracking software</h3>

    <p>
      Enhancing your website’s UX with heat map data to illuminate all on-site interactions can give
      you the edge that you need to cultivate exceptional experiences. However, bear in mind that
      while a heat map layer lets you see the bulk of user activity on each site, you might still
      need other tools to dive deep into each user’s on-site actions.
    </p>
    <p>
      {' '}
      Here’s where session recording solutions come into play, giving you the option to watch a
      video of how each user moves and interacts with your website. To make the most of your{' '}
      <Link href="/heatmaps/heatmap-analysis/" target="_blank" rel="noopener noreferrer">
        heat map analysis
      </Link>
      , we recommend using a solution like{' '}
      <Link href="/help/how-do-the-clickmaps-work/" target="_blank" rel="noopener noreferrer">
        LiveSession
      </Link>
      to use heatmaps and session recordings together
    </p>
    <StaticImage
      src="../../img/heatmaps/generating-heat-maps/heatmaps.png"
      alt="Heatmap and session recording"
      title="Use jointly with session recordings and eye-tracking software"
    />

    <p>
      {' '}
      Also, if you’d like to learn which parts of your website are most and least viewed, consider
      using eye-tracking software to create hover maps. As this requires wearable equipment,
      however, it is most commonly used in a lab environment as part of usability testing sessions.
      We will discuss them in the next point.
    </p>
    <h3>5. Verify your findings with other tools before drawing any conclusions</h3>

    <p>
      Once you have made your heat maps, it is important not to treat the gathered data as final.
      Cross-check your findings with tools such as Google Analytics and, where possible, run website
      surveys to gather more information.
    </p>

    <p>
      Additionally, you can take advantage of user testing labs where participants are asked to
      complete specific tasks. Depending on your preferences, you can either sit back and quietly
      observe them or ask testers to comment on each step they take. Consider conducting anonymized
      user sessions, as some respondents might not be comfortable providing feedback openly.
    </p>

    <h3>6. Make use of a heat map generator</h3>

    <p>
      Heat map generators are mapping software tools that take your website data, track the user
      behavior, and based on that create a visual representation of data. Most of them use the
      colormap range (from green to red) to essentially show the radius of influence - color
      concentration shows scroll depth data, where the highest traffic is, and which elements people
      most often click.
    </p>
    <p>
      Heat map generators are the best choice for heat mapping. A common color scale makes analysis
      easier and helps you detect any errors much faster.
    </p>
    <p>
      Now that you know how to create a heat map, let’s take a look at the mistakes that you need to
      avoid.{' '}
    </p>

    <h2>Mistakes to avoid when generating heat maps</h2>
    <h3>1. Don’t draw conclusions too early</h3>

    <p>
      When making heat maps, it can be tempting to gather data as fast as possible so that you can
      move straight to implementing the solutions. However, it is important that you do not rush the
      process. Not only do you require a sufficient number of website visitors in order to gain
      credible user insights, but you also need to set realistic deadlines for data gathering right
      from the very start. This will ensure you get the answers you need without the risk of jumping
      to the wrong conclusions.
    </p>
    <h3> 2. Not supporting your heat map findings with user feedback</h3>

    <p>
      As mentioned earlier, heat map data does not only give you a bird’s eye view of all user
      actions. More often than not, they are also anonymous, which means you don’t have the option
      to collect qualitative user feedback. Namely, you can’t reach out to specific users and ask
      them for more detailed explanations of their actions.
    </p>

    <p>
      You can tackle this potential obstacle with user testing sessions so that you can ask the
      participants about your website content and therefore understand which areas you must further
      focus on. You can do so by either talking to testers directly or asking them to finalize the
      session by taking a survey to provide feedback.
    </p>

    <p>
      Last, but not least, if you decide to organize user testing sessions, remember to account for
      any visual impairment or website responsiveness issues. These are the usability areas that a
      heat map analysis might not clearly point you towards.
    </p>
    <h3>3. Forgetting to block your IPs</h3>

    <p>
      When generating heatmap visualization, you need to ensure that the data you gather is from
      website users who are not your employees. Not blocking IPs will mean that you could be
      gathering data from within your own company, which is far from ideal.
    </p>
    <p>
      This is especially true when testing newly launched pages that are often viewed by in-house
      testing marketing teams or other colleagues. As such, you should always double-check if
      internal user traffic is not included in your analysis to ensure that your data is credible.
    </p>
    <StaticImage
      src="../../img/heatmaps/generating-heat-maps/office.png"
      alt="how to make a heat map and make sure your data is relevant – blocking internal traffic"
      title="Forgetting to block your IPs"
    />

    <p>
      Source:{' '}
      <Link
        href="https://unsplash.com/photos/5QiGvmyJTsc"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Unsplash
      </Link>
    </p>

    <p>
      As such, you should always double-check if internal user traffic is not included in your
      analysis to ensure that your data is credible.
    </p>
    <h3>4. Lack of user context</h3>

    <p>
      No two web users are alike, so you need to consider your various customer personas with their
      own challenges, goals, pain points, and on-site visitor behavior. Some visitors will be
      interacting with your website for the first time, while others are going to be returning
      customers.
    </p>

    <p>
      Consider splitting up your testing to reflect these different experiences. We recommend
      analyzing how new customers interact with the core content on your website, product, and
      services pages separately from those who already know it well and are more comfortable with
      the given information.
    </p>

    <p>
      However, if both your new user and established customer groups are struggling to finalize
      certain processes, product or interface familiarity won’t be to blame. There is the likelihood
      of a wider issue with the UX that will need to be addressed promptly.
    </p>
    <h3>5. Treating the number of spotted actions as indicators of success</h3>

    <p>
      Not all repeat actions indicate success. Consider, for example, “rage clicks”. These are
      erroneous button presses that users make when clicking on content repeatedly as a result of
      being unable to move successfully further along the user journey.
    </p>
    <StaticImage
      src="../../img/heatmaps/generating-heat-maps/bug.png"
      alt="generate heat map to make sure you spot bugs and errors"
      title="Treating the number of spotted actions as an indicator of success"
    />
    <p>
      {' '}
      Sometimes, users also navigate back to a webpage on more than one occasion or click on
      elements such as CTA buttons several times. This doesn’t necessarily mean interest in certain
      content though, especially if your conversion rate simply doesn’t correspond to the number of
      interactions.
    </p>

    <p>
      If that’s something you notice in your data, you should make sure the buttons are working
      properly before making any design changes.{' '}
    </p>
    <h2>How to create a heat map – final thoughts</h2>
    <p>
      Generating heatmap help documentation can provide an exceptional source of data to enhance
      your digital presence. Always retain your focus on the data you require, augment your findings
      with additional tools and user testing, and be clear on what your picture of success looks
      like for user engagement. It could be the game-changer you need to boost your business and
      gain customers.{' '}
    </p>
    <p>
      For heat maps and session recordings, look no further than{' '}
      <Link href="/" target="_blank" rel="noopener noreferrer">
        LiveSession
      </Link>{' '}
      – we offer a free trial that you can check out today.
    </p>

    <FAQ questions={faq3} style={{ paddingTop: '120px' }} />

    <div className="next-chapter">
      <Link href="/heatmaps/heat-maps-for-website/">
        <div>
          <p>Chapter 3</p>
          <p>How to use heat maps to improve your website</p>
        </div>
        <img src={arrow} alt="arrow" title="white arrow" />
      </Link>
    </div>
  </>
);

export default withUsabilityTestingTemplate(GeneratingHeatMaps);
