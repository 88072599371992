/* eslint-disable jsx-a11y/anchor-is-valid */

import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import withIntegrationCardContext from 'hoc/withIntegrationCardContext';
import Slider from 'react-slick';

const Slack = ({ sliderSettings }) => {
  const { img1 } = useStaticQuery(graphql`
    {
      img1: file(relativePath: { eq: "help/slack/slack-integration.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 95)
        }
      }
    }
  `);
  const photos = [
    {
      img: img1.childImageSharp,
      alt: 'Slack Integration',
      title: 'Integrate Slack with LiveSession',
    },
  ];
  return (
    <>
      <Slider {...sliderSettings(photos)} className="without-dots">
        <div>
          <GatsbyImage
            image={photos[0].img?.gatsbyImageData}
            alt={photos[0].alt}
            title={photos[0].title}
          />
        </div>
      </Slider>
      <div className="section">
        <h2>Overview</h2>
        <p>
          Slack is a messaging platform designed for teams. Get real-time messaging through calls
          and chat.
        </p>
        <p>
          Integrate LiveSession with Slack and get instant notifcations about the important events
          made by users. This integrations sends LiveSession Alerts to Slack channel.
        </p>
      </div>
      <div className="section">
        <h2>Benefits</h2>
        <ul className="checkmarks">
          <li>Send LiveSession Alerts to Slack channels</li>
          <li>Get instant notifications about important events inside your product</li>
          <li>Use Alerts to track signups, subscriptions, errors or frustration signals</li>
        </ul>
      </div>
      <h2>How to install</h2>
      <p>
        You can install Slack integration with one click. See a step-by-step tutorial in our{' '}
        <a href="/help/slack-integration/">help center</a>.
      </p>
    </>
  );
};

export default withIntegrationCardContext(Slack);

Slack.propTypes = {
  sliderSettings: PropTypes.func.isRequired,
};
