import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const Dropdown = styled.div`
  display: none;
  background-color: #f5f5f5;
  padding: 16px;
  ${media.desktopWide} {
    display: block;
    width: 100%;
  }
  ${media.mobile} {
    width: 100vw;
    transform: translateX(-15px);
    &.fixed {
      position: fixed;
      top: 77px;
      left: 15px;
      z-index: 99;
      box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
    }
  }
`;

export const Button = styled.button`
  width: 100%;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  & .active {
    font-size: 18px;
    line-height: 23px;
    font-weight: 800;
    margin-bottom: 0;
  }
  & .icon {
    width: 16px;
    height: 9px;
    transition: transform 0.2s ease;
  }
  &.active {
    & .icon {
      transform: rotate(180deg);
    }
  }
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 16px 0 0;
  padding-top: 16px;
  overflow-y: scroll;
  max-height: calc(100vh - 150px);
  & li {
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 24px;
    }
    & a {
      width: 100%;
      display: block;
      &.active {
        color: #0a4ed6;
        &:hover {
          color: #0a4ed6;
        }
        & p:first-child {
          color: #0a4ed6;
        }
        & p:last-child {
          font-weight: 800;
          color: #0a4ed6;
        }
      }
      & p:first-child {
        font-size: 14px;
        line-height: 24px;
        color: #808080;
        margin-bottom: 4px;
        text-transform: uppercase;
      }
      & p:last-child {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 0;
        color: #000;
      }
    }
  }
`;
