import styled from 'styled-components';

export const Card = styled.section`
  padding: 0px 0px 0px 0px;
  background-color: #f5f5f5;
  border-radius: 4px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  & .gatsby-image-wrapper {
    margin-top: -43px;
    overflow: visible !important;
    max-width: 80%;
    width: 100%;
    height: auto;
  }
`;

export const Container = styled.div`
  padding: 8px 24px 32px;
  text-align: center;
  & h3 {
    font-weight: 800;
    font-size: 26px;
    line-height: 33px;
    margin-bottom: 16px;
  }
`;
