import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import { StyledParagraph } from 'components/ui/Paragraph';
import { StyledLink } from 'components/ui/link';
import { CardsContainer, CardIntegration } from 'components/ecommerce/styles';

export const Section = styled.section`
  padding: 198px 0 72px;
  ${media.desktop} {
    padding: 72px 0 90px;
    max-width: 768px;
    margin: 0 auto;
  }
  ${media.tablet} {
    max-width: 576px;
    padding-bottom: 30px;
  }
`;

export const Integrations = styled.div`
  display: flex;
  background-color: #f5f5f5;
  border-radius: 6px;
  align-items: center;
  padding: 0 72px;
  max-height: 466px;
  justify-content: space-between;
  ${media.desktop} {
    flex-direction: column;
    padding: 48px 24px;
    max-height: fit-content;
  }
`;

export const Content = styled.div`
  & h2 {
    margin-bottom: 24px;
    width: 100%;
    & span {
      display: inline;
    }
  }
  & ${StyledLink} {
    font-size: 18px;
    margin-top: 40px;
  }
  ${media.desktopWide} {
    & ${StyledParagraph} {
      max-width: 90%;
    }
  }
  ${media.desktop} {
    margin-bottom: 48px;
    & h2 {
      text-align: center;
      width: 100%;
    }
    & ${StyledParagraph} {
      text-align: center;
    }
    & ${StyledLink} {
      margin-top: 32px;
      text-align: center;
      display: block;
    }
  }
`;

export const IconsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 80px;
  & .item {
    width: 75px;
    height: 75px;
    box-shadow: 0px 4px 16px rgb(0 0 0 / 8%);
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s ease;
    cursor: pointer;
    &.crisp {
      img {
        max-width: 50px;
      }
    }
    & img {
      max-width: 40px;
      max-height: 40px;
      &.active {
        transform: scale(1);
      }
    }
  }
  & .active {
    transform: scale(1.8);
  }
  ${media.tablet} {
    grid-gap: 55px;
    & .item {
      width: 50px;
      height: 50px;
      & img {
        max-width: 80%;
        max-height: 25px;
      }
      &.crisp {
        img {
          max-width: 38px;
        }
      }
    }
  }
`;

export const CustomCardsContainer = styled(CardsContainer)`
  grid-gap: 28px;
`;

export const CustomCardIntegration = styled(CardIntegration)`
  box-shadow: 0px 4px 16px rgb(0 0 0 / 8%);
  & img {
    min-width: 48px;
    max-width: 48px;
    max-height: 48px;
  }
  &.active {
    box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.16);
    & p {
      font-weight: 700;
    }
  }
  ${media.mobile} {
    box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.16);
    & img {
      min-width: 25px;
      max-width: 25px;
      max-height: 25px;
    }
  }
`;
