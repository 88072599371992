import withUsabilityTestingTemplate from 'hoc/content-cluster';
import { StaticImage } from 'gatsby-plugin-image';
import Link from 'components/ui/link';
import arrow from 'img/ui/arrow_white.svg';

const UXProtptyping = () => (
  <>
    <h2>UX Prototyping – What Is It Exactly?</h2>
    <p>
      Before venturing into UX prototyping, it is worth explaining what a prototype is and what
      business benefits it brings. A prototype is an elementary design that has the potential to
      turn into a finished product. This basic version of a product enables you to conduct initial
      usability testing and collect feedback from potential customers.
    </p>
    <p>Now, let us investigate the various benefits. </p>
    <h3>What are the benefits of UX prototyping?</h3>
    <p>
      UX{' '}
      <Link
        href="https://ux247.com/the-benefits-of-prototyping/"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        prototyping
      </Link>{' '}
      provides countless benefits and can be conducted for multiple reasons:
    </p>
    <ul>
      <li>
        <strong>Verifying product-market fit </strong>-UX prototyping provides tools and resources
        for user testing and validating product ideas to make sure they are fit for the market. If a
        product is not deemed market worthy after analyzing the results of this process, you can
        always adjust and add refinements to bring it up to par. The useful insights that UX
        prototyping provides always go a long way in turning your ideas into reality.{' '}
      </li>
      <li>
        <strong> Improving the user experience </strong>- prototyping is a special tool for UX
        designers in elevating the{' '}
        <Link href="/user-experience/" target="_blank" rel="noopener noreferrer">
          user experience
        </Link>{' '}
        . It makes the job of a designer easier by gathering real user feedback and insights, thus
        improving the user experience as an end-result. The ability to produce rapid prototyping and
        quick design revisions means a better finished product with less effort put into its
        development.{' '}
      </li>
      <li>
        <strong>Shortening development time and costs </strong>- research shows that UX prototyping
        shortens the average time of development and reduces costs. It reveals bugs in the early
        stages of design and lets you fix them before you start working on user interface (UI)
        tasks. If you omit the prototyping stage, you risk creating a less efficient UX design that
        might eventually need redesigning. Testing early and UX prototyping is much easier than
        redesigning the entire UI.{' '}
      </li>
    </ul>
    <StaticImage
      src="../../img/user-experience/ux-prototyping/meeting.png"
      title="Meeting"
      alt="Prototyping"
    />

    <p>
      Source:{' '}
      <Link
        href="https://unsplash.com/photos/Oalh2MojUuk"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Unsplash
      </Link>
    </p>
    <p>
      Now that we know the benefits of UX prototyping, let us discuss the different types of
      prototypes that are available.
    </p>
    <h2>UX prototyping methods</h2>

    <p>
      UX prototyping is not a monolith - there are various methodologies that can be used. Depending
      on the types of ideas you are trying to serve, or the kinds of problems that need to be
      solved, here are a few major ones that should be incorporated into your UX process:
    </p>
    <h3>1. Paper prototyping</h3>
    <p>
      <Link
        href="https://www.justinmind.com/blog/the-beginners-guide-to-prototypes/"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Paper prototyping
      </Link>{' '}
      is popular among UX designers due to its inexpensive and flexible nature. Some people confuse
      sketching with paper prototyping, but they are quite different things. A paper version of a
      prototype is more versatile than a regular sketch and can be created by using materials like
      cardboard and stencils to help produce a more defined graphic design.
    </p>
    <p>
      They can be cut out of paper, which gives you the flexibility of moving and switching UI
      elements around. You can also incorporate post-it-notes into your paper prototype to start
      defining your application flow. Here are a few major benefits of using this method of
      prototyping:
    </p>
    <p>
      <strong>No special skills required </strong>- even a novice UX designer can create paper
      prototypes as they are simply made of paper. No special skills are required, but a great
      imagination goes a long way.{' '}
    </p>

    <p>
      <strong>They are inexpensive </strong>- a piece of paper is very cheap, so these prototypes
      are budget-friendly as well.
    </p>

    <p>
      <strong>Low-fidelity prototypes </strong>- these are so-called limited function prototypes,
      but that does not make them any less valuable. These wireframes are normally concerned with
      what goes where in the design rather than how it looks or the colors that will be chosen.
      Because of this, they are faster to create than high-fidelity prototypes and aid in expediting
      the UX design process.
    </p>
    <StaticImage
      src="../../img/user-experience/ux-prototyping/analyze.png"
      title="Paper prototype"
      alt="Prototyping on paper"
    />

    <p>
      Source:{' '}
      <Link
        href="https://unsplash.com/photos/sv8oOQaUb-o"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Unsplash{' '}
      </Link>
    </p>
    <h3>2. Digital prototyping</h3>
    <p>
      Digital prototypes are interactive and quite robust, which is why they are mainly used for UX
      prototyping of apps. You can see functionality, usability, and realism all come together with
      these prototypes. Here are the key features:
    </p>

    <p>
      <strong>They are realistic </strong>- digital prototypes are clickable and respond to actions,
      hence they provide a complete and realistic visual design of your mobile app or website to the
      stakeholders before it goes into production.
    </p>

    <p>
      <strong>Can reduce development time </strong>- studies have shown that the digital prototyping
      process allows developers to catch bad design problems upfront, thus reducing development
      times. Early digital simulations can expedite the product development process, since most
      digital prototyping tools have drag and drop interfaces and no coding is required.
    </p>

    <p>
      <strong>A useful reference source for developers </strong>- digital prototypes can be used for
      gaining a solid understanding of the functionality of a product in a way that is not possible
      with paper or screen specifications. As an example, if a developer needs to understand how a
      specific widget or control should work, they can use a digital prototype to easily answer
      functionality questions.
    </p>
    <StaticImage
      src="../../img/user-experience/ux-prototyping/prototype.png"
      title="Prototype on computer"
      alt="Developer prototyping"
    />

    <p>
      Source:{' '}
      <Link
        href="https://www.a4plus.co.uk/prototyping-manufacture/digital-prototyping/"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        a4plus
      </Link>
    </p>
    <h3>3. HTML prototyping</h3>

    <p>
      <Link
        href="https://www.justinmind.com/blog/the-beginners-guide-to-prototypes/"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        HTML prototypes
      </Link>{' '}
      are becoming obsolete, but they still have a secure place in the world of UX design. As the
      name implies, this prototype is built by using the HTML language, which offers a great
      advantage to people who are fluent in coding. They are simplistic in design without any fancy
      typography, imagery, or color choices and can be useful for website prototyping. Here are a
      few benefits of HTML prototypes:
    </p>

    <p>
      <strong>Can be viewed in any browser </strong>- they all support HTML. Since this prototype is
      coded in HTML, it can be viewed in any web browser.
    </p>

    <p>
      <strong>Inexpensive to create </strong>- you don’t need any special tools to code a prototype
      in HTML. There are many free HTML editing tools that you can download, making this prototype
      very cost-effective as well.
    </p>
    <p>
      <strong>Lays the technical foundation </strong>- HTML prototypes set the basis of a digital
      product for future iteration. HTML prototypes also get to the coding stage faster, since they
      have already been partly written in code.{' '}
    </p>

    <p>
      In the next section, we are going to glance at the necessary steps before creating a
      prototype.
    </p>
    <StaticImage
      src="../../img/user-experience/ux-prototyping/code.png"
      title="Prototype in HTML"
      alt="code"
    />
    <p>
      Source:{' '}
      <Link
        href="https://unsplash.com/photos/u2Ru4QBXA5Q"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Unsplash
      </Link>{' '}
    </p>

    <h2>How to Approach UX Prototyping</h2>
    <p>
      Irrespective of whether you are building an app or a website prototype, there are several
      steps you need to take before creating one:
    </p>

    <h3>Step 1: Develop and perfect your idea</h3>
    <p>
      The first step before bringing your idea to life is to{' '}
      <Link href="/user-experience/ux-research/">figure out what problem it is going to solve</Link>
      for real users. For example, if you are trying to build a mobile app that lets them create a
      to-do-list, you need to ensure your app is unique in terms of solving this problem. It might
      seem like a basic idea at best, but most successful apps always pay attention to this step
      before moving forward with the prototype and design phase.{' '}
    </p>
    <p>
      Your idea may seem revolutionary to you, but for the end-user it must offer real functionality
      - they will be downloading, sharing, and using your app regularly. Questions like{' '}
      <em>“how different is your app compared to similar ones?”</em>,{' '}
      <em>“does it offer extra functionalities or integrations with other apps?”</em> or{' '}
      <em>“does it provide an appealing interface?”</em> should be addressed beforehand.
    </p>
    <h3>Step 2: Come up with product requirements</h3>
    <p>
      Once you have identified the problems your product or app will solve; it is time to create a
      list of the cover features and prioritize them. Start by writing down the main features that
      your app should have and then move on to shortlisting those that will form the core of your
      app.
    </p>
    <p>
      Prioritization of features should be addressed next. aligned with the needs and preferences of
      your target audience. It would be best if you divide the features into “must-haves” and
      “nice-to-haves”. Take the first 3-4 features at the top of your priority list and begin
      crafting your prototype with them in mind.
    </p>
    <h3>Step 3: Draw sketches of your app or web primary screens</h3>
    <p>
      This step is all about the user experience. To keep the features of your app visually
      organized, start by sketching its primary screens. Once the sketches are ready, you can start
      adding details and keep building the screens further. The initial sketch should already
      consist of one potential user flow that you can keep refining as you continue developing your
      app.
    </p>
    <h3>Step 4: Build wireframes based on your sketches</h3>

    <p>
      Wireframing is the next step in developing your prototype. A wireframe is a basic outline of
      your product that is normally based on elements like boxes, words, and lines. It is wise to
      add a few descriptions to those elements in order to communicate your concept better. The idea
      behind wireframing is to not create the final look for your app, but to create a framework
      that sets it in the right direction.
    </p>
    <h3>Step 5: Build a prototype</h3>
    <p>
      Now it is time to employ your wireframes to create a prototype. You can either put them
      together to create a basic prototype, or help transform the initial ideas into interactive
      prototypes by using specialized tools. Such tools build high-fidelity prototypes that have the
      look and feel of real apps by using fake instead of real content.{' '}
    </p>
    <h2>Summary</h2>
    <p>
      Prototypes are the essence of any design project. They bring many advantages to the entire UX
      process, from the ideation phase to the completion phase. In this article, we have provided
      comprehensive insights into each different type of prototype, their benefits, and the various
      methodologies used with them. We discussed that a prototype is an elementary draft that
      provides many benefits such as verifying the product-market fit, and improved user experience,
      as well as considerable time savings and reduced development costs. We also glanced over the
      main types of UX prototyping methodologies including paper, digital, and HTML prototyping.
      Lastly, we discussed the various steps involved in the creation of a prototype: develop and
      perfect your idea, come up with product requirements, draw sketches of your app or web primary
      screens, build wireframes based on your sketches, and then build a prototype.
    </p>
    <div className="next-chapter">
      <Link href="/user-experience/ux-research/">
        <div>
          <p>Chapter 6</p>
          <p>How to Conduct Effective UX Research?</p>
        </div>
        <img src={arrow} alt="arrow" title="white arrow" />
      </Link>
    </div>
  </>
);

export default withUsabilityTestingTemplate(UXProtptyping);
