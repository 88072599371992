import { media } from 'theme/breakpoints';
import styled from 'styled-components';
import worldmap from 'img/worldmap.svg';
import { Badge } from 'components/customer/customerBadge/styled';
import { StyledButton } from 'components/ui/button';

export const StyledHeader = styled.header`
  display: flex;
  padding: 168px 0 0;
  align-items: center;
  margin-bottom: 40px;
  ${media.desktop} {
    flex-direction: column;
  }
  ${media.tablet} {
    max-width: 576px;
    margin: 0 auto 90px;
  }
  ${media.mobile} {
    padding: 120px 0 60px;
    margin: 0;
  }
  div {
    ${media.desktop} {
      text-align: center;
      ${StyledButton} {
        margin: 0 auto;
      }
    }
    ${media.tablet} {
      flex-basis: auto;
    }
    ${media.mobile} {
      ${StyledButton} {
        margin: 0;
      }
    }
    h1 {
      margin-bottom: 48px;
    }
  }
`;

export const HeroImgBox = styled.div`
  position: relative;
  margin-left: 48px;
  flex-basis: 45%;
  & .databox-hero-img {
    flex-basis: 50%;
    position: relative;
    overflow: initial !important;
    min-width: 530px;
    &::before {
      content: '';
      position: absolute;
      bottom: -16px;
      right: -16px;
      background-color: #7dffef;
      width: 90%;
      height: 90%;
    }
    ${media.desktopWide} {
      min-width: 450px;
    }
    ${media.tablet} {
      flex-basis: auto;
    }
    ${media.mobile} {
      min-width: auto;
      &::before {
        bottom: -8px;
        right: -8px;
      }
    }
  }
  ${media.desktop} {
    width: 90%;
    margin-top: 40px;
    margin-left: 0;
  }
  ${media.tablet} {
    width: 100%;
  }
  ${Badge} {
    top: 32px;
    left: 32px;
    ${media.tablet} {
      top: 16px;
      left: 16px;
    }
  }
`;

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  ${media.tablet} {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, auto);
    grid-row-gap: 32px;
  }
`;

export const Section = styled.section`
  padding-top: 40px;
  ${media.tablet} {
    padding-top: 120px;
    padding-bottom: 0;
  }

  & h2,
  p.desc {
    text-align: center;
  }
  & p.desc {
    margin: 32px 0 64px;
    font-size: 22px;
    line-height: 34px;
  }
`;

export const NumbersSection = styled.section`
  position: relative;
  margin: 40px 0;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: -35%;
    background-image: url(${worldmap});
    background-size: contain;
    background-position: right center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  & p {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 24px;
    & span {
      font-weight: 800;
    }
  }
  & div {
    margin-top: 48px;
    & a:not(:last-child) {
      margin-right: 56px;
    }
  }
  ${media.tablet} {
    margin: 30px 0;
    &::before {
      display: none;
    }
    & p {
      font-size: 26px;
      line-height: 36px;
      margin-bottom: 24px;
    }
    & div {
      display: grid;
      grid-template-columns: repeat(2, min-content);
      grid-template-rows: repeat(2, min-content);
      grid-column-gap: 48px;
      grid-row-gap: 32px;
      & a:not(:last-child) {
        margin-right: 0;
      }
    }
  }
  ${media.mobile} {
    & div a img {
      width: 90px !important;
    }
    & p {
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 16px;
    }
  }
`;
