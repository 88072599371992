import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSourceParams } from 'hooks/useSourceParams';
import { navigate } from 'gatsby';
import * as Styled from './styled';

const StartUsingLS = ({ deleteMarginTop, setMarginTop }) => {
  const { link } = useSourceParams('start-using');
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`${link}&email=${email}`);
  };
  return (
    <Styled.StartUsing
      className="container"
      deleteMarginTop={deleteMarginTop}
      style={{ setMarginTop }}
    >
      <h2>
        Start understanding <br /> your users today
      </h2>
      <div className="start">
        <Styled.ExtendSignUpForm onSubmit={handleSubmit}>
          <label htmlFor="email-form-start-using" className="sign-up-free">
            <input
              id="email-form-start-using"
              type="email"
              name="email"
              placeholder="Your email address"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="input-email"
            />
          </label>
          <Styled.CustomButton as="button" type="submit" secondary>
            Sign up free
          </Styled.CustomButton>
        </Styled.ExtendSignUpForm>
        <div className="checkmarks">
          <p>Free 14-day trial</p>
          <p>
            No credit card <span>required</span>
          </p>
          <p>Set up in minutes </p>
        </div>
      </div>
    </Styled.StartUsing>
  );
};

export default StartUsingLS;

StartUsingLS.propTypes = {
  deleteMarginTop: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  setMarginTop: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

StartUsingLS.defaultProps = {
  deleteMarginTop: false,
  setMarginTop: false,
};
