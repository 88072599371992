import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { media } from 'theme/breakpoints';
import quote from 'img/rocketlink/quote.svg';

export const Box = styled.div`
  background-color: transparent;
  padding: 48px;
  max-width: 964px;
  margin: 0 auto;
  position: relative;
  margin-bottom: 50px;
  border-radius: 4px;
  border: 2px solid #000;
  ${media.tablet} {
    padding: 30px;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-35%, -50%);
    background-image: url(${quote});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 72px;
    height: 72px;
    ${media.tablet} {
      width: 52px;
      transform: translate(0, -50%);
    }
  }
  p {
    font-size: 20px;
    line-height: 36px;
    margin: 0;
    font-style: italic;
    font-weight: 400;
  }
`;

export const Person = styled.div`
  display: flex;
  margin-top: 24px;
  align-items: center;
  p {
    flex-direction: column;
    display: flex;
    color: #7d7f81;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    strong {
      font-weight: 800;
      color: #000;
    }
    & span {
    }
  }
  a {
    color: inherit;
  }
  ${media.mobile} {
    margin-top: 40px;
  }
`;

export const Face = styled(GatsbyImage)`
  width: 41px;
  margin-right: 16px;
  border-radius: 50%;
`;
