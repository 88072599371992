import Layout from 'layout';
import Link from 'components/ui/link';
import { StyledSection } from 'styles/contact';

const Contact = () => (
  <Layout
    metaTitle="Contact Us"
    canonical="/contact/"
    metaDescription="Need anything from the LiveSession team? Feel free to get in touch! We’re ready to answer all your session replay questions."
  >
    <StyledSection>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 text-left p-4">
            <h2 className="mb-4" style={{ fontSize: '1.65rem' }}>
              Contact Us
            </h2>
            <h5>Support</h5>
            <p>
              <Link href="mailto:support@livesession.io">
                <strong>support@livesession.io</strong>
              </Link>
            </p>
            <h5>Sales</h5>
            <p>
              <Link href="mailto:sales@livesession.io">
                <strong>sales@livesession.io</strong>
              </Link>
            </p>
            <h5>Marketing</h5>
            <p>
              <Link href="mailto:marketing@livesession.io">
                <strong>marketing@livesession.io</strong>
              </Link>
            </p>
            <h5>Security</h5>
            <p>
              <Link href="mailto:security@livesession.io">
                <strong>security@livesession.io</strong>
              </Link>
            </p>
            <h5>Data Protection Officer</h5>
            <p>
              <Link href="mailto:dpo@livesession.io">
                <strong>dpo@livesession.io</strong>
              </Link>
            </p>
            <h5>General</h5>
            <p>
              <Link href="mailto:dpo@livesession.io">
                <strong>hello@livesession.io</strong>
              </Link>
            </p>
          </div>
          <div className="col-lg-6 text-left p-4">
            <h2 className="mb-4" style={{ fontSize: '1.65rem' }}>
              🇪🇺 Office
            </h2>
            <p>
              <strong>LiveSession Sp. z.o.o</strong>
            </p>
            <p>gen. Wladyslawa Sikorskiego 3/2</p>
            <p>Atal Towers</p>
            <p>56-659, Wroclaw, Poland</p>
            <p>VAT ID: PL8971860301</p>
          </div>
        </div>
      </div>
    </StyledSection>
  </Layout>
);

export default Contact;
