export const cards = [
  {
    title: 'Session replays',
    desc: 'Shorten the time needed to dig into essential data with LiveSession Filters. Watch only sessions worth watching.',
  },
  {
    title: 'Funnels',
    desc: `Organizing visitors' paths into funnels, you will be able to analyze your customers' journeys to purchase.`,
  },
  {
    title: 'Click & Heat Maps',
    desc: `Visualization of your website’s hottest sections will enable you to increase the number of actions.`,
  },
];
