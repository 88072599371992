import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import arrow from 'img/ui/arrow_black.svg';
import Link from 'components/ui/link';
import Layout from 'layout';
import SideBar from 'components/usability-testing/sidebar';
import { data as usabilityTestingData } from 'data/guides/usability-testing';
import { data as userExperienceData } from 'data/guides/user-experience';
import { data as heatmapsData } from 'data/guides/heatmaps';
import { data as productAnalyticsData } from 'data/guides/product-analytics';
import StartUsing from 'components/ui/startUsing';
import ShareBar from 'components/usability-testing/shareBar';
import OtherChapters from 'components/usability-testing/otherChapters';
import MobileDropdown from 'components/usability-testing/mobileDropdown';
import Button from 'components/ui/button';
import { formatText } from 'helpers/formatter';
import * as Styled from './styled';

const clusters = [usabilityTestingData, userExperienceData, heatmapsData, productAnalyticsData];

const PageContext = ({ render }) => {
  const { pathname } = useLocation();
  const currentCluster = pathname.split('/').filter(Boolean);
  const data = clusters.find((cluster) => cluster[0].url.includes(currentCluster[0]));
  const currentArticle = data.find(({ url }) => url === pathname);

  return (
    <Layout
      canonical={pathname}
      metaTitle={currentArticle.metaTitle || currentArticle.desc}
      metaDescription={currentArticle.metaDesc}
      image={currentArticle.cover}
    >
      <div className="container">
        <Styled.Breadcrumbs>
          <p>Resources</p>
          <img src={arrow} alt="arrow" title="Arrow" />
          <p>
            <Link href="/resources/guides/">Guides</Link>
          </p>
          <img src={arrow} alt="arrow" title="Arrow" />
          <p>
            <Link href={data[0].url}>
              {pathname === data[0].url ? (
                <strong>{formatText(currentCluster[0])}</strong>
              ) : (
                <>{formatText(currentCluster[0])}</>
              )}
            </Link>
          </p>
          {pathname !== data[0].url && (
            <>
              {' '}
              <img src={arrow} alt="arrow" title="Arrow" />
              <p>
                <Link href={currentArticle.url}>
                  <strong>{currentArticle.desc}</strong>
                </Link>
              </p>
            </>
          )}
        </Styled.Breadcrumbs>
        <Styled.MainContent>
          <Styled.SideBarBox>
            <SideBar list={data} pathname={pathname} />
          </Styled.SideBarBox>
          <div>
            <Styled.Header cluster={currentCluster[0]}>
              <h1>{currentArticle.desc}</h1>
              <Styled.Author cluster={currentCluster[0]}>
                <img
                  src={currentArticle.author.avatar}
                  alt="author"
                  title={currentArticle.author.authorName}
                  className="avatar"
                />
                <div className="media-body">
                  <span className="h6">{currentArticle.author.authorName}</span>
                  <span>
                    {currentArticle.date}
                    <Styled.ExtendTimeToRead>
                      {currentArticle.timeToRead} min
                    </Styled.ExtendTimeToRead>
                  </span>
                </div>
              </Styled.Author>
            </Styled.Header>
            <MobileDropdown pathname={pathname} data={data} />
            <div className="head-content">{currentArticle.heading}</div>
            {currentArticle?.button?.text && (
              <Button secondary signUp sourceID="head-content" className="action-btn">
                {currentArticle.button.text}
              </Button>
            )}
          </div>
        </Styled.MainContent>
        <Styled.Divider />
        <Styled.Content>
          <Styled.Article>
            <article>{render()}</article>
          </Styled.Article>
          <div style={{ gridArea: '1 / 1 / 2 / 2' }}>
            <ShareBar pathname={pathname} />
          </div>
        </Styled.Content>
        <OtherChapters pathname={pathname} data={data} />
      </div>
      <StartUsing />
    </Layout>
  );
};

PageContext.propTypes = {
  render: PropTypes.func.isRequired,
};

const withContentClusterTemplate = (Component) => (props) =>
  <PageContext render={() => <Component {...props} />} />;

export default withContentClusterTemplate;
