import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const Header = styled.header`
  padding: 90px 0 90px;
  width: 90%;
  margin: 0 auto;
  ${media.desktop} {
    padding: 216px 0 36px;
  }
  ${media.tablet} {
    padding: 126px 0 36px;
  }
`;

export const Title = styled.h1`
  text-align: center;
  margin: auto;
  max-width: 960px;

  ${media.tablet} {
    font-size: 28px;
    line-height: 38px;
  }
  &.line {
    span {
      &::after {
        /* background-color: #F8D047; */
      }
      ${media.tablet} {
        display: inline-block;
      }
    }
  }
`;

export const Description = styled.p`
  text-align: center;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  margin-top: 24px;
  ${media.tablet} {
    font-size: 18px;
    line-height: 23px;
  }
`;
