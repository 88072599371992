export const questions = [
  {
    question: 'How hard is it to switch from FullStory to LiveSession?',
    answer:
      'Not hard at all! You won’t have to change all your habits, as many features work the same way and the interface is quite similar overall.',
  },
  {
    question: 'Does LiveSession offer a free version?',
    answer: (
      <>
        There is a <strong>free 14-day trial</strong> available so you can see if LiveSession is the
        right choice for you. No credit card is required! After the trial, the prices start at $49
        per month.
      </>
    ),
  },
  {
    question: 'Is LiveSession more affordable than FullStory?',
    answer:
      'Definitely. First of all, FullStory doesn’t display the prices on the website. LiveSession has a transparent pricing policy – you know the price from the start and you know what you’re getting for your money. In the case of LiveSession, 5,000 sessions cost $49 per month. When it comes to FullStory, the price isn’t directly stated on the website. ',
  },
  {
    question: 'Is LiveSession going to slow my website down? ',
    answer:
      'No need to worry about that! The LiveSession script works asynchronously and it doesn’t influence your page’s speed performance.',
  },
];
