import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import { StyledButton } from 'components/ui/button';
import { StyledParagraph } from 'components/ui/Paragraph';
import { Modal } from 'reactstrap';

export const Section = styled.section`
  padding-top: 168px;
  padding-bottom: 168px;

  ${media.mobile} {
    padding-top: 120px;
  }
  ${StyledParagraph} {
    font-size: 21px;
    margin: 0 auto 16px;
    max-width: 790px;
    font-size: 21px;
  }
`;

export const ImageWrapper = styled.div`
  min-width: 588px;
  height: 573px;
  max-width: 1000px;
  justify-self: center;
  width: 100%;
  position: relative;
  transform: scale(0.95) perspective(1000px) rotateY(-11deg) rotateX(2deg) rotate(2deg);

  &::before,
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background-color: #f5f5f5;
    border-radius: 4px;
    height: 100%;
    width: 282px;
    z-index: -1;
    ${media.desktop} {
      width: 340px;
    }
  }
  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    background-color: #f8d047;
    border-radius: 4px;
    height: 100%;
    width: 282px;
    z-index: -1;
    ${media.desktop} {
      width: 340px;
    }
  }
  ${media.desktop} {
    transform: translateX(0);
  }
  ${media.tablet} {
    display: none;
  }
`;

export const Video = styled.video`
  display: none;
  min-height: 554px;
  max-width: 800px;
  margin: 0 auto;
  display: block;
  ${media.tablet} {
    min-height: auto;
  }
`;

export const CustomButton = styled(StyledButton)`
  margin: 0 auto 0;
  ${media.mobile} {
    margin: 0 auto 0;
  }
`;

export const PlayButton = styled.a`
  width: 182px;
  font-weight: 800;
  font-size: 16px;
  ${media.desktop} {
    width: 100%;
    text-align: center;
  }
  &:hover {
    color: #0a4ed6;
  }

  & svg {
    max-width: 12px;
    margin-right: 12px;
    height: 14px;
    & path {
      transition: 0.2s ease;
      fill: #0a4ed6;
    }
  }
`;

export const GridBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 25px;
  margin-bottom: 54px;
  & .gridbox-container {
    display: flex;
    padding-top: 64px;
    flex-direction: column;
    ${media.desktop} {
      padding-top: 0;
    }

    & .up-heading {
      font-size: 14px;
      line-height: normal;
      font-weight: 800;
      margin-bottom: 0;
    }

    .start {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      width: 100%;
      margin-top: 40px;
      & form {
        justify-content: flex-start;
        ${media.desktop} {
          justify-content: center;
        }
      }
      .checkmarks {
        margin: 20px 0 0;
        display: grid;
        grid-template-columns: repeat(3, max-content);
        grid-column-gap: 53px;
        ${media.tablet} {
          grid-column-gap: 18px;
        }
        ${media.desktop} {
          justify-content: center;
        }
        p {
          margin-bottom: 0;
          display: flex;
          font-size: 14px;
          position: relative;
          text-align: center;
          line-height: normal;
          & span {
            margin-left: 4px;
            ${media.mobile} {
              display: none;
            }
          }
          &::after {
            content: '';
            width: 5px;
            height: 5px;
            border-radius: 50%;
            position: absolute;
            background-color: #000;
            top: 50%;
            right: -29px;
            transform: translateY(-50%);
            ${media.tablet} {
              right: -11px;
            }
          }
          &:nth-child(3) {
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
  ${media.desktopWide} {
    grid-column-gap: 0;
  }
  ${media.desktop} {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, min-content);
    grid-row-gap: 60px;
    & .start form {
      margin-top: 0;
    }
    h1,
    p {
      text-align: center;
    }
  }
  ${media.tablet} {
    & .start form label {
      width: auto;
    }
  }
  ${media.mobile} {
    grid-row-gap: 40px;
    width: 100vw;
    padding: 0 15px;
    & .checkmarks {
      justify-content: center;
    }
    & .start form {
      margin-top: 0;
      & label {
        width: 100%;
      }
    }
  }
`;

export const Checkmarks = styled.ul`
  list-style: none;
  display: flex;
  width: 100%;
  padding: 0;
  margin-top: 24px;
  & li {
    color: #808080;
    font-size: 14px;
    font-weight: 400;
    margin-right: 48px;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -26.5px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #808080;
    }
    &:last-child {
      margin-right: 0;
      &::after {
        display: none;
      }
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto 40px;
  &.secondary {
    & ${PlayButton} {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & ${CustomButton} {
      margin: 0 auto 0;
      width: 182px;
      ${media.mobile} {
        margin: 0 auto;
        width: 140px;
        padding: 12px 14px;
      }
    }
  }
  & .wistia_embed {
    & .wistia_click_to_play {
      padding: 0;
    }
    & a {
      &:hover {
        text-decoration: none;
      }
    }
  }
  ${media.mobile} {
    margin: 0 auto 16px;
    padding: 0 !important;
  }
`;

export const CustomModal = styled(Modal)`
  position: fixed;
  top: 0;
  left: 50%;
  width: 100vw;
  max-width: 900px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  &.modal-dialog {
    transform: translate(-50%) !important;
    ${media.mobile} {
      margin: 0;
    }
  }
`;

export const CloseButton = styled.button`
  border: none;
  padding: 12px;
  background-color: #eee;
  border-radius: 50%;
  position: absolute;
  right: -20px;
  top: -20px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;
