import withUsabilityTestingTemplate from 'hoc/content-cluster';
import Link from 'components/ui/link';
import { StaticImage } from 'gatsby-plugin-image';
import arrow from 'img/ui/arrow_white.svg';
import Banner from 'components/usability-testing/banner';
import { faq2 } from 'data/heatmaps/faq2';
import FAQ from 'components/FAQ';

const WhatIsAHeatMap = () => (
  <>
    <h2>What is a heat map?</h2>

    <p>
      A heat map is a visual representation of data, which displays numeric values as color schemes.
      It enables brands to monitor and record all actions that visitors perform on a website based
      on their mouse movements.{' '}
    </p>

    <p>
      If used and interpreted the right way, it can be a data gold mine, and give you incredible
      insights into customer behavior and enable you to understand your customers better. Heat map
      analysis can help you detect frustrated users, give insights to improve your conversion rate
      optimization, enhance your conversion funnel and deliver bespoke customer journey knowledge to
      product teams.
    </p>

    <p>
      Now that you know what a heat map is, you are likely wondering – how does it work exactly?
    </p>

    <h2>Heat map colors – what do they mean, and how can you use them to your advantage?</h2>

    <p>
      A heat map represents complex data through the use of color. Various color ranges provide the
      visual interpretation of a user journey. Essentially, it is like a road map of how users
      navigate your website. It allows you to track mouse cursor and to determine whether users make
      use of the action button or if they scroll through pages or hover on certain areas.{' '}
    </p>

    <p>Let’s take a look at how colors can guide you through interpreting user data.</p>

    <h3>Hot to cold: see your heat maps as thermostats for engagement!</h3>

    <p>
      Typically, a heat map guides your data analysis by indicating areas that are “hot” (which
      would be your most popular areas and content) and “cold” (which is content that is the least
      interacted with). Warmer color ranges such as reds and yellows indicate the sections on your
      website that get the most interactions, while cooler color ranges, such as blues and greens
      show areas with lesser engagement.
    </p>
    <StaticImage
      src="../../img/heatmaps/what-is-a-heat-map/heatmap.png"
      alt="heat map colours as shown by LiveSession"
      title="Heatmaps by LiveSession"
    />
    <p>
      Your heat map color ranges’ cues can be a powerful heat map tool if you need to determine
      potential reasons for drop-offs in engagement and if you need to assess how your user
      interface optimization is conducted, or content is performing.
    </p>
    <h2>Types of heat maps and how they can help you improve your business</h2>

    <p>
      Now that you know what a heat map is, let’s take a look at different heat map examples and
      their benefits. Here are some heat map examples to consider:
    </p>

    <h3>1. Scroll maps</h3>

    <p>
      It is no secret that people’s attention spans are getting shorter – according to a study by{' '}
      <Link
        href="https://www.digitalinformationworld.com/2018/09/the-human-attention-span-infographic.html#:~:text=The%20average%20human%20attention%20span,scene)%20to%20eight%20seconds%20today."
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Digital Information World
      </Link>
      , it dropped from 12 seconds in the year 2000, to just 8 seconds in 2018. This also means that
      people spend less time on certain website sections, or even click away or close a screen if
      they aren’t interested from the get-go.
    </p>

    <p>
      This is where a scroll heatmap comes in. This heat map example is the ultimate digital heatmap
      tool for businesses with eCommerce or retail websites, as it shows how far users scroll down
      your website. For instance, if your heat map indicates that visitors don’t scroll past the
      fold or midline of your website, you need to ensure that your most valuable content that will
      hook them is displayed right at the top.{' '}
    </p>

    <p>
      Also, let’s assume, you have a competitively priced product in the top section of your
      website, such as an air-conditioning unit that has a higher price than other units, but comes
      with more benefits than most. You need the section of content that explains the advantages of
      this product to be placed above the fold to ensure users will not miss it if your heat map
      data indicates that below the fold is where drop-offs happen.
    </p>

    <p>
      So, interpret your users’ interactions with your website and adjust your content accordingly
      to ensure success!
    </p>
    <StaticImage
      src="../../img/heatmaps/what-is-a-heat-map/scrollmap.png"
      alt="scrollmap as shown by LiveSession"
      title="Scrollmap by LiveSession"
    />
    <h3> 2. Click maps</h3>

    <p>
      Have you ever wondered how you can track click data on your website? A click map can help you
      assess this data and make an informed decision on layout changes, supported by your analytics
      data.
    </p>

    <p>
      This heat map example indicates via its color cues what your website’s click frequencies are.
      Blue colors show those areas that are getting fewer clicks, reds signify more clicks, and
      bright white and yellow is where your users are spending most of their time.
    </p>

    <p>
      So, how can you use this data to your advantage? If users are, for instance, clicking on
      certain elements, such as blog or product images that aren’t hyperlinked to other content,
      consider making them more interactive.{' '}
    </p>

    <p>
      Another example is looking at your top menu bar. Are certain sections getting more clicks than
      others? Could this be due to the words you use or uncertainty of what a section entails, such
      as “our work” versus “our clients”? Using your click data can reveal several areas of
      improvement for your website and enable you to optimize your site’s user experience!
    </p>
    <StaticImage
      src="../../img/heatmaps/what-is-a-heat-map/clickmap.png"
      alt="clickmap as shown by LiveSession"
      title="Clickmap by LiveSession"
    />
    <Banner
      title="Improve your checkout process based on data that really matters"
      desc="Find out when your Customers gets frustrated and improve your eCommerce UX. Test all LiveSession features for 14 days, no credit card required."
    />

    <h2>Improve your checkout process based on data that really matters</h2>

    <p>
      Find out when your Customers gets frustrated and improve your eCommerce UX as well as
      conversion rates. Test all LiveSession features for 14 days, no credit card required.
    </p>

    <Link href="/signup/?source_id=cta-banner" target="_blank" rel="noopener noreferrer nofollow">
      Sign up free
    </Link>
    <h3>3. Attention maps</h3>

    <p>
      This heat map example might sound similar to scrolling maps, but the key difference is that it
      highlights which sections of your website are getting the most attention from users. It also
      takes into account how users are navigating your website vertically and horizontally.
    </p>

    <p>
      For a streaming platform, this can, for instance, indicate if people are viewing certain areas
      or spending more time on others – which could be a sign of whether an interface needs an
      update to make content more visible and easier to digest.
    </p>
    <StaticImage
      src="../../img/heatmaps/what-is-a-heat-map/attentionmap.png"
      alt="Attention map as shown by LiveSession"
      title="Attention map by LiveSession"
    />
    <h3>4. Move/hover maps</h3>

    <p>
      Move or hover map can assist in indicating a user’s mouse movement or hover activity on your
      website. However, this data is not always concrete. According to research findings by
      <Link
        href="https://www.simpleusability.com/inspiration/2011/01/mouse-eye-tracking-how-useful-is-it/"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Dr Anne Aula
      </Link>
      , Senior User Experience Researcher at Google, 6% of people showed some vertical correlation
      between mouse movement and eye-tracking.
    </p>

    <p>
      Does this mean you should disregard move or hover maps? Not necessarily. There are some
      benefits to using this heat map, such as observing users across the globe in their natural
      environment and getting a low-cost solution to track data. However, as a word of caution,
      consider your data sources as a whole. This will help inform your decisions when it comes to
      adjusting your website content.
    </p>
    <h3> 5. Eye-tracking maps</h3>

    <p>
      The data for this heat map example is usually gathered in user testing labs where
      participants’ eye movements are tracked as they navigate your website. These tests typically
      require tracking devices, but even webcams can be used in these studies.
    </p>

    <p>
      The benefit of eye-tracking heat maps is that it will not only assist you in determining the
      areas of your website that got the most attention, but also the time users spent looking at
      certain content. This will provide insight into where you can improve your offering and help
      retain users at key points where there might be drop-offs. If you, for instance, have an
      online learning platform where people are not interacting with your search bar or not hovering
      for prolonged periods on course content sections, it could indicate that you need to update
      these sections to drive better conversion rates.
    </p>
    <StaticImage
      src="../../img/heatmaps/what-is-a-heat-map/eye.png"
      alt="eye tracking map on wikipedia website"
      title="Eye-tracking maps"
    />
    <h3>6. Confetti reports</h3>

    <p>
      A confetti report is a powerful heat map example with two great benefits: it serves you data
      of where your website users are clicking and how they navigate your website. The pattern
      insights that these types of reports provide will help you to improve your website offering
      and your engagement with your users.
    </p>

    <p>
      The types of data that you can gather with confetti reports include new and returning visitors
      (such as frequent buyers returning to an eCommerce shop versus new customers), current
      campaigns that you are running (such as an online promotion for Valentine’s Day or Black
      Friday) or even which days of the week visitors are coming to your site, to name a few. You
      can collect user behavior data from both mobile devices and desktops, which means you will be
      able to use this data to determine if your website is responsive enough on all platforms.
    </p>
    <StaticImage
      src="../../img/heatmaps/what-is-a-heat-map/confetti.png"
      alt="confetti report"
      title="Confetti reports"
    />
    <h2 style={{ marginTop: 80 }}>What is a heat map – final thoughts</h2>

    <p>
      Heat maps can provide exceptional value to your business. Heatmapping tools can help you
      achieve a client-centered approach to the digital content you serve to users – based on their
      behaviors and preferences. If so far you’ve relied only on Google Analytics in terms of
      analytics tools, even a basic type of heat map can help you see increase in conversions, lower
      bounce rate.
    </p>

    <p>
      Your heat maps will reveal the data and critical elements you need to improve, whether you
      need better visuals, have more or less contrast on your pages, or need to reshuffle content to
      help it make more sense to your end-users.
    </p>

    <p>
      Ultimately, heat mapping can assist in growing your business through a targeted approach that
      improves your content and delights your visitors, from start to finish.
    </p>

    <FAQ questions={faq2} style={{ paddingTop: '120px' }} />

    <div className="next-chapter">
      <Link href="/heatmaps/generating-heat-maps/">
        <div>
          <p>Chapter 2</p>
          <p>How to create a heat map - best practices & mistakes to avoid</p>
        </div>
        <img src={arrow} alt="arrow" title="white arrow" />
      </Link>
    </div>
  </>
);

export default withUsabilityTestingTemplate(WhatIsAHeatMap);
