import Button from 'components/ui/button';
import * as Styled from './styled';

const ScheduleDemo = () => (
  <Styled.ScheduleDemoContainer>
    <h3>Didn&apos;t find the answer to your question? </h3>
    <p>
      Write to us at <a href="mailto:hello@livesession.io">hello@livesession.io</a>, we will try to
      answer your questions. <br /> You can also schedule a demo:
    </p>
    <Button href="/demo/">Schedule a demo</Button>
  </Styled.ScheduleDemoContainer>
);

export default ScheduleDemo;
