import withUsabilityTestingTemplate from 'hoc/content-cluster';
import Link from 'components/ui/link';

const UXImprovements = () => (
  <>
    <h2>11 UX improvements you can achieve with heat maps</h2>
    <h3>#1 An 8% drop in bounce rate thanks to a smarter, more user-friendly design</h3>
    <p>
      Chris Anderson, Owner and SEO Analyst at{' '}
      <Link href="https://myfiveguide.com/" target="_blank" rel="noopener noreferrer nofollow">
        MyFiveGuide
      </Link>{' '}
      – curated content on Health and Fitness
    </p>
    <p>
      For my website, MyFiveGuide.com, heat maps helped me develop a smarter and user-centric
      design. When I first implemented the heat map, I noticed that a high percentage of users were
      not scrolling to my “Popular Posts” section on the homepage. After making some web visual
      design changes, I positioned the section in a more visible area for users to find. As a
      result, I noticed on the heat map immediately that users were spending an increasing amount of
      time in that section after the change. The heat map allowed me to find this issue in my
      website architecture.{' '}
    </p>
    <p>
      This resulted in an<strong>8% drop in website bounce rate and 17% more views</strong> to posts
      in the “Popular Posts” section.
    </p>
    <h3>#2 A variety of UX design improvements, leading to higher user engagement</h3>
    <p>
      Michael Saly, CEO at{' '}
      <Link href="https://aspiremedia.ie/" target="_blank" rel="noopener noreferrer nofollow">
        Aspire Media
      </Link>{' '}
      – a web design & SEO company
    </p>
    <p>
      It is not easy to design an application that can directly attract a user and convince them to
      download it immediately. In the case of music streaming apps, they are not so hard to find,
      but people tend not to install them and rather search for their favorite songs online through
      free platforms. And this really doesn’t help a website’s user experience.
    </p>
    <p>But when we implemented a website heat map, it was a BULLSEYE. Among others:</p>
    <ul>
      <li>
        We experienced a great decrease in the website’s average bounce rate by making the user
        experience more convenient as per their needs.
      </li>
      <li>
        We gained knowledge about how our CTAs were impacting our target audience, and, after a few
        modifications, they were pointing towards the right targeted locations.
      </li>
      <li>
        After getting a visual website heat map, we discovered some of the website’s unpopular areas
        including about us, advertising, and some others that needed real attention to work upon
      </li>
      <li>
        Website engagement rates escalated as we were clear about our potential customer’s needs and
        what their search intent was.
      </li>
      <li>
        Furthermore, the website heat map really helped in developing our mobile application to be
        more mobile user-friendly based on what they wanted to see.
      </li>
    </ul>
    <h3>
      #3 A 23% higher conversion rate from the homepage thanks to website experience improvements
    </h3>
    <p>
      Kristian Borghesan, Director of Marketing at{' '}
      <Link href="https://savology.com" target="_blank" rel="noopener noreferrer nofollow">
        Savology
      </Link>{' '}
      – an online financial planning platform{' '}
    </p>
    <p>
      We’ve used heat maps dozens of times in the past, and continue to use them as a way to help us
      identify user interaction points and ways to better optimize the website experience. We use
      heat maps both on our marketing site as well as directly in our application.{' '}
    </p>
    <p>
      {' '}
      In particular, on our homepage, we discovered three primary &apos;sticking&apos; points where
      our website visitors were spending 80% of their time without taking any further action. In
      this case, we tried a few different things like moving around the order of the content
      sections, as well as introducing CTAs in those respective areas. The results were a lift in
      pages visited per user as well as a <strong> 23% increase in conversion rate</strong> from the
      homepage
    </p>
    <h3>
      #4 Increasing session duration by 45 seconds and boosting the number of leads by 10% by moving
      relevant content up
    </h3>
    <p>
      Stewart Dunlop, CEO at{' '}
      <Link href="https://linkbuilder.io/" target="_blank" rel="noopener noreferrer nofollow">
        LinkBuilder.io
      </Link>{' '}
      – a link building services agency
    </p>
    <p>
      Previously, I kept the clients’ feedback responses section at the bottom of the products page,
      after all of the other info. I thought that it wasn’t very popular among the website visitors.
      However, then I decided to experiment with the website components by switching their places.
      Installing a heat map helped me realize that people not only stop briefly for viewing the
      comments, but also click the slider to read all of them. The heat map revealed that the
      section with feedback attracted equal attention to the service pricing.
    </p>
    <p>
      For this reason, I brought the comments up before the pricing, and it worked. Now, when
      website visitors land on the page, they have a chance to see which big companies trust us,
      then read about our services and check the positive experiences from our clients. This helps
      convince leads to make the first step and contact us. Surprisingly, this switch{' '}
      <strong>
        increased the average session duration by 45 seconds and brought us 10% more leads
      </strong>
      . So, it’s interesting to experiment with heat maps from time to time to find inspiration for
      some minor tweaks that could bring in more positive results as far as the user experience is
      concerned.
    </p>
    <h3>#5 Nearly 2x longer user sessions</h3>
    <p>
      John Ross, CEO at{' '}
      <Link href="https://testprepinsight.com/" target="_blank" rel="noopener noreferrer nofollow">
        Test Prep Insight
      </Link>{' '}
      – a US-based online education company offering exam test prep reviews
    </p>
    <p>
      Oftentimes, optimizing your site for user experience design can be a complete shot in the
      dark, where you’re just going off your gut for what you think will perform well with users.
      However, after using this approach for a couple years,{' '}
      <strong>
        we used heat maps to do some A/B testing on different page layouts to see which would
        perform better
      </strong>
      . We built two totally separate landing pages, with almost inverse layouts. Then we ran a heat
      map test after reaching a few thousand page views to see where users were clicking, scrolling,
      and ultimately dropping off the page.{' '}
    </p>
    <p>
      This gave us an awesome idea of the on-page layout changes that were needed, and we actually
      ended up combining elements from both landing pages to make a hybrid of sorts. I never would
      have thought to go with the final design that we used had it not been for the heat maps. We
      then ran another heat map test on the final landing page, and the metrics were fantastic.{' '}
      <strong>
        Our original single-page session durations prior to any redesign were about 1 minute 6
        seconds. After the redesign with heat map optimization, they were nearly 2 minutes (1 minute
        53 seconds).
      </strong>{' '}
    </p>
    <h3>#6 Reduced checkout abandonment</h3>
    <p>
      Kennedy from{' '}
      <Link
        href="https://www.emailmarketingheroes.com/"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Email Marketing Heroes{' '}
      </Link>{' '}
      – an email marketing podcast{' '}
    </p>
    <p>
      We found heat maps extremely useful when launching a new offer on our site. We wanted to see
      how the new page converted and what was stagnating sales.{' '}
    </p>
    <p>
      We noticed that a lot of people were clicking the ‘Buy Now’ link at the top of the page before
      the product price was even displayed to them. This resulted in them going to the first step of
      a 2-step checkout and ending up in our Cart Abandonment sequence.
    </p>
    <p>
      Instead of checking out, they simply wanted to know the price. You see, our sales page has a
      number of buttons that someone can click to begin checking out. However, we did not introduce
      the price until around halfway down the page.
    </p>
    <p>
      Using heat map technology, we noticed people were clicking on the Buy button near the top of
      the page, completing step 1 of the check out process (giving us their email address), and
      abandoning. This led us to the conclusion that they were only clicking to see the price.
    </p>
    <p>
      Based on that, we changed the page so that all buttons on it before the price is mentioned
      simply scroll to the price instead of commencing checkout. This has reduced checkout
      abandonment significantly.
    </p>
    <h3>#7 Improved website engagement and boosted conversion goal by 300%</h3>
    <p>
      Brenton Thomas, Founder at{' '}
      <Link href="https://twibiagency.com/" target="_blank" rel="noopener noreferrer nofollow">
        Twibi
      </Link>{' '}
      – a Digital Marketing agency
    </p>
    <p>
      I’ve used heat maps to assess landing page engagement. I was driving traffic sources to gated
      content to generate lead gen interest for a SaaS-related product. After enough data had been
      collected to review the heat maps, it was apparent that prospects didn’t engage with most of
      the content on the landing page. They only engaged with the form and a few other elements on
      the page. As a result, I stripped away <strong>80% of the content</strong>, streamlining the
      page. The outcome was a <strong>300% increase in the conversion rate</strong> , and it became
      my new default landing page to compete against future variations.{' '}
    </p>
    <h3> #8 Reducing the bounce rate by 27%, increasing engagement and adoption by 22%</h3>
    <p>
      Ray McKenzie, Founder and CEO of{' '}
      <Link href="https://www.startingpoint.ai" target="_blank" rel="noopener noreferrer nofollow">
        StartingPoint
      </Link>{' '}
      – a SaaS customer operations and experience platform for service-based companies
    </p>
    <p>
      As a SaaS platform, real customer experience is something we value a lot. Especially since we
      are a customer operations and experience platform. Utilizing site heat maps has helped us
      streamline multiple areas of our business. This includes our commercial website, but also our
      SaaS platform.
    </p>

    <p>
      As an early-stage startup, visits to our website experienced a bounce rate of more than 72%.
      We needed to increase the engagement on our site. We needed to make visitors want to see more
      and stay longer. We used heat maps to help direct us to visually change the content presented
      and in an order that made sense for our visitors.{' '}
    </p>
    <p>
      Our current website bounce rate is approximately 45%, and we are continuing to improve this.
      We also use heat maps inside our product for streamlined efficiency. We want our customer
      experience to be intuitive, simple, and lean. By recreating the steps our customers take and
      seeing what they focus on, we were able to do this,{' '}
      <strong>increasing engagement and adoption by more than 22%</strong>. We are just starting
      off, but heat maps are essential to our product and company growth.
    </p>
    <h3>#9 Optimized sign-up to reduce form fatigue</h3>
    <p>
      Dan Bailey, President at{' '}
      <Link
        href="https://www.wikilawn.com/lawn-care/ca/los-angeles/"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        WikiLawn Los Angeles Lawn Care
      </Link>{' '}
      – Lawn care services’ marketplace
    </p>
    <p>
      We actually used heat maps to optimize our site and address issues. We were noticing a
      drop-off in sign-ups and had to identify where that was happening. Once we isolated the bounce
      rate, we discovered that people were making it to the signup page and then just falling off.
    </p>
    <p>
      We used heat maps to determine where the focus was on the form and when that was happening,
      and we discovered that we were exacerbating the &quot;form fatigue&quot; phenomenon.
      Essentially, we were asking too many detailed questions. There was no reason that we
      absolutely needed to ask for the amount of information we were asking for. The heat map helped
      us determine where people were exiting the page and where they were spending the most time,
      which then let us address those issues and improve our signup rate.
    </p>
    <h3>#10 Optimizing content layout for a 9% higher conversion rate</h3>
    <p>
      Alex Willen, Owner of{' '}
      <Link href="https://coopersdogtreats.com/" target="_blank" rel="noopener noreferrer nofollow">
        Cooper&apos;s Treats
      </Link>{' '}
      – healthy frozen snacks for puppies{' '}
    </p>
    <p>
      e set up some heat mapping software on our site, and the one really useful thing we discovered
      was that a lot of homepage visitors were scrolling all the way down to the footer and clicking
      on the &quot;
      <Link
        href="https://coopersdogtreats.com/pages/our-mission"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Our Mission
      </Link>
      &quot; link. When we checked the data on Google Analytics, it turned out that people who
      landed on the homepage and clicked that link then subsequently went to a product page and were
      about 14% more likely to purchase than those going to a product page immediately after
      visiting the homepage.
    </p>
    <p>
      We moved the link from our footer to our header menu, and we ended up seeing about a 9%
      increase in the conversation rate from the 30 days prior to the change to the 30 days after.
      We saw visits to the &quot;Our Mission&quot; page increase by over 200%, and people who went
      there and then to a product page continued to purchase at a higher rate.
    </p>
    <h3>
      #11 Increasing the conversion rate, leading to 28 more leads and 2 more clients per week
    </h3>
    <p>
      Shawn Breyer, Owner of{' '}
      <Link href="http://www.thehivelaw.com" target="_blank" rel="noopener noreferrer nofollow">
        The Hive Law
      </Link>{' '}
      – virtual divorce attorneys and estate planning lawyers
    </p>
    <p>
      When we used heat maps for our website, we noticed that 8.7% of our clicks were going to our
      ‘About Us’ page. Before we noticed this, we had not spent much time building up our
      credibility. Our company page was basically blank with a generic blurb. We added photos, wrote
      out small descriptions about us, added our core values, and wrote what we stand for. Since the
      change, our amount of clicks on the About Us page has remained the same, but the number of
      conversions on the website has increased. Our conversions before the About Us page
      optimization was roughly 3.9%. After that, our conversion actions went up to 5.3%. That
      allowed us to get 28 more leads per week, which was another 2 clients every 7 days.
    </p>
    <h2>Summary</h2>
    <p>
      By using a heat map on your website, you can gain a variety of visitor insights that will help
      with UX improvements. As mentioned in the examples above, by using and analyzing the data from
      your{' '}
      <Link href="/heatmaps/what-is-a-heat-map/" target="_blank" rel="noopener noreferrer">
        heat maps
      </Link>
      , you can:
    </p>
    <ul>
      <li>increase conversion rate on your landing pages</li>
      <li>lower your average bounce rate</li>
      <li>boost user engagement</li>
      <li>streamline the customer journey</li>
      <li>reduce cart abandonment</li>
      <li>and much more</li>
    </ul>
    <p>
      Interested in learning more about the power of heat maps? Be sure to check out our dedicated
      guide.
    </p>
  </>
);

export default withUsabilityTestingTemplate(UXImprovements);
