import PropTypes from 'prop-types';
import styled from 'styled-components';
import { renderStars } from 'components/CustomersRate';
import Button from 'components/ui/button';
import { customers } from 'data/pricing/customers';
import Link from 'components/ui/link';
import { media } from 'theme/breakpoints';

const RateBox = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 240px);
  grid-gap: 40px;
  justify-content: center;
  margin: 80px 0;
  ${media.tablet} {
    margin: 40px 0 80px 0;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, 213px);
  }
`;

const Section = styled.section`
  padding-bottom: 240px;
  margin-bottom: 200px;
  border-bottom: 1px solid #e4e5e8;
  &.hero-page {
    margin-bottom: 0;
    padding-bottom: 48px;
    border-bottom: none;
    .button-signup {
      display: none;
    }
    ${RateBox} {
      margin-bottom: 0;
    }
    ${media.tablet} {
      padding-bottom: 68px;
    }
  }
  ${media.tablet} {
    padding-bottom: 100px;
    margin-bottom: 64px;
  }
  ${media.mobile} {
    margin-bottom: 100px;
  }
  & h2 {
    text-align: center;
    ${media.tablet} {
      font-size: 28px;
      line-height: 42px;
    }
  }
  & .button-signup {
    margin: 0 auto;
    display: block;
    width: 240px;
    ${media.tablet} {
      width: min-content;
    }
  }
`;

const Rate = styled.div`
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 40px 0;
  width: 240px;
  height: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  ${media.tablet} {
    width: 100%;
    justify-content: center;
    & a {
      margin-top: 40px;
    }
  }
  & .stars {
    margin-top: 20px;
    & img {
      width: 14px;
      margin-right: 4px;
    }
    & p {
      font-size: 14px;
      margin: 0;
      margin-left: 7px;
    }
    display: flex;
    align-items: center;
  }
  & div.content-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    & .logo {
      max-height: 42px;
    }
  }
`;

const CustomersRate = ({ className, sectionTitle }) => (
  <Section className={className}>
    <h2>{sectionTitle || 'But wait - there’s much more!'}</h2>
    <RateBox>
      {customers.map(({ logoBlack, mark, link, title }) => (
        <Rate key={title}>
          <div className="content-box">
            <img src={logoBlack} alt="logo" title={title} className="logo" style={{ height: 28 }} />
            <div className="stars">
              {renderStars(mark)}{' '}
              <p>
                <strong>{mark}</strong>/5
              </p>
            </div>
          </div>
          <Link withArrow href={link} target="_blank" rel="noopener noreferrer">
            Read more now
          </Link>
        </Rate>
      ))}
    </RateBox>
    <Button signUp secondary sourceID="customers-rate" className="button-signup">
      Sign up free
    </Button>
  </Section>
);

export default CustomersRate;

CustomersRate.propTypes = {
  className: PropTypes.string,
  sectionTitle: PropTypes.string,
};

CustomersRate.defaultProps = {
  className: '',
  sectionTitle: undefined,
};
