import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

export const Content = styled.div`
  padding: 35px 90px 42px;
  background-color: #d45c39;
  color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  & h2 {
    font-weight: 800;
    font-size: 24px;
    line-height: 30px;
  }
  & p {
    margin: 8px 0 0;
    font-size: 12px;
  }
`;

export const StyledImg = styled(StaticImage)`
  width: 200px;
  height: auto;
`;
