import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const Dropdown = styled.div`
  display: none;
  ${media.tablet} {
    display: block;
  }
`;

export const CurrentCategory = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: none;
  background-color: transparent;
  padding: 0;
  & .icon {
    width: 16px;
    height: 9px;
    transition: transform 0.2s ease;
  }
  &.active {
    & .icon {
      transform: rotate(180deg);
    }
  }
  & div {
    display: flex;
    align-items: center;
    & svg {
      width: 24px;
      height: 24px;
      margin-right: 16px;
      & .yellow {
        fill: #f8d047;
      }
      & .blue {
        fill: #7dffef;
      }
      & .purple {
        fill: #8765f9;
      }
    }
    & p {
      display: block;
      font-size: 16px;
      line-height: 24px;
      color: #000;
      transition: none;
      margin: 0;
      cursor: pointer;
      &.active {
        font-weight: 800;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
`;

export const Category = styled.li`
  display: flex;
  width: 100%;
  margin: 24px 0;
  & svg {
    width: 24px;
    height: 24px;
    margin-right: 16px;
  }
  & a {
    display: block;
    font-size: 16px;
    line-height: 24px;
    color: #000;
    transition: none;
    cursor: pointer;
  }
`;

export const List = styled.ul`
  padding: 0;
  margin: 0;
  ${Category}:last-child {
    margin-bottom: 0;
  }
`;
