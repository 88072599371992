/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Tippy from '@tippyjs/react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { FeatureConditions } from 'data/pricing/features';
import checkmark from 'img/ui/checkmarkBlue.svg';
import { showPlans } from 'data/pricing/plans';
import cross from 'img/ui/cross.svg';
import MoreInfo from 'img/ui/moreInfoBlack.inline.svg';
import * as Styled from './styled';

const TableMobile = ({
  privacy,
  sessionReplays,
  analytics,
  features,
  billingAndService,
  usage,
}) => {
  const [currentPage, setCurrentPage] = useState(0);

  const integrations = [
    'Sentry',
    'Segment',
    'Google Analytics',
    'Shopify',
    'Google Optimize',
    'Survicate',
  ];

  const premiumIntegrations = features.find(
    (item) => item.key === 'premium_integrations',
  )?.integrations;

  const allIntegrations = premiumIntegrations.concat(integrations);

  const handleClick = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);
  };

  const pages = showPlans.map((item, i) => ({
    plan: item,
    name: item.charAt(0).toUpperCase() + item.slice(1),
    key: i,
  }));

  const renderList = (list, featureList, title) => {
    const renderContent = (feature, plan) => {
      const planFeatures = featureList[feature];
      if (!planFeatures || feature === 'premium_integrations' || feature === 'integrations') {
        return null;
      }
      const feat = planFeatures[plan];

      if (typeof feat !== 'undefined') {
        if (typeof feat === 'boolean') {
          return feat ? <img src={checkmark} alt="checkmark" /> : <img src={cross} alt="cross" />;
        }

        return feat;
      }

      return null;
    };
    const finalList = list
      .filter((item) => {
        if (item.key === 'integrations') return null;
        if (item.key === 'premium_integrations') return null;
        return features;
      })
      .filter(Boolean);
    const parsedFeatures = finalList
      .map((item) => {
        if (item.soon) return null;
        return (
          <li key={item.key}>
            <p>
              {item.description ? (
                <Tippy content={item.description} placement="bottom-start">
                  <div>
                    <span>{item.name} </span>
                    <MoreInfo />
                  </div>
                </Tippy>
              ) : (
                <span>{item.name} </span>
              )}
            </p>
            {renderContent(item.key, pages.find((page) => page.key === currentPage).plan)}
          </li>
        );
      })
      .filter(Boolean);
    return [<li className="title">{title}</li>, ...parsedFeatures];
  };

  const renderIntegrations = (featureList, integrationsList) => {
    const activePage = pages.find((page) => page.key === currentPage).plan;
    const planFeatures = featureList.premium_integrations[activePage];
    const finalList = planFeatures ? allIntegrations : integrations;
    return integrationsList.map((item) => (
      <li>
        <p>{item}</p>{' '}
        {finalList.indexOf(item) > -1 ? (
          <img src={checkmark} alt="checkmark" />
        ) : (
          <img src={cross} alt="cross" />
        )}
      </li>
    ));
  };

  return (
    <Styled.List>
      <Styled.PaginationWrapper className="pagination-wrapper">
        <Pagination aria-label="Page navigation">
          {pages.map((page) => (
            <PaginationItem active={page.key === currentPage} key={page.key}>
              <PaginationLink onClick={(e) => handleClick(e, page.key)} href="#">
                {page.name}
              </PaginationLink>
            </PaginationItem>
          ))}
        </Pagination>
      </Styled.PaginationWrapper>
      <Styled.StyledList>
        {renderList(usage, FeatureConditions.Usage, 'Usage')}
        {renderList(
          sessionReplays,
          FeatureConditions.SessionReplays,
          'Session replays & Clickmaps',
        )}
        {renderList(analytics, FeatureConditions.Analytics, 'Analytics')}
        {renderList(features, FeatureConditions.Other, 'Customization')}
        {renderList(privacy, FeatureConditions.Privacy, 'Privacy')}
        {renderList(billingAndService, FeatureConditions.BillingAndService, 'Billing & Service')}
        <li className="title">Integrations</li>
        {renderIntegrations(FeatureConditions.Other, allIntegrations)}
      </Styled.StyledList>
    </Styled.List>
  );
};

export default TableMobile;
