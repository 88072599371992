import React, { useEffect, useState } from 'react';
import Layout from 'layout';
import { useLogoCompanies } from 'hooks/useLogoCompanies';
import RegisterForm from 'components/signup/register-form';
import logoDark from 'img/LogoLS/logo_livesession_dark.svg';
import { StaticImage } from 'gatsby-plugin-image';
import talentlyft from 'img/companyLogo/talentlyft-small.svg';
import { getTrackingValues } from 'services/tracking';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Cookies from 'js-cookie';
import { Logo, UsingLS, SignupSection, FormContainer, PromoCode } from 'styles/signup';

const SignUp = () => {
  const { databox, kissflow, currenxie, trail, ing, renmoney } = useLogoCompanies();

  const [isPromoActive, setData] = useState('');
  const [currency, setCurrency] = useState(Cookies.get('ls_currency') || 'usd');

  useEffect(() => {
    const data = getTrackingValues();
    if (!isPromoActive && data) {
      setData(data);
    }
  }, [isPromoActive]);

  useEffect(() => {
    if (Cookies.get('ls_currency')) {
      setCurrency(Cookies.get('ls_currency'));
    } else {
      fetch('https://freegeoip.live/json/')
        .then((res) => res.json())
        .then((res) => {
          if (res?.time_zone.includes('Europe')) {
            setCurrency('eur');
            Cookies.set('ls_currency', 'eur');
          } else {
            Cookies.set('ls_currency', 'usd');
            setCurrency('usd');
          }
        });
    }
  }, []);
  return (
    <Layout
      hideFooter
      hideHeader
      metaTitle="Signup to LiveSession"
      canonical="/signup/"
      blockScrollX
      noIndex
    >
      <Logo to="/">
        <img src={logoDark} alt="logo" title="LiveSession - logo" />
      </Logo>
      <UsingLS>
        <p>
          Already have an account? <a href={`${process.env.GATSBY_APP_URL}`}>Log in</a>
        </p>
      </UsingLS>
      <SignupSection>
        <div className="wrapperLogin">
          <FormContainer className="formContainer">
            <div className="text-center">
              {isPromoActive.promo_code && isPromoActive.promo_code === 'CROWEB01' && (
                <PromoCode>
                  <strong>Special offer for webinar attendees:</strong>
                  <br />
                  register now and get 20% off the annual plan!
                </PromoCode>
              )}

              {/* <FreeTrial>
              <p>
                All plans come with 14 days free, no credit card required
              </p>
            </FreeTrial> */}
            </div>
            {/* <form method="GET" action={`http://localhost:7090/accounts/oauth/google`}>
                <div className="form-row">
                <GoogleBtn type="submit" className="outline gray">
                  <img alt="logo" src={googleLogo} style={{ maxWidth: 20, marginRight: ".5rem" }} />Sign up with Google</GoogleBtn>
                </div>
                <OrDivider>or</OrDivider>
              </form> */}
            <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_SITE_ID}>
              <RegisterForm currency={currency?.toUpperCase()} />
            </GoogleReCaptchaProvider>
          </FormContainer>
          {/* <BenefitsContainer className="col-12 col-lg-7 card-body">
          <img src={signupImg} alt="LivSession application" />
        </BenefitsContainer> */}
        </div>
        <div className="rightWrapper">
          <h2>
            “With LiveSession, we investigate how similar existing features are used, so we can make
            the new ones even more user-friendly.“
          </h2>
          <img src={talentlyft} alt="logo" className="img-fluid" />
          <div className="imgWrapper">
            <StaticImage
              src="../img/index/mariologin.png"
              alt="Mario"
              title="Mario"
              objectFit="contain"
            />
            <div className="textWrapper">
              <p className="name">Mario Butnic</p>
              <p className="position">CEO TalentLyft</p>
            </div>
          </div>
          <p className="trustedBy">Trusted by 1 000+ teams worldwide</p>
          <div className="containerLogo">
            <div className="logoWrapper">
              <img src={kissflow} alt="kissflow" title="Kissflow" />
              <img src={databox} alt="databox" title="Databox" />
              <img src={currenxie} alt="currenxie" title="Currenxie" />
              <img src={ing} alt="ing" title="Ing" />
              <img src={trail} alt="trail" title="Trail" />
              <img src={renmoney} alt="renmoney" title="Renmoney" className="last-in-row" />
            </div>
          </div>
        </div>
      </SignupSection>
    </Layout>
  );
};

export default SignUp;
