import React, { useState } from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';

import { useSourceParams } from 'hooks/useSourceParams';
import { ExtendSignUpForm, CustomButton } from 'components/ui/startUsing/styled';
import { SignupBox } from './styled';

const SignUpForm = ({ blackbgc }) => {
  const [email, setEmail] = useState('');

  const { link } = useSourceParams('hero-input');

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`${link}&email=${email}`);
  };

  return (
    <SignupBox blackbgc={blackbgc} className="signup-box">
      <ExtendSignUpForm onSubmit={handleSubmit}>
        <label htmlFor="email-form-hero" className="sign-up-free">
          <input
            id="email-form-hero"
            type="email"
            name="email"
            placeholder="Your business email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="input-email"
          />
        </label>
        <CustomButton as="button" type="submit" secondary>
          Sign up free
        </CustomButton>
      </ExtendSignUpForm>
      <div className="checkmarks">
        <p>Free 14-day trial</p>
        <p>
          No credit card <span>required</span>
        </p>
        <p>Easy setup</p>
      </div>
    </SignupBox>
  );
};

export default SignUpForm;

SignUpForm.propTypes = {
  blackbgc: PropTypes.string.isRequired,
};
