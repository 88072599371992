import styled from 'styled-components';

export const Table = styled.table`
  border-collapse: collapse !important;
  border-spacing: 0;
  border: 0;
  width: 100%;
  font-size: 0.85rem;

  tr {
    border: 0;
    display: table-row !important;
    th {
      border: 0 !important;
      text-align: left !important;
      text-transform: uppercase;
      font-size: 0.85rem;
      font-weight: 800;
    }
    td {
      line-height: 1.6;
      text-align: left;
      vertical-align: middle;

      div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        span {
          font-size: 0.75rem;
        }
      }
    }
  }
`;
