import NewsletterTemplate from 'templates/newsletter';

const Content = () => (
  <table className="content" cellSpacing="0" cellPadding="0" align="center" width="100%">
    <tbody>
      <tr>
        <td>
          <table className="text" cellSpacing="0" cellPadding="0" style={{ width: '100%' }}>
            <tbody>
              <tr>
                <td style={{ padding: '32px 40px 12px' }}>
                  <h1>
                    Hi{' '}
                    <span role="img" aria-label="emoji">
                      &#128075;
                    </span>
                  </h1>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '12px 40px' }}>
                  <p style={{ margin: 0, fontSize: '16px', lineHeight: '24px' }}>
                    The last few weeks have been quite challenging. We hope you’re staying safe!
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '12px 40px 44px' }}>
                  <p style={{ margin: 0, fontSize: '16px', lineHeight: '24px' }}>
                    Here&apos;s what&apos;s new in LiveSession:
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '40px 40px 0', borderTop: '1px solid rgb(228, 229, 232)' }}>
                  <h2 style={{ margin: 0, fontSize: '22px' }}>Product updates</h2>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '0 40px 12px' }}>
                  <a
                    href="/help/how-to-track-users-across-multiple-subdomains/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: 'none',
                      color: '#061631',
                      fontWeight: 800,
                      marginTop: '24px',
                      display: 'block',
                    }}
                  >
                    Track users across multiple subdomains
                  </a>
                  <a
                    href="/help/how-to-track-users-across-multiple-subdomains/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: 'none',
                      color: '#9b9b9b',
                      fontSize: 16,
                      marginTop: 8,
                      display: 'block',
                    }}
                  >
                    We’ve added a new feature for subdomains. You can now see the whole user journey
                    in one recording.
                  </a>
                  <a
                    href="/help/how-to-track-users-across-multiple-subdomains/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      fontSize: '16px',
                      color: '#0446CA',
                      fontWeight: 700,
                      textDecoration: 'none',
                      marginTop: 8,
                      display: 'block',
                    }}
                  >
                    See how to track users across subdomains
                  </a>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    padding: '12px 40px 44px',
                    borderBottom: '1px solid rgb(228, 229, 232)',
                  }}
                >
                  <a
                    href="https://developers.livesession.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: 'none',
                      color: '#061631',
                      fontWeight: 800,
                      marginTop: '24px',
                      display: 'block',
                    }}
                  >
                    Developer page
                  </a>
                  <a
                    href="https://developers.livesession.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: 'none',
                      color: '#9b9b9b',
                      fontSize: 16,
                      marginTop: 8,
                      display: 'block',
                    }}
                  >
                    Read about LiveSession API, Webhooks, and more.
                  </a>
                  <a
                    href="https://developers.livesession.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      fontSize: '16px',
                      color: '#0446CA',
                      fontWeight: 700,
                      textDecoration: 'none',
                      marginTop: 8,
                      display: 'block',
                    }}
                  >
                    Go to our developer page
                  </a>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '32px 40px 0' }}>
                  <h2 style={{ margin: 0, fontSize: '22px' }}>Blog posts</h2>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '32px 40px 12px' }}>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <a
                            href="/blog/ux-in-the-time-of-coronavirus/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src="https://emails.livesession.io/uxcoronavirus.png"
                              width="520"
                              height="400"
                              alt="uxcoronavirus"
                              style={{
                                border: 0,
                                lineHeight: '100%',
                                outline: 0,
                                display: 'block',
                                height: 'auto',
                                borderRadius: '6px',
                                maxWidth: '100%',
                              }}
                            />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            href="/blog/ux-in-the-time-of-coronavirus/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              textDecoration: 'none',
                              color: '#061631',
                              fontWeight: 800,
                              marginTop: '24px',
                              display: 'block',
                            }}
                          >
                            UX in Times of Coronavirus
                          </a>
                          <a
                            href="/blog/ux-in-the-time-of-coronavirus/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              textDecoration: 'none',
                              color: '#9b9b9b',
                              fontSize: 16,
                              marginTop: 8,
                              display: 'block',
                            }}
                          >
                            Remote work, content strategy, and expected changes. You&apos;ll also
                            find a discount for non-profit organizations inside
                          </a>
                          <a
                            href="/blog/ux-in-the-time-of-coronavirus/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              fontSize: '16px',
                              color: '#0446CA',
                              fontWeight: 700,
                              textDecoration: 'none',
                              marginTop: 8,
                              display: 'block',
                            }}
                          >
                            Read our insights and predictions
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '32px 40px 12px' }}>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <a
                            href="/blog/cognitive-bias-ux/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src="https://img.mailinblue.com/2639338/images/rnb/original/5e9f7ea652bdc558606bfa78.png?t=1587510956023"
                              width="520"
                              height="400"
                              alt="uxcoronavirus"
                              style={{
                                border: 0,
                                lineHeight: '100%',
                                outline: 0,
                                display: 'block',
                                height: 'auto',
                                borderRadius: '6px',
                                maxWidth: '100%',
                              }}
                            />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            href="/blog/cognitive-bias-ux/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              textDecoration: 'none',
                              color: '#061631',
                              fontWeight: 800,
                              marginTop: '24px',
                              display: 'block',
                            }}
                          >
                            Cognitive Bias in UX: The User Side and The Designer Side
                          </a>
                          <a
                            href="/blog/cognitive-bias-ux/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              textDecoration: 'none',
                              color: '#9b9b9b',
                              fontSize: 16,
                              marginTop: 8,
                              display: 'block',
                            }}
                          >
                            We&apos;re not as rational as we think.
                          </a>
                          <a
                            href="/blog/cognitive-bias-ux/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              fontSize: '16px',
                              color: '#0446CA',
                              fontWeight: 700,
                              textDecoration: 'none',
                              marginTop: 8,
                              display: 'block',
                            }}
                          >
                            Learn about cognitive biases
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '32px 40px 12px' }}>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <a
                            href="/blog/analyze-session-recordings/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src="https://emails.livesession.io/analyze-session-recordings.png"
                              width="520"
                              height="400"
                              alt="uxcoronavirus"
                              style={{
                                border: 0,
                                lineHeight: '100%',
                                outline: 0,
                                display: 'block',
                                height: 'auto',
                                borderRadius: '6px',
                                maxWidth: '100%',
                              }}
                            />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <a
                            href="/blog/analyze-session-recordings/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              textDecoration: 'none',
                              color: '#061631',
                              fontWeight: 800,
                              marginTop: '24px',
                              display: 'block',
                            }}
                          >
                            How to Analyze Session Recordings (Effectively)
                          </a>
                          <a
                            href="/blog/analyze-session-recordings/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              textDecoration: 'none',
                              color: '#9b9b9b',
                              fontSize: 16,
                              marginTop: 8,
                              display: 'block',
                            }}
                          >
                            You&apos;ve got hours of session recordings - now what?
                          </a>
                          <a
                            href="/blog/analyze-session-recordings/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              fontSize: '16px',
                              color: '#0446CA',
                              fontWeight: 700,
                              textDecoration: 'none',
                              marginTop: 8,
                              display: 'block',
                            }}
                          >
                            See how to analyze session replays
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '40px 60px 12px 40px' }}>
                  <p style={{ margin: 0, fontSize: '16px', lineHeight: '24px' }}>
                    Got a blog post idea? Let us know! We&apos;re happy to hear your thoughts.
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '45px 40px 32px' }}>
                  <table className="kamil" cellSpacing="0" cellPadding="0">
                    <tbody>
                      <tr style={{ verticalAlign: 'middle' }}>
                        <th>
                          <img
                            src="https://emails.livesession.io/kamil-rounded.png"
                            className="kamil-img"
                            style={{ maxWidth: '56px' }}
                            alt="kamil"
                          />
                        </th>
                        <th style={{ paddingLeft: '16px' }}>
                          <p
                            className="info"
                            style={{
                              margin: 0,
                              textAlign: 'left',
                              fontWeight: 400,
                              lineHeight: '140%',
                            }}
                          >
                            <strong>Kamil</strong> <br />
                            CEO and Founder @ LiveSession
                          </p>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
);

const Newsletter = NewsletterTemplate(Content);

const frontmatter = {
  title: 'LiveSession Newsletter April 2020',
  description: "Read LiveSession's monthly update from April 2020.",
  date: '2020-04-01',
  link: '/newsletters/april-2020/',
};

const NewsletterPage = () => <Newsletter {...frontmatter} />;

export default NewsletterPage;
