import React from 'react';
import NewLogo from 'components/index/newLogo/index';
import * as Styled from './styled';

const NewTrustedBy = () => (
  <Styled.BackgroundContainer className="waveGray">
    <NewLogo linkStudies="See case studies" customText="">
      <h2 className="line">
        <span>Trusted</span> by companies of all sizes
      </h2>
    </NewLogo>
  </Styled.BackgroundContainer>
);

export default NewTrustedBy;
