import React from 'react';
import { Popup } from 'react-typeform-embed';
import Layout from 'layout';
import Button from 'components/ui/button';
import hero from 'img/affiliate-program/hero.svg';
import FAQ from 'components/FAQ';
import TrustedBySmall from 'components/ui/trustedBySmall';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { questions } from 'data/affiliate-program/questions';
import earn from 'img/affiliate-program/icons/earn.svg';
import promote from 'img/affiliate-program/icons/promote.svg';
import join from 'img/affiliate-program/icons/join.svg';
import bsLogo from 'img/affiliate-program/bs-logo.svg';
import { graphql, useStaticQuery } from 'gatsby';
import cover from 'img/affiliate-program/cover.png';
import { Header, Banner, HowItWorks, BoxContainer, CTA, Thomas } from 'styles/partners';
import Testimonial from 'components/index/testimonial';

const AffiliateProgram = () => {
  const handleDownloadKitClicks = () => {
    trackCustomEvent({
      category: 'Affiliate program',
      action: 'Click',
      label: 'Download promo materials',
    });
  };
  const { ceo } = useStaticQuery(graphql`
    {
      ceo: file(relativePath: { eq: "affiliate-program/ceo.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);
  return (
    <Layout
      canonical="/partners/"
      metaTitle="Affiliate Program"
      metaDescription="Earn money with the LiveSession Affiliate Program. Get 20% recurring commission."
      image={cover}
    >
      <div className="container">
        <Header>
          <div>
            <h1 className="line">
              LiveSession <span>Affiliate Program</span>
            </h1>
            <p className="subtitle">Get a 20% commission for every paying customer.</p>
            <Popup id="A4gNh79V" hideHeaders hideFooter style={{ top: '-100%' }}>
              <Button as="button">Get started</Button>
            </Popup>
          </div>
          <img
            src={hero}
            alt="hero"
            title="Get started with our affiliate program!"
            className="img-fluid"
          />
        </Header>
        <TrustedBySmall />
        <Banner>
          <p>
            As a LiveSession affiliate partner, you’ll get a <strong>20%</strong> recurring
            commission for every user you refer through an affiliate link. For example, if the user
            pays $99 monthly, you’ll get <strong>$19.8 each month</strong>.
          </p>
        </Banner>
        <div className="p-168-120-90">
          <HowItWorks>
            <h2>How it works</h2>
            <BoxContainer>
              <div>
                <img src={join} alt="Join - icon" title="Join the program" />
                <h3>Join the program</h3>
                <p>Once you’re in, you’ll get a dedicated affiliate link just for you.</p>
              </div>
              <div>
                <img src={promote} alt="Promote - icon" title="Promote LiveSession" />
                <h3>Promote LiveSession</h3>
                <p>
                  Share the link with your community and spread the word about LiveSession. Download
                  our{' '}
                  <a href="/affiliates.zip" onClick={handleDownloadKitClicks}>
                    promo materials
                  </a>
                  .
                </p>
              </div>
              <div>
                <img src={earn} alt="Earn money - icon" title="Earn money" />
                <h3>Earn money</h3>
                <p>
                  You’ll get a 20% commission for every payment made by a user who joined via your
                  affiliate link.
                </p>
              </div>
            </BoxContainer>
            <Popup id="A4gNh79V" hideHeaders hideFooter style={{ top: '-100%' }}>
              <Button as="button">Become an affiliate</Button>
            </Popup>
          </HowItWorks>
        </div>
      </div>
      <div className="pb-168-120-90">
        <Thomas>
          <Testimonial
            company="CEO"
            name="Thomas Cheng"
            logo={bsLogo}
            img={ceo}
            noIndex
            copy="The program rules are clear and there is no fine print. LiveSession is a competitive offer for everyone dealing with qualitative analytics. Gotta admit it's practically selling itself!"
          />
        </Thomas>
      </div>
      <div className="container">
        <FAQ questions={questions} />
        <CTA>
          <h2>Join the program</h2>
          <p>Get a 20% commission for every paying customer.</p>
          <Popup id="A4gNh79V" hideHeaders hideFooter style={{ top: '-100%' }}>
            <Button as="button">Become an affiliate</Button>
          </Popup>
        </CTA>
      </div>
    </Layout>
  );
};

export default AffiliateProgram;
// https://hello811074.typeform.com/to/
