import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import { StyledLink } from 'components/ui/link';

export const Banner = styled.div`
  background-color: #f4f0eb;
  height: 38px;
  position: fixed;
  bottom: 0;
  text-align: center;
  width: 100%;
  z-index: 999;
  align-items: center;
  justify-content: center;
  display: flex;

  & .hide-mobile {
    @media (max-width: 1000px) {
      display: none;
    }
  }

  & .cookie-learn-more {
    text-decoration: underline;
    font-weight: 400;
  }
`;

export const Message = styled.span`
  font-size: 12px;
  font-weight: 300;
  color: #222;
  ${media.mobile} {
    ${StyledLink} {
      font-size: 12px;
    }
  }
`;

export const AcceptButton = styled.button`
  margin-left: 10px;
  line-height: 24px;
  padding: 0 8px;
  border: none;
  border-radius: 3px;
  box-shadow: none;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
`;
