import styled from 'styled-components';
import upBackground from 'img/index/blackUp.svg';
import { media } from 'theme/breakpoints';

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, min-content);
  grid-template-rows: repeat(3, min-content);
  grid-column-gap: 28px;
  grid-row-gap: 28px;
  ${media.mobile} {
    grid-gap: 55px;
  }
  :hover {
    p {
      font-weight: 400;
    }
  }
`;
export const IntegrationsAside = styled.aside`
  border-radius: 4px;
  position: relative;
  margin: 0 auto;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    width: 100vw;
    height: 424px;
    z-index: -1;
    background-color: #f5f5f5;
  }
  .waveContainer {
    display: flex;
    justify-content: center;
    background-color: #f5f5f5;
    position: relative;
    height: 450px;
    ${media.desktop} {
      height: auto;
    }
    ::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 8px;
      bottom: -7px;
      transform: rotate(180deg);
      left: 0;
      right: 0;
      mask-image: url(${upBackground});
      background-size: auto 30px;
      background-position: top left;
      background-repeat: repeat-x;
      background-color: #f5f5f5;
      z-index: -1;
    }
  }
  & .container {
    display: flex;
    align-items: center;
    padding-right: 120px;
    & div.aside-content {
      & h2 {
        margin-bottom: 32px;
        max-width: 78%;
      }
      & p {
        margin-bottom: 24px;
        max-width: 80%;
      }
    }
  }
  ${media.desktop} {
    background-color: #f5f5f5;
    &::before {
      display: none;
    }
    & .container {
      padding: 32px 0;
      flex-direction: column;
      & div.aside-content {
        margin-bottom: 48px;
        text-align: center;
        & h2 {
          width: 100%;
          margin: 0 auto 32px auto;
        }
        & p {
          width: 100%;
          margin: 0 auto 32px auto;
        }
      }
    }
  }
  ${media.mobile} {
    margin: 0;
    padding: 48px 9px;
  }
`;
