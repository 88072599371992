export const faq2 = [
  {
    question: 'What is a heat map?',
    answer:
      'A heat map is a visual representation of data that uses color-coding to display data points on a web page or any other surface. It provides a clear and concise overview of quantitative and qualitative data for data analysis purposes. ',
  },
  {
    question: 'What are heat maps used for?',
    answer: (
      <>
        Heat maps are used to visualize complex data, identify patterns, and analyze user behavior
        on web pages. They are also used by data scientists to track user interactions, such as
        clicks, mouse tracking, and scrolls.
      </>
    ),
  },
  {
    question: 'What is the difference between a heat map and a click map?',
    answer:
      'Heat maps show population density of user interactions on a web page using color-coding, while click maps show only where users clicked on the web page.',
  },
  {
    question: 'What is a scroll map?',
    answer:
      'A scroll map is a type of heat map that tracks how far down users scrolled on a web page.',
  },
  {
    question: 'What type of data is shown on a heat map?',
    answer:
      'Heat maps show numerical data related to user interactions on a web page, such as clicks, scrolls, and mouse tracking.',
  },
  {
    question: 'How do heat maps help with data analysis?',
    answer:
      'Heat maps help data scientists to quickly and easily analyze user behavior on a web page by providing a visual representation of quantitative and qualitative data.',
  },
  {
    question: 'What are some tools for creating heat maps?',
    answer: 'There are many heat map tools available, including Hotjar, Crazy Egg, and Mouseflow.',
  },
  {
    question: 'What is the importance of using heat maps in web design?',
    answer: (
      <>
        Using heat maps in web design allows designers to optimize web pages for user behavior by
        analyzing where users click, how far down they scroll, and other user interactions.
      </>
    ),
  },
  {
    question: 'What is the difference between mouse tracking heat maps and eye tracking tools?',
    answer:
      'Mouse tracking heat maps track the mouse movements of users on a web page, while eye tracking tools track the eye movements of users. ',
  },
  {
    question: 'Can heat maps be used for other applications besides web design?',
    answer:
      'Yes, heat maps can be used in many other applications besides web design, such as in population density studies or for analyzing customer behavior in a retail store. ',
  },
];
