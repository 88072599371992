import { StyledLink } from 'components/ui/link';
import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const JobList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    padding: 0.75rem 1rem;

    &:hover {
      background-color: #f5f5f5;
      border-radius: 4px;
    }
    h2 {
      font-weight: 800;
      color: #0446ca;
      font-size: 1.3rem;
      ${media.mobile} {
        font-size: 18px;
        line-height: 30px;
      }
    }
    ${StyledLink} {
      color: inherit;
      display: flex;
      ${media.mobile} {
        font-size: 16px;
        line-height: 28px;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
`;
