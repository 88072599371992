import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import { Link } from 'gatsby';

export const Box = styled(Link)`
  min-height: 500px;
  display: flex;
  flex-direction: column;
  color: #000;
  cursor: ${({ comingSoon }) => (comingSoon ? 'inital' : 'pointer')};
  border: 1px solid #e4e5e8;
  border-radius: 4px;
  &:hover {
    text-decoration: none;
    color: initial;
    & .content-box {
      & p.with-arrow {
        text-decoration: underline;
        &::after {
          transform: translateX(8px);
        }
      }
    }
  }
  & .image-box {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 235px;
    border-radius: 4px 4px 0px 0px;
    & img {
      max-width: 200px;
      ${media.desktop} {
        max-width: 150px;
      }
      ${media.tablet} {
        max-width: 200px;
      }
    }
  }
  & .content-box {
    padding: 24px;

    border-radius: 0 0 4px 4px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    & h3 {
      font-weight: 800;
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 16px;
    }
    & p {
      margin-bottom: 32px;
      text-align: left;
    }
    & p.coming-soon {
      margin-bottom: 0;
      font-weight: 800;
      color: #808080;
      margin-top: auto;
    }
    & p.with-arrow {
      margin-top: auto !important;
      font-size: 16px;
    }
    ${media.desktop} {
      & p.coming-soon,
      & a {
        font-size: 16px;
      }
    }
  }
`;
