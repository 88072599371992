import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import { media } from 'theme/breakpoints';
import styled from 'styled-components';
import arrow from 'img/ui/chevron-down.svg';

const Answer = styled.p`
  font-size: 16px;
  line-height: 26px;
  margin: 0;
  & strong {
    font-weight: 800;
  }
`;

const ListItem = styled.li`
  list-style: none;
  width: 100%;
  cursor: pointer;
  &:not(:last-child) {
    border-bottom: 1px solid #e4e5e8;
    ${Answer} {
      margin-bottom: 32px;
    }
  }
  &:not(:first-child) {
    & button {
      margin-top: 32px;
    }
  }
`;

const Title = styled.button`
  margin-bottom: 32px;
  position: relative;
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 0;
  text-align: left;

  & p {
    margin: 0;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    padding-right: 44px;
    ${media.mobile} {
      line-height: 32px;
      font-size: 22px;
    }
  }
  &::after {
    content: '';
    background-image: url(${arrow});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 24px;
    height: 13px;
    position: absolute;
    right: 8px;
    top: 50%;
    transition: 0.2s ease;
    transform-origin: 50% 50%;

    transform: ${(props) =>
      props.isActive ? 'translateY(-50%) rotate(180deg)' : 'translateY(-50%)'};
  }
`;

const Container = styled.section`
  padding: 36px 0 126px;
  margin: 0 auto;
  ${media.desktop} {
    width: auto;
  }
  ${media.mobile} {
    padding: 0 0 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  h2 {
    margin-bottom: 88px;
    text-align: center;
    ${media.mobile} {
      margin-bottom: 56px;
      line-height: 40px;
      font-size: 26px;
      text-align: center;
    }
  }
`;

const Question = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <ListItem onClick={toggle}>
      <Title onClick={toggle} isActive={isOpen}>
        <p>{question}</p>
      </Title>
      <Collapse isOpen={isOpen}>
        <Answer>{answer}</Answer>
      </Collapse>
    </ListItem>
  );
};

Question.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

const FAQ = ({ questions, twoColumns, style }) => {
  const renderQuestions = (arr) =>
    arr.map((q, id) => (
      <Question key={q.question} id={id} question={q.question} answer={q.answer} />
    ));

  return (
    <Container twoColumns={twoColumns} style={style}>
      <h2>Frequently asked questions</h2>
      <ul style={{ padding: 0, margin: 0 }}>{renderQuestions(questions)}</ul>
    </Container>
  );
};

export default FAQ;

FAQ.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  twoColumns: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
};

FAQ.defaultProps = {
  twoColumns: false,
  style: null,
};
