import Layout from 'layout';
import 'sass/theme/terms.scss';
import { Table } from 'styles/subprocessors';

const renderHeader = () => (
  <thead>
    <tr>
      <th>Company</th>
      <th>Purpose</th>
      <th>URL</th>
    </tr>
  </thead>
);

const renderRow = (name, legal, url, desc) => (
  <tr>
    <td className="name">
      <div>
        <strong>{name}</strong> <span>{legal}</span>
      </div>
    </td>
    <td>{desc}</td>
    <td>
      {' '}
      <a target="_blank" rel="noopener noreferrer nofollow" href={`https://${url}`}>
        {url}
      </a>
    </td>
  </tr>
);

const Tos = () => (
  <Layout
    metaTitle="List of data subprocessors"
    canonical="/subprocessors/"
    extraTags={[{ name: 'robots', content: 'noindex' }]}
  >
    <section className="terms">
      <h1>
        LIVESESSION SOFTWARE-AS-A-SERVICE <br />
        LIST OF SUBPROCESSORS
      </h1>

      <h3
        style={{
          fontWeight: 800,
          fontSize: '1.25rem',
          margin: '120px 0 .5rem 0',
          textAlign: 'left',
        }}
      >
        Tracking data subprocessors:
      </h3>
      <p>
        List of companies that process data collected by LiveSession (including tracking code data
        collected on our customers&apos; websites):
      </p>
      <Table>
        {renderHeader()}
        <tbody>
          {renderRow(
            'Google',
            'Google Ireland LTD',
            'cloud.google.com',
            'Cloud infrastructure & data hosting',
          )}
        </tbody>
      </Table>

      <h3
        style={{
          fontWeight: 800,
          fontSize: '1.25rem',
          margin: '120px 0 .5rem 0',
          textAlign: 'left',
        }}
      >
        Account data subprocessors:
      </h3>
      <p>List of companies that process accounts data (LiveSession&apos;s customer data):</p>
      <Table>
        {renderHeader()}
        <tbody>
          {renderRow(
            'Google',
            'Google Ireland LTD',
            'google.com',
            'Cloud infrastructure & data hosting',
          )}
          {renderRow('Chargebee', 'Chargebee Inc.', 'chargebee.com', 'Billing automation')}
          {renderRow(
            'Braintree',
            'PayPal (Europe) S.à r.l. et Cie, S.C.A.',
            'braintreepayments.com',
            'Payment processing provider',
          )}
          {renderRow('Slack', 'Slack, Inc.', 'slack.com', 'Internal communication hub')}
          {renderRow(
            'Intercom',
            'Intercom, Inc.',
            'intercom.com',
            'Chat service for customer support',
          )}
          {renderRow('Postmark', 'Wildbit, LLC', 'postmarkapp.com', 'Email service provider')}
          {renderRow(
            'Sendinblue',
            'Sendinblue',
            'sendinblue.com',
            'Marketing & newsletter email service provider',
          )}

          {renderRow('Amplitude', 'Amplitude, Inc.', 'amplitude.com', 'Analytics')}
          {renderRow(
            'ChartMogul',
            'ChartMogul CMTDE GmbH & Co. KG',
            'chartmogul.com',
            'Business analytics',
          )}
          {renderRow('Profitwell', '200 OK, LLC', 'profitwell.com', 'Business analytics')}
          {renderRow('Refiner', 'Refiner SAS', 'refiner.io', 'NPS provider')}
          {renderRow('G2', 'G2 Crowd, Inc.', 'g2.com', 'Software reviews')}
          {renderRow('Capterra', 'Capterra Inc.', 'capterra.com', 'Software reviews')}
          {renderRow('GetApp', 'Edificio SPACES.', 'getapp.com', 'Software reviews')}
          {renderRow('Gartner Digital Markets', 'Gartner Inc', 'gartner.com', 'Software reviews')}
        </tbody>
      </Table>
    </section>
  </Layout>
);

export default Tos;
