import withUsabilityTestingTemplate from 'hoc/content-cluster';
import Link from 'components/ui/link';
import arrow from 'img/ui/arrow_white.svg';

const WhatIsUX = () => (
  <>
    <h2>How do you explain what UX is?</h2>
    <p>
      We’ve asked 10 business experts to define user experience. Here is their take on the subject.
    </p>
    <h3>
      1. When it comes to a user experience definition, it’s how people interact with a product
      throughout its entire lifecycle.
    </h3>
    <blockquote>
      <p>
        <em>
          When it comes to defining user experience, it’s how people interact with a product
          (whether that be a digital product like a program or a physical product such as a
          microwave oven) throughout its entire lifecycle.
        </em>
      </p>
      <p>
        <em>
          It’s usually used in the context of someone working as a user experience designer/UX
          content specialist of some sort, and in theory is the noble art of optimizing aesthetics
          appeal, ease of use, and overall customer satisfaction with that product.{' '}
        </em>
      </p>
      <p>
        <em>
          It is the responsibility of a product designer to conceive the user experience design
          process from a viewer’s point so that a visitor can enjoy an enhanced interactive
          atmosphere.
        </em>
      </p>
      <p>
        <strong>
          <Link
            href="https://www.linkedin.com/in/muhammed-mateen-khan-286383107/?originalSubdomain=pk
            "
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            Muhammad Mateen Khan
          </Link>
          , Digital Marketing Strategist at{' '}
          <Link href="https://www.purevpn.com/" target="_blank" rel="noopener noreferrer nofollow">
            PureVPN
          </Link>
        </strong>
      </p>
    </blockquote>
    <h3>
      2. What is user experience design? It’s about how you make your users feel, right from the
      moment they first come in contact with your brand.{' '}
    </h3>

    <blockquote>
      <p>
        <em>
          It was Maya Angelou who said, &quot;I&apos;ve learned that people will forget what you
          said, people will forget what you did, but people will never forget how you made them
          feel.&quot; <strong>What is user experience?</strong> It has to do with how you make your
          users feel, right from the moment they first come in contact with your brand. It is the
          sum total of how they feel about their encounter with you.
        </em>
      </p>
      <p>
        <em>
          At Astute Copy Blogging, we seek to provide an amazing UX, also known as customer
          experience. We do this by making our clients feel unique and special. A great UX
          invariably leads to more loyal customers, increased customer satisfaction, better
          word-of-mouth marketing, positive reviews, and recommendations. Here is the reality: the
          better the experience customers have, the more repeat custom and positive reviews a
          business will receive.
        </em>
      </p>
      <p>
        <strong>
          <Link
            href="https://www.linkedin.com/in/pedrookoro/?src=aff-lilpar&veh=aff_src.aff-lilpar_c.partners_pkw.10078_plc.Skimbit%20Ltd._pcrid.449670_learning&trk=aff_src.aff-lilpar_c.partners_pkw.10078_plc.Skimbit%20Ltd._pcrid.449670_learning&clickid=TLM08MXiDxyOUzQwUx0Mo3EHUkiQt5WpBwujRw0&irgwc=1"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            Pedro Okoro
          </Link>
          , pro blogger, List-grow strategist, and CEO of{' '}
          <Link
            href="https://www.astutecopyblogging.com/"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            Astute Copy Blogging
          </Link>
        </strong>
      </p>
    </blockquote>

    <h3>3. The absence of negative experiences is what a good user experience is.</h3>

    <blockquote>
      <p>
        <em>
          You know when you download a new app and then immediately consider throwing your phone
          into the sea because you can’t figure out how to do that one thing, and an influx of ads
          keep spamming your screen? Good UX is the absence of that type of experience.
        </em>
      </p>
      <p>
        <em>
          Good UX is like going on a great first date that leads to a successful long-term
          relationship. The experience is interactive, the conversation flows, and you just get each
          other.
        </em>
      </p>
      <p>
        <strong>
          <Link
            href="https://www.linkedin.com/in/kimberly-smith-0829625/"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            Kimberly Smith
          </Link>
          , Marketing Manager at{' '}
          <Link
            href="https://clarifycapital.com/"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            Clarify Capital
          </Link>
        </strong>
      </p>
    </blockquote>

    <h3>
      4. My user experience definition comes down to how you make your users feel when they consume
      your content.{' '}
    </h3>

    <blockquote>
      <p>
        <em>
          It goes beyond simply achieving the primary purpose, but rather aims to engage real users
          and improve behavioral and attitudinal loyalty. The UX should be at the forefront of any
          digital content strategy, aiming to ensure that users have a positive experience when
          exposed to your business.
        </em>
      </p>
      <p>
        <strong>
          <Link
            href="https://www.linkedin.com/in/itamarblauer/"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            Itamar Blauer
          </Link>
          , SEO Consultant at{' '}
          <Link
            href="https://www.itamarblauer.com/"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            ItamarBlauer
          </Link>
        </strong>
      </p>
    </blockquote>
    <h3>
      5. What is user experience design? At its core, good UX is all about delighting clients.
    </h3>

    <blockquote>
      <p>
        <em>
          User Experience is often thought of in terms of customer interaction with a web or
          software application. However, UX goes much deeper than just the technical features or
          layout of a product. At its core, good UX is all about delighting clients. The focus
          should be on the customer rather than on the application or solution.
        </em>
      </p>
      <p>
        <em>
          Alpine XC, a small dirt bike repair shop with no real tech presence recently reminded me
          that UX is not a technical problem, but a customer-focused one. Alpine XC adjusted to the
          COVID-19 pandemic by offering mobile service and repairs so that customers didn’t have to
          go to the shop but instead had mechanics come to them. To me, this exemplifies what UX is
          about. It doesn’t have to be a technical solution, it just needs to make the process
          simple and pain-free for your customer base.
        </em>
      </p>
      <p>
        <strong>
          <Link
            href="https://www.linkedin.com/in/trent-mcmillan/"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            Trent McMillan
          </Link>
          , Product Manager at{' '}
          <Link href="https://www.alpinexc.com/" target="_blank" rel="noopener noreferrer nofollow">
            Alpine XC
          </Link>
        </strong>
      </p>
    </blockquote>
    <h3>
      6. When it comes to defining user experience, I see it as a roller coaster design job; it’s
      the neverending battle between satisfying your fans and growth through reversion to the mean.
    </h3>

    <blockquote>
      <p>
        <em>
          User Experience design is like a roller coaster design project. Products are like roller
          coasters because riders know where it starts and ends. When I open Uber, I know it ends
          with me getting a ride. The entire customer journey of the roller coaster becomes the UX.
          Most roller coasters are designed for mass appeal and end up formulaic. Every so often,
          you find a roller coaster that is, in one or more dimensions, far too extreme for mass
          appeal. If it survives, it becomes a cult classic. UX, and ultimately user-centered
          design, is the neverending battle between satisfying your adoring fans and growth through
          reversion to the mean.
        </em>
      </p>
      <p>
        <strong>
          <Link
            href="https://www.linkedin.com/in/jeffreyyzhou/"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            Jeff Zhou
          </Link>
          , CEO at{' '}
          <Link
            href="https://www.figloans.com/community-loans"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            Fig
          </Link>
        </strong>
      </p>
    </blockquote>
    <h3>
      7. The user experience is built on every time your customers see something about you in the
      press, receive an email, or interact with you in any way whatsoever.
    </h3>
    <blockquote>
      <p>
        <em>
          User experience is how your customers feel when interacting with your brand. Do they feel
          flustered because your website is difficult to navigate? Do they feel important because
          you are always excited and happy to see them in meetings? It is easy to plan to give your
          customers a good UX, but the importance of this word goes beyond the standard dictionary
          definition. The UX is built on every time your customers see something about you in the
          press, receive an email, or interact with you in any way whatsoever.
        </em>
      </p>
      <p>
        <strong>
          <Link
            href="https://www.linkedin.com/company/directiondotcom"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            Cali Saturn
          </Link>
          , Digital Marketing Specialist at{' '}
          <Link href="https://direction.com" target="_blank" rel="noopener noreferrer nofollow">
            Direction.com
          </Link>
        </strong>
      </p>
    </blockquote>
    <h3>
      8. User experience is defined as how efficiently the average user can navigate around without
      getting lost.
    </h3>
    <blockquote>
      <p>
        <em>
          We define user experience as how efficiently the average user of a site can navigate
          around it and complete their intended action without getting lost. The intended action
          varies based on the type of site and business model - for example, what applies to an
          e-commerce platform and shopping experience is vastly different from the recommendations
          we might make for a local attorney’s site.
        </em>
      </p>
      <p>
        <em>
          Once we understand the type of business we’re dealing with, we can then jump into the
          analytics to understand the metrics around a user’s experience. This includes time on
          site, average number of pages consumed, bounce rates, exit rates, and abandon cart rates
          (for online stores). With a comprehensive set of data, we can then optimize a website’s
          hierarchy, its page flows, navigation, color palettes, font sizes for important
          interactive elements on a page, and anything else we can identify as where a user gets
          lost.
        </em>
      </p>
      <p>
        <strong>
          <Link
            href="https://www.linkedin.com/in/jeffromero/"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            Jeff Romero
          </Link>
          , Co-Founder at{' '}
          <Link
            href="https://www.octivdigital.com/"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            Octiv Digital
          </Link>
        </strong>
      </p>
    </blockquote>
    <h3>
      9. We define user experience as everything that helps users fall in love with our product.
    </h3>
    <blockquote>
      <p>
        <em>
          Interaction designers would say that user experience is how a product makes you feel.
          Analysts would say it’s how quickly you complete key workflows. Product managers would say
          it’s about retention and recurring revenue.
        </em>
      </p>
      <p>
        <em>
          For our business, it’s all of these things combined. We take a holistic approach to
          defining user experience as everything that helps users fall in love with our product.
        </em>
      </p>
      <p>
        <em>
          This approach keeps everyone in our design team aligned around the UX and motivates them
          to do their best work, as they recognize how their contribution leads to an overall
          positive experience for our users.
        </em>
      </p>
      <p>
        <strong>
          <Link
            href="https://www.linkedin.com/in/matthew-johnson-6a58b82/"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            Matthew Johnson
          </Link>
          , Product Manager at{' '}
          <Link href="https://www.userback.io/" target="_blank" rel="noopener noreferrer nofollow">
            userback.io
          </Link>
        </strong>
      </p>
    </blockquote>
    <h3>
      UX is the combination of facets like functionality, design, usability testing, and branding
      all into one coherent story, and then bringing it into the user’s world in a balanced way.
    </h3>
    <blockquote>
      <p>
        <em>
          What is User Experience Design? For me, it’s the combination of facets such as
          functionality, interaction design, usability testing, and branding all into one coherent
          story, and then bringing it into the user’s world in a balanced way.
        </em>
      </p>
      <p>
        <em>
          During the whole process of creating, developing, and promoting products, users are always
          at the top. It doesn’t matter how innovative the features you come up with are or how
          aesthetic the visual design you create is. If your users don’t feel it, you can’t count on
          success.
        </em>
      </p>
      <p>
        <em>
          Users should have a sense that they’ve always lacked something and that they’ve actually
          longed for your product. It meets their needs, solves their problems, keeps them happy,
          and thus makes it difficult for them to understand how they could have lived without it.
        </em>
      </p>
      <p>
        <em>
          Giving users a meaningful experience wrapped in the product makes it an integral part of
          their lives.
        </em>
      </p>
      <p>
        <strong>
          <Link
            href="https://www.linkedin.com/in/weronika-masternak/"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            Weronika Masternak
          </Link>
          , Content Marketing Specialist at{' '}
          <Link href="https://www.helpdesk.com/" target="_blank" rel="noopener noreferrer nofollow">
            HelpDesk
          </Link>
        </strong>
      </p>
    </blockquote>
    <h2>Summary</h2>
    <p>
      As you’ve just seen in the examples presented above, defining user experience doesn’t have to
      be confined to a standard, Wikipedia-style answer. In fact, depending on your product
      experience with creation, it can mean a lot of different things.
    </p>
    <p>
      UX can be a wide range of aspects of user experience from the sense of building a product that
      a human user feels like they’ve longed for on a daily basis, through increasing the efficiency
      of how people finalize processes in a user interface, all the way to bringing functionality,
      graphic design skills, user testing, usability, and branding into a perfectly balanced
      product.
    </p>
    <p>
      How about you – how do you define{' '}
      <Link href="/user-experience/" target="_blank" rel="noopener noreferrer">
        user experience
      </Link>
      ? If you’d like to add your very own UX definition, don’t hesitate to reach out.
    </p>
    <div className="next-chapter">
      <Link href="/user-experience/ux-design-process/">
        <div>
          <p>Chapter 2</p>
          <p>UX Design Process: What to Include and How to Do It Right</p>
        </div>
        <img src={arrow} alt="arrow" title="white arrow" />
      </Link>
    </div>
  </>
);

export default withUsabilityTestingTemplate(WhatIsUX);
