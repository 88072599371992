import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import arrow from 'img/ui/chevron-down.svg';
import { Link } from 'gatsby';
import * as Styled from './styled';

const MobileDropdown = ({ pathname, data }) => {
  const [currentCategory] = data.filter(({ url }) => url === pathname);
  const [isOpen, setOpen] = useState(false);
  const [firstPosition, setFirstPosition] = useState('');
  const dropdown = useRef(null);

  const listenScroll = useCallback(() => {
    const position = dropdown.current.getBoundingClientRect().y;
    const windowPos = window.pageYOffset;
    if (position < 77) {
      dropdown.current.classList.add('fixed');
    }
    if (windowPos + 77 < firstPosition) {
      dropdown.current.classList.remove('fixed');
    }
  }, [firstPosition]);

  useEffect(() => {
    if (firstPosition === '') {
      setFirstPosition(dropdown.current.offsetTop);
    }
    window.addEventListener('scroll', listenScroll);
    return () => window.removeEventListener('scroll', listenScroll);
  }, [firstPosition, listenScroll]);
  return (
    <Styled.Dropdown ref={dropdown}>
      <Styled.Button
        className={isOpen && 'active'}
        as="button"
        onClick={() => setOpen(!isOpen)}
        type="button"
      >
        <p className="active">{currentCategory.title}</p>
        <img src={arrow} alt="arrow" className="icon" />
      </Styled.Button>
      <Collapse isOpen={isOpen}>
        <Styled.List>
          {data.map(({ desc, title, url }) => (
            <li key={url}>
              <Link to={url} className={pathname === url ? 'active' : undefined}>
                <p>{title}</p>
                <p>{desc}</p>
              </Link>
            </li>
          ))}
        </Styled.List>
      </Collapse>
    </Styled.Dropdown>
  );
};

export default MobileDropdown;

MobileDropdown.propTypes = {
  pathname: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([PropTypes.array]).isRequired,
};
