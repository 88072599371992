import { media } from 'theme/breakpoints';
import styled from 'styled-components';

export const Heading = styled.h4`
  font-size: 14px;
  line-height: normal;
  font-weight: 800;
  margin-bottom: 8px;
`;
export const Span = styled.span`
  line-height: 17px;
  font-size: 12px;
  font-weight: 700;
  padding: 0 8px;
  margin-left: 8px;
  margin-bottom: 8px;
  border-radius: 10px;
  background-color: #ebebeb;
`;
export const Flex = styled.div`
  display: flex;
  align-items: center;
  ${media.desktop} {
    justify-content: center;
  }
`;
