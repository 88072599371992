import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import * as Styled from './styled';
import UpHeading from '../upHeading';

const ConnectData = () => (
  <Styled.Wrapper className="container">
    <div className="p240">
      <Styled.Section>
        <StaticImage
          src="../../../img/saas/dashboard.png"
          alt="using Dashboards to track product adoption, activation or retention"
          objectFit="contain"
        />
        <div className="content-box">
          <UpHeading headingText="DASHBOARDS" />
          <h2 className="line">Build and customize dashboards</h2>
          <p>
            Visualize and analyze the data you collect with LiveSession. Use Dashboards to track
            product adoption, activation or retention. Combining with custom events allow you to see
            the most important metrics of your product. See the trends of your business KPIs and set
            goals based on it.
          </p>
        </div>
      </Styled.Section>
    </div>
  </Styled.Wrapper>
);
export default ConnectData;
