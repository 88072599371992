import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import UpHeading from '../upHeading';
import * as Styled from './styled';

const Information = () => (
  <div className="container">
    <Styled.InfoSection>
      <div>
        <StaticImage
          src="../../../img/saas/importantUser.png"
          alt="new customers, lost customers, returning customers. Filter user sessions to find out how different groups use your product"
          objectFit="contain"
        />
        <UpHeading headingText="FILTERS & SEGMENTS" />
        <h2>Find important users</h2>
        <p>
          New customers, lost customers, returning customers. Segment your users’ sessions and know
          how different groups use your product. Find out what leads to activation or churn, or what
          is the most important feature that customers use.
        </p>
      </div>
      <div>
        <StaticImage
          src="../../../img/saas/lsBot.png"
          alt="notifications from Slack about important business events, such as starting a new subscription or completing the process of bringing users into the app"
          objectFit="contain"
        />
        <UpHeading headingText="ALERTS" />
        <h2 className="comingHeader">Get instant alerts</h2>
        <p>
          LiveSession allows you to see how your users are engaging with your application. Get
          instant Slack notification of important business events like starting new subscription or
          finishing onboarding process by your users.
        </p>
      </div>
    </Styled.InfoSection>
  </div>
);

export default Information;
