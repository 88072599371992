import React from 'react';
import Link from 'components/ui/link/';
import videoArrow from 'img/index/viedoArrow.svg';
import arrowBottom from 'img/index/arrowBottom.svg';
import * as Styled from './styled';

const NewTrustedBy = () => (
  <Styled.BackgroundContainer className="waveGray">
    <Styled.SectionWrapper className="container">
      <h2 className="line">
        How LiveSession can <span>help</span> your business?
      </h2>
      <Styled.ContentWrapper>
        <div className="answer">
          <h2 className="question">With LiveSession, you can answer questions like:</h2>
          <Link withArrow href="/industry/saas/">
            LiveSession for SaaS
          </Link>
          <Link withArrow href="/industry/ecommerce/">
            LiveSession for eCommerce
          </Link>
        </div>
        <img src={videoArrow} alt="arrows pointing to questions" className="img-fluid" />
        <img
          src={arrowBottom}
          alt="arrows pointing to questions"
          className="img-fluid arrowBottom"
        />
        <ul>
          <li className="card-1">How users use my application?</li>
          <li className="card-2">Where and why do users drop off?</li>
          <li className="card-3">What are the most crucial page elements?</li>
          <li className="card-4">What features are most used in my product?</li>
          <li className="card-5">What Javascript and network errros users have?</li>
          <li className="card-6">What are the frustration signals on my page?</li>
        </ul>
      </Styled.ContentWrapper>
    </Styled.SectionWrapper>
  </Styled.BackgroundContainer>
);

export default NewTrustedBy;
