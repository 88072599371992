/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import Button from 'components/ui/button';
import checkmark from 'img/ui/checkmarkBlue.svg';
import Slider from 'rc-slider';
import {
  kFormatter,
  numberWithSpaces,
  getPricingModelSessions,
  formatPlanLabel,
} from 'data/pricing';
import * as Styled from './styled';

const PricingCreator = ({
  type,
  currency,
  planName,
  features,
  mostPopular = false,
  use,
  custom,
  free,
  style,
  pricingModel,
}) => {
  const renderRange = () => {
    if (pricingModel?.pricing[planName] && pricingModel?.pricing[planName][0]) {
      return pricingModel?.pricing[planName][0]?.unit * 1000;
    }
    return undefined;
  };

  const sessionsCountOptions = getPricingModelSessions(pricingModel);
  const [isOpen, setOpen] = useState(false);
  const [range, setRange] = useState(renderRange());

  useEffect(() => {
    if (typeof window !== 'undefined' && window.innerWidth > 576) {
      setOpen(true);
    }
  }, []);

  const createMarksForSlider = () => {
    const marks = {};
    sessionsCountOptions[planName].forEach((val, i) => {
      marks[i + 1] = {
        label:
          i === 0 || i === sessionsCountOptions[planName].length - 1 ? kFormatter(val.key) : '',
        style:
          i === sessionsCountOptions[planName].length - 1
            ? { left: '95%' }
            : i === 0 && { left: '2%' },
      };
    });
    return marks;
  };

  const handleRangeChange = (value) => {
    const sessionNumber = sessionsCountOptions[planName][value - 1]?.key;
    setRange(sessionNumber);
  };

  const getBasicPrice = (key) => {
    if (pricingModel?.pricing[key]) {
      const unitValue = pricingModel?.pricing[key].find((item) => item?.unit === range / 1000);
      return unitValue?.prices[`1m`][currency.toUpperCase()][type];
    }
    return 0;
  };

  const list = features.map((f, i) =>
    f.tooltip ? (
      <Styled.StyledTippy content={f.tooltip} className="custom">
        <li
          key={`${f.key + i}`}
          className={['with-hover', planName === 'free' ? 'white' : ''].join(' ')}
        >
          <img src={checkmark} alt="checkmark" />
          <Styled.TextFeature>{f.title}</Styled.TextFeature>
        </li>
      </Styled.StyledTippy>
    ) : (
      <li key={`${f.key + i}`}>
        <img src={checkmark} alt="checkmark" />
        <Styled.TextFeature
          style={{
            borderBottom: 'none',
          }}
        >
          {f.title}
        </Styled.TextFeature>
      </li>
    ),
  );

  const handleContact = (e) => {
    e.preventDefault();
    if (window.Intercom) {
      /*eslint-disable */
      Intercom('show');
      /* eslint-enable */
    }
  };

  return (
    <Styled.Box
      free={free}
      mostPopular={mostPopular}
      style={{
        ...style,
      }}
      className={[
        custom ? 'custom' : '',
        free ? 'free' : '',
        mostPopular ? 'most-popular' : '',
        'pricing-box',
      ].join(' ')}
    >
      <Styled.Inner>
        <Styled.PricingHeader>
          <Styled.PlanName>{formatPlanLabel(planName)}</Styled.PlanName>
          <Styled.Use>{use}</Styled.Use>
          <Styled.PriceBox>
            {!custom ? (
              <Styled.Price startsAt={range}>
                <div style={{ display: 'flex' }}>
                  <Styled.Currency>{currency === 'usd' ? '$ ' : '€ '}</Styled.Currency>
                  {planName === 'free'
                    ? 0
                    : type === 'annual'
                    ? (getBasicPrice(planName) / 100 / 12).toFixed()
                    : getBasicPrice(planName) / 100}{' '}
                </div>
                <Styled.PageviewsCTA>
                  {free && (
                    <span>
                      includes{' '}
                      <strong>
                        {pricingModel &&
                          // eslint-disable-next-line react/prop-types
                          numberWithSpaces(pricingModel?.stair_steps_sessions[planName][0])}
                      </strong>{' '}
                      free sessions
                    </span>
                  )}
                  {!free && (
                    <span>
                      {type === 'annual' ? 'per month' : 'monthly'} for{' '}
                      <strong>{numberWithSpaces(range) || '1 000'}</strong> sessions
                    </span>
                  )}
                </Styled.PageviewsCTA>
              </Styled.Price>
            ) : (
              <Styled.CustomPricing>
                <p className="custom-pricing">Custom</p>
                <p className="starts-at">
                  starts from <strong>100 000</strong> sessions
                </p>
              </Styled.CustomPricing>
            )}

            {range && (
              <Styled.Range>
                <Slider
                  min={1}
                  max={sessionsCountOptions[planName].length}
                  step={null}
                  marks={createMarksForSlider()}
                  onChange={handleRangeChange}
                  value={sessionsCountOptions[planName].map((item) => item.key).indexOf(range) + 1}
                  handleStyle={{
                    backgroundColor: '#0446ca',
                  }}
                  trackStyle={{
                    backgroundColor: '#0446ca',
                  }}
                />
              </Styled.Range>
            )}
          </Styled.PriceBox>

          <Button
            sourceID={`pricing-${planName}`}
            signUp={!custom}
            secondary
            reversed={free}
            onClick={custom && handleContact}
          >
            {custom ? 'Contact us' : free ? 'Get started' : 'Sign up free'}
          </Button>
        </Styled.PricingHeader>

        <Styled.SeeFeatures onClick={() => setOpen(!isOpen)}>
          See features <i className={isOpen ? 'icon-chevron-up' : 'icon-chevron-down'} />
        </Styled.SeeFeatures>
        <Collapse isOpen={isOpen} style={{ width: '100%' }}>
          <Styled.Features>{list}</Styled.Features>
        </Collapse>
      </Styled.Inner>
    </Styled.Box>
  );
};

export default PricingCreator;

PricingCreator.propTypes = {
  type: PropTypes.string.isRequired,
  planName: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(PropTypes.object).isRequired,
  mostPopular: PropTypes.bool,
  free: PropTypes.bool,
  custom: PropTypes.bool,
  use: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  style: PropTypes.oneOfType([PropTypes.object]),
};

PricingCreator.defaultProps = {
  mostPopular: false,
  custom: false,
  free: false,
  style: undefined,
};
