import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const Header = styled.div`
  text-align: center;

  & p {
    margin: 32px auto 0;
    font-size: 22px;
    line-height: 34px;
    ${media.tablet} {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;
export const Section = styled.section`
  padding-top: 168px;
  padding-bottom: 0;
  ${media.mobile} {
    padding-top: 120px;
  }
`;

export const GridBox = styled.div`
  display: grid;
  grid-template-columns: 270px 1fr;
  grid-column-gap: 40px;
  margin-top: 96px;
  padding-bottom: 120px;
  ${media.desktop} {
    grid-template-columns: 213px 1fr;
  }
  ${media.tablet} {
    grid-template-columns: 1fr;
    margin-top: 43px;
    grid-gap: 48px;
    padding-bottom: 80px;
  }
`;

export const ExtendGridBox = styled(GridBox)`
  padding-bottom: 120px;
  margin-top: 144px;
  grid-template-rows: auto auto;
  grid-row-gap: 56px;
  grid-template-areas:
    'breadcrumbs breadcrumbs'
    'sidebar content';
  ${media.tablet} {
    margin-top: 110px;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      'breadcrumbs'
      'sidebar'
      'content';
    grid-gap: 40px;
  }
  ${media.mobile} {
    grid-template-columns: calc(100vw - 30px);
  }
  .breadcrumbs {
    grid-area: breadcrumbs;
    display: flex;
    align-items: center;
    & img {
      margin: 0 16px;
    }
    & p {
      margin: 0;
      line-height: normal;
      & a {
        color: #000;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
`;
