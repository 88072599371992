/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/control-has-associated-label */

import checkmark from 'img/ui/checkmarkGreen.svg';
import cross from 'img/ui/x-ads.svg';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';
import logoLS from 'img/LogoLS/favicon-ls.png';
import MoreInfo from 'img/ui/moreInfoBlack.inline.svg';
import * as Styled from './styled';

const PlanTable = ({
  SessionReplayLS,
  SessionReplayCompany,
  DevToolsLS,
  DevToolsCompany,
  ClickmapsLS,
  ClickmapsCompany,
  EngagementScoreLS,
  EngagementScoreCompany,
  RageClicksLS,
  RageClicksCompany,
  CustomEventsLS,
  CustomEventsCompany,
  CustomPropertiesLS,
  CustomPropertiesCompany,
  UserIdentificationLS,
  UserIdentificationCompany,
  InspectModeLS,
  InspectModeCompany,
  SegmentationLS,
  SegmentationCompany,
  RecordingLS,
  RecordingCompany,
  EventAlertsLS,
  EventAlertsCompany,
  AlwaysOnRecordingLS,
  AlwaysOnRecordingCompany,
  FullSPASupportLS,
  FullSPASupportCompany,
  logoCompany,
  companyName,
  price,
}) => {
  const sessionReplays = [
    {
      name: 'Session replay',
      description: 'See mouse movements, clicks, scrolling and more.',
    },
    {
      name: 'DevTools',
      description: 'Track console logs and errors.',
    },
    {
      name: 'Clickmaps',
      description: 'See how users interact with specific elements on your website.',
    },
    {
      name: 'Engagement score',
      description: 'Find and watch the most interesting recordings based on user activity.',
    },
    {
      name: 'Rage clicks & Error clicks',
      description: 'Spot errors and points of frustration.',
    },

    {
      name: 'Custom events',
      description: 'Capture any interaction your customers make with your app.',
    },
    {
      name: 'Custom properties',
      description:
        'Extend sessions with custom data (e.g. subscription plan) and use it to filter your recordings.',
    },
    {
      name: 'Recording rules',
      description: 'Choose to record sessions based on their URL, IP and other variables.',
    },
    {
      name: 'User identification',
      description: 'Identify your users by name or email.',
    },
    {
      name: 'Always-on recordings',
      description: 'Record all sessions, all the time.',
    },
    {
      name: 'Inspect mode',
      description: 'See how users interact with specific elements on your website.',
    },
    {
      name: 'Segmentation',
      description: 'Create custom segments to track different behavioral patterns.',
    },
    {
      name: 'Full SPA support',
      description: 'Works with React, Vue, Angular and other popular frameworks.',
    },
    {
      name: 'Alerts',
    },
  ];

  const findSessionReplays = (nameSession) => sessionReplays.find((el) => el.name === nameSession);

  const Tips = (name) =>
    findSessionReplays(name).description ? (
      <Tippy content={findSessionReplays(name)?.description} className="custom" placement="right">
        <div>
          <span>{name}</span>
          <i>
            <MoreInfo />
          </i>
        </div>
      </Tippy>
    ) : (
      <div>
        <span>{name}</span>
      </div>
    );

  return (
    <div className="container">
      <Styled.Section>
        <h3>Find out why livesession is a better choice for product analytics</h3>
        <p>More useful and intuitive features at a reasonable price.</p>
        <table>
          <tbody>
            <tr>
              <th />
              <th className="planLS">
                <div className="imageWrapper">
                  <img src={logoLS} alt="company logo" />
                  <span>LiveSession</span>
                </div>
                Pro plan from <span>$99/mo</span>
              </th>
              <th className="planCompany">
                <div className="imageWrapper">
                  <img src={logoCompany} alt="company logo" />
                  <span>{companyName}</span>
                </div>
                Pro plan from <span>${price}/mo</span>
              </th>
            </tr>
            <tr>
              <th className="variant">{Tips('Session replay')}</th>
              {SessionReplayLS ? (
                <td className="cross">
                  <img src={cross} alt="cross" />
                </td>
              ) : (
                <td>
                  <img src={checkmark} alt="checkmark" />
                </td>
              )}
              {SessionReplayCompany ? (
                <td className="cross">
                  <img src={cross} alt="cross" />
                </td>
              ) : (
                <td>
                  <img src={checkmark} alt="checkmark" />
                </td>
              )}
            </tr>
            <tr>
              <th className="variant">{Tips('DevTools')}</th>
              {DevToolsLS ? (
                <td className="cross">
                  <img src={cross} alt="cross" />
                </td>
              ) : (
                <td>
                  <img src={checkmark} alt="checkmark" />
                </td>
              )}
              {DevToolsCompany ? (
                <td className="cross">
                  <img src={cross} alt="cross" />
                </td>
              ) : (
                <td>
                  <img src={checkmark} alt="checkmark" />
                </td>
              )}
            </tr>
            <tr>
              <th className="variant">{Tips('Clickmaps')}</th>
              {ClickmapsLS ? (
                <td className="cross">
                  <img src={cross} alt="cross" />
                </td>
              ) : (
                <td>
                  <img src={checkmark} alt="checkmark" />
                </td>
              )}
              {ClickmapsCompany ? (
                <td className="cross">
                  <img src={cross} alt="cross" />
                </td>
              ) : (
                <td>
                  <img src={checkmark} alt="checkmark" />
                </td>
              )}
            </tr>
            <tr>
              <th className="variant">{Tips('Engagement score')}</th>
              {EngagementScoreLS ? (
                <td className="cross">
                  <img src={cross} alt="cross" />
                </td>
              ) : (
                <td>
                  <img src={checkmark} alt="checkmark" />
                </td>
              )}
              {EngagementScoreCompany ? (
                <td className="cross">
                  <img src={cross} alt="cross" />
                </td>
              ) : (
                <td>
                  <img src={checkmark} alt="checkmark" />
                </td>
              )}
            </tr>
            <tr>
              <th className="variant">{Tips('Rage clicks & Error clicks')}</th>
              {RageClicksLS ? (
                <td className="cross">
                  <img src={cross} alt="cross" />
                </td>
              ) : (
                <td>
                  <img src={checkmark} alt="checkmark" />
                </td>
              )}
              {RageClicksCompany ? (
                <td className="cross">
                  <img src={cross} alt="cross" />
                </td>
              ) : (
                <td>
                  <img src={checkmark} alt="checkmark" />
                </td>
              )}
            </tr>
            <tr>
              <th className="variant">{Tips('Custom events')}</th>
              {CustomEventsLS ? (
                <td className="cross">
                  <img src={cross} alt="cross" />
                </td>
              ) : (
                <td>
                  <img src={checkmark} alt="checkmark" />
                </td>
              )}
              {CustomEventsCompany ? (
                <td className="cross">
                  <img src={cross} alt="cross" />
                </td>
              ) : (
                <td>
                  <img src={checkmark} alt="checkmark" />
                </td>
              )}
            </tr>
            <tr>
              <th className="variant">{Tips('Custom properties')}</th>
              {CustomPropertiesLS ? (
                <td className="cross">
                  <img src={cross} alt="cross" />
                </td>
              ) : (
                <td>
                  <img src={checkmark} alt="checkmark" />
                </td>
              )}
              {CustomPropertiesCompany ? (
                <td className="cross">
                  <img src={cross} alt="cross" />
                </td>
              ) : (
                <td>
                  <img src={checkmark} alt="checkmark" />
                </td>
              )}
            </tr>
            <tr>
              <th className="variant">{Tips('User identification')}</th>
              {UserIdentificationLS ? (
                <td className="cross">
                  <img src={cross} alt="cross" />
                </td>
              ) : (
                <td>
                  <img src={checkmark} alt="checkmark" />
                </td>
              )}
              {UserIdentificationCompany ? (
                <td className="cross">
                  <img src={cross} alt="cross" />
                </td>
              ) : (
                <td>
                  <img src={checkmark} alt="checkmark" />
                </td>
              )}
            </tr>
            <tr>
              <th className="variant">{Tips('Inspect mode')}</th>
              {InspectModeLS ? (
                <td className="cross">
                  <img src={cross} alt="cross" />
                </td>
              ) : (
                <td>
                  <img src={checkmark} alt="checkmark" />
                </td>
              )}
              {InspectModeCompany ? (
                <td className="cross">
                  <img src={cross} alt="cross" />
                </td>
              ) : (
                <td>
                  <img src={checkmark} alt="checkmark" />
                </td>
              )}
            </tr>
            <tr>
              <th className="variant">{Tips('Segmentation')}</th>
              {SegmentationLS ? (
                <td className="cross">
                  <img src={cross} alt="cross" />
                </td>
              ) : (
                <td>
                  <img src={checkmark} alt="checkmark" />
                </td>
              )}
              {SegmentationCompany ? (
                <td className="cross">
                  <img src={cross} alt="cross" />
                </td>
              ) : (
                <td>
                  <img src={checkmark} alt="checkmark" />
                </td>
              )}
            </tr>
            <tr>
              <th className="variant">{Tips('Recording rules')}</th>
              {RecordingLS ? (
                <td className="cross">
                  <img src={cross} alt="cross" />
                </td>
              ) : (
                <td>
                  <img src={checkmark} alt="checkmark" />
                </td>
              )}
              {RecordingCompany ? (
                <td className="cross">
                  <img src={cross} alt="cross" />
                </td>
              ) : (
                <td>
                  <img src={checkmark} alt="checkmark" />
                </td>
              )}
            </tr>
            <tr>
              <th className="variant">{Tips('Alerts')}</th>
              {EventAlertsLS ? (
                <td className="cross">
                  <img src={cross} alt="cross" />
                </td>
              ) : (
                <td>
                  <img src={checkmark} alt="checkmark" />
                </td>
              )}
              {EventAlertsCompany ? (
                <td className="cross">
                  <img src={cross} alt="cross" />
                </td>
              ) : (
                <td>
                  <img src={checkmark} alt="checkmark" />
                </td>
              )}
            </tr>
            <tr>
              <th className="variant">{Tips('Always-on recordings')}</th>
              {AlwaysOnRecordingLS ? (
                <td className="cross">
                  <img src={cross} alt="cross" />
                </td>
              ) : (
                <td>
                  <img src={checkmark} alt="checkmark" />
                </td>
              )}
              {AlwaysOnRecordingCompany ? (
                <td className="cross">
                  <img src={cross} alt="cross" />
                </td>
              ) : (
                <td>
                  <img src={checkmark} alt="checkmark" />
                </td>
              )}
            </tr>
            <tr>
              <th className="variant">{Tips('Full SPA support')} </th>
              {FullSPASupportLS ? (
                <td className="cross">
                  <img src={cross} alt="cross" />
                </td>
              ) : (
                <td>
                  <img src={checkmark} alt="checkmark" />
                </td>
              )}
              {FullSPASupportCompany ? (
                <td className="cross">
                  <img src={cross} alt="cross" />
                </td>
              ) : (
                <td>
                  <img src={checkmark} alt="checkmark" />
                </td>
              )}
            </tr>
          </tbody>
        </table>
      </Styled.Section>
    </div>
  );
};

export default PlanTable;
PlanTable.propTypes = {
  SessionReplayLS: PropTypes.string.isRequired,
  SessionReplayCompany: PropTypes.string.isRequired,
  DevToolsLS: PropTypes.string.isRequired,
  DevToolsCompany: PropTypes.string.isRequired,
  ClickmapsLS: PropTypes.string.isRequired,
  ClickmapsCompany: PropTypes.string.isRequired,
  EngagementScoreLS: PropTypes.string.isRequired,
  EngagementScoreCompany: PropTypes.string.isRequired,
  RageClicksLS: PropTypes.string.isRequired,
  RageClicksCompany: PropTypes.string.isRequired,
  CustomEventsLS: PropTypes.string.isRequired,
  CustomEventsCompany: PropTypes.string.isRequired,
  CustomPropertiesLS: PropTypes.string.isRequired,
  CustomPropertiesCompany: PropTypes.string.isRequired,
  UserIdentificationLS: PropTypes.string.isRequired,
  UserIdentificationCompany: PropTypes.string.isRequired,
  InspectModeLS: PropTypes.string.isRequired,
  InspectModeCompany: PropTypes.string.isRequired,
  SegmentationLS: PropTypes.string.isRequired,
  SegmentationCompany: PropTypes.string.isRequired,
  RecordingLS: PropTypes.string.isRequired,
  RecordingCompany: PropTypes.string.isRequired,
  EventAlertsLS: PropTypes.string.isRequired,
  EventAlertsCompany: PropTypes.string.isRequired,
  AlwaysOnRecordingLS: PropTypes.string.isRequired,
  AlwaysOnRecordingCompany: PropTypes.string.isRequired,
  FullSPASupportLS: PropTypes.string.isRequired,
  FullSPASupportCompany: PropTypes.string.isRequired,
  logoCompany: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
};
