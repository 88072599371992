import styled from 'styled-components';
import { StyledLink } from 'components/ui/link';
import { media } from 'theme/breakpoints';

export const Banner = styled.a`
  background-color: #f5f5f5;
  width: 100%;
  padding: 48px 0 48px 64px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 147px;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 72px;
  align-items: center;
  transition: 0.2s ease;
  color: #000;
  position: relative;
  ${media.desktopWide} {
    padding: 48px;
    margin-bottom: 48px;
    grid-column-gap: 22px;
  }
  ${media.desktop} {
    padding: 24px 32px 0;
    margin-bottom: 48px;
    grid-column-gap: 5px;
    grid-template-columns: 1fr;
  }
  ${media.tablet} {
    display: none;
  }
  ${StyledLink} {
    font-size: 16px;
    line-height: normal;
    margin: 0;
    ${media.desktopWide} {
      font-size: 14px;
    }
  }
  & img.main-img {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 372px;
    width: 480px;
    ${media.desktopWide} {
      width: 55%;
      height: 100%;
    }
    ${media.desktop} {
      display: none;
    }
  }
  &:hover {
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
    text-decoration: none;
    color: #000;
    & ${StyledLink} {
      text-decoration: underline;
      &::after {
        transform: translateX(8px);
      }
    }
  }
  &:focus {
    color: #000;
    outline: 0;
  }
`;

export const Content = styled.div`
  ${media.desktop} {
    padding-bottom: 24px;
  }
  & img {
    max-width: 100px;
    margin-bottom: 16px;
    ${media.desktopWide} {
      max-width: 80px;
    }
  }
  & h3 {
    font-weight: 800;
    font-size: 22px;
    line-height: 34px;
    margin-bottom: 16px;
    ${media.desktopWide} {
      font-size: 16px;
      line-height: 24px;
    }
  }
  & p:first-of-type {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;
    ${media.desktopWide} {
      display: none;
    }
  }
`;

export const Badge = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  ${media.desktopWide} {
    margin-bottom: 16px;
  }
  & img {
    width: 24px;
    height: 22px;
    margin-right: 8px;
    ${media.desktopWide} {
      width: 12px;
      height: 12px;
      margin-right: 5px;
    }
  }
  & p {
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    ${media.desktopWide} {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;
