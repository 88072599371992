import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from 'layout';
import queryString from 'query-string';
import FeaturesSlider from 'components/index/featuresSlider';
import NewToolIntegrations from 'components/index/newToolStack';
import Modal from 'components/ui/modal';
import Link from 'components/ui/link';
import ProductHuntContent from 'components/layout/producthuntModal';
import Testimonial from 'components/index/testimonial';
import PersonalData from 'components/newCustomers/personalData';
import TrustedBy from 'components/ui/trustedBy';
import { cardsIndex } from 'data/index/cards';
import NewTrustedBy from 'components/index/newTrustedBy';
import AboutUs from 'components/index/aboutUs';
import SignupAndProductTour from 'components/ui/signupAndProductTour';
import { media } from 'theme/breakpoints';
import QuestionVideo from 'components/index/questionVideo';
import { SliderWrapper } from 'styles/index';
import styled from 'styled-components';
import hero from 'src/img/index/hero.png';
import { renderStars } from 'components/CustomersRate';

import getappBlack from 'img/companyLogo/getapp-logo.svg';
import g2Black from 'img/companyLogo/g2logo.png';
import capterralogoBlack from 'img/companyLogo/capterraLogo.svg';
import UpHeading from '../components/newCustomers/upHeading';

const HeroWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding-top: 208px;
  color: #000;

  .stars {
    display: flex;
    grid-template-columns: repeat(3, 1fr);
    flex-wrap: wrap;
    column-gap: 48px;
    row-gap: 24px;
    margin-top: 64px;

    & > div {
      display: flex;
      align-items: center;

      &.getapp {
        img {
          height: 18px !important;
        }
      }

      img {
        margin-right: 8px;
        width: 16px;

        &.logo {
          margin-right: 16px;
          height: 20px;
          width: auto;
        }
      }

      p {
        margin: 0 0 0 8px !important;
        line-height: normal;
        font-size: 14px;
      }
    }

    ${media.mobile}, ${media.tablet} {
      display: none;
    }
  }

  ${media.mobile} {
    padding-top: 120px;
  }
  h1 {
    font-weight: 900;
    text-align: center;
    margin-bottom: 0;
    font-size: 64px;
    line-height: 1.3;

    ${media.desktop} {
      font-size: 32px;
    }
  }
  p {
    text-align: center;
    ::after {
      content: '';
      color: #000;
    }
  }
  .headerContent {
    padding-top: 24px;
    padding-bottom: 24px;
    .analizeText {
      max-width: 650px;
      margin-bottom: 0;
      ${media.desktop} {
        width: 100%;
      }
    }
    p {
      margin: 0 auto;
      display: block;
      text-align: center;
      font-size: 22px;
      margin-bottom: 24px;
      line-height: 32px;
      max-width: 960px;

      ${media.desktop} {
        display: inline;
        font-size: 18px;
        line-height: 26px;
      }
    }
  }
  form {
    justify-content: center;
  }
  div {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  button {
    background-color: #f8d047;
    color: black;
    :hover {
      background-color: #f5f3f5;
    }
  }
`;

const BackgroundContainer = styled.div`
  width: 100%;
  background: rgb(248, 208, 71);
  background: linear-gradient(180deg, #fffaea 0%, rgba(255, 255, 255, 0) 100%);
`;
const WaveWrapper = styled.div`
  width: 100%;
  height: calc(643px / 2 + 80px);
  ${media.desktopWide} {
    height: calc(537px / 2 + 80px);
  }
  ${media.desktop} {
    height: calc(399px / 2 + 80px);
  }
  ${media.tablet} {
    height: calc(290px / 2 + 40px);
  }
  ${media.mobile} {
    height: calc(196px / 2 + 40px);
  }
`;
const VideoWrapper = styled.div`
  margin: 0 auto;
  max-width: 1110px;
  text-align: center;
  position: relative;
  margin-top: calc(-642px / 2);
  ${media.desktopWide} {
    margin-top: calc(-537px / 2);
  }
  ${media.desktop} {
    margin-top: calc(-399px / 2);
  }
  ${media.tablet} {
    margin-top: calc(-290px / 2);
  }
  ${media.mobile} {
    margin-top: calc(-196px / 2);
  }
  .replayText {
    max-width: 550px;
    margin: 0 auto;
  }
  .image-container {
    position: relative;
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: 0.5s ease;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-container:hover .overlay {
    opacity: 1;
  }

  .image-container img {
    border-radius: 8px;
  }

  h2 {
    padding: 80px 0 42px;
    ${media.tablet} {
      padding: 42px 0 24px;
    }
  }
  p {
    font-weight: 700;
  }
  img {
    ${media.mobile} {
      width: 340px;
    }
  }
`;

const IndexPage = () => {
  const [isModalActive, setModalActive] = useState(false);

  useEffect(() => {
    const data = queryString.parse(window.location.search);
    if (data.ref === 'producthunt') {
      setModalActive(true);
    }
  }, []);

  const { session, events, metrics, funnels, devtools, heatmap } = useStaticQuery(graphql`
    {
      session: file(relativePath: { eq: "ecommerce/session-replay-feature.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      events: file(relativePath: { eq: "ecommerce/custom-events.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }

      metrics: file(relativePath: { eq: "ecommerce/metrics.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      funnels: file(relativePath: { eq: "ecommerce/funnels-new.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      devtools: file(relativePath: { eq: "ecommerce/devtools-new.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      heatmap: file(relativePath: { eq: "ecommerce/click&heatmaps.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  const dataImage = {
    images: [session, events, metrics, funnels, devtools, heatmap],
    alt: [
      'advanced filtering to help you focus on only the most important recordings',
      'measure your product KPIs',
      `organizing visitors' paths into funnels`,
      'the console logs allow you to see errors',
      'visualize the hottest sections of your site ',
    ],
  };

  return (
    <Layout
      metaTitle="LiveSession: Product Analytics | Session Replay | Funnels | Bug Tracking"
      metaDescription="LiveSession helps you analyze users’ behavior, improve UX, find bugs, and increase conversion rates using session replays, and event-based product analytics."
      titleOverride
      canonical="/"
      blockScrollX
      addBanner
    >
      <BackgroundContainer>
        <HeroWrapper className="container">
          <UpHeading headingText="PRODUCT ANALYTICS SOFTWARE" />
          <h1 className="line">
            An <span>all-in-one</span> platform for product and digital experience analytics
          </h1>
          <div className="headerContent">
            <p>
              LiveSession helps product teams analyze users’ behavior, improve UX, find bugs, and
              increase conversion rates using session replays, and event-based product analytics.
            </p>
          </div>
          <SignupAndProductTour />
          <div className="stars">
            <div className="capterra">
              <img
                alt="Capterra"
                className="logo"
                src={capterralogoBlack}
                style={{ marginRight: 16 }}
              />
              {renderStars(4)}{' '}
              <p>
                <strong>{4.6}</strong>/5
              </p>
            </div>
            <div className="getapp">
              <img alt="GetApp" className="logo" src={getappBlack} style={{ marginRight: 16 }} />
              {renderStars(4)}{' '}
              <p>
                <strong>{4.6}</strong>/5
              </p>
            </div>
            <div className="g2">
              <img alt="G2" className="logo" src={g2Black} style={{ marginRight: 16 }} />
              {renderStars(4)}{' '}
              <p>
                <strong>{4.7}</strong>/5
              </p>
            </div>
          </div>
        </HeroWrapper>
        <WaveWrapper />
      </BackgroundContainer>
      <div className="container">
        <VideoWrapper>
          <a href="/product-tour/">
            <div className="image-container">
              <img
                src={hero}
                alt="LiveSession - Product Tour"
                title="LiveSession Product Tour"
                className="img-fluid"
              />
            </div>
          </a>
        </VideoWrapper>
        <div className="container p72">
          <TrustedBy saas style={{ padding: 0 }} />
        </div>
      </div>
      {isModalActive && (
        <Modal
          onClose={() => {
            // Cookies.set(HIDE_BANNER_COOKIE, '1', {
            //   expires: 10000,
            // });
            setModalActive(false);
          }}
        >
          <ProductHuntContent />
        </Modal>
      )}

      <SliderWrapper className="container">
        <div className="headingContainer">
          <h2 className="line">
            The <span>power</span> of combined analytics
          </h2>
          <p>
            Combine data from several tools to get the big picture. Draw right conclusions based on
            facts, not guesses.
          </p>
        </div>
        <FeaturesSlider
          images={dataImage.images}
          cards={cardsIndex}
          alternativeText={dataImage.alt}
        >
          <Link className="padding" withArrow href="/features/">
            See all features
          </Link>
        </FeaturesSlider>
      </SliderWrapper>

      <div className="pb16 pt-120-90">
        <QuestionVideo />
      </div>

      <div className="mpclear p-240-168-120-90">
        <Testimonial />
      </div>

      <PersonalData
        header="Private by default"
        paragraph="LiveSession gives you full control of what part of the page and application is recorded. We are GDPR and CCPA compliant. "
      />
      <div className="m-240-168-120-90">
        <NewToolIntegrations />
      </div>
      <NewTrustedBy />
      <AboutUs />
    </Layout>
  );
};

export default IndexPage;
