import styled from 'styled-components';
import { StyledLink } from 'components/ui/link';
import { StyledButton } from 'components/ui/button';
import { media } from 'theme/breakpoints';

export const Card = styled.a`
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 32px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.integrationPage ? 'center' : 'space-between')};
  color: #000;
  height: ${(props) => (props.integrationPage ? 'fit-content' : 'auto')};
  transition: box-shadow 0.2s ease;
  ${media.desktop} {
    padding: 24px;
  }
  &:focus {
    color: #000;
  }
  &:hover {
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
    cursor: pointer;
    text-decoration: none;
    color: #000;
    ${StyledLink} {
      text-decoration: ${(props) => (props.integrationPage ? 'none' : 'underline')};
      &::after {
        transform: ${(props) => (props.integrationPage ? 'translateX(0)' : 'translateX(8px)')};
      }
    }
  }
  &::before {
    content: '${(props) => props.badge}';
    position: absolute;
    top: 32px;
    right: 0;
    background-color: ${({ badgeColor }) => `${badgeColor}` || null};
    padding: 3px 12px 5px 7px;
    font-size: 12px;
    line-height: 15px;
    font-weight: 700;
    color: #000;
    border-radius: 4px 0px 0px 4px;
    display: ${(props) => (props.integrationPage ? 'none' : 'flex')};
    align-items: center;
    justify-content: center;
    color: ${(props) => (props.white ? '#fff' : '#000')};
    ${media.desktop} {
      font-size: 11px;
      line-height: 14px;
      top: 24px;
    }
  }

  & div {
    display: flex;
    flex-direction: column;
    justify-content: ${(props) => (props.integrationPage ? 'center' : 'flex-start')};
    align-items: ${(props) => (props.integrationPage ? 'center' : 'flex-start')};
    text-align: ${(props) => (props.integrationPage ? 'center' : 'left')};
    & img {
      max-width: 32px;
      max-height: 32px;
      margin-bottom: ${(props) => (props.integrationPage ? '16px' : '24px')};
      ${media.desktop} {
        max-width: 24px;
        max-height: 24px;
        margin-bottom: ${(props) => (props.integrationPage ? '20px' : '16px')};
      }
      ${media.tablet} {
        max-width: 32px;
        max-height: 32px;
        margin-bottom: ${(props) => (props.integrationPage ? '20px' : '24px')};
      }
    }
    & h3 {
      font-size: ${(props) => (props.integrationPage ? '24px' : '18px')};
      line-height: ${(props) => (props.integrationPage ? '36px' : '30px')};
      font-weight: 800;
      margin-bottom: ${(props) => (props.integrationPage ? '8px' : '16px')};
      ${media.desktop} {
        font-size: ${(props) => (props.integrationPage ? '24px' : '16px')};
        line-height: ${(props) => (props.integrationPage ? '36px' : '20px')};
      }
      ${media.tablet} {
        font-size: ${(props) => (props.integrationPage ? '24px' : '18px')};
        line-height: ${(props) => (props.integrationPage ? '36px' : '20px')};
      }
    }
    & p {
      font-size: ${(props) => (props.integrationPage ? '16px' : '14px')};
      line-height: ${(props) => (props.integrationPage ? '28px' : '24px')};
      margin-bottom: 16px;
      ${media.desktop} {
        font-size: ${(props) => (props.integrationPage ? '16px' : '12px')};
        line-height: ${(props) => (props.integrationPage ? '28px' : '18px')};
      }
      ${media.tablet} {
        font-size: ${(props) => (props.integrationPage ? '16px' : '14px')};
        line-height: ${(props) => (props.integrationPage ? '28px' : '24px')};
        margin-bottom: ${(props) => (props.integrationPage ? '16px' : '24px')};
      }
    }
  }
  ${StyledLink} {
    font-size: 14px;
    margin: 0;
    text-align: ${(props) => (props.integrationPage ? 'center' : 'left')};
    &:hover {
      text-decoration: underline;
      &::after {
        transform: translateX(8px);
      }
    }
  }
  ${StyledButton} {
    max-width: 100%;
    width: 100%;
    margin: 32px 0 0 0;
    &.btn-only {
      margin: 16px 0 0 0;
    }
    ${media.mobile} {
      max-width: 205px;
      margin: 32px auto 0;
      &.btn-only {
        margin: 16px auto 0;
      }
    }
  }
`;
