import Layout from 'layout';
import 'sass/theme/terms.scss';

const Tos = () => (
  <Layout
    metaTitle="Terms of Service Attachement II - Minimal Technical Requirements"
    canonical="/terms-minimal-requirements/"
  >
    <section className="terms">
      <h1>
        LIVESESSION SOFTWARE-AS-A-SERVICE <br />
        TERMS & CONDITIONS
      </h1>
      <h2>ATTACHMENT II – MINIMAL TECHNICAL REQUIREMENTS</h2>

      <ol type="1">
        <li>
          PC with a x86 processor with an operational system either of the Windows (7, 8, 8.1, 10 or
          newer), macOS (10.11, 10.12, 10.13 or newer) or Linux group (Ubuntu 14.04 or newer; or
          similar).
        </li>
        <li>
          Up-to-date internet browser build on the basis of one of the following systems:
          <ul>
            <li>GOOGLE CHROME (version) 66+;</li>
            <li>MOZILLA FIREFOX 59+;</li>
            <li>MICROSOFT EDGE 16+;</li>
            <li>APPLE SAFARI 11+;</li>
            <li>OPERA 53+.</li>
          </ul>
        </li>
      </ol>
    </section>
  </Layout>
);

export default Tos;
