/* eslint-disable jsx-a11y/anchor-is-valid */

import PropTypes from 'prop-types';
import withIntegrationCardContext from 'hoc/withIntegrationCardContext';
import Slider from 'react-slick';

const Shopify = ({ sliderSettings }) => {
  const photos = [
    {
      img: 'https://res.cloudinary.com/livesession/image/upload/v1711328638/marketplace/shopify/Session-replay-v3_plswdz.png',
      alt: 'Shopify Integration',
      title: 'Integrate Shopify with LiveSession',
    },
    {
      img: 'https://res.cloudinary.com/livesession/image/upload/v1711328479/marketplace/shopify/Funnels-v3_zsmxh8.png',
      alt: 'Shopify Integration',
      title: 'Integrate Shopify with LiveSession',
    },
    {
      img: 'https://res.cloudinary.com/livesession/image/upload/v1711328478/marketplace/shopify/Clickmaps-Heatmaps-v3_exozub.png',
      alt: 'Shopify Integration',
      title: 'Integrate Shopify with LiveSession',
    },
    {
      img: 'https://res.cloudinary.com/livesession/image/upload/v1711328479/marketplace/shopify/Dev-tools-v3_ccrmrq.png',
      alt: 'Shopify Integration',
      title: 'Integrate Shopify with LiveSession',
    },
    {
      img: 'https://res.cloudinary.com/livesession/image/upload/v1711328480/marketplace/shopify/Metrics-v3_tfuarz.png',
      alt: 'Shopify Integration',
      title: 'Integrate Shopify with LiveSession',
    },
  ];

  return (
    <>
      <Slider {...sliderSettings(photos)}>
        {photos.map((photo) => (
          <div key={photo.title}>
            <img src={photo.img} alt={photo.alt} title={photo.title} className="img-fluid" />
          </div>
        ))}
      </Slider>

      <div className="section">
        <h2>Overview</h2>
        <p>
          Integrating LiveSession with your Shopify store gives you the power to deeply understand
          your customer&apos;s behaviors and sales trends. This integration is packed with features
          that allow you to analyze everything from purchases and abandoned carts to checkout
          drop-offs. It&apos;s designed to boost your sales performance by providing advanced user
          tracking tools, offering a comprehensive view of the customer journey.
        </p>
      </div>
      <div className="section">
        <h2>Benefits</h2>
        <ul className="checkmarks">
          <li>
            Watch real-time customer journeys with Session Replays. You&apos;ll be able to see how
            customers navigate your store, identify patterns, and discover ways to reduce cart
            abandonment.
          </li>
          <li>
            With Funnels, you can pinpoint where and why customers are leaving your shopping
            process. Integrated with session recordings, this feature gives you valuable insights to
            enhance user experience and conversion rates.
          </li>
          <li>
            Use LiveSession&apos;s advanced Metrics to measure and analyze your Key Performance
            Indicators (KPIs). This tool gives you a detailed look at customer interactions, helping
            you to optimize your strategies and improve the overall user experience.
          </li>
          <li>
            Find out which parts of your store are capturing the most customer attention with
            Heatmaps. This visual tool lets you see what draws in your shoppers and how they
            interact with your store&apos;s layout and content.
          </li>
          <li>
            Keep track of key interactions during the checkout process. Event tracking allows you to
            monitor these critical touchpoints and gain data-driven insights to refine your sales
            strategies.
          </li>
        </ul>
      </div>
      <h2>How to install</h2>
      <p>
        Installing LiveSession in your Shopify store is a breeze: simply click `Install` in the
        Shopify App Store, sign in or create a new account, and immediately start collecting
        customer data with no complex setup required.
      </p>
    </>
  );
};

export default withIntegrationCardContext(Shopify);

Shopify.propTypes = {
  sliderSettings: PropTypes.func.isRequired,
};
