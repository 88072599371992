import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const ShareBar = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 88px;
  position: sticky;
  top: calc(50% - 80px);
  ${media.desktopWide} {
    display: none;
  }
  & li {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    margin-bottom: 8px;
    & button {
      width: 100%;
      height: 100%;
      padding: 10px !important;
      transition: transform 0.2s ease;
      &:hover {
        transform: scale(1.08);
      }
    }
  }
`;
