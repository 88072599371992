// Code generated by LiveSession pricing generator; DO NOT EDIT.
export const Pricing = {
  plans_pricing: {
    basic: {
      range: { min: 5e3, max: 5e4 },
      prices: [
        {
          from: 1,
          to: 4,
          price: {
            EUR: { monthly: 10.92, annual: 10.92 },
            PLN: { monthly: 52, annual: 52 },
            USD: { monthly: 13, annual: 13 },
          },
        },
        {
          from: 5,
          to: 9,
          price: {
            EUR: { monthly: 8.23, annual: 8.23 },
            PLN: { monthly: 40, annual: 40 },
            USD: { monthly: 9.8, annual: 9.8 },
          },
        },
        {
          from: 10,
          to: 24,
          price: {
            EUR: { monthly: 6.3, annual: 6.3 },
            PLN: { monthly: 30, annual: 30 },
            USD: { monthly: 7.5, annual: 7.5 },
          },
        },
        {
          from: 25,
          to: 49,
          price: {
            EUR: { monthly: 5.04, annual: 5.04 },
            PLN: { monthly: 24, annual: 24 },
            USD: { monthly: 6, annual: 6 },
          },
        },
        {
          from: 50,
          to: 74,
          price: {
            EUR: { monthly: 4.2, annual: 4.2 },
            PLN: { monthly: 20, annual: 20 },
            USD: { monthly: 5, annual: 5 },
          },
        },
        {
          from: 75,
          to: 100,
          price: {
            EUR: { monthly: 3.36, annual: 3.36 },
            PLN: { monthly: 16, annual: 16 },
            USD: { monthly: 4, annual: 4 },
          },
        },
        {
          from: 101,
          to: 0,
          price: {
            EUR: { monthly: 3.31, annual: 3.31 },
            PLN: { monthly: 15, annual: 15 },
            USD: { monthly: 3.95, annual: 3.95 },
          },
        },
      ],
      features: {
        always_on_recording: true,
        team_members: 5,
        websites: true,
        click_maps: true,
        heat_maps: true,
        engagement_score: true,
        funnels: true,
        multi_factor_authentication: true,
        recording_rules: true,
        user_journey: true,
        tagging: true,
        segments: true,
        rage_and_error_clicks: true,
        skip_inactivity: true,
        dashboards: true,
        filters: true,
        speed_up_session_replay: true,
        user_identification: true,
        custom_properties: 10,
        min_duration: true,
        notes: true,
        customizable_dashboards: true,
        integrations: true,
        premium_integrations: ['intercom', 'zendesk', 'livechat'],
        resource_synchronization: true,
        segment_notifications: true,
        rest_api: false,
        developer_tools: false,
        custom_events: false,
        audit_logs: false,
        sso: false,
        webhooks: false,
        priority_support: false,
        data_export: false,
        customer_success_manager: false,
        custom_user_access: false,
        custom_invoicing: false,
        organizations: false,
        session_sharing: true,
        alerts: false,
      },
      available_retentions: [1],
    },
    enterprise: {
      range: { min: 1e5, max: 5e7 },
      prices: null,
      features: {
        always_on_recording: true,
        team_members: true,
        websites: true,
        click_maps: true,
        heat_maps: true,
        engagement_score: true,
        funnels: true,
        multi_factor_authentication: true,
        recording_rules: true,
        user_journey: true,
        tagging: true,
        segments: true,
        rage_and_error_clicks: true,
        skip_inactivity: true,
        dashboards: true,
        filters: true,
        speed_up_session_replay: true,
        user_identification: true,
        custom_properties: true,
        min_duration: true,
        notes: true,
        customizable_dashboards: true,
        integrations: true,
        premium_integrations: ['*'],
        resource_synchronization: true,
        segment_notifications: true,
        rest_api: true,
        developer_tools: true,
        custom_events: true,
        audit_logs: true,
        sso: true,
        webhooks: true,
        priority_support: true,
        data_export: true,
        customer_success_manager: true,
        custom_user_access: true,
        custom_invoicing: true,
        organizations: true,
        session_sharing: true,
        alerts: true,
      },
      available_retentions: [1],
    },
    freemium: {
      range: { min: 0, max: 1e3 },
      prices: null,
      features: {
        always_on_recording: true,
        team_members: 3,
        websites: 2,
        click_maps: true,
        heat_maps: true,
        engagement_score: true,
        funnels: 1,
        multi_factor_authentication: true,
        recording_rules: true,
        user_journey: true,
        tagging: false,
        segments: true,
        rage_and_error_clicks: true,
        skip_inactivity: true,
        dashboards: 1,
        filters: true,
        speed_up_session_replay: true,
        user_identification: true,
        custom_properties: 3,
        min_duration: false,
        notes: false,
        customizable_dashboards: false,
        integrations: true,
        premium_integrations: [],
        resource_synchronization: false,
        segment_notifications: false,
        rest_api: false,
        developer_tools: false,
        custom_events: false,
        audit_logs: false,
        sso: false,
        webhooks: false,
        priority_support: false,
        data_export: false,
        customer_success_manager: false,
        custom_user_access: false,
        custom_invoicing: false,
        organizations: false,
        session_sharing: false,
        alerts: false,
      },
      available_retentions: [1],
    },
    professional: {
      range: { min: 1e4, max: 25e4 },
      prices: [
        {
          from: 1,
          to: 24,
          price: {
            EUR: { monthly: 8.31, annual: 8.29 },
            PLN: { monthly: 39.6, annual: 39.6 },
            USD: { monthly: 9.9, annual: 9.9 },
          },
        },
        {
          from: 25,
          to: 29,
          price: {
            EUR: { monthly: 6.68, annual: 6.66 },
            PLN: { monthly: 31.84, annual: 31.84 },
            USD: { monthly: 7.96, annual: 7.96 },
          },
        },
        {
          from: 30,
          to: 34,
          price: {
            EUR: { monthly: 6.3, annual: 6.28 },
            PLN: { monthly: 30, annual: 30 },
            USD: { monthly: 7.5, annual: 7.5 },
          },
        },
        {
          from: 35,
          to: 39,
          price: {
            EUR: { monthly: 5.88, annual: 5.86 },
            PLN: { monthly: 28, annual: 28 },
            USD: { monthly: 7, annual: 7 },
          },
        },
        {
          from: 40,
          to: 49,
          price: {
            EUR: { monthly: 5.46, annual: 5.44 },
            PLN: { monthly: 26, annual: 26 },
            USD: { monthly: 6.5, annual: 6.5 },
          },
        },
        {
          from: 50,
          to: 59,
          price: {
            EUR: { monthly: 5, annual: 4.98 },
            PLN: { monthly: 23.92, annual: 23.92 },
            USD: { monthly: 5.98, annual: 5.98 },
          },
        },
        {
          from: 60,
          to: 74,
          price: {
            EUR: { monthly: 4.41, annual: 4.39 },
            PLN: { monthly: 21, annual: 21 },
            USD: { monthly: 5.25, annual: 5.25 },
          },
        },
        {
          from: 75,
          to: 99,
          price: {
            EUR: { monthly: 4.19, annual: 4.17 },
            PLN: { monthly: 19.96, annual: 19.96 },
            USD: { monthly: 4.99, annual: 4.99 },
          },
        },
        {
          from: 100,
          to: 199,
          price: {
            EUR: { monthly: 4.15, annual: 4.13 },
            PLN: { monthly: 19.8, annual: 19.8 },
            USD: { monthly: 4.95, annual: 4.95 },
          },
        },
        {
          from: 200,
          to: 499,
          price: {
            EUR: { monthly: 3.82, annual: 3.8 },
            PLN: { monthly: 18.2, annual: 18.2 },
            USD: { monthly: 4.55, annual: 4.55 },
          },
        },
        {
          from: 500,
          to: 0,
          price: {
            EUR: { monthly: 3.65, annual: 3.63 },
            PLN: { monthly: 17.4, annual: 17.4 },
            USD: { monthly: 4.35, annual: 4.35 },
          },
        },
      ],
      features: {
        always_on_recording: true,
        team_members: 10,
        websites: true,
        click_maps: true,
        heat_maps: true,
        engagement_score: true,
        funnels: true,
        multi_factor_authentication: true,
        recording_rules: true,
        user_journey: true,
        tagging: true,
        segments: true,
        rage_and_error_clicks: true,
        skip_inactivity: true,
        dashboards: true,
        filters: true,
        speed_up_session_replay: true,
        user_identification: true,
        custom_properties: 50,
        min_duration: true,
        notes: true,
        customizable_dashboards: true,
        integrations: true,
        premium_integrations: ['*'],
        resource_synchronization: true,
        segment_notifications: true,
        rest_api: true,
        developer_tools: true,
        custom_events: true,
        audit_logs: true,
        sso: true,
        webhooks: true,
        priority_support: true,
        data_export: true,
        customer_success_manager: false,
        custom_user_access: false,
        custom_invoicing: false,
        organizations: false,
        session_sharing: true,
        alerts: true,
      },
      available_retentions: [1, 3],
    },
  },
  plans_paid: { basic: 'basic', enterprise: 'enterprise', professional: 'professional' },
  plans_all: {
    basic: 'basic',
    enterprise: 'enterprise',
    freemium: 'freemium',
    professional: 'professional',
  },
  integrations: { intercom: 'intercom', livechat: 'livechat', zendesk: 'zendesk' },
  sessions_per_unit: 1e3,
  features: {
    alerts: 'alerts',
    always_on_recording: 'always_on_recording',
    audit_logs: 'audit_logs',
    click_maps: 'click_maps',
    custom_events: 'custom_events',
    custom_invoicing: 'custom_invoicing',
    custom_properties: 'custom_properties',
    custom_user_access: 'custom_user_access',
    customer_success_manager: 'customer_success_manager',
    customizable_dashboards: 'customizable_dashboards',
    dashboards: 'dashboards',
    data_export: 'data_export',
    developer_tools: 'developer_tools',
    engagement_score: 'engagement_score',
    filters: 'filters',
    funnels: 'funnels',
    heat_maps: 'heat_maps',
    integrations: 'integrations',
    min_duration: 'min_duration',
    multi_factor_authentication: 'multi_factor_authentication',
    notes: 'notes',
    organizations: 'organizations',
    premium_integrations: 'premium_integrations',
    priority_support: 'priority_support',
    rage_and_error_clicks: 'rage_and_error_clicks',
    recording_rules: 'recording_rules',
    resource_synchronization: 'resource_synchronization',
    rest_api: 'rest_api',
    segment_notifications: 'segment_notifications',
    segments: 'segments',
    session_sharing: 'session_sharing',
    skip_inactivity: 'skip_inactivity',
    speed_up_session_replay: 'speed_up_session_replay',
    sso: 'sso',
    tagging: 'tagging',
    team_members: 'team_members',
    user_identification: 'user_identification',
    user_journey: 'user_journey',
    webhooks: 'webhooks',
    websites: 'websites',
  },
};
