import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import { StyledParagraph } from 'components/ui/Paragraph';

export const Section = styled.section`
  padding: 0;
  & h2 {
    font-size: 36px;
    margin-bottom: 24px;
    ${media.tablet} {
      font-size: 32px;
    }
  }
  & ${StyledParagraph} {
    margin-bottom: 40px;
    line-height: 24px;
    ${media.mobile} {
      margin-bottom: 32px;
      font-size: 16px;
      line-height: 20px;
    }
  }
  .signupAndDemo {
    display: inline-block;
    padding: 0 16px;
  }
  .signupAndDemo {
    ${media.mobile} {
      padding: 0px 8px;
      float: left;
    }
  }
`;
