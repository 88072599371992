import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import { StyledButton } from 'components/ui/button';

export const StyledSection = styled.section`
  min-height: 100vh;
  width: 40%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & h1,
  p,
  ${StyledButton} {
    margin-top: 40px;
    margin-bottom: 0;
  }
  & h1 {
    font-size: 54px;
    font-weight: 800;
  }
  & p {
    font-weight: 400;
    font-size: 22px;
    line-height: 36px;
  }
  ${media.tablet} {
    width: 75%;
  }
  ${media.mobile} {
    width: 90%;
    & h1 {
      font-size: 28px;
    }
    & p {
      font-size: 18px;
    }
    & {
      margin-top: 24px;
    }
    & h1,
    p,
    ${StyledButton} {
      margin-top: 24px;
    }
  }
`;
