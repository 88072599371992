/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
// import Cookies from 'js-cookie';
// import { graphql, useStaticQuery } from 'gatsby';
import Link from 'components/ui/link';
import Logo from 'components/layout/logo';
import Hamburger from 'components/layout/hamburger';
import Button from 'components/ui/button';
import optimization from 'img/solutions/icons/optimization.svg';
import tracking from 'img/solutions/icons/tracking.svg';
import testing from 'img/solutions/icons/testing.svg';
import debugging from 'img/solutions/icons/debugging.svg';
import support from 'img/solutions/icons/support.svg';
import marketing from 'img/solutions/icons/marketing.svg';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
// import { useLocation } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import * as Styled from './styled';

const dropdown = [
  {
    name: 'Product Tour',
    url: '/product-tour/',
  },
  {
    name: 'Features',
    url: '/features/',
  },
  {
    name: 'Pricing',
    url: '/pricing/',
  },
  {
    name: 'Customers',
    url: '/customers/',
  },
  {
    name: 'Integrations',
    url: '/integrations/',
  },
  {
    name: 'Help',
    url: '/help/',
  },
  {
    name: 'Blog',
    url: '/blog/',
  },
  {
    name: 'Get a demo',
    url: '/demo/',
  },
];

// const COOKIE_DOMAIN =
//   process.env.GATSBY_HOST === 'http://localhost:8000' ? 'localhost' : '.livesession.io';
// const HIDE_BANNER_COOKIE = 'hide_banner';

// eslint-disable-next-line no-unused-vars
const Header = ({ font, photoColor, textBanner, addBanner, hideBorder, btnColor }) => {
  const {
    blogpost: { edges },
  } = useStaticQuery(graphql`
    query HeaderBlogPost {
      blogpost: allBlogJson(sort: { fields: date, order: DESC }, limit: 1) {
        edges {
          node {
            slug
            title
          }
        }
      }
    }
  `);

  const article = edges[0].node;
  // const [isBarActive, setCookieBar] = useState(false);
  const [showMobileMenu, setMobileMenu] = useState(false);

  const navigation = useRef(null);
  const banner = useRef(null);
  // const location = useLocation();

  const listenToScroll = useCallback(() => {
    const position = window.pageYOffset;
    if (addBanner && position > 10) {
      navigation.current.classList.add('fixed-bg-banner');
    } else if (addBanner && position < 10) {
      navigation.current.classList.remove('fixed-bg-banner');
    }
    if (position > 50) {
      navigation.current.classList.add('fixed-bg', 'fixed-bg-black', 'fix-hamburger');
    } else {
      navigation.current.classList.remove('fixed-bg', 'fixed-bg-black', 'fix-hamburger');
    }
  }, [addBanner]);

  const handleMobileMenu = () => {
    if (window.innerWidth > 1024) {
      setMobileMenu(false);
    }
  };

  useEffect(() => {
    // const acceptedCookies = Cookies.get(HIDE_BANNER_COOKIE);
    // if (acceptedCookies !== '1') {
    //   setCookieBar(true);
    // }
    listenToScroll();
    window.addEventListener('scroll', listenToScroll);
    window.addEventListener('resize', handleMobileMenu);
    return () => {
      window.removeEventListener('scroll', listenToScroll);
      window.removeEventListener('resize', handleMobileMenu);
    };
  }, [listenToScroll]);

  // const handleHideBanner = () => {
  //   Cookies.set(HIDE_BANNER_COOKIE, '1', {
  //     path: '/',
  //     domain: COOKIE_DOMAIN,
  //     expires: 10000,
  //   });
  //   setCookieBar(false);
  // };

  const navigationBehavior = () => {
    setMobileMenu(!showMobileMenu);

    if (showMobileMenu && window.pageYOffset > 10) {
      setTimeout(() => {
        navigation.current.classList.add('fixed-bg', 'fixed-bg-black', 'fix-hamburger');
      }, 0);
      if (addBanner) {
        setTimeout(() => {
          navigation.current.classList.add('fixed-bg-banner');
        }, 0);
      }
    } else if (!showMobileMenu && window.pageYOffset > 10 && addBanner) {
      setTimeout(() => {
        navigation.current.classList.add('fixed-bg-banner');
      }, 0);
    }
  };

  return (
    <Styled.Navigation
      ref={navigation}
      color={font}
      backgroundColor={showMobileMenu ? '#fff' : null}
      borderBottom={showMobileMenu ? '1px solid #e4e5e8' : null}
      className={hideBorder ? 'hide-border' : ''}
    >
      {addBanner && (
        <Styled.Banner ref={banner} href={article.slug} target="_blank" rel="noopener noreferrer">
          <span>Blog</span>
          <p>{textBanner || article.title}</p>
          <Link withArrow yellow tag="p">
            Read more
          </Link>
        </Styled.Banner>
      )}
      <Styled.Container className="container">
        <Styled.Box>
          <Logo
            beta
            listenToScroll={listenToScroll}
            photoColor={photoColor}
            showMobileMenu={showMobileMenu}
          />
          {/* {location.pathname.split('/')[1] === 'integrations' ? (
            <Styled.Integrations href="/integrations/">
              <span>|</span>Integrations
            </Styled.Integrations>
          ) : null} */}
          <Styled.MenuButtonsContainer>
            <Styled.Menu>
              <Styled.Dropdown>
                <a href="/#" className={btnColor ? 'linkColor' : ''}>
                  Product
                </a>
                <Styled.DropdownMenu className="dropdown-menu" style={{ minWidth: 250 }}>
                  <li>
                    <Link href="/product-tour/">Product Tour</Link>
                  </li>
                  <li>
                    <Link href="/features/">Features</Link>
                  </li>
                  <Styled.SubMenu>
                    <a href="/#">Industries</a>
                    <ul>
                      <li>
                        <Styled.SubLink href="/industry/saas/">
                          <p>
                            <span>SaaS</span>
                            <span className="desc">
                              Track user behavior to improve acquisition, retention, and expansion
                            </span>
                          </p>
                        </Styled.SubLink>
                      </li>
                      <li>
                        <Styled.SubLink href="/industry/ecommerce/">
                          <p>
                            <span>eCommerce</span>
                            <span className="desc">
                              Sales tracking, cart recovery and conversion rate optimization
                            </span>
                          </p>
                        </Styled.SubLink>
                      </li>
                    </ul>
                  </Styled.SubMenu>
                  <Styled.SubMenu>
                    <a href="/solutions/">Solutions</a>
                    <ul className="solutions">
                      <li>
                        <Styled.SubLink tag={AnchorLink} to="/solutions#conversion-optimization">
                          <img src={optimization} alt="optimization" />
                          <p>
                            Conversion optimization
                            <span className="desc">Find opportunities for improvement</span>
                          </p>
                        </Styled.SubLink>
                      </li>
                      <li>
                        <Styled.SubLink tag={AnchorLink} to="/solutions#user-engagement-tracking">
                          <img src={tracking} alt="tracking" />
                          <p>
                            User engagement tracking
                            <span className="desc">Discover what works best</span>
                          </p>
                        </Styled.SubLink>
                      </li>
                      <li>
                        <Styled.SubLink tag={AnchorLink} to="/solutions#usability-testing">
                          <img src={testing} alt="testing" />
                          <p>
                            Usability testing
                            <span className="desc">See how users interact with your website</span>
                          </p>
                        </Styled.SubLink>
                      </li>
                      <li>
                        <Styled.SubLink tag={AnchorLink} to="/solutions#application-debugging">
                          <img src={debugging} alt="debugging" />
                          <p>
                            Application debugging
                            <span className="desc">Debug your application faster</span>
                          </p>
                        </Styled.SubLink>
                      </li>
                      <li>
                        <Styled.SubLink tag={AnchorLink} to="/solutions#customer-support">
                          <img src={support} alt="support" />
                          <p>
                            Customer support
                            <span className="desc">Improve your help center</span>
                          </p>
                        </Styled.SubLink>
                      </li>
                      <li>
                        <Styled.SubLink tag={AnchorLink} to="/solutions#performance-marketing">
                          <img src={marketing} alt="marketing" />
                          <p>
                            Performance marketing
                            <span className="desc">Boost your marketing results</span>
                          </p>
                        </Styled.SubLink>
                      </li>
                    </ul>
                  </Styled.SubMenu>
                  <li>
                    <Link href="/integrations/">Integrations</Link>
                  </li>
                </Styled.DropdownMenu>
              </Styled.Dropdown>
              <li>
                <Link href="/pricing/" className={btnColor ? 'linkColor' : ''}>
                  Pricing
                </Link>
              </li>
              <li>
                <Link href="/customers/" className={btnColor ? 'linkColor' : ''}>
                  Customers
                </Link>
              </li>
              <Styled.Dropdown>
                <a href="/#" className={btnColor ? 'linkColor' : ''}>
                  Resources
                </a>
                <Styled.DropdownMenu className="dropdown-menu resources" as="div">
                  <ul className="dropdown-resource-container">
                    <li>
                      <Link href="https://help.livesession.io/en/" style={{ color: font }}>
                        Help Center
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="https://developers.livesession.io/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        API & Developers
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="https://updates.livesession.io/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Updates
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="https://livesession.statuspal.io/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        System status
                      </Link>
                    </li>
                    <li>
                      <Link href="/partners/">Affiliate program</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Styled.SubLink href="/blog/">
                        <p>
                          <span>Blog</span>
                          <span className="desc">UX, conversion, analytics and more</span>
                        </p>
                      </Styled.SubLink>
                    </li>
                    <li>
                      <Styled.SubLink href="/resources/guides/">
                        <p>
                          <span>Guides</span>
                          <span className="desc">
                            In-depth guides about UX, Heatmaps, Usability Testing and more
                          </span>
                        </p>
                      </Styled.SubLink>
                    </li>
                    <li>
                      <Styled.SubLink href="/tutorials/">
                        <p>
                          <span>Tutorials</span>
                          <span className="desc">
                            Step-by-step tutorials to get even more from LiveSession
                          </span>
                        </p>
                      </Styled.SubLink>
                    </li>
                  </ul>
                </Styled.DropdownMenu>
              </Styled.Dropdown>
              <li>
                <Link href="/demo/" className={btnColor ? 'linkColor' : ''}>
                  Get a demo
                </Link>
              </li>
            </Styled.Menu>
            <Styled.DesktopButtons>
              <Styled.LoginButton
                href={process.env.GATSBY_APP_URL}
                className={btnColor ? 'log-in-button-pc linkWhite' : 'log-in-button-pc'}
              >
                Log in
              </Styled.LoginButton>
              <div>
                <Button
                  className={btnColor ? 'yellowBtn' : ''}
                  signUp
                  secondary
                  sourceID="header"
                  small
                >
                  Sign up free
                </Button>
              </div>
            </Styled.DesktopButtons>
          </Styled.MenuButtonsContainer>
        </Styled.Box>
        <Styled.MobileNavbar>
          <Logo
            beta
            listenToScroll={listenToScroll}
            photoColor={photoColor}
            showMobileMenu={showMobileMenu}
          />
          <div className={showMobileMenu ? '' : btnColor ? 'whiteHamburger' : ''}>
            <Hamburger showMobileMenu={showMobileMenu} font={font} onClick={navigationBehavior} />
          </div>
        </Styled.MobileNavbar>
        <Styled.MobileNavigation className={showMobileMenu && 'active'}>
          {dropdown.map(({ name, url }) => (
            <li key={url}>
              <Link href={url} style={{ color: font }}>
                {name}
              </Link>
            </li>
          ))}
          <Styled.Buttons>
            <Styled.SecondaryMobileButton
              href={process.env.GATSBY_APP_URL}
              className="mr-2"
              secondary
              small
            >
              Log in
            </Styled.SecondaryMobileButton>
            <Button small secondary signUp sourceID="header">
              Sign up free
            </Button>
          </Styled.Buttons>
        </Styled.MobileNavigation>
      </Styled.Container>
    </Styled.Navigation>
  );
};

export default Header;

Header.propTypes = {
  font: PropTypes.string.isRequired,
  btnColor: PropTypes.string.isRequired,

  photoColor: PropTypes.string.isRequired,
  textBanner: PropTypes.string,
  addBanner: PropTypes.bool,
  hideBorder: PropTypes.bool,
};

Header.defaultProps = {
  textBanner: null,
  addBanner: false,
  hideBorder: false,
};
