import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const GridContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-gap: 40px;
  padding-bottom: 0;
  ${media.desktop} {
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    text-align: center;
  }
  ${media.tablet} {
    padding-top: 80px;
    padding-bottom: 75px;
  }
  ${media.mobile} {
    grid-gap: 24px;
    padding-bottom: 45px;
  }
`;
