import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import { ChatWithUs } from 'components/ui/contactwithus';
import { GuestPostBox } from 'styles/blog';

export const Box = styled.section`
  ${media.mobile} {
    padding: 3.5rem 0;
  }
  ${ChatWithUs} {
    ${media.tablet} {
      display: block;
      margin: 24px 0 0;
    }
  }
`;

export const InfoBox = styled.div`
  display: flex;
  max-width: 1200px;
  min-height: 411px;
  margin: 0 auto;
  border-radius: 4px;
  background-color: #000;
  color: #fff;
  position: relative;
  ${media.tablet} {
    flex-basis: auto;
    flex-direction: column;
    max-width: auto;
    min-height: auto;
  }

  .left,
  .right {
    padding: 72px;
    flex-basis: 50%;
    ${media.desktop} {
      padding: 52px;
    }
    ${media.mobile} {
      padding: 32px;
    }
  }
  .left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 30px;
    h2 {
      text-align: left;
      font-weight: 800;
      font-size: 36px;
      margin: 0;
      ${media.mobile} {
        font-size: 22px;
      }
    }
  }
  .right {
    ${media.mobile} {
      padding-top: 8px;
    }
    h3 {
      font-size: 22px;
      text-align: left;
      font-weight: 800;
      margin: 0;
      ${media.mobile} {
        font-size: 16px;
      }
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 40px 0 0;
      ${media.mobile} {
        margin: 24px 0 0;
      }
      li {
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        display: flex;
        align-items: flex-start;
        margin-top: 1rem;
        ${media.mobile} {
          font-size: 14px;
          margin-top: 15px;
          line-height: 22px;
        }
        &:nth-child(1) {
          margin-top: 0;
        }
        i {
          color: #f8d047;
          font-size: 24px;
        }
      }
    }
  }
`;

export const Description = styled.p`
  font-size: 18px;
  font-weight: 500;
  margin: 24px 0 0;
  ${media.mobile} {
    font-size: 14px;
    margin: 16px 0 0;
    line-height: 22px;
  }
`;

export const ExtendGuestPostBox = styled(GuestPostBox)`
  padding: 40px 0;
  ${media.mobile} {
    padding: 0;
    & .box {
      align-self: flex-start;
    }
  }
`;
