import styled from 'styled-components';

export const Container = styled.div`
  & h3 {
    margin-bottom: 64px;
    margin-top: 128px;
    font-weight: 900;
  }

  & h4 {
    margin-bottom: 16px;
    margin-top: 48px;
    font-weight: 900;
    font-size: 18px;
    line-height: 24px;
  }
`;
