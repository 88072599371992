import { graphql, useStaticQuery } from 'gatsby';
import Layout from 'layout';
import Card from 'components/tutorials/card';
import Button from 'components/ui/button';
import StartUsing from 'components/ui/startUsing';
import { Header, StyledSection, CTASection } from 'styles/tutorialsPage';

const Tutorials = () => {
  const { img1, img2, img3 } = useStaticQuery(graphql`
    query AcademyPhotos {
      img1: file(relativePath: { eq: "tutorials/abtest.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 90)
        }
      }
      img2: file(relativePath: { eq: "tutorials/formanalytics.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 90)
        }
      }
      img3: file(relativePath: { eq: "tutorials/comingSoonBlue.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 90)
        }
      }
    }
  `);
  return (
    <Layout
      canonical="/tutorials/"
      metaTitle="Tutorials | Learn More with Us"
      metaDescription="Learn about A/B testing, conversion optimization, customer experience, and more. LiveSession Tutorials is free forever!"
    >
      <div className="container">
        <Header>
          <h1 className="line">
            LiveSession <span>Tutorials</span>
          </h1>
          <p className="subtitle">
            See how you can grow your business with LiveSession. Read our Tutorials and learn from
            the experts!
          </p>
        </Header>
        <StyledSection>
          <Card
            img={img1.childImageSharp.gatsbyImageData}
            title="Guide to A/B testing with LiveSession"
            content="A/B testing is no longer a niche optimization technique. Did you know that 77% of companies run A/B tests on their websites?"
            link="/tutorials/guide-to-ab-testing-with-livesession/"
            linkContent="Read the Tutorial"
          />
          <Card
            img={img2.childImageSharp.gatsbyImageData}
            title="Form Analytics"
            content="Online form analytics has been proven to successfully boost lead generation. How? LiveSession's tutorial will certainly help you out now!"
            link="/tutorials/form-analytics/"
            linkContent="Read the Tutorial"
          />
          <Card
            img={img3.childImageSharp.gatsbyImageData}
            title="Guide to Customer Support with LiveSession"
            content="In this guide, we’re going to show you how you can improve crucial customer support factors with LiveSession."
            comingSoon
          />
          {/* <Card
            comingSoon
            img={img1.childImageSharp.fluid}
            title="Guide to UX Design with LiveSession"
            content="Learn about user experience design and improve your UX with LiveSession."
          /> */}
        </StyledSection>
        <CTASection>
          <h2>Have a tutorial idea?</h2>
          <p className="subtitle">Let us know! We&apos;ll consider every suggestion.</p>
          <Button href="mailto:hello@livesession.io?subject=Tutorial%20idea">Send your idea</Button>
        </CTASection>
      </div>
      <StartUsing />
    </Layout>
  );
};

export default Tutorials;
