import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const Header = styled.header`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 80px;
  align-items: center;
  padding-top: 168px;
  padding-bottom: 80px;
  & .subtitle {
    margin-bottom: 40px;
  }
  & h1.line {
    & span {
      display: inline-block;
    }
  }
  ${media.desktop} {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    & img {
      margin: 0 auto;
    }
    & div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      & h1.line {
        & span {
          display: inline;
        }
      }
    }
  }
  ${media.tablet} {
    & div {
      & h1.line {
        & span {
          display: inline-block;
        }
      }
    }
  }
  ${media.mobile} {
    padding-top: 120px;
    grid-gap: 56px;
    padding-bottom: 60px;
  }
`;

export const Banner = styled.section`
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 56px 64px;
  margin: 32px 0 0;
  & p {
    margin: 0;
    font-size: 22px;
    line-height: 34px;
    text-align: center;
  }
  ${media.desktop} {
    margin: 70px 0 0;
    padding: 32px 40px;
    & p {
      font-size: 20px;
      line-height: 34px;
    }
  }
  ${media.mobile} {
    padding: 32px 16px;
    margin: 60px 0 0;
    & p {
      font-size: 18px;
      line-height: 30px;
    }
  }
`;

export const CTA = styled.section`
  background-color: #f8d047;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 72px 0;
  margin-bottom: 144px;
  margin-top: 60px;
  border-radius: 4px;
  & h2 {
    margin-bottom: 24px;
  }
  & p {
    margin-bottom: 40px;
  }
  ${media.desktop} {
    margin-top: 0;
  }
  ${media.mobile} {
    padding: 32px 24px;
    & h2 {
      font-size: 26px;
    }
    & p {
      text-align: center;
      margin-bottom: 32px;
    }
  }
`;

export const HowItWorks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & h2 {
    margin-bottom: 90px;
  }
  ${media.tablet} {
    & h2 {
      margin-bottom: 56px;
    }
  }
  ${media.mobile} {
    margin: 0;
    & h2 {
      font-size: 26px;
    }
  }
`;

export const BoxContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 56px;
  margin-bottom: 64px;
  ${media.desktop} {
    grid-gap: 32px;
  }
  ${media.tablet} {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    grid-gap: 56px;
    margin-bottom: 64px;
  }
  & div {
    ${media.tablet} {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 80%;
      margin: 0 auto;
    }
    & img {
      max-width: 48px;
      margin-bottom: 16px;
    }
    & h3 {
      margin-bottom: 16px;
      font-size: 24px;
      line-height: 34px;
      ${media.mobile} {
        font-size: 22px;
        line-height: 32px;
      }
    }
    & p {
      margin: 0;
      font-size: 16px;
      line-height: 26px;
    }
  }
`;
export const Thomas = styled.div`
  background-color: #f5f5f5;
  padding: 80px 0;
  section {
    padding: 0;
  }
`;
