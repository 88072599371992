import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const Wrapper = styled.section`
  ${media.desktop} {
    padding: 120px 0 !important;
  }
`;
export const WrapperInformation = styled.section`
  ${media.desktop} {
    padding-top: 120px !important;
  }
`;
export const WrapperStartUsing = styled.section`
  ${media.desktop} {
    padding: 120px auto !important;
  }
`;
