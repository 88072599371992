import styled from 'styled-components';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import { media } from 'theme/breakpoints';
import Layout from 'layout';
import arrow from 'img/ui/icon_arrow.svg';
import Tippy from '@tippyjs/react';

const StyledSection = styled.section`
  background-color: #f1f5f9;
  padding: 4rem 0;
  ${media.mobile} {
    padding: 2rem 0;
  }
  a {
    text-decoration: none;
    color: #0a4ed6;
    &.with-arrow {
      position: relative;
      display: inline-block;
      font-weight: 800;

      &::after {
        content: '';
        background-image: url(${arrow});
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        display: inline-block;
        clear: both;
        margin-left: 16px;
        width: 12px;
        height: 12px;
      }
    }
  }
  p {
    font-size: 16px;
    line-height: 24px;
    & a {
      display: inline;
    }
  }
  body {
    font-family: 'Muli', sans-serif;
    width: 100%;
    color: #000;
    background-color: #f1f5f9;
  }
  strong {
    font-weight: 800;
  }
  h1 {
    font-size: 30px;
    line-height: 38px;
    font-weight: 300;
  }
  h1 span {
    font-weight: 800;
  }
  .logo {
    display: block;
    margin: 0 auto 40px;
    max-width: 140px;
    height: 21px;
  }
  .head {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
  .hero-img {
    padding: 0 0 24px !important;
  }
  .text {
    width: 100%;
  }
  .content {
    max-width: 600px;
    margin: 0 auto;
    background-color: #fff;
  }
  .go-to-app {
    font-size: 16px;
    color: #fff;
  }
  .logo-bottom {
    display: block;
    margin: 40px auto 0;
    max-width: 104px;
    height: 16px;
  }
  .credits {
    opacity: 0.5;
    font-size: 14px;
    line-height: 28px;
    margin: 32px 0;
    text-align: center;
    width: 100%;
  }
  .bottom-info {
    width: 100%;
    text-align: center;
  }
  .bottom-info a {
    margin: 0 16px;
  }
  .bottom-info a img {
    width: 40px;
  }
  .divider {
    width: 100%;
    height: 1px;
    background-color: #ddd;
  }
  .info {
    margin: 0;
    text-align: left;
    font-weight: 400;
    line-height: 140%;
    font-size: 16px;
  }
`;

const ArrowButton = styled.button`
  border: 2px solid #0a4ed6;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  background-color: transparent;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  transition: background-color 0.2 ease;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  &:disabled {
    opacity: 0.5;
  }
  &:disabled:hover {
    background-color: transparent;
  }
  &:hover {
    background-color: #fff;
  }
  &.right {
    right: 40px;
  }
  &.left {
    left: 40px;
    & img {
      transform: rotate(180deg);
    }
  }
  ${media.tablet} {
    height: 30px;
    width: 30px;
    top: 40px;
    min-width: 30px;
    min-height: 30px;
    &.left {
      left: 30px;
    }
    &.right {
      right: 30px;
    }
  }
`;

export default (Content) => (props) => {
  // eslint-disable-next-line react/prop-types
  const { link, title, description } = props;
  const {
    allNewslettersJson: { edges },
  } = useStaticQuery(graphql`
    query SitePaths {
      allNewslettersJson(sort: { fields: date, order: DESC }) {
        edges {
          node {
            link
            title
          }
          next {
            link
            title
          }
          previous {
            link
            title
          }
        }
      }
    }
  `);
  const [page] = edges.filter((post) => post.node.link === link);
  return (
    <Layout canonical={link} metaTitle={title} metaDescription={description} hideFooter hideHeader>
      <Tippy content={page.previous !== null && page.previous.title} placement="right">
        <ArrowButton
          onClick={() => navigate(page.previous !== null ? page.previous.link : page.node.link)}
          disabled={page.previous === null}
          className="left"
        >
          <img src={arrow} alt="left arrow" title="arrow" />
        </ArrowButton>
      </Tippy>
      <Tippy content={page.next !== null && page.next.title} placement="left">
        <ArrowButton
          onClick={() => navigate(page.next !== null ? page.next.link : page.node.link)}
          className="right"
          disabled={page.next === null}
        >
          <img src={arrow} alt="right arrow" title="arrow" />
        </ArrowButton>
      </Tippy>
      <StyledSection>
        <table width="100%" border="0" cellPadding="0" cellSpacing="0">
          <tbody>
            <tr>
              <td bgcolor="#F1F5F9" style={{ padding: '40px 16px' }}>
                <img
                  src="https://emails.livesession.io/logo-new.png"
                  className="logo"
                  style={{
                    display: 'block',
                    margin: '0 auto 40px',
                    maxWidth: '150px',
                    height: '21px',
                  }}
                  alt="Logo small"
                  title="LiveSession Logo"
                />
                <Content {...props} />
                <table
                  className="bottom-info"
                  cellSpacing="0"
                  cellPadding="0"
                  align="center"
                  width="100%"
                  style={{ width: '100%', textAlign: 'center' }}
                >
                  <tbody>
                    <tr>
                      <td>
                        <img
                          src="https://emails.livesession.io/logo-new.png"
                          className="logo-bottom"
                          style={{
                            display: 'block',
                            margin: '40px auto 0',
                            maxWidth: '104px',
                            height: '16px',
                          }}
                          alt="Logo big"
                          title="LiveSession Logo"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p
                          className="credits"
                          style={{
                            fontSize: '14px',
                            lineHeight: '28px',
                            opacity: 0.5,
                            margin: '32px 0',
                            textAlign: 'center',
                            width: '100%',
                          }}
                        >
                          &copy; {new Date().getFullYear()} LiveSession. All rights reserved. <br />
                          LiveSession Sp. z o.o. Sikorskiego 3/2, Wroclaw, Poland
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <a
                          href="https://twitter.com/livesessionhq"
                          style={{ textDecoration: 'none', margin: '0 16px' }}
                        >
                          <img
                            src="https://emails.livesession.io/twitter-logo.png"
                            style={{ width: '40px' }}
                            alt="twitter"
                            title="LiveSession's Twitter"
                          />
                        </a>
                        <a
                          href="https://www.facebook.com/LiveSessionIO/"
                          style={{ textDecoration: 'none', margin: '0 16px' }}
                        >
                          <img
                            src="https://emails.livesession.io/fb-logo.png"
                            style={{ width: '40px' }}
                            alt="facebook"
                            title="LiveSession's Facebook"
                          />
                        </a>
                        <a
                          href="https://www.linkedin.com/company/livesession/"
                          style={{ textDecoration: 'none', margin: '0 16px' }}
                        >
                          <img
                            src="https://emails.livesession.io/linkedin-logo.png"
                            style={{ width: '40px' }}
                            alt="linkedIn"
                            title="LiveSession's LinkedIn"
                          />
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </StyledSection>
    </Layout>
  );
};
