import React, { useState, useEffect } from 'react';
import Layout from 'layout';
import Features from 'components/pricing/features';
import ScheduleDemo from 'components/pricing/schedule-demo';
import FAQ from 'components/FAQ';
import PricingBox from 'components/pricing/pricingBox';
import StartUsing from 'components/ui/startUsing';
import Enterprise from 'components/pricing/enterprise';
import NewLogo from 'components/index/newLogo/index';
import { questions } from 'data/pricing/questions';
import pricingModel from 'helpers/pricing.json';
import { getFeaturesForPlan } from 'helpers/pricing_features';
import Cookies from 'js-cookie';
import { Features as FeaturesData } from 'data/pricing/features';
import {
  Header,
  Container,
  Subtitle,
  StyledSection,
  PlansAndMethods,
  Switcher,
  Plans,
} from 'styles/pricingPage';

const Pricing = () => {
  const [type, setType] = useState('monthly');
  const [currency, setCurrency] = useState(Cookies.get('ls_currency') || 'usd');

  useEffect(() => {
    if (Cookies.get('ls_currency')) {
      setCurrency(Cookies.get('ls_currency'));
    } else {
      fetch('https://freegeoip.live/json/')
        .then((res) => res.json())
        .then((res) => {
          if (res?.time_zone.includes('Europe')) {
            setCurrency('eur');
            Cookies.set('ls_currency', 'eur');
          } else {
            Cookies.set('ls_currency', 'usd');
            setCurrency('usd');
          }
        });
    }
  }, []);
  const basicFeatures = [
    {
      title: (
        <>
          Everything in <strong>Free</strong> plan
        </>
      ),
    },
    ...getFeaturesForPlan('basic', pricingModel?.min_feature_plans),
  ];
  const proFeatures = [
    {
      title: (
        <>
          Everything in <strong>Basic</strong> plan
        </>
      ),
    },
    ...getFeaturesForPlan('pro', pricingModel?.min_feature_plans),
  ];
  const freeFeatures = getFeaturesForPlan('free', pricingModel?.min_feature_plans);
  const enterpriseFeatures = [
    {
      title: (
        <>
          Everything in <strong>Pro</strong> plan
        </>
      ),
    },
    {
      title: 'Custom paperwork',
      key: FeaturesData.BillingAndService.CustomPaperwork,
      tooltip: 'Custom agreements, DPA, Security audits etc.',
    },
    {
      title: 'Product training',
      key: FeaturesData.BillingAndService.Training,
      tooltip: 'Training session that will teach you how to effectivly use LiveSession.',
    },
    {
      key: 'priority_support',
      title: 'Priority support',
      tooltip: 'Quick responses and personalized approach via live chat and email.',
    },
  ];
  return (
    <Layout
      metaTitle="Pricing | Top Session Replay Software"
      canonical="/pricing/"
      metaDescription="See LiveSession’s pricing options and find the best match for your session recording needs. Choose a plan or request a tailor-made session replay solution."
      hideBorder
    >
      <Header style={{ backgroundColor: '#F5F5F5' }}>
        <Container className="container">
          <h1 className="line">
            Find the right plan for your <span>business</span>
          </h1>
          <Subtitle>
            Get access to LiveSession analytics platform and see what users really do on your
            website.
          </Subtitle>
        </Container>
      </Header>
      <StyledSection>
        <div className="container">
          <PlansAndMethods>
            <Switcher>
              <ul>
                <li
                  className={type === 'monthly' ? 'monthly selected' : ''}
                  onClick={() => setType('monthly')}
                  onKeyPress={() => setType('monthly')}
                  role="presentation"
                >
                  <p>Monthly prices</p>
                </li>
                <li
                  className={[type === 'annual' ? 'annual selected' : '']}
                  onClick={() => setType('annual')}
                  onKeyPress={() => setType('annual')}
                  role="presentation"
                >
                  <p>Annual prices</p> <span>2 months free</span>
                </li>
              </ul>
            </Switcher>

            <Plans>
              <PricingBox
                free
                type={type}
                planName="free"
                features={freeFeatures}
                currency={currency}
                use="A simple way to get started with LiveSession"
                pricingModel={pricingModel}
              />
              <PricingBox
                type={type}
                planName="basic"
                currency={currency}
                features={basicFeatures}
                use="For small teams and early-stage startups"
                pricingModel={pricingModel}
              />
              <PricingBox
                type={type}
                planName="pro"
                features={proFeatures}
                currency={currency}
                mostPopular
                use="Fully featured solution for growing companies"
                pricingModel={pricingModel}
              />
              <PricingBox
                custom
                type={type}
                planName="enterprise"
                currency={currency}
                use="Most powerful solution tailored to your scale"
                features={enterpriseFeatures}
                pricingModel={pricingModel}
              />
            </Plans>
          </PlansAndMethods>
        </div>
      </StyledSection>
      <div className="container">
        <Header style={{ paddingTop: 60 }}>
          <Features currency={currency} type={type} />
        </Header>
        <Enterprise />
        <FAQ questions={questions} style={{ paddingTop: '120px' }} />
        <ScheduleDemo />
      </div>
      <div className="pbclear">
        <NewLogo />
      </div>
      <StartUsing />
    </Layout>
  );
};

export default Pricing;
