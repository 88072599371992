import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const Card = styled.div`
  margin: ${(props) => (props.setMarginBottom ? '2rem 0' : '2rem 0 3rem')};
  border: none;

  ${media.desktop} {
    margin: ${(props) => (props.setMarginBottom ? '1.25rem 0' : '1.25rem 0 2rem')};
  }
`;

export const CardBody = styled.div`
  box-shadow: 0px 0px 10px #00000029;
  padding: 0;
`;
