import styled from 'styled-components';
import { Link } from 'gatsby';
import { media } from 'theme/breakpoints';

export const Card = styled(Link)`
  border-radius: 4px;
  border: 2px solid #000;
  display: block;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
  color: #000;
  width: 235px;
  height: 100%;
  ${media.desktopWide} {
    width: auto;
  }
  &:hover,
  &.active {
    border: 2px solid #0446ca;
    text-decoration: none;
    & div {
      & p {
        color: #0a4ed6;
      }
      & h3 {
        color: #0a4ed6;
      }
    }
  }
  &.active {
    & div {
      & h3 {
        font-weight: 800;
      }
    }
  }
  & div {
    & p {
      color: #808080;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 8px;
      text-transform: uppercase;
    }
    & h3 {
      font-size: 16px;
      line-height: 24px;
      font-weight: normal;
      padding-bottom: 8px;
    }
  }
  & .time {
    color: #808080;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    & img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }
`;

export const Section = styled.section`
  max-width: 770px;
  margin: 88px auto 0;
  padding-top: 80px;
  border-top: 1px solid #e4e5e8;
  ${media.desktopWide} {
    max-width: 100%;
  }
  ${media.desktop} {
    display: none;
  }
  & h2 {
    margin-bottom: 40px;
    font-size: 32px;
    line-height: 40px;
  }
  & .card-box {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 32px;
    grid-template-rows: repeat(3, 1fr);
  }
`;
