import Button from 'components/ui/button';
import * as Styled from './styled';

const OwnAppSection = () => (
  <Styled.Section>
    <h2>Build your own app with LiveSession API</h2>
    <p>Get started building now</p>
    <Button
      center
      href="https://developers.livesession.io/"
      target="_blank"
      rel="noopener noreferrer"
    >
      Explore docs
    </Button>
  </Styled.Section>
);

export default OwnAppSection;
