import Layout from 'layout';
import CardPreview from 'components/newsletters/cardPreview';
import NewsletterBox from 'components/blog/newsletterBox';
import StartUsing from 'components/ui/startUsing';
import { graphql, useStaticQuery } from 'gatsby';
import { StyledSection, GridContainer } from 'styles/newslettersPage';

const Newsletters = () => {
  const {
    allNewslettersJson: { edges },
  } = useStaticQuery(graphql`
    query {
      allNewslettersJson(sort: { fields: date, order: DESC }) {
        edges {
          node {
            title
            description
            date
            link
          }
        }
      }
    }
  `);

  return (
    <Layout
      metaTitle="Newsletter Archive"
      canonical="/newsletters/"
      metaDescription="Missed an update? Read LiveSessions newsletters from the previous months."
    >
      <StyledSection>
        <div className="container">
          <h1 className="line">
            Newsletter <span>archive</span>
          </h1>
          <p className="subtitle">Missed a newsletter? Read all previous updates here:</p>
          <GridContainer>
            {edges.map((post) => (
              <CardPreview {...post?.node} key={post?.node?.title} />
            ))}
          </GridContainer>
        </div>
        <NewsletterBox />
        <div className="container">
          <StartUsing />
        </div>
      </StyledSection>
    </Layout>
  );
};

export default Newsletters;
