import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const ScheduleDemoContainer = styled.section`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 64px 0;
  & h3 {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 32px;
    text-align: center;
  }
  & p {
    font-size: 20px;
    line-height: 32px;
    margin: 0 auto 32px;
    text-align: center;
  }
  ${media.tablet} {
    padding-top: 0;
    padding-bottom: 108px;
  }
`;
