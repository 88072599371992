import * as Styled from './styled';
import SearchBar from '../searchbar/SearchBar';

const SearchBox = () => {
  const openChat = (e) => {
    e.preventDefault();
    /*eslint-disable */
    Intercom('show');
    /* eslint-enable */
  };

  return (
    <Styled.FindAnswer className="col-lg-4">
      <h2>Didn’t find an answer?</h2>
      <p>Try using the search engine or just contact us</p>
      <SearchBar />
      <a href="mailto:hello@livesession.io?subject=Question">hello@livesession.io</a>
      <a href="/#" onClick={(e) => openChat(e)}>
        Chat with us
      </a>
    </Styled.FindAnswer>
  );
};

export default SearchBox;
