import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const Article = styled.div`
  & div.head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    & .title {
      margin: 0;
      font-size: 22px;
      ${media.mobile} {
        font-size: 18px;
      }
    }
    & p.subtitle {
      padding: 10px 0 15px;
      margin-bottom: 0;
      font-size: 18px;
    }
  }
  & a.with-arrow {
    font-size: 16px;
    ${media.mobile} {
      font-size: 13px;
    }
  }
`;

export const TextContent = styled.div`
  display: block;
  margin-top: 24px;
  & p {
    margin-bottom: 12px;
  }
  & .head-2 {
    font-size: 20px;
  }
  & .desc-2 {
    color: #9b9b9b;
  }
`;
