/* eslint-disable jsx-a11y/anchor-is-valid */
import withUsabilityTestingTemplate from 'hoc/content-cluster';
import Link from 'components/ui/link';
import { StaticImage } from 'gatsby-plugin-image';
import Banner from 'components/usability-testing/banner';
import arrow from 'img/ui/arrow_white.svg';

const HeatMapSoftware = () => (
  <>
    <h2>7 types of tools to use with your heatmap software (with examples)</h2>

    <p>
      One of the distinguishing characteristics of the best heatmap software is the variety of tools
      they integrate with. Below are a few types that you should particularly show interest in.
    </p>
    <h3>#1 Session recording software</h3>

    <p>
      Simply put, session recording and{' '}
      <Link href="/heatmaps/what-is-a-heat-map/" target="_blank" rel="noopener noreferrer">
        heatmap
      </Link>{' '}
      software are a match made in heaven that complement each other perfectly. While heat maps give
      you a bird’s eye view of all actions on a given page, session recording software lets you dive
      in and replay each user’s activity.{' '}
    </p>

    <p>
      To give you a sense of how it works, let’s consider a scenario for an eCommerce store. Your
      heatmap indicates that a lot of users click on the “proceed to checkout” button, but only 5%
      of them have finalized a purchase within the last week. If your{' '}
      <Link href="/heatmaps/heatmap-analysis/" target="_blank" rel="noopener noreferrer">
        heatmap analysis
      </Link>{' '}
      rules out any broken buttons or rage clicks, it’s time for further investigation. After
      watching a couple of recordings, you notice an error – items keep disappearing from peoples’
      carts. After trying two or three times, most potential customers simply lose motivation and
      give up.
    </p>

    <h4>What tools to use?</h4>

    <p>
      We encourage you to try out{' '}
      <Link href="/" target="_blank" rel="noopener noreferrer">
        LiveSession
      </Link>
      , as it offers a wide range of features from session replays to heatmap software. The best
      part is that it gives a 3-in-1 view, i.e. as you watch the recording of a user’s session, you
      will also see a semi-transparent overlay of the areas with the overall highest number of
      activities and clicks on the site.
    </p>

    <StaticImage
      src="../../img/heatmaps/heat-map-software/heatmap.png"
      alt="LiveSession's heat map software provides an all-in-one view of session recordings, clicks, and most noticed areas of your website."
      title="What tools to use?"
    />
    <p>
      If you’d like to see its advanced features in action, you can try LiveSession on a{' '}
      <Link signUp sourceID="text" target="_blank" rel="noopener noreferrer">
        free trial
      </Link>
      .
    </p>
    <h3>#2 Website analytics</h3>

    <p>
      Website analytics tools are essential if you want to make the most of your heat maps. How so?
      They store key website stats such as each page’s bounce and conversion rates, CTR, and session
      duration. This means they can help you decide which pages, other than your homepage and
      pricing plans, would make good candidates for heat mapping.
    </p>

    <p>
      On your website analytics platform, you could, for instance, find an important landing page
      that has very few signups or notice that your entire blog has an exceptionally high bounce
      rate. By setting up heat maps on them, you should be able to determine if your CTAs are
      getting notices or clicks, and how far down the page readers scroll before dropping from it.
    </p>
    <StaticImage
      src="../../img/heatmaps/heat-map-software/ganalytics.png"
      alt="Google Analytics is a website analytics tool you can integrate with best heatmap tools, including LiveSession"
      title="Website analytics"
    />
    <p>
      Source:{' '}
      <Link
        href="https://contentmarketinginstitute.com/wp-content/uploads/2019/04/blog-traffic-google-analytics.png"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Content Marketing Institute
      </Link>
    </p>

    <h4>What tools to use?</h4>
    <p>
      As far as website analytics are concerned, we recommend going for two tools from the G-Suite,
      i.e.,
      <Link
        href="https://livesession.io/integrations/google-analytics/"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Google Analytics
      </Link>{' '}
      and{' '}
      <Link
        href="https://search.google.com/search-console/about"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Google Search Console
      </Link>{' '}
      . While the former gives you access to both general and in-depth website and audience
      insights, the latter focuses on your site’s traffic source, SEO ranks, and performance.{' '}
    </p>

    <h3>#3 Customer analytics</h3>

    <p>
      As your business, customer base, and website grow in complexity, you might be interested in
      implementing customer analytics. Advanced analytics tools give you a more sophisticated view
      of your client base. They help you better tailor your offer, increase on-site user engagement,
      boost conversions, and turn visitors into customers by diving deep into your customers’
      on-site visitor behavior, psychographics, and purchase history (among others).
    </p>
    <h4>What tools to use?</h4>
    <p>
      We recommend looking into two customer analytics leaders,{' '}
      <Link href="/integrations/mixpanel/" target="_blank" rel="noopener noreferrer">
        Mixpanel
      </Link>{' '}
      and{' '}
      <Link href="/integrations/segment/" target="_blank" rel="noopener noreferrer">
        Segment
      </Link>
      .{' '}
    </p>

    <p>
      The first tool is famous for its‘
      <Link
        href="/usability-testing/usability-testing-tools/"
        target="_blank"
        rel="noopener noreferrer"
      >
        innovation loop
      </Link>
      ’ functionality - it collects data from various tools, notices emerging trends, and helps you
      find out the reasons behind new behaviors so that you can use them to the advantage of your
      business. Similarly, Segment collects data from various sources and can be used by your
      product team to make data-informed decisions about your product and user experience.
    </p>
    <StaticImage
      src="../../img/heatmaps/heat-map-software/mixpanel.png"
      alt="customer analytics tools make a great supplement to heat map software"
      title="Customer analytics"
    />
    <p>
      {' '}
      Source:{' '}
      <Link
        href="https://mixpanel.com/behavioral-analytics/"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Mixpanel
      </Link>
    </p>

    <p>
      Which one would be a better choice for you depends on your specific needs. Nevertheless,
      regardless of your choice, it’s possible to integrate both of them with LiveSession for an
      all-in-one-tool customer analytics experience.
    </p>
    <h3>#4 A/B testing tools</h3>
    <p>
      Yet another type of software that works perfectly with heat maps, letting you trial two
      versions of your site and decide which one drives better results. You can use this type of
      testing capabilities both if you’re putting out a new page or looking to fix an existing one.
    </p>

    <p>
      Let’s assume that a heat map on a landing page shows that it’s getting very few CTA clicks.{' '}
    </p>

    <p>
      Let’s assume that a heat map on a landing page shows that it’s getting very few CTA clicks.
      Your product team draws up a hypothesis that adding a fixed CTA banner to the top of the page
      will increase conversion by 15%, say. You set up heatmaps on two versions of the site – ‘A’,
      the current one, and ‘B’, the new concept. Then, you can compare the results from both
      versions and either confirm or overthrow your hypothesis. Pretty neat, right?
    </p>
    <h4>What tools to use?</h4>
    <p>
      While there are a number of A/B multivariate testing tools on the market, we recommend going
      with{' '}
      <Link href="/integrations/google-optimize/" target="_blank" rel="noopener noreferrer">
        Google Optimize
      </Link>{' '}
      . Not only does it integrate with other tools from the G-Suite, but also with the majority of
      heat map and session recording software, including LiveSession.
    </p>
    <StaticImage
      src="../../img/heatmaps/heat-map-software/goptimize.jpg"
      alt="Google Optimize is a free A/B testing tool which you can integrate with the best heatmap tools out there"
      title="A/B testing tools"
    />

    <p>
      {' '}
      Source:{' '}
      <Link
        href="https://marketingplatform.google.com/about/optimize/"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Google
      </Link>
    </p>
    <h3>#5 Website feedback</h3>

    <p>
      While heatmaps are a great source of insights into what is happening on your site, you might
      need to hear directly from users as to why they’re interacting with it in a certain way.
      Here’s where website feedback tools such as survey software come into play.
    </p>
    <p>
      Surveying your users will give you both quantitative and qualitative data to supplement your
      heat map analysis. For instance, you can ask your site visitors why they don’t scroll further
      down your page or why they ignore the CTA.
    </p>

    <p>
      Set up a survey on the exact pages that you’re using your heatmaps on. For pages on which
      you’ve noticed an issue, you can ask a very specific question based on the user’s on-site
      activity. Alternatively, on crucial pages such as pricing or the homepage, you can always keep
      a general survey running to ask people if they’d found what they were looking for.
    </p>
    <h4>What tools to use?</h4>
    <p>
      Some customer communication or CRM tools, such as Intercom and Hubspot, offer built-in
      surveys. However, bear in mind that they have lower customizability and follow-up logic than
      dedicated survey solutions. If you’d like to add custom questions or create complex follow-up
      question paths, we recommend using a tool like{' '}
      <Link href="/integrations/survicate/" target="_blank" rel="noopener noreferrer">
        Survicate
      </Link>
      , which integrates with LiveSession.
    </p>
    <StaticImage
      src="../../img/heatmaps/heat-map-software/survicate.png"
      alt="website feedback surveys are a great supplement to your heatmap insights"
      title="Website feedback"
    />
    <p>
      Source:{' '}
      <Link
        href="https://survicate.com/surveys/templates/website-usability-survey/"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Survicate
      </Link>
    </p>

    <h3>#6 Customer support</h3>
    <p>
      You should also use heat maps with customer support software. While they’re not related to
      optimizing the on-site experience per se, they certainly influence the customer experience.
      Chances are, you’ll find that the issues reported by users in your heatmap data are reflected
      in your conversations with them. For instance, if you were wondering why many people click on
      an image, you might learn by checking your customer conversations that a number of users
      expect the images to be clickable and direct to another webpage
    </p>
    <p>
      That said, by integrating CS and heat mapping software, you’ll also be able to link heat maps
      to specific customer query tickets. As a result, your support team will be given more context
      on the issues and user behavior on each page.
    </p>
    <Banner
      title="Improve your checkout process based on data that really matters"
      desc="Find out when your Customers gets frustrated and improve your eCommerce UX. Test all LiveSession features for 14 days, no credit card required."
    />
    <h4>What tools to use?</h4>

    <p>
      There are a number of great tools out there, but two that we can wholeheartedly recommend are{' '}
      <Link href="/integrations/livechat/" target="_blank" rel="noopener noreferrer">
        LiveChat
      </Link>{' '}
      and{' '}
      <Link href="/integrations/help-scout/" target="_blank" rel="noopener noreferrer">
        HelpScout
      </Link>
      . Both platforms allow you to integrate customer communication from various touch points into
      one panel. That said, LiveChat also gives you the ability to set up a chatbot, which can
      relieve your support teams of answering common questions.
    </p>
    <StaticImage
      src="../../img/heatmaps/heat-map-software/lc.png"
      alt="best heatmap software integrates with customer support tools"
      title="Customer support"
    />
    <h3>#7 Plug-ins for browsers</h3>
    <p>
      A good way to get a better understanding of your website’s performance is to use browser
      plugins, which can be excellent add-ons to the data that you gather from heatmaps. There are a
      few different types of browser plugins, but the most popular ones used for data analysis are
      web page performance tools. These allow you to see how quickly your pages load, what resources
      are being used the most, and where any bottlenecks occur.
    </p>
    <h4>What tools to use?</h4>
    <p>
      Most popular browser plugins related to website feedback streamline the workflow with Google
      Analytics. Tools such as Tag Assistant check if Google scripts are running on the site and
      that everything is okay with them. Google Analytics Debugger also allows you to verify whether
      or not GA is being properly implemented and what data it is receiving. There are many options,
      so take advantage of them to get the most out of your website feedback.
    </p>
    <h2>Summary</h2>
    <p>
      Dynamic heatmaps are a great source of user intent and website usability insights. Among
      others, they let you conduct an audit of your site’s user experience and better understand
      user activity. That being said, heatmaps deliver the best results if used with a few other
      tools.
    </p>

    <p>
      First and foremost, the best heat mapping software comes with other usability testing
      solutions, such as user session recordings. Here’s where LiveSession might come especially in
      handy, since it allows you to replay each user’s on-site behavior as well as see the most
      clicked on and noticed parts of the site.
    </p>

    <p>
      Secondly, it’s worth using your heatmap solution with software such as website and customer
      analytics, A/B testing tools, surveys, and live chat. By cross-referencing your heatmap
      findings with data from these platforms, you’ll be able to fill any information gaps and
      improve your website usability and CX
    </p>

    <p>
      To get a sense of all the types of tools that you can integrate with heatmaps, you can refer
      to our{' '}
      <Link href="/integrations/" target="_blank" rel="noopener noreferrer">
        Integrations
      </Link>{' '}
      catalog.
    </p>
    <div className="next-chapter">
      <Link href="/heatmaps/ux-improvements/">
        <div>
          <p>Chapter 8</p>
          <p>11 experts share the UX improvements they achieved with heat maps</p>
        </div>
        <img src={arrow} alt="arrow" title="white arrow" />
      </Link>
    </div>
  </>
);

export default withUsabilityTestingTemplate(HeatMapSoftware);
