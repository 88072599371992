import Link from 'components/ui/link';
import PropTypes from 'prop-types';
import * as Styled from './styled';

const Sidebar = ({ list, pathname }) => (
  <Styled.Aside>
    <ul>
      {list.map(({ url, title, desc }) => (
        <li key={url}>
          <Link href={url} className={pathname === url ? 'active' : undefined}>
            <p>{title}</p>
            <p>{desc} </p>
          </Link>
        </li>
      ))}
    </ul>
  </Styled.Aside>
);

export default Sidebar;

Sidebar.propTypes = {
  list: PropTypes.oneOfType([PropTypes.array]).isRequired,
  pathname: PropTypes.string.isRequired,
};
