import Link from 'components/ui/link';
import withUsabilityTestingTemplate from 'hoc/content-cluster';
import arrow from 'img/ui/arrow_white.svg';
import { StaticImage } from 'gatsby-plugin-image';
import Banner from 'components/usability-testing/banner';

const WhatIsUsabilityTesting = () => (
  <>
    <h2>What is usability testing?</h2>
    <p>
      Usability testing is a form of examining how real users interact with a product, acting as
      test subjects when they operate it and complete tasks. Testing can be carried out on your
      website navigation, on an app’s user interface, and even on physical products.
    </p>
    <StaticImage
      src="../../img/usability-testing/what-is-usability-testing/devices.png"
      alt="Usability testing"
      title="Test your product"
    />
    <p>Depending on the design, users will interact differently with various products.</p>
    <p>
      When we say “usability,” this also refers to the methods that can be adopted in order for your
      development team to improve ease-of-use in the design process. After all, you want it to be as
      simple and easy as possible for people to interact with and use your product.
    </p>

    <p>
      Functional testing, from early on in the product development process to product release and
      beyond, can provide valuable user feedback and data to improve your current product (when
      possible) or to inspire future product iterations.
    </p>
    <h3>Usability definition – what do we mean by ‘usability’?</h3>
    <p>
      If you want to nail down the usability definition even further, these five quality components
      by{' '}
      <Link
        href="https://www.nngroup.com/articles/usability-101-introduction-to-usability/"
        rel="noopener noreferrer nofollow"
        target="_blank"
      >
        Jakob Nielsen
      </Link>{' '}
      of Nielsen Norman Group can help:
    </p>
    <ol>
      <li>
        <strong>Learnability</strong>. How easy is it for users to complete basic tasks the first,
        real time using a product or interface?
      </li>
      <li>
        <strong>Efficiency</strong>. After users have handled your interface and design, how
        efficient are they when performing a series of tasks?
      </li>
      <li>
        <strong>Memorability</strong>. A lot like efficiency, in that the test participants have
        already used your design multiple times. But if they don’t use your design for weeks,
        months, or even years, can they return to high efficiency levels quickly?
      </li>
      <li>
        <strong>Errors or bugs</strong>. When users make mistakes, and people will always do, can
        they recover from them? Also, how many errors can be considered “severe?”
      </li>
      <li>
        <strong>Satisfaction</strong>. Did users find your design pleasant to use?
      </li>
    </ol>
    <p>
      Now that you know what usability testing is and how usability works, it’s time to learn why
      carrying out a usability study is so important in design.
    </p>
    <h2>The benefits of usability testing</h2>
    <p>
      There are a lot of different usability tests that you can perform, including remote,
      assessment, and comparative testing. But what is a usability evaluation good for and what
      benefits does it offer you?
    </p>

    <h3>Improved user experience</h3>
    <p>
      The user experience can enhance satisfaction, reduce customer service issues, and increase the
      longevity of product usage.
    </p>
    <p>And if you’re thinking big businesses don’t test usability, you’re wrong.</p>
    <p>
      Apple tweaked their Mac’s UI to improve the user experience, and the result was{' '}
      <Link
        href="https://uxplanet.org/why-is-it-important-to-do-usability-testing-5080a5640df3"
        rel="noopener noreferrer nofollow"
        target="_blank"
      >
        90% fewer support calls
      </Link>
      . Impressive, isn’t it?
    </p>
    <h3>Increased conversion</h3>
    <p>
      Conversions are actions that you want actual users to take. Perhaps you want them to purchase
      a pro version of your app. Effective usability testing can increase the number of such actions
    </p>
    <p>
      If you own a website, you can use conversions with your email marketing to increase the number
      of subscribers who sign up to your list.
    </p>
    <h3>Generating more revenue</h3>
    <p>
      Revenue keeps businesses afloat. Single usability issues can lead to massive revenue loss.{' '}
      <Link
        href="https://thenextscoop.com/usability-testing-benefits/"
        rel="noopener noreferrer nofollow"
        target="_blank"
      >
        A bank in Australia
      </Link>{' '}
      found a single key design issue with their app that, when fixed, led to a potential 600%
      increase in revenue generation for mobile loan applications.
    </p>
    <StaticImage
      src="../../img/usability-testing/what-is-usability-testing/revenue.png"
      alt="Statistics"
      title="Our customers didn't get the experience they deserve"
    />
    <p>
      Source:{' '}
      <Link
        href="https://thenextscoop.com/usability-testing-benefits/"
        rel="noopener noreferrer nofollow"
        target="_blank"
      >
        NextApp
      </Link>
    </p>
    <h3>Showing brand commitment to a great UX</h3>
    <p>
      Happy users lead to referrals, long-term income, and success. Brands that continually conduct
      usability testing show they’re committed to the real user experience they provide. A company
      can gain the trust of its users by showing dedication to improving the real customer journey
      that will be undertaken.
    </p>
    <h3>Learning about target users’ behavior and preferences</h3>
    <p>
      Big data is everywhere (your smartphone is likely collecting your data right now), and you can
      collect data during functional testing. When you conduct any type of usability testing, you’re
      gathering data about the user’s behavior and preferences.
    </p>
    <Banner title="Improve usability testing with LiveSession" />
    <h2>What are the different types of usability testing?</h2>
    <p>
      We’ve talked a lot about{' '}
      <Link href="/usability-testing/" target="_blank" rel="noopener noreferrer">
        usability testing
      </Link>
      , but it’s time to find out what the right type of testing for your business might be. The key
      types are:
    </p>
    <h3>1. In-person vs remote usability testing</h3>
    <p>
      In-person and remote usability testing allow the creation of products that are robust,
      user-friendly, and ready for market. Your product type and what you’re testing will dictate
      which form is best suited to your product.
    </p>
    <p>
      You wouldn’t evaluate an app’s interface the same way you would test a vacuum, right? That’s
      why a mix of testing methods are chosen when refining your product.
    </p>

    <h4>
      <strong>In-person usability testing methods:</strong>
    </h4>
    <ol>
      <li>
        <strong>Lab testing</strong>. A lab setting allows for a controlled environment where the
        person tested can be assessed based on the target market characteristics. This method helps
        identify problems based on specific parameters, allows for quantitative data collection,
        confirms user challenges, and evaluates new prototypes. Testing can be performed in-house or
        in a third-party facility.
      </li>
      <li>
        <strong>Guerilla testing</strong>. A powerful method that involves going out conducting
        unmoderated testing with a large sample size of as many random people as possible. If you
        sell a powerful stain remover, as a usability testing example, you could go to cafes or
        stand on the street corner beta testing the product. You’ll gain valuable data on how
        different, real users interact with your product, the difficulties they face, and how best
        to improve it.
      </li>
      <li>
        <strong>Observational usability testing</strong>. A form of stealth evaluation that allows
        you to understand user behavior when you’re not present. The test participant is set up in a
        private location and allowed to use your product independently. You may use screen or video
        recording to observe their behavior, body language, and facial expressions. It’s important
        to intervene only if a participant is truly stuck and facing a problem.
      </li>
      <li>
        <strong>Eye-tracking studies</strong>. Users’ eye movements can tell you a lot about your
        interface. Eye-tracking allows you to measure a person’s eye movement on a web page or app,
        so you&apos;ll be able to see what parts of the interface get the most focus and which parts
        are overlooked.
      </li>
    </ol>
    <h4>Remote usability testing methods:</h4>
    <p>
      Remote usability alleviates the stress and anxiety that users face when tests are conducted
      in-person. A lot of remote testing methods can be used to gather data, but the most common
      forms are:
    </p>
    <ol>
      <li>
        <strong>Phone interviews</strong>. User interviews are an integral part of UX research,
        during which participants are asked questions by researchers. These one-on-one testing
        sessions provide valuable product feedback, allowing both the tester and researcher to ask
        questions.
      </li>
      <li>
        <strong>Digital </strong>{' '}
        <Link
          href="https://www.justinmind.com/blog/card-sorting/"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          card sorting
        </Link>{' '}
        . This is a simple and effective form of remote interface testing. When trying to improve or
        perfect your user experience, this method works well. Users are given digital cards that are
        organized in a way that makes sense for them. Through sorting, you’re able to determine how
        representative users would adapt the information architecture and what form of organization
        works best for them.
      </li>
      <li>
        <strong>Session recordings</strong>.{' '}
        <Link href="/solutions/#usability-testing">tools like LiveSession</Link> allow you to test
        ideas remotely and watch users as they traverse your interface. Each session recording lets
        you gain actionable insights and collect qualitative data. You’ll be able to find errors,
        hotspots, and areas where actual users struggle with your interface.
      </li>
    </ol>
    <StaticImage
      src="../../img/usability-testing/what-is-usability-testing/player.png"
      alt="Session recordings - LiveSession"
      title="LiveSession provides a recording tool"
    />
    <p>
      Source: <Link href="/features/">LiveSession</Link>
    </p>
    <h3>2. Exploratory testing vs comparative testing</h3>
    <p>
      Explorative and comparative testing methods provide insights into how users operate your
      product naturally and how it compares to the competition.
    </p>
    <p>Let&apos;s take a deeper look into these forms of usability testing methods:</p>
    <h4>Exploratory testing</h4>
    <p>
      This method removes influence on the evaluator, allowing for an on-the-fly evaluation to see
      how users interact with your product. They may be asked to write down notes before the test,
      but the main goal is to explore your product freely.
    </p>
    <p>
      Agile models rely heavily on exploratory testing because each individual tester is given the
      freedom and responsibility to test your product or interface naturally.
    </p>
    <h4>Comparative testing</h4>
    <p>
      These are used to compare your product against two or more products. When performed properly,
      this type of test allows you to better understand your product’s strengths and weaknesses
      versus those of your competitors.
    </p>
    <p>
      You can further use comparative testing to generate new ideas, features, and functions for
      your product.
    </p>
    <h3>3. Assessment Testing</h3>
    <p>
      If you want to conduct a general usability test for your product,{' '}
      <Link
        href="
https://mentormate.com/blog/four-distinct-approaches-to-successful-usability-testing/#:~:text=Assessment%20Test%3A&text=Assessment%20%E2%80%94%20also%20known%20as%20a,and%20how%20they%20impact%20usability"
        rel="noopener noreferrer nofollow"
        target="_blank"
      >
        an assessment test
      </Link>{' '}
      is what you’ll want to perform. This type often comes after exploratory testing to determine
      how certain aspects of design impact usability.
    </p>
    <p>
      During an assessment, the user is asked to complete specific, real tasks and may even speak
      aloud when performing them to give insights into their actions. You’ll want to run an
      assessment test in the early and midway phase of product development, then tweak your product
      after testing to improve the design to cater for your target audience.
    </p>

    <h2>Summary</h2>
    <p>
      Now that you know what a usability testing report consists of, as well as how remote,
      in-person, comparative, and assessment testing all work, it’s time to collect the data that
      your company needs to improve your product.
    </p>
    <p>
      By collecting data on your target audience, you’ll be able to modify the user experience,
      improve your results, and increase your end-users’ satisfaction. This, in turn, will allow you
      to create a successful physical or digital product that adds value and is enjoyable for its
      users.
    </p>
    <p>All that’s left to say? Good luck on your usability testing endeavors!</p>
    <div className="next-chapter">
      <Link href="/usability-testing/usability-testing-questions/">
        <div>
          <p>Chapter 2</p>
          <p>24 Usability Testing Questions You Need To Ask Your Audience</p>
        </div>
        <img src={arrow} alt="arrow" title="white arrow" />
      </Link>
    </div>
  </>
);

export default withUsabilityTestingTemplate(WhatIsUsabilityTesting);
