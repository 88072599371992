import ArticlePreview from 'components/blog/blogArticlePreview';
import { WhatsNext } from 'styles/tutorials';
import { graphql, useStaticQuery } from 'gatsby';
import { data } from 'data/guides/usability-testing';
import { authorKalina } from 'data/authors';
import { meta as articleDesc2 } from 'data/tutorials/abtesting';

export const meta = {
  title: 'Form analytics',
  url: '/tutorials/form-analytics/',
  description: `Online form analytics is crucial in greatly boosting lead generation. How? 
    LiveSession's advice is here to help out.
    `,
  author: authorKalina,
  date: new Date(2020, 8, 16),
  group: 'Tutorial',
  tutorials: true,
  CustomSection: () => {
    const { signupCover, usabilityCover, abtestCover } = useStaticQuery(graphql`
      query {
        abtestCover: file(relativePath: { eq: "tutorials/abtest.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 95)
          }
        }
        signupCover: file(relativePath: { eq: "blog/covers/signup-improve.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 95)
          }
        }
        usabilityCover: file(
          relativePath: { eq: "usability-testing/usability-testing-cover.png" }
        ) {
          childImageSharp {
            gatsbyImageData(quality: 95)
          }
        }
      }
    `);
    return (
      <WhatsNext>
        <h2 className="heading">What’s next?</h2>
        <p className="desc">
          Now you know everything you need to get started with form analytics. For more inspiration,
          be sure to check out these resources:
        </p>
        <div className="article-container">
          <ArticlePreview
            title="5 Tips to Improve Sign-up Forms (with examples)"
            url="/blog/tips-to-improve-sign-up-forms-with-examples/"
            description="Sign-up forms are like medicines - you may hate them, but you know you have to take them to feel better."
            date="2020-01-24"
            group="Research"
            timeToRead={7}
            authorId="kalina_tyrkiel"
            img={signupCover?.childImageSharp?.gatsbyImageData}
            isList
          />
          <ArticlePreview
            {...articleDesc2}
            authorId={articleDesc2?.author?.authorId}
            img={abtestCover?.childImageSharp?.gatsbyImageData}
            isList
          />
          <ArticlePreview
            {...data[0]}
            authorId={data[0]?.author?.authorId}
            img={usabilityCover?.childImageSharp?.gatsbyImageData}
            isList
          />
        </div>
      </WhatsNext>
    );
  },
};
