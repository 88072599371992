import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Carousel from '@brainhubeu/react-carousel';
import { GatsbyImage } from 'gatsby-plugin-image';
import ChevronIcon from 'img/ui/chevron-down.inline.svg';
import * as Styled from './styled';
import '@brainhubeu/react-carousel/lib/style.css';

const FeaturesSlider = ({
  sectionTitle,
  sectionDesc,
  children,
  images,
  cards,
  reversed,
  alternativeText,
  color,
  style,
  className,
}) => {
  const [activeCard, setActiveCard] = useState(0);

  const onChange = (value) => {
    setActiveCard(value);
  };

  return (
    <div className="container">
      <Styled.AbandonedCarts
        className={[reversed ? 'reversed' : '', 'features-slider', className].join(' ')}
        color={color}
        style={style}
      >
        <div className="content-container">
          {sectionTitle || (
            <h2 className="line displayNone">
              Recover <span>abandoned</span> carts
            </h2>
          )}
          <p className="displayNone">
            {sectionDesc ||
              `There are many tactics for recovering abandoned carts, but good old-fashioned UX research
          will remain irreplaceable.`}
          </p>
          <Styled.CardContainer color={color}>
            {cards.map(({ desc, title }, id) => (
              <Styled.Card
                key={title}
                onClick={() => setActiveCard(id)}
                className={activeCard === id ? 'active' : ''}
                color={color}
                spanText="COMING SOON"
              >
                <div>
                  <h3>{title}</h3>
                  <ChevronIcon alt="arrow" />
                </div>
                <p>{desc}</p>
              </Styled.Card>
            ))}
            {children}
          </Styled.CardContainer>
        </div>
        {typeof window !== `undefined` && (
          <Carousel value={activeCard} onChange={onChange}>
            {images.map((item) => (
              <GatsbyImage
                image={
                  item?.childImageSharp?.gatsbyImageData ||
                  item?.node?.childImageSharp?.gatsbyImageData
                }
                alt={alternativeText ? alternativeText[activeCard] : ''}
                objectFit="contain"
                key={item}
              />
            ))}
          </Carousel>
        )}
      </Styled.AbandonedCarts>
    </div>
  );
};

export default FeaturesSlider;

FeaturesSlider.propTypes = {
  sectionTitle: PropTypes.element.isRequired,
  sectionDesc: PropTypes.string.isRequired,
  alternativeText: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      desc: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  reversed: PropTypes.bool,
  color: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  className: PropTypes.string,
};

FeaturesSlider.defaultProps = {
  reversed: false,
  color: undefined,
  style: undefined,
  className: '',
};
