import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
`;

const Box = styled.div`
  background-color: transparent;
  position: relative;
  width: 95vw;
  height: 95vh;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const Close = styled.span`
  position: absolute;
  font-size: 1.8rem;
  right: 20px;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #fff;
  border-radius: 100px;
  width: 40px;
  height: 40px;
  padding-bottom: 3px;
  filter: drop-shadow(5px 1px 4px rgba(0, 0, 0, 0.2));
  z-index: 10;
`;

const ImageWrapped = styled.img`
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: 0 auto;
  border: none;
`;
const minWidthToShow = 600;

const Image = styled.img`
  border: 1px solid #eaebec;
  max-width: 100%;
  height: auto;

  @media (min-width: ${minWidthToShow}px) {
    cursor: pointer;
  }
`;

class ImageModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false);
  }

  escFunction = (event) => {
    const { showModal } = this.state;

    if (event.keyCode === 27 && showModal) {
      this.closeModal();
    }
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  openModal = () => {
    if (window.innerWidth >= minWidthToShow) {
      this.setState({ showModal: true });
    }
  };

  render() {
    const { showModal } = this.state;
    const { src, imgStyle, imgModalStyle, alt, title, lazy } = this.props;

    const modal = (
      <Container onClick={this.closeModal}>
        <Close onClick={this.closeModal}>&times;</Close>
        <Box onClick={this.closeModal}>
          <ImageWrapped
            style={imgModalStyle}
            src={src}
            alt={alt}
            title={title}
            loading={lazy ? 'lazy' : null}
          />
        </Box>
      </Container>
    );

    return (
      <>
        <Image
          style={imgStyle}
          onClick={this.openModal}
          src={src}
          alt={alt}
          title={title}
          loading={lazy ? 'lazy' : null}
        />
        {showModal && modal}
      </>
    );
  }
}

export default ImageModal;

ImageModal.propTypes = {
  src: PropTypes.string.isRequired,
  imgStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
  imgModalStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  lazy: PropTypes.bool,
};

ImageModal.defaultProps = {
  lazy: false,
  imgStyle: null,
  imgModalStyle: null,
};
