import { useStaticQuery, graphql } from 'gatsby';
import { transformImages } from 'helpers/transformImages';
import PropTypes from 'prop-types';
import * as Styled from './styled';
import Card from './card';

const FeatureCards = ({ children, text, primary }) => {
  const { allFile } = useStaticQuery(graphql`
    query FeatureCards {
      allFile(filter: { relativePath: { regex: "/feature-cards/" } }) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);
  const files = transformImages(allFile.edges);
  const cardsInfo = [
    {
      title: 'Session recording',
      desc: `Play back your users' sessions and learn more about their behavior to make the product even better suited to their needs.`,
      img: files.session.node.childImageSharp.gatsbyImageData,
      imageStyle: {
        maxWidth: '94%',
      },
    },
    {
      title: 'Dev Tools',
      desc: `Replay users’ sessions and stop guessing why bugs happen. See JS errors, console logs and network requests.`,
      img: files.devtools.node.childImageSharp.gatsbyImageData,
      imageStyle: {
        maxWidth: '92%',
      },
    },

    {
      title: 'Dashboards',
      desc: 'Analyze the data you collect with LiveSession. Use Dashboards to track product adoption, activation or retention.',
      img: files.dashboards.node.childImageSharp.gatsbyImageData,
      imageStyle: {
        maxWidth: '95%',
      },
    },
    {
      title: 'Clickmaps and heatmaps',
      desc: `Visualize most engagement places on your website and better analyze users interactions.`,
      img: files.clickmaps.node.childImageSharp.gatsbyImageData,
      imageStyle: {
        maxWidth: '87%',
      },
    },
  ];

  const cardsInfo2 = [
    {
      title: 'Advanced filters',
      desc: 'Filter sessions and create user segments to analyze the behavior of specific user groups faster and more effectively.',
      img: files.advfilters.node.childImageSharp.gatsbyImageData,
      imageStyle: {
        maxWidth: '100%',
      },
    },
    {
      title: 'Segments',
      desc: 'Save filter presets and segment your visitor’s base so you can quickly start where you left off.',
      img: files.segments.node.childImageSharp.gatsbyImageData,
      imageStyle: {
        maxWidth: '64%',
      },
    },
    {
      title: 'Segment notifications',
      desc: 'Get the summary of segment activity directly to your email.',
      img: files.notifications.node.childImageSharp.gatsbyImageData,
      imageStyle: {
        maxWidth: '93%',
      },
    },
    {
      title: 'Inspect mode',
      desc: 'Find interactions with particular element by selecting it during session playback.',
      img: files.inspect.node.childImageSharp.gatsbyImageData,
      imageStyle: {
        maxWidth: '82%',
      },
    },
  ];

  return (
    <Styled.Wrapper className="container">
      <Styled.ContentWrapper>
        {children}
        <p>{text}</p>
      </Styled.ContentWrapper>

      {primary ? (
        <Styled.Section>
          {cardsInfo.map((item) => (
            <Card {...item} key={item.title} />
          ))}
        </Styled.Section>
      ) : (
        <Styled.Section>
          {cardsInfo2.map((item) => (
            <Card {...item} key={item.title} />
          ))}
        </Styled.Section>
      )}
    </Styled.Wrapper>
  );
};

export default FeatureCards;

FeatureCards.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  primary: PropTypes.string.isRequired,
};
