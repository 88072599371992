export const numberWithSpaces = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export function kFormatter(num) {
  return Math.abs(num) > 999
    ? `${Math.sign(num) * (Math.abs(num) / 1000).toFixed(1)}k`
    : Math.sign(num) * Math.abs(num);
}

export const formatPlanLabel = (plan) => {
  if (!plan) return '';
  const arr = plan.split('-');
  arr[0] = arr[0].charAt(0).toUpperCase() + arr[0].slice(1);
  if (arr[0] === 'Freemium') return 'Free';
  return arr[0];
};

export const getPriceForValue = (unit, prices, currency, billingCycle) => {
  const currencyField = currency.toUpperCase();

  for (let i = 0; i < prices.length; i += 1) {
    const el = prices[i];
    const between = unit >= el.from && unit <= el.to;
    const above = !between && el.from && !el.to;

    if (between || above) {
      return Number((el.price[currencyField][billingCycle] * unit).toFixed(0));
    }
  }
  return 0;
};

export const getSavings = (unit, prices, currency, billingCycle) => {
  const currencyField = currency.toUpperCase();

  for (let i = 0; i < prices.length; i += 1) {
    const el = prices[i];
    const between = unit >= el.from && unit <= el.to;
    const above = !between && el.from && !el.to;

    if (between || above) {
      return parseInt(
        100 -
          (el.price[currencyField][billingCycle] * 100) /
            prices[0].price[currencyField][billingCycle],
        10,
      );
    }
  }
  return 0;
};

export const getPricingModelSessions = (pricingModel) => {
  if (pricingModel?.stair_steps_sessions) {
    const steps = {};
    const plans = Object.keys(pricingModel?.stair_steps_sessions);
    const sessions = Object.values(pricingModel?.stair_steps_sessions);
    plans.forEach((item, i) =>
      Object.assign(steps, {
        [item]: sessions[i]
          .sort((a, b) => a - b)
          .map((num) => ({
            key: num,
            value: numberWithSpaces(num),
          })),
      }),
    );
    return steps;
  }
  return {};
};
