import PropTypes from 'prop-types';
import * as Styled from './styled';

const Recommendation = ({ recommendation, person, job, company, image, url, style }) => (
  <Styled.Box style={style} className="recommendation-box">
    <p>{recommendation}</p>
    <Styled.Person>
      <Styled.Face image={image} alt="face" />
      <p>
        <strong>{person}</strong>
        <span>
          {job} at{' '}
          {url ? (
            <a href={url} target="_blank" rel="noopener noreferrer">
              {company}
            </a>
          ) : (
            company
          )}
        </span>
      </p>
    </Styled.Person>
  </Styled.Box>
);

export default Recommendation;

Recommendation.propTypes = {
  recommendation: PropTypes.string.isRequired,
  person: PropTypes.string.isRequired,
  job: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.node]).isRequired,
  url: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object]),
};

Recommendation.defaultProps = {
  url: null,
  style: undefined,
};
