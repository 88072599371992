import { StaticImage } from 'gatsby-plugin-image';

const texts = [
  {
    title: 'Session recording tool',
    desc: (
      <p>
        LiveSession is here to help you improve your web application. It’s one of the most effective
        ways to <strong>optimize your website</strong> and get to know your customers.
      </p>
    ),
  },
  {
    title: 'Wide variety of filters',
    desc: (
      <p>
        You can use a wide variety of filters to focus your{' '}
        <strong>research on a particular user segment</strong>. This will help you draw the most
        important findings in a more efficient way. You can easily{' '}
        <strong>customize your search</strong> to focus on the areas you’re interested in.
      </p>
    ),
  },
  {
    title: 'Single-page applications',
    desc: (
      <p>
        LiveSession is designed for single-page applications. It works with the most popular
        frameworks, such as{' '}
        <strong>Angular, React, Vue and other frequently used technologies</strong>. We’re
        collecting all data, but we also don’t want you to waste your credits on pointless
        recordings. That’s why bots are excluded and the sessions start only when the user makes an
        action.
      </p>
    ),
  },
  {
    title: 'Answer your needs',
    desc: (
      <p>
        We’re always here to answer your needs. That’s why we’re always working on new features, yet
        we’re also making sure that the interface remains <strong>clear and easy to use</strong>.
      </p>
    ),
  },
];

const HeadContentFullstory = () => (
  <>
    <h2>
      Sleek and intuitive interface? <br />
      We’ve got it!
    </h2>
    <StaticImage
      src="../../img/fullstory-alternative/player.png"
      alt="player presents the session"
    />
    <div className="text-box">
      {texts.map(({ title, desc }) => (
        <div key={title}>
          <h4>{title}</h4>
          {desc}
        </div>
      ))}
    </div>
  </>
);

export default HeadContentFullstory;
