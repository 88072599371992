import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const Section = styled.section`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 120px;
  align-items: center;
  justify-content: center;
  max-width: 880px;
  margin: 0 auto;
  padding: 0;
  ${media.desktop} {
    grid-gap: 60px;
  }
  ${media.tablet} {
    grid-gap: 40px;
  }
  ${media.mobile} {
    grid-gap: 32px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  & div {
    & p {
      font-size: 22px;
      line-height: 28px;
      margin: 0;
      ${media.tablet} {
        font-size: 18px;
        line-height: 24px;
      }
      ${media.mobile} {
        font-size: 22px;
        line-height: 28px;
        text-align: center;
      }
    }
  }
`;
