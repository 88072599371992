import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import Link, { StyledLink } from 'components/ui/link';

export const Card = styled(Link)`
  padding: 40px 30px 40px 40px;
  background-color: #f5f5f5;
  display: block;
  & ${StyledLink} {
    font-size: 16px;
    margin-bottom: 0;
  }
  & .title {
    margin: 18px 0 8px;
    font-weight: 800;
    font-size: 22px;
    color: #000;
  }
  & .desc {
    margin: 0 0 24px;
    line-height: 30px;
    font-size: 18px;
    color: #000;
  }
  &:hover {
    text-decoration: none;
    ${StyledLink} {
      text-decoration: underline;
      &::after {
        transform: translateX(8px);
      }
    }
  }
  ${media.desktop} {
    padding: 40px;
  }
  ${media.tablet} {
    padding: 16px;
    & img {
      max-width: 30px;
      max-height: 30px;
    }
    & .title {
      font-size: 14px;
      line-height: 24px;
      margin: 18px 0 0;
    }
    & .desc,
    ${StyledLink} {
      display: none;
    }
  }
`;
