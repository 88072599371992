/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as Styled from './styled';

const SliderAboutAs = () => {
  const { renaudD, alexander, adityax2, bruno, mariox2, gasperVidovic, wjasnos } =
    useStaticQuery(graphql`
      {
        renaudD: file(relativePath: { eq: "persons/renaudx2.png" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
        alexander: file(relativePath: { eq: "persons/alexander.png" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
        adityax2: file(relativePath: { eq: "persons/adityax2.png" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
        bruno: file(relativePath: { eq: "persons/bruno.png" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
        mariox2: file(relativePath: { eq: "persons/mariox2.png" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
        gasperVidovic: file(relativePath: { eq: "persons/gasperVidovic.png" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
        wjasnos: file(relativePath: { eq: "persons/wjasnos.png" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    `);

  const cards = [
    {
      title:
        '“LiveSession is an impressive solution for those who believe in never-ending continuous improvement.”',
      name: 'Renaud Darval',
      job: 'Co-Founder at RandomCoffe',
      image: renaudD,
    },
    {
      title:
        '“We’ve been using LiveSession for over three years, and we are incredibly happy with this choice. This software completes the needed set of tools that are essential for our success.”',
      name: 'Alexander Shpilka',
      job: 'CEO & Founder at LiveReacting',
      image: alexander,
    },
    {
      title: '“LiveSession is an example of advanced technology supporting leaders like us.”',
      name: 'Aditya Sharma',
      job: 'CTO at Plaxonic',
      image: adityax2,
    },
    {
      title:
        '“Sometimes, given errors happened only on a certain combination of Operation System and browser If it was not for LiveSession, we would not have been able to replicate the issue.”',
      name: 'Bruno Ilisevic',
      job: 'Product Manager at TalentLyft',
      image: bruno,
    },
    {
      title: '“With LiveSession, we can work in a continuous improvement approach mode.”',
      name: 'Mario Buntic',
      job: 'CEO at TalentLyft',
      image: mariox2,
    },
    {
      title:
        '“We had experimented with several solutions, but we weren’t fully satisfied with them. We needed to find the best-in-class session recording software. LiveSession lives up to our high expectations..”',
      name: 'Gasper Vidovic',
      job: 'Director of Product at Databox',
      image: gasperVidovic,
    },
    {
      title:
        '“Monitoring how existing users interact with the our app is not the only benefit of using LiveSession. We can also record sessions on our homepage and all other pages. That allows us to understand what our users do on our website before they sign up.”',
      name: 'Wojciech Jasnos',
      job: 'CEO of Racketlink',
      image: wjasnos,
    },
  ];
  return (
    <Styled.ContainerSlider>
      <Styled.Wrapper>
        <div className="slidWrapper1">
          {cards.map(({ title, name, job, image }) => (
            <Styled.CardWrapper>
              <p>{title}</p>
              <Styled.UserCompetition>
                <Styled.ImgWrapper>
                  <GatsbyImage
                    image={image.childImageSharp.gatsbyImageData}
                    alt="session"
                    objectFit="contain"
                  />
                </Styled.ImgWrapper>
                <div>
                  <p className="user">{name}</p>
                  <p>{job}</p>
                </div>
              </Styled.UserCompetition>
            </Styled.CardWrapper>
          ))}
        </div>
        <div className="slidWrapper2">
          {cards.map(({ title, name, job, image }) => (
            <Styled.CardWrapper>
              <p>{title}</p>
              <Styled.UserCompetition>
                <Styled.ImgWrapper>
                  <GatsbyImage
                    image={image.childImageSharp.gatsbyImageData}
                    alt="session"
                    objectFit="contain"
                  />
                </Styled.ImgWrapper>
                <div>
                  <p className="user">{name}</p>
                  <p>{job}</p>
                </div>
              </Styled.UserCompetition>
            </Styled.CardWrapper>
          ))}
        </div>
      </Styled.Wrapper>
    </Styled.ContainerSlider>
  );
};
export default SliderAboutAs;
