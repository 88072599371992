import PropTypes from 'prop-types';
import Paragraph from 'components/ui/Paragraph';
import CalendarImage from 'img/ui/calendar.inline.svg';
import { format } from 'date-fns';
import * as Styled from './styled';

const CardPreview = ({ date, title, link }) => {
  const formattedDate = format(new Date(date), 'MMMM yyyy');
  return (
    <Styled.Card href={link}>
      <Styled.Container>
        <CalendarImage />
        <Paragraph size="xl">{formattedDate}</Paragraph>
      </Styled.Container>
      <Styled.CardTitle>{title}</Styled.CardTitle>
    </Styled.Card>
  );
};

export default CardPreview;

CardPreview.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};
