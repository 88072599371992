import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import { StyledLink } from 'components/ui/link';

export const Section = styled.section`
  margin-top: 90px;
  & ol {
    padding-inline-start: 20px;
  }
  ${media.desktopWide} {
    margin-top: 3rem;
  }
  ${media.mobile} {
    margin-top: 1rem;
    ${StyledLink} {
      font-size: 18px;
    }
  }
`;

export const Container = styled.div`
  ${media.desktopWide} {
    margin-bottom: 3rem;
  }
  ${media.mobile} {
    margin-bottom: 2rem;
    & h1.line {
      & span {
        &::after {
          display: none;
        }
      }
    }
  }
`;

export const SubTitle = styled.p`
  font-size: 22px;
  line-height: 34px;
  margin-top: 32px;
  ${media.mobile} {
    margin-top: 24px;
    font-size: 20px;
    line-height: 30px;
  }
`;

export const CustomButton = styled.button`
  background-color: transparent;
  border: none;
  font-size: 18px;
  line-height: normal;
  color: #000;
  opacity: 0.6;
  font-weight: 400;
  &.active {
    font-weight: 800;
    opacity: 1;
    position: relative;
    &::after {
      content: '';
      width: 90%;
      height: 2px;
      background-color: #0a4ed6;
      position: absolute;
      bottom: -4px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

export const Buttons = styled.div`
  margin-bottom: 16px;
  margin-top: 40px;
  ${media.mobile} {
    margin-top: 24px;
  }

  ${CustomButton}:first-child {
    margin-right: 40px;
    ${media.mobile} {
      margin-right: 16px;
    }
    @media (max-width: 340px) {
      margin-right: 0;
    }
  }
`;

export const Preview = styled.div`
  display: flex;
  margin: 32px 0 48px;
  align-items: center;
  ${media.mobile} {
    margin: 24px 0 32px;
  }
  & p {
    margin: 0 16px 0 0;
  }
  & img {
    max-width: 130px;
  }
`;
