import { StyledLink } from 'components/ui/link';
import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const ChatWithUs = styled.button`
  color: #0346c9;
  border: none;
  background-color: #fff;
  padding: 0;
  font-weight: 300;
  &:hover {
    text-decoration: underline;
  }
`;

export const StyledSection = styled.section`
  ${media.mobile} {
    ${StyledLink} {
      font-size: 18px;
    }
  }
`;
