import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import { StyledButton } from 'components/ui/button';

export const NewsletterForm = styled.form`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  ${media.mobile} {
    flex-wrap: wrap;
    width: 100%;
    flex-direction: column-reverse;
  }
  ${StyledButton} {
    border: none;
    height: 44px;
    font-size: 16px;
    padding: 10px 22px;
    border-radius: 0;
    border-radius: 4px;
    ${media.mobile} {
      border-radius: 4px;
      width: 100%;
    }
    &:hover {
      border-color: #000;
    }
  }
  .agreement {
    display: flex;
    margin-top: 16px;
    position: relative;
    input {
      cursor: pointer;
    }
    p {
      margin: -4px 0 0 16px;
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      & a {
        color: #fff;
        text-decoration: underline;
      }
      ${media.mobile} {
        font-size: 12px;
        line-height: 18px;
      }
    }
    ${media.mobile} {
      margin-top: 0;
      margin-bottom: 24px;
    }
  }
  .subscribe {
    margin-bottom: 0;
    width: 100%;
    margin-right: 16px;
    position: relative;
    ${media.mobile} {
      width: 100%;
      margin-bottom: 16px;
    }
    input {
      border-radius: 4px;
      border: none;
      height: 44px;
      width: inherit;
      padding-left: 16px;
      outline: 0;
      -webkit-appearance: none;
      border: 2px solid #0a4ed6;
      &:disabled {
        opacity: 0.5;
      }
      &:focus {
        border-color: #0a4ed6;
      }
      &::placeholder {
        font-size: 16px;
        opacity: 1;
        font-weight: 400;
        color: #808080;
      }
      ${media.mobile} {
        width: 100%;
        border-radius: 4px;
      }
    }
    input.error {
      border: 1px solid #ff0157;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;

  ${media.mobile} {
    flex-direction: column;
  }
`;

export const Message = styled.span`
  font-size: 12px;
  display: none;
  margin-top: 4px;
  &.error {
    display: block;
    color: #ff0157;
  }
  &.success {
    display: block;
    color: #00f50a;
  }
  ${media.mobile} {
    bottom: 36px;
    &.active {
      transform: translateY(28px);
    }
  }
`;
