import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from 'layout';
import Header from 'components/customer/header';
import Recommendation from 'components/customer/recommendation';
import StartUsing from 'components/ui/startUsing';
import FinalOutcome from 'components/customer/finalOutcome';
import Banner from 'components/usability-testing/banner';
import {
  HeroImage,
  SectionWithRecommendation,
  DescriptionBox,
  StyledSection,
} from 'components/styled/CustomerPage';
import { media } from 'theme/breakpoints';
import Container from 'components/customer/breadcrumbs';
import Link from 'components/ui/link';

const RecommendationBox = styled.div`
  & div.recommendation-box {
    margin-bottom: 90px;
    ${media.tablet} {
      margin-bottom: 24px;
    }
  }
`;

const LiveReacting = () => {
  const { hero, asharma, post } = useStaticQuery(graphql`
    query livereactingPhotos {
      hero: file(relativePath: { eq: "livereacting/livereacting.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 95)
        }
      }
      asharma: file(relativePath: { eq: "persons/alexanderShpilka.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 95)
        }
      }
      post: markdownRemark(frontmatter: { company: { eq: "LiveReacting" } }) {
        frontmatter {
          description
          title
          slug
          company
        }
      }
    }
  `);

  const { frontmatter } = post;

  const imgSocial = process.env.GATSBY_HOST + hero.childImageSharp.gatsbyImageData.src;
  const descriptionSocial = frontmatter.description;
  const { title, slug } = frontmatter;

  const extraMetaTags = [
    { property: 'fb:app_id', content: '926720414193529' },
    { property: 'og:type', content: 'article' },
    { property: 'og:url', content: process.env.GATSBY_HOST + slug },
    { property: 'og:title', content: title },
    { property: 'og:site_name', content: 'LiveSession' },
    { name: 'twitter:title', content: title },
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:site', content: '@LiveSessionHQ' },
  ];

  if (imgSocial) {
    extraMetaTags.push({ property: 'og:image', content: imgSocial });
    extraMetaTags.push({
      property: 'og:image:secure_url',
      content: imgSocial,
    });
    extraMetaTags.push({ property: 'og:image:width', content: 1287 });
    extraMetaTags.push({ property: 'og:image:height', content: 685 });
    extraMetaTags.push({ name: 'twitter:image', content: imgSocial });
  }

  if (descriptionSocial) {
    extraMetaTags.push({
      property: 'og:description',
      content: descriptionSocial,
    });
    extraMetaTags.push({
      name: 'twitter:description',
      content: descriptionSocial,
    });
  }

  return (
    <Layout
      metaTitle={frontmatter.title}
      titleOverride
      metaDescription={frontmatter.description}
      extraTags={extraMetaTags}
      canonical="/customers/livereacting/"
      image={hero.childImageSharp.gatsbyImageData.src}
    >
      <div className="container">
        <Container title="LiveReacting">
          <Header
            title={
              <>
                How have LiveSession led LiveReacting to success in creating an{' '}
                <span>extraordinary</span> customer-centric product
              </>
            }
            description="Better service in a shorter time? What more do you need?"
            titleStyle={{ maxWidth: 920 }}
          />
          <HeroImage
            image={hero.childImageSharp.gatsbyImageData}
            clasName="img-fluid"
            alt="website - hero"
            title="Plaxonic team"
            style={{ maxHeight: 500 }}
          />
          <SectionWithRecommendation>
            <DescriptionBox>
              <p>
                {' '}
                LiveReacting have been helping their customers for over four years. They can create
                their video streams much more engaging.
              </p>
              <p>
                In a time, when the number of people streaming on YouTube or social media is
                increasing tremendously, the quality of a stream is not enough. What can make one
                streaming stand out from the rest is the possibility of live interaction. And that
                is where LiveReacting helps. Their customers can add pre-recorded videos, or
                interactive elements, such as countdowns, polls, or games to their streams.
              </p>
            </DescriptionBox>
            <FinalOutcome>
              <h2>Key achievements</h2>
              <ul>
                <li>
                  <p>customer-centric product</p>
                </li>
                <li>
                  <p>
                    <strong>faster</strong> defect detection
                  </p>
                </li>
                <li>
                  <p>
                    insights gathered for a <strong>selected segment</strong>
                  </p>
                </li>
                <li>
                  <p>
                    <strong>no need</strong> to read logs every time
                  </p>
                </li>
              </ul>
            </FinalOutcome>
          </SectionWithRecommendation>
          <StyledSection>
            <h2>Completing the Toolbox</h2>
            <p>
              When the area of focus is the digital world, one needs the right toolbox to succeed.
              LiveReacting uses Amplitude, Google Analytics, and Wistia among many other solutions.
              From the beginning, they knew they needed a session tracking tool. After trying one of
              the available software solutions for a while, they decided to switch to LiveSession.
            </p>
          </StyledSection>
          <Recommendation
            recommendation="We’ve been using LiveSession for over three years, and we are incredibly happy with this choice. This software completes the needed set of tools that are essential for our success."
            person="Alexander Shpilka"
            job="CEO & Founder"
            company="LiveReacting"
            url="https://www.livereacting.com/"
            image={asharma.childImageSharp.gatsbyImageData}
            style={{ marginBottom: 0 }}
          />
          <StyledSection>
            <p>
              LiveReacting have started using LiveSession after the release of their Browser
              Streaming Studio. It is a very sophisticated application with a Rich User Interface.
            </p>

            <Banner title="Are you sure your toolbox is completed?" />
          </StyledSection>

          <StyledSection>
            <h2>No more reading logs!</h2>
            <p>
              In theory, LiveReacting could have been fine without software like LiveSession. They
              had a lot of information in their logs. But now imagine that a single user session
              consists of hundreds or thousands of clicks. On top of that, there are various ways to
              interact with the application. So to understand what happened during a single user
              session you would have to read logs for several hours. LiveSession speeds up the whole
              process significantly and gives you more context. Comparing the logs to a script
              written by a good screenwriter, the benefits provided by LiveSession could be compared
              to the movie based on that script.
            </p>
            <p>
              Depending on their needs, LiveReacting can use LiveSession Filters and Segmentation to
              focus on specific aspects of customer’s needs or when a particular issue is
              investigated. For example, they can analyze user journeys that include a specific part
              of their application or segment their customers by a device they have been using.
              Then, with the context of the situation, and knowing the user perspective of
              unexpected application behavior, the LiveReacting specialists can read more details in
              logs to get a perspective from the technical standpoint. In this way, they figure out
              the effect and the cause. Such knowledge allows for quick fixes.
            </p>
          </StyledSection>
          <StyledSection>
            <h2>Faster investigation, integration, and support.</h2>

            <p>
              As mentioned earlier, LiveSession has made it possible to quickly analyze multiple
              user sessions in a short time, instead of time-consuming reading of inconclusive logs.
              But that is just one of many benefits of this great solution. It took LiveReacting
              less than one hour to connect LiveSession to their website. They also integrated it
              with the live chat provided by{' '}
              <Link
                href="https://www.chaport.com/"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                cwhaport.com
              </Link>
              . This way, when a user interacts with LiveReacting chat operators it is possible to
              see their sessions immediately.
            </p>
            <Banner title="Quickly find out where the issue is and fix it!" />
          </StyledSection>
          <StyledSection>
            <h2>UX Analysis supported by Filters and Segmentation </h2>
            <p>
              With LiveSession, LiveReacting can analyze the user sessions assigned to different
              segments. LiveSession allows session segmentation basing on the user’s interaction
              intensity, referrer source, specific session characteristics (such as duration, exit
              URL, start URL, and many others), user’s locations, device, browser, OS, or screen
              size.
            </p>
            <p>
              These filters helped to gain valuable insights. By segmenting sessions by the screen
              size used by their visitors, LiveReacting discovered that in case the user&apos;s
              browser had been set to a non-standard viewport, the website was not rendered
              properly. With LiveSession, they were able to improve their website responsiveness in
              every case.
            </p>
            <p>
              {' '}
              When the sessions were segmented by Browser, it was easy to discover that in some of
              the less popular browsers, a particular functionality could not work as intended. For
              example, certain actions worked fine in Chrome, Opera, or Firefox. However, in the
              Safari browser, clicking on a particular button did not generate the expected result.
            </p>
            <p>
              LiveReacting support team can also respond faster and be more helpful to customers
              requesting immediate assistance. A lookup of the user’s last session is possible, and
              the information about OS, browser, screen, and other important details is available
              immediately. This lets the support team know whether a user has made a misstep in
              using the application or the issue is caused by an unexpected glitch. Sometimes it is
              easy to quickly replicate the error because the chat operator knows in which browser
              the reported situation should be recreated.
            </p>
          </StyledSection>
          <StyledSection>
            <h2>Data to improve Customer Experience</h2>
          </StyledSection>
          <RecommendationBox>
            <Recommendation
              recommendation="One of the main benefits of using LiveSession is the ability to observe how our customers are using the app and what challenges they are experiencing. This gives us insight into what we can improve."
              person="Alexander Shpilka"
              job="CEO & Founder"
              company="LiveReacting"
              image={asharma.childImageSharp.gatsbyImageData}
              style={{ marginBottom: 0 }}
            />
          </RecommendationBox>

          <StyledSection>
            <p>
              LiveReacting can share the experiences of their users. With LiveSession, they watch
              different sessions and they can go head-to-head with every customer. They learn what
              the differences are when users come from different locations, and use different
              devices or operating systems. Each user group may face specific challenges while using
              the application. It may also have unique needs. After careful analysis of data
              provided by LiveSession, it is possible to improve UX Design for every segment group.
            </p>
            <Banner title="Find out how to improve your website’s UX in no time" />
          </StyledSection>
          <FinalOutcome lastSection>
            <h2>Final outcome</h2>
            <p>
              The Filters are one of the many useful features of LiveSession. They allow selection
              of sessions from a chosen segment to view. This segmentation based on session
              features, user interactions, customer data, and system data can help quickly find
              recurring issues for a given group of users. This has helped LiveRacting and can help
              any company significantly improve the User Experience and create an outstanding
              customer-centric product.
            </p>
          </FinalOutcome>
        </Container>
      </div>
      <StartUsing />
    </Layout>
  );
};

export default LiveReacting;
