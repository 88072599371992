import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import { StyledLink } from 'components/ui/link';

export const Section = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: ${(props) => (props.idx % 2 ? "'content image'" : "'image content'")};
  grid-column-gap: 80px;
  align-items: center;
  ${media.desktopWide} {
    grid-column-gap: 60px;
  }
  ${media.desktop} {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  ${media.mobile} {
    padding-bottom: 30px;
  }
`;

export const Banner = styled.div`
  border-radius: 6px;
  border: 2px solid #000;
  padding: 24px 17px;
  width: fit-content;
  max-width: ${(props) => (props.intercom ? '90%' : '70%')};
  & img {
    margin-bottom: 16px;
  }
  & p {
    margin-bottom: 24px;
    font-weight: 800;
  }
  ${media.desktopWide} {
    max-width: 90%;
  }
  ${media.desktop} {
    max-width: 75%;
    margin: 40px auto 0;
    ${StyledLink} {
      margin: 0 auto !important;
    }
  }
  ${media.tablet} {
    & p {
      font-size: 16px;
      line-height: 28px;
    }
  }
  ${media.mobile} {
    max-width: 100%;
  }
`;

export const ContentBox = styled.div`
  grid-area: content;
  & .icon {
    margin-bottom: 16px;
  }
  & h2 {
    margin-bottom: 40px;
  }
  & .desc {
    margin-bottom: 40px;
  }
  ${media.desktop} {
    text-align: center;
    ${StyledLink} {
      margin: 40px auto 0;
    }
  }
  ${media.tablet} {
    & h2 {
      margin-bottom: 24px;
    }
    & .desc {
      margin-bottom: 32px;
      font-size: 16px;
      line-height: 28px;
    }
  }
`;

export const MainImage = styled.img`
  grid-area: image;
  &.mobile {
    display: none;
  }
  ${media.desktop} {
    grid-area: auto;
    display: none;
    &.mobile {
      display: block;
      margin: 0 auto;
    }
  }
`;
