/* eslint-disable jsx-a11y/anchor-is-valid */

import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import withIntegrationCardContext from 'hoc/withIntegrationCardContext';
import Slider from 'react-slick';

const LiveChat = ({ sliderSettings }) => {
  const { img1 } = useStaticQuery(graphql`
    {
      img1: file(relativePath: { eq: "help/livechat/marketplace-lc.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 95)
        }
      }
    }
  `);
  const photos = [
    {
      img: img1.childImageSharp,
      alt: 'Livechat Integration',
      title: 'Integrate Livechat with LiveSession',
    },
    {
      img: 'https://res.cloudinary.com/dn1j6dpd7/image/upload//v1597821688/Media%20Kit/Product%20screenshots/LiveChat/LiveChat_Chats.png',
      alt: 'Livechat Integration',
      title: 'Integrate Livechat with LiveSession',
    },
    {
      img: 'https://res.cloudinary.com/dn1j6dpd7/image/upload//v1597821691/Media%20Kit/Product%20screenshots/LiveChat/LiveChat_Customer_List.png',
      alt: 'Livechat Integration',
      title: 'Integrate Livechat with LiveSession',
    },
    {
      img: 'https://res.cloudinary.com/dn1j6dpd7/image/upload//v1597821690/Media%20Kit/Product%20screenshots/LiveChat/LiveChat_Reports.png',
      alt: 'Livechat Integration',
      title: 'Integrate Livechat with LiveSession',
    },
  ];
  return (
    <>
      <Slider {...sliderSettings(photos)}>
        <div>
          <GatsbyImage
            image={photos[0].img?.gatsbyImageData}
            alt={photos[0].alt}
            title={photos[0].title}
          />
        </div>
        <div>
          <img
            src={photos[1].img}
            alt={photos[1].alt}
            title={photos[1].title}
            className="img-fluid"
          />
        </div>
        <div>
          <img
            src={photos[2].img}
            alt={photos[2].alt}
            title={photos[2].title}
            className="img-fluid"
          />
        </div>
        <div>
          <img
            src={photos[3].img}
            alt={photos[3].alt}
            title={photos[3].title}
            className="img-fluid"
          />
        </div>
      </Slider>
      <div className="section">
        <h2>Overview</h2>
        <p>
          The name speaks for itself: LiveChat is a market-leading live chat solution and a
          comprehensive customer service platform. It offers a wide range of features to help you
          manage customer support more effectively. Apart from customer service, LiveChat works
          great for lead generation and online surveys.
        </p>
        <p>
          What’s more, you can integrate it with LiveSession to see how visitors interact with the
          website.
        </p>
      </div>
      <div className="section">
        <h2>Benefits</h2>
        <ul className="checkmarks">
          <li>Access session replays from the live chat application</li>
          <li>Get more context on user behavior</li>
          <li>Provide better help for your customers</li>
        </ul>
      </div>
      <h2>How to install</h2>
      <p>
        You can install LiveChat integration with one click. See a step-by-step tutorial in our{' '}
        <a href="/help/livechat-integration/">help center</a>.
      </p>
    </>
  );
};

export default withIntegrationCardContext(LiveChat);

LiveChat.propTypes = {
  sliderSettings: PropTypes.func.isRequired,
};
