import CustomLink from 'components/ui/link';
import star from 'img/ui/starBanner.svg';
import PropTypes from 'prop-types';
import { data } from 'data/marketplace/sidebar';
import * as Styled from './styled';

const Banner = ({ activeCategory, card, text }) => {
  const [getCurrentCat] = data.flatMap(({ list }) =>
    list.filter(({ title }) => title === activeCategory),
  );
  return (
    <Styled.Banner>
      <Styled.MainContent>
        {getCurrentCat.image}
        <h3>
          <span>{activeCategory}</span>
        </h3>
        <p>{text}</p>
      </Styled.MainContent>
      <div>
        <Styled.Card href={card.link}>
          <div className="header-container">
            <img src={card.img} alt={`Logo - ${card.title}`} title={card.title} className="logo" />
            <div>
              <img src={star} alt="Star" title="Most popular" />
              <p>Most popular</p>
            </div>
          </div>
          <h4>{card.title}</h4>
          <p>{card.desc}</p>
          <CustomLink withArrow tag="p">
            Learn more
          </CustomLink>
        </Styled.Card>
      </div>
    </Styled.Banner>
  );
};

export default Banner;

Banner.propTypes = {
  card: PropTypes.oneOfType([PropTypes.object]).isRequired,
  text: PropTypes.string.isRequired,
  activeCategory: PropTypes.string.isRequired,
};
