import { FeatureConditions, Features } from 'data/pricing/features';
import Button from 'components/ui/button';
import Tippy from '@tippyjs/react';
import PropTypes from 'prop-types';
import TableMobile from 'components/pricing/TableMobile';
import checkmark from 'img/ui/checkmarkBlue.svg';
import cross from 'img/ui/cross.svg';
import LearnMoreIcon from 'img/ui/moreInfo.inline.svg';
import pricingModel from 'helpers/pricing.json';
import * as Styled from './styled';
import 'css/entypo.css';
import { Plans } from '../../../data/pricing/plans';

const billingAndService = [
  {
    name: 'Billing cycle',
    key: Features.BillingAndService.Term,
    description: '',
  },
  {
    name: 'Payment type',
    key: Features.BillingAndService.PaymentType,
    description: 'Available method of payment.',
  },
  {
    name: 'Email support',
    key: Features.BillingAndService.EmailSupport,
    description: 'Get help by the email.',
  },
  {
    name: 'Chat support',
    key: Features.BillingAndService.ChatSupport,
    description: 'Get help on a chat.',
  },
  // {
  //   name: 'Priority support',
  //   key: 'priority_support',
  //   description: 'Your cases are most important for our support team.',
  // },
  {
    name: 'Custom paperwork',
    key: Features.BillingAndService.CustomPaperwork,
    description: 'Custom agreements, DPA, Security audits etc.',
  },
  {
    name: 'Product training',
    key: Features.BillingAndService.Training,
    description: 'Training session that will teach you how to effectivly use LiveSession.',
  },
];

const privacy = [
  {
    name: 'Exclude sensitive data',
    key: Features.Privacy.ContentExclude,
    description: 'Exclude static or dynamic sensitive elements from beeing recorded.',
  },
  {
    name: 'GDPR compliance',
    key: Features.Privacy.GDPR,
    description: '',
  },
  {
    name: 'CCPA compliance',
    key: Features.Privacy.CCPA,
    description: '',
  },
];

const usage = [
  {
    name: 'Sessions per month',
    key: Features.Usage.SessionsLimit,
    description: 'The number of sessions which can be recorded every month.',
  },
  {
    name: 'Data retention',
    key: Features.Usage.DataRetention,
    description: 'Choose for how long your data will be stored.',
  },
  {
    name: 'Team members',
    key: Features.Usage.Seats,
    description: 'Number of people that can use organization account.',
  },
  {
    name: 'Websites / Projects',
    key: Features.Usage.Websites,
    description: 'Create a different projects for multiple websites.',
  },
];

const sessionReplays = [
  {
    name: 'Session replay',
    key: Features.SessionReplays.SessionReplay,
    description: 'See mouse movements, clicks, scrolling and more.',
  },
  {
    name: 'Clickmaps',
    key: Features.SessionReplays.ClickMaps,
    description: 'See how users interact with specific elements on your website.',
  },
  {
    name: 'Heatmaps',
    key: Features.SessionReplays.Heatmaps,
    description: 'Find the hottest sections on your website with heat maps.',
  },
  {
    name: 'Engagement score',
    key: Features.SessionReplays.EngagementScore,
    description: 'Find and watch the most interesting recordings based on user activity.',
  },
  {
    name: 'Speed up session replay',
    key: Features.SessionReplays.PlaybackSpeed,
    description: 'Speed up recordings to watch them faster.',
  },
  {
    name: 'Skip inactivity',
    key: Features.SessionReplays.SkipInactivity,
    description: 'Skip idle moments in the recordings.',
  },
  {
    name: 'Autoplay',
    key: Features.SessionReplays.Autoplay,
    description: 'Automatically play next session from current segment.',
  },
  {
    name: 'Recording rules',
    key: Features.SessionReplays.RecordingRules,
    description: 'Exclude recording based on their URL, IP and other variables.',
  },
  {
    name: 'Rage clicks & Error clicks',
    key: Features.SessionReplays.RageAndErrorClicks,
    description: 'Spot errors and points of frustration.',
  },
  {
    name: 'Filters',
    key: Features.SessionReplays.Filters,
    description: 'Use a wide range of filtering options to browse your recordings.',
  },
  {
    name: 'Forms analytics',
    key: Features.SessionReplays.FormAnalytics,
    description: 'Track forms submissions and abandonments.',
  },
  {
    name: 'Session sharing',
    key: Features.SessionReplays.SessionsSharing,
    description: 'Share sessions with your team.',
  },
  {
    name: 'Minimum time on page',
    key: Features.SessionReplays.MinDuration,
    description: "Don't capture sessions shorter than chosen time.",
  },
  {
    name: 'Tagging',
    key: Features.SessionReplays.Tagging,
    description: 'Tag session recordings to find them easily later.',
  },
  {
    name: 'Resource backup',
    key: Features.SessionReplays.ResourceSynchronization,
    description:
      'Styles and fonts are saved to properly display session even when the original files are deleted.',
  },
  {
    name: 'DevTools',
    key: Features.SessionReplays.DeveloperTools,
    description: 'Track console logs and errors.',
  },
];

const analytics = [
  {
    name: 'Funnels',
    key: Features.Analytics.Funnels,
    description: 'Create funnels to track conversions and drop-offs.',
  },
  {
    name: 'Dashboards',
    key: Features.Analytics.Dashboard,
    description: 'Build dashboards with metrics you want to track.',
  },
  {
    name: 'User journey',
    key: Features.Analytics.UserJourney,
    description: 'Discover the most important actions made during the user’s session',
  },
  {
    name: 'Segments',
    key: Features.Analytics.Segments,
    description: 'Build multiple segments to track different behavioral patterns.',
  },
  {
    name: 'Email notifications',
    key: Features.Analytics.SegmentNotifications,
    description: 'Daily or weekly email notifications about segment changes.',
  },
  {
    name: 'Data export',
    key: Features.Analytics.DataExport,
    description: 'Export users or events data to CSV or JSON.',
  },
];

const features = [
  {
    name: 'Integrations',
    key: Features.Other.Integrations,
    description: 'Integrate LiveSession with other tools.',
  },
  {
    name: 'Premium integrations',
    key: Features.Other.PremiumIntegrations, // TODO:
    description: 'Integrate LiveSession with Intercom, LiveChat or Zendesk.',
    integrations: ['Intercom', 'LiveChat', 'Zendesk'],
  },
  {
    name: 'User identification',
    key: Features.Other.UserIdentification,
    description: 'Identify your users by name or email.',
  },
  {
    name: 'Custom properties',
    key: Features.Other.CustomProperties,
    description:
      'Extend sessions with custom data (e.g. subscription plan) and use it to filter your recordings.',
  },
  {
    name: '2FA',
    key: Features.Other.MultiFactorAuthentication,
    description: '',
    soon: true,
  },
  {
    name: 'Notes',
    key: Features.Other.Notes,
    description: '',
    soon: true,
  },
  {
    name: 'Custom events',
    key: Features.Other.CustomEvents,
    description: 'Capture any interaction your customers make with your app.',
  },
  {
    name: 'REST API',
    key: Features.Other.RestAPI,
    description: 'Retrieve collected data programmatically.',
  },
  {
    name: 'Audit logs',
    key: Features.Other.AuditLogs,
    description: '',
    soon: true,
  },
  {
    name: 'SSO/SAML (Octa, Google, MS)',
    key: Features.Other.SSO,
    description: '',
    soon: true,
  },
  {
    name: 'Alerts',
    key: Features.Other.Alerts, // ?
    description: '',
    soon: true,
  },
  {
    name: 'Webhooks',
    key: Features.Other.Webhooks,
    description: '',
    soon: true,
  },
  {
    name: 'Custom user access',
    key: Features.Other.CustomUserAccess,
    description: '',
    soon: true,
  },
  {
    name: 'Organizations',
    key: Features.Other.Organizations,
    description: '',
    soon: true,
  },
];

const renderList = (list, featureList) => {
  const renderContent = (feature, plan) => {
    const planFeatures = featureList[feature];
    if (!planFeatures || feature === 'premium_integrations' || feature === 'integrations') {
      return null;
    }
    const feat = planFeatures[plan];

    if (typeof feat !== 'undefined') {
      if (typeof feat === 'boolean') {
        return feat ? <img src={checkmark} alt="checkmark" /> : <img src={cross} alt="cross" />;
      }

      return feat;
    }

    return null;
  };
  const finalList = list
    .filter((item) => {
      if (item.key === 'integrations') return null;
      if (item.key === 'premium_integrations') return null;
      return features;
    })
    .filter(Boolean);
  return finalList.map((item) => {
    if (item.soon) return null;
    return (
      <tr key={item.key}>
        <th scope="row" style={{ fontWeight: 700 }}>
          <div className="row-description">
            {item.description ? (
              <Tippy content={item.description} className="custom" placement="right">
                <div>
                  <span>{item.name} </span>
                  <i>
                    <LearnMoreIcon />
                  </i>
                </div>
              </Tippy>
            ) : (
              <span>{item.name} </span>
            )}
          </div>
        </th>
        <td key={`${item.key}.free`}>{renderContent(item.key, Plans.Free)}</td>
        <td key={`${item.key}.basic`}>{renderContent(item.key, Plans.Basic)}</td>
        <td key={`${item.key}.pro`}>{renderContent(item.key, Plans.Pro)}</td>
        <td key={`${item.key}.enterprise`}>{renderContent(item.key, Plans.Enterprise)}</td>
      </tr>
    );
  });
};

const FeaturesComponent = ({ currency, type }) => {
  const basicPrice = pricingModel.pricing.basic[0].prices['1m'][currency.toUpperCase()][type];
  const proPrice = pricingModel.pricing.pro[0].prices['1m'][currency.toUpperCase()][type];
  return (
    <>
      <Styled.Header>
        <h2>Full feature breakdown</h2>
        <p>
          Compare the features and benefits of each plan. Still not sure which plan is best for you?{' '}
        </p>
        <Button signUp sourceID="table" secondary>
          Sign up free
        </Button>
      </Styled.Header>
      <TableMobile
        privacy={privacy}
        sessionReplays={sessionReplays}
        analytics={analytics}
        features={features}
        billingAndService={billingAndService}
        usage={usage}
      />
      <Styled.FeaturesTable className="table">
        <thead className="sticky">
          <tr style={{ borderBottom: '1px solid #ebebeb' }}>
            <th scope="col" className="plan">
              <div>
                <p>
                  Compare <br /> plans
                </p>
              </div>
            </th>
            <th scope="col" className="plan">
              <div>
                <p>Free</p>
                <span>{currency === 'usd' ? '$' : '€'}0 / mo</span>
              </div>
            </th>
            <th scope="col" className="plan">
              <div>
                <p>Basic</p>
                <span>
                  starting at {currency === 'usd' ? '$' : '€'}
                  {((type === 'annual' ? basicPrice / 12 : basicPrice) / 100).toFixed()} / mo
                </span>
              </div>
            </th>
            <th scope="col" className="plan">
              <div>
                <p>Pro</p>
                <span>
                  starting at {currency === 'usd' ? '$' : '€'}
                  {((type === 'annual' ? proPrice / 12 : proPrice) / 100).toFixed()} / mo
                </span>
              </div>
            </th>
            <th scope="col" className="plan">
              <div>
                <p>Enterprise</p>
                <span>custom pricing</span>
              </div>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <th className="title" scope="row" colSpan="5">
              Usage
            </th>
          </tr>
          {renderList(usage, FeatureConditions.Usage)}
          <tr>
            <th className="title" scope="row" colSpan="5">
              Session replays & Clickmaps
            </th>
          </tr>
          {renderList(sessionReplays, FeatureConditions.SessionReplays)}
          <tr>
            <th className="title" scope="row" colSpan="5">
              Analytics
            </th>
          </tr>
          {renderList(analytics, FeatureConditions.Analytics)}
          <tr>
            <th className="title" scope="row" colSpan="5">
              Customization
            </th>
          </tr>
          {renderList(features, FeatureConditions.Other)}
          <tr>
            <th className="title" scope="row" colSpan="5">
              Integrations
            </th>
          </tr>
          <tr>
            <th scope="row" style={{ fontWeight: 700 }}>
              <div className="row-description">
                <span>Intercom</span>
              </div>
            </th>
            <td>
              <img src={cross} alt="cross" />
            </td>
            <td>
              <img src={cross} alt="cross" />
            </td>
            <td>
              <img src={checkmark} alt="checkmark" />
            </td>
            <td>
              <img src={checkmark} alt="checkmark" />
            </td>
          </tr>
          <tr>
            <th scope="row" style={{ fontWeight: 700 }}>
              <div className="row-description">
                <span>LiveChat</span>
              </div>
            </th>
            <td>
              <img src={cross} alt="cross" />
            </td>
            <td>
              <img src={cross} alt="cross" />
            </td>
            <td>
              <img src={checkmark} alt="checkmark" />
            </td>
            <td>
              <img src={checkmark} alt="checkmark" />
            </td>
          </tr>
          <tr>
            <th scope="row" style={{ fontWeight: 700 }}>
              <div className="row-description">
                <span>Zendesk</span>
              </div>
            </th>
            <td>
              <img src={cross} alt="cross" />
            </td>
            <td>
              <img src={cross} alt="cross" />
            </td>
            <td>
              <img src={checkmark} alt="checkmark" />
            </td>
            <td>
              <img src={checkmark} alt="checkmark" />
            </td>
          </tr>
          <tr>
            <th scope="row" style={{ fontWeight: 700 }}>
              <div className="row-description">
                <span>Sentry</span>
              </div>
            </th>
            <td>
              <img src={checkmark} alt="checkmark" />
            </td>
            <td>
              <img src={checkmark} alt="checkmark" />
            </td>
            <td>
              <img src={checkmark} alt="checkmark" />
            </td>
            <td>
              <img src={checkmark} alt="checkmark" />
            </td>
          </tr>
          <tr>
            <th scope="row" style={{ fontWeight: 700 }}>
              <div className="row-description">
                <span>Segment</span>
              </div>
            </th>
            <td>
              <img src={checkmark} alt="checkmark" />
            </td>
            <td>
              <img src={checkmark} alt="checkmark" />
            </td>
            <td>
              <img src={checkmark} alt="checkmark" />
            </td>
            <td>
              <img src={checkmark} alt="checkmark" />
            </td>
          </tr>
          <tr>
            <th scope="row" style={{ fontWeight: 700 }}>
              <div className="row-description">
                <span>Google Analytics</span>
              </div>
            </th>
            <td>
              <img src={checkmark} alt="checkmark" />
            </td>
            <td>
              <img src={checkmark} alt="checkmark" />
            </td>
            <td>
              <img src={checkmark} alt="checkmark" />
            </td>
            <td>
              <img src={checkmark} alt="checkmark" />
            </td>
          </tr>
          <tr>
            <th scope="row" style={{ fontWeight: 700 }}>
              <div className="row-description">
                <span>Shopify</span>
              </div>
            </th>
            <td>
              <img src={checkmark} alt="checkmark" />
            </td>
            <td>
              <img src={checkmark} alt="checkmark" />
            </td>
            <td>
              <img src={checkmark} alt="checkmark" />
            </td>
            <td>
              <img src={checkmark} alt="checkmark" />
            </td>
          </tr>
          <tr>
            <th scope="row" style={{ fontWeight: 700 }}>
              <div className="row-description">
                <span>Google Optimize</span>
              </div>
            </th>
            <td>
              <img src={checkmark} alt="checkmark" />
            </td>
            <td>
              <img src={checkmark} alt="checkmark" />
            </td>
            <td>
              <img src={checkmark} alt="checkmark" />
            </td>
            <td>
              <img src={checkmark} alt="checkmark" />
            </td>
          </tr>
          <tr>
            <th scope="row" style={{ fontWeight: 700 }}>
              <div className="row-description">
                <span>Survicate</span>
              </div>
            </th>
            <td>
              <img src={checkmark} alt="checkmark" />
            </td>
            <td>
              <img src={checkmark} alt="checkmark" />
            </td>
            <td>
              <img src={checkmark} alt="checkmark" />
            </td>
            <td>
              <img src={checkmark} alt="checkmark" />
            </td>
          </tr>
          <tr>
            <th className="title" scope="row" colSpan="5">
              Privacy
            </th>
          </tr>
          {renderList(privacy, FeatureConditions.Privacy)}
          <tr>
            <th className="title" scope="row" colSpan="5">
              Billing & Service
            </th>
          </tr>
          {renderList(billingAndService, FeatureConditions.BillingAndService)}
        </tbody>
      </Styled.FeaturesTable>
    </>
  );
};

export default FeaturesComponent;

FeaturesComponent.propTypes = {
  currency: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};
