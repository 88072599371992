import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import { Link } from 'gatsby';

const Adnotations = styled.div`
  ${media.tablet} {
    margin-top: 56px;
  }
  ${media.mobile} {
    margin-top: 32px;
  }
  p {
    color: ${(props) => (props.pricing ? '#fff' : '#000')};
    font-size: 20px;
    font-weight: 500;
    ${media.tablet} {
      margin-bottom: 24px;
      font-size: 18px;
      line-height: 32px;
    }
  }
  a {
    color: ${(props) => (props.pricing ? '#7DFFEF' : '#0a4ed6')};
    text-decoration: none;
    display: inline-block;
    font-weight: 800;
    margin-right: 58px;
    font-size: 22px;
    ${media.desktopWide} {
      margin-right: 20px;
    }
    ${media.tablet} {
      margin-right: 0;
    }
    &:hover {
      color: ${(props) => (props.pricing ? '#7DFFEF' : '#0a4ed6')};
      text-decoration: underline;
    }
  }
`;

export const ChatWithUs = styled.button`
  color: ${(props) => (props.pricing ? '#7DFFEF' : '#0a4ed6')};
  border: none;
  background-color: transparent;
  padding: 0;
  font-weight: 800;
  font-size: 20px;
  margin-top: 16px;
  &:hover {
    text-decoration: underline;
  }
  ${media.tablet} {
    display: block;
    margin: 24px auto 0;
  }
  ${media.mobile} {
    margin: 24px 0 0;
  }
`;

const ContactWithUs = ({ mail, pricing }) => {
  const openChat = (e) => {
    e.preventDefault();
    /*eslint-disable */
    Intercom('show');
    /* eslint-enable */
  };
  return (
    <Adnotations pricing={pricing}>
      <p>Contact us:</p>
      <a href={`mailto:${mail || 'hello@livesession.io'}?subject=Question`}>
        {mail || 'hello@livesession.io'}
      </a>
      {pricing ? (
        <ChatWithUs as={Link} to="/demo/" pricing={pricing}>
          Get a demo
        </ChatWithUs>
      ) : (
        <ChatWithUs pricing={pricing} onClick={(e) => openChat(e)}>
          Chat with us
        </ChatWithUs>
      )}
    </Adnotations>
  );
};

export default ContactWithUs;

ContactWithUs.propTypes = {
  mail: PropTypes.string,
  pricing: PropTypes.bool,
};

ContactWithUs.defaultProps = {
  mail: null,
  pricing: false,
};
