import Layout from 'layout';
import Link from 'components/ui/link';
import iso27001 from 'img/compliance/iso-27001_2x.png';
import soc1 from 'img/compliance/soc-one_2x.png';
import privacyicon from 'img/help/helppage/privacyicon.svg';
import { Container } from 'styles/security';

const Jobs = () => (
  <Layout
    metaTitle="Security"
    canonical="/security/"
    metaDescription="Keeping LiveSession secure is our priority. Read more about security in our session replay software."
  >
    <section>
      <div className="container">
        <div className="row">
          <Container className="col-lg-8 col-md-8 text-left p-4 m-auto">
            <div
              style={{
                margin: '1rem 0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={privacyicon} style={{ width: '60px' }} title="Security" alt="shield icon" />
            </div>
            <h1 className="mb-5 text-center">Security at LiveSession</h1>
            <p>
              LiveSession provides encryption, security, and stability for all of the customers’
              data. We always handle the data only in a scope permitted by the relevant regulation
              and by our customers.
            </p>

            <h3>Infrastructure and data security</h3>
            <h4>Infrastructure</h4>
            <div>
              <p>
                LiveSession is using Google Cloud Platform (GCP) as an infrastructure provider,
                which is ISO/IEC 27001 and S0C1 certified. You can read more about GCP compliance{' '}
                <Link
                  href="https://cloud.google.com/security/compliance/"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  here
                </Link>
                .
              </p>
              <div className="mt-4">
                <img
                  style={{ maxHeight: '100px' }}
                  src={iso27001}
                  alt="iso27001"
                  title="ISO/IEC 27001"
                />
                <img
                  style={{ maxHeight: '100px', marginLeft: 40 }}
                  src={soc1}
                  alt="soc1"
                  title="SOC1"
                />
              </div>
            </div>

            <h4>Data location</h4>
            <p>The data is stored in the United States (Iowa) datacenter on GCP infrastructure. </p>

            <h4>Backups and monitoring</h4>
            <p>
              We ship logs to Google Stackdriver for analysis and use Google Storage as a storage
              for backups. We use automatic, daily incremental backups encrypted with cryptographic
              keys to assure security and safety.
            </p>

            <h4>Stability</h4>
            <p>
              Our technical team is constantly monitoring LiveSession systems’ efficacy to catch all
              performance, availability and integrity issues using internal and third-party systems
              to provide the best experience for our customers. All incidents in the last 60 days
              are listed on our{' '}
              <a
                href="https://livesession.statuspal.io/"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                status page
              </a>
              .
              <br />
              <br />
              We always ensure our development efforts follow industry-standard guidelines/best
              practices.
            </p>

            <h4>Network access</h4>
            <p>
              Only authorized engineers have access to the infrastructure. Specific private keys are
              required for individual servers, and keys are stored in a secure and encrypted
              location. We use control lists (ACLs) that prevent unauthorized requests getting to
              our internal network.
            </p>
            <h4>Physical security</h4>
            <p>
              Since we use GCP, LiveSession team members do not have physical access to Google data
              centers, servers, network equipment, or storage. Google data centers feature a layered
              security model, including extensive safeguards such as:
            </p>
            <ul>
              <li>Custom-designed electronic access cards</li>
              <li>Alarms</li>
              <li>Vehicle access barriers</li>
              <li>Perimeter fencing</li>
              <li>Metal detectors</li>
              <li>Biometrics</li>
            </ul>
            <p>
              According to the{' '}
              <a
                href="https://cloud.google.com/security/whitepaper"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                Google Security Whitepaper
              </a>
              : “The data center floor features laser beam intrusion detection. Data centers are
              monitored 24/7 by high-resolution interior and exterior cameras that can detect and
              track intruders. Access logs, activity records, and camera footage are reviewed in
              case an incident occurs. Data centers are also routinely patrolled by professional
              security guards who have undergone rigorous background checks and training.”
            </p>
            <h3>Application security</h3>
            <h4>Encryption</h4>
            <p>
              All data sent to or from LiveSession is encrypted in transit using 256 bit encryption.
              Our API and application endpoints are TLS/SSL only. We also encrypt data at rest using
              an AES-256 encryption algorithm.
            </p>

            <h4>Permissions</h4>
            <p>
              We enable roles levels within the app to be set for organization teammates. Different
              roles allow access to billing, invoices, inviting account members or exporting data.{' '}
            </p>

            <h4>Single Sign-on</h4>
            <p>
              LiveSession currently offers external Single Sign-on (SSO) via Google Apps for Work.{' '}
            </p>

            <h4>Credentials storage</h4>
            <p>
              All passwords are hashed with a bcrypt encryption algorithm and stored in a secure
              way.{' '}
            </p>
            <h3>Customer data security </h3>
            <h4>End user security</h4>
            <p>
              LiveSession protects user privacy by default. Sensitive data in forms (e.g. passwords
              and credit card details) is always anonymized in the recordings. It’s possible to
              anonymize static content as well, such as surnames and other personal information.
              Sensitive images (e.g. user personal photos) can be masked to provide a fully
              anonymized experience. Also, we don’t store or display the IP addresses of recorded
              users.{' '}
            </p>

            <h4>Data retention</h4>
            <p>
              LiveSession retains collected data based on the chosen plan&apos;s retention. We offer
              30, 90, 180 or 365 days of retention. The data is automatically deleted after it
              passes the retention time.{' '}
            </p>

            <h4>Third parties</h4>
            <p>
              We believe in our responsibility to protect privacy and security. We will never sell
              or share any of our customer’s data to third parties.
            </p>
            <h3>Working security</h3>
            <h4>Office security</h4>
            <p>
              The company&apos;s headquarters is equipped with an alarm, burglar-proof roller
              blinds. Documents and data carriers are stored in a locked room and cabinets.
            </p>

            <h4>Access restrictions</h4>
            <p>
              Access to our internal customers management system is restricted to our staff members
              with Google SSO authorization with 2FA enabled. Staff members have access only to data
              related to their projects and responsibilities. The offboarding process includes
              access revocation to all of our systems.{' '}
            </p>

            <h4>Confidentiality</h4>
            <p>All employee contracts include a confidentiality agreement.</p>
            <h4>Policies</h4>
            <p>
              LiveSession has a comprehensive set of security policies covering a range of topics.
              These policies are shared with all employees in our internal knowledge base.
            </p>
            <h3>Other</h3>
            <h4>PCI Compliance</h4>
            <p>
              LiveSession uses{' '}
              <a
                href="https://www.braintreepayments.com/"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                Braintree
              </a>{' '}
              (a PayPal company) as a payment gateway. Details about their security setup and PCI
              compliance can be found at Braintree’s{' '}
              <a
                href="http://braintreepayments.com/features/data-security"
                rel="noopener noreferrer nofollow"
                target="_blank"
              >
                security page
              </a>
              .
            </p>

            <h4>Security Self Assessment Questionnaire (CAIQ - Lite)</h4>
            <p>
              We use the Consensus Assessments Initiative Questionnaire Lite (
              <a
                href="https://cloudsecurityalliance.org/star/caiq-lite/"
                rel="noopener noreferrer nofollow"
                target="_blank"
              >
                CAIQ-Lite
              </a>
              ) from the Cloud Security Alliance to provide security control transparency. Please
              reach our support to obtain the document.{' '}
            </p>
          </Container>
        </div>
      </div>
    </section>
  </Layout>
);

export default Jobs;
