import React from 'react';
import Layout from 'layout';
import TrustedBy from 'components/ui/trustedBy';
import styled from 'styled-components';
import SignupAndDemo from 'components/ui/signupAndDemo';
import { media } from 'theme/breakpoints';
import UpHeading from '../components/newCustomers/upHeading';
import Testimonial from '../components/index/testimonial';

const HeroWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding-top: 168px;
  color: #000;
  ${media.mobile} {
    padding-top: 120px;
  }
  h1 {
    font-weight: 900;
    text-align: center;
    margin-bottom: 0;
  }
  p {
    text-align: center;
    ::after {
      content: '';
      color: #000;
    }
  }
  .headerContent {
    padding-top: 24px;
    padding-bottom: 48px;
    .analizeText {
      max-width: 650px;
      margin-bottom: 0;
      ${media.desktop} {
        width: 100%;
      }
    }
    p {
      margin: 0 auto;
      display: block;
      text-align: center;
      font-size: 22px;
      margin-bottom: 24px;
      line-height: 32px;
      max-width: 960px;

      ${media.desktop} {
        display: inline;
        font-size: 18px;
        line-height: 26px;
      }
    }
  }
  form {
    justify-content: center;
  }
  div {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  button {
    background-color: #f8d047;
    color: black;
    :hover {
      background-color: #f5f3f5;
    }
  }
`;

const BackgroundContainer = styled.div`
  width: 100%;
  background-color: #f5f5f5;
`;
const WaveWrapper = styled.div`
  width: 100%;
  background-color: #f5f5f5;
  height: calc(643px / 2 + 80px);
  ${media.desktopWide} {
    height: calc(537px / 2 + 80px);
  }
  ${media.desktop} {
    height: calc(399px / 2 + 80px);
  }
  ${media.tablet} {
    height: calc(290px / 2 + 40px);
  }
  ${media.mobile} {
    height: calc(196px / 2 + 40px);
  }
`;
const VideoWrapper = styled.div`
  margin: 0 auto;
  max-width: 1110px;
  text-align: center;
  position: relative;
  margin-top: calc(-642px / 2);
  ${media.desktopWide} {
    margin-top: calc(-537px / 2);
  }
  ${media.desktop} {
    margin-top: calc(-399px / 2);
  }
  ${media.tablet} {
    margin-top: calc(-290px / 2);
  }
  ${media.mobile} {
    margin-top: calc(-196px / 2);
  }
  .replayText {
    max-width: 550px;
    margin: 0 auto;
  }
  h2 {
    padding: 80px 0 42px;
    ${media.tablet} {
      padding: 42px 0 24px;
    }
  }
  p {
    font-weight: 700;
  }
  img {
    max-width: 1110px;
    ${media.mobile} {
      width: 340px;
    }
  }
`;

const Producttour = () => (
  <Layout
    canonical="/product-tour/"
    metaTitle="Product Tour | Learn how to use LiveSession"
    metaDescription="Identify your website’s most clickable elements with LiveSession Clickmaps. Use heat maps to spot the hottest sections."
  >
    <BackgroundContainer>
      <HeroWrapper className="container">
        <UpHeading />
        <h1 className="line"> Explore the possibilities of LiveSession</h1>
        <div className="headerContent">
          <p className="analizeText">
            See how to build a great product experience using data and insights from LiveSession.
          </p>
        </div>
        <SignupAndDemo />
      </HeroWrapper>
      <WaveWrapper className="waveGrayBottom" />
    </BackgroundContainer>
    <div className="container">
      <VideoWrapper>
        <div
          style={{
            position: 'relative',
            paddingBottom: 'calc(55.55555555555556% + 39px)',
            height: 0,
            border: '2px solid #000',
            borderRadius: '8px',
          }}
        >
          <iframe
            src="https://demo.arcade.software/0UhltaU4aaCIDtK3yvj6?embed&show_copy_link=true"
            loading="lazy"
            webkitallowfullscreen
            mozallowfullscreen
            allowFullScreen
            style={{
              position: 'absolute',
              border: 'none',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              colorScheme: 'light',
            }}
            title="LiveSession"
          />
        </div>
      </VideoWrapper>
    </div>
    <div className="container p72">
      <TrustedBy saas style={{ padding: 0 }} />
    </div>
    <div className="mpclear p120">
      <Testimonial />
    </div>
  </Layout>
);

export default Producttour;
