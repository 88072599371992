import { AnchorLink } from 'gatsby-plugin-anchor-links';
import Link from 'components/ui/link';
import optimizationImg from 'img/solutions/optimizationImg.svg';
import debuggingImg from 'img/solutions/debuggingImg.svg';
import marketingImg from 'img/solutions/marketingImg.svg';
import trackingImg from 'img/solutions/trackingImg.svg';
import testingImg from 'img/solutions/testingImg.svg';
import supportImg from 'img/solutions/supportImg.svg';
import optimization from 'img/solutions/icons/optimization.svg';
import tracking from 'img/solutions/icons/tracking.svg';
import testing from 'img/solutions/icons/testing.svg';
import debugging from 'img/solutions/icons/debugging.svg';
import support from 'img/solutions/icons/support.svg';
import marketing from 'img/solutions/icons/marketing.svg';
import intercomlogo from 'img/solutions/icons/intercomlogo.svg';

export const data = [
  {
    id: 'conversion-optimization',
    image: optimizationImg,
    icon: optimization,
    title: 'Conversion rate optimization',
    desc: (
      <>
        Put your trust in qualitative data and grow your conversions. See why something works (or
        why it doesn’t) with session replays. Draw <strong>valuable insights</strong> that will help
        you come up with more relevant hypotheses.
        <br />
        <br />
        Filter session recordings and <strong>save plenty of time</strong>. This will help you focus
        on the areas that are crucial for conversion.
      </>
    ),
    banner: {
      text: 'See how RocketLink increased signups by 110% with LiveSession',
      link: {
        href: '/customers/rocketlink/',
        text: 'Read case study',
      },
    },
  },
  {
    id: 'user-engagement-tracking',
    image: trackingImg,
    icon: tracking,
    title: 'User engagement tracking',
    desc: (
      <>
        Find the most active users and the most interesting sessions thanks to our{' '}
        <strong>engagement score</strong>. Use <strong>custom properties</strong> to see how the
        engagement varies depending on different factors.
        <br />
        <br />
        We generate engagement scores automatically, based on in-app events, session duration, and
        other aspects. No need to estimate: the answer is already here.
      </>
    ),
    link: {
      text: 'Boost engagement',
      href: 'boost-engagement',
    },
  },
  {
    id: 'usability-testing',
    image: testingImg,
    icon: testing,
    title: 'Usability testing',
    desc: (
      <>
        Use session recordings to get started with usability testing. See how exactly the users
        interact with your website. It’s just like you’re sitting next to them.
        <br />
        <br />
        <strong>Test your ideas remotely</strong>. Watch results anytime. Get more insights with
        qualitative data.
      </>
    ),
    link: {
      text: 'Get to know your users',
      href: 'get-to-know-your-users',
    },
  },
  {
    id: 'application-debugging',
    image: debuggingImg,
    icon: debugging,
    title: 'Application debugging',
    desc: (
      <>
        Find errors faster thanks to session replays. Detect JavaScript errors with{' '}
        <Link tag={AnchorLink} to="/features/#rage-and-error-clicks">
          <strong>error clicks</strong>
        </Link>{' '}
        and points of frustration with rage clicks. Add support tier data to recordings and
        cooperate with your tech support team.
        <br />
        <br />
        With LiveSession, debugging is faster and less frustrating. Thanks to session recordings,
        you can see what exactly caused the error.
      </>
    ),
    link: {
      text: 'Remove bugs faster',
      href: 'remove-bugs-faster',
    },
  },
  {
    id: 'customer-support',
    image: supportImg,
    icon: support,
    title: 'Customer support',
    desc: (
      <>
        LiveSession will make the work of your technical support team much easier. You can{' '}
        <strong>assign different support tiers</strong> to your recordings, from frequently asked
        questions to more advanced issues.
      </>
    ),
    banner: {
      text: 'Handle support even faster. Play the session straight from Intercom and see what went wrong.',
      img: intercomlogo,
      link: {
        href: '/integrations/intercom/',
        text: 'See the Intercom integration',
      },
    },
  },
  {
    id: 'performance-marketing',
    image: marketingImg,
    icon: marketing,
    title: 'Performance marketing',
    desc: (
      <>
        Analyze <strong>marketing funnels</strong> with qualitative data. Filter sessions based on
        different variables, such as <strong>UTM tags</strong>. Track specific events according to
        your needs.
        <br />
        <br />
        Session recordings are a goldmine of information. Find what works best and how you can
        improve your marketing strategy. It’s a brilliant tool for campaign validation.
      </>
    ),
    link: {
      text: 'Boost your marketing',
      href: 'boost-your-marketing',
    },
  },
];
