import withUsabilityTestingTemplate from 'hoc/content-cluster';
import { StaticImage } from 'gatsby-plugin-image';
import Link from 'components/ui/link';
import arrow from 'img/ui/arrow_white.svg';

const UserExperienceDesign = () => (
  <>
    <h2>What is User Experience Design?</h2>

    <p>
      In the most simple terms,{' '}
      <Link
        href="https://medium.com/@sagarajkt/top-tips-to-improve-user-experience-design-7345948bc12"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        UX design
      </Link>{' '}
      is the process of increasing user satisfaction and making sure that the services you provide
      to your customers genuinely deliver value. This is often measured through metrics like mobile
      app use, the number of returning visitors, conversion rates, and others.
    </p>
    <p>
      User Experience Designer & Information Architecture pioneer Peter Morville does a great job of
      explaining UX design by breaking it down into 7 key areas, known as the{' '}
      <Link
        href="http://semanticstudios.com/publications/semantics/000029.php"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        UX Honeycomb
      </Link>
      .
    </p>
    <StaticImage
      src="../../img/user-experience/user-experience-design/semanticstudios.png"
      alt="7 key areas"
      title="7 key areas - Semantic Studios"
    />
    <p>
      Source:{' '}
      <Link
        href="http://semanticstudios.com/user_experience_design/"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Semantic Studios
      </Link>
    </p>
    <p>
      According to this theory, the quality of user experience design lies in the following
      qualities: usefulness, usability, desirability, accessibility, findability, credibility, and
      value (for the business and its sponsors).
    </p>
    <p>
      That being said, let’s now see how you can improve the{' '}
      <Link href="/user-experience/" target="_blank" rel="noopener noreferrer">
        digital experience
      </Link>{' '}
      for your service.
    </p>
    <h2>UX Best Practices - How to Create a Good UX Design?</h2>

    <p>
      An effective UX is all about catering your design process to your audience so that they feel
      confident and comfortable while achieving their end goal. What’s more, if people find your
      user interface design easy and satisfying to use, they will be less likely to leave you for a
      competitor.
    </p>
    <p>
      Below we share several top UX design best practices – whether you’re an interaction designer
      or a product manager, make sure to cross them off your checklist.
    </p>
    <h3>Understand who your audience is</h3>

    <p>
      Any UX design program should start with gaining an intimate understanding of who your current
      and potential users are. There are several methods for learning about their needs and
      expectations including by running surveys, talking to them directly, and collecting insights
      from analytics software to identify their mental models. You should always make design
      decisions by looking at your product through the eyes of your users; ask yourself, “what are
      they trying to get out of using our product?”
    </p>
    <p>Here are a few questions you should aim to answer in the discovery phase of your product:</p>
    <ul>
      <li>What problems are users looking to solve?</li>
      <li>How can this product address their goals and needs?</li>
      <li>What makes people choose this product over competing solutions on the market?</li>
    </ul>
    <h3>Seek simplicity and familiarity</h3>

    <p>
      Have you ever visited a website with completely different graphic design principles than what
      you’re used to? Perhaps it doesn’t have the typical hamburger menu, or there aren’t any tabs
      at the top. Odds are that you got frustrated, and you might have even left the site
      altogether.
    </p>
    <p>
      Your audience doesn’t want to relearn how to use an app or a website, so it’s your job to not
      completely reinvent the wheel when it comes to user experience design. Rely on known
      behavioral patterns to design solutions that your users are familiar with. Simplicity and
      familiarity in your designs and core features will help your users achieve their goals faster
      and more efficiently.
    </p>

    <h3>Observe how your users interact with your design</h3>

    <p>
      You can’t know what to alter or improve unless you know what the user experience is truly like
      by user testing. Conduct session recordings or in-person usability testing to find out about
      the kind of physical interaction a target user has with the design that you’ve created.
    </p>
    <p>
      What do they like, what do they struggle with, and how can you make elements of your design
      more intuitive (and thus create a faster, more efficient experience)? If you want to observe
      how your users interact with your website or service, sign up to{' '}
      <Link href="https://livesession.io/signup/" target="_blank" rel="noopener noreferrer">
        LiveSession
      </Link>{' '}
      for free.
    </p>

    <h3>Make sure the entire user flow is consistent</h3>

    <p>
      A user flow shows all of the steps that someone has to take to achieve their goals. When you
      consider how a user will move throughout your website or app, you should ask yourself:
    </p>
    <ul>
      <li>Do they achieve their goal in the end?</li>

      <li>Do they encounter any interruptions along the way?</li>

      <li>Are there any steps that could be simplified or omitted altogether?</li>
    </ul>
    <p>
      <Link href="/user-experience/user-journey-map/" target="_blank" rel="noopener noreferrer">
        User journey mapping
      </Link>{' '}
      is one way that you can narrow down any underperforming areas in user flow and address it in
      your finished design, eliminating any common misconceptions you might have had about certain
      visual elements by making assumptions rather than analyzing data.
    </p>
    <StaticImage
      src="../../img/user-experience/user-experience-design/uxdesign.png"
      alt="User Flow Design"
      title="User journey mapping"
    />
    <p>
      Source:{' '}
      <Link
        href="https://uxdesign.cc/when-to-use-user-flows-guide-8b26ca9aa36a"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        UXdesign
      </Link>
    </p>
    <h3>Prioritize readability (and accessibility)</h3>

    <p>
      The text you display in your product needs to be legible and promote maximum readability,
      which means how easy it is for the reader to understand what’s written. While you may have
      text that is grammatically correct, that doesn’t necessarily mean it’s easily readable. Aim at
      making it easy to understand for a wide range of human users by using familiar language.
    </p>
    <p>
      Depending on whom your product is targeted at, you should also consider accessible design
      ideas that extend beyond the screen – for instance, by creating a voice user interface (VUI)
      for the visually impaired or other such solutions for people with disabilities. If you wish to
      explore this further, we recommend taking a look at the accessibility guidelines described on
      the{' '}
      <Link
        href="https://www.interaction-design.org/literature/topics/accessibility#:~:text=Both%20overlap%20and%20are%20vital,also%20key%20distinctions%20between%20them.&text=Accessibility%2C%20on%20the%20other%20hand,e.g.%2C%20using%20assistive%20devices)."
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Interaction Design Foundation
      </Link>{' '}
      site.
    </p>
    <h2>4 User Experience Design Examples</h2>

    <p>
      The biggest companies often lead the way in positive user experience design. Just think about
      it – if they are able to dominate the market and become global favorites among users, there
      must be something to their design thinking and business strategy.
    </p>
    <p>
      That being said, UX design doesn’t only apply to big companies or brands. It just means that
      they’ve done a phenomenal job of putting emphasis on user experience design for their
      products, and it’s proven successful. If you’re currently looking for ways to better your
      company’s position and achieve your business goals, good UX design can help you connect with
      your customers and foster brand loyalty.
    </p>
    <p>Below, we share 4 of the best UX design industry examples. </p>
    <h3>1. Google Search Engine</h3>
    <p>
      Google just had its 23<sup>rd</sup>birthday on September 27th, 2021. Today, it is by far{' '}
      <Link
        href="https://userguiding.com/blog/good-ux-examples/"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        the world’s largest search engine
      </Link>{' '}
      with over 3.5 bn searches made daily. If there’s one secret to Google’s search engine success,
      it’s UX design. All you have to do is type an inquiry into the search bar and hit ‘Enter’.
      Before you know it, a wide range of hits matching your inquiry pop up.
    </p>
    <StaticImage
      src="../../img/user-experience/user-experience-design/hackermoon.png"
      alt="Google example - autocomplete"
      title="Autocomplete"
    />
    <p>
      Source:{' '}
      <Link
        href="https://hackernoon.com/design-auto-complete-system-in-python-8fab1470cd92"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Hackernoon{' '}
      </Link>
    </p>
    <p>
      As the simplest and fastest search tool ever created, customers know exactly what to expect.
      In the past 20 years, Google’s home page has varied little in its design, only getting simpler
      if anything. Thanks to removing unnecessary links, even new users won’t get confused about
      what to do. Click, type, and search. It’s about as easy and familiar as it gets.
    </p>
    <h3>2. Facebook</h3>

    <p>
      Facebook was launched on February 2, 2004 from a Harvard dorm by Mark Zuckerberg. Today, there
      are over{' '}
      <Link
        href="https://www.statista.com/statistics/264810/number-of-monthly-active-facebook-users-worldwide/"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        2.7 billion
      </Link>{' '}
      monthly active users. Learning how to create a good UX design for all of these users has been
      challenging for Facebook, but they’ve risen to the occasion.
    </p>
    <p>
      Over the years, they’ve added new features about users’ locations, languages, interests,
      activities, potential friends, groups, and more to help them connect with their network. As
      they’ve added more features, they’ve improved their customer experience design process and the
      value they offer as a social media network.
    </p>
    <StaticImage
      src="../../img/user-experience/user-experience-design/techcrunch.png"
      alt="Facebook example - navigation"
      title="Navigation"
    />
    <p>
      Source:{' '}
      <Link
        href="https://consent.yahoo.com/v2/collectConsent?sessionId=3_cc-session_8749294b-996c-49c2-9a07-e0b0e796a0d6"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Techcrunch
      </Link>
    </p>
    <p>
      Every few years, Facebook’s dedicated team of industrial designers also updates its interface
      to ensure that it’s serving its audience effectively.
    </p>
    <h3>3. PayPal</h3>

    <p>
      PayPal was officially established in December 1998 as Confinity, which developed security
      software for handheld devices. However, with little success in that field, it switched its
      focus to online wallets as digital products. Its first version was an electronic payments
      system launched in 1999. Today, PayPal is the most trusted and secure online payment method.
    </p>
    <StaticImage
      src="../../img/user-experience/user-experience-design/opayo.png"
      alt="PayPal example - summary"
      title="Summary"
    />
    <p>
      Source:{' '}
      <Link
        href="https://www.opayo.co.uk/support/12/36/linking-paypal-to-your-live-account"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Opayo
      </Link>
    </p>
    <p>
      However, the platform was complex before they{' '}
      <Link
        href="http://www.nickyvandewater.com/paypal-web-redesign"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        released a redesign
      </Link>{' '}
      of both their websites and optimized them for mobile in May 2014. The redesign launched in 24
      countries to 154 million customers and sought to cut down on the number of words and links
      that overcomplicated the product experience for its audience. Their main priority was to
      reduce the time that users needed to spend on their website. They also incorporated a
      mobile-first methodology so that their site could be used on smartphones as well.
    </p>
    <h3>4. Starbucks</h3>

    <p>
      Starbucks is another user experience design example worth looking at. It has come a long way
      since being founded in 1971. On September 23, 2009, Starbucks launched its app, which is now
      the most regularly used loyalty rewards app amongst major restaurant chains. Nearly half of
      all Starbucks drinkers (
      <Link
        href="https://www.retailcustomerexperience.com/news/starbucks-is-top-mobile-loyalty-app-with-consumers-reveals-survey/"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        48%
      </Link>
      ) use the mobile app, and its digital engagement is largely credited for its success.
    </p>
    <p>
      Part of this is a user-friendly mobile experience and personalization. The app design makes
      online ordering easy by recording purchase histories and patterns. By narrowing down the menu
      items to a shortlist of the physical products a customer has previously enjoyed, it makes the
      checkout process a more streamlined experience. If you’re a Starbucks regular, it’s unlikely
      that you look at the full menu every single time you order but rather choose one of three or
      four drinks that you’ve had previously. The Starbucks app also highlights new drinks in the
      “Featured” tab so that customers won’t miss out on anything they may want to try, even if
      they’re creatures of habit.
    </p>
    <StaticImage
      src="../../img/user-experience/user-experience-design/starbucks.png"
      alt="Starbucks example - app"
      title="Easy to use"
    />
    <p>
      Source:{' '}
      <Link
        href="https://www.starbucks.com/coffeehouse/mobile-apps"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Starbucks
      </Link>
    </p>
    <p>
      After selecting a drink, the user will see suggested food items that may pair well, plus
      suggested locations for picking up the order. Starbucks’ geolocation feature highlights the
      closest physical premises to the user. All the while, users will be gaining rewards points
      (“stars”) for their purchases and thus earning free drinks or food items periodically.
    </p>
    <p>
      The Starbucks app is quick and easy to use, which is why it’s been so successful with its
      customer base.
    </p>
    <h2>Final thoughts</h2>

    <p>
      Making good user experience design decisions helps to increase usability and customer
      satisfaction. You might have a great product, but if its visual design and core features
      aren’t laid out in a user-centered design then your audience won’t be able to use it
      effectively.
    </p>
    <p>
      Companies like PayPal sought to redesign their customer experience by seeking simplicity,
      which ultimately boosted their presence on mobile devices. Starbucks, meanwhile, took a look
      at their audience and implemented personalization features to improve its app’s usability test
      results.
    </p>
    <p>
      Ultimately, when you focus on user experience design, you increase your company’s potential
      and give yourself the opportunity to provide greater value to your customers.
    </p>
    <div className="next-chapter">
      <Link href="/user-experience/user-journey-map/">
        <div>
          <p>Chapter 4</p>
          <p>How to develop a user journey map in 6 simple steps</p>
        </div>
        <img src={arrow} alt="arrow" title="white arrow" />
      </Link>
    </div>
  </>
);

export default withUsabilityTestingTemplate(UserExperienceDesign);
