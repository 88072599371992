import { media } from 'theme/breakpoints';
import styled from 'styled-components';

export const ContainerSlider = styled.div`
  position: relative;
  width: 2500px;
  margin: 0 auto;
  ::after {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    width: 2000px;
    left: -2100px;
    right: 0;
    background-color: #171717;
    box-shadow: 70px 6px 44px 25px rgba(23, 23, 23, 1);
    z-index: 2;
  }
  ::before {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    width: 2000px;
    right: -2100px;
    background-color: #171717;
    z-index: 3;
    box-shadow: -70px 6px 44px 25px rgba(23, 23, 23, 1);
  }
`;

export const Wrapper = styled.div`
  display: flex;

  ${media.tablet} {
    animation: none;
    width: 100vw;
    overflow-x: scroll;
  }
  :hover {
    .slidWrapper1,
    .slidWrapper2 {
      animation-play-state: paused;
    }
  }
  .slidWrapper1 {
    z-index: 0;
    display: flex;
    animation: left-to-right 120s linear infinite;
    ${media.tablet} {
      animation: none;
    }
  }
  .slidWrapper2 {
    background-color: #171717;
    border-right: 1px solid #171717;
    z-index: 1;
    display: flex;
    animation: left 120s linear infinite;
    ${media.tablet} {
      display: none;
      animation: none;
    }
  }

  @keyframes left-to-right {
    0% {
      transform: translateX(0);
    }

    50% {
      transform: translateX(100%);
    }
    50.01% {
      transform: translateX(-100%);
    }
    75% {
      transform: translateX();
    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes left {
    0% {
      transform: translateX(-5823px);
    }
    100% {
      transform: translateX(0);
    }
  }
  :hover {
    animation-play-state: paused;
  }
`;

export const CardWrapper = styled.div`
  position: sticky;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 350px;
  width: 376px;
  border-radius: 16px;
  background-color: #333;
  color: white;
  padding: 32px 22px;
  font-weight: 500;
  margin: 20px;
  ${media.tablet} {
    width: 320px;
  }
  p {
    font-size: 18px;
    line-height: 26px;
    ${media.tablet} {
      font-size: 16px;
    }
  }
`;
export const UserCompetition = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  p {
    line-height: 26px;
    font-size: 16px;
    margin-bottom: 0;
    text-align: left;
    font-weight: 400;
    ${media.tablet} {
      font-size: 14px;
    }
  }
  .user {
    font-weight: 700;
    font-size: 18px;
    ${media.tablet} {
      font-size: 16px;
    }
  }
`;

export const ImgWrapper = styled.div`
  height: 40px;
  width: 40px;
  margin-right: 16px;
  img {
    height: 40px;
    width: 40px;
  }
`;
