import { media } from 'theme/breakpoints';
import styled from 'styled-components';

export const BackgroundContainer = styled.div`
  position: relative;
  width: 100%;
  background: linear-gradient(180deg, #f5f5f5 0%, #f5f5f5 44.51%, rgba(245, 245, 245, 0) 90.19%);
`;

export const SectionWrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 90px 15px 0;
  ${media.tablet} {
    padding: 48px 15px 0 15px;
  }
  ${media.tablet} {
    padding-top: 48px;
  }
  ${media.mobile} {
    padding-top: 32px;
  }

  .line {
    text-align: center;
  }
  .question {
    font-size: 32px;
    ${media.desktop} {
      font-weight: 800;
      font-size: 24px;
    }
  }
  span {
    z-index: 1;
  }
  ul {
    height: 390px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0;
    width: 570px;
    padding-left: 20px;
    ${media.desktop} {
      width: 100%;
      padding-left: 0;
      height: auto;
    }
  }

  li {
    width: auto;
    display: flex;
    font-weight: 600;
    font-size: 20px;
    line-height: 34px;
    padding: 8px 16px;
    border-radius: 8px;
    text-align: left;
    :before {
      content: '•';
      color: black;
      margin-right: 20px;
    }
  }
`;
export const ContentWrapper = styled.div`
  padding-top: 68px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  ${media.desktop} {
    flex-direction: column;
  }
  .answer {
    width: 300px;
    display: flex;
    flex-direction: column;
    ${media.desktop} {
      width: 100%;
      text-align: center;
      padding-bottom: 32px;
    }
    a {
      ${media.tablet} {
        display: none;
      }
    }
    a:nth-child(2) {
      padding: 24px 0 16px;
    }
  }
  img {
    width: 274px;
    ${media.desktop} {
      display: none;
    }
  }
  .arrowBottom {
    display: none;
    ${media.desktop} {
      display: block;
      width: 130px;
      margin-bottom: 40px;
    }
    ${media.tablet} {
      width: 72px;
      margin-bottom: 24px;
    }
  }
  ul {
    ${media.desktop} {
      text-align: center;
      height: auto;
      li {
        margin-bottom: 32px;
        font-size: 22px;
        padding: 0;
        width: 600;
      }
    }
    ${media.tablet} {
      li {
        margin-bottom: 8px;
        font-size: 16px;
      }
    }

    ${media.tablet} {
      width: 100%;
      padding: 0;
    }
  }
`;
