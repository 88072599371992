import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import arrow from 'img/ui/arrow_black.svg';
import { useLocation } from '@reach/router';
import * as Styled from 'hoc/content-cluster/styled';

const Container = ({ children, title }) => {
  const location = useLocation();
  return (
    <>
      <Styled.Breadcrumbs>
        <p>
          <Link href="/customers/">Customers</Link>
        </p>
        <img src={arrow} alt="arrow" title="Arrow" />
        <p>Success stories</p>
        <img src={arrow} alt="arrow" title="Arrow" />
        <p>
          <Link href={location.pathname}>
            <strong>{title}</strong>
          </Link>
        </p>
      </Styled.Breadcrumbs>
      {children}
    </>
  );
};

export default Container;

Container.propTypes = {
  children: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
};
