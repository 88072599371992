import PropTypes from 'prop-types';
import imgFB from 'img/footer/facebook.svg';
import imgLI from 'img/footer/linkedin.svg';
import imgTwitter from 'img/footer/twitter.svg';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';
import { useMetadata } from 'hooks/useMetadata';
import * as Styled from './styled';

const ShareBar = ({ pathname }) => {
  const site = useMetadata();
  const url = `${site.siteMetadata.siteUrl}${pathname}`;
  return (
    <Styled.ShareBar>
      <li>
        <FacebookShareButton url={url}>
          <img src={imgFB} alt="facebbok" />
        </FacebookShareButton>
      </li>
      <li>
        <TwitterShareButton url={url}>
          <img src={imgTwitter} alt="twitter" />
        </TwitterShareButton>
      </li>
      <li>
        <LinkedinShareButton url={url}>
          <img src={imgLI} alt="linkedin" />
        </LinkedinShareButton>
      </li>
    </Styled.ShareBar>
  );
};

export default ShareBar;

ShareBar.propTypes = {
  pathname: PropTypes.string.isRequired,
};
