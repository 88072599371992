import { data } from 'data/solutions/cards';
import Card from '../card';
import * as Styled from './styled';

const SolutionCards = () => (
  <Styled.GridContainer>
    {data.map((card) => (
      <Card {...card} key={card.title} />
    ))}
  </Styled.GridContainer>
);

export default SolutionCards;
