import withUsabilityTestingTemplate from 'hoc/content-cluster';
import { StaticImage } from 'gatsby-plugin-image';
import Link from 'components/ui/link';
import { useSourceParams } from 'hooks/useSourceParams';

const UXResearch = () => {
  const paragraph = useSourceParams('stage3');
  return (
    <>
      <h2>Why Does UX Research Matter?</h2>
      <p>
        The product design process can be a challenging endeavor. However, extensive user experience
        research makes it easier by providing the necessary data required at each stage of the
        product design cycle. This allows you to validate your hypotheses and analyze your product
        against a backdrop of similar solutions on the market. Ux research also guides you through
        understanding end-user behaviors, as well as their expectations, goals, and challenges.
      </p>

      <p>
        As aptly noticed by{' '}
        <Link
          href="https://www.toptal.com/designers/user-research/guide-to-ux-research-methods"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Toptal
        </Link>
        , “user experience research is one of the most misunderstood yet critical steps in UX
        design. Sometimes treated as an afterthought or an unaffordable luxury, UX research and user
        testing should inform every design decision.”
      </p>
      <p>
        Now that we know the gist of it, let’s explore the different types of UX research in the
        following section.{' '}
      </p>
      <h2>Types of UX Research</h2>
      <p>
        UX research can be roughly divided into two different types – qualitative and quantitative.
        These research types gather data in contrasting ways. While qualitative studies are based on
        direct observations, quantitative studies gather numerical data – for instance, through
        online surveys.
      </p>

      <p>
        Once combined, these two types of UX research can provide a great depiction of the wants and
        needs of each prospective customer, as well as what would make them a satisfied user.
      </p>
      <h3>Quantitative research method</h3>
      <p>
        This type of UX research quantifies or gathers measurable data. It offers precise, fixed
        figures and numbers to work with. As an example, it can show you how many users purchased
        your product via an e-commerce app or the percentage of visitors who added an item to their
        wish lists. Quantitative methods give you a number or statistic for usability benchmarking.
        Also, design solution comparisons can be made to determine which version outsmarts the
        others.
      </p>
      <h3>Qualitative research method</h3>

      <p>
        Qualitative UX research discovers the reasons behind quantitative actions taken by
        end-users. As an example, this would be a suitable method to employ for exploring scenarios
        like why a user added a certain item to their wish list instead of purchasing it or why they
        bounced from your website. While quantitative research methods produce data that is fixed in
        nature, data from qualitative feedback is more open-ended and descriptive.
      </p>
      <p>
        It’s important to note that quantitative and qualitative user experience research should be
        conducted at every stage by your product managers, design team, and development team.
        Fortunately, different user research methods can aid in this process. Depending on the stage
        of the product development process, some methods might take precedence over others.
      </p>
      <h2>UX Research Methods – Which to Choose</h2>
      <p>
        According to the Nielsen Norman Group, UX research methods can be divided roughly into four{' '}
        <Link
          href="https://www.nngroup.com/articles/ux-research-cheat-sheet/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          stages
        </Link>
        : Discover, Explore, Learn, and Listen. Let’s look at each one in detail.
      </p>

      <StaticImage
        src="../../img/user-experience/ux-research/uxactivities.png"
        alt="UX activities in the product & service design cycle"
        title="Chose your reasearch method"
      />
      <h3>Stage 1 – Discover</h3>
      <p>In the ‘Discover’ stage, methods include:</p>
      <ul>
        <li>
          <strong>Field studies </strong>- includes conducting studies about the user’s natural
          environment as opposed to in a restricted environment like a lab or office.{' '}
        </li>
        <li>
          <strong>Stakeholder interviews </strong>- UX research at the discovery stage should mean
          that you involve stakeholder interviews, as they can provide detailed and valuable product
          information that will steer your product to success. Defining your goals and understanding
          the parameters, valuable insights, and assumptions that are set out before sharing designs
          with stakeholders is a crucial step.
        </li>
        <li>
          <strong>User interviews </strong>- it is pertinent to interview the potential end-users of
          your product to get into their minds and learn how they would benefit from it. These user
          interviews are normally conducted during the ideation phase or early concept development.
          The interview questions are delivered via a structured methodology covering a variety of
          topics, and are generally recorded then systematically analyzed afterwards to extract the
          main points.{' '}
        </li>
        <li>
          <strong>Competitive analysis </strong>- this is market analysis to compare and gather data
          about products and companies. To ensure that your product fills a genuine niche and will
          sell, it is very important to see what your competitors are up to. This method will bring
          to light any strengths or weaknesses of your intended products so that you can make smart
          decisions regarding your development strategy.{' '}
        </li>
      </ul>
      <p>
        <strong>When are these methods used?</strong> The ideal time for this early-stage task is
        when you wish to discover target user needs, expectations, and problems, as well as when you
        are considering developing a product and need to validate your idea.
      </p>
      <h3>Stage 2 – Explore</h3>
      <p>The methods employed in the ‘Explore’ stage include:</p>
      <ul>
        <li>
          <strong>User persona development </strong>- a user persona is like a fictional
          representation of your customer. The purpose of{' '}
          <Link
            href="https://www.interaction-design.org/literature/article/personas-why-and-how-you-should-use-them"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            persona building
          </Link>{' '}
          is to create dependable and realistic representations of your key audience segments for
          reference. Persona creation focus will build empathy with your target users, helping
          identify exactly what they need and why.{' '}
        </li>
        <li>
          <strong>Task analysis </strong>- assign actions for the participants to take during your
          research study. Tasks provide context to user research processes so that they can engage
          with the design of products as they would do in a real life scenario.{' '}
        </li>
        <li>
          <p>
            <strong>Card sorting </strong>- this is a UX research method in which participants
            organize topics into categories in a way that makes sense to them. A
            <Link
              href="https://www.usability.gov/how-to-and-tools/methods/card-sorting.html"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              {' '}
              Card sorting
            </Link>{' '}
            session can use actual cards, pieces of papers, or some kind of online card sort tool.{' '}
          </p>
          <StaticImage
            src="../../img/user-experience/ux-research/analyzing.jpg"
            alt="Card sorting"
            title="One of reaserch method - card sorting"
          />
          <p>
            Source:{' '}
            <Link
              href="https://unsplash.com/photos/ywwuOBJy60c"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              Unsplash
            </Link>
          </p>
        </li>
        <li>
          <strong>User stories </strong>- these are scenarios that enable a product designer to
          empathize with the end-user and generate ideas to better their product for users in terms
          of how it fits into their life. User stories are full of minor details like activities and
          thoughts, and they can be presented via different media.
        </li>
      </ul>
      <p>
        <strong>When are these methods used?</strong> The above are intended for exploring and
        validating prospective user paths, design rationale, and features before you invest your
        time and resources into developing the product.
      </p>
      <h3>Stage 3 – Learn</h3>
      <p>In the ‘Learn’ stage, UX research methods include:</p>
      <ul>
        <li>
          <strong>Usability testing </strong>: - a behavioral user research method. One of the
          easiest ways to start with the{' '}
          <Link
            href="https://livesession.io/usability-testing/"
            target="_blank"
            rel="noopener noreferrer"
          >
            usability testing
          </Link>{' '}
          method is to conduct session recordings, which allow you to replay and observe real
          actions performed by current users. They include mouse clicks or scrolling if you are
          looking for website usability issues, for example. Session recordings are a superb way of
          spotting major problems in your product and its functionalities. If you want to test the
          potential of user session recordings, you can try out{' '}
          <Link href={paragraph.link} target="_blank" rel="noopener noreferrer">
            LiveSession
          </Link>{' '}
          for free.
        </li>
        <li>
          <strong>Benchmark tests </strong>- such studies allow you to test the progress over time
          of a website, app, or product and where it stands in comparison to its competitors,
          earlier versions, or industry benchmarks.{' '}
        </li>
        <li>
          <strong>Accessibility tests </strong>- these allow you to check whether or not your
          software or product has a comfortable user flow for people with disabilities such as
          deafness, physical limitations, and even color blindness or age.
        </li>
      </ul>
      <h3>Stage 4 – Listen</h3>
      <p>In the fourth stage, among others, user researchers can apply the following methods:</p>
      <ul>
        <li>
          <strong>Surveys & questionnaires </strong>- this type of user research analysis can be
          carried out by one-on-one interviews, over the phone, diary studies, or via an online
          survey tool. These questionnaires use both open-ended and closed questions to collect
          valuable data, not least about user satisfaction levels. This is very useful, as it
          ensures that both quantitative and qualitative data is obtained.{' '}
        </li>
        <li>
          <strong>Bug report analysis </strong>- when you build new products, bugs are bound to
          occur. Some are relatively harmless and do not prevent task completion, but others can
          cause major interruptions and distractions. Iit is vital to eliminate those bugs before
          release as part of the product design process. Having your user design researchers create
          and analyze bug reports helps immensely in your{' '}
          <Link href="/user-experience/" target="_blank" rel="noopener noreferrer">
            user experience
          </Link>{' '}
          studies.{' '}
        </li>
        <li>
          <strong>Analyzing FAQs </strong>- frequently asked questions that a real user would ask
          can guide you as to critical areas for improvement. Carefully assessing users’ FAQS is
          imperative when trying to improve your product and its UX design scope.
        </li>
        <li>
          <strong>Analyzing search queries on-site </strong>- search logs and queries that can be
          found in{' '}
          <Link
            href="https://search.google.com/search-console/about"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            Google Search Console
          </Link>{' '}
          are often overlooked, yet they contain important information. As an example, your on-site
          user queries can easily convey what people are searching for, how they phrase it, and when
          they look for it.{' '}
        </li>
      </ul>
      <p>
        <strong>When are these methods used?</strong> For improving UX and adjusting the product to
        the changing demands of both users and the market over time.
      </p>
      <h2>Summary</h2>
      <p>
        UX research is an indispensable element of any UX process. It is applied across all stages
        of development by the product team – both in the early ideation phase and the design
        process, as well as after the initial launch. Effective UX research can be carried out
        through a variety of methods, and it helps you maximize profits and returns for your
        products and services.
      </p>
      <p>
        Last, but not least, UX research allows you to validate your hypotheses and analyze your
        product against similar solutions, as well as guiding you through understanding your
        end-users’ expectations, goals, and challenges. Follow the methods discussed in this post,
        and you’ll be sure to ace your user experience research. Good luck.
      </p>
    </>
  );
};

export default withUsabilityTestingTemplate(UXResearch);
