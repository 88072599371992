import React, { Component } from 'react';
import { media } from 'theme/breakpoints';
import PropTypes from 'prop-types';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Configure, Highlight, connectSearchBox } from 'react-instantsearch-dom';
import styled from 'styled-components';
import Autocomplete from './autocomplete';
import CustomHits from './CustomHits';

const StyledContainer = styled.div`
  position: relative;
  z-index: 20;
  .react-autosuggest__suggestions-container--open {
    max-height: 363px;
    overflow-y: auto;
    width: 488px;
    box-shadow: 0px 0px 20px #00000029;
    border-radius: 4px;
    position: absolute;
    top: 0;
    right: 50%;
    transform: translate(50%, 52px);
    z-index: 15;
  }
  ${media.tablet} {
    .react-autosuggest__suggestions-container--open {
      width: 100%;
    }
  }
  .react-autosuggest__suggestion {
    list-style: none;
    display: list-item;
  }
  .react-autosuggest__suggestions-list {
    padding: 0;
    margin: 0;
  }

  .react-autosuggest__suggestion--highlighted .styledbox {
    background-color: #f6f8f9;
  }
`;

const VirtalSearchBox = connectSearchBox(() => null);

const index = process.env.GATSBY_ALGOLIA_HELPCENTER;

const searchClient = algoliasearch('G5L9QWEP2F', 'bf1653634fbcabb418d2f166f607c083');

const Hit = ({ hit }) => (
  <div>
    <Highlight attribute="name" hit={hit} />
  </div>
);

Hit.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  hit: PropTypes.object.isRequired,
};

class SearchBar extends Component {
  state = {
    query: '',
  };

  onSuggestionSelected = (_, { suggestion }) => {
    window.location.href = suggestion.canonical;
    this.setState({
      query: suggestion.name,
    });
  };

  onSuggestionCleared = () => {
    this.setState({
      query: '',
    });
  };

  render() {
    const { query } = this.state;
    return (
      <StyledContainer>
        <InstantSearch indexName={index} searchClient={searchClient}>
          <Configure hitsPerPage={5} />
          <Autocomplete
            onSuggestionSelected={this.onSuggestionSelected}
            onSuggestionCleared={this.onSuggestionCleared}
          />
        </InstantSearch>

        <InstantSearch indexName={index} searchClient={searchClient}>
          <VirtalSearchBox defaultRefinement={query} />
          {query && <CustomHits hitComponent={Hit} />}
        </InstantSearch>
      </StyledContainer>
    );
  }
}

export default SearchBar;
