import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import { StyledButton } from 'components/ui/button';

export const Section = styled.section`
  padding: ${(props) => (props.blogPost ? '0 16px 90px' : '1.5rem 16px 3.5rem')};
  ${media.tablet} {
    padding: ${(props) => (props.blogPost ? '0 16px 90px' : '2rem 15px 1rem')};
  }
`;

export const Box = styled.div`
  background-color: #000;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  padding: 60px 86px 60px 64px;
  ${media.desktopWide} {
    padding: 60px 32px;
  }
  ${media.tablet} {
    padding: 32px;
  }
  ${media.desktop} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Info = styled.div`
  flex-basis: 70%;
  & h2 {
    font-size: 36px;
    line-height: 50px;
    margin: 0 0 18px;
    font-weight: 800;
    ${media.tablet} {
      font-size: 26px;
      line-height: 38px;
      margin: 0 0 16px;
    }
  }
  & p {
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    width: 85%;
    ${media.desktop} {
      margin-bottom: 24px;
    }
    ${media.tablet} {
      font-size: 14px;
      line-height: 24px;
    }
    ${media.mobile} {
      width: 100%;
    }
  }
  ${media.desktop} {
    flex-basis: auto;
  }
`;

export const CustomStyledButton = styled(StyledButton)`
  ${media.mobile} {
    max-width: 100%;
  }
  &:hover {
    background-color: #fff;
    color: #000 !important;
  }
  &:disabled {
    opacity: 0.5;
  }
`;
