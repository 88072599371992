import Link from 'components/ui/link';
import PropTypes from 'prop-types';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import * as Styled from './styled';

const SolutionCard = ({ image, title, desc, link }) => (
  <Styled.Card tag={AnchorLink} to={`/solutions/${link}`}>
    <img src={image} alt={title} />
    <p className="title">{title}</p>
    <p className="desc">{desc}</p>
    <Link withArrow to={link} tag="p">
      Learn more
    </Link>
  </Styled.Card>
);

export default SolutionCard;

SolutionCard.propTypes = {
  image: PropTypes.oneOfType([PropTypes.node, PropTypes.object]).isRequired,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};
