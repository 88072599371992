import ArticlePreview from 'components/blog/blogArticlePreview';
import { WhatsNext } from 'styles/tutorials';
import { authorKalina } from 'data/authors';
import { graphql, useStaticQuery } from 'gatsby';

export const meta = {
  title: 'Guide to A/B testing with LiveSession',
  url: '/tutorials/guide-to-ab-testing-with-livesession/',
  description:
    'Read our A/B testing guide and learn how to get the most out of your experiments with qualitative analytics and session recordings.',
  author: authorKalina,
  date: new Date(2020, 6, 30),
  group: 'Tutorial',
  tutorials: true,
  CustomSection: () => {
    const { increaseCover, abtestCover, analyzeCover } = useStaticQuery(graphql`
      query {
        abtestCover: file(relativePath: { eq: "blog/covers/ab-test.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 95)
          }
        }
        increaseCover: file(relativePath: { eq: "blog/covers/increase-conversion.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 95)
          }
        }
        analyzeCover: file(relativePath: { eq: "blog/covers/analyze-session-recordings.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 95)
          }
        }
      }
    `);
    return (
      <WhatsNext>
        <h2 className="heading">What’s next?</h2>
        <p className="desc">
          We hope this guide helped you understand how you can get the most out of A/B tests with
          LiveSession! Want to read more? You might also be interested in these resources:
        </p>
        <div className="article-container">
          <ArticlePreview
            authorId="kalina_tyrkiel"
            group="Articles"
            date="2019-10-07"
            url="/blog/5-easy-ways-to-increase-landing-page/"
            description="Landing page optimization is crucial for improving the performance of your marketing campaigns. Check out our tips and revamp your landing pages with no fuss!"
            title="5 easy ways to increase landing page conversion rate"
            img={increaseCover?.childImageSharp?.gatsbyImageData}
            isList
          />
          <ArticlePreview
            title="5 Tips for Better A/B Tests"
            url="/blog/5-tips-for-better-a-b-tests/"
            description="Every resource and asset you create - be it an ad, landing page or email, always consists of multiple elements."
            date="2019-12-30"
            group="Analytics"
            timeToRead={6}
            authorId="kalina_tyrkiel"
            img={abtestCover?.childImageSharp?.gatsbyImageData}
            isList
          />
          <ArticlePreview
            title="How to Analyze Session Recordings (Effectively)"
            url="/blog/analyze-session-recordings/"
            description="Don’t know what to do with your session replays? Learn how to analyze session recordings, the right way."
            date="2020-04-10"
            group="Analytics"
            timeToRead={6}
            authorId="kalina_tyrkiel"
            img={analyzeCover?.childImageSharp?.gatsbyImageData}
            isList
          />
        </div>
      </WhatsNext>
    );
  },
};
