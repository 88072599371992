import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import { StyledLink } from 'components/ui/link';
import purpleBgc from 'img/usability-testing/background/purple.svg';
import yellowBgc from 'img/user-experience/background/yellow.svg';
import blueBgc from 'img/heatmaps/background/blue.svg';
import quote from 'img/ui/quote.svg';

const renderClusterStyles = (cluster) => {
  switch (cluster) {
    case 'user-experience':
      return [yellowBgc, '#000', 'rgb(250,229,136)'];
    case 'usability-testing':
      return [purpleBgc, '#fff', '#8765f9'];
    case 'heatmaps':
      return [blueBgc, '#000', '#7dffef'];
    case 'product-analytics':
      return [purpleBgc, '#fff', '#8765f9'];
    default:
      return [yellowBgc, '#000', 'rgb(250,229,136)'];
  }
};

export const TimeToRead = styled.em`
  font-style: normal;
  margin-left: 16px;
  position: relative;
  &::before {
    content: '';
    width: 4px;
    height: 4px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-10px, -25%);
    border-radius: 50%;
    background-color: #000;
    opacity: 0.75;
  }
`;

export const Header = styled.div`
  padding: 56px 0;
  align-items: center;
  position: relative;
  ${media.desktopWide} {
    background-color: ${(props) => renderClusterStyles(props.cluster)[2]};
    padding: 56px 64px;
  }
  ${media.desktop} {
    padding: 56px 48px;
  }
  ${media.mobile} {
    width: 100vw;
    transform: translateX(-15px);
    padding: 24px 16px;
    font-size: 36px;
    line-height: 45px;
  }
  & h1 {
    color: ${(props) => renderClusterStyles(props.cluster)[1]};
  }
  &::before {
    content: '';
    top: 0;
    left: 0;
    width: 1290px;
    background-image: ${(props) => `url(${renderClusterStyles(props.cluster)[0]})`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    position: absolute;
    transform: translateX(-445px);
    z-index: -1;
    ${media.desktopWide} {
      display: none;
    }
  }
`;

export const Breadcrumbs = styled.div`
  grid-area: breadcrumbs;
  display: flex;
  align-items: center;
  margin-top: 144px;
  ${media.desktop} {
    display: none;
  }
  & img {
    margin: 0 16px;
    ${media.mobile} {
      max-width: 8px;
    }
  }
  & p {
    margin: 0;
    color: #000;
    font-size: 14px;
    line-height: 24px;
    ${media.mobile} {
      line-height: normal;
      font-size: 12px;
      line-height: 24px;
    }
    & a {
      color: #000;
      font-size: 14px;
      line-height: 24px;
      &:hover {
        color: #000;
      }
      ${media.mobile} {
        font-size: 12px;
        line-height: 24px;
      }
    }
  }
`;

export const MainContent = styled.section`
  margin: 54px 0 0;
  display: grid;
  grid-template-columns: 312px 770px;
  grid-column-gap: 72px;
  padding: 0 0 56px;
  ${media.desktopWide} {
    display: block;
  }
  ${media.desktop} {
    margin: 110px 0 0;
  }
  & .action-btn {
    margin-top: 40px;
  }
  & .head-content {
    margin-top: 72px;
    ${media.mobile} {
      margin-top: 40px;
    }
    ${StyledLink} {
      font-weight: 700;
      ${media.mobile} {
        font-size: 18px;
        line-height: 30px;
      }
    }
    & p {
      font-size: 20px;
      line-height: 36px;
      font-weight: 700;
      ${media.mobile} {
        font-size: 18px;
        line-height: 30px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    & h2 {
      font-weight: 800;
      font-size: 32px;
      line-height: 40px;
      margin: 0 0 24px;
      ${media.mobile} {
        font-size: 26px;
        line-height: 33px;
      }
    }
  }
`;

export const Article = styled.div`
  grid-area: 1 / 2 / 2 / 3;
  & article {
    margin-top: 88px;
  }
  ${StyledLink} {
    font-weight: 700;
    display: inline;
    ${media.mobile} {
      font-size: 18px;
      line-height: 30px;
    }
  }
  & p {
    font-size: 20px;
    line-height: 36px;
    margin-bottom: 32px;
    ${media.mobile} {
      font-size: 18px;
      line-height: 30px;
    }
  }
  & h2 {
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
    margin: 56px 0 24px;
    ${media.mobile} {
      font-size: 26px;
      line-height: 33px;
      margin: 48px 0 21px;
    }
  }
  & h3 {
    font-weight: 800;
    font-size: 24px;
    line-height: 30px;
    margin: 56px 0 32px;
  }
  & h4 {
    font-weight: 800;
    font-size: 20px;
    line-height: 28px;
    margin: 56px 0 32px;
  }
  ol,
  ul {
    margin-bottom: 30px;
    padding-left: 30px;
    & li {
      font-size: 20px;
      line-height: 36px;
      ${media.mobile} {
        font-size: 18px;
        line-height: 30px;
      }
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }
  & img,
  .gatsby-image-wrapper {
    margin-bottom: 56px;
    display: block;
    max-width: 100%;
  }
  & .next-chapter {
    background-color: #0446ca;
    border-radius: 4px;
    width: fit-content;
    max-width: 60%;
    margin-top: 72px;
    ${media.tablet} {
      max-width: 70%;
    }
    ${media.mobile} {
      max-width: 100%;
    }
    & a {
      color: #fff;
      padding: 16px 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      & img {
        transition: transform 0.2s ease;
        margin: 0;
      }
      &:hover {
        text-decoration: none;
        & p:last-child {
          text-decoration: underline;
        }
        & img {
          transform: translateX(8px);
        }
      }
      & div {
        margin-right: 40px;
        & p:first-child {
          margin-bottom: 8px;
          font-size: 18px;
          text-transform: uppercase;
          line-height: normal;
        }
        & p:last-child {
          margin-bottom: 0;
          font-weight: 800;
          font-size: 20px;
          line-height: 30px;
        }
      }
    }
  }
  blockquote {
    margin: 3.5rem 0 2rem;
    position: relative;
    background-color: #f1f5f9;
    padding: 48px;
    p:last-child {
      margin-bottom: 0;
    }
    ${media.mobile} {
      padding: 36px;
    }
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      transform: translate(-30%, -50%);
      background-image: url(${quote});
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      width: 82px;
      height: 61px;
      ${media.mobile} {
        width: 74px;
        height: 74px;
        transform: translate(0, -50%);
      }
    }
  }
  .action-banner {
    background-color: #7cffef;
    padding: 16px 24px;
    font-size: 18px;
    text-decoration: underline;
    color: #000;
    width: 100%;
    display: block;
    margin: 1.5rem 0;
    font-weight: 700;
    border-radius: 4px;
  }
`;

export const SideBarBox = styled.div`
  height: 100%;
  ${media.desktopWide} {
    display: none;
  }
`;

export const Author = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 56px;
  ${media.mobile} {
    margin-top: 24px;
  }
  & .avatar {
    margin: 0px 1rem 0px 0px;
  }
  & div {
    flex: 1 1;
    color: #fff;
    & span:first-child {
      margin-bottom: 0;
      font-weight: 700;
      display: block;
      color: ${(props) => renderClusterStyles(props.cluster)[1]};
    }
    & span:last-child {
      opacity: 0.75;
      font-size: 0.78rem;
      display: block;
      color: ${(props) => renderClusterStyles(props.cluster)[1]};
      ${media.mobile} {
        line-height: normal;
        margin-top: 3px;
      }
    }
  }
`;

export const ExtendTimeToRead = styled(TimeToRead)`
  &::before {
    background-color: #fff;
  }
`;

export const Divider = styled.div`
  border-top: 1px solid #e4e5e8;
  width: 100%;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 770px 1fr;
  justify-content: center;
  ${media.desktopWide} {
    display: block;
  }
`;
