/* eslint-disable jsx-a11y/anchor-is-valid */
import Link from 'components/ui/link';
import withUsabilityTestingTemplate from 'hoc/content-cluster';
import arrow from 'img/ui/arrow_white.svg';
import { StaticImage } from 'gatsby-plugin-image';
import { faq1 } from 'data/heatmaps/faq1';
import FAQ from 'components/FAQ';

const Heatmaps = () => (
  <>
    <h2>What are heat maps and which types can you use? </h2>
    <p>
      A heat map visually represents data by using a variety of colors. It enables brands to observe
      how visitors interact with a website by closely monitoring their cursor movement. Different
      colors indicate various levels of user engagement, with red being the ‘hottest spot’ and blue
      being the ‘coldest’. There are multiple{' '}
      <Link href="/heatmaps/what-is-a-heat-map/" target="_blank" rel="noopener noreferrer">
        web page heat maps
      </Link>{' '}
      that you can take advantage of, and here are the most common ones:{' '}
    </p>
    <p>
      <strong>Scroll map</strong> – we’re frequently overloaded with information, and have limited
      time to absorb it. Therefore it’s crucial to include the most important information in the
      most exposed places, and that’s what scroll maps are for. They help you assess how far down a
      page your visitors scroll and decide where to place your key copy or CTAs{' '}
    </p>

    <p>
      <strong>Click map</strong>– if you were to name the one most crucial element on your site,
      what would it be? CTAs, I bet. Click maps show you (attempted) button press frequencies, and
      by using them you’ll be able to identify the best areas for placing your CTAs, which should be
      the hottest spots. If you’re seeking a more detailed article on{' '}
      <Link href="/heatmaps/click-maps/" target="_blank" rel="noopener noreferrer">
        click maps, then here is one
      </Link>
      .
    </p>

    <p>
      <strong>Move maps</strong> – as indicated by their name, move maps allow you to observe cursor
      movements or hover activity on your web pages.{' '}
    </p>

    <p>
      <strong>Confetti heat map reports</strong> – similar to click maps, they demonstrate where
      users press by clearly showing click patterns, which say a lot about how visitors interact
      with your website.{' '}
    </p>
    <p>
      If you want to learn more about the aforementioned web AI-generated heat maps or read about
      attention heat maps and eye-tracking heat maps,{' '}
      <Link href="/heatmaps/what-is-a-heat-map/" target="_blank" rel="noopener noreferrer">
        then here is a great source for it.
      </Link>
      .
    </p>
    <h2>The top benefits of using web heat maps</h2>
    <p>
      Before we get down to the nitty-gritty of creating and analyzing heat maps, it’s worth looking
      at why it’s actually worth using a heat map online in the first place. Below are a few
      benefits:
    </p>
    <h3>#1 Understanding the context of data from other website tools</h3>
    <p>
      Chances are, before you first create a webpage heat map, you will already be making the most
      of some other usability-related tools such as Google Analytics or on-site surveys. Perhaps
      they’ve helped you notice a lower-than-expected conversion rate on your pricing page or that
      you’ve received low scores in your website usability survey. By creating a heat map online,
      you will be able to get more context to these numbers and understand whether it’s the
      service/offer itself or website usability to blame. This brings us to the next point below.
    </p>
    <StaticImage
      src="../img/heatmaps/introduction/unsplash1.jpg"
      alt="person using macbook pro on black table photo"
      title="The top benefits of using web heatmaps"
    />
    <p>
      Source:{' '}
      <Link
        href="https://unsplash.com/photos/Pu96DJ6rct8"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Unsplash
      </Link>
    </p>
    <h3> #2 Finding usability issues and bugs</h3>
    <p>
      When it comes to the reasons why people visit your site, it’s simple – they click through to
      find what they came for (either a service or information), quickly and intuitively. If you’ve
      noticed that a page of yours has an alarmingly high bounce rate, it’s worth setting up a heat
      map for further investigation. Some of the usability problems you can notice on your heat map
      include:
    </p>
    <ul>
      <li>
        wrong clicks (i.e. visitors clicking on elements they think are hyperlinked, but are not)
      </li>
      <li>rage/error clicks (broken links)</li>
      <li>people not scrolling far enough down to read your content (i.e. low scroll rates)</li>
    </ul>
    <h3>#3 Boosting conversion through better CTA and content placement</h3>

    <p>
      Understanding on-site activities will also help you better tailor your messaging and optimize
      content element placement. If you use a scroll map, you’ll be able to understand how far down
      the page visitors browse, on average. If you see that just a quarter of people look anywhere
      below the fold, you’ll know that you should either work on your messaging or layout to
      encourage further content exploration.
    </p>
    <p>
      Respectively, if you’re experiencing low conversion rates, a click map might indicate that
      people are distracted before they see the CTA. Perhaps they’re more drawn to a promotional
      banner than to your “sign up for free” button?
    </p>

    <p>
      These are just a few of the advantages you can expect to gain from. If you’d like to learn
      more about the other ways that heat maps can assist you, refer to our dedicated piece on the
      benefits of using a{' '}
      <Link href="/heatmaps/heat-mapping-tool/" target="_blank" rel="noopener noreferrer">
        heat mapping tool
      </Link>
      .
    </p>

    <h2>Best practices & mistakes to avoid when creating a heat map</h2>
    <p>
      Now that you know the benefits of using heat maps, it’s time to take a look at some best
      practices.{' '}
    </p>
    <h3>Decide which pages to place your heat map on</h3>
    <p>
      If you’re thinking about placing a heat map on every page, you may want to reconsider your
      approach. Pick those that are most crucial for your business and attract the most traffic.
      While the homepage probably seems like an obvious choice, we also recommend diving into your
      Google Analytics to identify the most suitable candidates.{' '}
    </p>
    <h3>Know what you want to verify</h3>
    <p>
      To find solutions to your problem, you firstly need to know what challenge you want to
      address. Is a high cart abandonment rate what leaves you sleepless at night, or maybe the fact
      that only 20% of visitors scroll to the bottom of your page? Whatever it is, agree on your
      objectives before creating a web heat map.
    </p>
    <h3>Supplement your heat maps with session recordings</h3>
    <p>
      Even though heat maps constitute a great data mine and can point out major issues on your
      website, unfortunately they won’t tell you the ‘why’ behind the problem. For this reason, we
      recommend using them together with session replays, which will show you what users do on your
      site. After watching a few recordings, you’ll find out exactly what the problem is. If you’re
      searching for a heat map tool that offers both heat maps and session recordings{' '}
      <Link href="/" target="_blank" rel="noopener noreferrer">
        then check out LiveSession
      </Link>{' '}
      – we offer a free trial.{' '}
    </p>
    <p>
      It’s normal to make mistakes when creating heat maps, so, to make it a little easier for you,
      we will discuss the most common ones so you can avoid them in the future.
    </p>
    <h3>Drawing conclusions too early from your web heat map</h3>
    <p>
      It is tempting to start making changes on your website right after gathering a few insights
      from your web page heat map, but try not to do this too early in the process. To draw credible
      conclusions you have to collect a sufficient amount of data, which means enough users must
      visit your site. Otherwise, your results won’t be statistically relevant, and your conclusions
      will be based on just a few users’ behavior.{' '}
    </p>
    <h3>Relying solely on heat map findings</h3>
    <p>
      As mentioned above, you cannot rely only on the data you get from heat maps. Support it with
      additional actionable insights – conduct user surveys, hold in-person interviews, or turn to
      session recordings, etc. The more meaningful insights you collect, the easier it will be for
      you to draw meaningful conclusions and come up with actionable solutions.{' '}
    </p>
    <h3>Not blocking your IP</h3>
    <p>
      Remembering that blocking your IP is important, especially if your organization is large. If a
      lot of visitors that enter your site are employees, the data you gather will be irrelevant.{' '}
    </p>

    <p>
      If you’re hungry for more info about creating a heat map online with additional mistakes to
      avoid and best practices to follow listed,{' '}
      <Link href="/heatmaps/generating-heat-maps/" target="_blank" rel="noopener noreferrer">
        check out this article
      </Link>
      .{' '}
    </p>
    <h2>Improving your website with heat maps – three key pages explained</h2>

    <p>
      You may now be wondering “what exactly should I keep an eye out for on my homepage, blog, and
      other key pages?” Let’s discuss how to leverage heat maps on each of those pages below.
    </p>
    <h3>Homepage & landing pages</h3>
    <p>
      In many cases, your homepage is the first place visitors come across on your site. And you
      know what they say – you can only make a first impression once, so it better be a good one.
    </p>
    <p>
      The same goes for landing pages, which can be discovered via Google searches. It’s worth
      setting up a heat map on landing pages for products and your marketing/sales campaigns.
    </p>

    <p>Among others, a homepage heat map will assist you with:</p>
    <ul>
      <li>
        <strong>Better CTA placement</strong> - a heat map will indicate the “hot” (i.e. most
        noticed) parts of your page, which make great candidates for your buttons.
      </li>
      <li>
        <strong>Removing confusing or distracting elements</strong> - are people clicking on
        unlinked elements? Perhaps, that’s why they are getting frustrated and bouncing from your
        site. You’ll also be able to notice elements that unrighteously “steal the show”, i.e. draw
        visitors’ attention away from critical “buy now” or “sign up free” buttons
      </li>
      <li>
        <strong>Trial various messaging</strong>- observe how a change in your value proposition of
        CTA affects user click rates or scrolls. As we discuss further below, you can do this
        jointly with an A/B testing tool.
      </li>
    </ul>
    <h3>Blog</h3>
    <p>
      Your blog also deserves attention – it’s where you can establish yourself as an expert and
      convert many of your prospects into leads.{' '}
    </p>

    <p>In the case of your blog, we recommend:</p>
    <ul>
      <li>
        <strong>Looking at scroll rates</strong>- this will tell you if people are actually reading
        your content and where they drop off. Bear in mind that “just” creating good content won’t
        suffice. Your blog must also be pleasing to the eye to encourage visitors to keep reading.{' '}
      </li>
      <li>
        <strong>Checking if people see your buttons</strong> such as newsletter signups - if your
        analytics tool says people don’t subscribe to your newsletter, check if the buttons or forms
        are placed in “hot” areas on your page.
      </li>
    </ul>
    <p>
      You can read more on various{' '}
      <Link href="/heatmaps/what-is-a-heat-map/" target="_blank" rel="noopener noreferrer">
        heat map uses
      </Link>{' '}
      in a dedicated section of this guide.
    </p>
    <h2>Experts share the UX improvements they have achieved with heat maps</h2>

    <p>
      With the theory now covered, it’s time for some real-life examples from the market. We’ve
      asked a few experts to share what user experience improvements they were able to achieve by
      using web page heat maps. We share a few of them below (you can find the full list in a
      separate chapter on website experience and{' '}
      <Link href="/heatmaps/what-is-a-heat-map/" target="_blank" rel="noopener noreferrer">
        UX improvements
      </Link>
      ).
    </p>
    <h3>#1 An 8% drop in bounce rates</h3>
    <p>
      Chris Anderson, Owner and SEO Analyst at{' '}
      <Link href="https://myfiveguide.com" target="_blank" rel="noopener noreferrer nofollow">
        My Five Guide
      </Link>
      , shared with us that heat maps helped him create a smarter and more user-friendly web design.
      After setting up a heat map on their homepage, Chris noticed that users weren’t scrolling to
      the “Popular Posts” section.{' '}
    </p>
    <StaticImage
      src="../img/heatmaps/introduction/myfiveguide.png"
      alt="My five guide website"
      title="An 8% drop in bounce rates"
    />
    <p>
      Source:{' '}
      <Link href="https://myfiveguide.com/" target="_blank" rel="noopener noreferrer nofollow">
        My Five Guide
      </Link>
    </p>
    <p>
      To solve the issue, he decided to move the section to a hotter, i.e. more visible area which
      made it easier for users to find. As a result, Chris noticed a quite abrupt, positive change
      in user session length. The best part, however, is that the change resulted in an{' '}
      <strong>8% drop in bounce rates</strong>, and the “Popular Posts” section now gets{' '}
      <strong>17% more notices from visitors</strong>Chris concludes that setting up the heat map
      online allowed him to find the issue in the website architecture that was hindering the
      overall results.
    </p>
    <h3>
      {' '}
      #2 A 23% higher conversion rate from the homepage thanks to website experience improvements
    </h3>
    <p>
      Kristian Borghesan, Director of Marketing at{' '}
      <Link href="https://savology.com" target="_blank" rel="noopener noreferrer nofollow">
        Savology
      </Link>
      , an online financial planning platform, says that he frequently turns to heat maps to spot
      the most important user interaction points and come up with solutions for optimizing the
      website experience. By placing a heat map on their homepage, they identified areas where
      visitors spend 80% of their time without taking any action. To increase engagement they
      reorganized their content sections and introduced new CTAs. This resulted in their{' '}
      <strong>conversion rate increasing by 23%</strong>.{' '}
    </p>
    <StaticImage
      src="../img/heatmaps/introduction/savology.png"
      alt="Savology website"
      title="A 23% higher conversion rate from the homepage thanks to website experience improvements"
    />
    <p>
      Source:{' '}
      <Link href="https://savology.com/" target="_blank" rel="noopener noreferrer nofollow">
        Savology
      </Link>
    </p>
    <h3>#3 Nearly 2x longer user sessions</h3>
    <p>
      John Ross, CEO at{' '}
      <Link href="https://testprepinsight.com/" target="_blank" rel="noopener noreferrer nofollow">
        Test Prep Insight
      </Link>
      , a US-based online education company offering exam test prep reviews, says that more often
      than not website conversion rate optimization is like walking in the dark – you’re unsure
      where you’re going. This can be changed by using heat maps. John built two separate landing
      pages with inverse layouts and ran a heat map test after thousands of users had visited the
      page to see where they clicked, scrolled, and dropped off the page. Thanks to the insights
      collected, they came up with the idea to create a page that would combine elements from both
      variants and decided to run another test. The results were fabulous – the initial session
      duration prior to the redesign was <strong>1 minute 6 seconds</strong>, which increased to{' '}
      <strong>1 minute and 53 seconds</strong>after implementing the changes. Well worth the effort.{' '}
    </p>
    <StaticImage
      src="../img/heatmaps/introduction/testprep.png"
      alt="Test Prep Insight website"
      title="Nearly 2x longer user sessions"
    />
    <p>
      Source:{' '}
      <Link href="https://testprepinsight.com/" target="_blank" rel="noopener noreferrer nofollow">
        Test Prep Insight
      </Link>
    </p>
    <h3>#4 A 9% higher conversion rate thanks to improving the content layout</h3>
    <p>
      Alex Willen, owner of the e-commerce store{' '}
      <Link href="https://coopersdogtreats.com/" target="_blank" rel="noopener noreferrer nofollow">
        Cooper’s Treats
      </Link>
      , shares yet another impressive heat map result.{' '}
    </p>

    <p>
      When Alex first set up a web heat map, he was surprised to see that people were scrolling all
      the way to the footer and clicking on “Our Mission”. As the Cooper’s Treats team found out,
      people who read the mission webpage were 14% more likely to place an order than those who went
      straight to product pages. They understood that their target customer was interested in
      company values, so they moved the “Our Mission” link right to the top menu.
    </p>
    <p>
      The results? After 30 days, <strong>the conversion rate grew by 9%</strong>. Also, visits to
      the &quot;Our Mission&quot; page <strong>increased by over 200%</strong>, with those clicking
      through more likely to finalize their purchases.
    </p>
    <StaticImage
      src="../img/heatmaps/introduction/cooper.png"
      alt="Cooper’s Treats website"
      title="9% higher conversion rate thanks to improving the content layout"
    />
    <p>
      Source:{' '}
      <Link href="https://coopersdogtreats.com/" target="_blank" rel="noopener noreferrer nofollow">
        Cooper’s Treats
      </Link>
    </p>
    <p>
      Hopefully by now you’re ready to roll up your sleeves and see how setting up a heat map can
      also help your business. Before you dive in, however, a few words on how you should analyze
      your heat maps to make sure you can achieve the tangible results you’ve read about in this
      section.
    </p>
    <h2>How to make sure you properly analyze your heat maps</h2>

    <p>
      Time to discuss how to analyze your heat maps. Below are a few steps you should follow to
      ensure you’re reading them properly and drawing the right conclusions.{' '}
    </p>

    <p>
      Assuming you’ve previously selected a few pages and set up heat maps on them, you should focus
      your analysis on:
    </p>
    <ul>
      <li>
        <strong>Heat map colors</strong>.- as mentioned earlier, areas that get the most engagement
        are marked with “hot” colors such as red, orange, and yellow. That’s where you should place
        the critical elements and information that you want to get noticed. On the other end of the
        spectrum are greens and blues, which show where people don’t look at all.
      </li>
      <li>
        <p>
          <strong>Clicks</strong> - a heat map tool like{' '}
          <Link href="/" target="_blank" rel="noopener noreferrer">
            LiveSession
          </Link>{' '}
          lets you see both heat map colors and clicks for each site in one view. By looking at your
          heat map results, ask yourself:
        </p>
        <ul>
          <li>Where do people click the most? Are any of them non-clickable elements?</li>
          <li>
            How many clicks do the various CTAs get? Do your conversion-related dynamic elements get
            more clicks than those of lesser business importance?
          </li>
          <li>
            Are there any rage clicks? If so, chances are you have an error on your site which is
            hurting your usability immensely.
          </li>
        </ul>
      </li>
      <li>
        <strong>Scrolls</strong>- where do people stop browsing down to? Are there any products or
        CTAs that you’d like to get noticed in the “cold” parts of your webpage?
      </li>
    </ul>
    <p>
      This is just a quick overview – you can read more on heat map analysis in a{' '}
      <Link href="/heatmaps/heatmap-analysis/" target="_blank" rel="noopener noreferrer">
        separate chapter
      </Link>{' '}
      of this guide.
    </p>

    <p>
      Before you jump all in though, there’s also one more thing you must be aware of – avoiding
      bias. Heat maps tell you a ton about how people interact with your site, but they might not be
      able to tell you why. That’s why you should supplement your insights with data from other
      software, which is what we will get into below.
    </p>
    <h2>How to use heat map software with other tools</h2>
    <p>
      Let’s take a look at{' '}
      <Link href="/heatmaps/heat-map-software/" target="_blank" rel="noopener noreferrer">
        the tools that you can turn to to further enrich your data
      </Link>
      , starting with session recordings.
    </p>
    <h3>Session recordings</h3>
    <p>
      Believe us when we say that you can’t effectively use heat maps without session recordings.
      You probably want to know why, and that is because heat map data comes in large volumes and is
      anonymous. While they’re a great tool for identifying problematic areas, heat maps won’t tell
      you why the issue occurs. You have to dive deeper, and that’s exactly what session recordings
      let you do. Check out LiveSession if you need a platform that combines the two.
    </p>
    <StaticImage
      src="../img/heatmaps/introduction/session.png"
      alt="LiveSession recording"
      title="Session recordings"
    />
    <h3>Website analytics</h3>
    <p>
      Website analytics should always be your starting point prior to setting up a heat map. Not
      only will you be able to decide which pages to place them on based on such stats like
      conversion rate, traffic, and bounce rate, but you’ll also be able to check how the changes
      you implement impact the initial stats. Has your conversion rate gone up, and is the bounce
      rate lower? We highly recommend using{' '}
      <Link href="https://analytics.google.com/" target="_blank" rel="noopener noreferrer nofollow">
        Google Analytics
      </Link>
      .{' '}
    </p>
    <StaticImage
      src="../img/heatmaps/introduction/ga.png"
      alt="Google analytics website"
      title="Website analytics"
    />
    <p>
      Source:{' '}
      <Link
        href="https://contentmarketinginstitute.com/2019/04/helpful-google-analytics-reports/"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        CMI
      </Link>
    </p>
    <h3>Website feedback</h3>
    <p>
      Heat map data is quantitative, so it’s always worth supplementing it with qualitative data
      that can be collected via surveys. Only then will you be able to get a whole picture of the
      issue. You can either email your customers asking them to fill out surveys or place one on
      your website for visitors to answer. You can turn to{' '}
      <Link href="https://survicate.com/" target="_blank" rel="noopener noreferrer nofollow">
        Survicate
      </Link>{' '}
      to collect user feedback - it offers multiple surveying options and has various integrations,
      including one with LiveSession.{' '}
    </p>
    <StaticImage
      src="../img/heatmaps/introduction/survicate.png"
      alt="Survicate website"
      title="Website feedback"
    />
    <p>
      Source:{' '}
      <Link
        href="https://survicate.com/integrations/getresponse-survey/"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Survicate
      </Link>{' '}
    </p>
    <h3>A/B testing tools</h3>
    <p>
      After getting results from your heat maps, you may come up with a solution or two. To check
      which one is best, you can use A/B testing tools. For instance, you might identify that your
      copy is the reason behind your low conversion rate. If so, you can test different variants to
      check which one performs best. If you’re on the lookout for an A/B testing tool, then you
      might want to consider{' '}
      <Link
        href="https://optimize.google.com/optimize/home/"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Google Optimize
      </Link>
      . It quite handily integrates not only with LiveSession, but also the entire G-Suite.
    </p>
    <StaticImage
      src="../img/heatmaps/introduction/goptimize.png"
      alt="Google Marketing Platform website"
      title="A/B testing tools"
    />
    <p>
      Source:{' '}
      <Link
        href="https://marketingplatform.google.com/about/optimize/"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Google Marketing Platform
      </Link>
    </p>
    <h2>Summary</h2>

    <p>
      If you want to make sure that your website is user-friendly and optimized for conversion,
      you’ll love what heat maps bring to the table. There are a variety types of heat maps that you
      can use to gain insights – from general, color-based ones all the way through to scroll,
      click, and move maps. We dive into these various options in the next chapter.
    </p>

    <p>
      When you first decide which pages to set up heat maps on, consider those that are crucial from
      a business perspective – e.g. homepage, pricing, blog, or any important landing pages. To draw
      the right conclusions, we also highly recommend using them jointly with other tools including
      A/B testing software, customer surveys, and website analytics, just to name a few.
    </p>

    <p>
      LiveSession is unique on the heat map software landscape, as it offers users a 3-in-1 view of
      user activities – you can see a heat map, clicks, and every user’s session recordings on each
      page. You can{' '}
      <Link signUp sourceID="text" target="_blank" rel="noopener noreferrer">
        try it out for free here
      </Link>{' '}
      and use what you’ll learn later on in this guide to deliver the best results.
    </p>
    <FAQ questions={faq1} style={{ paddingTop: '120px' }} />
    <div className="next-chapter">
      <Link href="/heatmaps/what-is-a-heat-map/">
        <div>
          <p>Chapter 1</p>
          <p>How to define heat maps and what types you can use for your business</p>
        </div>
        <img src={arrow} alt="arrow" title="white arrow" />
      </Link>
    </div>
  </>
);

export default withUsabilityTestingTemplate(Heatmaps);
