import React, { useState } from 'react';
import { navigate, Link as GatsbyLink } from 'gatsby';
import Layout from 'layout';
import StartUsing from 'components/ui/startUsing';
import TrustedBy from 'components/ui/trustedBy';
import Button from 'components/ui/button';
import {
  Header,
  FeaturesSection,
  IntegrationsAside,
  LivechatSection,
  InfoSection,
  CardsContainer,
  CardIntegration,
} from 'components/ecommerce/styles';
import StartUsingCTA from 'components/index/startusingCTA';
import { StaticImage } from 'gatsby-plugin-image';
import { ExtendSignUpForm, CustomButton } from 'components/ui/startUsing/styled';
import Link from 'components/ui/link';
import { useSourceParams } from 'hooks/useSourceParams';
import { GridBox } from 'components/index/hero/styled';
import segmentlogo from 'img/ecommerce/segment.png';
import eventsIcon from 'img/ecommerce/properties-icon.svg';
import segmentsIcon from 'img/ecommerce/segments-icon.svg';
import lclogo from 'img/companyLogo/livechat_black.png';
import { platforms } from 'data/integrations/platforms';
import FeaturesSlider from 'components/index/featuresSlider';
import { cards } from 'data/index/cards';
import { useFeatureData } from 'data/features/index';
import { FeatureWrapper } from 'styles/ecommerce';

const Ecommerce = () => {
  const [email, setEmail] = useState('');

  const { link } = useSourceParams('hero-input');

  const data = useFeatureData();

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`${link}&email=${email}`);
  };

  return (
    <Layout
      metaTitle="LiveSession for eCommerce | Recover Abandoned Carts"
      canonical="/industry/ecommerce/"
      metaDescription="Get to know how to recover abandoned carts and increase conversion rates using session replays. Improve your store’s UX and boost your sales."
    >
      <div className="container">
        <Header>
          <GridBox>
            <div className="gridbox-container">
              <h1 className="line">
                LiveSession for <span>eCommerce</span>
              </h1>
              <p>
                Improve your eCommerce conversion rates and recover abandoned carts using
                qualitative analytics.
              </p>
              <div className="start">
                <ExtendSignUpForm onSubmit={handleSubmit}>
                  <label htmlFor="email-form-hero" className="sign-up-free">
                    <input
                      id="email-form-hero"
                      type="email"
                      name="email"
                      placeholder="Your business email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      className="input-email"
                    />
                  </label>
                  <CustomButton as="button" type="submit" secondary>
                    Sign up free
                  </CustomButton>
                </ExtendSignUpForm>
                <div className="checkmarks">
                  <p>Free 14-day trial</p>
                  <p>
                    No credit card <span>required</span>
                  </p>
                  <p>Easy setup</p>
                </div>
              </div>
            </div>
          </GridBox>
          <StaticImage
            src="../../img/ecommerce/hero.png"
            alt="showcasing a session that demonstrates the recovery of abandoned shopping carts using qualitative analytics"
            placeholder="BLURRED"
            objectFit="contain"
          />
        </Header>
        <TrustedBy hero />
      </div>
      <div className="container">
        <FeatureWrapper>
          <div className="separator" />
          <div className="pt-168-120-90">
            <FeaturesSlider
              cards={cards}
              images={data.yellow.images}
              alternativeText={data.yellow.alt}
            />
          </div>
        </FeatureWrapper>
        <div className="p-120-90">
          <StartUsingCTA />
        </div>
        <FeaturesSection className="shopping-events">
          <div>
            <img
              src={eventsIcon}
              alt="configure custom events to reflect the buyer's journey and identify leaks in the funnel. take full advantage of session replay to track your customers' purchase path. "
            />
            <h3>Track shopping events</h3>
            <p>
              Set up custom events to reflect your buyer’s journey and identify funnel leaks. Get
              the most of your session replays and track your customers’ path to purchase. Plug the
              funnel by improving your website’s usability to increase conversion rates. Add few
              lines of code and extend our default filters set according to your needs.
            </p>
            <Link withArrow href="/help/how-do-the-custom-events-work/">
              Use custom events
            </Link>
            <p>
              <strong>Connect events with your current analytics stack:</strong>
            </p>
            <div className="logo-container">
              <StaticImage
                src="../../img/ecommerce/ga.png"
                alt="google analytics logo"
                title="Google analytics logo"
                style={{ maxWidth: 204 }}
                objectFit="contain"
              />
              <img
                src={segmentlogo}
                alt="segment logo"
                title="Segment logo"
                className="img-fluid"
              />
            </div>
          </div>
          <StaticImage
            src="../../img/ecommerce/events.png"
            alt="custom events"
            style={{ zIndex: 5, maxWidth: 1600, margin: '0 auto' }}
            objectFit="contain"
          />
        </FeaturesSection>
        <FeaturesSection reversed>
          <div>
            <img src={segmentsIcon} alt="search icon" />
            <h3>Get to know your customers’ segments</h3>
            <p>
              Clients who didn’t make a purchase even though they filled their shopping carts.
              Customers who spent more than $100. Window shoppers. Segment your visitors’ base to
              match their profile so you can get valuable insights into how different groups of
              customers use your online store. Save your search terms as segments set email
              notifications and keep them on hand at all times.
            </p>
          </div>
          <StaticImage
            src="../../img/ecommerce/filters.png"
            alt="segment your visitor base to match their profile, which gives you valuable information about how different customer groups use your online store"
            style={{ zIndex: 5, maxWidth: 1600, margin: '0 auto' }}
            objectFit="contain"
          />
        </FeaturesSection>
      </div>
      <IntegrationsAside>
        <div className="container">
          <div className="aside-content">
            <h2>Connect with eCommerce platforms</h2>
            <p>
              No matter if you use Magento, PrestaShop, Shopify, WooCommerce or a custom solution -
              we got you covered. You can easily integrate LiveSession with every eCommerce platform
              in the world.
            </p>
            <Link withArrow href="/integrations/">
              Show integrations
            </Link>
          </div>
          <CardsContainer>
            {platforms.map((platform) => (
              <CardIntegration
                key={platform.img}
                to={platform.link}
                as={platform.link ? GatsbyLink : 'div'}
              >
                <img src={platform.img} alt={platform.alt} className="img-fluid" />
                <p>{platform.alt}</p>
              </CardIntegration>
            ))}
          </CardsContainer>
        </div>
      </IntegrationsAside>
      <div className="container">
        <InfoSection>
          <div>
            <StaticImage
              src="../../img/ecommerce/rage.png"
              alt="rage and error clicks"
              objectFit="contain"
            />
            <h2>Identify errors with ease</h2>
            <p>
              Be one of the first to know about flaws that lower your conversion rates. Identify
              places on your website that causes your customers’ frustration and eliminate
              JavaScript errors. Use Rage Clicks & Error Clicks filters and focus on session replays
              requiring your attention.
            </p>
          </div>
          <div>
            <StaticImage
              src="../../img/ecommerce/engagement.png"
              alt="combining data from paid marketing campaigns with qualitative analysis"
              objectFit="contain"
            />
            <h2>Get more out of your ad spend</h2>
            <p>
              Combine data from your paid marketing campaigns with qualitative analytics. See how
              different groups of clients you target use your website. Optimize your ad sets based
              not only on how they perform but also on the desired effect.
            </p>
          </div>
        </InfoSection>
        <LivechatSection>
          <h2>Enhance your customer support</h2>
          <p className="header-desc">
            Session replays are a great way to ease your customer support team&apos;s daily routine.
            Lower the time needed to provide assistance to your clients with LiveSession&apos;s
            integrations!
          </p>
          <div className="banner">
            <div className="banner-content">
              <img src={lclogo} alt="livechat logo" className="img-fluid" />
              <h3>Extend your tool stack capabilities</h3>
              <p>
                Add context to each chat you are having with your customers. Enrich every LiveChat
                conversation with a link to the user’s session replay. Streamline your customer
                support process and make your support team’s life easier.
              </p>
              <div className="banner-cta--buttons">
                <Button href="/integrations/livechat/">Learn more</Button>
                <Link withArrow href="/integrations/">
                  Show more integrations
                </Link>
              </div>
            </div>
            <StaticImage
              src="../../img/ecommerce/livechat.png"
              alt="livechat"
              objectFit="contain"
            />
          </div>
        </LivechatSection>
      </div>
      <StartUsing />
    </Layout>
  );
};

export default Ecommerce;
