import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import Link from 'components/ui/link';
import { useFeatureData } from 'data/features';
import badge from 'img/index/GDPRbadge.svg';
import badge2 from 'img/index/CCPAbadge.svg';
import PropTypes from 'prop-types';
import * as Styled from './styled';

const PersonalData = ({ header, headerBr, paragraph }) => {
  const data = useFeatureData();
  return (
    <Styled.BackgroundContainer>
      <div className="container">
        <Styled.PersonalDataSection>
          <div className="content">
            <h2>
              {header}
              <br /> {headerBr}
            </h2>
            <p>{paragraph}</p>
            <Link withArrow title="Privacy policy" href="/help/privacy/">
              Read about the privacy
            </Link>
            <Styled.ImageContainer>
              <Link href="/help/gdpr/">
                <img src={badge} alt="GDPR badge" title="GDPR Compiliant" className="img-fluid" />
              </Link>
              <Link href="/help/ccpa/">
                <img src={badge2} alt="CCPA badge" title="CCPA Compiliant" className="img-fluid" />
              </Link>
            </Styled.ImageContainer>
          </div>
          <GatsbyImage
            image={data.personalData.mainImage}
            objectFit="contain"
            objectPosition="center center"
            className="main-image"
            alt="a session showing how livesession takes care of security to make sure everything is RODO and CCPA compliant"
          />
        </Styled.PersonalDataSection>
      </div>
    </Styled.BackgroundContainer>
  );
};

export default PersonalData;
PersonalData.propTypes = {
  header: PropTypes.string.isRequired,
  headerBr: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
};
