import { useStaticQuery, graphql } from 'gatsby';

export const useMetadata = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          description
          siteUrl
          title
          social {
            fbAppId
            twitter
          }
          organization {
            logo
            name
            url
          }
        }
      }
    }
  `);
  return site;
};
