import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const GridBox = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  padding-top: ${(props) => (props.hideTitle ? 0 : '48px')};
  padding-bottom: 0;
  ${media.desktopWide} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${media.tablet} {
    grid-template-columns: 1fr;
  }
`;

export const Section = styled.section`
  padding: 0;
`;
