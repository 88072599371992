require('./src/fonts/roboto/styles.css');
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

/**
 * Imported global styles here, not in Layout file
 * When you import global styles from layout file, then you've got different order of style imports on develop and build.
 * So your styled-components got lower specifity on develop mode than bootstrap
 * Now all looks good
 * Issue: https://github.com/gatsbyjs/gatsby/issues/9911
 * Inspiration: https://github.com/gatsbyjs/gatsby/issues/9911#issuecomment-451993934
 */
if (process.env.activeEnv === 'development') {
  // eslint-disable-next-line global-require
  require('./src/sass/style.scss');
}

// fetch polyfill
require('whatwg-fetch');

exports.onClientEntry = () => {
  // Don't need to do anything here, but if you don't
  // export something, the import won't work.
};

// exports.shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
//   const currentPosition = getSavedScrollPosition(location);
//   const queriedPosition = getSavedScrollPosition({ pathname: `/random` });

//   window.scrollTo(...(currentPosition || [0, 0]));
//   window.scrollTo(0, 0);

//   return false;
// };
