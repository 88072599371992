import Layout from 'layout';
import OwnAppSection from 'components/marketplace/ownAppSection';
import SideBar from 'components/marketplace/sideBar';
import Content from 'components/marketplace/content';
import { GridBox, Header, Section } from 'styles/integrations';

const Integrations = () => (
  <Layout
    metaTitle="Integrations Marketplace"
    metaDescription="Get even more out of LiveSession. Browse available integrations with other tools. Improve analytics, customer support, and more."
    canonical="/integrations/"
  >
    <Section className="container">
      <Header>
        <h1 className="line">
          Connect your favorite tools to <span>LiveSession</span>
        </h1>
        <p>Use integrations to get even more insights</p>
      </Header>
      <GridBox>
        <SideBar activeCategory="All integrations" />
        <Content activeCategory="All integrations" hideTitle />
      </GridBox>
      <OwnAppSection />
    </Section>
  </Layout>
);

export default Integrations;
